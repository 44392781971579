import React from 'react'
import moment from 'moment'
import { DatePicker, Radio, Menu, Button, Select } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { DatePickerSlider } from '../CSS/style'
import Bar from './Bar'
import { historicChartData1 } from "../ChartCalls"
import {
    getTranslationCache,
} from "../../../../selectors/language";
const { Option } = Select;
let constant = {
    GetReport: 'Get Report',
    Daily: 'Daily',
    Month: 'Month',
    Year: 'Year'
}


class BarDatePicker extends React.Component {
    constructor(props) {
        super(props)
        let start = moment().format('YYYY-MM-DD 00:00:00')
        let end = moment().format('YYYY-MM-DD 23:59:59')
        this.state = {
            currentDate: moment(),
            endDate: moment(),
            disableNext: false,
            currentTab: 'today',
            month: moment().format('MMMM'),
            year: moment().year(),
            translationCache: props.translationCache || [],
            language: props.language || "English",
            graphprops: {
                ...props.graphprops,
                startDate: moment(start).utc().format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment(end).utc().format('YYYY-MM-DD HH:mm:ss'),
                duration: '1 hour',
                durationType: 'daily',
                currentDateData: [],
            } || {},
            payload: {
                startDate: moment(start).utc().format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment(end).utc().format('YYYY-MM-DD HH:mm:ss'),
                duration: '1 hour',
                durationType: 'daily',
            }
        };
    }

    disabledDate = (current) => {
        return current > moment().startOf('day').add(1, 'day');
    }

    async componentDidMount() {
        let { graphprops } = this.state
        let chartData = await historicChartData1(this.state.graphprops);
        let graphpropsResult = {
            ...graphprops,
            currentDateData: chartData
        }
        this.setState({ graphprops: graphpropsResult })
    }

    onChange = async (date) => {
        let startDateSelected = moment(date).format('YYYY-MM-DD 00:00:00')
        let endDateSelected = moment(date).format('YYYY-MM-DD 23:59:59')
        let { graphprops } = this.state
        let graphpropsProps = {
            ...graphprops,
            startDate: moment(startDateSelected).utc().format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(endDateSelected).utc().format('YYYY-MM-DD HH:mm:ss'),
            duration: '1 hour',
            durationType: 'daily',
        }
        let chartData = await historicChartData1(graphpropsProps);
        let graphpropsResult = {
            ...graphpropsProps,
            currentDateData: chartData
        }
        let payload = {
            startDate: moment(startDateSelected).utc().format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(endDateSelected).utc().format('YYYY-MM-DD HH:mm:ss'),
            duration: '1 hour',
            durationType: 'daily',
        }
        this.setState({ currentDate: date, graphprops: graphpropsResult, payload })
    }

    onRadioChange = (e) => {
        if (e.target.value === 'month') {
            let currentMonth = moment().format('MMMM')
            let currentYear = moment().year()
            this.handleMenuClick(currentMonth, 'month')
            this.setState({
                currentTab: e.target.value,
                month: currentMonth,
                year: currentYear
            })
        } else if (e.target.value === 'year') {
            let currentYear = moment().year()
            this.handleMenuClick(currentYear, 'year')
            this.setState({
                currentTab: e.target.value,
                year: currentYear
            })
        } else {
            this.onChange(moment())
            this.setState({
                currentTab: e.target.value,
            })
        }
    }

    getNavMenuItems = (menusData, parent) => {
        if (!menusData) {
            return []
        }
        return menusData
            .map(item => {
                return <Menu.Item key={item} >{item}</Menu.Item>
            })
            .filter(item => item)
    };

    handleMenuClick = async (value, parent) => {
        let { graphprops} = this.state
        let currentYear = this.state.year
        if (parent === 'month') {
            let month = moment(value, 'MMMM').month()
            let graphpropsProps = {
                ...graphprops,
                startDate: moment([currentYear, month]).startOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment([currentYear, month]).endOf('month') === moment() ?
                    moment([currentYear, month]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss') :
                    moment([currentYear, month]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
                duration: '1 day',
                durationType: 'monthly',
            }
            let chartData = await historicChartData1(graphpropsProps);
            let payload = {
                startDate: moment([currentYear, month]).startOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment([currentYear, month]).endOf('month') === moment() ?
                    moment([currentYear, month]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss') :
                    moment([currentYear, month]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
                duration: '1 day',
                durationType: 'monthly',
                month:value,
                year: currentYear
            }
            let graphpropsResult = {
                ...graphpropsProps,
                currentDateData: chartData
            }
            this.setState({ currentDate: value, graphprops: graphpropsResult, month: value, payload })
        }else if(parent === 'monthYear'){
            let month = moment(this.state.month, 'MMMM').month()
         
            let graphpropsProps = {
                ...graphprops,
                startDate: moment([value, month]).startOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment([value, month]).endOf('month') === moment() ?
                    moment([value, month]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss') :
                    moment([value, month]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
                duration: '1 day',
                durationType: 'monthly',
            }
            let chartData = await historicChartData1(graphpropsProps);
            let payload = {
                startDate: moment([value, month]).startOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment([value, month]).endOf('month') === moment() ?
                    moment([value, month]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss') :
                    moment([value, month]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
                duration: '1 day',
                durationType: 'monthly',
                month:this.state.month,
                year: value
            }
            let graphpropsResult = {
                ...graphpropsProps,
                currentDateData: chartData
            }
            this.setState({  graphprops: graphpropsResult, payload,year:value })

        } else if (parent === 'year') {
            let graphpropsProps = {
                ...graphprops,
                startDate: moment([value, 0]).startOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment([value]).year() === moment().year() ?
                    moment([value, 11]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss') :
                    moment([value, 11]).endOf('month').subtract(1, 'day').utc().format('YYYY-MM-DD HH:mm:ss'),
                duration: '1 month',
                durationType: 'yearly',
               
            }
            let payload = {
                startDate: moment([value, 0]).startOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment([value]).year() === moment().year() ?
                    moment([value, 11]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss') :
                    moment([value, 11]).endOf('month').subtract(1, 'day').utc().format('YYYY-MM-DD HH:mm:ss'),
                duration: '1 month',
                durationType: 'yearly',
                year: value
            }
            let chartData = await historicChartData1(graphpropsProps);
            let graphpropsResult = {
                ...graphpropsProps,
                currentDateData: chartData
            }
            this.setState({ currentDate: value, graphprops: graphpropsResult, year: value, payload })
        }
    }

    componentWillUnmount() {
        clearInterval(this.timeTicket)
    }

    getTranslation = (keyword) => {
        let { translationCache } = this.props
        return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword
    }

    render() {
        let { currentTab, payload ,year} = this.state;
        if (moment().year() > 2020) {
            var yearList = [];
            for (var i = 2020; i <= moment().year(); i++) {
                yearList.push(i);
            }
            yearList.sort((a, b) => b - a)
        }
        return (
            <div style={{ height: '70%', marginTop: '40px' }}>

                <DatePickerSlider>

                    <Button size="large" style={{ marginLeft: "10px" }}>

                        <Link to={{ pathname: `/rubus/report/Report/Power Generation Report`, state: payload }}>
                            {this.getTranslation(constant.GetReport)}
                        </Link>
                    </Button>

                    <Radio.Group value={this.state.currentTab} onChange={this.onRadioChange} style={{ marginLeft: '20px' }} size="large">
                        <Radio.Button value="today">
                            {this.getTranslation(constant.Daily)}
                        </Radio.Button>
                        <Radio.Button value="month">
                            {this.getTranslation(constant.Month)}
                        </Radio.Button>
                        <Radio.Button value="year">
                            {this.getTranslation(constant.Year)}
                        </Radio.Button>
                    </Radio.Group>
                    {
                        currentTab === "today" ?
                            <DatePicker onChange={this.onChange} style={{ width: "300px" }} value={moment(this.state.currentDate)} disabledDate={this.disabledDate} size="large" />
                            : null
                    }
                    {
                        currentTab === "month" ?
                            <Select
                                size="large"
                                value={this.state.month}
                                onChange={(e) => this.handleMenuClick(e, "month")}
                                style={{ width: '100px' }}
                            >
                                {moment.months().map((value, index) => {
                                    if (this.state.year && this.state.year === moment().format('YYYY')) {
                                        let indexData = index + 1
                                        if (indexData > Number(moment().format('M'))) {
                                            return <Option disabled value={value}>{value}</Option>;
                                        } else {
                                            return <Option value={value}>{value}</Option>;
                                        }

                                    } else {
                                        return <Option value={value}>{value}</Option>;
                                    }
                                })}
                            </Select>

                            : null
                    }
                    {
                        currentTab === "year" ?
                            <Select
                                size="large"
                                value={this.state.year}
                                onChange={(e) => this.handleMenuClick(e, "year")}
                                style={{ width: '100px' }}
                            >
                                {
                                    yearList.map((value) => {
                                        return <Option value={value}>{value}</Option>
                                    })
                                }
                            </Select>
                            : null
                    }
                     {
                        currentTab === "month" ?
                            <Select
                                size="large"
                                value={this.state.year}
                                onChange={(e) => this.handleMenuClick(e, "monthYear")}
                                style={{ width: '100px' }}
                            >
                                {
                                    yearList.map((value) => {
                                        return <Option value={value}>{value}</Option>
                                    })
                                }
                            </Select>
                            : null
                    }
                </DatePickerSlider>
                <Bar graphprops={this.state.graphprops} currentTab={currentTab} />
            </div>
        );
    }



}
const mapStateToProps = createStructuredSelector({
    translationCache: getTranslationCache()
})


export default connect(mapStateToProps)(BarDatePicker);
