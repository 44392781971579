import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Table, Radio, DatePicker, Select, Button, Menu } from "antd";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { historicDateTableChartData } from "../ChartCalls";
import { tableTranslation } from "../../Utils/Adapter/translationAdapter";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { StyledTable, lightTheme, darkTheme, DatePickerSlider } from '../CSS/style'

import "antd/dist/antd.css";

const { Option } = Select;
let constant = {
  GetReport: 'Get Report',
  Daily: 'Daily',
  Month: 'Month',
  Year: 'Year'
}

class DateTableComponent extends React.Component {
  constructor(props) {
    super(props);
    let start = moment().format("YYYY-MM-DD 00:00:00")
    let end = moment().format("YYYY-MM-DD 23:59:59")
    this.state = {
      columns: [],
      tabledata: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      currentTab: "today",
      currentDate: moment(),
      graphprops:
        {
          ...props.graphprops,
          date: moment().format("YYYY-MM-DD"),
          startDate: moment(start, 'YYYY-MM-DD HH:mm:ss').utc().format("YYYY-MM-DD HH:mm:ss"),
          endDate: moment(end, 'YYYY-MM-DD HH:mm:ss').utc().format("YYYY-MM-DD HH:mm:ss"),
          month: moment().format("MMMM"),
          year: moment().format("YYYY"),
          days: moment().daysInMonth(),
          durationType: "daily"
        } || {},
      payload: {
        date: moment().format("YYYY-MM-DD"),
        startDate: moment(start, 'YYYY-MM-DD HH:mm:ss').utc().format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(end, 'YYYY-MM-DD HH:mm:ss').utc().format("YYYY-MM-DD HH:mm:ss"),
        month: moment().format("MMMM"),
        year: moment().format("YYYY"),
        days: moment().daysInMonth(),
        durationType: "daily"
      }
    };
  }

  columns = [];
  tabledata = [];
  config = {
    redirectTo: "counter",
  };

  timeTicket = null;

  rowUpdateInterval = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }

    this.getData();

    this.timeTicket = setInterval(() => {
      clearInterval(this.rowUpdateInterval);
      this.getData();
    }, 60000);
  }

  disabledDate = (current) => {
    return (
      current >
      moment()
        .startOf("day")
        .add(1, "day")
    );
  };

  updateData = async (data) => {
    try {
      this.columns = [];
      this.tabledata = [];
      const columnNames = Object.keys(data[0]);

      columnNames.map((value, key) => {
        if (value.length > 0) {
          this.columns.push({
            title: value,
            dataIndex: value,
            key: value,
            // render: text => <text style={{ color: 'steelblue' }}>{text}</text>
            render(text, record, key) {
              return {
                props: {
                  style: { background: Math.abs(key % 2) === 1 ? '#1a1a1a' : '#000' },
                },
                children: <text style={{ color: "grey" }}>{text}</text>,
              };
            },
          });
        }
      });

      data.map((dataval, dataindex) => {
        dataval.key = dataindex + 1;
        this.tabledata.push(dataval);
      });

      let translationTable = await tableTranslation(
        this.columns,
        this.props.translationCache,
        this.props.language
      );
      this.setState({
        columns: translationTable,
        tabledata: this.tabledata,
      });
    } catch (e) {
      this.setState({ columns: [], tabledata: [] });
    }
  };

  getData = async () => {
    let { graphprops } = this.state;
    const data = await historicDateTableChartData(graphprops, this.abortController);
    this.updateData(data);
  };

  onChange = async (date) => {
    let startDateSelected = moment(date).format('YYYY-MM-DD 00:00:00')
    let endDateSelected = moment(date).format('YYYY-MM-DD 23:59:59')
    let { graphprops } = this.state;
    let payload = {
      date: date.format("YYYY-MM-DD"),
      startDate: moment(startDateSelected).utc().format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(endDateSelected).utc().format('YYYY-MM-DD HH:mm:ss'),
      days: moment(date).daysInMonth(),
      month: moment(date).format("MMMM"),
      year: moment(date).format("YYYY"),
      durationType: "daily"
    };
    let graphpropsResult = {
      ...graphprops,
      ...payload,
      currentDateData: chartData
    }
    let chartData = await historicDateTableChartData(graphpropsResult, this.abortController);
    this.setState({ currentDate: date, graphprops: graphpropsResult, payload })
    this.updateData(chartData);
  };

  componentWillUnmount() {
    clearInterval(this.timeTicket);
    this.abortController.abort()
  }

  onRadioChange = (e) => {
    if (e.target.value === "month") {
      let currentYear = moment().format("YYYY");
      let currentMonth = moment().format("MMMM");
      this.handleMenuClick(currentMonth, "month");
      this.setState({
        currentTab: e.target.value,
        month: currentMonth,
        year: currentYear,
      });
    } else if (e.target.value === "year") {
      let currentYear = moment().year();
      this.handleMenuClick(currentYear, "year");
      this.setState({
        currentTab: e.target.value,
        year: currentYear,
      });
    } else {
      this.onChange(moment());
      this.setState({
        currentTab: e.target.value,
      });
    }
  };

  getNavMenuItems = (menusData, parent) => {
    if (!menusData) {
      return [];
    }
    return menusData
      .map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
      })
      .filter((item) => item);
  };

  handleMenuClick = async (value, parent) => {
    let { graphprops } = this.state;

    if (parent === "month") {
      let currentYear = graphprops && graphprops.year ? graphprops.year : moment().year();
      let month = moment(value, "MMMM").month();
      let payload = {
        startDate: moment([currentYear, month]).startOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment([currentYear, month]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
        timeBucket: '1d',
        durationType: "month",
        date: null,
        month: moment([currentYear, month])
          .startOf("month")
          .format("MMMM"),
        days: moment([currentYear, month]).daysInMonth(),
        year: currentYear
      };

      let graphpropsResult = {
        ...graphprops,
        ...payload
      };

      this.setState({
        currentYear: value,
        graphprops: graphpropsResult,
        month: value,
        payload
      });
      //  this.getData();
      const data = await historicDateTableChartData(graphpropsResult, this.abortController);
      this.updateData(data);
    } else if (parent === 'monthYear') {

      let currentYear = value ? value : moment().year();
      let month = moment(this.state.month, "MMMM").month();
      let payload = {
        startDate: moment([currentYear, month]).startOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment([currentYear, month]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
        timeBucket: '1d',
        durationType: "month",
        date: null,
        month: moment([currentYear, month])
          .startOf("month")
          .format("MMMM"),
        days: moment([currentYear, month]).daysInMonth(),
        year: value
      };

      let graphpropsResult = {
        ...graphprops,
        ...payload
      };

      this.setState({
        graphprops: graphpropsResult,
        year: value,
        payload
      });
      //  this.getData();
      const data = await historicDateTableChartData(graphpropsResult, this.abortController);
      this.updateData(data);

    } else if (parent === "year") {
      let payload = {
        date: null,
        month: null,
        year:
          moment([value]).year() === moment().year()
            ? moment().utc().format("YYYY")
            : moment([value, 11])
              .endOf("month").utc()
              .format("YYYY"),
        days:
          moment([value]).year() === moment().year()
            ? moment().daysInMonth()
            : moment([value, 11]).daysInMonth(),


        startDate: moment([value, 0]).startOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment([value, 11]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
        durationType: "year"
      };

      let graphpropsResult = {
        ...graphprops,
        ...payload
      };
      this.setState({ graphprops: graphpropsResult, year: value, payload });
      // this.getData(graphpropsResult);
      const data = await historicDateTableChartData(graphpropsResult, this.abortController);
      this.updateData(data);
    }
  };

  getTranslation = (keyword) => {
    let { translationCache } = this.props
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword
  }

  render() {
    let theme = this.props.selectedThemeing;
    let { currentTab, payload } = this.state;
    let yearList = [];
    if (moment().year() > 2020) {
      for (var i = 2020; i <= moment().year(); i++) {
        yearList.push(i);
      }
      yearList.sort((a, b) => b - a)
    }
    return (
      <div style={{ height: "70%", marginTop: "40px" }}>
        <DatePickerSlider>
          <Button size="large" style={{ marginLeft: "10px" }}>
            <Link to={{ pathname: `/rubus/report/Report/Power Sales Report`, state: payload }}>
              {this.getTranslation(constant.GetReport)}
            </Link>
          </Button>
          <Radio.Group
            defaultValue={this.state.currentTab}
            onChange={this.onRadioChange}
            style={{ marginLeft: "20px" }}
            size="large"
          >
            <Radio.Button value="today">
              {this.getTranslation(constant.Daily)}
            </Radio.Button>
            <Radio.Button value="month">
              {this.getTranslation(constant.Month)}
            </Radio.Button>
            <Radio.Button value="year">
              {this.getTranslation(constant.Year)}
            </Radio.Button>
          </Radio.Group>
          {currentTab === "today" ? (
            <DatePicker
              onChange={this.onChange}
              value={moment(this.state.currentDate)}
              style={{ width: "300px" }}
              disabledDate={this.disabledDate}
              size="large"
            />
          ) : null}
          {currentTab === "month" ? (
            <Select
              size="large"
              value={this.state.month}
              onChange={(e) => this.handleMenuClick(e, "month")}
              style={{ width: "100px" }}
            >
              {moment.months().map((value, index) => {
                if (this.state.year && this.state.year === moment().format('YYYY')) {
                  let indexData = index + 1
                  if (indexData > Number(moment().format('M'))) {
                    return <Option disabled value={value}>{value}</Option>;
                  } else {
                    return <Option value={value}>{value}</Option>;
                  }

                } else {
                  return <Option value={value}>{value}</Option>;
                }
              })}
            </Select>
          ) : null}
          {currentTab === "year" ? (
            <Select
              size="large"
              value={this.state.year}
              onChange={(e) => this.handleMenuClick(e, "year")}
              style={{ width: "100px" }}
            >
              {yearList.map((value) => {
                return <Option value={value}>{value}</Option>;
              })}
            </Select>
          ) : null}
          {currentTab === "month" ? (
            <Select
              size="large"
              value={this.state.year}
              onChange={(e) => this.handleMenuClick(e, "monthYear")}
              style={{ width: "100px" }}
            >
              {yearList.map((value) => {
                return <Option value={value}>{value}</Option>;
              })}
            </Select>
          ) : null}
        </DatePickerSlider>


        <StyledTable theme={theme === "lightTheme" ? lightTheme : darkTheme}>
          <Table
            // scroll={{ x: this.tabledata.length > 5 ? true : false }}
            className="TableWidget"
            style={{ padding: "0px 10px" }}
            columns={this.state.columns}
            dataSource={this.state.tabledata}
            scroll={{ x: 140, y: 300 }}
            pagination={false}
          />
        </StyledTable>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(DateTableComponent);
