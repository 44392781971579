const set = function (key, value) {
  localStorage[key] = value
  return localStorage[key]
}
const get = function (key, defaultValue) {
  return localStorage[key] || defaultValue
}
const setObject = function (key, value) {
  localStorage[key] = JSON.stringify(value)
  return localStorage[key]
}
const getObject = function (key) {
  return JSON.parse(localStorage[key] || '{}')
}
const clear = function () {
  return localStorage.clear()
}
const remove = function (key) {
  return localStorage.removeItem(key)
}

export default {
  set,
  get,
  setObject,
  getObject,
  clear,
  remove
}
