import React, { Component} from "react";

import { Input, Form, Select, Collapse, Button, message } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./reportConfig.css";
import ChildrenColumnCustomization from "./ChildrenColumnCustomization";
const uuid = require("uuid");

let { Option } = Select;
let { Panel } = Collapse;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  // width: 303,
  marginTop: 14,
  marginBottom: 14,
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging ? "lightgrey" : "grey",
  ...draggableStyle,
});

class ColumnCustomization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editObj: {},
      activeKey: [],
      dragDisabled: false,
      columnsProperties: props.columnsProperties || [],
    };
    this.columns = {};
  }

  componentWillMount() {}

  componentDidUpdate(prevprops) {
    if (this.props.columnsProperties !== prevprops.columnsProperties) {
      this.setState({
        columnsProperties: this.props.columnsProperties,
      });
    }
  }

  onDragEnd = (result) => {
    const { columnsProperties } = this.state;
    if (!result.destination) {
      return;
    }
    const columnsPropertiess = reorder(
      columnsProperties,
      result.source.index,
      result.destination.index
    );
    this.setState({
      columnsProperties: columnsPropertiess,
    });
    this.props.jsonSaveObject(columnsProperties, "columns");
  };

  handleFieldChange(value, id, colName, index) {
    const { columnsProperties } = this.state;
    columnsProperties.map((column, colIndex) => {
      if (colIndex === index) {
        if (column.id === id) {
          column[colName] = value;
        }
      }
    });

    this.setState({
      columnsProperties,
    });
  }
  submitChildren = (children, index) => {
    let { columnsProperties } = this.state;
    columnsProperties[index]["children"] = children;
    this.setState({ columnsProperties });
  };

  columnValidation = (columnsProps, id, index) => {
    const { columnsProperties } = this.state;
    columnsProps.map((column, colIndex) => {
      if (index === colIndex) {
        if (column.id === id) {
          if (column["children"]) {
            if (column.dataIndex === "" || column.key === "") {
              message.error("Please Enter dataIndex & key");
            } else if (column.dataIndex !== "" && column.key !== "") {
              this.props.jsonSaveObject(columnsProperties, "columns");
              message.success("Column Validated Successfully");
              this.setState({
                activeKey: [],
              });
            }
          } else {
            if (
              column.title === "" ||
              column.dataIndex === "" ||
              column.key === ""
            ) {
              message.error("Please Enter All These Fields");
            } else if (
              column.title !== "" &&
              column.dataIndex !== "" &&
              column.key !== ""
            ) {
              this.props.jsonSaveObject(columnsProperties, "columns");
              message.success("Columns Validated Successfully");
              this.setState({
                activeKey: [],
              });
            }
          }
        }
      }
    });
  };

  deleteColumn = (e, index) => {
    let { columnsProperties } = this.state;
    columnsProperties = columnsProperties.filter(function(e, i) {
      return i !== index;
    });
    this.props.jsonSaveObject(columnsProperties, "columns");
    message.success("Column Deleted");
    this.setState({
      activeKey: [],
    });
  };

  callback = (key) => {
    if (key && key.length <= 1) {
      this.setState({
        activeKey: key,
      });
    } else if (key.length > 1) {
      key = key[key.length - 1];
      this.setState({
        activeKey: [key],
      });
    }
  };
  addChildren = (id, index) => {
    let { columnsProperties } = this.state;
    let childrensArray = [];
    columnsProperties.map((item, colIndex) => {
      if (colIndex === index) {
        if (item.id === id) {
          if (item.title === "" || item.dataIndex === "" || item.key === "") {
            message.error("Please Enter All These Fields");
          } else {
            if (item["children"]) {
              let newObj = {
                id: uuid(),
                title: "Children",
                dataIndex: "",
                key: "",
                width: "100",
                className: "",
                background: "",
              };

              item["children"].push(newObj);
            } else {
              let newObj = {
                id: uuid(),
                title: "Children",
                dataIndex: "",
                key: "",
                width: "100",
                className: "",
                background: "",
              };
              childrensArray.push(newObj);
              columnsProperties[index]["children"] = childrensArray;
            }
          }
        }
      }
    });

    this.setState({ columnsProperties });
  };

  render() {
  const { columnsProperties, dragDisabled, activeKey } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
    };
   
    return (
      <div className="column-div" style={{ marginBottom: "50px",width:'50%' }}>
        {columnsProperties ? (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {columnsProperties.map((item, index) => (
                    <Draggable
                      key="New Column"
                      draggableId="New Column"
                      index={index}
                      isDragDisabled={dragDisabled}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <Collapse
                            bordered
                            activeKey={activeKey}
                            onChange={this.callback}
                          >
                            <Panel header={item.title} key={index}>
                              <Form {...formItemLayout}>
                                <Form.Item label="Title :" name="title">
                                  <Input
                                    value={item.title}
                                    onChange={(e) =>
                                      this.handleFieldChange(
                                        e.target.value,
                                        item.id,
                                        "title",
                                        index
                                      )
                                    }
                                  />
                                </Form.Item>
                                <Form.Item label="DataIndex :">
                                  <Input
                                    value={item.dataIndex}
                                    onChange={(e) =>
                                      this.handleFieldChange(
                                        e.target.value,
                                        item.id,
                                        "dataIndex",
                                        index
                                      )
                                    }
                                  />
                                </Form.Item>
                                <Form.Item label="Key :">
                                  <Input
                                    value={item.key}
                                    onChange={(e) =>
                                      this.handleFieldChange(
                                        e.target.value,
                                        item.id,
                                        "key",
                                        index
                                      )
                                    }
                                  />
                                </Form.Item>
                                <Form.Item label="Width :">
                                  <Input
                                    style={{ width: "250px" }}
                                    value={item.width}
                                    type="number"
                                    onChange={(e) =>
                                      this.handleFieldChange(
                                        e.target.value,
                                        item.id,
                                        "width",
                                        index
                                      )
                                    }
                                  ></Input>
                                </Form.Item>
                                <Form.Item label="ClassName :">
                                  <Select
                                    style={{ width: "250px" }}
                                    value={item.className}
                                    onSelect={(value) =>
                                      this.handleFieldChange(
                                        value,
                                        item.id,
                                        "className",
                                        index
                                      )
                                    }
                                  >
                                    <Option value="table-column">
                                      {" "}
                                      TABLE-COLUMN{" "}
                                    </Option>
                                  </Select>
                                </Form.Item>
                                <Button
                                  type="primary"
                                  style={{
                                    marginLeft: "15%",
                                    marginBottom: "20px",
                                    marginTop: "15px",
                                    border: true,
                                  }}
                                  onClick={(e) =>
                                    this.addChildren(item.id, index)
                                  }
                                >
                                  Add Children
                                </Button>

                                <Button
                                  type="primary"
                                  style={{ marginLeft: "10px" }}
                                  onClick={(e) =>
                                    this.columnValidation(
                                      columnsProperties,
                                      item.id,
                                      index
                                    )
                                  }
                                >
                                  {" "}
                                  Save
                                </Button>
                                {columnsProperties[index]["children"] ? (
                                  <div>
                                    <ChildrenColumnCustomization
                                      childProperties={
                                        columnsProperties[index]["children"]
                                      }
                                      submitChildren={this.submitChildren}
                                      index={index}
                                    />
                                  </div>
                                ) : null}
                              </Form>
                              <div className="configButton">
                                <Button
                                  type="danger"
                                  onClick={(e) => this.deleteColumn(e, index)}
                                >
                                  Delete
                                </Button>
                              </div>
                            </Panel>
                          </Collapse>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : null}
      </div>
    );
  }
}
export default Form.create()(ColumnCustomization);
