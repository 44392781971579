import styled, { keyframes } from 'styled-components'
import { Button, List } from 'antd'
import { Link } from 'react-router-dom'
import { zoomIn } from 'react-animations'

const bounceAnimation = keyframes`${zoomIn}`

export const StyledDashboard = styled.div`
background: white;
// margin: 20px;
padding: 30px;
`
export const StyledAnimation = styled(List)`
animation: 1s ${bounceAnimation};
animation-duration: 2s
`
export const DrawerFooter = styled.div`
position: absolute;
left: 0;
bottom: 0;
width: 100%;
border-top: 1px solid #e9e9e9;
padding: 10px 16px;
background: #fff;
text-align: right;
`
export const StyledAddButton = styled(Button)`
margin-left: 30px;
`
export const AddTableButton = styled(Button)`
margin-bottom: 20px;
`
export const StyledIFrame = styled.iframe`
width: 402%;
height: 400%;
border: 0;
position: absolute;
top: 0;
left: -0.5%;
background: #191a1d;
-webkit-transform: scale(0.5);
transform: scale(0.25);
-webkit-transform-origin: top left;
transform-origin: top left;
-webkit-transition: opacity 0.4s ease;
transition: opacity 0.4s ease;
margin: 2px auto;
`

export const LinkOverlay = styled(Link)`
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: 1;  
:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
}
`
