import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Tag} from "antd";
import moment from "moment";

import ReactEcharts from "../Echarts/Echarts";
import {
  getChartDataWithoutSelection,
  getChartDataWithSelection,
} from "../ChartCalls";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"

import { makeSelectThemeing } from "../../../../selectors/theme";
import { candleStickBrushMock } from "../../Mock/candleStick";
import { defaultRefreshTime } from '../constant.json'
import { Fragment } from "react";

const upColor = "#00da3c";
const downColor = "#ec0000";

class CandleStickBrush extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if(this.requestCheck){
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear
    ) {
      this.getData(this.props, this.props.selectedThemeing);
    }
  }

  getData = async (props, theme) => {
    this.requestCheck = false
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false 
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = candleStickBrushMock;
      this.requestCheck = true
    } else {

    if (
      (graphprops &&
        graphprops.dashboardType === "dynamic" &&
        this.props.deviceId) ||
      (graphprops &&
        graphprops.dateExists === "true" &&
        this.props.selectionDate) ||
      (graphprops &&
        graphprops.monthExists === "true" &&
        this.props.selectionMonth) ||
      (graphprops &&
        graphprops.weekExists === "true" &&
        this.props.selectionWeek) ||
      (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
      (graphprops &&
        graphprops.dateRangeExists === "true" &&
        this.props.selectionDateRange) ||
      (graphprops &&
        graphprops.dayMonthYearExists === "true" &&
        this.props.dayMonthYear)
    ) {
      json = await getChartDataWithSelection(
        graphprops,
        this.props.deviceId,
        this.props.selectionDate,
        this.props.selectionMonth,
        this.props.selectionWeek,
        this.props.shift,
        this.props.selectionDateRange,
        this.props.dayMonthYear,
        this.abortController
      );
      this.requestCheck = true
    } else {
      json = await getChartDataWithoutSelection(graphprops, this.abortController);
      this.requestCheck = true
    }
    }
    if ((json && json.message) || (Array.isArray(json) && (json.length === 0) && 
  (graphprops.enableMock !== true))) {

    if (json.message) {
      errorInAPI = true
    } else if (json.length === 0) {
      noDataInDB = true
    }

  } else {
    if (json && Array.isArray(json)) {
      json = candleStickBrushMock;

      let data = splitData(json);
      let option = this.getOption(data);
      this.setState({ option });
    }else{
      json = candleStickBrushMock;
      let data = splitData(json);
      let option = this.getOption(data);
      this.setState({
         option ,
         refreshDateTime:moment()
        });
  
    }
  };
  this.setState({
    errorInAPI,
    noDataInDB
  })
};

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  render() {
    const {errorInAPI, noDataInDB,refreshDateTime } = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
            <NoRecordComponent/>
            : <ErrorComponent/>
            :
            <ReactEcharts
        option={this.state.option}
        style={{ height: "90%", width: "100%" }}
        className="react_for_echarts"
      />
        }
      </Fragment>
      
    );
  }

  getOption = (data) => {
    let theme = this.props.selectedThemeing;
    let option = {
      animation: false,
      legend: {
        bottom: 10,
        left: "center",
        textStyle: {
          color: theme === "lightTheme" ? "black" : "white",
          fontSize: 18,
          fontWeight:"bold",
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
        backgroundColor: "rgba(245, 245, 245, 0.8)",
        borderWidth: 1,
        borderColor: "#ccc",
        padding: 10,
        textStyle: {
          color: "#000",
        },
        position: function(pos, params, el, elRect, size) {
          var obj = { top: 10 };
          obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          return obj;
        },
        // extraCssText: 'width: 170px'
      },
      axisPointer: {
        link: { xAxisIndex: "all" },
        label: {
          backgroundColor: "#777",
        },
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: false,
          },
          brush: {
            type: ["lineX", "clear"],
          },
        },
      },
      brush: {
        xAxisIndex: "all",
        brushLink: "all",
        outOfBrush: {
          colorAlpha: 0.1,
        },
      },
      visualMap: {
        show: false,
        seriesIndex: 5,
        dimension: 2,
        pieces: [
          {
            value: 1,
            color: downColor,
          },
          {
            value: -1,
            color: upColor,
          },
        ],
      },
      grid: [
        {
          left: "10%",
          right: "8%",
          height: "50%",
        },
        {
          left: "10%",
          right: "8%",
          top: "63%",
          height: "16%",
        },
      ],
      xAxis: [
        {
          type: "category",
          data: data && data.categoryData,
          scale: true,
          boundaryGap: false,

          splitLine: { show: false },
          splitNumber: 20,
          min: "dataMin",
          max: "dataMax",
          axisPointer: {
            z: 100,
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: theme === "lightTheme" ? "black" : "white",
            },
          },
          axisTick: {
            lineStyle: {
              color: theme === "lightTheme" ? "black" : "white",
            },
          },
          axisLabel: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        {
          type: "category",
          gridIndex: 1,
          data: data && data.categoryData,
          scale: true,
          boundaryGap: false,
          axisLine: { onZero: false },
          axisTick: { show: false },
          splitLine: { show: false },
          axisLabel: { show: false },
          splitNumber: 20,
          min: "dataMin",
          max: "dataMax",
        },
      ],
      yAxis: [
        {
          scale: true,
          splitArea: {
            show: true,
          },
          axisLine: {
            lineStyle: {
              color: theme === "lightTheme" ? "black" : "white",
            },
          },
        },
        {
          scale: true,
          gridIndex: 1,
          splitNumber: 2,
          axisLabel: { show: false },
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
        },
      ],
      dataZoom: [
        {
          type: "inside",
          xAxisIndex: [0, 1],
          start: 0,
          end: 100,
        },
        {
          show: true,
          xAxisIndex: [0, 1],
          type: "slider",
          top: "85%",
          start: 0,
          end: 100,
        },
      ],
      series: [
        {
          name: "index",
          type: "candlestick",
          data: data && data.values,
          itemStyle: {
            color: upColor,
            color0: downColor,
            borderColor: null,
            borderColor0: null,
          },
          tooltip: {
            formatter: function(param) {
              param = param[0];
              return [
                "Date: " + param.name + '<hr size=1 style="margin: 3px 0">',
                "Open: " + param.data[0] + "<br/>",
                "Close: " + param.data[1] + "<br/>",
                "Lowest: " + param.data[2] + "<br/>",
                "Highest: " + param.data[3] + "<br/>",
              ].join("");
            },
          },
        },
        {
          name: "MA5",
          type: "line",
          data: calculateMA(5, data),
          smooth: true,
          lineStyle: {
            opacity: 0.5,
          },
        },
        {
          name: "MA10",
          type: "line",
          data: calculateMA(10, data),
          smooth: true,
          lineStyle: {
            opacity: 0.5,
          },
        },
        {
          name: "MA20",
          type: "line",
          data: calculateMA(20, data),
          smooth: true,
          lineStyle: {
            opacity: 0.5,
          },
        },
        {
          name: "MA30",
          type: "line",
          data: calculateMA(30, data),
          smooth: true,
          lineStyle: {
            opacity: 0.5,
          },
        },
        {
          name: "Volume",
          type: "bar",
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: data && data.volumes,
        },
      ],
    };
    return option;
  };
}

function splitData(rawData) {
  var categoryData = [];
  var values = [];
  var volumes = [];
  for (var i = 0; i < rawData.length; i++) {
    categoryData.push(rawData[i].splice(0, 1)[0]);
    values.push(rawData[i]);
    volumes.push([i, rawData[i][4], rawData[i][0] > rawData[i][1] ? 1 : -1]);
  }

  return {
    categoryData: categoryData,
    values: values,
    volumes: volumes,
  };
}

function calculateMA(dayCount, data) {
  var result = [];
  for (var i = 0, len =data && data.values.length; i < len; i++) {
    if (i < dayCount) {
      result.push("-");
      continue;
    }
    var sum = 0;
    for (var j = 0; j < dayCount; j++) {
      sum += data.values[i - j][1];
    }
    result.push(+(sum / dayCount).toFixed(3));
  }
  return result;
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(CandleStickBrush);
