import React from "react"
import moment from "moment"
import { DatePicker } from 'antd'

import Slider from './Slider'
import { DatePickerSlider } from '../CSS/style'
const { RangePicker } = DatePicker

class DateRangeSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphprops: {
        ...props.graphprops,
        dateRangeExists: 'true'
      } || {},
      initialRender: true,
      deviceId: props.deviceId || undefined,
      selectionDateRange: {
        startDate: moment().subtract(1, "hour").format('YYYY-MM-DD HH:mm'),
        endDate: moment().format('YYYY-MM-DD HH:mm'),
        timeBucket: '1m'
      }
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();


  componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.timeTicket = setInterval(() => {
      let { initialRender } = this.state
      if (initialRender) {
        this.setState({
          selectionDateRange: {
            startDate: moment().subtract(1, "hour").format('YYYY-MM-DD HH:mm'),
            endDate: moment().format('YYYY-MM-DD HH:mm'),
            timeBucket: '1m'
          }
        })
      }
    }, 60000);
  }

  componentDidUpdate(prevProps) {

    if (
      // prevProps.graphprops !== this.props.graphprops ||
      // prevProps.selectionDate !== this.props.selectionDate ||
      // prevProps.selectionMonth !== this.props.selectionMonth ||
      // prevProps.selectionWeek !== this.props.selectionWeek ||
      // prevProps.selectionDateRange !== this.props.selectionDateRange ||
      // prevProps.shift !== this.props.shift ||
      prevProps.deviceId !== this.props.deviceId
    ) {
      this.setState({
        deviceId: this.props.deviceId
      })
    }
  }


  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  onDateRangePickerChange = (currentDate, dateString) => {
    // let timeBucket = ""
    let selectionDateRange = {}
    if (currentDate && currentDate[0] && currentDate[1]) {
      // let dayCount = currentDate[1].diff(currentDate[0], 'days')
      // let hourCount = currentDate[1].diff(currentDate[0], 'hours')

      // if (dayCount <= 2) {
      //   if (hourCount <= 1) {
      //     timeBucket = '1m'
      //   } else if (hourCount > 1 && hourCount <= 2) {
      //     timeBucket = '5m'
      //   } else if (hourCount >= 3 && hourCount <= 23) {
      //     timeBucket = '15m'
      //   } else {
      //     timeBucket = '1h'
      //   }
      // } else if (dayCount > 2 && dayCount <= 60) {
      //   timeBucket = '1d'
      // } else if (dayCount > 60) {
      //   timeBucket = '1week'
      // } else {
      //   timeBucket = ''
      // }

      selectionDateRange = {
        startDate: currentDate[0].format('YYYY-MM-DD HH:mm:ss'),
        endDate: currentDate[1].format('YYYY-MM-DD HH:mm:ss'),
        timeBucket: "1m"
      }

      // } else {
      // selectionDateRange = {
      //   startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      //   endDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      //   timeBucket: '1m'
      // }
    }
    this.setState({
      selectionDateRange,
      initialRender: false
    })

  }

  disabledDate = (current) => {
    return current > moment().endOf('day');
  }
  handleOpenChange = open => {
    if (open) {
      this.setState({ mode: 'time' });
    }
  };

  handlePanelChange = (value, mode) => {
    this.setState({ mode });
  };


  render() {
    let { selectionDateRange } = this.state
    return (
      <div style={{ height: '70%', marginTop: '40px' }}>
        <DatePickerSlider>
          <RangePicker
            format="YYYY-MM-DD HH:mm"
            showTime
            placeholder={["Start Date", "End Date"]}
            onChange={this.onDateRangePickerChange}
            disabledDate={this.disabledDate}
            value={[moment(selectionDateRange.startDate), moment(selectionDateRange.endDate)]}
            ranges={{
              Today: [moment().startOf('day'), moment()],
              'This Month': [moment().startOf('month'), moment()],
              'Last Month': [moment().subtract(1, "month").startOf('month'), moment().subtract(1, "month").endOf('month')],
            }}
            style={{
              width: "400px",
              cursor: "pointer",
            }}
          />
        </DatePickerSlider>
        <Slider graphprops={this.state.graphprops} deviceId={this.state.deviceId} selectionDateRange={this.state.selectionDateRange} initialRender={this.state.initialRender} />
      </div>

    );
  }

}
export default DateRangeSlider
