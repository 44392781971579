import React, { Component } from 'react'
import axios from 'axios'
import MonacoEditor from 'react-monaco-editor'
import {
    Button, Table, Drawer, Row, Input, Col, Select, Form, Icon
} from 'antd';

import { getUserProfile } from "../../../selectors/layout";
import { createStructuredSelector } from "reselect";
import localStorage from '../../../utils/localStorage'
import { connect } from "react-redux";


const { Option } = Select
const columns = [
    {
        title: 'ID',
        dataIndex: 'Id',
        key: 'Id',
    },
    {
        title: 'SiteId',
        dataIndex: 'SiteId',
        key: 'SiteId',
    },

];
class HierarchyUpload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns,
            visible: false,
            theme: "vs-dark",
            editObj: {},
            datasource: [],
            editEditor: {},
            listType: "",
            userProfile: props && props.userProfile ? props.userProfile : {},
        }
    }
    componentWillMount() {
        this._getHierarchyList()
        this.siteList()
    }

    siteList = () => {
        let sitesArray = [];
        let sitesListArray =
            this.props &&
            this.props.userProfile &&
            this.props.userProfile.belongsTo &&
            this.props.userProfile.belongsTo.sites[1] && this.props.userProfile.belongsTo.sites;

        sitesArray = sitesListArray && Object.keys(sitesListArray).map((key) => {
            return sitesListArray[key]
        });
        this.setState({
            sitesArray
        })
    }

    componentDidUpdate(prevprops) {
        if (this.props.userProfile !== prevprops.userProfile) {
            this.siteList()
        }
    }
    onClose = () => {
        this.setState({
            visible: false,
        });
    }
    addNewHierarchy = () => {
        this.props.form.resetFields();
        this.setState({
            visible: true,
            editObj: {},
            listType: "save",
            editEditor: {}
        })
    }
    _getHierarchyList = () => {
        const siteId = localStorage.get('currentSite')
        let accessToken = localStorage.get('accessToken')
        const healthHeaders = {
            method: 'GET',
            url: `/api/hierarchy/list?SiteId=${siteId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },


        }
        axios(healthHeaders)
            .then((response) => {
                this.setState({ datasource: response.data, visible: false })
            }).catch(error => {

            })
    }
    _saveHierarchyAPI = (data) => {
        const accessToken = localStorage.get('accessToken')
        const deviceTypeObject = {
            method: 'POST',
            url: `/api/hierarchy/create`,
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            data
        }
        axios(deviceTypeObject)
            .then(() => {
                this.setState({
                    visible: false
                })
                this._getHierarchyList()
            })
            .catch(function () {

            })
    }
    _deleteHmiAPI = (payload) => {

        const accessToken = localStorage.get('accessToken')
        const deviceTypeObject = {
            method: 'DELETE',
            url: `/api/hierarchy/delete?Id=${payload}`,
            headers: {
                Authorization: `Bearer ${accessToken}`
            },

        }
        axios(deviceTypeObject)
            .then((response) => {
                this.setState({
                    visible: false
                })
                this._getHierarchyList()
            })
            .catch(function () {

            })
    }

    saveHMI = (operation) => {
        if (operation === 'delete') {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    let Id = values.ID
                    this._deleteHmiAPI(Id)
                }
            })
        } else if (operation === 'update') {
            this.props.form.validateFields((err, values) => {
                const model = this.refs.monaco.editor.getModel();
                const value = model.getValue();
                if (typeof JSON.parse(value) !== 'string') {
                    let updateObj = {
                        "SiteId": values.siteId,
                        "Hierarchy": JSON.parse(value)
                    }
                    this._saveHierarchyAPI(updateObj)
                } else {
                    alert("Error")
                }
            })
        }
        else if (operation === 'save') {
            this.props.form.validateFields((err, values) => {
                const model = this.refs.monaco.editor.getModel();
                const value = model.getValue();

                if (typeof JSON.parse(value) !== 'string') {
                    let saveObj = {
                        "SiteId": values.siteId,
                        "Hierarchy": JSON.parse(value),
                    }
                    this._saveHierarchyAPI(saveObj)
                } else {
                    alert("Error")
                }
            })
        }

    }

    _saveHierarchyAPI = (payload) => {
        const accessToken = localStorage.get('accessToken')
        const deviceTypeObject = {
            method: 'POST',
            url: `/api/hierarchy/create`,
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            data: payload
        }
        axios(deviceTypeObject)
            .then((res, resp) => {
                this.setState({
                    visible: false
                })
                this._getHierarchyList()
            })
            .catch(function () {

            })
    }



    editorDidMount = (editor) => {
        setTimeout(function () {
            editor.getAction('editor.action.formatDocument').run();
        }, 300);
    }

    editorWillMount = (monaco) => {
        monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
        });
    }


    render() {
        let { columns, datasource, editEditor, editObj, listType, sitesArray } = this.state;
        const { getFieldDecorator } = this.props.form
        const options = {
            selectOnLineNumbers: false
        }
        return (
            <div style={{ minHeight: window.innerHeight - 300 }}>
                <Drawer
                    title="Create a new HMI configuration"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{ paddingBottom: 80 }}
                >
                    <Form layout="vertical" hideRequiredMark>
                        <Row gutter={16}>

                            <Col span={24}>
                                {listType === "save" ? <div /> : <Form.Item label="ID :">
                                    {getFieldDecorator('ID', {
                                        initialValue: editObj.Id,
                                        rules: [{ required: true, message: "Please enter ID" }]
                                    })(
                                        <Input placeholder="Please enter ID"
                                            disabled={!editObj.Id ? false : true} />
                                    )}
                                </Form.Item>}

                                <Form.Item label="SiteID :">
                                    {getFieldDecorator('siteId', {
                                        initialValue: editObj.SiteId,
                                        rules: [{ required: true, message: "Please enter Site ID" }]
                                    })(
                                        <Select placeholder="Please Select Site ID" >
                                            {
                                                sitesArray && sitesArray.map((item) => (
                                                    <Option value={item.site.Id}>{item.site.Name}</Option>
                                                ))

                                            }

                                        </Select>
                                    )}


                                </Form.Item>

                            </Col>
                        </Row>
                    </Form>

                    <div>Enter Hierarchy JSON</div>
                    <MonacoEditor
                        width="680"
                        height="650"
                        language="json"
                        theme="vs-dark"

                        defaultValue={JSON.stringify(editEditor)}
                        options={options}
                        ref="monaco"
                        value={JSON.stringify(editEditor, null, '\t')}
                        editorWillMount={this.editorWillMount}
                        editorDidMount={this.editorDidMount}
                    />
                    {this.state.listType === 'save' ?
                        <Button type="primary" shape="round" onClick={() => this.saveHMI('save')} ghost style={{ marginRight: '10px', background: 'rgb(190, 200, 200)', marginTop: '10px' }}>
                            Save
                        </Button>
                        :
                        <Button type="primary" shape="round" onClick={() => this.saveHMI('update')} ghost style={{ background: 'rgb(190, 200, 200)', marginTop: '10px' }}>
                            Update
                        </Button>
                    }


                    {
                        <Button type="primary" shape="round" onClick={() => this.saveHMI('delete')} ghost style={{ background: 'rgb(190, 200, 200)', marginTop: '10px' }}>
                            Delete
                        </Button>
                    }
                </Drawer>
                <Button type="primary" onClick={this.addNewHierarchy} style={{ marginBottom: '10px' }}>  <Icon type="plus" />  Create Hierarchy</Button>
                <Table
                    columns={columns}
                    dataSource={datasource}
                    onRow={(rowData) => {

                        return {
                            onClick: () => {
                                this.setState({
                                    visible: true,
                                    editEditor: rowData.Hierarchy,
                                    editObj: rowData,
                                    listType: "update"
                                })
                            },
                        };
                    }}
                    pagination={false}
                />

            </div>

        )
    }
}

const mapStateToProps = createStructuredSelector({
    userProfile: getUserProfile(),
});

export default connect(mapStateToProps)(Form.create()(HierarchyUpload));

