import React from "react";
import moment from "moment";
import cloneDeep from "lodash.clonedeep";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Tag} from "antd";
import ReactEcharts from "../Echarts/Echarts";
import { getChartDataWithoutSelection, getChartDataWithSelection } from "../ChartCalls";
import { trendLabelTranslation } from "../../Utils/Adapter/translationAdapter";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import { beijingChartMockJson } from "../../Mock/MockJson";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { defaultRefreshTime } from '../constant.json'
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { Fragment } from "react";

class BeijingChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      colors: this.props.graphprops.colors,
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  data = [];
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if(this.requestCheck){
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear
    ) {
      this.getData(this.props, this.props.selectedThemeing);
    }
  }

  componentWillUnmount() {
    this.abortController.abort()
    clearInterval(this.timeTicket);
  }
  render() {
    const {errorInAPI, noDataInDB ,refreshDateTime} = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
          {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
      {
noDataInDB === true || errorInAPI === true ?
 noDataInDB === true ?
 <NoRecordComponent/>
 : <ErrorComponent/>
 :
      <ReactEcharts
        option={this.state.option}
        notMerge={true}
        lazyUpdate={true}
        style={{ height: "80%", width: "100%" }}
        className="react_for_echarts"
      />
  }
  </Fragment>
    );
  }

  getData = async (props, theme) => {
    let { graphprops } = this.props;
    this.requestCheck = false
    let json = [];
    // let { errorInAPI, noDataInDB } = this.state
    this.state.errorInAPI = false
    this.state.noDataInDB = false
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = beijingChartMockJson;
      this.requestCheck = true
    } else {

    if (
      props.graphprops.graph === "" &&
      props.graphprops.currentDateData !== undefined
    ) {
      json = props.graphprops.currentDateData;
    } else {
      if (
        (graphprops &&
          graphprops.dashboardType === "dynamic" &&
          this.props.deviceId) ||
        (graphprops &&
          graphprops.dateExists === "true" &&
          this.props.selectionDate) ||
        (graphprops &&
          graphprops.monthExists === "true" &&
          this.props.selectionMonth) ||
        (graphprops &&
          graphprops.weekExists === "true" &&
          this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
        (graphprops &&
          graphprops.dateRangeExists === "true" &&
          this.props.selectionDateRange)
        ||
        (graphprops && graphprops.dayMonthYearExists === 'true' && this.props.dayMonthYear)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
         this.props.dayMonthYear,
          this.abortController
        );
        this.requestCheck = true
      } else if (graphprops) {
        json = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true
      }
    }
  }
  if ((json && json.message )|| (Array.isArray(json) && (json.length === 0 && 
  graphprops.enableMock !== true))) {

    if (json.message) {
      this.state.errorInAPI = true
    } else if (json.length === 0) {
      this.state.noDataInDB = true
    }

  } else {
    if (json && Array.isArray(json)) {
      let dates = [];
      let values = [];
      let name = "";
      let tagnames = [];
      let seriesarray = [];
      let legendsdata = [];
      json.map((val, index) => {
        if (val.tagname !== name) {
          if (tagnames.indexOf(val.tagname) === -1) {
            tagnames.push(val.tagname);
            name = val.tagname;
            legendsdata.push({
              name: val.tag_description,
              icon: "roundRect",
              textStyle: {
                color: theme === "lightTheme" ? "black" : "white",
                fontSize: 18,
                fontWeight:"bold",
              
              },
            });
          }
        }
      });
 
      let yAxisData = [];

      if (Array.isArray(this.props.graphprops.YAxis)) {
        yAxisData = [...this.props.graphprops.YAxis];
      }

      let yaxis = yAxisData.map((value, index) => {
        let axis = {};
        axis.splitLine = {
          show: true,
          lineStyle: {
            color: "#504c4c",
          },
        };
        axis.triggerEvent = true;
        axis.axisLine = {
          lineStyle: {
            // color: "white"
            color:
              this.props.graphprops.colors[index] !== undefined
                ? value.tags.length > 1
                  ? "white"
                  : this.props.graphprops.colors[index]
                : "white",
          },
        };
        axis.max = value.max;
        axis.min = value.min;
        axis.offset = value.offset;
        axis.interval = value.interval;
        axis.position = value.position;
        axis.name = value.name;
        //  axis.nameLocation = (value.nameLocation==undefined)?"top":"center";
        axis.tags = value.tags;
        return axis;
      });

      yaxis.unshift({
        splitLine: {
          show: true,
          lineStyle: {
            color: "#504c4c",
          },
        },
        triggerEvent: true,
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        tags: [],
      });

      tagnames.map((tagval, tagindex) => {
        let tagdescription = "";
        values = [];
        json.map((jsonval, jsonindex) => {
          if (tagnames[0] === jsonval.tagname && tagindex === 0) {
            let date = graphprops && graphprops.type === "live" ? moment.utc(jsonval.interval_alias).local().format("DD/MM/YYYY HH:mm:ss") : moment(jsonval.interval_alias).format("DD/MM/YYYY HH:mm:ss")
            dates.push(date);
            // dates.push(
            //   moment(jsonval.interval_alias)
            //     .utc()
            //     .format("DD/MM/YYYY HH:mm:ss")
            // );
          }
          if (tagval === jsonval.tagname) {
            values.push(jsonval.tagvalue);
          }
          tagdescription = jsonval.tag_description;
        });
        if (this.props.graphprops.linetype === "area") {
          if (yaxis.length > 1) {
            yaxis.map((axis, axisindex) => {
              if (axis.tags.indexOf(tagval) !== -1) {
                seriesarray.push({
                  name: legendsdata[tagindex]["name"],
                  data: values,
                  type: "line",
                  yAxisIndex: axisindex,
                  areaStyle: {},
                  smooth: true,
                });
              }
            });
          } else {
            seriesarray.push({
              name: legendsdata[tagindex]["name"],
              data: values,
              type: "line",
              areaStyle: {},
              smooth: true,
            });
          }
        } else {
          if (yaxis.length > 1) {
            yaxis.map((axis, axisindex) => {
              if (axis.tags.indexOf(tagval) !== -1) {
                seriesarray.push({
                  name: legendsdata[tagindex]["name"],
                  data: values,
                  yAxisIndex: axisindex,
                  type: "line",
                });
              }
            });
          } else {
            seriesarray.push({
              name: legendsdata[tagindex]["name"],
              data: values,
              type: "line",
            });
          }
        }
      });

    
      const option = cloneDeep(this.getOption());
      let series = await trendLabelTranslation(
        seriesarray,
        this.props.translationCache,
        this.props.language
      );
      option.xAxis.data = [...dates];
      option.yAxis = [...yaxis];
      option.series = [...series];
      option.legend = [...legendsdata];
      this.setState({
         option: option,
         refreshDateTime: moment()
         });
      return option;
    
  };
}
}

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      title: {},
      color: this.props.graphprops.colors,
      tooltip: {
        trigger: "axis",
      },
      visualMap: {
        textStyle:{
          fontSize:15,
          color:theme === "lightTheme" ? "black" : "white",
      },

        top: 20,
        //right: "auto",
        left:"center",
        align:"left",
        orient: 'horizontal' ,

        
        pieces: this.props.graphprops.VisualMap,
        outOfRange: {
          color: "#999",
        },
      },
      xAxis: {
        data: this.data.map(function (item) {
          return item.date;
        }),
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisTick: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisLabel: {
          color: theme === "lightTheme" ? "black" : "white",
        },
      },
      yAxis: [],

      toolbox: {
        showTitle: false,
        orient: "vertical",
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: "download",
            backgroundColor: theme === "lightTheme" ? "black" : "white",
            name: this.props.graphprops.title,
            // backgroundColor: "black",
          },
        },
      },
      // toolbox: {
      //   showTitle: false,
      //   orient: "vertical",
      //   feature: {
      //     saveAsImage: {
      //       title: "download",
      //       name: this.props.graphprops.title,
      //       backgroundColor: "black"
      //     }
      //   }
      // },
      dataZoom: [
        {
          type: "slider",
          show: true,
          start: 0,
          end: 100,
          backgroundColor: "#9a9191",

          textStyle: {
            color: "white",
          },
        },
        {
          type: "inside",
          start: 0,
          end: 100,
        },
      ],

      series: {
        name: "value",
        type: "line",
        data: this.data.map(function (item) {
          return item.value;
        }),

        // markLine: {
        //   silent: true,
        //   data: [
        //     {
        //       yAxis: 20
        //     },
        //     {
        //       yAxis: 40
        //     },
        //     {
        //       yAxis: 60
        //     },
        //     {
        //       yAxis: 80
        //     },
        //     {
        //       yAxis: 100
        //     }
        //   ]
        // }
      },
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(BeijingChart);
