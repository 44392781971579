import React from "react";
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Tag } from "antd";
import moment from "moment";

import { getChartDataWithoutSelection, getChartDataWithSelection } from "../ChartCalls";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import { defaultRefreshTime } from '../constant.json'
import { countMock } from "../../Mock/MockJson";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { Fragment } from "react";

class Counter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = ""
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentWillUnmount() {
    this.abortController.abort()
    clearInterval(this.timeTicket);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection
    ) {
      this.getData();
    }
  }

  render() {

    const { counter, errorInAPI, noDataInDB, refreshDateTime } = this.state
    let { graphprops } = this.props
    return (

      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            this.state.boolean === true ?

              <div
                style={{
                  textAlign: "center",
                }}
              >

                <span
                  style={{
                    color: counter === "on" ? "green" : "red",
                    fontSize: this.props.graphprops.counter.fontSize
                      ? this.props.graphprops.counter.fontSize + "em"
                      : "5em",
                  }}
                >
                  {this.state.counter}
                  {this.state.uom}
                </span>

              </div>
              :
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <span
                  style={{

                    color: this.props.graphprops.counter.titleColor,
                    fontSize: this.props.graphprops.counter.fontSize
                      ? this.props.graphprops.counter.fontSize + "em"
                      : "5em",
                  }}
                >
                  {this.state.counter}
                  {""} {this.state.uom}
                </span>
              </div>

        }
      </Fragment>

    )


  }



  getData = async () => {
    this.requestCheck = false;
    let { graphprops } = this.props
    let data = []
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      data = countMock;
      this.requestCheck = true
    } else {
      if (
        (graphprops && graphprops.dashboardType === 'dynamic' && this.props.deviceId) ||
        (graphprops && graphprops.dateExists === 'true' && this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops && graphprops.dateRangeExists === 'true' && this.props.selectionDateRange) ||
        (graphprops && graphprops.dayMonthYearExists === 'true' && this.props.dayMonthYear)
      ) {
        data = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection
        )
        this.requestCheck = true;
      } else {
        data = await getChartDataWithoutSelection(graphprops, this.abortController, this.props.dropdownSelection);
        this.requestCheck = true;
      }
    }

    if ((data && data.message) || (Array.isArray(data) && (data.length === 0) &&
      (graphprops.enableMock !== true))) {

      if (data.message) {
        errorInAPI = true
      } else if (data.length === 0) {
        noDataInDB = true
      }

    } else {
      if (data && Array.isArray(data) && data.length > 0 && this.props.graphprops.counter && this.props.graphprops.counter.counterType === "number"
      ) {
        this.setState({ counter: Number(data[0].tagvalue).toFixed(2) });
      } else if (data && Array.isArray(data) && data.length > 0 && this.props.graphprops.counter.counterType === "string") {
        this.setState({
          counter: data[0].tagvalue,
          refreshDateTime: moment()
        });
      } else if (data && Array.isArray(data) && data.length > 0 && this.props.graphprops.counter && this.props.graphprops.counter.counterType === "both") {
        this.setState({ counter: Number(data[0].tagvalue).toFixed(2), uom: this.props.graphprops.counter.uom });
      } else if (data && Array.isArray(data) && data.length > 0 && this.props.graphprops.counter.counterType === "boolean") {
        this.setState({ counter: Number(data[0].tagvalue).toFixed(2), boolean: true });
      }
      this.setState({
        errorInAPI,
        noDataInDB
      })
    };
    if (data && Array.isArray(data) &&
      data[0] && data[0].tagvalue === null) {
      this.setState({ errorInAPI, noDataInDB: !noDataInDB })
    } else { this.setState({ errorInAPI, noDataInDB }) }
    // this.setState({errorInAPI,noDataInDB})
  }

}


const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache()
})


export default connect(mapStateToProps)(Counter);