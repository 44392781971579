import React from 'react';
import axios from 'axios'
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Menu, Icon, Dropdown } from 'antd';
import { withTheme } from 'styled-components';
import { createStructuredSelector } from "reselect";

import history from '../../../../../commons/history';
import localStorage from '../../../../../utils/localStorage';
import menuAdapter from '../Adapter/MenuAdapter';
import { StyledMenu } from '../styles';

import '../styles.css';
import './device.css';
import { constant } from "./Constants";


import { getCurrentLanguage, getTranslationCache } from "../../../../../selectors/language";
import { makeSelectThemeing, makeSelectTheme } from "../../../../../selectors/theme";
const { SubMenu } = Menu;

class SlideMenuComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: props.currentLanguage || 'English',
      currentMenu: '',
      userProfile: props.userProfile || {},
      loggedInRole: {},
      logoPath: '',
      rightMenuAlignment: 'right',
      selectedMenu: [] || '',
      firstLevelMenu: [],
      secondLevelMenu: [],
      menuHierarchy: props.menuHierarchy || {},
      notificationList: [],
      currentSite: localStorage.get('currentSite') || '',
      navigationMode: 'top',
      translationCache: props.translationCache || [],
      language: props.language || "English",
      languageList: []
    };
  }

  componentWillMount() {
    const width = window.innerWidth;
    if (width > 1466) {
      this.setState({ rightMenuAlignment: 'right' });
    } else {
      this.setState({ rightMenuAlignment: 'none' });
    }
    this.props.actions.setCurrentLanguage('English');
    this.getLanguageList()
  }

  componentDidUpdate(prevProps) {
    let { menuHierarchy } = this.state;
    let { selectedMenu } = this.props
    // Language & translation 
    if (prevProps.translationCache !== this.props.translationCache || prevProps.currentLanguage !== this.props.currentLanguage) {
      let firstLevelMenu = menuAdapter.firstMenuAdapter(
        menuHierarchy,
        this.props.currentLanguage,
        this.props.translationCache
      );
      this.getSecondLevelMenu(menuHierarchy, selectedMenu[0]);

      this.setState({
        menuHierarchy,
        firstLevelMenu,
      });
      this.setState({
        translationCache: this.props.translationCache, language: this.props.currentLanguage
      });
    }

    //menuHierarchy change
    if (this.props.menuHierarchy !== prevProps.menuHierarchy) {
      menuHierarchy = this.props.menuHierarchy;
      let firstLevelMenu = menuAdapter.firstMenuAdapter(
        menuHierarchy,
        this.props.currentLanguage,
        this.props.translationCache
      );
      this.getSecondLevelMenu(menuHierarchy, selectedMenu[0]);

      this.setState({
        menuHierarchy,
        firstLevelMenu,
      });
    }
    // Selected Menu change
    if (this.props.selectedMenu !== prevProps.selectedMenu) {
      if (selectedMenu.length >= 2) {
        this.setState({ currentMenu: selectedMenu[1] });
      }
      this.getSecondLevelMenu(menuHierarchy, selectedMenu[0]);
    }
    // Current Language, SelectedMenu
    if (this.props.currentLanguage !== prevProps.currentLanguage && selectedMenu[0]) {
      let firstLevelMenu = menuAdapter.firstMenuAdapter(menuHierarchy);
      this.setState({ firstLevelMenu });
      this.getSecondLevelMenu(menuHierarchy, selectedMenu[0]);
    }

    // Userprofile change
    if (this.props.userProfile !== prevProps.userProfile) {
      this.getRole(this.props.userProfile);
    }
  }

  getLanguageList = () => {
    const accessToken = localStorage.get('accessToken')
    let changePassword = {
      method: 'GET',
      url: `/api/language/languageList`,
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    }
    axios(changePassword)
      .then((response) => {
        this.setState({
          languageList: response.data
        })
        this.props.actions.getTranslationsCache(response.data && response.data[0] ? response.data[0] : "English");
      })
      .catch(function () {

      })
  }

  getSecondLevelMenu = async (menuHierarchy, selectedMenu) => {
    let menuHierarchyClone = cloneDeep(menuHierarchy);
    let secondLevelMenu = await menuAdapter.secoundMenuAdapter(
      menuHierarchyClone,
      selectedMenu || "Modules",
      this.props.currentLanguage,
      this.props.translationCache
    );
    this.setState({ secondLevelMenu });
  };

  componentWillReceiveProps({
    currentLanguage,
    currentSite,
    userProfile,
    selectedMenu,
  }) {
    if (userProfile.belongsTo) {
      this.setState({
        currentLanguage,
        currentSite,
        userProfile,
        selectedMenu,
      });
    } else {
      this.setState({ currentLanguage, userProfile, selectedMenu });
    }
  }

  getRole = async (userProfile) => {
    let currentSiteId = localStorage.get('currentSite')
    let orgId =
      currentSiteId &&
        userProfile &&
        userProfile.belongsTo &&
        userProfile.belongsTo.sites &&
        userProfile.belongsTo.sites[currentSiteId] &&
        userProfile.belongsTo.sites[currentSiteId].site &&
        userProfile.belongsTo.sites[currentSiteId].site['OrganizationId'] ?
        userProfile.belongsTo.sites[currentSiteId].site['OrganizationId'] : ""

    let imageURL =
      orgId &&
        userProfile &&
        userProfile.belongsTo &&
        userProfile.belongsTo.organization &&
        userProfile.belongsTo.organization[orgId] &&
        userProfile.belongsTo.organization[orgId].ImageURL ?
        userProfile.belongsTo.organization[orgId].ImageURL
        : ""

    this.setState({
      logoPath: imageURL,
    });

  };

  translation = (keyword) => {
    let { translationCache } = this.props
    return translationCache &&
      translationCache[keyword] ?
      translationCache[keyword] : keyword
  }

  getSubMenuOrItem = (item, menuLevel) => {
    let { selectedMenu, menuHierarchy } = this.state;

    return (
      <Menu.Item
        key={item.key}
        className="customclass"
        onClick={() => {
          if (menuLevel === 'firstLevelMenu') {
            let defaultPageValueSetting = menuAdapter.setFirstDefaultPages(
              this.state.menuHierarchy,
              [],
              item
            );
            if (item && item.key === "ApplicationSettings") {
              history.push('/rubus/settingsPage/Profile');
            } else if (defaultPageValueSetting.length === 1) {
              this.props.actions.setMenuSelection(defaultPageValueSetting);
              if (menuHierarchy && menuHierarchy[defaultPageValueSetting[0]] && menuHierarchy[defaultPageValueSetting[0]].path) {
                history.push(menuHierarchy[defaultPageValueSetting[0]].path)
              }
            } else if (defaultPageValueSetting.length >= 2) {
              this.props.actions.setMenuSelection(defaultPageValueSetting);
              history.push(
                menuHierarchy[defaultPageValueSetting[0]].children[
                  defaultPageValueSetting[1]
                ].path
              );
            }
          } else if (menuLevel === 'secondLevelMenu') {
            if (item.menuItemType && item.menuItemType === 'tab') {
              window.open(item.path, "_blank");
            } else {
              this.setState({ secondLevelMenu: [] });
              selectedMenu = selectedMenu && Array.isArray(selectedMenu) ? selectedMenu : ['Modules', 'rt_dashboard']
              const defaultSecondLevel = menuAdapter.setSecondDefaultPages(
                this.state.menuHierarchy,
                selectedMenu,
                item
              );
              let finalResult = [...defaultSecondLevel];
              this.props.actions.setMenuSelection(finalResult);
              history.push(item.path);
            }
          }
        }}
      >
        {item.icon ? <Icon type={item.icon} /> : null}
        <span className="nav-text">
          {
            this.translation(item.name)
          }
        </span>
      </Menu.Item>
    );
  };

  getNavMenuItems = (menusData, menuLevel) => {
    if (!menusData) {
      return [];
    }
    if (Array.isArray(menusData)) {
      return menusData
        .filter(item => item && item.name && !item.hideInMenu)
        .map(item => {
          return this.getSubMenuOrItem(item, menuLevel)
        })
        .filter(item => item);
    }
  };

  getSiteMenuItems = siteList => {
    if (!siteList) {
      return [];
    }
    if (typeof siteList === 'object') {
      return (
        <Menu theme="dark">
          {Object.keys(siteList)
            .map(item => {
              return this.getSiteSubMenuOrItem(siteList[item].site);
            })
            .filter(item => item)}
        </Menu>
      );
    }
  };

  getSiteSubMenuOrItem = item => {
    return (
      <Menu.Item
        key={item._key}
        className="customclass"
        onClick={() => {
          let modules = this.state.userProfile.belongsTo.sites[item._key].role;
          localStorage.set('role', modules.role);
          localStorage.set('currentSite', item._key);
          localStorage.set('landingPage', modules.landingPage);

          this.props.actions.setMenuHierarchy({
            modules: modules.Modules,
            configuration: modules.Configuration,
          });
          window.location.reload();
          this.setState({ currentSite: item._key });
        }}
      >
        <span className="nav-text"> {item.name}</span>
      </Menu.Item>
    );
  };

  setLanguage = language => {
    this.props.actions.setCurrentLanguage(language);
    this.props.actions.getTranslationsCache(language);
  };

  render() {
    const {
      currentMenu,
      userProfile,
      logoPath,
      firstLevelMenu,
      secondLevelMenu,
      languageList

    } = this.state;
    const {
      nativProps: { menuHierarchy },
      navigationMode,
    } = this.props;
    return (
      <StyledMenu
        mode="horizontal"
        theme="dark"
        style={{
          background: this.props.theme.main,
          overflow: 'auto',
          left: 0,
        }}
        selectedKeys={[currentMenu]}
      >
        <Menu.Item key="logo">
          {
            logoPath ?
              <Link to="/rubus/LandingPage">
                <img src={`/api/uploads/${logoPath}`} alt="LOGO" style={{ height: '60px', maxWidth: "100%", padding: '10px' }} />
              </Link>
              : <div>LOGO</div>
          }
        </Menu.Item>

        {secondLevelMenu !== undefined && menuHierarchy
          ? this.getNavMenuItems(secondLevelMenu, 'secondLevelMenu')
          : null}

        {userProfile &&
          userProfile.belongsTo &&
          userProfile.belongsTo.sites &&
          Object.keys(userProfile.belongsTo.sites).length > 1 ? (
          <Menu.Item key="logo" style={{ float: 'right' }}>
            <Dropdown
              overlay={
                userProfile && userProfile.belongsTo && userProfile.belongsTo.sites
                  ? this.getSiteMenuItems(userProfile.belongsTo.sites)
                  : null
              }
              trigger={['click']}
            >
              <div>
                {localStorage.get('currentSite') &&
                  userProfile &&
                  userProfile.belongsTo &&
                  userProfile.belongsTo.sites
                  ? userProfile.belongsTo.sites[`${localStorage.get('currentSite')}`]
                    .site.name
                  : ''}{' '}
                <Icon type="caret-down" />
              </div>
            </Dropdown>
          </Menu.Item>
        ) : null}

        <SubMenu style={{ float: "right" }} title={<span><Icon type="global" style={{ fontSize: '20px', color: 'rgb(255, 255, 255)' }} /></span>}>
          {
            languageList && Array.isArray(languageList) && languageList.map((language) => {
              return <Menu.Item key={language} onClick={() => { this.setLanguage(language) }}> {language} </Menu.Item>
            })
          }
        </SubMenu>

        <Menu.Item key="fullscreen" style={{ float: 'right' }}>
          <Icon
            type="fullscreen"
            title={'fullscreen'}
            onClick={() => {
              document.fullscreenEnabled =
                document.fullscreenEnabled ||
                document.mozFullScreenEnabled ||
                document.documentElement.webkitRequestFullScreen;

              if (document.fullscreenEnabled) {
                this.props.fullScreen(document.documentElement);
              } else {
                alert('full screen not supported');
              }
            }}
            style={{ fontSize: '15px', color: 'rgb(255, 255, 255)' }}
          />{' '}
        </Menu.Item>

        {
          <SubMenu
            style={{ float: 'right' }}
            title={
              <span>
                <Icon type="user" />{' '}

                {
                  this.translation(userProfile.user && userProfile.user.FirstName ? userProfile.user.FirstName : "")
                }
              </span>
            }
          >
            {navigationMode === 'top'
              ? this.getNavMenuItems(firstLevelMenu, 'firstLevelMenu')
              : null}
            <Menu.Item
              key="logOut"
              onClick={() => {
                localStorage.clear();
                this.props.history.push('/login');
                window.location.reload();
              }}
            >
              {' '}
              {
                <span>
                  <Icon type="logout" />
                  {
                    this.translation(constant.LogOut)
                  }
                </span>
              }
            </Menu.Item>
          </SubMenu>
        }

      </StyledMenu>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  selectedTheme: makeSelectTheme(),
});
export default connect(mapStateToProps)(withTheme(SlideMenuComponent));

