export const heatmapCartesionMockJson = {
	"18:30:00": [
		{
			"intervalAlias": "2023-01-14 18:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-15 18:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 18:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 18:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 18:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 18:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 18:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"19:00:00": [
		{
			"intervalAlias": "2023-01-14 19:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-15 19:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 19:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 19:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 19:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 19:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 19:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"19:30:00": [
		{
			"intervalAlias": "2023-01-14 19:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-15 19:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 19:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 19:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 19:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 19:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 19:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"20:00:00": [
		{
			"intervalAlias": "2023-01-14 20:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-15 20:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 20:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 20:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 20:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 20:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 20:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"20:30:00": [
		{
			"intervalAlias": "2023-01-14 20:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-15 20:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 20:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 20:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 20:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 20:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 20:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"21:00:00": [
		{
			"intervalAlias": "2023-01-14 21:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-15 21:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 21:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 21:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 21:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 21:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 21:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"21:30:00": [
		{
			"intervalAlias": "2023-01-14 21:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-15 21:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 21:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 21:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 21:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 21:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 21:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"22:00:00": [
		{
			"intervalAlias": "2023-01-14 22:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-15 22:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 22:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 22:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 22:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 22:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 22:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"22:30:00": [
		{
			"intervalAlias": "2023-01-14 22:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-15 22:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 22:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 22:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 22:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 22:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 22:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"23:00:00": [
		{
			"intervalAlias": "2023-01-14 23:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-15 23:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 23:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 23:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 23:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 23:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 23:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"23:30:00": [
		{
			"intervalAlias": "2023-01-14 23:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-15 23:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 23:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 23:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 23:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 23:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 23:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"00:00:00": [
		{
			"intervalAlias": "2023-01-15 00:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 00:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 00:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 00:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 00:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 00:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 00:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"00:30:00": [
		{
			"intervalAlias": "2023-01-15 00:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 00:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 00:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 00:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 00:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 00:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 00:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"01:00:00": [
		{
			"intervalAlias": "2023-01-15 01:00:00",
			"tagValue": null,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 01:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 01:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 01:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 01:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 01:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 01:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"01:30:00": [
		{
			"intervalAlias": "2023-01-15 01:30:00",
			"tagValue": null,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 01:30:00",
			"tagValue": 1,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 01:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 01:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 01:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 01:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 01:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"02:00:00": [
		{
			"intervalAlias": "2023-01-15 02:00:00",
			"tagValue": null,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 02:00:00",
			"tagValue": 1,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 02:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 02:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 02:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 02:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 02:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"02:30:00": [
		{
			"intervalAlias": "2023-01-15 02:30:00",
			"tagValue": null,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 02:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 02:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 02:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 02:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 02:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 02:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"03:00:00": [
		{
			"intervalAlias": "2023-01-15 03:00:00",
			"tagValue": null,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 03:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 03:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 03:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 03:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 03:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 03:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"03:30:00": [
		{
			"intervalAlias": "2023-01-15 03:30:00",
			"tagValue": null,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 03:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 03:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 03:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 03:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 03:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 03:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"04:00:00": [
		{
			"intervalAlias": "2023-01-15 04:00:00",
			"tagValue": null,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 04:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 04:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 04:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 04:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 04:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 04:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"04:30:00": [
		{
			"intervalAlias": "2023-01-15 04:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 04:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 04:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 04:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 04:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 04:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 04:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"05:00:00": [
		{
			"intervalAlias": "2023-01-15 05:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 05:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 05:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 05:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 05:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 05:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 05:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"05:30:00": [
		{
			"intervalAlias": "2023-01-15 05:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 05:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 05:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 05:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 05:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 05:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 05:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"06:00:00": [
		{
			"intervalAlias": "2023-01-15 06:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 06:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 06:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 06:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 06:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 06:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 06:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"06:30:00": [
		{
			"intervalAlias": "2023-01-15 06:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 06:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 06:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 06:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 06:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 06:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 06:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"07:00:00": [
		{
			"intervalAlias": "2023-01-15 07:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 07:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 07:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 07:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 07:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 07:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 07:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"07:30:00": [
		{
			"intervalAlias": "2023-01-15 07:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 07:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 07:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 07:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 07:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 07:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 07:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"08:00:00": [
		{
			"intervalAlias": "2023-01-15 08:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 08:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 08:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 08:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 08:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 08:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 08:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"08:30:00": [
		{
			"intervalAlias": "2023-01-15 08:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 08:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 08:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 08:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 08:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 08:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 08:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"09:00:00": [
		{
			"intervalAlias": "2023-01-15 09:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 09:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 09:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 09:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 09:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 09:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 09:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"09:30:00": [
		{
			"intervalAlias": "2023-01-15 09:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 09:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 09:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 09:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 09:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 09:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 09:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"10:00:00": [
		{
			"intervalAlias": "2023-01-15 10:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 10:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 10:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 10:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 10:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 10:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 10:00:00",
			"tagValue": null,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"10:30:00": [
		{
			"intervalAlias": "2023-01-15 10:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 10:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 10:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 10:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 10:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 10:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 10:30:00",
			"tagValue": null,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"11:00:00": [
		{
			"intervalAlias": "2023-01-15 11:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 11:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 11:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 11:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 11:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 11:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 11:00:00",
			"tagValue": null,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"11:30:00": [
		{
			"intervalAlias": "2023-01-15 11:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 11:30:00",
			"tagValue": 1,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 11:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 11:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 11:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 11:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 11:30:00",
			"tagValue": null,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"12:00:00": [
		{
			"intervalAlias": "2023-01-15 12:00:00",
			"tagValue": 0,
			"description": "TR131_PVの¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-16 12:00:00",
			"tagValue": 1,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-17 12:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-18 12:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-19 12:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-20 12:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-21 12:00:00",
			"tagValue": null,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		}
	],
	"12:30:00": [
		{
			"intervalAlias": "2023-01-15 12:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-16 12:30:00",
			"tagValue": 1,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-17 12:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-18 12:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-19 12:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-20 12:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-21 12:30:00",
			"tagValue": null,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		}
	],
	"13:00:00": [
		{
			"intervalAlias": "2023-01-15 13:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-16 13:00:00",
			"tagValue": 1,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-17 13:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-18 13:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-19 13:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-20 13:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-21 13:00:00",
			"tagValue": null,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		}
	],
	"13:30:00": [
		{
			"intervalAlias": "2023-01-15 13:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-16 13:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-17 13:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-18 13:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-19 13:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-20 13:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-21 13:30:00",
			"tagValue": null,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		}
	],
	"14:00:00": [
		{
			"intervalAlias": "2023-01-15 14:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-16 14:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-17 14:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-18 14:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-19 14:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-20 14:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-21 14:00:00",
			"tagValue": null,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		}
	],
	"14:30:00": [
		{
			"intervalAlias": "2023-01-15 14:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-16 14:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-17 14:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-18 14:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-19 14:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-20 14:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-21 14:30:00",
			"tagValue": null,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		}
	],
	"15:00:00": [
		{
			"intervalAlias": "2023-01-15 15:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-16 15:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-17 15:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-18 15:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-19 15:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-20 15:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-21 15:00:00",
			"tagValue": null,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		}
	],
	"15:30:00": [
		{
			"intervalAlias": "2023-01-15 15:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-16 15:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-17 15:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-18 15:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-19 15:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-20 15:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-21 15:30:00",
			"tagValue": null,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		}
	],
	"16:00:00": [
		{
			"intervalAlias": "2023-01-15 16:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-16 16:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-17 16:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-18 16:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-19 16:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-20 16:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-21 16:00:00",
			"tagValue": null,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		}
	],
	"16:30:00": [
		{
			"intervalAlias": "2023-01-15 16:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-16 16:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-17 16:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-18 16:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-19 16:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-20 16:30:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		},
		{
			"intervalAlias": "2023-01-21 16:30:00",
			"tagValue": null,
			"description": "TR131_PVã®å¤ã¯10åè¶ãã¾ãã"
		}
	],
	"17:00:00": [
		{
			"intervalAlias": "2023-01-15 17:00:00",
			"tagValue": 0,
			"description": "TR131_PVã®å¤ã¯10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 17:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 17:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 17:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 17:00:00",
			"tagValue": 1,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 17:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 17:00:00",
			"tagValue": null,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"17:30:00": [
		{
			"intervalAlias": "2023-01-15 17:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 17:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 17:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 17:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 17:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 17:30:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 17:30:00",
			"tagValue": null,
			"description": "TR131_PVの値は10回超えました"
		}
	],
	"18:00:00": [
		{
			"intervalAlias": "2023-01-15 18:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-16 18:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-17 18:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-18 18:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-19 18:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-20 18:00:00",
			"tagValue": 0,
			"description": "TR131_PVの値は10回超えました"
		},
		{
			"intervalAlias": "2023-01-21 18:00:00",
			"tagValue": null,
			"description": "TR131_PVの値は10回超えました"
		}
	]
}
  