import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Tag} from "antd";
import moment from "moment";
import ReactEcharts from "../Echarts/Echarts";
import {
  getChartDataWithoutSelection,
  getChartDataWithSelection,
} from "../ChartCalls";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";

import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { boxPlotMockJson } from "../../Mock/MockJson";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { defaultRefreshTime } from '../constant.json'
import { Fragment } from "react";

class Funnel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear
    ) {
      this.getData(this.props, this.props.selectedThemeing);
    }
  }

  getData = async (props, theme) => {
    this.requestCheck = false
    let data = [];
    let { graphprops } = this.props;

    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false 

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      data = boxPlotMockJson;
      this.requestCheck = true
    } else {

      if (
        (graphprops &&
          graphprops.dashboardType === "dynamic" &&
          this.props.deviceId) ||
        (graphprops &&
          graphprops.dateExists === "true" &&
          this.props.selectionDate) ||
        (graphprops &&
          graphprops.monthExists === "true" &&
          this.props.selectionMonth) ||
        (graphprops &&
          graphprops.weekExists === "true" &&
          this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
        (graphprops &&
          graphprops.dateRangeExists === "true" &&
          this.props.selectionDateRange)
        ||
        (graphprops && graphprops.dayMonthYearExists === 'true' && this.props.dayMonthYear)
      ) {
        data = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController
        );
        this.requestCheck = true
      } else {
        data = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true
      }
    }
   if ((data && data.message) || ((Array.isArray(data) && data.length === 0 && graphprops.enableMock !== true))) {

    if (data.message) {
      errorInAPI = true
    } else if (data.length === 0) {
      noDataInDB = true
    }

  } else {

    if (data && Array.isArray(data)) {
     let seriesData = mockData(data)
      let option = this.getOption(seriesData);
      this.setState({ 
        option ,
        refreshDateTime: moment()
      });
    }
  };
  this.setState({
    errorInAPI,
    noDataInDB
  })
};

  componentWillUnmount() {
    this.abortController.abort()
    clearInterval(this.timeTicket);
  }

  render() {
    const {errorInAPI, noDataInDB,refreshDateTime } = this.state;
    let { graphprops } = this.props
    return (

      <Fragment>
         {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
      {
        noDataInDB === true || errorInAPI === true ?
          noDataInDB === true ?
          <NoRecordComponent/>
          : <ErrorComponent/>
          :
      <ReactEcharts
        option={this.state.option}
        style={{ height: "90%", width: "100%" }}
        className="react_for_echarts"
      />
      }
      </Fragment>

    );
  }
  getOption = (data) => {
    let theme = this.props.selectedThemeing;
    let option = {
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        left: "10%",
        right: "10%",
        bottom: "15%",
      },
      xAxis: {
        type: "category",
        data: data.axisData,
        boundaryGap: true,
        nameGap: 30,
        splitArea: {
          show: false,
        },
        axisLabel: {
          formatter: "week {value}",
          /// color: "white"
          color: theme === "light" ? "black" : "white",
        },
        axisLine: {
          lineStyle: {
            //color: "white"
            color: theme === "light" ? "black" : "white",
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          lineStyle: {
            // color: "white"
            color: theme === "light" ? "black" : "white",
          },
        },
      },
      yAxis: {
        type: "value",

        splitArea: {
          show: true,
        },
        axisLine: {
          lineStyle: {
            // color: "white"
            color: theme === "light" ? "black" : "white",
          },
        },
      },
      series: [
        {
          name: "boxplot",
          type: "boxplot",
          data: data.boxData,
          tooltip: {
            formatter: function (param) {
              return [
                "Experiment " + param.name + ": ",
                "upper: " + param.data[5],
                "Q3: " + param.data[4],
                "median: " + param.data[3],
                "Q1: " + param.data[2],
                "lower: " + param.data[1],
              ].join("<br/>");
            },
          },
        },
        {
          name: "outlier",
          type: "scatter",
          data: data.outliers,
        },
      ],
    };
    return option;
  };
}

function mockData(data) {
  var seriesData = prepareBoxplotData(data);
  return seriesData;
}
function quantile(ascArr, p) {
  var H = (ascArr.length - 1) * p + 1;
  var h = Math.floor(H);
  var v = +ascArr[h - 1];
  var e = H - h;
  return e ? v + e * (ascArr[h] - v) : v;
}

function asc(arr) {
  arr.sort(function (a, b) {
    return a - b;
  });
  return arr;
}
function prepareBoxplotData(rawData, opt) {
  opt = opt || [];
  var boxData = [];
  var outliers = [];
  var axisData = [];
  var boundIQR = opt.boundIQR;
  var useExtreme = boundIQR === "none" || boundIQR === 0;

  for (var i = 0; i < rawData.length; i++) {
    axisData.push(i + "");
    var ascList = asc(rawData[i].slice());

    var Q1 = quantile(ascList, 0.25);
    var Q2 = quantile(ascList, 0.5);
    var Q3 = quantile(ascList, 0.75);
    var min = ascList[0];
    var max = ascList[ascList.length - 1];

    var bound = (boundIQR == null ? 1.5 : boundIQR) * (Q3 - Q1);

    var low = useExtreme ? min : Math.max(min, Q1 - bound);
    var high = useExtreme ? max : Math.min(max, Q3 + bound);

    boxData.push([low, Q1, Q2, Q3, high]);

    for (var j = 0; j < ascList.length; j++) {
      var dataItem = ascList[j];
      if (dataItem < low || dataItem > high) {
        var outlier = [i, dataItem];
        opt.layout === "vertical" && outlier.reverse();
        outliers.push(outlier);
      }
    }
  }
  return {
    boxData: boxData,
    outliers: outliers,
    axisData: axisData,
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(Funnel);
