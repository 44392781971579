import axios from "axios";
import moment from "moment";
import localStorage from '../../../utils/localStorage'


export async function getChartDataWithoutSelection(graphprops, abortController, dropdownSelectionn) {
  let siteId = localStorage.get('currentSite')
  siteId = siteId ? siteId : 1
  const token = localStorage.get('accessToken')
  var Authorization = "Bearer " + token;
  let dropdownSelection = {}
  if (graphprops && graphprops.dashboardType === "multi" && dropdownSelectionn) {
    dropdownSelection = dropdownSelectionn
  }

  let data = await fetch(`/api/dashboard/get`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization
    },
    body: JSON.stringify({
      Name: graphprops.dashboardName,
      PanelName: graphprops.title,
      Id: graphprops.id,
      dashboardType: 'static',
      type: graphprops.type || "historic",
      siteId,
      startDate: graphprops.startDate,
      endDate: graphprops.endDate,
      timeBucket: "1h",
      // startDate:  moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      // endDate: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      date: moment()
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
      ...dropdownSelection

    }),
    signal: abortController.signal
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      console.log(error);
    });

  return data;
}

export async function getChartDataWithSelection(graphprops,
  deviceId, selectionDate, selectionMonth, selectionWeek, shift,
  selectionDateRange, dayMonthYear, abortController, dropdownSelection) {
  let siteId = localStorage.get('currentSite')
  siteId = siteId ? siteId : 1
  const token = localStorage.get('accessToken')

  let newdashboardPayload = {}
  let newDatePayload = {}
  let newDateRangePayload = {}
  let newMonthPayload = {}
  let newShiftPayload = {}
  let newWeekPayload = {}
  let newdayMonthYearPayload = {}
  let newmultiDropdownPayload = {}

  let payload = {
    Name: graphprops && graphprops.dashboardName,
    PanelName: graphprops && graphprops.title,
    Id: graphprops && graphprops.id,
    type: (graphprops && graphprops.type) || "historic",
    dashboardType: 'static',
    siteId,
    startDate: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
    // date: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
    timeBucket: "1h"
  }
  if (graphprops.dashboardType === 'dynamic') {
    newdashboardPayload = {
      dashboardType: 'dynamic',
      deviceId
    }
  }

  if (dropdownSelection && (graphprops.dashboardType === 'multi' || graphprops.dashboardType === 'hierarchy')) {
    newmultiDropdownPayload = dropdownSelection
  }

  if (graphprops.dateExists === 'true') {
    newDatePayload = {
      dateExists: true,
      date: moment(selectionDate.date).utc().format("YYYY-MM-DD HH:mm:ss")
    }
  }

  if (graphprops.monthExists === 'true') {
    let monthYear = selectionMonth.split('-')
    let monthslipt = selectionMonth
    let currentYear = moment().format("YYYY-MM")
    let selectedYear = monthYear[0]
    let selectedmonth = monthYear[1]
    newMonthPayload = {
      monthExists: true,
      timeBucket: "1d",
      deviceId,
      selectionType: "month",
      startDate: moment([monthYear[0], monthYear[1]]).startOf('month').subtract('month', 1).utc().format(`YYYY-MM-DD HH:mm:ss`),
      endDate: monthslipt === currentYear ? moment().subtract(1, "day").utc().format('YYYY-MM-DD HH:mm:ss') :
        moment([selectedYear, selectedmonth - 1]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
      //  endDate: moment([monthYear[0], monthYear[1]]).endOf('month').subtract('month', 1).utc().format(`YYYY-MM-DD HH:mm:ss`)

    }
  }

  if (graphprops.weekExists === 'true') {
    let currentWeek = moment().startOf('week')
    newWeekPayload = {
      weekExists: true,
      startDate: moment(selectionWeek).startOf('week').utc().format('YYYY-MM-DD HH:mm:ss'),
      endDate: selectionWeek.isSame(currentWeek) ? moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss') :
        moment(selectionWeek).endOf('week').utc().format('YYYY-MM-DD HH:mm:ss')
    }
  }


  if (graphprops.shiftExists === 'true') {
    newShiftPayload = {
      shiftExists: true,
      shift
    }
  }
  if (graphprops.dateRangeExists === 'true') {
    newDateRangePayload = {
      dateRangeExists: true,
      ...selectionDateRange,
      startDate: moment(selectionDateRange.startDate).utc().format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(selectionDateRange.endDate).utc().format("YYYY-MM-DD HH:mm:ss")
    }
  }

  if (graphprops.dayMonthYearExists === 'true') {
    newdayMonthYearPayload = {
      dayMonthYearExists: true,
      ...dayMonthYear,

      startDate: moment(dayMonthYear.startDate).utc().format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(dayMonthYear.endDate).utc().format("YYYY-MM-DD HH:mm:ss")

    }
  }

  payload = {
    ...payload,
    ...newdashboardPayload,
    ...newDatePayload,
    ...newMonthPayload,
    ...newWeekPayload,
    ...newShiftPayload,
    ...newDateRangePayload,
    ...newdayMonthYearPayload,
    ...newmultiDropdownPayload
  }

  var Authorization = "Bearer " + token;
  let data = await fetch(`/api/dashboard/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify(payload),
    signal: AbortController.signal
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      console.log(error);
    });

  return data;
}

export async function historicChartData(config) {
  let siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");
  var Authorization = "Bearer " + token;
  if (siteId === undefined) {
    siteId = "1"
  }
  let data = await fetch(`/api/dashboard/get`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify({
      Name: config.dashboardName,
      PanelName: config.title,
      Id: config.id,
      dashboardType: 'static',
      siteId,
      date: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      // startDate: config.startDate,
      // endDate: config.endDate,
      startDate: moment(config.startDate).utc().format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(config.endDate).utc().format("YYYY-MM-DD HH:mm:ss"),
      duration: config.duration,
      durationType: config.durationType
    })
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return []
    });

  return data;
}
export async function historicChartData1(config) {
  let siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");
  var Authorization = "Bearer " + token;
  if (siteId === undefined) {
    siteId = "1"
  }
  let data = await fetch(`/api/dashboard/get`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify({
      Name: config.dashboardName,
      PanelName: config.title,
      Id: config.id,
      dashboardType: 'static',
      siteId,
      date: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      // startDate: config.startDate,
      // endDate: config.endDate,
      startDate: moment(config.startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(config.endDate).format("YYYY-MM-DD HH:mm:ss"),
      duration: config.duration,
      durationType: config.durationType
    })
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      return []
    });

  return data;
}
export async function historicWithPayloadTableChartData(graphprops, deviceId, selectionDate,
  selectionMonth, selectionWeek, shift, selectionDateRange, dayMonthYear, abortController, dropdownSelection) {
  let siteId = localStorage.get('currentSite')
  siteId = siteId ? siteId : 1
  const token = localStorage.get('accessToken')
  let newdashboardPayload = {}
  let newDatePayload = {}
  let newDateRangePayload = {}
  let newMonthPayload = {}
  let newShiftPayload = {}
  let newWeekPayload = {}
  let newdayMonthYearPayload = {}
  let newmultiDropdownPayload = {}

  let payload = {
    Name: graphprops.dashboardName,
    PanelName: graphprops.title,
    // date1:selectionDate.date,
    Id: graphprops.id,
    type: graphprops.type || "historic",
    dashboardType: 'static',
    siteId,
    // startDate: graphprops && graphprops.startDate,
    // endDate: graphprops && graphprops.endDate,
    date: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
    timeBucket: '1h'
  }
  if (graphprops.dashboardType === 'dynamic') {
    newdashboardPayload = {
      dashboardType: 'dynamic',
      deviceId
    }
  }

  if (dropdownSelection && (graphprops.dashboardType === 'multi' || graphprops.dashboardType === 'hierarchy')) {
    newmultiDropdownPayload = dropdownSelection
  }

  if (graphprops.dateExists === 'true') {
    newDatePayload = {
      dateExists: true,
      date: graphprops.type && graphprops.type === "live" ? moment(selectionDate).utc().format("YYYY-MM-DD HH:mm:ss") : moment(selectionDate.date).format("YYYY-MM-DD HH:mm:ss")
    }
  }
  if (graphprops.monthExists === 'true') {
    let monthYear = selectionMonth.split('-')
    newMonthPayload = {
      monthExists: true,
      timeBucket: "1d",
      startDate: graphprops.type && graphprops.type === "live" ?
        moment([monthYear[0], monthYear[1]]).startOf('month').subtract('month', 1).utc().format(`YYYY-MM-DD HH:mm:ss`) :
        moment([monthYear[0], monthYear[1]]).startOf('month').subtract('month', 1).format(`YYYY-MM-DD HH:mm:ss`),
      endDate: graphprops.type && graphprops.type === "live" ?
        moment([monthYear[0], monthYear[1]]).startOf('month').subtract('month', 1).utc().format(`YYYY-MM-DD HH:mm:ss`) :
        moment([monthYear[0], monthYear[1]]).add('months', 1).subtract('month', 1).subtract('day', 1).format('YYYY-MM-DD HH:mm:ss'),

    }
  }

  if (graphprops.weekExists === 'true') {
    newWeekPayload = {
      weekExists: true,
      startDate: graphprops.type && graphprops.type === "live" ? moment(selectionWeek).startOf('week').utc().format('YYYY-MM-DD HH:mm:ss') : moment(selectionWeek).startOf('week').format('YYYY-MM-DD HH:mm:ss'),
      endDate: graphprops.type && graphprops.type === "live" ? moment(selectionWeek).endOf('week').utc().format('YYYY-MM-DD HH:mm:ss') : moment(selectionWeek).endOf('week').format('YYYY-MM-DD HH:mm:ss')
    }
  }


  if (graphprops.shiftExists === 'true') {
    newShiftPayload = {
      shiftExists: true,
      shift
    }
  }
  if (graphprops.dateRangeExists === 'true') {
    newDateRangePayload = {
      dateRangeExists: true,
      ...selectionDateRange,
      startDate: graphprops.type && graphprops.type === "live" ? moment(selectionDateRange.startDate).utc().format("YYYY-MM-DD HH:mm:ss") : moment(selectionDateRange.startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: graphprops.type && graphprops.type === "live" ? moment(selectionDateRange.endDate).utc().format("YYYY-MM-DD HH:mm:ss") : moment(selectionDateRange.endDate).format("YYYY-MM-DD HH:mm:ss")
    }
  }

  if (graphprops.dayMonthYearExists === 'true') {
    newdayMonthYearPayload = {
      dayMonthYearExists: true,
      ...dayMonthYear,
      startDate: graphprops.type && graphprops.type === "live" ? moment(dayMonthYear.startDate).utc().format("YYYY-MM-DD HH:mm:ss") : moment(dayMonthYear.startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: graphprops.type && graphprops.type === "live" ? moment(dayMonthYear.endDate).utc().format("YYYY-MM-DD HH:mm:ss") : moment(dayMonthYear.endDate).format("YYYY-MM-DD HH:mm:ss")

    }
  }

  payload = {
    ...payload,
    // ...newdashboardPayload,
    ...newDatePayload,
    // ...newMonthPayload,
    // ...newWeekPayload,
    ...newShiftPayload,
    ...newDateRangePayload,
    // ...newdayMonthYearPayload,
    // ...newmultiDropdownPayload
  }

  var Authorization = "Bearer " + token;
  let data = await fetch(`/api/dashboard/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify(payload),
    signal: AbortController.signal
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      console.log(error);
    });

  return data;
}
export async function historicWithPayloadTableChartDataHeatMap(graphprops, deviceId, selectionDate,
  selectionMonth, selectionWeek, shift, selectionDateRange, dayMonthYear, abortController, dropdownSelection) {
  let siteId = localStorage.get('currentSite')
  siteId = siteId ? siteId : 1
  const token = localStorage.get('accessToken')
  let newdashboardPayload = {}
  let newDatePayload = {}
  let newDateRangePayload = {}
  let newMonthPayload = {}
  let newShiftPayload = {}
  let newWeekPayload = {}
  let newdayMonthYearPayload = {}
  let newmultiDropdownPayload = {}

  let payload = {
    Name: graphprops.dashboardName,
    PanelName: graphprops.title,
    // date1:selectionDate.date,
    Id: graphprops.id,
    type: graphprops.type || "historic",
    dashboardType: 'static',
    siteId,
    startDate: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
    // date: moment().utc().format("YYYY-MM-DD HH:mm:ss")
    timeBucket: '1h'
  }
  if (graphprops.dashboardType === 'dynamic') {
    newdashboardPayload = {
      dashboardType: 'dynamic',
      deviceId
    }
  }

  if (dropdownSelection && (graphprops.dashboardType === 'multi' || graphprops.dashboardType === 'hierarchy')) {
    newmultiDropdownPayload = dropdownSelection
  }
  if (graphprops.dateExists === 'true') {
    newDatePayload = {
      dateExists: true,
      date: graphprops.type && graphprops.type === "live" ?
        moment(selectionDate).utc().format("YYYY-MM-DD HH:mm:ss") :
        moment(selectionDate.date).utc().format("YYYY-MM-DD HH:mm:ss")
    }
  }
  if (graphprops.monthExists === 'true') {
    let monthYear = selectionMonth.split('-')
    newMonthPayload = {
      monthExists: true,
      timeBucket: "1d",
      startDate: graphprops.type && graphprops.type === "live" ?
        moment([monthYear[0], monthYear[1]]).startOf('month').subtract('month', 1).utc().format(`YYYY-MM-DD HH:mm:ss`) :
        moment([monthYear[0], monthYear[1]]).startOf('month').subtract('month', 1).format(`YYYY-MM-DD HH:mm:ss`),
      endDate: graphprops.type && graphprops.type === "live" ?
        moment([monthYear[0], monthYear[1]]).startOf('month').subtract('month', 1).utc().format(`YYYY-MM-DD HH:mm:ss`) :
        moment([monthYear[0], monthYear[1]]).add('months', 1).subtract('month', 1).subtract('day', 1).format('YYYY-MM-DD HH:mm:ss'),

    }
  }

  if (graphprops.weekExists === 'true') {
    newWeekPayload = {
      weekExists: true,
      startDate: graphprops.type && graphprops.type === "live" ? moment(selectionWeek).startOf('week').utc().format('YYYY-MM-DD HH:mm:ss') : moment(selectionWeek).startOf('week').format('YYYY-MM-DD HH:mm:ss'),
      endDate: graphprops.type && graphprops.type === "live" ? moment(selectionWeek).endOf('week').utc().format('YYYY-MM-DD HH:mm:ss') : moment(selectionWeek).endOf('week').format('YYYY-MM-DD HH:mm:ss')
    }
  }


  if (graphprops.shiftExists === 'true') {
    newShiftPayload = {
      shiftExists: true,
      shift
    }
  }
  if (graphprops.dateRangeExists === 'true') {
    newDateRangePayload = {
      dateRangeExists: true,
      ...selectionDateRange,
      startDate: graphprops.type && graphprops.type === "live" ? moment(selectionDateRange.startDate).utc().format("YYYY-MM-DD HH:mm:ss") : moment(selectionDateRange.startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: graphprops.type && graphprops.type === "live" ? moment(selectionDateRange.endDate).utc().format("YYYY-MM-DD HH:mm:ss") : moment(selectionDateRange.endDate).format("YYYY-MM-DD HH:mm:ss")
    }
  }

  if (graphprops.dayMonthYearExists === 'true') {
    newdayMonthYearPayload = {
      dayMonthYearExists: true,
      ...dayMonthYear,
      startDate: graphprops.type && graphprops.type === "live" ? moment(dayMonthYear.startDate).utc().format("YYYY-MM-DD HH:mm:ss") : moment(dayMonthYear.startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: graphprops.type && graphprops.type === "live" ? moment(dayMonthYear.endDate).utc().format("YYYY-MM-DD HH:mm:ss") : moment(dayMonthYear.endDate).format("YYYY-MM-DD HH:mm:ss")

    }
  }

  payload = {
    ...payload,
    // ...newdashboardPayload,
    ...newDatePayload,
    // ...newMonthPayload,
    // ...newWeekPayload,
    ...newShiftPayload,
    ...newDateRangePayload,
    // ...newdayMonthYearPayload,
    // ...newmultiDropdownPayload
  }

  var Authorization = "Bearer " + token;
  let data = await fetch(`/api/dashboard/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify(payload),
    signal: AbortController.signal
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      console.log(error);
    });

  return data;
}

/**
 * @description This function is used DateTable graphtype
 * @return Array of Objects
 */
export async function historicDateTableChartData(graphpropsProps, abortController) {
  let siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");
  var Authorization = "Bearer " + token;
  if (siteId === undefined) {
    siteId = "1"
  }
  let data = await fetch(`/api/dashboard/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify({
      Name: graphpropsProps.dashboardName,
      PanelName: graphpropsProps.title,
      Id: graphpropsProps.id,
      dashboardType: 'static',
      siteId,
      startDate: graphpropsProps.startDate,
      endDate: graphpropsProps.endDate,
      date: graphpropsProps && graphpropsProps.date,
      month: graphpropsProps && graphpropsProps.month,
      year: graphpropsProps && graphpropsProps.year,
      days: graphpropsProps && graphpropsProps.days
    }),
    signal: abortController.signal
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      console.log(error);
    });

  return data;
}

/**
 * @description This function is used DateTable graphtype
 * @return Array of Objects
 */
export async function historicTableChartData(config) {
  let siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");
  var Authorization = "Bearer " + token;
  siteId = siteId ? siteId : 1
  let data = await fetch(`/api/site/${siteId}/dashboard/get`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify({
      DashboardName: config.dashboardName,
      Id: config.id,
      dashboardType: 'static',
      siteId,
      date: config && config.date ? moment(config.date).format("YYYY-MM-DD HH:mm:ss") : null,
      month: config.month,
      year: config.year,
      days: config.days
    })
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      console.log(error);
    });

  return data;
}


/**
 * @description This function is used for prediction graphtype
 * @return Array
 */
export async function historicPredictionsChartDataa(type, graphprops) {
  const token = localStorage.get('accessToken')
  let siteId = localStorage.get('currentSite')
  siteId = siteId ? siteId : 1
  var Authorization = "Bearer " + token;
  let data = await fetch(`/api/dashboard/get`, {

    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify({
      Name: graphprops.dashboardName,
      PanelName: graphprops.title,
      Id: graphprops.id,
      dashboardType: 'static',
      siteId,
      date: moment()
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
      predictionType: type
    })
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      console.log(error);
    });

  return data;
}

/**
 * @description This function is used for prediction graphtype with date selection
 * @return Array
 */
export async function historicPredictionsWithPayloadChartDataa(type, graphprops, deviceId,
  selectionDate, selectionMonth, selectionWeek, shift, selectionDateRange, dayMonthYear) {

  const token = localStorage.get('accessToken')
  let siteId = localStorage.get('currentSite')
  siteId = siteId ? siteId : 1
  var Authorization = "Bearer " + token;

  let newdashboardPayload = {}
  let newDatePayload = {}
  let newMonthPayload = {}
  let newShiftPayload = {}
  let newWeekPayload = {}
  let newDateRangePayload = {}
  let newdayMonthYearPayload = {}

  let payload = {
    Name: graphprops.dashboardName,
    PanelName: graphprops.title,
    Id: graphprops.id,
    predictionType: type,
    dashboardType: 'static',
    siteId,
    startDate: graphprops.startDate,
    endDate: graphprops.endDate,
    date: moment()
      .utc()
      .format("YYYY-MM-DD HH:mm:ss")
  }
  if (graphprops.dashboardType === 'dynamic') {
    newdashboardPayload = {
      dashboardType: 'dynamic',
      deviceId
    }
  }

  if (graphprops.dateExists === 'true') {
    newDatePayload = {
      dateExists: true,
      date: graphprops.type && graphprops.type === "live" ? moment(selectionDate).utc().format("YYYY-MM-DD HH:mm:ss") : moment(selectionDate).format("YYYY-MM-DD HH:mm:ss")
    }
  }
  if (graphprops.monthExists === 'true') {
    let monthYear = selectionMonth.split('-')
    newMonthPayload = {
      monthExists: true,
      timeBucket: "1d",
      startDate: graphprops.type && graphprops.type === "live" ?
        moment([monthYear[0], monthYear[1]]).startOf('month').subtract('month', 1).utc().format(`YYYY-MM-DD`) :
        moment([monthYear[0], monthYear[1]]).startOf('month').subtract('month', 1).format(`YYYY-MM-DD`),
      endDate: graphprops.type && graphprops.type === "live" ?
        moment([monthYear[0], monthYear[1]]).startOf('month').subtract('month', 1).utc().format(`YYYY-MM-DD`) :
        moment([monthYear[0], monthYear[1]]).add('months', 1).subtract('month', 1).subtract('day', 1).format('YYYY-MM-DD'),

    }
  }

  if (graphprops.weekExists === 'true') {
    newWeekPayload = {
      weekExists: true,
      startDate: graphprops.type && graphprops.type === "live" ? moment(selectionWeek).startOf('week').utc().format('YYYY-MM-DD') : moment(selectionWeek).startOf('week').format('YYYY-MM-DD'),
      endDate: graphprops.type && graphprops.type === "live" ? moment(selectionWeek).endOf('week').utc().format('YYYY-MM-DD') : moment(selectionWeek).endOf('week').format('YYYY-MM-DD')
    }
  }

  if (graphprops.shiftExists === 'true') {
    newShiftPayload = {
      shiftExists: true,
      shift
    }
  }
  if (graphprops.dateRangeExists === 'true') {
    newDateRangePayload = {
      dateRangeExists: true,
      ...selectionDateRange,
      startDate: graphprops.type && graphprops.type === "live" ? moment(selectionDateRange.startDate).utc().format("YYYY-MM-DD HH:mm:ss") : moment(selectionDateRange.startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: graphprops.type && graphprops.type === "live" ? moment(selectionDateRange.endDate).utc().format("YYYY-MM-DD HH:mm:ss") : moment(selectionDateRange.endDate).format("YYYY-MM-DD HH:mm:ss")
    }
  };
  if (graphprops.dayMonthYearExists === 'true') {
    newdayMonthYearPayload = {
      dayMonthYearExists: true,
      ...dayMonthYear,
      startDate: graphprops.type && graphprops.type === "live" ? moment(selectionDateRange.startDate).utc().format("YYYY-MM-DD HH:mm:ss") : moment(selectionDateRange.startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: graphprops.type && graphprops.type === "live" ? moment(selectionDateRange.endDate).utc().format("YYYY-MM-DD HH:mm:ss") : moment(selectionDateRange.endDate).format("YYYY-MM-DD HH:mm:ss")

    }
  }


  payload = {
    ...payload,
    ...newdashboardPayload,
    ...newDatePayload,
    ...newMonthPayload,
    ...newShiftPayload,
    ...newWeekPayload,
    ...newDateRangePayload,
    ...newdayMonthYearPayload,
    type
  }

  let data = await fetch(`/api/dashboard/get`, {

    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      console.log(error);
    });

  return data;
}

/**
 * @description This function is used for excel download 
 * @return String
 */
export function _getReportNametoDownload(excelData) {
  let payload = {
    date: moment().format("YYYY-MM-DD"),
    time: moment().format("HH:mm:SS"),
    data: excelData || [],
  };

  let accessToken = localStorage.get("accessToken");
  let options = {
    method: "POST",
    url: `/api/report/generateExcel`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: JSON.stringify(payload),
  };
  return axios(options)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return "";
    });
}

/**
 * @description This function is used to get treeGraph data response
 * @return Nested Object with childrens
 */
export async function treeGraphData(abortController) {
  const token = localStorage.get('accessToken')
  var Authorization = "Bearer " + token;
  let data = await fetch(`/api/hierarchymodule/hierarchy`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    signal: abortController.signal
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      console.log(error);
    });

  return data;
}

export async function getReportDashboardData(config, payload) {
  let siteId = localStorage.get('currentSite')
  const token = localStorage.get('accessToken')
  var Authorization = "Bearer " + token;
  if (siteId === undefined) {
    siteId = "1"
  }
  let data = await fetch(`/api/site/${siteId}/dashboard/get`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify({
      DashboardName: config.dashboardName,
      Id: config.id,
      dashboardType: 'multi',
      siteId,
      ...payload
    })
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      console.log(error);
    });

  return data;
}




