import { fromJS } from "immutable";
import { types } from "./types";

export const getLoggedInProfile = () => {
  return {
    type: types.GET_LOGGEDIN_PROFILE,
  };
};

export const setCurrentSite = (payload) => {
  return {
    type: types.SET_CURRENTSITE,
    payload,
  };
};

export const getLoggedInProfileSuccess = (payload) => {
  
  return {
    type: types.GET_LOGGEDIN_PROFILE_SUCCESS,
    payload,
  };
};

export const setMenuHierarchy = (payload) => {
  return {
    type: types.SET_MENUHIERARCHY,
    payload,
  };
};

export const setLandingPage = (payload) =>{

  return {
    type: types.SET_LandingPage,
    payload
  }
}
export const setUserDetails = (payload) =>{

  return {
    type: types.SET_UserDetails,
    payload
  }
};

export const setMenuCollapse = (payload) =>{
  return {
    type: types.SET_MenuCollapse,
    payload
  }
}

const initialState = fromJS({
  loggedInProfile: {},
  menuHierarchy:{},
  landingPage: "",
  UserDetails:{},
  menuCollapse: false
})

const layout = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LOGGEDIN_PROFILE_SUCCESS: {
      return state.set('loggedInProfile', action.payload)
    }   
    case types.SET_CURRENTSITE: {
      return state.set("currentSite", action.payload);
    }
    case types.SET_MENUHIERARCHY: {
      return state.set("menuHierarchy", action.payload);
    }
    case types.SET_LandingPage: {
      return state.set('landingPage', action.payload)
    }
    case types.SET_UserDetails: {
      return state.set('UserDetails', action.payload)
    } 
    case types.SET_MenuCollapse: {
      return state.set('menuCollapse', action.payload)
    }
    default: return state
  }
};

export default layout;
