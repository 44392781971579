import React from 'react'
import axios from 'axios'
import { Form, Input, Button, message, Select } from 'antd'

import localStorage from '../../../utils/localStorage'
const FormItem = Form.Item
const { Option } = Select

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
}

class MailSettingsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mailSetings: {}
    }
  }

  componentWillMount() {
    this._getInitialMailSettingData()
  }

  _getInitialMailSettingData = () => {
    const accessToken = localStorage.get('accessToken')
    const siteId = localStorage.get('currentSite')
    let object = {
      method: 'POST',
      url: `/api/notification/getsettings`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { type: "Email", SiteId:siteId}
    }

    axios(object)
      .then((response) => {
        if (response && Array.isArray(response.data) && response.data && response.data.length > 0) {
          this.setState({
            mailSetings: response.data[0]
          })
        } else {
          this.setState({
            mailSetings: {}
          })
        }


      })
      .catch(function () {
        this.setState({
          mailSetings: {}
        })
      })
  }

  _updateMailSettings = (payload) => {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get('accessToken')
    const object = {
      method: 'PATCH',
      url: `/api/notification/updatesettings`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        ...payload,
        type: "Email",
        SiteId:siteId
      }
    }
    axios(object)
      .then(({data}) => {
        if(data && data.message){
          message.success(data.message)
        }

      })
      .catch(function () {
      })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this._updateMailSettings(values)
      }
    })
  }



  render() {
    const { getFieldDecorator } = this.props.form
    const { mailSetings } = this.state

    return (
      <Form style={{ marginRight: '180px' }} {...formItemLayout} onSubmit={this.handleSubmit}>
        <Form.Item label="Outgoing Server Id">
          {getFieldDecorator('host', {
            initialValue: mailSetings.host || "",
            rules: [{ required: true, message: 'Please Enter Outgoing Server Id' }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label="Port">
          {getFieldDecorator('port', {
            initialValue: mailSetings.port || "",
            rules: [{ required: true, message: 'Please Enter port number' }],
          })(
            <Input type="number" />
          )}
        </Form.Item>
        <Form.Item label="Username">
          {getFieldDecorator('username', {
            initialValue: mailSetings.username || "",
            rules: [{ required: true, message: 'Please Enter User Name' }]
          })(
            <Input />
          )}
        </Form.Item>
        <FormItem label="Password">
          {getFieldDecorator('password', {
            initialValue: mailSetings.password || "",
            rules: [{ required: true, message: 'Please Enter password' }]
          })(
            <Input />
          )}
        </FormItem>

        <FormItem label="Use Secure Connection">
          {getFieldDecorator('secureConnection', {
            initialValue: mailSetings.secureConnection || "",
          })(
            <Select >
              <Option value='Yes'>Yes</Option>
              <Option value='No'>No</Option>
            </Select>
          )}
        </FormItem>
        <Form.Item label="Sender Address">
          {getFieldDecorator('senderAddress', {
            initialValue: mailSetings.senderAddress || "",
            rules: [{ message: 'Please Enter Sender Address' }]
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Update Settings
          </Button>
          <Button >
            Cancel
          </Button>
        </Form.Item>
      </Form>

    )
  }
}

export default Form.create()(MailSettingsComponent)
