import React from "react";
import cloneDeep from "lodash.clonedeep";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Tag} from "antd";
import moment from "moment";
import ReactEcharts from "../Echarts/Echarts";
import {
  getChartDataWithoutSelection,
  getChartDataWithSelection,
} from "../ChartCalls";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { funnelMockJson } from "../../Mock/MockJson";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { defaultRefreshTime } from '../constant.json'
import { Fragment } from "react";

class Funnel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if(this.requestCheck){
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear
    ) {
      this.getData(this.props, this.props.selectedThemeing);
    }
  }

  componentWillUnmount() {
    this.abortController.abort()
    clearInterval(this.timeTicket);
  }

  getData = async (props, theme) => {
    this.requestCheck = false
    // let graphData = [];
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false 
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = funnelMockJson;
      this.requestCheck = true
    } else {
    if (
      (graphprops &&
        graphprops.dashboardType === "dynamic" &&
        this.props.deviceId) ||
      (graphprops &&
        graphprops.dateExists === "true" &&
        this.props.selectionDate) ||
      (graphprops &&
        graphprops.monthExists === "true" &&
        this.props.selectionMonth) ||
      (graphprops &&
        graphprops.weekExists === "true" &&
        this.props.selectionWeek) ||
      (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
      (graphprops &&
        graphprops.dateRangeExists === "true" &&
        this.props.selectionDateRange) 
        ||
        (graphprops && graphprops.dayMonthYearExists === 'true' && this.props.dayMonthYear)
    ) {
      json = await getChartDataWithSelection(
        graphprops,
        this.props.deviceId,
        this.props.selectionDate,
        this.props.selectionMonth,
        this.props.selectionWeek,
        this.props.shift,
        this.props.selectionDateRange,
        this.props.dayMonthYear,
        this.abortController
      );
      this.requestCheck = true
    } else {
      json = await getChartDataWithoutSelection(graphprops,this.abortController);
      this.requestCheck = true
    }
  }

  if ((json && json.message) || (Array.isArray(json) && (json.length === 0) && 
  (graphprops.enableMock !== true))) {

    if (json.message) {
      errorInAPI = true
    } else if (json.length === 0) {
      noDataInDB = true
    }

  } else {
    let series = [];
    //  json = [{"interval_alias":"2020-04-08 00:00:03","tagname":"Vi_07_fuel_level_perc","tagvalue":90},
    //    {"interval_alias":"2020-04-08 00:00:03","tagname":"Vi_07_Pressure_level_perc","tagvalue":70},
    //    {"interval_alias":"2020-04-08 00:00:03","tagname":"Vi_08_level_perc","tagvalue":60},
    //    {"interval_alias":"2020-04-08 00:00:03","tagname":"Vi_09_level_perc","tagvalue":50},
    //    {"interval_alias":"2020-04-08 00:00:03","tagname":"Vi_10_level_perc","tagvalue":30},]

    if (json && Array.isArray(json)) {
      json.map((val, index) => {
        series.push({ value: val.tagvalue, name: val.tagname });
      });

      // let legendsdata = {
      //   orient: "vertical",
      //   x: "left",
      //   data: [],
      //    textStyle: {
      //      color: theme === "lightTheme" ? "black" : "white",
      //    },
        
      // };

      const option = cloneDeep(this.state.option);
      option.series[0].data = [...series];

      this.setState({
        option,
      });
    }
  };
  this.setState({
    errorInAPI,
    noDataInDB
  })
};

  render() {
    const {errorInAPI, noDataInDB,refreshDateTime } = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
            <NoRecordComponent/>
            : <ErrorComponent/>
            :
            <ReactEcharts
            option={this.state.option}
            style={{ height: "90%", width: "100%" }}
            className="react_for_echarts"
          />
        }
      </Fragment>
     
    );
  }
  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      title: {},
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c}%",
      },

      legend: {
        data: ["data1", "data2", "data3", "data4", "data5"],
        
        textStyle: {
          color: theme === "lightTheme" ? "black" : "white",
          fontSize: 18,
          fontWeight:"bold",
        },
      },
      calculable: true,
      series: [
        {
          name: "Funnel",
          type: "funnel",
          left: "10%",
          top: 60,

          bottom: 60,
          width: "80%",

          min: 0,
          max: 100,
          minSize: "0%",
          maxSize: "100%",
          sort: "descending",
          gap: 2,
          label: {
            normal: {
              show: true,
              position: "inside",
            },
            emphasis: {
              textStyle: {
                fontSize: 20,
              },
            },
          },
          labelLine: {
            normal: {
              length: 10,
              lineStyle: {
                width: 1,
                type: "solid",
              },
            },
          },
          itemStyle: {
            normal: {
              borderColor: "#fff",
              borderWidth: 1,
            },
          },
          data: [],
        },
      ],
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(Funnel);
