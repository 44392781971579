import React, { Component } from 'react'

import { StyledFooter, FooterAlignment, FooterCopyRight } from './styles'

class FooterLayout extends Component {
  render () {
    return (
      <div>
        <StyledFooter>
          <FooterAlignment>
            <FooterCopyRight>
              <span style={{ color: 'white' }}>© 2022 Rubus Digital Inc.</span>
            </FooterCopyRight>
          </FooterAlignment>
        </StyledFooter>
      </div>
    )
  }
}

export default FooterLayout
