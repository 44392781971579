import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import DeviceListComponent from './DeviceListComponent'
import { getAllAssetList } from '../../../selectors/asset'
import { getDeviceListSelector } from '../../../selectors/device'
import { getDeviceTypeListSelector } from '../../../selectors/deviceType'
import { getCurrentLanguage } from '../../../selectors/language'
import { getAllDeviceList, createDevice, updateDevice,deleteDevice } from '../../../modules/device/device.duck'
import { getDeviceTypeList } from '../../../modules/deviceType/deviceType.duck'
import { getAssetDeviceCache } from '../../../modules/asset/asset.duck'

import { getTagReferenceListSelector } from '../../../selectors/tagReference'
import { getConditionList } from '../../../modules/condition/condition.duck'
import { getTagReferenceList } from '../../../modules/tagReference/tagReference.duck'

const mapStateToProps = createStructuredSelector({
  // deviceDataSource: getDeviceList(),
  asset: getAllAssetList(),
  deviceList: getDeviceListSelector(),
  deviceTypeList: getDeviceTypeListSelector(),
  currentLanguage: getCurrentLanguage(),
  tagReferenceList: getTagReferenceListSelector()
  // conditionList : getConditionListSelector() 
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(
      {
        createDevice,
        updateDevice,
        deleteDevice,
        getTagReferenceList,
        getConditionList,
        getDeviceTypeList,
        getAssetDeviceCache,
        getAllDeviceList
      },
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceListComponent)
