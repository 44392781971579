import React from "react";
import axios from "axios";
import { Spin } from 'antd'

import localStorage from "../../utils/localStorage";
import Text from "./Text";

class SinglePlot extends React.Component {
  timeTicket = null;
  hmiConfig = "";

  constructor(props) {
    super(props);
    this.state = {
      config: {},
      annotations: [],
      shapesannotations: [],
      sldName: "",
      hmiName: "",
      hmiType: "",
      x: 0,
      y: 0,
      loading: false,
      payload: 0
    };
  }

  componentDidMount() {
    try {
      let hmiObj = {}
      let siteId = localStorage.get("currentSite");
      if (siteId === undefined) {
        siteId = "1"
      }
      if (this.props.match.params.hmiName && this.props.match.params.type) {

        this.setState({ hmiName: this.props.match.params.hmiName, hmiType: this.props.match.params.type || "dynamic" })
        hmiObj = {
          "hmiName": this.props.match.params.hmiName,
          "hmiType": this.props.match.params.type || "dynamic",
          "deviceCode": this.props.match.params.dynamicName,
          "SiteId": siteId
        }
      } else if (this.props.match.params.hmiName && !this.props.match.params.dynamicName) {
        this.setState({ hmiName: this.props.match.params.hmiName, hmiType: "static", name: this.props.match.params.hmiName })
        hmiObj = {
          "hmiName": this.props.match.params.hmiName,
          "hmiType": "static",
          "SiteId": siteId
        }
      }
      this.hmiMapping(hmiObj);

      let initialHMIConfig = require(`./mock`).config;
      this.hmiConfig = initialHMIConfig;
    } catch (e) { }
  }

  timeInterval = (hmiObj,response) => {
    this._updatehmiMapping(hmiObj);
    if(response.frequency){    
      if (this.timeTicket) {
        clearInterval(this.timeTicket);
      }
      this.timeTicket = setInterval(() => {
        this._updatehmiMapping(hmiObj);
      }, 1000 * response.frequency);
    }
 
  }

  hmiMapping = (payload) => {
    this.setState({ loading: true });
    
    const accessToken = localStorage.get('accessToken')
    const hmiMappingObject = {
      method: 'POST',
      url: `/api/hmi/list`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    }

    axios(hmiMappingObject)
      .then(async (response) => {
        let responseData = response.data && Array.isArray(response.data) && response.data[0] ? response.data[0] : {}
        let imagePath = ""
        if (responseData && responseData.background) {
          imagePath = await import(`./images/${responseData.background}.svg`).then(image => {
            return image.default
          }).catch((e) => {
            console.log(e);
          })
        }
        this.setState({
          loading: false,
          config: {
            ...responseData,
            background: imagePath
          }
        })
        this.timeInterval(payload,responseData)

      })
      .catch(function () {
        return ""
      })
  }

  _updatehmiMapping = (payload) => {
    const accessToken = localStorage.get('accessToken')
    const hmiMappingObject = {
      method: 'POST',
      url: `/api/hmi/hmiMapping`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    }
    axios(hmiMappingObject)
      .then((response) => {
        this.setState({
          annotations: response.data.annotations,
          shapesannotations: response.data.shapesannotations
        })
      })
      .catch(function () {
      })
  }


  componentDidUpdate(prevprops) {
    try {
      if (
        this.props.match.params.hmiName &&
        this.props.match.params.hmiName !== prevprops.match.params.hmiName &&
        this.props.match.params.type
      ) {

        this.setState({ hmiName: this.props.match.params.hmiName, hmiType: this.props.match.params.type || "dynamic" })
        let siteId = localStorage.get("currentSite");
        if (siteId === undefined) {
          siteId = "1"
        }
        let hmiObj = {
          "hmiName": this.props.match.params.hmiName,
          "hmiType": this.props.match.params.type || "dynamic",
          "deviceCode": this.props.match.params.dynamicName,
          "SiteId": siteId
        };
        this.hmiMapping(hmiObj);
        if (this.timeTicket) {
          clearInterval(this.timeTicket);
        }
        this.timeTicket = setInterval(() => {
          this._updatehmiMapping(hmiObj);
        }, 10000);
      } else if (
        this.props.match.params.hmiName !== prevprops.match.params.hmiName
      ) {
        let siteId = localStorage.get("currentSite");
        if (siteId === undefined) {
          siteId = "1"
        }
        this.setState({ hmiName: this.props.match.params.hmiName, hmiType: "static" })
        let hmiObj = {
          "hmiName": this.props.match.params.hmiName,
          "hmiType": "static",
          "SiteId": siteId
        };
        this.hmiMapping(hmiObj);
      
      }
    } catch (e) { }
  }

  componentWillUnmount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
  }

  render() {
    const { config, loading, payload } = this.state;
    const {
      fill
    } = this.state.config;

    return (
      <div>
        {loading === false ?
          <div>
            <svg viewBox={config.viewbox}>
              <image
                xlinkHref={config.background}
                width={config.width}
                height={config.height}
              />
              {this.state.shapesannotations &&
                this.state.shapesannotations.length > 0
                ? this.state.shapesannotations.map((annotations) => {
                  switch (annotations.type) {
                    case "rect":
                      return (
                        <rect
                          x={annotations.x}
                          y={annotations.y}
                          rx={annotations.rx}
                          ry={annotations.ry}
                          width={annotations.width}
                          height={annotations.height}
                          fill={annotations.fill}
                          fill-opacity={annotations.opacity}
                        />
                      );
                    case "circle":
                      return (
                        <circle
                          cx={annotations.cx}
                          cy={annotations.cy}
                          r={annotations.r}

                          fill={annotations.fill}
                          // fill={payload > 5 ? "yellow" : "red"}
                          fill-opacity={annotations.opacity}
                        />
                      );
                    case "ellipse":
                      return (
                        <ellipse
                          cx={annotations.cx}
                          cy={annotations.cy}
                          rx={annotations.rx}
                          ry={annotations.ry}
                          fill={annotations.fill}
                          // fill={payload > 7 ? "red" : "orange"}
                          fill-opacity={annotations.opacity}
                        />
                      );
                    default: return (null)
                  }

                })
                : ""}
              {this.state.annotations && this.state.annotations.length > 0
                ? this.state.annotations.map((annotation) => {
                  let {className} = annotation
                  return (
                    <Text
                      limits={annotation.limits}
                      x={annotation.x}
                      y={annotation.y}
                      fontFamily={config.fontFamily}
                      fontSize={config.fontSize}
                      fill={fill}
                      checkStatus={config.checkStatus}
                      displayName={annotation.displayName}
                      device={annotation.device}
                      conditions={annotation.conditions}
                      payload={annotation.payload || ""}
                      fontWeight={annotation.fontWeight}
                      hmiName={this.state.hmiName}
                      hmiType={this.state.hmiType}
                      className={annotation.className}
                    />
                  );
                })
                : ""}
            </svg>
          </div>
          :
          <div
            style={{
              textAlign: "center",
              marginTop: "20%"
            }}
          >
            <Spin spinning={loading} tip="Loading..." size="large"></Spin>
          </div>
        }
      </div>


    );
  }
}

export default SinglePlot;
