import React from 'react'

import history from '../commons/history'
import localStorage from '../utils/localStorage'
import { notification, Button } from 'antd'

export function jwtToken (errorMessage) {
  const key = `open${Date.now()}`
  const close = () => {
    localStorage.clear()
    history.push('/login')
    notification.close(key)
    window.location.reload()
  }
  const btn = (
    <Button type='primary' size='small' onClick={close}>Log Out</Button>
  )
  notification['success']({
    message: errorMessage,
    btn,
    key,
    duration: 0
  })
}
