export const types = {

  USERLOGIN_AUTH: '@AUTH/USERLOGIN',
  USERLOGIN_AUTH_SUCCESS: '@AUTH/USERLOGIN/SUCCESS',

  USERSIGNUP_AUTH: '@/USERSIGNUP_AUTH',
  USERSIGNUP_AUTH_SUCCESS: '@/USERSIGNUP_AUTH_SUCCESS',

  USER_CURRENT_MENU: '@USER/CURRENTMENU'

}
