import styled from "styled-components";
import { Link } from "react-router-dom";
import { Icon,Select } from "antd";

export const Button = styled.button`
  color: ${(props) => props.theme.fg};
  border: 2px solid ${(props) => props.theme.fg};
  background: ${(props) => props.theme.bg};
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border-radius: 3px;
`;
export const darkTheme = {
  fg: "#bfbfbf",
  hg: "#262626",
  hbg: "#8c8c8c",
  bc: "#1a3652",
  bg: "#1a3652",
  shadow: "#000000",
};

export const lightTheme = {
  fg: "rgba(0,0,0,.85)",
  bc: "#1a3652",
  bg: "#1a3652",
  hg: "#fff",
  hbg: "#d9d9d9",
  shadow: "#8c8c8c",
};
export const FullScreenIcon = styled(Icon)`
  float: right;
  color: ${(props) => props.theme.fg};
  margin-right: 10px;
  margin-top: -30px;
  cursor: pointer;
  font-size: 20px;
`;
export const DashboardDiv = styled.div`
  margin-top: -5px;
  min-height: 88.2vh;
`;
export const Title = styled.h3`
  color: ${(props) => props.theme.fg};
  background: ${(props) => props.theme.hg};
  font-family: Roboto-Medium;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
  padding: 0.7em 0em;
  margin: 0px;
`;
export const PanelDiv = styled.div`
  background-color: ${(props) => props.theme.hg} !important;
  box-shadow: 3px 3px 7px ${(props) => props.theme.shadow};
  -moz-box-shadow: 0px 2px 7px ${(props) => props.theme.shadow};
  -webkit-box-shadow: 0px 2px 7px ${(props) => props.theme.shadow};
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
`;

export const DashboardHeaderDiv = styled.div`
  height: 40px;
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
`;


export const ModalDiv = styled.div`
  background-color: ${(props) => props.theme.hg};
 
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
`;
export const DateRangePickerDiv = styled.div`
  margin-left: -20%;
  display: ${(props) => (props.showDateRange === false ? "none" : "block")};
`;

export const NoPanelmessage = styled.div`
  text-align: center;
  color: white;
  margin-top: 25%;
  font-size: 20px;
`;

export const StyledIFrame = styled.iframe`
  width: 402%;
  height: 400%;
  border: 0;
  position: absolute;
  top: 0;
  left: -0.5%;
  background: #191a1d;
  -webkit-transform: scale(0.5);
  transform: scale(0.25);
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  margin: 2px auto;
`;

export const LinkOverlay = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  :hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
  }
`;

export const SelectDropDown = styled(Select)`
  width: 100%;
`;
export const CommonDiv = styled.div`
  margin-top: 5px;
`;
