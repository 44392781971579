import React, { Fragment } from 'react';

import SinglePlot from "../../../HMI";
import { LinkOverlay } from "../CSS/style";
class Image extends React.Component {

    images(hmiName) {
        return (<div>
            <LinkOverlay
                to={{ pathname: `/rubus/singleHMI/static/${hmiName}` }}
            />
            <SinglePlot
                match={{
                    params: {
                        hmiName
                    }
                }} />
        </div>)
    }

    render() {
        let { graphprops } = this.props
        return (
            <Fragment>
                {
                    this.images(graphprops.image.path)
                }
            </Fragment >
        );
    }
}

export default (Image)