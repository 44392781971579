import localStorage from '../../utils/localStorage'
import axios from 'axios'

export default class condition {
  static getList () {
    const accessToken = localStorage.get("accessToken");
    const ParameterTypeObject = {
      method: "POST",
      url: `/api/masterconfiguration/master`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data:{
        "MasterName":"AssetDevice_Conditions",
        "ActionType": "read"
      }
    };
   return axios(ParameterTypeObject)
      .then(response => {
        return response.data 
      })
      .catch(function() {});
  }

}
