export function sideMenuFormat(slideMenu, flage) {
  let menuList = [];

  menuList = Object.keys(slideMenu).map(module => {
    let temp = {};
    temp.name = slideMenu[module].name;
    temp.path = slideMenu[module].path;
    temp.uid = `mod${Math.random() * 100}`;
    if (flage === "child") {
    } else {
      temp.icon = slideMenu[module].icon;
    }
    if (slideMenu[module].children) {
      temp.routes = sideMenuFormat(slideMenu[module].children, "child");
      return temp
    } else {
      return temp
    }
  });
  return menuList;
}
