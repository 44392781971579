import React from "react";
import moment from "moment";
import cloneDeep from "lodash.clonedeep";
import { connect } from "react-redux";
import axios from "axios";
import { createStructuredSelector } from "reselect";

import {Tag} from "antd";
import ReactEcharts from "../Echarts/Echarts";
import {
  getChartDataWithoutSelection,
  getChartDataWithSelection,
} from "../ChartCalls";
// import { excelDownloadUrl } from "./ExcelAdapters/const";
import { barMockJson } from "../../Mock/MockJson";
import { trendLabelTranslation } from "../../Utils/Adapter/translationAdapter";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import localStorage from "../../../../utils/localStorage";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { defaultRefreshTime } from '../constant.json';
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { Fragment } from "react";

class StackBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime:moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if(this.requestCheck){
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange
    ) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  getReportNametoDownload(excelData) {
    let payload = {
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm:SS"),
      data: excelData || [],
      ...this.props.graphprops.excelObj,
    };

    let accessToken = localStorage.get("accessToken");
    // const siteId = localStorage.get("currentSite");
    let options = {
      method: "POST",
      url: `/api/report/generateExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify(payload),
    };
    return axios(options)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return "";
      });
  }

  getData = async () => {
    this.requestCheck = false
    const option = cloneDeep(this.state.option);
    let objSeries = {};
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false 

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = barMockJson;
      this.requestCheck = true
    } else {

    if (
      (graphprops &&
        graphprops.dashboardType === "dynamic" &&
        this.props.deviceId) ||
      (graphprops &&
        graphprops.dateExists === "true" &&
        this.props.selectionDate) ||
      (graphprops &&
        graphprops.monthExists === "true" &&
        this.props.selectionMonth) ||
      (graphprops &&
        graphprops.weekExists === "true" &&
        this.props.selectionWeek) ||
      (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
      (graphprops &&
        graphprops.dateRangeExists === "true" &&
        this.props.selectionDateRange) ||
      (graphprops &&
        graphprops.dayMonthYearExists === "true" &&
        this.props.dayMonthYear)
    ) {
      json = await getChartDataWithSelection(
        graphprops,
        this.props.deviceId,
        this.props.selectionDate,
        this.props.selectionMonth,
        this.props.selectionWeek,
        this.props.shift,
        this.props.selectionDateRange,
        this.props.dayMonthYear,
        this.abortController
      );
      this.requestCheck = true
    } else {
      json = await getChartDataWithoutSelection(graphprops, this.abortController);
      this.requestCheck = true
    }
  }
    // let reportName = await this.getReportNametoDownload(
    //   defaultExcelAdapter(json)
    // );
  if ((json && json.message) || (Array.isArray(json) && (json.length === 0 )&& 
  (graphprops.enableMock !== true))) {

    if (json.message) {
      errorInAPI = true
    } else if (json.length === 0) {
      noDataInDB = true
    }

  } else {

    if (json && Array.isArray(json)) {
      json.map((val) => {
        if (!objSeries[val.tagname]) {
          let arry = [
            val.tagvalue,
            moment(val.interval_alias)
              .utc()
              .format("DD/MM/YYYY HH:mm:ss"),
          ];
          objSeries[val.tagname] = {
            name: val.tagname,
            type: "bar",
            stack: "总量",
            label: {
              show: true,
              position: "insideRight",
            },
            data: [arry],
          };
        } else {
          let arry = [
            val.tagvalue,
            moment(val.interval_alias)
              .utc()
              .format("DD/MM/YYYY HH:mm:ss"),
          ];
          objSeries[val.tagname].data.push(arry);
        }
      });
      let series = Object.keys(objSeries).map((tag) => objSeries[tag]);
      series = await trendLabelTranslation(
        series,
        this.props.translationCache,
        this.props.language
      );

      // if (graphprops.enableExcel) {
      //   const graphContext = this;
      //   option.toolbox.feature.myTool1 = {
      //     show: true,
      //     title: "Download Excel",
      //     icon:
      //       "path:M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494zM514.1 580.1l-61.8-102.4c-2.2-3.6-6.1-5.8-10.3-5.8h-38.4c-2.3 0-4.5.6-6.4 1.9-5.6 3.5-7.3 10.9-3.7 16.6l82.3 130.4-83.4 132.8a12.04 12.04 0 0 0 10.2 18.4h34.5c4.2 0 8-2.2 10.2-5.7L510 664.8l62.3 101.4c2.2 3.6 6.1 5.7 10.2 5.7H620c2.3 0 4.5-.7 6.5-1.9 5.6-3.6 7.2-11 3.6-16.6l-84-130.4 85.3-132.5a12.04 12.04 0 0 0-10.1-18.5h-35.7c-4.2 0-8.1 2.2-10.3 5.8l-61.2 102.3z",

      //     onclick: function() {
      //       graphContext.downLoadExcel();
      //     },
      //   };
      // }

      option.series = series;
      this.setState({
        option,
        refreshDateTime:moment(),
        // reportName: reportName && reportName.file ? reportName.file : "",
        // excelDownload: graphprops.enableExcel,
      });
    }
  };
  this.setState({
    errorInAPI,
    noDataInDB
  })
} 

  downLoadExcel = () => {
    const { reportName } = this.state;
    // let url = `${excelDownloadUrl}/${reportName}`;
    const link = document.createElement("a");
    // link.href = url;
    link.setAttribute("download", reportName); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
     

      xAxis: {
        type: "value",
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
      },
      yAxis: {
        type: "category",
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisTick: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisLabel: {
          color: theme === "lightTheme" ? "black" : "white",
        },
      },
      dataZoom: [
        {
          type: "slider",
          show: true,
          yAxisIndex: 0,
          start: 0,
          end: 100,

          backgroundColor: "#9a9191",
          textStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        {
          type: "inside",
          yAxisIndex: 0,
          start: 0,
          end: 100,
        },
      ],
      grid: {
        left: "1 %",
        right: "5%",
        bottom: "0%",
        containLabel: true,
      },
      legend: {
        textStyle: {
           color: theme === "lightTheme" ? "black" : "white" ,
           fontSize:18,
           fontWeight:"bold",
                },
      },
    };

    return option;
  };

  render() {
    const {errorInAPI, noDataInDB ,refreshDateTime} = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
          {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
         {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
            <NoRecordComponent/>
            : <ErrorComponent/>
            :
            <ReactEcharts
        option={this.state.option}
        notMerge={true}
        lazyUpdate={true}
        style={{ height: "80%", width: "100%" }}
        className="react_for_echarts"
      />
         }
      </Fragment>
      
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(StackBar);
