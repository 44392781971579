import React from "react";
import cloneDeep from "lodash.clonedeep";
import ReactEcharts from "../Echarts/Echarts";
import { connect } from "react-redux";
import { Spin } from "antd";
import { createStructuredSelector } from "reselect";
import moment from "moment";
import { Tag } from "antd";
import { getChartDataWithoutSelection, getChartDataWithSelection } from "../ChartCalls";
import { candleStickTranslation } from "../../Utils/Adapter/translationAdapter";
import { makeSelectThemeing } from "../../../../selectors/theme";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { singleCandleStickMock } from "../../Mock/MockJson";
import { defaultRefreshTime } from '../constant.json'
import { Fragment } from "react";

class SingleCandleStick extends React.Component {
  constructor(props) {
    super(props);
    let option = this.getInitialState();
    this.state = {
      option: option.option,
      data: [],
      loading: false,
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false, range: []
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  getInitialState = () => ({ option: this.getOption() });


  componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange
    ) {
      this.getData();
    }
  }

  getData = async () => {
    this.requestCheck = false
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false

    let json = await getChartDataWithoutSelection(this.props.graphprops, this.abortController);
    const option = cloneDeep(this.state.option);
    this.setState({ loading: true });

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = singleCandleStickMock;
      this.setState({ loading: false });
      this.requestCheck = true
    } else {
      if (
        (graphprops &&
          graphprops.dashboardType === "dynamic" &&
          this.props.deviceId) ||
        (graphprops &&
          graphprops.dateExists === "true" &&
          this.props.selectionDate) ||
        (graphprops &&
          graphprops.monthExists === "true" &&
          this.props.selectionMonth) ||
        (graphprops &&
          graphprops.weekExists === "true" &&
          this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
        (graphprops &&
          graphprops.dateRangeExists === "true" &&
          this.props.selectionDateRange)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.abortController
        );
        this.requestCheck = true
        this.setState({ loading: false });
      } else {
        json = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true
        this.setState({ loading: false });
      }
    }

    if ((json && json.message) || (Array.isArray(json) && (json.length === 0) &&
      (graphprops.enableMock !== true))) {

      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
      }

    } else {
      if (json && Array.isArray(json) && json.length > 0) {
        let tag_description = await candleStickTranslation(
          json && json[0] && json[0].tag_description,
          this.props.translationCache,
          this.props.language
        );
        // option.yAxis.min = this.props.graphprops.range[0];
        // option.yAxis.max = this.props.graphprops.range[1];


        // console.log("this.props.graphprops.singleCandleStick", this.props.graphprops.singleCandleStick.range);
        let stringRange = this.props.graphprops.dateRangeSingleCandleStick.range

        let range = JSON.parse(stringRange);
        this.setState({
          range: range
        })

        option.yAxis.min = range[0];
        option.yAxis.max = range[1];

        option.series[0].data[0][1] = json && Array.isArray(json) && json[0] && Number(json[0].tagvalue).toFixed(2);
        option.xAxis.data[0] = json && Array.isArray(json) && json[0] && Number(json[0].tagvalue).toFixed(2);
        option.xAxis.name = tag_description;
        //option.textStyle.color="green"
      }
      return this.setState({
        option: option,
        refreshDateTime: moment()

      });
    };

    this.setState({
      errorInAPI,
      noDataInDB
    })
  }

  componentWillUnmount() {
    this.abortController.abort()
    clearInterval(this.timeTicket);
  }

  getOption = () => {
    let { graphprops } = this.props
  
    let theme = this.props.selectedThemeing;
    let stringRange = this.props.graphprops.dateRangeSingleCandleStick.range
        
    let range = JSON.parse(stringRange);
    let min = range[0] || "";
    let max = range[1] || "";


    let option = {
      xAxis: {
        data: [""],

        axisLine: {
          show: false,
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },

        nameTextStyle: {
          color: "steelblue",
          fontSize: 20,
          padding: [10, 4, 5, 6],
        },

        textStyle: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },

        axisLabel: {
          color: theme === "lightTheme" ? "black" : "white",
          fontSize: 15,
        },

        name: "",
        nameLocation: "center",
        // data: [],
        axisTick: {
          show: false,
        },
        type: "category",
        // data: ['Mon'],
        show: true,
        // axisLine: {
        //   show: false,
        // },
      },

      yAxis: {
        min: -450,
        max: 450,
        interval: 150,
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" :"white",
            fontSize: 15,
          },
        },
      },
      series: [
        {
          // type: "k",
          itemStyle: {
            color:this.props.graphprops.dateRangeSingleCandleStick.titleColor[0],
            borderColor: this.props.graphprops.dateRangeSingleCandleStick.titleColor[0],
          },

          data: [
            [
              range !== undefined &&
                range !== null
                ? range[0]
                : -300,

              0,
              range[0],
              range !== undefined &&
                range !== null
                ? range[1]
                : 300,
            ],
          ],

          type: "candlestick",
          color: this.props.graphprops.dateRangeSingleCandleStick.titleColor[0],
        },
      ],
      toolbox: {
        showTitle: false,
        orient: "vertical",
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: "download",
            backgroundColor: "black",
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: "#8c8c8c",
              borderWidth: 2,
            },
          },
        },
      },
      // toolbox: {
      //   showTitle: false,
      //   orient: "vertical",
      //   feature: {
      //     saveAsImage: {
      //       title: "download",
      //       name: this.props.graphprops.title,
      //       backgroundColor: "black"
      //     }
      //   }
      // },
    };
    return option;
  };

  render() {
    let theme = this.props.selectedThemeing;
    let { loading, errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props
  
    // let {t} = this.props.graphprops
    // let data123 = JSON.parse("this.props.graphprops.singleCandleStick")

    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <span>
              {loading === false ? (
                <ReactEcharts
                  option={this.state.option}
                  style={{ height: "90%", width: "100%" }}
                  className="react_for_echarts"
                />
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    color: theme === "lightTheme" ? "black" : "white",
                    marginTop: "15%",
                  }}
                >
                  <h2 style={{ color: theme === "lightTheme" ? "black" : "white" }}>
                    <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                  </h2>
                </div>
              )}
            </span>
        }
      </Fragment>

    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(SingleCandleStick);
