import React, { Component } from 'react'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import {
    Tabs, Button, Icon, Input
} from 'antd'
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import ReportTable from './ReportTable'
import {
    reportTableCall
} from './ReportCalls'

import { makeSelectTheme, makeSelectThemeing } from '../../../../../selectors/theme'

import {
    StyledReport,
} from './style'

const { TabPane } = Tabs;

class Report extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reportName: '',
            reportJSON: [],
            craneList: [],
            currentDatasource: [],
            dashboardVisible: false,
            graph: {},
            selectedThemeing: this.props.selectedThemeing,
            selectedTheme: this.props.selectedTheme,
            dashboardPayload: {}
        }
    }

    componentWillMount() {
        let reportName = this.props.graphprops &&
            this.props.graphprops.report &&
            this.props.graphprops.report.ReportName
            ? this.props.graphprops.report.ReportName : ""
        this.getReportJSON(reportName, "initial", "Report");
    }

    // componentDidUpdate() {
    //     if (this.props.match.params.report !== this.state.reportName) {
    //         let reportType = this.props.match.params.reportType
    //         this.getReportJSON(this.props.match.params.report, "initial", reportType);
    //     }
    // }

    getReportJSON = async (reportName, name, reportType) => {
        let reportJSON = await reportTableCall(reportName, reportType)
        if (name === 'initial' && !this.state.reportName) {
            let reportObject = await this.tableColumnAdapter(reportJSON)
            this.setState({ reportName, reportJSON: reportObject })
            if (reportJSON.length > 0) {
                this.setState({
                    currentTab: reportJSON[0].TabName
                })
            }
        } else {
            let reportObject = await this.tableColumnAdapter(reportJSON)
            this.setState({ reportName, reportJSON: reportObject })
        }
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => record && record[dataIndex] && record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select())
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        )
    })

    handleSearch = (selectedKeys, confirm) => {
        confirm()
        this.setState({ searchText: selectedKeys[0] })
    }

    handleReset = clearFilters => {
        clearFilters()
        this.setState({ searchText: '' })
    }

    callback = async (currentTab) => {
        this.setState({
            currentTab
        })
    }

    tableColumnAdapter = (reportJSON) => {
        if (reportJSON && Object.entries(reportJSON).length !== 0 && !reportJSON.stack) {
            reportJSON.map(async (reportItem, key) => {
                let { JSON } = reportItem
                if (JSON && JSON.columns) {
                    let children = []
                    await JSON.columns.map(async (columnsItem, key1) => {
                        if (JSON.tableConfiguration && JSON.tableConfiguration.style && JSON.tableConfiguration.style.colSpan && JSON.tableConfiguration.style.colSpan.length > 0) {//colspan
                            let styleObj = {}
                            if (!JSON.tableConfiguration || !JSON.tableConfiguration.style) {
                                styleObj = {
                                    "textAlign": "center",
                                    //  "fontWeight": "normal",
                                    "evenRowColor": "rgb(251, 250, 250)",
                                    "oldRowColor": "#fff"
                                }
                            } else {
                                styleObj = {
                                    "textAlign": JSON.tableConfiguration.style.textAlign ? JSON.tableConfiguration.style.textAlign : "center",
                                    //  "fontWeight": JSON.tableConfiguration.style.fontWeight ? JSON.tableConfiguration.style.fontWeight : "normal",
                                    "evenRowColor": JSON.tableConfiguration.style.evenRowColor ? JSON.tableConfiguration.style.evenRowColor : "rgb(251, 250, 250)",
                                    "oldRowColor": JSON.tableConfiguration.style.oldRowColor ? JSON.tableConfiguration.style.oldRowColor : "#fff",
                                    "colSpan": JSON.tableConfiguration.style.colSpan ? JSON.tableConfiguration.style.colSpan : []
                                }
                            }

                            let colSpanObject = JSON.tableConfiguration.style.colSpan
                            colSpanObject.map(async (colSpan) => {
                                if (columnsItem.key === colSpan.columnKey || (key1 !== 0 ? JSON.columns[key1 - 1].key === colSpan.columnKey : false)) {
                                    let column = await this.getGenerateColSpanJSON(columnsItem, styleObj, colSpan)
                                    reportJSON[key].JSON.columns[key1] = column
                                } else {
                                    let column = await this.getGenerateJSON(columnsItem, styleObj)
                                    reportJSON[key].JSON.columns[key1] = column

                                }
                            })

                        } else {
                            let styleObj = {}
                            if (!JSON.tableConfiguration || !JSON.tableConfiguration.style) {
                                styleObj = {
                                    "textAlign": "center",
                                    //  "fontWeight": "normal",
                                    "evenRowColor": "rgb(251, 250, 250)",
                                    "oldRowColor": "#fff"
                                }
                            } else {
                                styleObj = {
                                    "textAlign": JSON.tableConfiguration.style.textAlign ? JSON.tableConfiguration.style.textAlign : "center",
                                    // "fontWeight": JSON.tableConfiguration.style.fontWeight ? JSON.tableConfiguration.style.fontWeight : "normal",
                                    "evenRowColor": JSON.tableConfiguration.style.evenRowColor ? JSON.tableConfiguration.style.evenRowColor : "rgb(251, 250, 250)",
                                    "oldRowColor": JSON.tableConfiguration.style.oldRowColor ? JSON.tableConfiguration.style.oldRowColor : "#fff",
                                    "colSpan": JSON.tableConfiguration.style.colSpan ? JSON.tableConfiguration.style.colSpan : []
                                }
                            }
                            if (columnsItem.children) {
                                children = []
                                children = columnsItem.children.map((child) => {
                                    let column = this.getGenerateJSON(child, JSON.tableConfiguration.style)
                                    return column
                                })
                                Promise.all().then(
                                    results => {
                                        console.log("Promise", results);
                                    }
                                ).catch(e => {
                                    console.log("Error");
                                })
                                reportJSON[key].JSON.columns[key1].title = reportJSON[key].JSON.columns[key1].process ?
                                    <div><span style={{ marginRight: "10px" }}>{reportJSON[key].JSON.columns[key1].title}</span><Icon type="dashboard"
                                        onClick={() => {
                                            this.openDashboard(reportJSON[key].JSON.columns[key1])
                                        }}
                                        style={{ cursor: "pointer" }}
                                    /></div> : reportJSON[key].JSON.columns[key1].title
                                reportJSON[key].JSON.columns[key1].children = children
                            } else {
                                let column = await this.getGenerateJSON(columnsItem, styleObj)
                                reportJSON[key].JSON.columns[key1] = column
                            }
                        }
                    })
                }
            })
        }
        return reportJSON
    }

    getGenerateJSON = (columns, tableConfig) => {
        let { textAlign, fontWeight } = tableConfig
        if (columns && columns.key && columns.key === 'Status') {
            return {
                ...columns,
                onFilter: (value, record) => {
                    return record[columns.key] && record[columns.key].indexOf(value) === 0
                },
                ...(columns.rowFilter ? this.getColumnSearchProps(columns.key) : {}),
                render(text) {
                    return {
                        children: <div style={{ fontSize: '15px', textAlign: 'center' }}>    {
                            text === true || text === "true" ?
                                <Button style={{ backgroundColor: "#389e0d", color: "#fff" }} >Avaliable</Button> : <Button type="danger">Offline</Button>
                        }   </div>,
                    };
                },

            }
        } else if (columns && columns.condition && columns.condition === true) {
            return {
                ...columns,
                onFilter: (value, record) => {
                    return record[columns.key] && record[columns.key].indexOf(value) === 0
                },
                ...(columns.rowFilter ? this.getColumnSearchProps(columns.key) : {}),
                render(text, record) {
                    if (record && record.durationType && record.durationType === 'daily') {
                        if (columns && columns.conditionType && columns.conditionType === 'combineColumns') {
                            let combineColumnList = columns && columns.combineColumnList ? columns.combineColumnList : []
                            return {
                                props: {
                                    style: { background: columns.background },
                                },
                                children: <div style={{ textAlign, fontWeight, color: record && record.color ? record.color : "black" }}>
                                    {combineColumnList && combineColumnList[0] && record[combineColumnList[0]] ?
                                        moment.utc(record[combineColumnList[0]]).local().format("HH:mm:ss") : ""}
                                    - {combineColumnList && combineColumnList[1] && record[combineColumnList[1]] ?
                                        moment.utc(record[combineColumnList[1]]).local().format("HH:mm:ss") : ""}
                                </div>,
                            };
                        } else {
                            return {
                                props: {
                                    style: { background: columns.background },
                                },
                                children: <div style={{ textAlign, fontWeight, color: record && record.color ? record.color : "black" }}>
                                    {text !== "null" ? text : ""}
                                </div>,

                            };
                        }
                    } else if (record && record.durationType && (record.durationType === 'monthly' || record.durationType === 'yearly')) {
                        let generatedValue = text || ""
                        if (record && record.durationType && record.durationType === 'monthly') {
                            generatedValue = moment.utc(text).local().format("YYYY-MM-DD")
                        } else if (record && record.durationType && record.durationType === 'yearly') {
                            generatedValue = moment.utc(text).local().format("MMMM")
                        }
                        return {
                            props: {
                                style: { background: columns.background },
                            },
                            children: <div style={{ textAlign, fontWeight, color: record && record.color ? record.color : "black" }}>
                                {generatedValue ? generatedValue : ""}
                            </div>
                        };
                    } else {
                        return {
                            props: {
                                style: { background: columns.background },
                            },
                            children: <div style={{ textAlign, fontWeight, color: record && record.color ? record.color : "black" }}>
                                {text !== "null" ? text : ""}
                            </div>
                        };
                    }
                }
            }
        } else {
            return {
                ...columns,
                onFilter: (value, record) => {
                    return record[columns.key] && record[columns.key].indexOf(value) === 0
                },
                ...(columns.rowFilter ? this.getColumnSearchProps(columns.key) : {}),
                render(text, record) {
                    return {
                        props: {
                            style: { background: columns.background },
                        },
                        children: <div style={{ textAlign, fontWeight, color: record && record.color ? record.color : "black" }}>{text !== "null" ? text : ""}</div>,
                    };
                }
            }
        }
    }

    render() {
        let { reportJSON, currentTab } = this.state
        return (
            <StyledReport style={{ minHeight: window.innerHeight - 173, padding: "0px 30px" }}>

                {
                    reportJSON && reportJSON.length > 1 ?
                        (<Tabs tabPosition={this.state.tabPosition} onChange={this.callback} style={{ marginTop: '10px' }} >
                            {
                                this.state.reportJSON.map((tab) => {
                                    let tabObject = {
                                        tab,
                                        ...tab.JSON,
                                        currentTab: currentTab
                                    }
                                    return <TabPane tab={tab.TabName} key={tab.TabName}>
                                        {
                                            tab.JSON ?
                                                <ReportTable {...tabObject} />
                                                : null
                                        }
                                    </TabPane>
                                })
                            }
                        </Tabs>)
                        :
                        <div>
                            {
                                reportJSON && reportJSON[0] ?
                                    (<div>
                                        {
                                            <ReportTable {...{
                                                ...reportJSON[0].JSON
                                            }} />
                                        }

                                    </div>)
                                    : <div />
                            }
                        </div>
                }

            </StyledReport>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    selectedThemeing: makeSelectThemeing(),
    selectedTheme: makeSelectTheme(),
});

export default connect(mapStateToProps)(Report);