import React from 'react'
import ImgCrop from 'antd-img-crop'
import { Upload, Icon, message, Form, InputNumber, Select } from 'antd'
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getCurrentLanguage, getTranslationCache } from "../../../selectors/language";
import { getUserProfile } from "../../../selectors/layout";
import { constant } from "./constants";


import localStorage from '../../../utils/localStorage'

const { Option } = Select

const formItemLayout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 12 },
  },
};

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}


class LogoUploadFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      orgid: '',
      width: 1000,
      height: 250,
      translationCache: props.translationCache || [],
      language: props.language || "English",
      userProfile: props && props.userProfile ? props.userProfile : {},
      organisationList: []
    };
  }

  componentDidMount() {
    this._getOrganisationList(this.props.userProfile)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache || prevProps.language !== this.props.language) {
      this.setState({
        translationCache: this.props.translationCache, language: this.props.language
      });
    }
    if (this.props.userProfile !== prevProps.userProfile) {
      this._getOrganisationList(this.props.userProfile)
    }
  }


  beforeUpload = (file) => {
    let { orgid } = this.state
    if (orgid) {
      const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png')
      if (!isJPG) {
        message.error('You can only upload JPG file!')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!')
      }
      return isJPG && isLt2M
    } else {
      message.warn("Please select Organisation")
    }

  }

  _getOrganisationList = (userProfile) => {
    let organisationList =
      this.props &&
        userProfile &&
        userProfile.belongsTo &&
        userProfile.belongsTo.organization ?
        userProfile.belongsTo.organization
        : null
    this.setState({ organisationList });
  }


  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true })
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false
        })
      )
    }
  };

  setParameter = (parameter, value) => {
    if (parameter === 'width') {
      this.setState({
        width: value
      })
    } else if (parameter === 'height') {
      this.setState({
        height: value
      })
    }
  }
  
  translation = (keyword) => {
    let { translationCache } = this.props
    return translationCache &&
      translationCache[keyword] ?
      translationCache[keyword] : keyword
  }

  onOrganisationChange = (orgid) => {
    this.setState({
      orgid
    })
  }

  render() {
    const {imageUrl, width, height, organisationList } = this.state

    const { getFieldDecorator } = this.props.form
    const token = localStorage.get('accessToken')
    var Authorization = 'Bearer ' + token
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    )

    return (
      <div>
        <Form {...formItemLayout}>
          {/* <Form.Item label="Organisation">  */}
         <Form.Item label={this.translation("Organisation")}>  
            {getFieldDecorator('organization', {
              rules: [{ required: true, message: "Select Organisation" }]
            })(
              <Select placeholder="Select Organisation" style={{ width: '200px' }} onChange={this.onOrganisationChange}>
                {
                  organisationList && Object.keys(organisationList).map((item) => (
                    <Option value={item}>{organisationList[item].Name}</Option>
                  ))
                }
              </Select>
            )}
          </Form.Item>
          <Form.Item label={this.translation(constant.Image_Width)}>
            {getFieldDecorator('width', {
              rules: [
                {
                  required: true,
                  message: 'Please input your items',
                },
              ],
            })(<InputNumber style={{ width: '200px' }} max={'5000'} value={width} onChange={(e) => this.setParameter('width', e)} />)}
          </Form.Item>
          <Form.Item label={this.translation(constant.Image_Height)}>
            {getFieldDecorator('height', {
              rules: [
                {
                  required: true,
                  message: 'Please input your items',
                },
              ],
            })(<InputNumber max={'5000'} style={{ width: '200px' }} value={height} onChange={(e) => this.setParameter('height', e)} />)}
          </Form.Item>

          <Form.Item label={this.translation(constant.Upload)}>
            {getFieldDecorator('upload', {

            })(<span><ImgCrop width={width} height={height}>
              <Upload
                name="logo"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={`/api/organisation/logoUpload?Id=${this.state.orgid}`}
                headers={{
                  Authorization: Authorization
                }}
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
              >
                {imageUrl ? <img src={imageUrl} height='100px' alt="avatar" /> : uploadButton}
              </Upload>
            </ImgCrop>
              ({width}* {height})
             </span>)}
          </Form.Item>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  userProfile: getUserProfile(),

});


export default connect(mapStateToProps)(Form.create()(LogoUploadFile));

