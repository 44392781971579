import React from "react";
import cloneDeep from "lodash.clonedeep";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Tag } from "antd";
import moment from "moment";
import ReactEcharts from "../Echarts/Echarts";
import { trendLabelTranslation } from "../../Utils/Adapter/translationAdapter";
import {
  getChartDataWithoutSelection,
  getChartDataWithSelection,
} from "../ChartCalls";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { donutMockJson } from "../../Mock/MockJson";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { defaultRefreshTime } from '../constant.json'
import { Fragment } from "react";

class Donut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }

  // componentWillReceiveProps(props) {
  //     this.getData();
  //     this.state = {
  //         option: this.getOption(),
  //         data: []
  //     };
  // }
  componentDidUpdate(prevProps) {
    if (
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange
    ) {
      this.getData(this.props, this.props.selectedThemeing);
    }
  }
  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <ReactEcharts
              option={this.state.option}
              style={{ height: "90%", width: "100%" }}
              className="react_for_echarts"
            />
        }
      </Fragment>

    );
  }

  componentWillUnmount() {
    this.abortController.abort()
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
  }

  getData = async (props, theme) => {
    this.requestCheck = false
    // let graphData = [];
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = donutMockJson;
      this.requestCheck = true
    } else {
      if (
        (graphprops &&
          graphprops.dashboardType === "dynamic" &&
          this.props.deviceId) ||
        (graphprops &&
          graphprops.dateExists === "true" &&
          this.props.selectionDate) ||
        (graphprops &&
          graphprops.monthExists === "true" &&
          this.props.selectionMonth) ||
        (graphprops &&
          graphprops.weekExists === "true" &&
          this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
        (graphprops &&
          graphprops.dateRangeExists === "true" &&
          this.props.selectionDateRange)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController
        );
        this.requestCheck = true
      } else {
        json = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true
      }
    }


    // json = [
    //   {
    //     interval_alias: "2020-04-08 15:31:33",
    //     tagname: "Bearing In Board",
    //     tagvalue: 81,
    //   },
    //   {
    //     interval_alias: "2020-04-08 15:31:33",
    //     tagname: "Bearing Out Board",
    //     tagvalue: 43,
    //   },
    // ];
    if ((json && json.message) || (Array.isArray(json) && (json.length === 0 &&
      graphprops.enableMock !== true))) {

      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
      }

    } else {
      let series = [];
      if (json && Array.isArray(json)) {
        json.map((val, index) => {
          series.push({ value: val.tagvalue, name: val.tag_description });
        });
      }

      // let legendsdata = {
      //   orient: "vertical",
      //   x: "left",
      //   data: [],
      //   textStyle: {
      //     color: "white"
      //   }
      // };

      const option = cloneDeep(this.state.option);
      if (
        this.props.graphprops.colors !== null &&
        this.props.graphprops.colors !== undefined &&
        this.props.graphprops.colors.length !== 0
      ) {
        option.color = [...this.props.graphprops.colors];
      }
      series = await trendLabelTranslation(
        series,
        this.props.translationCache,
        this.props.language
      );
      option.series[0].data = [...series];
      this.setState({
        option,
        refreshDateTime: moment()
      });
    };
    this.setState({
      errorInAPI,
      noDataInDB
    })
  };

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      // color:["#FFD12A","#007FFF","#87A96B"]
      //   color:["#ff4d4f","#8c8c8c","#87A96B"],
      //color: this.props.graphprops.colors > 0 ? this.props.graphprops.colors : ["#ff4d4f","#0050b3","#87A96B"],

      tooltip: {
        trigger: "item",
        formatter: "{b}: {c} ({d}%)",
      },
      legend: {
        textStyle: {
          color: theme === "lightTheme" ? "black" : "white",
          fontSize: 18,
          fontWeight: "bold",
        },
      },
      toolbox: {
        showTitle: false,
        // orient: "vertical",
        itemSize: 25,
        feature: {
          saveAsImage: {
            // backgroundColor: "black",
            title: "download",
            backgroundColor: theme === "lightTheme" ? "black" : "white",
            name: this.props.graphprops.title,
            iconStyle: {
              // color: "none",
              borderColor: "#8c8c8c",
              borderWidth: 2,
            },
          },
        },
      },
      series: [
        {
          name: this.props.graphprops.title,
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: true,
          label: {
            // formatter: ' {b|{b}：}{c}  {per|{d}%}  ',
            formatter: " {b|{c}}  {per|{d}%}  ",

            color: theme === "lightTheme" ? "black" : "white",
            backgroundColor: "#eee",
            borderColor: "#aaa",
            borderWidth: 1,
            borderRadius: 4,
            // shadowBlur:3,
            // shadowOffsetX: 2,
            //     shadowOffsetY: 2,
            //     shadowColor: '#999',
            padding: [0, 4],
            rich: {
              b: {
                fontSize: 14,
                fontWeight: "bold",
                color: theme === "lightTheme" ? "black" : "white",
                lineHeight: 22,
                backgroundColor: "#c23531",
                padding: [3, 5],
                borderRadius: 2,
              },
              per: {
                fontSize: 14,
                fontWeight: "bold",
                color: theme === "lightTheme" ? "black" : "white",
                backgroundColor: "#52c41a",
                padding: [3, 5],
                borderRadius: 2,
              },
            },
          },
          data: [],
        },
      ],
    };

    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing()
});

export default connect(mapStateToProps)(Donut);
