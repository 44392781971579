import axios from 'axios'
import localStorage from '../../utils/localStorage'

export async function getTrendData(config) {
	const siteId = localStorage.get('currentSite');
	const token = localStorage.get('accessToken');
	var Authorization = 'Bearer ' + token;

	const data = await fetch(`/api/trends/get`, {
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
			Authorization: Authorization,
		},
		body: JSON.stringify({
			...config,
			SiteId: siteId
		}),
	})
		.then(response => {
			return response.json();
		})
		.then(json => {
			return json;
		})
		.catch(() => { });
	return data;
}

export async function getTrendConfig() {
	const siteId = localStorage.get('currentSite');
	const token = localStorage.get('accessToken');
	var Authorization = 'Bearer ' + token;

	const data = await fetch(`/api/site/${siteId}/deviceType/cache`, {
		method: 'get',
		headers: {
			'Content-Type': 'application/json',
			Authorization: Authorization,
		},
	})
		.then(response => {
			return response.json();
		})
		.then(json => {
			return json;
		})
		.catch(() => { });

	return data === undefined ? [] : data;
}

export async function getParameterList(param) {
	let device = param.map(Number)
	const siteId = localStorage.get('currentSite');
	const token = localStorage.get('accessToken');
	var Authorization = 'Bearer ' + token;
	const parameterGroup = {
		method: 'POST',
		url: `/api/deviceParameter/deviceParameters`,
		headers: {
			Authorization: Authorization,
		},
		data: { deviceId: device, siteId: siteId },
	};
	return axios(parameterGroup).then((res) => {
		return res
	}).catch((err) => {
		console.log("err");
		return {}
	})
}

export async function getbookmarkList(Module) {
	const siteId = localStorage.get('currentSite');
	const token = localStorage.get('accessToken');
	var Authorization = 'Bearer ' + token;
	const parameterGroup = {
		method: 'POST',
		url: `/api/bookmark/list`,
		headers: {
			Authorization: Authorization,
		},
		data: {
			SiteId: siteId,
			"Module": "EMS"
		}
	};
	return axios(parameterGroup).then(res => {
		return res
	}).catch(err => {
		console.log("err", err);
		return {
			data: []
		}
	});
}

export async function createBookmark(params) {
	const siteId = localStorage.get('currentSite');
	const token = localStorage.get('accessToken');
	var Authorization = 'Bearer ' + token;
	const parameterGroup = {
		method: 'POST',
		url: `/api/bookmark/insert`,
		headers: {
			Authorization: Authorization,
		},
		data: {
			...params,
			"SiteId": siteId,
			"Module": "EMS"
		}
	};

	return axios(parameterGroup)
		.then(res => {
			return res.data
		}).catch(err => {
			console.log("err", err);
			return {
				data: {}
			}
		});
}

export async function updateBookmark(params) {
	const siteId = localStorage.get('currentSite');
	const token = localStorage.get('accessToken');
	var Authorization = 'Bearer ' + token;
	const parameterGroup = {
		method: 'PATCH',
		url: `/api/bookmark/update`,
		headers: {
			Authorization: Authorization,
		},
		data: {
			...params,
			"SiteId": siteId
		},
	};

	return axios(parameterGroup)
		.then(res => {
			return res.data
		}).catch(err => {
			console.log("err", err);
			return {
				data: {}
			}
		});
}

export async function deleteBookmark(Name, Id) {
	const siteId = localStorage.get('currentSite');
	const token = localStorage.get('accessToken');
	var Authorization = 'Bearer ' + token;
	const parameterGroup = {
		method: 'POST',
		url: `/api/bookmark/delete`,
		headers: {
			Authorization: Authorization,
		},
		data: {
			Name,
			Id,
			"SiteId": siteId
		}
	};

	return axios(parameterGroup)
		.then(res => {
			return res
		}).catch(err => {
			console.log("err", err);
			return {
				data: {}
			}
		});
}

export async function getbookMarkByname(bookmarkId) {
	const siteId = localStorage.get('currentSite');
	const token = localStorage.get('accessToken');
	var Authorization = 'Bearer ' + token;
	const parameterGroup = {
		method: 'POST',
		url: `/api/bookmark/getById`,
		headers: {
			Authorization: Authorization,
		},
		data: {
			Id: bookmarkId,
			SiteId: siteId
		},
	};

	return axios(parameterGroup)
		.then(res => {
			return res.data
		}).catch(err => {
			console.log("err", err);
			return {
				data: {}
			}
		});
}
export async function getDeviceList(Module) {
	let siteId = localStorage.get('currentSite');
	siteId = siteId ? siteId : 1
	const token = localStorage.get('accessToken');
	var Authorization = 'Bearer ' + token;
	const deviceListData = {
		method: 'POST',
		url: `/api/device/cache`,
		headers: {
			Authorization: Authorization,
		},
		data: {
			"SiteId": siteId,
			"Module": Module || "EMS"
		}
	};

	return axios(deviceListData).then(response => {
		return response.data
	}).catch(err => {
		console.log("err", err);
	})

}
