import React from 'react'
import moment from 'moment'
import { DatePicker, Radio, Menu, Select } from 'antd'

import { DatePickerSlider } from './style'

const { Option } = Select;

class DayMonthYearSelection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentDate: moment(),
            endDate: moment(),
            disableNext: false,
            currentTab: 'today',
            month: moment().format('MMMM'),
            year: moment().year(),
            payload: {
                date: moment().format('YYYY-MM-DD'),
                month: moment().format('MMMM'),
                year: props.selectionWidget && props.selectionWidget.year ? props.selectionWidget.year : moment().format('YYYY'),
                days: moment().daysInMonth(),
                startDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                duration: '1 hour',
                durationType: 'daily',
                currentDateData: [],
            } || {},
            selectionWidget: props.selectionWidget
        };
    }

    componentDidMount() {
        let selectionWidget = this.props.selectionWidget
        this.setFilterData(selectionWidget)
    }

    componentDidUpdate = (prevprops) => {
        if (prevprops.selectionWidget !== this.props.selectionWidget) {
            let selectionWidget = this.props.selectionWidget
            this.setFilterData(selectionWidget)
        }
    }

    setFilterData = (selectionWidget) => {
        if (selectionWidget && selectionWidget.durationType === "daily") {
            this.onChange(moment(selectionWidget.endDate, "YYYY-MM-DD HH:mm:ss"))
        } else if (selectionWidget.durationType === "month" || selectionWidget.durationType === "monthly") {
            let currentMonth = selectionWidget.month
            let currentYear = selectionWidget.year
            this.handleMenuClick(selectionWidget.month, "month", currentYear)
            this.setState({
                currentTab: "month",
                month: currentMonth,
                year: currentYear
            })
        } else if (selectionWidget.durationType === "year" || selectionWidget.durationType === "yearly") {
            let currentYear = selectionWidget.year
            this.handleMenuClick(selectionWidget.year, "year")
            this.setState({
                currentTab: "year",
                year: currentYear
            })
        }
    }

    disabledDate = (current) => {
        return current > moment().startOf('day').add(1, 'day');
    }

    onChange = async (date) => {
        let startDateSelected = moment(date).format('YYYY-MM-DD 00:00:00')
        let endDateSelected = moment(date).format('YYYY-MM-DD 23:59:59')
        let payload = {
            date: date.format('YYYY-MM-DD'),
            days: moment(date).daysInMonth(),
            startDate: moment(startDateSelected).utc().format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(endDateSelected).utc().format('YYYY-MM-DD HH:mm:ss'),
            duration: '1 hour',
            durationType: 'daily',
            month: moment(date).format('MMMM'),
            year: moment(date).year()
        }

        this.setState({ currentDate: date, payload })
        this.props.selectionpayload(payload, "Day")
    }

    onRadioChange = (e) => {
        if (e.target.value === 'month') {
            let currentMonth = moment().format('MMMM')
            let currentYear = moment().year()
            this.handleMenuClick(currentMonth, 'month', currentYear)
            this.setState({
                currentTab: e.target.value,
                month: currentMonth,
                year: currentYear
            })
        } else if (e.target.value === 'year') {
            let currentYear = moment().year()
            this.handleMenuClick(currentYear, 'year')
            this.setState({
                currentTab: e.target.value,
                year: currentYear
            })
        } else {
            this.onChange(moment())
            this.setState({
                currentTab: e.target.value,
            })
        }
    }

    getNavMenuItems = (menusData, parent) => {
        if (!menusData) {
            return []
        }
        return menusData
            .map(item => {
                return <Menu.Item key={item} >{item}</Menu.Item>
            })
            .filter(item => item)
    };

    handleMenuClick = async (value, parent, year) => {
        if (parent === 'month') {
            // let currentYear = this.state.year
            let month = moment(value, 'MMMM').month()
            let payload = {
                date: null,
                month: moment([year, month]).startOf('month').format('MMMM'),
                year: moment([year, month]).format('YYYY'),
                days: moment([year, month]).daysInMonth(),
                startDate: moment([year, month]).startOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment([year, month]).endOf('month') === moment() ?
                    moment([year, month]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss') :
                    moment([year, month]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
                duration: '1 day',
                durationType: 'monthly',
            }
            this.setState({ currentDate: value, payload, month: value, })
            this.props.selectionpayload(payload, "Month")
        } else if (parent === 'year') {

            let payload = {
                date: null,
                month: null,
                year: moment([value]).year() === moment().year() ?
                    moment().format('YYYY') :
                    moment([value, 11]).endOf('month').format('YYYY'),
                days: (moment([value]).year() === moment().year() ?
                    moment().daysInMonth() :
                    moment([value, 11]).daysInMonth()),
                startDate: moment([value, 0]).startOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
                endDate:
                    //  moment([value]).year() === moment().year() ?
                    //     moment([value, 11]).format('YYYY-MM-DD 23:59:59') :
                    moment([value, 11]).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss'),
                duration: '1 month',
                durationType: 'yearly',
            }
            this.setState({ currentDate: value, payload, year: value, })
            this.props.selectionpayload(payload, "Year")
        }
    }

    componentWillUnmount() {
        clearInterval(this.timeTicket)
    }


    render() {
        let { currentTab } = this.state

        if (moment().year() > 2019) {
            var yearList = [];
            for (var i = 2019; i <= moment().year(); i++) {
                yearList.push(i);
            }
            yearList.sort((a, b) => b - a)

        }
        return (
            <div style={{ height: '70%', marginTop: '40px' }}>
                <DatePickerSlider>
                    <Radio.Group value={this.state.currentTab} onChange={this.onRadioChange} style={{ marginLeft: '20px' }}>
                        <Radio.Button value="today">Daily</Radio.Button>
                        <Radio.Button value="month">Month</Radio.Button>
                        <Radio.Button value="year">Year</Radio.Button>
                    </Radio.Group>
                    {
                        currentTab === "today" ?
                            <DatePicker onChange={this.onChange} value={moment(this.state.currentDate)} disabledDate={this.disabledDate} />
                            : null
                    }
                    {
                        currentTab === "month" ?
                            <Select
                                value={this.state.month}
                                onChange={(e) => this.handleMenuClick(e, "month", this.state.year)}
                                style={{ width: '100px' }}
                            >
                                {
                                    moment.months().map((value) => {
                                        return <Option value={value}>{value}</Option>
                                    })
                                }
                            </Select>

                            : null
                    }
                    {
                        currentTab === "year" || currentTab === "month" ?
                            <Select
                                value={this.state.year}
                                onChange={(e) => this.handleMenuClick(e, "year")}
                                style={{ width: '100px' }}
                            >
                                {
                                    yearList.map((value) => {
                                        return <Option value={value}>{value}</Option>
                                    })
                                }
                            </Select>
                            : null
                    }
                </DatePickerSlider>
            </div>
        );
    }



}

export default DayMonthYearSelection;
