import React from 'react'
import axios from 'axios'
import { Table, Form, Button, Input, Drawer, Row, Col } from 'antd'

import localStorage from '../../../../utils/localStorage'
import { StyledApplication, DrawerFooter,AddTableButton } from '../style'

const FormItem = Form.Item

class TagReference extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      visible: false,
      tagReferenceList: props.tagReferenceList || [],
      currentLanguage: 'English',
      TGObject: {}
    }
  }

  componentWillMount () {
    this.props.actions.getTagReferenceList()
  }

  componentWillReceiveProps ({ tagReferenceList }) {
    this.setState({ tagReferenceList })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err) => {
      if (!err) {

      }
    })
  }

  showDrawer = () => {
    this.props.form.resetFields()
    this.setState({
      visible: true,
      TGObject: {}
    })
  };

  onClose = () => {
    this.props.form.resetFields()
    this.setState({
      visible: false
    })
  };

  resetForm = () => {
    this.props.form.resetFields()
  }

  showDrawerOldDevice = (e, row) => {
    this.props.form.resetFields()
    e.preventDefault()
    this.setState({
      TGObject: row,
      visible: true
    })
  };

  createTagReference = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this._saveTagReference(values)
      }
    })
  }

  updateTagReference = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this._updateTagReference(values)
      }
    })
  }

  _saveTagReference = (payload) => {

    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get('accessToken')
    const TGObject = {
      method: 'POST',
      url: `/api/site/${siteId}/parameterReference/create`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    }
    axios(TGObject)
      .then(() => {
        this.props.actions.getTagReferenceList()
        this.setState({
          visible: false
        })
      })
      .catch(function () {

      })
  }

  _updateTagReference = (payload) => {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get('accessToken')
    const TGObject = {
      method: 'PATCH',
      url: `/api/site/${siteId}/parameterReference/update/${payload._key}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    }
    axios(TGObject)
      .then(() => {
        this.props.actions.getTagReferenceList()
        this.setState({
          visible: false
        })
      })
      .catch(function () {
      })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { tagReferenceList, TGObject, visible } = this.state
    const columns = [{
      title: 'Name',
      dataIndex: 'title',
      key: 'title'
    }, {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    }
    ]

    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 338 }}>
        <AddTableButton type='primary' onClick={this.showDrawer}>Create Tag Reference</AddTableButton>
        <Drawer
          title={!TGObject._key ? 'Create Tag Reference' : 'Update Tag Reference'}
          placement="right"
          closable={true}
          width={500}
          onClose={this.onClose}
          visible={visible}
        >

          <Form layout='vertical' onSubmit={this.handleSubmit} >
            <Row>
              <Col span={24}>
                <FormItem label="Name">
                  {getFieldDecorator('title', {
                    initialValue: TGObject.title,
                    rules: [{ required: true, message: 'Please input your name!' }]
                  })(
                    <Input placeholder="Enter Name" />
                  )}
                  {getFieldDecorator('_id', {
                    initialValue: TGObject._id
                  })(
                    <Input style={{ display: 'none' }} />
                  )}
                  {getFieldDecorator('_key', {
                    initialValue: TGObject._key
                  })(
                    <Input style={{ display: 'none' }} />
                  )}
                  {getFieldDecorator('_rev', {
                    initialValue: TGObject._rev
                  })(
                    <Input style={{ display: 'none' }} />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label="Code">
                  {getFieldDecorator('code', {
                    initialValue: TGObject.code,
                    rules: [{ required: true, message: 'Please input your code!' }]
                  })(
                    <Input placeholder="Enter Code" disabled={TGObject._key}/>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
            </Row>

          </Form>
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}> Cancel</Button>
            {
              TGObject._key === undefined
                ? <Button onClick={this.createTagReference} type="primary">Create Tag Reference</Button>
                : <Button onClick={this.updateTagReference} type="primary">Update Tag Reference</Button>
            }
          </DrawerFooter>

        </Drawer>
        <Table
          size='medium'
          columns={columns}
          dataSource={tagReferenceList}
          pagination={(tagReferenceList.length > 5) ? {
            pageSize: '5'
          } : false}
          locale={{ emptyText: 'No Data' }}
          onRow={(record) => ({
            onClick: (e) => {
              e.stopPropagation()
              this.showDrawerOldDevice(e, record)
            }
          })}
        />
      </StyledApplication>
    )
  }
}

export default Form.create()(TagReference)
