import React, { Component, Fragment } from "react";
import { Input, Form, Select, Row, Button, message } from "antd";

import "./reportConfig.css";

let { Option } = Select;
const { TextArea } = Input;

class ApiCustomization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editObj: {},
      activeKey: [],
      dragDisabled: false,
      apiProperties: props.apiProperties || [],
      tableMaster: [],
      inputFields: [],
      payloadFields: [],
    };
    this.columns = {};
  }

  componentWillMount() {
    // this._getTableMasters()
  }

  componentDidUpdate(prevprops) {
    if (this.props.apiProperties !== prevprops.apiProperties) {
      this.setState({
        apiProperties: this.props.apiProperties,
      });
    }
  }

  handleFieldChange(value, colName) {
    const { apiProperties } = this.state;
    apiProperties[colName] = value;

    this.setState({
      apiProperties,
    });
    this.props.jsonSaveObject(apiProperties, "api");
  }

  handleChange(value, keyName, key) {
    const { apiProperties } = this.state;
    apiProperties[keyName][key] = value;

    this.setState({
      apiProperties,
    });
    // this.props.jsonSaveObject(apiProperties, "api");
  }

  apiSave = (e) => {
    const { apiProperties } = this.state;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.jsonSaveObject(apiProperties, "api");
        message.success("Api Validated Successfully");
        this.setState({
          activeKey: [],
        });
      }
    });
  };

  callback = (key) => {
    if (key && key.length <= 1) {
      this.setState({
        activeKey: key,
      });
    } else if (key.length > 1) {
      key = key[key.length - 1];
      this.setState({
        activeKey: [key],
      });
    }
  };
  
  handlePayloadChange = (key, event, objectkey) => {
    const { apiProperties } = this.state;
    if (objectkey === "key") {
      apiProperties.payload[event.target.value] = apiProperties.payload[key];
      delete apiProperties.payload[key];
      this.setState({ apiProperties });
    } else if (objectkey === "value") {
      apiProperties.payload[key] = event.target.value;
      this.setState({ apiProperties });
    }
    this.props.jsonSaveObject(apiProperties, "api");
  };
  handleAddPayloadFields = () => {
    let { payloadFields } = this.state;

    // apiProperties = {
    //   ...apiProperties,
    //   ["payload"]:{
    //   ...( apiProperties["payload"] ? apiProperties["payload"] : {}),
    //  "":""
    // },

    payloadFields.push({
      reportKey: "",
      reportValue: "",
    });

    this.setState({ payloadFields });
  };

  addPayload = (e) => {
    let inputFields = [
      {
        reportKey: "",
        reportValue: "",
      },
    ];

    this.setState({ inputFields });
  };
  handleAddFields = () => {
    const values = this.state.inputFields;
    values.push({ reportKey: "", reportValue: "" });
    this.setState(values);
  };

  handleRemoveFields = (index) => {
    const values = this.state.inputFields;
    values.splice(index, 1);
    this.setState(values);
  };
  handleMainPayloadRemoveFields = (index) => {
    let { apiProperties } = this.state;
    let { payload } = apiProperties;

    delete payload[index];

    this.setState({ apiProperties });
  };
  handlePayloadRemoveFields = (index) => {
    const values = this.state.payloadFields;
    values.splice(index, 1);
    this.setState(values);
  };
  handleInputChange = (index, event) => {
    const { inputFields, apiProperties } = this.state;

    inputFields[index][event.target.name] = event.target.value;

    this.setState({ inputFields });
    inputFields.map((item, index) => {
      apiProperties.payload[item.reportKey] = item.reportValue;
    });

    this.setState({ apiProperties });
    this.props.jsonSaveObject(apiProperties, "api");
  };
  UpdatePayload = () => {
    let { payloadFields, apiProperties } = this.state;
    payloadFields.map((item, index) => {
      apiProperties.payload[item.reportKey] = item.reportValue;
    });

    this.setState({ payloadFields: [] });
    this.setState({ apiProperties });
    this.props.jsonSaveObject(apiProperties, "api");
  };
  handlePayloadInputChange = (index, event) => {
    const { payloadFields } = this.state;

    payloadFields[index][event.target.name] = event.target.value;

    this.setState({ payloadFields });
    // payloadFields.map((item, index) => {
    //   apiProperties.payload[item.reportKey] = item.reportValue;
    // });
    // this.setState({ apiProperties });

    // this.props.jsonSaveObject(apiProperties, "api");
  };
  // submitPayload = () => {
  //   const {inputFields,apiProperties} = this.state
  //   // const { apiProperties } = this.state;
  //   inputFields.map((item, index) => {
  //     apiProperties.payload[item.reportKey] = item.reportValue;
  //   });
  //   this.setState({ apiProperties });
  //   this.props.jsonSaveObject(apiProperties, "api");
  // };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      apiProperties,
      inputFields,
      payloadFields,
    } = this.state;

    let item = apiProperties;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <div style={{ marginBottom: "50px" }}>
        {apiProperties ? (
          <div>
            <Form {...formItemLayout}>
              <Form.Item label="Uri :">
                {getFieldDecorator("uri", {
                  initialValue: item.uri,
                  rules: [{ required: true, message: "Please Provide Uri!" }],
                })(
                  <Input
                    value={item.uri}
                    style={{ width: "250px" }}
                    // type="number"
                    onChange={(e) =>
                      this.handleFieldChange(e.target.value, "uri")
                    }
                  />
                )}
              </Form.Item>
              <Form.Item label="Method :">
                {getFieldDecorator("method", {
                  initialValue: item.method,
                  rules: [{ required: true, message: "Please Select Method!" }],
                })(
                  <Select
                    value={item.method}
                    style={{ width: "250px" }}
                    onSelect={(value) =>
                      this.handleFieldChange(value, "method")
                    }
                  >
                    <Option value="GET">GET</Option>
                    <Option value="POST">POST</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Payload :">
                {Object.entries(apiProperties.payload).length > 0 &&
                this.props.currentTab === "Edit Report JSON" ? (
                  Object.entries(apiProperties.payload).map(
                    ([key, value], index) => (
                      <Row>
                        <input
                          type="text"
                          className="form-control"
                          id="reportKey"
                          name="reportKey"
                          value={key}
                          onChange={(e) =>
                            this.handlePayloadChange(key, e, "key")
                          }
                        />
                        {/* <label htmlFor="reportName">Last Name</label> */}
                        <input
                          type="text"
                          className="form-control"
                          id="reportValue"
                          name="reportValue"
                          value={value}
                          onChange={(e) =>
                            this.handlePayloadChange(key, e, "value")
                          }
                        />
                        <Button
                          className="btn-link"
                          type="Button"
                          onClick={() =>
                            this.handleMainPayloadRemoveFields(key)
                          }
                        >
                          -
                        </Button>
                        {Object.keys(apiProperties.payload).length - 1 ===
                        index ? (
                          <Button
                            className="btn-link"
                            type="Button"
                            onClick={() => this.handleAddPayloadFields()}
                          >
                            +
                          </Button>
                        ) : null}
                      </Row>
                    )
                  )
                ) : (
                  <div>
                    <Button onClick={(e) => this.addPayload(e)}>
                      addPayload
                    </Button>
                    {inputFields.length > 0 &&
                      inputFields.map((item, index) => (
                        <Fragment key={`${item}~${index}`}>
                          <div>
                            {/* <label htmlFor="reportKey">First Name</label> */}
                            {inputFields.length}
                            {index}
                            <input
                              type="text"
                              className="form-control"
                              id="reportKey"
                              name="reportKey"
                              value={item.reportKey}
                              onChange={(e) => this.handleInputChange(index, e)}
                            />

                            {/* <label htmlFor="reportName">Last Name</label> */}
                            <input
                              type="text"
                              className="form-control"
                              id="reportValue"
                              name="reportValue"
                              value={item.reportValue}
                              onChange={(e) => this.handleInputChange(index, e)}
                            />

                            <Button
                              className="btn-link"
                              type="Button"
                              onClick={() => this.handleRemoveFields(index)}
                            >
                              -
                            </Button>
                            {inputFields.length - 1 === index ? (
                              <Button
                                className="btn-link"
                                type="Button"
                                onClick={() => this.handleAddFields()}
                              >
                                +
                              </Button>
                            ) : null}
                          </div>
                          {/* <div><Button onClick={() => this.submitPayload()}>Submit</Button></div> */}
                        </Fragment>
                      ))}
                  </div>
                )}
                {this.props.currentTab === "Edit Report JSON" &&
                payloadFields &&
                payloadFields.length > 0
                  ? payloadFields.map((item, index) => (
                      <Fragment key={`${item}~${index}`}>
                        <div>
                          {/* <label htmlFor="reportKey">First Name</label> */}

                          <input
                            type="text"
                            className="form-control"
                            id="reportKey"
                            name="reportKey"
                            value={item.reportKey}
                            onChange={(e) =>
                              this.handlePayloadInputChange(index, e)
                            }
                          />

                          {/* <label htmlFor="reportName">Last Name</label> */}
                          <input
                            type="text"
                            className="form-control"
                            id="reportValue"
                            name="reportValue"
                            value={item.reportValue}
                            onChange={(e) =>
                              this.handlePayloadInputChange(index, e)
                            }
                          />

                          <Button
                            className="btn-link"
                            type="Button"
                            onClick={() =>
                              this.handlePayloadRemoveFields(index)
                            }
                          >
                            -
                          </Button>
                          {payloadFields.length - 1 === index ? (
                            <Button
                              className="btn-link"
                              type="Button"
                              onClick={() => this.handleAddPayloadFields()}
                            >
                              +
                            </Button>
                          ) : null}
                        </div>

                        {/* <div><Button onClick={() => this.submitPayload()}>Submit</Button></div> */}
                      </Fragment>
                    ))
                  : null}
              </Form.Item>
              <Form.Item label="Query :">
                {getFieldDecorator("query", {
                  initialValue: item.query,
                  rules: [{ required: true, message: "Please Provide Query!" }],
                })(
                  <TextArea
                    type={TextArea}
                    value={item.query}
                    style={{ width: "550px" }}
                    // type="number"
                    onChange={(e) =>
                      this.handleFieldChange(e.target.value, "query")
                    }
                  />
                )}
              </Form.Item>
            </Form>
            <div className="validButton">
              <Button
                type="primary"
                // style={{ marginLeft: "60px" }}
                onClick={(e) => this.apiSave(e)}
              >
                ValidateJson
              </Button>{" "}
              {payloadFields.length > 0 ? (
                <Button
                  type="primary"
                  // style={{ marginLeft: "60px" }}
                  onClick={(e) => this.UpdatePayload(e)}
                >
                  UpdatePayload
                </Button>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
export default Form.create()(ApiCustomization);
