import React from "react";
import axios from "axios";
import {
  Divider,
  Form,
  Icon,
  Button,
  Row,
  Col,
  Select,
  Input,
  message
} from "antd";
import localStorage from "../../../../utils/localStorage";

import {
  _getDeviceParameterBasedOnType,
  // _saveDeviceParamters
} from '../../AssetDeviceList/APICalls'

const FormItem = Form.Item;
const { Option } = Select;

class DeviceParameterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: props.currentLanguage || "es",
      UOMList: props.UOMList || [],
      deviceParametersBasedOnType: [],
      deviceTypeObject: {}
    };
  }

  componentWillReceiveProps({
    currentLanguage,
    UOMList,
    deviceParametersBasedOnType,
    deviceType
  }) {
    this.setState({
      currentLanguage,
      UOMList,
      deviceType,
      deviceParametersBasedOnType
    });
  }
  componentDidUpdate(prevprops) {
    if (prevprops.deviceParametersBasedOnType !== this.props.deviceParametersBasedOnType) {
      this.setState({
        deviceParametersBasedOnType: this.props.deviceParametersBasedOnType
      })
    }
  }

  handleFieldChange = (e, parameter, index) => {
    const { deviceParametersBasedOnType } = this.state;
    const arrayUpdated = this.updateArray(
      deviceParametersBasedOnType,
      index,
      item => ({ ...item, [parameter]: e })
    );
    this.props.deviceParameter(arrayUpdated);
    this.setState({ deviceParametersBasedOnType: arrayUpdated });
  };

  parameterValChanged = (e, parameter, index) => {
    const { deviceParametersBasedOnType } = this.state;
    const arrayUpdated = this.updateArray(
      deviceParametersBasedOnType,
      index,
      item => ({ ...item, [parameter]: e.target.value })
    );
    this.props.deviceParameter(arrayUpdated);
    this.setState({ deviceParametersBasedOnType: arrayUpdated });
  };

  remove = (k, object, deviceId) => {
    const accessToken = localStorage.get("accessToken");
    const ParameterDeleteObject = {
      method: "DELETE",
      url: `/api/deviceType/delete?Id=${object.Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(ParameterDeleteObject)
      .then(async response => {
        if (response.status === 204) {
          message.success("Deleted")
          let data = await _getDeviceParameterBasedOnType(deviceId);
          this.setState({ deviceParametersBasedOnType: data });

        }else{
          message.error("Cant delete this record")
        }
      })
      .catch(function () { });
  };

  updatedeviceParameter(deviceTypeId) {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get("accessToken");
    const deviceTypeObject = {
      method: "GET",
      url: `/api/site/${siteId}/device/getParameters/${deviceTypeId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(deviceTypeObject)
      .then(response => {
        this.setState({ deviceParametersBasedOnType: response.data });
      })
      .catch(function () { });
  }

  add = () => {
    const { deviceParametersBasedOnType } = this.state;
    const newObj = {
      Name: "",
      UOM: "",
      DataType: "",
      Description: "",
      DeviceType: this.props.deviceType
    };
    deviceParametersBasedOnType.push(newObj);
    this.props.deviceParameter(deviceParametersBasedOnType);
    this.setState({ deviceParametersBasedOnType });
  };

  updateArray = (array, index, updateFn) => {
    return [
      ...array.slice(0, index),
      updateFn(array[index]),
      ...array.slice(index + 1)
    ];
  };

  resetForm = () => {
    this.setState({ deviceTypeObject: {} });
    this.props.form.resetFields();
  };

  handleSubmit = () => {
    const { deviceParametersBasedOnType } = this.state;

    this.props.form.validateFields(err => {
      if (!err) {
        this.setState({ visible: false });
        this.props.actions.saveDeviceParamters(deviceParametersBasedOnType);
        // let saveDeviceTypeResponse = _saveDeviceParamters(deviceParametersBasedOnType);
        // if (saveDeviceTypeResponse) {
        //   message.success(saveDeviceTypeResponse.message)
        // }
        this.props.actions.getDeviceTypeList();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      UOMList,
      deviceType,
      deviceParametersBasedOnType
    } = this.state;
    // let {
    //   deviceParametersBasedOnType
    // } = this.props
    const formItems = deviceParametersBasedOnType.map((k, index) =>
      k !== null ? (
        <Row>
          <Col span={5}>
            <FormItem
              label={index === 0 ? "Name" : ""}
              key={k.Name}
            >
              {getFieldDecorator(`Name[${index}]]`, {
                initialValue: k.Name,
                rules: [{ required: true, message: "Name is required" }]
              })(
                <Input
                  style={{ width: "90%" }}
                  placeholder="Enter name"
                  onBlur={e => {
                    this.parameterValChanged(e, "Name", index);
                  }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={5}>
            <FormItem label={index === 0 ? "Code" : ""} key={k.Name}>
              {getFieldDecorator(`Code[${index}]]`, {
                initialValue: k.Code,
                rules: [{ required: true, message: "Code is required" }]
              })(
                <Input
                  style={{ width: "90%" }}
                  placeholder="Enter code"
                  onBlur={e => {
                    this.parameterValChanged(e, "Code", index);
                  }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={5}>
            <FormItem label={index === 0 ? "Description" : ""} key={k.Name}>
              {getFieldDecorator(`Description[${index}]]`, {
                initialValue: k.Description,
                rules: [{ required: true, message: "Description is required" }]
              })(
                <Input
                  style={{ width: "90%" }}
                  placeholder="Enter Description"
                  onBlur={e => {
                    this.parameterValChanged(e, "Description", index);
                  }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem label={index === 0 ? "UOM" : ""} key={k}>
              {getFieldDecorator(`UOM[${index}]`, {
                initialValue: k.UOM,
                rules: [{ required: true, message: "UOM is required" }]
              })(
                <Select
                  showArrow
                  showSearch
                  defaultValue={k.UOM}
                  openOnFocus
                  style={{ width: 160 }}
                  onChange={e => this.handleFieldChange(e, "UOM", index)}
                  placeholder="Name"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {UOMList.map(uom => {
                    return <Option value={uom.Id}>{uom.Name}</Option>;
                  })}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={5}>
            <FormItem label={index === 0 ? "Data Type" : ""} key={k}>
              {getFieldDecorator(`DataType[${index}]`, {
                initialValue: k.DataType,
                rules: [{ required: true, message: "DataType is required" }]
              })(
                <Select
                  defaultValue={k.DataType}
                  openOnFocus
                  style={{ width: 160 }}
                  onChange={e => this.handleFieldChange(e, "DataType", index)}
                  placeholder="DataType"
                >
                  <Option value="float">float</Option>
                  <Option value="boolean">boolean</Option>
                </Select>
              )}
              {deviceParametersBasedOnType.length > 0 ? (
                <Icon
                  style={{ marginLeft: "10px" }}
                  className="dynamic-delete-button"
                  type="minus-circle-o"
                  disabled={deviceParametersBasedOnType.length === 1}
                  onClick={() => this.remove(index, k, deviceType)}
                />
              ) : null}
            </FormItem>
          </Col>
        </Row>
      ) : null
    );

    return (
      <div>
        <Divider>Equipment Parameter</Divider>
        <Form.Item>
          <Button type="dashed" onClick={this.add}>
            <Icon type="plus" /> Add
          </Button>
        </Form.Item>
        {formItems}
        <Form.Item>
          <Button type="primary" onClick={this.handleSubmit}>
            Create Equipment Parameters
          </Button>
          {/* <ResetButton onClick={this.resetForm}>{translation[currentLanguage].ACTION_CANCEL}</ResetButton> */}
        </Form.Item>
      </div>
    );
  }
}

export default Form.create()(DeviceParameterComponent);
