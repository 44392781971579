import React, { Component } from "react";
import axios from "axios";
import { v4 as uuid } from 'uuid';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import SortableTree, { toggleExpandedForAll } from "react-sortable-tree";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  message,
  Upload,
  Icon,
  Table
} from "antd";

import { adapter, adapterArrToObj } from "../utility/adapter";
import localStorage from "../../../../utils/localStorage";
import { getUserProfile } from "../../../../selectors/layout";
import { getCurrentLanguage, getTranslationCache } from "../../../../selectors/language";
import { constant } from '../Constants'
import "react-sortable-tree/style.css";
import { StyledTable, lightTheme, StyledApplication } from "./style";
import "./index.css"

let accessToken = localStorage.get("accessToken");
const { Option } = Select;

class MenuBuilder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      forms: [],
      name: "",
      path: "",
      menuItemType: "default",
      visible: false,
      TableVisible: false,
      userProfile: props && props.userProfile ? props.userProfile : {},
      siteId: "",
      siteList: [],
      menuId: "",
      translationCache: props.translationCache || [],
      language: props.language || "English",
      updateMenuData: [],
      editObject: {},
      defValue: ""
    };

    this.onClose = this.onClose.bind(this);
    this.saveMenu = this.saveMenu.bind(this);
    this.selectedModule = this.selectedModule.bind(this);
    this.addModuleClick = this.addModuleClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleUpdateClick = this.handleUpdateClick.bind(this);

  }


  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache || prevProps.language !== this.props.language) {
      this.setState({
        translationCache: this.props.translationCache, language: this.props.language
      });
    }

    if (prevProps.userProfile !== this.props.userProfile) {
      this.sitesArrayList()
    }
  }
  componentDidMount() {
    let orgId =
      this.props &&
        this.props.userProfile &&
        this.props.userProfile.belongsTo &&
        this.props.userProfile.belongsTo.organization &&
        this.props.userProfile.belongsTo.organization.Id
        ? this.props.userProfile.belongsTo.organization.Id
        : "";

    if (orgId) {
      this._getSiteListById(orgId);
    }
    this.sitesArrayList()
  }

  sitesArrayList = () => {
    let sitesListArray =
      this.props &&
      this.props.userProfile &&
      this.props.userProfile.belongsTo &&
      this.props.userProfile.belongsTo.sites[1] && this.props.userProfile.belongsTo.sites;

    let sitesArray = []
    if (sitesListArray) {
      sitesArray = Object.keys(sitesListArray).map((key) => {
        return sitesListArray[key]
      });
    }

    let dataSource = sitesArray && Array.isArray(sitesArray) && sitesArray.map((item) => {
      return item.site
    });

    this.setState({
      sitesArray,
      dataSource
    })
  }

  _getSiteListById = () => {
    const accessToken = localStorage.get("accessToken");
    let orgHeaders = {
      method: "GET",
      url: "/api/organisation/list",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios(orgHeaders)
      .then((response) => {

        if (response && response.data && Array.isArray(response.data) && response.data.length > 0) {
          this.setState({ siteList: response.data });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleEditClick(obj) {
    let editObj = obj.node.maniObj;
    this.props.form.setFieldsValue({
      name: editObj.name,
      path: editObj.path,
      menuItemType: editObj.menuItemType,
      key: editObj.key,
      icon: editObj.icon,
    });
    let selected = obj.path[0] === 0 ? "modules" : "configuration";
    this.setState({
      visible: true,
      selectedModule: selected,
      editModule: true,
    });
  }

  handleUpdateClick() {
    let { treeData } = this.state;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        treeData.forEach(function iter(a) {
          if (values.key === a.maniObj.key) {
            a.title = values.name;
            a.maniObj = values;
          } else {
            Array.isArray(a.children) && a.children.forEach(iter);
          }
        });
        this.setState({ treeData: [...treeData], visible: false });
      }
    });
  }


  addModuleClick = () => {
    const { treeData, selectedModule } = this.state;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let flag = treeData.map((item) => item.key).indexOf(values.key);
        if (flag === -1) {
          let obj = {
            key: values.key,
            title: values.name,
            maniObj: values,
          };
          let index = treeData.map((item) => item.key).indexOf(selectedModule);
          if (treeData[index].children !== undefined) {
            treeData[index].children.push(obj);
          } else {
            treeData[index].children = [obj];
          }
          this.setState({
            treeData: [...treeData],
            visible: false,
            selectedModule: "",
          });
          this.props.form.setFieldsValue({
            name: "",
            path: "",
            menuItemType: "default",
            key: "",
            icon: "",
          });
        }
      } else {
        message.error("Error while adding");
      }
    });
  };


  onClose() {
    this.setState({ visible: false, TableVisible: false });
  }

  handleModuleClick() {
    const defValue = uuid()
    this.setState({ visible: true, editModule: false, defValue });
  }

  saveMenu = (SiteId, Id) => {
    const { treeData, menuName } = this.state;
    let dataMenu = adapterArrToObj(treeData)
    dataMenu.name = menuName;
    dataMenu.SiteId = SiteId
    dataMenu.Id = Id

    const accessToken = localStorage.get("accessToken");
    let authMenuObject = {
      method: "PATCH",
      url: `/api/menu/update`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: dataMenu,
    };

    axios(authMenuObject)
      .then(() => {
        message.success("Menu Saved");
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  selectedModule = (value) => {
    this.setState({ selectedModule: value });
  };

  siteSelection = (value) => {
    this.setState({ siteId: value, TableVisible: true })
    this.getMenuItems(value);
  };

  getMenuItems = (siteId) => {
    const accessToken = localStorage.get("accessToken");
    let authMenuObject = {
      method: "GET",
      url: `/api/menu/list?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios(authMenuObject)
      .then((response) => {
        this.setState({
          treeData: toggleExpandedForAll({
            treeData: adapter(response.data[0]),
          }),
          menuKey: response.data[0].key,
          menuName: response.data[0].name,
          menuId: response.data[0].Id,
          siteId,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleDeleteClick = (obj) => {
    let { treeData } = this.state;

    const findModule = (deleteModule, arr) => {
      if (!arr) return;
      const idx = arr.findIndex((obj) => obj.key === deleteModule.node.key);
      if (idx > -1) return [arr, idx];
      for (const obj of arr) {
        const result = findModule(deleteModule, obj.children);
        if (result) return result;
      }
    };
    const [arr, idx] = findModule(obj, treeData) || [];
    if (arr) {
      arr.splice(idx, 1);
    }
    this.setState({ treeData: [...treeData] });
  };
  //  uploading file of menu json 
  updateFileMenu = (dataMenu, siteId, menuId) => {
    const accessToken = localStorage.get("accessToken");
    let authMenuObject = {
      method: "PATCH",
      url: `/api/menu/update`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: { ...dataMenu, "SiteId": siteId, "Id": menuId },
    };

    axios(authMenuObject)
      .then(() => {
        message.success("Menu Saved");
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  }
  editMenu = (editObject) => {
    this.props.form.resetFields()
    this.setState({
      editObject,
      TableVisible: true,
    })
  }

  translation = (keyword) => {
    let { translationCache } = this.props
    return translationCache &&
      translationCache[keyword] ?
      translationCache[keyword] : keyword
  }

  render() {
    let { treeData, editModule, selectedModule, siteId, menuId, dataSource } = this.state;

    const { getFieldDecorator } = this.props.form;

    let dataMenu = adapterArrToObj(treeData)
    let columns = [
      {
        title: "SiteName",
        dataIndex: "Name",
        key: "Name"
      },
      {
        title: "SiteId",
        dataIndex: "SiteId",
        key: "SiteId"
      },
      {
        title: "Action",
        render: (text, record) =>

          <div>
            <button type='button' onClick={() =>
              this.editMenu(record)} style={{ marginRight: '10px' }} className='ant-btn' >
              <Icon type='edit' />
            </button>
          </div>
      }
    ];

    return (

      <div>
        <Row>
          <StyledApplication style={{ minHeight: window.innerHeight - 170 }}>
            <StyledTable theme={lightTheme}>
              <Table
                className={"customTable"}
                columns={columns}
                dataSource={dataSource}
                onRow={(record) => {
                  let siteId = 1
                  return {
                    onClick: () => {
                      this.setState({
                        editObject: record
                      });
                      this.getMenuItems(siteId)
                    },
                  };
                }}
                pagination={false}
              />
            </StyledTable>
          </StyledApplication>

          <Col span={24}>
            <Drawer onClose={this.onClose} size="large"
              width={1500}
              visible={this.state.TableVisible}>
              <div style={{ padding: "10px 40px" }}>
                <div
                  style={{ padding: 20, background: "white", minHeight: "65vh" }}
                >
                  <Button
                    type="primary"
                    style={{ float: "right", marginRight: "10vh" }}
                    onClick={() => {
                      this.saveMenu(siteId, menuId);
                    }}
                  >{this.translation(constant.Save_Menu)}
                  </Button>
                  <Button
                    type="primary"
                    style={{ float: "right", marginRight: "2vh" }}
                    onClick={() => {
                      this.handleModuleClick();
                    }}
                  >{this.translation(constant.Add_Module)}
                  </Button>

                  {/* upload and download menu data */}
                  <div style={{ float: "right", marginRight: "40px" }}>
                    {treeData.length >= 1 ?
                      <Upload
                        name="file"
                        accept="application/json"
                        headers={{
                          Authorization: `Bearer ${accessToken}`,
                        }}
                        // beforeUpload is for reading content of json data 
                        beforeUpload={file => {
                          const reader = new FileReader();

                          reader.onload = e => {
                            this.updateFileMenu(JSON.parse(e.target.result), siteId, menuId)
                          };
                          reader.readAsText(file);
                          return false;
                        }}

                      >
                        <Button>
                          <Icon type="upload" /> Click to Upload
                        </Button>
                      </Upload> : ""}
                  </div>


                  <div style={{ marginLeft: "50%" }}>

                    {/* downloading menu data */}
                    {treeData.length >= 1 ?
                      <Button icon="download" type="primary"
                        href={`data:text/json;charset=utf-8,${encodeURIComponent(
                          JSON.stringify(dataMenu)
                        )}`}
                        download="menuConfiguration.json"
                      >
                        {`Download`}
                      </Button>
                      : ""}
                  </div>

                  <SortableTree
                    treeData={treeData}
                    onChange={(treeData) => this.setState({ treeData })}
                    isVirtualized={false}
                    slideRegionSize={0}
                    rowHeight={60}
                    generateNodeProps={(rowInfo) => {
                      if (
                        rowInfo.node.key === "Modules" ||
                        rowInfo.node.key === "Configuration"
                      ) {
                      } else {
                        return {
                          buttons: [
                            <Button
                              shape="circle"
                              size="small"
                              icon="edit"
                              onClick={() => this.handleEditClick(rowInfo)}
                              style={{ marginRight: "5px" }}
                            />,
                            <Button
                              size="small"
                              shape="circle"
                              icon="delete"
                              onClick={() => this.handleDeleteClick(rowInfo)}
                            />,
                          ],
                        };
                      }
                    }}
                  />

                </div>
              </div>
            </Drawer>
            <Drawer
              title="Create a new Module"
              width={250}
              onClose={this.onClose}
              visible={this.state.visible}
              bodyStyle={{ paddingBottom: 80 }}
            >
              <Form layout="vertical" hideRequiredMark>
                <Row gutter={16}>
                  <Col span={24}>
                    <Select
                      value={selectedModule}
                      placeholder="Please Select"
                      onChange={this.selectedModule}
                    >
                      {treeData.map((item) => (

                        <Option key={item.key} value={item.key}>
                          {item.key}
                        </Option>
                      ))}
                    </Select>
                    <Form.Item label="Name">
                      {getFieldDecorator("name", {
                        initialValue: "",
                        rules: [
                          {
                            required: true,
                            message: "Please enter Module name",
                          },
                        ],
                      })(<Input placeholder="Please enter Module name" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="Path">
                      {getFieldDecorator("path", {
                        rules: [
                          { required: true, message: "Please select an owner" },
                        ],
                      })(<Input placeholder="Please enter Path" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="Key">
                      {getFieldDecorator("key", {
                        initialValue: this.state.defValue,
                        rules: [
                          {
                            required: false,
                            message: "Please Enter Key",
                          },
                        ],
                      })(
                        <Input placeholder="Please Enter Key" disabled />
                      )}
                    </Form.Item>

                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="MenuItem Type">
                      {getFieldDecorator("menuItemType", {
                        rules: [
                          { required: true, message: "Please select MenuItem Type" },
                        ],
                      })(
                        <Select placeholder="Please select MenuItem Type">
                          <Option value={'default'}>{"Default"}</Option>
                          <Option value={'tab'}>{'Tab'}</Option>
                        </Select>)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="Icon">
                      {getFieldDecorator("icon", {
                        rules: [
                          {
                            required: false,
                            message: "please enter url Icon",
                          },
                        ],
                      })(<Input placeholder="please enter Icon" />)}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  borderTop: "1px solid #e9e9e9",
                  padding: "10px 16px",
                  background: "#fff",
                  textAlign: "right",
                }}
              >
                <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                {editModule ? (
                  <Button onClick={this.handleUpdateClick} type="primary">
                    update
                  </Button>
                ) : (
                  <Button onClick={this.addModuleClick} type="primary">
                    Submit
                  </Button>
                )}
              </div>
            </Drawer>
          </Col>
        </Row>
      </div>

    );
  }
}
const FormApp = Form.create()(MenuBuilder);
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
});

export default connect(mapStateToProps)(FormApp);