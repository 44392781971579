import axios from 'axios'

import localStorage from '../../../utils/localStorage'


export function getHMICalls(payload) {
    const accessToken = localStorage.get('accessToken')
    const header = {
      ...payload,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    }
    return axios(header)
      .then((response) => {
        return response.data
      })
      .catch( ()=> {
        return {
          "message": "Error in API"
        }
      })
  }
  export function _getReportList ()  {
    const siteId = localStorage.get("currentSite");
    let accessToken = localStorage.get("accessToken");
    const healthHeaders = {
      method: "GET",
      url: `/api/report/list?SiteId=${siteId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return axios(healthHeaders)
      .then((response) => {
        return response.data
      })
      .catch( ()=> {
        return {
          "message": "Error in API"
        }
      })
  };
  export function _getReportData()  {
    let accessToken = localStorage.get("accessToken");
    const healthHeaders = {
      method: "GET",
      url:`/api/report/reports?SiteName=RakCeramics%20Site`,
     // url: `/api/report/reports?SiteId=${siteId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
    };
    return axios(healthHeaders) 
      .then((response) => {
        return response.data
      })
      .catch( ()=> {
        return {
          "message": "Error in API"
        }
      })
  };
  export async function selectDashboard(e) {
    const token = localStorage.get("accessToken");
    var Authorization = "Bearer " + token;
    let data = await fetch(`/api/dashboard/dashboardsList?SiteName=RakCeramics%20Site`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: Authorization
      },
      // body: JSON.stringify({
      //   Name: e,
      //   SiteId: siteId
      // })
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (Array.isArray(json)) {
          return json
        }
      })
      .catch(error => {
        console.log("Error", error);
      });
    return data === undefined ? [] : data;
  }
  export async function getDatasourceData() {
    const token = localStorage.get('accessToken')
    var Authorization = "Bearer " + token;
  
    let data = await fetch(`/api/logbook/logbooksList?SiteName=RakCeramics%20Site`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: Authorization
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json;
      })
      .catch(error => {
        console.log(error);
        return []
      });
      if(data && Array.isArray(data)){
  
        return data;
      }else{
        return []
      }
  
  
  }