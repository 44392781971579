import React, { Component } from "react";
import cloneDeep from "lodash.clonedeep";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Table, Icon, Tag, TreeSelect, Button, Row, Col } from "antd";

import history from "../../commons/history";
import SelectionComponent from "./SelectionComponent";
import {
  getDataSourceFromBackend,
  getReportNametoDownload,
  getDropDownDataFromBackend,
} from "./ReportCalls";
// import { tableTranslation } from "../../pages/Adapter/TranslationAdapter";
import { tableTranslation } from "../../Adapter/TranslationAdapter"
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../selectors/language";

import "./style.css";
import BarChart from "./BarChart";

const { SHOW_PARENT } = TreeSelect;

let graphprops = {
  id: 1,
  title: "new panel",
  graph: "rangeBar",
  source: "mqtt",
  query: "",
  position: {
    x: 0,
    y: 0,
    w: 6,
    h: 13,
  },
  refreshTime: "",
  deviceType: "select",
  deviceName: [],
  deviceParams: [],
  selectedAssetsList: [],
  searchText: [],
  searchResults: [],
  assetQueryType: [],
  aggregation: "select",
  barType: "circle",
  deviceInterval: {
    days: 0,
    hours: 0,
    minutes: 0,
  },
  deviceDuration: {
    days: 0,
    hours: 0,
    minutes: 0,
  },
  dashboardName: "BDRD Monthly",
  dateExists: "false",
  dateRangeExists: "false",
  monthExists: "false",
  shiftExists: "false",
  weekExists: "false",
  dashboardType: "static",
};

class ReportTable extends Component {
  constructor(props) {
    super(props);
    let start = moment().format("YYYY-MM-DD 00:00:00")
    let end = moment().format("YYYY-MM-DD 23:59:59")
    this.state = {
      columns: props.columns || [],
      currentDatasource: props.currentDatasource || [],
      reportName: "",
      treeData: [],
      tags: [],
      selectedTreeData: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      currentTab: "毎日",
      tableConfiguration: {
        bordered:
          props.tableConfiguration && props.tableConfiguration.bordered
            ? props.tableConfiguration.bordered
            : true,
        horizontalScroll: props.tableConfiguration
          ? props.tableConfiguration.horizontalScroll
          : false,
        tableSize: props.tableConfiguration
          ? props.tableConfiguration.tableSize
          : "default",
        noResultMessage: props.tableConfiguration
          ? props.tableConfiguration.noResultMessage
          : "No Records",
        isCursorPointer: props.tableConfiguration
          ? props.tableConfiguration.isCursorPointer
          : false,
        title: props.tableConfiguration
          ? props.tableConfiguration.title
          : "No Title",
        pageSize: props.tableConfiguration
          ? props.tableConfiguration.pageSize
          : "10",
        dashboardExists: props.tableConfiguration
          ? props.tableConfiguration.dashboardExists
          : false,
        downloadXLXS: props.tableConfiguration
          ? props.tableConfiguration.downloadXLXS
          : false,
        downloadExcelPayload: props.tableConfiguration
          ? props.tableConfiguration.downloadExcelPayload
          : {},
        style: {
          textAlign:
            props.tableConfiguration &&
              props.tableConfiguration.style &&
              props.tableConfiguration.style.textAlign
              ? props.tableConfiguration.style.textAlign
              : "center",
          fontWeight:
            props.tableConfiguration &&
              props.tableConfiguration.style &&
              props.tableConfiguration.style.fontWeight
              ? props.tableConfiguration.style.fontWeight
              : "normal",
          evenRowColor:
            props.tableConfiguration &&
              props.tableConfiguration.style &&
              props.tableConfiguration.style.evenRowColor
              ? props.tableConfiguration.style.evenRowColor
              : "rgb(251, 250, 250)",
          oldRowColor:
            props.tableConfiguration &&
              props.tableConfiguration.style &&
              props.tableConfiguration.style.oldRowColor
              ? props.tableConfiguration.style.oldRowColor
              : "#fff",
        },
      },
      selectionWidget: props.selectionWidget || {},
      graphprops: graphprops,
      payload: {
        duration: "1 hour",
        durationType: "daily",
        date: moment().format("YYYY-MM-DD"),
        month: moment().format("MMMM"),
        year: moment().format("YYYY"),
        days: moment().daysInMonth(),
        startDate: moment(start).utc().format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(end).utc().format("YYYY-MM-DD HH:mm:ss"),
      },
      graphPayload: {
        duration: "1 hour",
        durationType: "daily",
        startDate: moment.utc().format("YYYY-MM-DD 00:00:00"),
        endDate: moment.utc().format("YYYY-MM-DD 23:59:59"),
        tags: [],
      },
    };
  }

  componentWillMount() {
    this._getDataSource("initial");
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectionWidget: nextProps.selectionWidget,
    });
  }

  componentDidUpdate({ api }) {
    if (api !== this.props.api) {
      this._getDataSource();
    }
  }

  _getDataSource = async (callType) => {
    let api = cloneDeep(this.props.api);
    let columns = cloneDeep(this.state.columns);
    let { selectionWidget } = this.props;

    let month = moment().month();
    let year = moment().year();
    let payload = {};

    if (selectionWidget && selectionWidget.payload) {
      Object.keys(selectionWidget.payload).map((payloadParams, key) => {
        if (key <= 1 && key === 0) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment([year, month])
            .startOf("month")
            .format(payloadItem.format);
        } else if (key <= 1 && key === 1) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment([year, month])
            .endOf("months")
            .format(payloadItem.format);
        }
        if (payloadParams === "month") {
          payload[`${payloadParams}`] = moment().format("MMMM");
        }
        if (payloadParams === "year") {
          payload[`${payloadParams}`] = year;
        }
        if (payloadParams === "Date") {
          payload[`${payloadParams}`] = moment()
            .subtract("day", 1)
            .format("YYYY-MM-DD");
        }
      });
    }

    api.payload = {
      ...api.payload,
      ...this.state.payload,
    };
    let newSelectedPayload = {}
    if (callType === "initial") {
      api.payload = {
        ...api.payload,
        ...history.location.state || {}
      }
      newSelectedPayload = {
        ...api.payload,
        ...selectionWidget,
        ...history.location.state || {},
        payload:{
          ...api.payload,
          ...selectionWidget,
          ...history.location.state || {},
        }
      }
      this.setState({
        selectionWidget: newSelectedPayload
      })
    }else{
      newSelectedPayload={
        ...api.payload,
        ...selectionWidget,
        ...history.location.state || {},
        payload:{
          ...api.payload,
          ...selectionWidget,
          ...history.location.state || {},
        }
      }
      this.setState({
        selectionWidget: newSelectedPayload
      })
    }

    if (api.payload.reportName === "Power Generation Report") {
      let treeData = await getDropDownDataFromBackend(api);
      let translationColumn = await tableTranslation(
        treeData,
        this.props.translationCache,
        this.props.language
      );
      let translationColumnns = await tableTranslation(
        columns,
        this.props.translationCache,
        this.props.language
      );
      this.setState({
        treeData: translationColumn,
        columns: translationColumnns,
      });
    } else {
      let currentDatasource = await getDataSourceFromBackend(newSelectedPayload);
      let translationColumn = await tableTranslation(
        columns,
        this.props.translationCache
      );
      let excelDate = moment().format("YYYY-MM-DD")
      if (newSelectedPayload.durationType && newSelectedPayload.durationType === "daily") {
        excelDate = newSelectedPayload.date
      } else if (newSelectedPayload.durationType && newSelectedPayload.durationType === "monthly") {
        excelDate = moment(newSelectedPayload.endDate, "YYYY-MM-DD").format('YYYY-MM')
      } else if (newSelectedPayload.durationType && newSelectedPayload.durationType === "yearly") {
        excelDate = moment(newSelectedPayload.endDate, "YYYY-MM-DD").format('YYYY')
      }
      let reportName = await getReportNametoDownload(
        translationColumn,
        currentDatasource,
        this.props.tableConfiguration.downloadExcelPayload,
        this.state.currentTab,
        excelDate
      );
      this.setState({
        columns: translationColumn,
        currentDatasource,
        reportName: reportName && reportName.file ? reportName.file : "",
        language: this.props.language,
      });
    }
  };

  selectionpayload = async (selectionpayload, currentTab) => {
    let api = cloneDeep(this.props.api);
    let columns = cloneDeep(this.state.columns);
    api.payload = {
      ...api.payload,
      ...selectionpayload,
      tags: this.state.tags,
    };
    let graphPayload = {
      tags: this.state.tags,
      ...selectionpayload,
    };
    let currentDatasource = await getDataSourceFromBackend(api);
    let translationColumn = await tableTranslation(
      columns,
      this.props.translationCache,
      this.props.language
    );
    let excelDate = moment().format("YYYY-MM-DD")
    if (selectionpayload.durationType && selectionpayload.durationType === "daily") {
      excelDate = selectionpayload.date
    } else if (selectionpayload.durationType && selectionpayload.durationType === "monthly") {
      excelDate = moment(selectionpayload.endDate, "YYYY-MM-DD").format('YYYY-MM')
    } else if (selectionpayload.durationType && selectionpayload.durationType === "yearly") {
      excelDate = moment(selectionpayload.endDate, "YYYY-MM-DD").format('YYYY')
    }
    let reportName = await getReportNametoDownload(
      translationColumn,
      currentDatasource,
      this.props.tableConfiguration.downloadExcelPayload,
      currentTab,
      excelDate
    );
    this.setState({
      payload: selectionpayload,
      graphPayload,
      currentDatasource,
      currentTab: currentTab ? currentTab : "",
      reportName: reportName && reportName.file ? reportName.file : "",
    });
  };

  onChange = (selectedTreeData) => {
    this.setState({ selectedTreeData });
  };

  getReportData = async () => {
    let { selectedTreeData } = this.state;
    let api = cloneDeep(this.props.api);
    let treeData = cloneDeep(this.state.treeData);
    let newObj = [];
    let removeObj = [];

    selectedTreeData.map((selectedKey) => {
      treeData.map((value) => {
        if (value.key === selectedKey && value.children) {
          removeObj.push(selectedKey);
          value.children.map((value) => {
            newObj.push(value.key);
          });
        }
      });
    });

    selectedTreeData = selectedTreeData.filter(function (val) {
      return removeObj.indexOf(val) == -1;
    });

    let tagsList = [...selectedTreeData, ...newObj];

    api.payload = {
      ...api.payload,
      ...this.state.payload,
      tags: tagsList,
    };

    let columns = treeData.map((tree) => {
      if (tree && tree.children) {
        tree["children"] = tree.children.map((children) => {
          if (tagsList.indexOf(children.key) > -1) {
            children["dataIndex"] = children["value"];
            children["className"] = "table-column";
            delete children["value"];
            return children;
          } else {
            return;
          }
        });
        tree["children"] = tree["children"].filter(function (element) {
          return element !== undefined;
        });
        if (tree["children"] && tree["children"].length > 0) {
          tree["dataIndex"] = tree["value"];
          tree["className"] = "table-column";
          delete tree["value"];
          return tree;
        } else {
          return;
        }
      } else {
        if (tagsList.indexOf(tree.key) > -1) {
          tree["dataIndex"] = tree["value"];
          tree["className"] = "table-column";
          delete tree["value"];
          return tree;
        } else {
          return;
        }
      }
    });
    columns = columns.filter(function (element) {
      return element !== undefined;
    });
    columns.unshift({
      title: "Date",
      dataIndex: "date",
      key: "date",
      isUTC: true,
      utcFormats: {
        "daily": "YYYY-MM-DD",
        "monthly": "YYYY-MM-DD",
        "yearly": "MMMM"
      },
      className: "table-column",
      fixed: "left",
      width: 120,
      render(text, record) {
        let generatedValue = ""
        if (record && record.durationType && record.durationType === 'daily') {
          generatedValue = moment.utc(text).local().format('YYYY-MM-DD')
        } else if (record && record.durationType && record.durationType === 'monthly') {
          generatedValue = moment.utc(text).local().format("YYYY-MM-DD")
        } else if (record && record.durationType && record.durationType === 'yearly') {
          generatedValue = moment.utc(text).local().format("MMMM")
        } else {
          generatedValue = text
        }

        return {
          props: {
            style: { background: columns.background },
          },
          children: <div>{generatedValue ? generatedValue : ""}</div>
        };
      }
    });

    let graphPayload = {
      ...this.state.payload,
      tags: tagsList,
    };
    let currentDatasource = await getDataSourceFromBackend(api);
    let translationColumn = await tableTranslation(
      columns,
      this.props.translationCache,
      this.props.language
    );


    let excelDate = moment().format("YYYY-MM-DD")
    if (api && api.payload && api.payload.durationType) {
      if (api.payload.durationType === "daily") {
        excelDate = moment(api.payload.endDate, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
      } else if (api.payload.durationType === "monthly") {
        excelDate = moment(api.payload.endDate, "YYYY-MM-DD").format('YYYY-MM')
      } else if (api.payload.durationType === "yearly") {
        excelDate = moment(api.payload.endDate, "YYYY-MM-DD").format('YYYY')
      }
    }

    let reportName = await getReportNametoDownload(
      translationColumn,
      currentDatasource,
      this.props.tableConfiguration.downloadExcelPayload,
      this.state.currentTab,
      excelDate
    );
    this.setState({
      columns: translationColumn,
      tags: tagsList,
      graphPayload,
      currentDatasource,
      reportName: reportName && reportName.file ? reportName.file : "",
    });
  };

  render() {
    let { api } = this.props;
    let {
      tableConfiguration,
      columns,
      selectionWidget,
      currentDatasource,
      reportName,
      treeData,
      graphprops,
      graphPayload,
      language,
    } = this.state;
    const {
      bordered,
      tableSize,
      noResultMessage,
      isCursorPointer,
      title,
      pageSize,
      horizontalScroll,
      downloadXLXS,
      dashboardExists,
    } = tableConfiguration;

    const tProps = {
      treeData,
      value: this.state.selectedTreeData,
      onChange: this.onChange,
      treeCheckable: true,
      showCheckedStrategy: SHOW_PARENT,
      searchPlaceholder: "Please select",
      allowClear: true,
      style: {
        width: "300px",
      },
    };

    return (
      <div>
        {api &&
          api.payload.reportName &&
          api.payload.reportName === "Power Generation Report" ? (
          <div>
            <TreeSelect {...tProps} />{" "}
            <Button type="primary" onClick={this.getReportData}>
              {language !== "English" ? "参加する" : "Submit"}
            </Button>
          </div>
        ) : null}
        {selectionWidget ? (
          <SelectionComponent
            selectionWidget={selectionWidget}
            selectionpayload={this.selectionpayload}
            language={language}
          />
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            cursor: "pointer",
            marginBottom: "10px",
          }}
        >
          {downloadXLXS && reportName ? (
            <a
              href={`https://bpt.bmecomosolution.com/api/reports/${reportName}`}
            >
              {" "}
              <Icon type="download" style={{ fontSize: "18px" }} />
            </a>
          ) : null}
          {this.props.tableConfiguration && this.props.tableConfiguration.link
            ? this.props.tableConfiguration.link.map((links) => {
              return (
                <Tag color="#aaa3a3">
                  <Link
                    to={{ pathname: links.path }}
                    style={{ marginRight: "10px" }}
                  >
                    {links.name}
                  </Link>
                </Tag>
              );
            })
            : null}
        </div>
        {false ? (
          <Row>
            <Col span={10}>
              <BarChart graphprops={graphprops} graphPayload={graphPayload} />
            </Col>
            <Col span={14}>
              {columns && columns.length > 0 ? (
                <Table
                  className="reportParentTable"
                  columns={columns}
                  dataSource={currentDatasource}
                  bordered={bordered ? bordered : false}
                  size={tableSize ? tableSize : "default"}
                  locale={{ emptyText: noResultMessage }}
                  isCursorPointer={
                    isCursorPointer === true ? "not-allowed" : "pointer"
                  }
                  loading={false}
                  rowKey={this.props.api.payload.reportName}
                  scroll={
                    horizontalScroll === true
                      ? { x: 120, y: 600 }
                      : { x: "max-content" }
                  }
                  pagination={pageSize ? { pageSize } : false}
                  title={
                    title
                      ? () => (
                        <div
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          {title}
                        </div>
                      )
                      : null
                  }
                />
              ) : (
                <div />
              )}
            </Col>
          </Row>
        ) : (
          <div>
            {columns && columns.length > 0 ? (
              <Table
                className="reportParentTable"
                columns={columns}
                dataSource={currentDatasource}
                bordered={bordered ? bordered : false}
                size={tableSize ? tableSize : "default"}
                locale={{ emptyText: noResultMessage }}
                isCursorPointer={
                  isCursorPointer === true ? "not-allowed" : "pointer"
                }
                loading={false}
                rowKey={this.props.api.payload.reportName}
                scroll={
                  horizontalScroll === true
                    ? { x: 120, y: 600 }
                    : { x: "max-content" }
                }
                pagination={pageSize ? { pageSize } : false}
                title={
                  title
                    ? () => (
                      <div
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {title}
                      </div>
                    )
                    : null
                }
              />
            ) : (
              <div />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
});

export default connect(mapStateToProps)(ReportTable);
