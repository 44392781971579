import React, { Component } from "react";
import cloneDeep from "lodash.clonedeep";
import moment from "moment";
import { Tag } from "antd";

import ReactEcharts from "../Echarts/Echarts";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { getChartDataWithSelection, getChartDataWithoutSelection } from "../ChartCalls";

import { defaultRefreshTime } from '../constant.json'
import { liquidgaugeMockJson } from "../../Mock/MockJson";
import "../CSS/progressgauge.css"
import { Fragment } from "react";

export default class LiquidGuage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // option: this.getData(props),
      option: {},
      data: [],
      value: 30,
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
    this.getData = this.getData.bind(this);
    this.updateGauge = this.updateGauge.bind(this)
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = ""
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);

  }


  componentDidUpdate(prevProps) {
    if (prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear
    ) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.abortController.abort()
    clearInterval(this.timeTicket);
  }


  async getData(props) {
    this.requestCheck = false
    let { graphprops } = this.props
    let data = []
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      data = liquidgaugeMockJson;
      this.requestCheck = true
    } else {
      if (
        (graphprops && graphprops.dashboardType === 'dynamic' && this.props.deviceId) ||
        (graphprops && graphprops.dateExists === 'true' && this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops && graphprops.dateRangeExists === 'true' && this.props.selectionDateRange)
        ||
        (graphprops && graphprops.dayMonthYearExists === 'true' && this.props.dayMonthYear)
      ) {

        data = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController
        );
        this.requestCheck = true
      } else if (graphprops) {
        data = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true
      }
    }

    // data =[{"interval_alias":"2020-04-08 00:00:03",
    //     "tagname":"Vi_07_fuel_level_perc","tagvalue":71}]
    if ((data && data.message) || (Array.isArray(data) && (data.length === 0) &&
      (graphprops.enableMock !== true))) {

      if (data.message) {
        errorInAPI = true
      } else if (data.length === 0) {
        noDataInDB = true
      }

    } else {

      if (data && data.length > 0) {
        this.updateGauge(data)

      }
    }
    this.setState({
      errorInAPI,
      noDataInDB
    })
  }

  updateGauge(data) {
    let percentageValue = data[0].tagvalue / 100



    const option = cloneDeep(this.getOption());
    option.series = [{
      data: [percentageValue]
    }]


    this.setState({
      option,
      refreshDateTime: moment()

    });


  }

  getOption() {
    let option = {
      series: [
        {
          type: "liquidFill",
          data: [0.0],
          radius: "90%",
          color: ["#294D99"],
          amplitube: 15,
          animationDuration: 2,
          outline: {
            show: true,
            borderDistance: 5,
            itemStyle: {
              color: 'none',
              borderColor: '#294D99',
              borderWidth: 8,
              shadowBlur: 20,
              shadowColor: 'rgba(0, 0, 0, 0.25)'
            }
          },
          backgroundStyle: {
            color: "trasparent"
          },
          label: {
            show: true,
            color: '#294D99',
            insideColor: '#fff',
            fontSize: 50,
            fontWeight: 'bold',
            align: 'center',
            baseline: 'middle',
            position: 'inside'
          }
        }
      ]
    }
    return option
  }




  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <ReactEcharts
              option={this.state.option}
              notMerge={true}
              lazyUpdate={true}
              // style={{ height: "90%", width: "100%" }}
              className="react_for_echarts"
            />
        }
      </Fragment>


    );
  }
}
