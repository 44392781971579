import React from "react";
import moment from "moment";
import cloneDeep from "lodash.clonedeep";
import { connect } from "react-redux";
import { Row, Col, Table, Spin, Icon } from "antd";
import { createStructuredSelector } from "reselect";

import "../CSS/Table.css";

import ReactEcharts from "../Echarts/Echarts";
import {
    getChartDataWithoutSelection,
    getChartDataWithSelection,
} from "../ChartCalls";
import { trendLabelTranslation } from "../../Utils/Adapter/translationAdapter";
import {
    getCurrentLanguage,
    getTranslationCache,
  } from "../../../../selectors/language";
import { Tag } from "antd";
import { barTableMockJson } from "../../Mock/MockJson";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { makeSelectThemeing } from "../../../../selectors/theme";
import { defaultRefreshTime } from '../constant.json'
import { Fragment } from "react";

class LineTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            option: this.getOption(),
            data: [],
            loading: false,
            dataSource: [],
            translationCache: props.translationCache || [],
            language: props.language || "English",
            refreshDateTime: moment(),
            errorInAPI: false,
            noDataInDB: false
        };
    }
    timeTicket = null;
    requestCheck = true;
    abortController = new window.AbortController();

    async componentDidMount() {
        if (this.timeTicket) {
            clearInterval(this.timeTicket);
        }
        this.getData(this.props, this.props.selectedThemeing);

        let refreshTime = ""
        if (this.props.graphprops.refreshTime) {
            refreshTime = this.props.graphprops.refreshTime * 60000
        } else {
            refreshTime = defaultRefreshTime
        }

        this.timeTicket = setInterval(() => {
            if (this.requestCheck) {
                this.getData(this.props, this.props.selectedThemeing);
            }

        }, refreshTime);
    }

    // componentWillReceiveProps(props) {
    //   this.getData(props);
    // }

    componentWillUnmount() {
        this.abortController.abort();
        clearInterval(this.timeTicket);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.graphprops !== this.props.graphprops ||
            prevProps.selectionDate !== this.props.selectionDate ||
            prevProps.selectionMonth !== this.props.selectionMonth ||
            prevProps.selectionWeek !== this.props.selectionWeek ||
            prevProps.deviceId !== this.props.deviceId ||
            prevProps.shift !== this.props.shift ||
            prevProps.selectionDateRange !== this.props.selectionDateRange ||
            prevProps.dayMonthYear !== this.props.dayMonthYear
        ) {
            this.getData(this.props, this.props.selectedThemeing);
        }
    }

    getColor = (status) => {
        if (status === "Low") {
            return "red";
        } else if (status === "High") {
            return "green";
        } else {
            return "orange";
        }
    };

    getData = async (props, theme) => {
        const option = cloneDeep(this.state.option);
        this.requestCheck = false
        let json = [];
        let { graphprops } = this.props;
        let { errorInAPI, noDataInDB } = this.state
        errorInAPI = false
        noDataInDB = false
        // this.setState({
        //   loading: true,
        // });
        if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
            json = barTableMockJson;
            this.requestCheck = true
        } else {

            if (
                (graphprops &&
                    graphprops.dashboardType === "dynamic" &&
                    this.props.deviceId) ||
                (graphprops &&
                    graphprops.dateExists === "true" &&
                    this.props.selectionDate) ||
                (graphprops &&
                    graphprops.monthExists === "true" &&
                    this.props.selectionMonth) ||
                (graphprops &&
                    graphprops.weekExists === "true" &&
                    this.props.selectionWeek) ||
                (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
                (graphprops &&
                    graphprops.dateRangeExists === "true" &&
                    this.props.selectionDateRange)
                ||
                (graphprops && graphprops.dayMonthYearExists === 'true' && this.props.dayMonthYear)
            ) {
                json = await getChartDataWithSelection(
                    graphprops,
                    this.props.deviceId,
                    this.props.selectionDate,
                    this.props.selectionMonth,
                    this.props.selectionWeek,
                    this.props.shift,
                    this.props.selectionDateRange,
                    this.props.dayMonthYear,
                    this.abortController
                );
                this.requestCheck = true
                this.setState({ loading: false });
            } else {
                json = await getChartDataWithoutSelection(graphprops, this.abortController);
                this.requestCheck = true
                this.setState({ loading: false });
            }
        }

        if ((json && json.message) || (Array.isArray(json) && (json.length === 0 &&
            graphprops.enableMock !== true))) {

            if (json.message) {
                errorInAPI = true
            } else if (json.length === 0) {
                noDataInDB = true
            }

        } else {

            
            let tagnames = [];
            let series = [];
            let legends = [];

            let xaxisLabels = [];
            var label = {
                show: true,
                formatter: '{c}',
                fontSize: 16
            };
            if (json && Array.isArray(json)) {
               
                json.map((val, index) => {
                    let name = "";
                    // if (val.tagname !== name) {
                    if (tagnames.indexOf(val.tagname) === -1) {
                        tagnames.push(val.tagname);
                        name = val.tagname;
                        legends.push({
                            name: val.tag_description,
                            icon: "roundRect",
                            textStyle: {
                                fontSize: 18,
                                fontWeight: "bold",
                            }
                        });

                    }
                });
            }
            let yAxisData = [];

            if (Array.isArray(this.props.graphprops.YAxis)) {
                yAxisData = [...this.props.graphprops.YAxis];
            }

            let yaxis = yAxisData.map((value, index) => {
                let axis = {};
                // axis.splitLine = {
                //     show: true,
                //     lineStyle: {
                //         color: "#504c4c",
                //     },
                //     axisLabel: { fontSize: 15 },
                // };
                axis.triggerEvent = true;
                axis.axisLine = {
                    lineStyle: {
                        // color: "white"
                        color:
                            this.props.graphprops.colors[index] !== undefined
                                ? value && value.tags && value.tags.length > 1
                                    ? "white"
                                    : this.props.graphprops.colors[index]
                                : "white",
                    },
                    axisLabel: { fontSize: 15 },
                };
                axis.max = value.max;
                axis.min = value.min;
                axis.offset = value.offset;
                axis.interval = value.interval;
                axis.position = value.position;
                axis.name = value.name;
                axis.tags = value.tags;
                return axis;
            });

            yaxis.unshift({
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: "#504c4c",
                    },
                },
                triggerEvent: true,
                axisLine: {
                    lineStyle: {
                        color: theme === "lightTheme" ? "black" : "white",
                    },
                },
                axisLabel: { fontSize: 15 },
                tags: [],
            });
            let color = [
                "#d87c7c",
                "#919e8b",
                "#d7ab82",
                "#6e7074",
                "#61a0a8",
                "#efa18d",
                "#787464",
                "#cc7e63",
                "#724e58",
                "#4b565b", "steelblue", "green", ""
            ]
            tagnames.map((tagval, tagindex) => {
                let data = [];

                json.map((jsonval, jsonindex) => {
                    if (tagval === jsonval.tagname) {
                        if (jsonval.tagvalue < 0) {
                            data.push({
                                // value: Math.abs(jsonval.tagvalue),
                                value: jsonval.tagvalue,
                                itemStyle: {
                                    color: color[jsonindex]
                                }
                            });
                        } else {
                            data.push({
                                value: jsonval.tagvalue,
                                itemStyle: {
                                    color: color[jsonindex]
                                }
                            });
                        }
                    }
                    let valueDate = moment(jsonval.interval_alias)
                        .utc()
                        .format("DD/MM/YYYY HH:mm:ss");
                    if (
                        xaxisLabels.indexOf(valueDate) === -1 &&
                        tagval === jsonval.tagname
                    ) {
                        if (moment(jsonval.interval_alias).utc()._isUTC === true && typeof jsonval.interval_alias !== "string") {
                            xaxisLabels.push(
                                moment(jsonval.interval_alias)
                                    .utc()
                                    .format("DD/MM/YYYY HH:mm:ss")
                            );
                        } else if (
                            xaxisLabels.indexOf(jsonval.interval_alias) === -1 &&
                            tagval === jsonval.tagname
                        ) {
                            xaxisLabels.push(jsonval.interval_alias);
                        }
                    }
                });
                if (yaxis.length > 1) {
                    yaxis.map((axis, axisindex) => {
                        if (axis && axis.tags && axis.tags.indexOf(tagval) !== -1) {
                            let linetags = props.graphprops ? props.graphprops.tags : [];
                            if (linetags && linetags.length > 0) {
                                linetags &&
                                    linetags.map((tag) => {
                                        series.push({
                                            name: legends[tagindex]["name"],
                                            data: data,
                                            yAxisIndex: axisindex,
                                            label,
                                            type: linetags,
                                            axisLabel: { fontSize: 15 },
                                        });
                                    })
                            } else {
                                series.push({
                                    name: legends[tagindex]["name"],
                                    data: data,
                                    yAxisIndex: axisindex,
                                    type: "bar",
                                    label,
                                    axisLabel: { fontSize: 15 },
                                });
                            }
                        }
                    });
                } else {
                    let linetags = props.graphprops ? props.graphprops.tags : [];
                    if (linetags && linetags.length > 0) {
                        linetags &&
                            linetags.map((tag) => {
                                series.push({
                                    name: legends[tagindex]["name"],
                                    data: data,
                                    label,
                                    type: "line",
                                    axisLabel: { fontSize: 15 },
                                });
                            })
                    } else {
                        series.push({
                            name: legends[tagindex]["name"],
                            data: data,
                            type: "line",
                            label,
                            // markPoint: {
                            //   data: [
                            //     { type: 'max', name: legends[tagindex]["name"] },
                            //     { type: 'min', name: legends[tagindex]["name"] }
                            //   ]
                            // },
                            axisLabel: { fontSize: 15 },
                        });
                    }
                }
            });

            let seriesData = await trendLabelTranslation(
                series,
                this.props.translationCache,
                this.props.language
            );
            legends = await trendLabelTranslation(
                legends,
                this.props.translationCache,
                this.props.language
            );

            option.series = seriesData;
            option.legend = {
                textStyle: { color: theme === "lightTheme" ? "black" : "white" },
                data: legends,
            };
            option.xAxis[0].data = [...xaxisLabels];
            if (
                this.props.graphprops.colors !== null &&
                this.props.graphprops.colors !== undefined &&
                this.props.graphprops.colors.length !== 0
            ) {
                option.color = [...this.props.graphprops.colors];
            } else {
                option.color = ["steelblue", "green", "rgb(172, 172, 9)", "red"];
            }
            this.setState({
                option: option,
                data: json,
                refreshDateTime: moment()
            });
            return option;

        };
        this.setState({
            errorInAPI,
            noDataInDB
        })
    }

    onChartClick = (cellData) => {
        if (cellData.seriesType === "line") {
            let newObject = this.state.data.filter((predict) => {
                if (typeof predict.interval_alias === 'string') {
                    return predict.interval_alias === cellData.name;
                } else {
                    return (
                        moment(predict.interval_alias)
                            .utc()
                            .format("DD/MM/YYYY HH:mm:ss") === cellData.name
                    );
                }
            });
            if (
                newObject &&
                Array.isArray(newObject) &&
                newObject[cellData.seriesIndex]
            ) {
                this.setState({
                    dataSource: newObject[cellData.seriesIndex].anomalyList,
                    selectedDate: cellData.name,
                });
            }
        } else if (cellData.seriesType === "line") {
            let newObject = this.state.data.filter((predict) => {
                if (typeof predict.interval_alias === 'string') {
                    return predict.interval_alias === cellData.name;
                } else {
                    return (
                        moment(predict.interval_alias)
                            .utc()
                            .format("DD/MM/YYYY HH:mm:ss") === cellData.name
                    );
                }
            });
            if (
                newObject &&
                Array.isArray(newObject) &&
                newObject[cellData.seriesIndex]
            ) {
                this.setState({
                    dataSource: newObject[cellData.seriesIndex].anomalyList,
                    selectedDate: cellData.name,
                });
            }
        }
    };

    render() {
        // let theme = this.props.selectedThemeing;
        let { loading, errorInAPI, noDataInDB, refreshDateTime } = this.state;
        let columns = [
            {
                title: " S.No",
                dataIndex: "S.No",
                key: "S.No",
                render: (text, row, key) => {
                    return {

                        children: <span>{text}</span>,
                    };
                },
            },
            {
                title: "Meter Name",
                dataIndex: "locationName",
                key: "locationName",
                render: (text, row, key) => {
                    return {

                        children: <span>{text}</span>,
                    };
                },
            },
            {
                title: "Value",
                dataIndex: "value",
                key: "value",
                render: (text, row, key) => {
                    return {

                        children: <span>{text}</span>,
                    };
                },
            },
            {
                title: "Impact",
                dataIndex: "impact",
                key: "impact",
                render: (text, row, key) => {
                    if (row.severity === "High") {
                        return {
                            props: {
                                style: {

                                    color: row.severity
                                        ? this.getColor(row.severity)
                                        : "red",
                                },
                            },

                            children: <span> <Icon type="arrow-up" 
                            style={{ marginRight: "4px", fontWeight: "800", fontSize: "0.8em" }} />
                            {text}</span>,
                        };
                    } else if (row.severity === "Low") {
                        return {
                            props: {
                                style: {
                                    color: row.severity
                                        ? this.getColor(row.severity)
                                        : "green",
                                },
                            },

                            children: <span> <Icon type="arrow-down" style={{ marginRight: "4px", fontWeight: "800", fontSize: "0.8em" }} />{text}</span>,
                        };
                    } else {
                        return {
                            props: {
                                style: {
                                    color: row.severity
                                        ? this.getColor(row.severity)
                                        : "orange",
                                },
                            },

                            children: <span>{text}</span>,
                        };
                    }

                },
            },
            // {
            //   title: "Severity",
            //   dataIndex: "severity",
            //   key: "severity",
            //   render: (text, row, key) => {
            //     return {

            //       children: <span>{text}</span>,
            //     };
            //   },
            // },
        ];
        let onEvents = {
            click: (cellData) => this.onChartClick(cellData),
        };
        let { graphprops } = this.props
        return (
            <Fragment>
                {
                    graphprops && graphprops.checked === true ?
                        <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
                            {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
                        </Tag>
                        : null
                }
                <span>
                    {loading === false ? (
                        <div style={{ padding: "10px" }}>
                            <Row>
                                <Col span={24}>
                                    {
                                        noDataInDB === true || errorInAPI === true ?
                                            noDataInDB === true ?
                                                <NoRecordComponent />
                                                : <ErrorComponent />
                                            :
                                            <ReactEcharts
                                                option={this.state.option}
                                                notMerge={true}
                                                onEvents={onEvents}
                                                lazyUpdate={true}
                                                className="react_for_echarts"
                                            />
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <div style={{ padding: "20px" }}>
                                        <Table
                                            className="barTable"
                                            dataSource={this.state.dataSource}
                                            columns={columns}
                                            pagination={false}
                                            size={"small"}
                                            style={{ padding: "0px 10px", marginTop: "12px" }}
                                            scroll={{ y: 240 }}
                                            bordered
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <div
                            style={{
                                textAlign: "center",
                                color: "white",
                                marginTop: "15%",
                            }}
                        >
                            <h2 style={{ color: "white" }}>
                                <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                            </h2>
                        </div>
                    )}
                </span>
            </Fragment>
        );
    }

    getOption = () => {
        let theme = this.props.selectedThemeing;
        // var labelOption = {
        //     show: true,
        //     formatter: "{c}",
        //     fontSize: 16,
        // };
        let option = {
            color: ["tomato", "steelblue", "orange", "yellow", "green"],
            legend: {
                textStyle: { color: theme === "lightTheme" ? "black" : "white" },
                data: [
                    {
                        name: "test1",
                        icon: "roundRect",
                        textStyle: {
                            fontSize: 15,
                        },
                    },
                ],
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow",
                },
            },
            toolbox: {
                showTitle: false,
                itemSize: 25,
                feature: {
                    saveAsImage: {
                        backgroundColor: "black",
                        title: "download",
                        name: this.props.graphprops.title,
                        iconStyle: {
                            borderColor: "#8c8c8c",
                            borderWidth: 2,
                        },
                    },
                },
            },
            // legend: {
            //     data: [],
            // },
            xAxis: [
                {
                    type: "category",
                    // axisTick: { show: false },
                    data: [],
                    axisLine: {
                        lineStyle: {
                            color: theme === "lightTheme" ? "black" : "white",
                        },
                    },
                    axisTick: {
                        show: false,
                        lineStyle: {
                            color: theme === "lightTheme" ? "black" : "white",
                        },
                    },
                    axisLabel: {
                        color: theme === "lightTheme" ? "black" : "white",
                    },
                },
            ],
            yAxis: [
                {
                    type: "value",
                    axisLine: {
                        lineStyle: {
                            color: theme === "lightTheme" ? "black" : "white",
                        },
                    },
                    axisTick: {
                        lineStyle: {
                            color: theme === "lightTheme" ? "black" : "white",
                        },
                    },
                    axisLabel: {
                        color: theme === "lightTheme" ? "black" : "white",
                    },
                },
            ],
            series: [
                // {
                //   name: 'Forest',
                //   type: 'bar',
                //   barGap: 0,
                //   label: labelOption,
                //   data: [320, 400]
                // },
                // {
                //   name: 'Steppe',
                //   type: 'bar',
                //   label: labelOption,
                //   data: [220, 100]
                // },
                // {
                //   name: 'Desert',
                //   type: 'bar',
                //   label: labelOption,
                //   data: [150, 200]
                // }
            ]
        };
        return option;
    };
}

const mapStateToProps = createStructuredSelector({
    language: getCurrentLanguage(),
    translationCache: getTranslationCache(),
    selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(LineTable);
