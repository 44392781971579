import React from "react";
import axios from "axios";
import {
  Table,
  Form,
  Button,
  Input,
  Drawer,
  Row,
  Col,
  Icon,
  Popconfirm
} from "antd";

import localStorage from "../../../utils/localStorage";
import DeviceParameterContainer from "./DeviceParameter/DeviceParameterContainer";
import { StyledApplication, AddTableButton } from "./style";
import {
  _getDeviceParameterBasedOnType
} from '../AssetDeviceList/APICalls'

const FormItem = Form.Item;
const { TextArea } = Input;

class DeviceType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      deviceTypeList: this.props.deviceTypeList,
      currentLanguage: props.currentLanguage || "es",
      deviceTypeObject: {},
      deviceParametersBasedOnType: [],
      UOMList: []
    };
  }

  componentWillMount() {
    this.props.actions.getDeviceTypeList();
    this._getUOMist();

  }

  componentDidUpdate(prevprops) {
    if (prevprops.deviceTypeList !== this.props.deviceTypeList || prevprops.currentLanguage !== this.props.currentLanguage) {
      this.setState({
        deviceTypeList: this.props.deviceTypeList,
        currentLanguage: this.props.currentLanguage || "English"
      })
    }
  }

  _getUOMist = () => {
    const accessToken = localStorage.get("accessToken");
    const uomObject = {
      method: "POST",
      url: `/api/masterconfiguration/master`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        "MasterName": "AssetDevice_UOM",
        "ActionType": "read"
      }
    };
    axios(uomObject)
      .then(response => {
        this.setState({ UOMList: response.data });
      })
      .catch(function () { });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { deviceParametersBasedOnType } = this.state;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        delete values.names;
        delete values.code;
        delete values.uom;
        delete values.dataType;
        deviceParametersBasedOnType.forEach((parameter, index) => {
          if (!parameter.Id) {
            deviceParametersBasedOnType[index].DeviceType = values.Id;
          }
        });
        this.setState({ visible: false });
        this.props.actions.saveDeviceParamters(deviceParametersBasedOnType);
        this.props.actions.getDeviceTypeList();
      }
    });
  };

  showDrawer = () => {
    this.props.form.resetFields();
    this.setState({
      visible: true,
      deviceTypeObject: {},
      deviceParametersBasedOnType: []
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  resetForm = () => {
    this.setState({ deviceTypeObject: {} });
    this.props.form.resetFields();
  };

  showDrawerOldDevice = async (e, row) => {
    e.preventDefault();
    let data = await _getDeviceParameterBasedOnType(row.Id);
    this.setState({
      deviceTypeObject: row,
      deviceParametersBasedOnType: data,
      visible: true
    });
  };

  updateArray = (array, index, updateFn) => {
    return [
      ...array.slice(0, index),
      updateFn(array[index]),
      ...array.slice(index + 1)
    ];
  };

  saveDeviceType = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        delete values.names;
        delete values.uom;
        delete values.dataType;
        this.props.actions.saveDeviceType(values);
        this.props.actions.getDeviceTypeList();
        setTimeout(() => {
          this.props.actions.getDeviceTypeList();
          this.setState({ visible: false });
        }, 500)
          ;
      }
    }
    );
  };


  updateDeviceType = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        delete values.names;
        delete values.uom;
        delete values.dataType;
        this.props.actions.updateDeviceType(values);
        this.props.actions.getDeviceTypeList();
        setTimeout(() => {
          this.props.actions.getDeviceTypeList();
          this.setState({
            visible: false
          });
        }, 500);
      }
    });
  };

  updateDeviceParameter = deviceParametersBasedOnType => {
    this.setState({ deviceParametersBasedOnType });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      deviceTypeList,
      deviceTypeObject,
      UOMList,
      deviceParametersBasedOnType,
      currentLanguage
    } = this.state;
    const columns = [
      {
        title: "Equipment Type Name",
        dataIndex: "Name",
        key: "Name"
      },
      {
        title: "Equipment Type Code",
        dataIndex: "Code",
        key: "Code"
      },
      {
        title: "Equipment Type Description",
        dataIndex: "Description",
        key: "Description"
      },
      {
        title: "Delete",
        dataIndex: "delete",
        key: "delete",
        render: (text, row, key) => {
          return {
            props: {
              // style: {
              //   background: "#fff"
              // }
            },
            children: (
              <Popconfirm
                title="Are you sure delete deviceType?"
                onConfirm={e => {
                  e.stopPropagation()
                  this.props.actions.deleteDeviceType(row.Id);
                  this.props.actions.getDeviceTypeList();
                }}
                okText="Yes"
                cancelText="No"
              >
                <Icon
                  type="delete"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                />
              </Popconfirm>
            )
          };
        }
      }
    ];

    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 226 }}>
        <AddTableButton type="primary" onClick={this.showDrawer}>
          Create Equipment Type
        </AddTableButton>
        <Drawer
          title={
            !deviceTypeObject.Id ? "Create Equipment Type" : "Update Equipment Type"
          }
          placement="right"
          closable={true}
          width={1200}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          ></div>
          <Form layout="vertical">
            <Row>
              <Col span={24}>
                <FormItem label="Equipment Type Name">
                  {getFieldDecorator("Name", {
                    initialValue: deviceTypeObject.Name,
                    rules: [
                      {
                        required: true,
                        message: "Please input your device type name!"
                      }
                    ]
                  })(
                    <Input
                      placeholder="enter device name"
                      style={{ width: "300px" }}
                    />
                  )}
                  {getFieldDecorator("Id", {
                    initialValue: deviceTypeObject.Id
                  })(<Input style={{ display: "none" }} />)}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label="Equipment Type Code">
                  {getFieldDecorator("Code", {
                    initialValue: deviceTypeObject.Code,
                    rules: [
                      {
                        required: true,
                        message: "Please input your device code!"
                      }
                    ]
                  })(
                    <Input
                      placeholder="enter device code"
                      style={{ width: "300px" }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label="Equipment Type Description">
                  {getFieldDecorator("Description", {
                    initialValue: deviceTypeObject.Description,
                    rules: [
                      {
                        required: true,
                        message: "Please input your device type description!"
                      }
                    ]
                  })(
                    <TextArea
                      rows={2}
                      style={{ width: "400px" }}
                      placeholder="enter device description"
                    />
                  )}
                </FormItem>
                {
                  <div>
                    {!deviceTypeObject.Id ? (
                      <Button
                        type="primary"
                        size="small"
                        style={{ marginLeft: "5px", marginBottom: "5px" }}
                        onClick={this.saveDeviceType}
                      >
                        Save Equipment Type
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        size="small"
                        style={{ marginLeft: "5px", marginBottom: "5px" }}
                        onClick={this.updateDeviceType}
                      >
                        Update Equipment Type
                      </Button>
                    )}
                    <Button type="primary"
                      size="small"
                      style={{ marginLeft: "5px", marginBottom: "5px" }}
                      onClick={this.resetForm}
                    >
                      Clear
                    </Button>
                  </div>
                }
              </Col>
            </Row>
            <Row></Row>
            {deviceTypeObject.Id ? (
              <DeviceParameterContainer
                currentLanguage={currentLanguage}
                UOMList={UOMList}
                deviceType={deviceTypeObject.Id}
                deviceParametersBasedOnType={deviceParametersBasedOnType}
                deviceParameter={this.updateDeviceParameter}
              />
            ) : (
              <div></div>
            )}
          </Form>
        </Drawer>
        <div className="Table">
          <Table
            size="medium"
            columns={columns}
            dataSource={deviceTypeList && Array.isArray(deviceTypeList) ? deviceTypeList : []}
            pagination={
              deviceTypeList && Array.isArray(deviceTypeList) && deviceTypeList.length > 5
                ? {
                  pageSize: "5"
                }
                : false
            }
            locale={{ emptyText: "No Data" }}
            onRow={record => ({
              onClick: e => {
                e.stopPropagation();
                this.showDrawerOldDevice(e, record);
              }
            })}
          />
        </div>
      </StyledApplication>
    );
  }
}

export default Form.create()(DeviceType);
