import React from "react"
import cloneDeep from "lodash.clonedeep"
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ecStat from 'echarts-stat'
import moment from "moment";
import { Tag } from "antd";

import ReactEcharts from "../Echarts/Echarts"
import { getChartDataWithoutSelection, getChartDataWithSelection } from "../ChartCalls";
import { trendLabelTranslation } from "../../Utils/Adapter/translationAdapter";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import { makeSelectThemeing } from "../../../../selectors/theme";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { defaultRefreshTime } from '../constant.json'
import { regressionMockJson } from "../../Mock/MockJson";
import { Fragment } from "react"

class Regression extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // option: this.getData(props),
      option: this.getOption(props),
      data: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };

  }

  data = [];
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = ""
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange
    ) {
      this.getData();
    }
  }
  componentWillReceiveProps(props) {
    this.getData(props);
  }

  componentWillUnmount() {
    this.abortController.abort()
    clearInterval(this.timeTicket);
  }

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <ReactEcharts
              option={this.state.option}
              notMerge={true}
              lazyUpdate={true}
              style={{ height: "90%", width: "100%" }}
              className="react_for_echarts"
            />
        }
      </Fragment>

    );
  }

  getData = async (props) => {
    this.requestCheck = false
    let theme = this.props.selectedThemeing;
    let legend = []
    let json = []
    let { graphprops } = this.props;

    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = regressionMockJson;
      this.requestCheck = true
    } else {

      if ((graphprops && graphprops.dashboardType === 'dynamic' && this.props.deviceId) ||
        (graphprops && graphprops.dateExists === 'true' && this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops && graphprops.dateRangeExists === 'true' && this.props.selectionDateRange)
        ||
        (graphprops && graphprops.dayMonthYearExists === 'true' && this.props.dayMonthYear)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController
        );
        this.requestCheck = true
      } else if (graphprops) {
        json = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true
      }
    }

    //  json = [
    //     [1, 4862.4],
    //     [2, 5294.7],
    //     [3, 5934.5],
    //     [4, 7171.0],
    //     [5, 8964.4],
    //     [6, 10202.2],
    //     [7, 11962.5],
    //     [8, 14928.3],
    //     [9, 16909.2],
    //     [10, 18547.9],
    //     [11, 21617.8],
    //     [12, 26638.1],
    //     [13, 34634.4],
    //     [14, 46759.4],
    //     [15, 58478.1],
    //     [16, 67884.6],
    //     [17, 74462.6],
    //     [18, 1200.7]
    // ];

    if ((json && json.message) || (Array.isArray(json) && (json.length === 0 &&
      graphprops.enableMock !== true))) {

      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
      }

    } else {

      if (json && Array.isArray(json)) {


        var myRegression = ecStat.regression('exponential', json);

        myRegression.points.sort(function (a, b) {
          return a[0] - b[0];
        });

        let series = [{
          name: 'scatter',
          type: 'scatter',
          emphasis: {
            label: {
              show: true,
              position: 'left',
              color: theme === "lightTheme" ? "black" : "white",
              fontSize: 16
            }
          },
          data: json
        }, {
          name: 'line',
          type: 'line',
          showSymbol: false,
          smooth: true,
          data: myRegression.points,
          markPoint: {
            itemStyle: {
              color: 'transparent'
            },
            label: {
              show: true,
              position: 'left',
              formatter: myRegression.expression,
              color: '#333',
              fontSize: 14
            },
            data: [{
              coord: myRegression.points[myRegression.points.length - 1]
            }]
          }
        }]

        legend.push({
          name: 'scatter'
        })
        legend.push({
          name: 'line'
        })

        series = await trendLabelTranslation(series, this.props.translationCache, this.props.language)
        legend = await trendLabelTranslation(
          legend,
          this.props.translationCache,
          this.props.language
        );

        const option = cloneDeep(this.getOption());
        option.series = series
        option.legend = {
          textStyle: {
            color: theme === "lightTheme" ? "black" : "white",
            fontSize: 18,
            fontWeight: "bold",
          },
          right: 60,
          data: [...legend],
        };
        this.setState({ option: option });
      }
    };

    this.setState({
      errorInAPI,
      noDataInDB
    })
  }

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      legend: {
        textStyle: { color: theme === "lightTheme" ? "black" : "white" },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      xAxis: {
        type: 'value',
        splitLine: {
          lineStyle: {
            type: 'dashed'
          }
        },
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          }
        },
        axisTick: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          }
        },
        axisLabel: {
          color: theme === "lightTheme" ? "black" : "white",
        },
        splitNumber: 20
      },
      yAxis: {
        type: 'value',
        splitLine: {
          lineStyle: {
            type: 'dashed'
          }
        },
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          }
        },
        axisTick: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          }
        },
        axisLabel: {
          color: theme === "lightTheme" ? "black" : "white",
        }
      },



    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
})


export default connect(mapStateToProps)(Regression)
