import React, { Component,Fragment } from "react";
import moment from "moment";
import cloneDeep from "lodash.clonedeep";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ReactEcharts from "../Echarts/Echarts";
import { Icon, Tag} from "antd";

import { getChartDataWithoutSelection, getChartDataWithSelection } from "../ChartCalls";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
// import { excelDownloadUrl } from "./ExcelAdapters/const";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { defaultRefreshTime } from '../constant.json'
import { guageRingMock } from "../../Mock/MockJson";

class GuageRing extends Component {
  constructor(props) {
    super(props);
    let option = this.getInitialState();
    this.state = {
      option: option.option,
      fromedit: props.graphprops.fromedit,
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  componentWillReceiveProps(props) {
    let option = this.getInitialState();
    this.setState({
      option: option.option,
      fromedit: this.props.graphprops.fromedit === undefined ? true : true,
    })
    
  }

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let { graphprops } = this.props;
    return {
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {
          borderWidth: 1,
          borderColor: "#464646",
        },
      },
      toolbox: {
        showTitle: true,
        itemSize: 25,
        feature: {
          saveAsImage: {
            // backgroundColor: "black",
            title: "download",
            backgroundColor: theme === "lightTheme" ? "black" : "white",
            name: graphprops && graphprops.title ? graphprops.title : "",
            iconStyle: {
              color: "none",
              borderColor: "#8c8c8c",
              borderWidth: 2,
            },
          },
        },
      },
    };
  };

  getInitialState = () => ({ option: this.getOption() });

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);

  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear
    ) {
      this.getData();
    }
  }
  getData = async () => {
    let { graphprops } = this.props;
    let json = [];
    let series = [];
    let sampleJson = []
    let { errorInAPI, noDataInDB } = this.state
    this.requestCheck = false
    errorInAPI = false
    noDataInDB = false

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = guageRingMock;
      this.requestCheck = true
    } else {
      if (
        (graphprops &&
          graphprops.dashboardType === "dynamic" &&
          this.props.deviceId) ||
        (graphprops &&
          graphprops.dateExists === "true" &&
          this.props.selectionDate) ||
        (graphprops &&
          graphprops.monthExists === "true" &&
          this.props.selectionMonth) ||
        (graphprops &&
          graphprops.weekExists === "true" &&
          this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
        (graphprops &&
          graphprops.dateRangeExists === "true" &&
          this.props.selectionDateRange) ||
        (graphprops &&
          graphprops.dayMonthYearExists === "true" &&
          this.props.dayMonthYear)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController
        );
        this.requestCheck = true
      } else if (graphprops) {
        json = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true
      }
    }

    if ((json && json.message) || ((Array.isArray(json) && 
    (json.length === 0) && graphprops.enableMock !== true))) {

      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
      }

    } else {
      sampleJson = [
        {
          title: {
            offsetCenter: ["0%", "-5%"],
          },
          detail: {
            offsetCenter: ["0%", "10%"],
          },
        },
        {
          title: {
            offsetCenter: ["0%", "-5%"],
          },
          detail: {
            offsetCenter: ["0%", "10%"],
          },
        }
        ,
        {
          title: {
            offsetCenter: ["0%", "25%"],
          },
          detail: {
            offsetCenter: ["0%", "40%"],
          },
        },
      ];
      let finalJson = []
      json && Array.isArray(json) && json.length > 0 && json.map((item, index) => {

        // let object = {}
        item.title = sampleJson && sampleJson[index] && sampleJson[index]["title"]
        item.detail = sampleJson && sampleJson[index] && sampleJson[index]["detail"]
        finalJson.push(item)
      })
      const option = cloneDeep(this.state.option);
      if (json && Array.isArray(json) && json[0]) {
        series.push({
          type: "gauge",
          startAngle: 90,
          endAngle: -270,
          pointer: {
            show: false,
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#464646",
            },
          },
          axisLine: {
            lineStyle: {
              width: 40,
            },
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
            distance: 50,
          },
          //   aria:{
          //       decal:{
          //           enabled:true,
          //           show:true
          //       }
          //   }
          //  ,
          //    darkMode:true,
          data: finalJson,
          title: {
            fontSize: 13,
            color: "white",
          },
          detail: {
            width: 38,
            height: 10,
            fontSize: 13,
            fontWeight: "bold",
            color: "auto",
            borderColor: "auto",
            borderRadius: 5,
            borderWidth: 1,
            formatter: "{value}%",
          },
        });
      }

      option.series = series;
      if (option && option.series && option.series[0]) {
        option.series[0].pointer.show = false;
      }

      // option.series[0].data[0].value = (Math.random() * 100).toFixed(2) - 0;
      // option.series[0].data[1].value = (Math.random() * 100).toFixed(2) - 0;
      // option.series[0].data[2].value = (Math.random() * 100).toFixed(2) - 0;

      this.setState({ option, refreshDateTime: moment() });

    }

    this.setState({
      errorInAPI,
      noDataInDB
    })
  };

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  render() {
    const { reportName, excelDownload, refreshDateTime, errorInAPI, noDataInDB } = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
          {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }

        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <ReactEcharts
              id={Math.ceil(Math.random(100, 100000) * 1000)}
              option={this.state.option}
              style={{
                height: "90%",
                width: "100%",
              }}
              className="react_for_echarts"
            />
        }


        {/* {
          reportName && excelDownload === "true" ? (
            <a
              href={`${excelDownloadUrl}/${reportName}`}
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              <Icon
                type="download"
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginRight: "25px",
                  marginTop: "6px",
                }}
              />
            </a>
          ) : null
        } */}
      </Fragment >
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(GuageRing);
