export const constant = {

    //***trends label start***//
   
    Select_Parameter:'Parameter Name',
    Value:'Value',
    UOM:'UoM'

    //***trends label stop***//
}
  