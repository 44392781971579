import React from 'react'
import { Button } from "antd";
import {
  getPdfNametoDownload,
  getReportNametoDownload
} from "./ReportCalls";


export function tableTranslation(columnName, translationCache) {
    if (columnName.length > 0 && columnName !== undefined && translationCache !== undefined) {
      columnName.map((column, key) => {
        if (translationCache[column.title] !== undefined) {
          if (translationCache[column.title]) {
            columnName[key].title = translationCache[column.title]
          }
        }
      })
    }
  
    return columnName
  }
export function tableColumnAdapter(reportJSON) {
    if (reportJSON && Object.entries(reportJSON).length !== 0 && !reportJSON.stack) {
        reportJSON.map((reportItem, key) => {
            let { JSON } = reportItem
            if (JSON && JSON.columns) {
                let children = []
                JSON.columns.map((columnsItem, key1) => {
                    if (JSON.tableConfiguration && JSON.tableConfiguration.style && JSON.tableConfiguration.style.colSpan && JSON.tableConfiguration.style.colSpan.length > 0) {//colspan
                        let styleObj = {}
                        if (!JSON.tableConfiguration || !JSON.tableConfiguration.style) {
                            styleObj = {
                                "textAlign": "center",
                                //  "fontWeight": "normal",
                                "evenRowColor": "rgb(251, 250, 250)",
                                "oldRowColor": "#fff"
                            }
                        } else {
                            styleObj = {
                                "textAlign": JSON.tableConfiguration.style.textAlign ? JSON.tableConfiguration.style.textAlign : "center",
                                //  "fontWeight": JSON.tableConfiguration.style.fontWeight ? JSON.tableConfiguration.style.fontWeight : "normal",
                                "evenRowColor": JSON.tableConfiguration.style.evenRowColor ? JSON.tableConfiguration.style.evenRowColor : "rgb(251, 250, 250)",
                                "oldRowColor": JSON.tableConfiguration.style.oldRowColor ? JSON.tableConfiguration.style.oldRowColor : "#fff",
                                "colSpan": JSON.tableConfiguration.style.colSpan ? JSON.tableConfiguration.style.colSpan : []
                            }
                        }

                        let colSpanObject = JSON.tableConfiguration.style.colSpan
                        colSpanObject.map((colSpan) => {
                            if (columnsItem.key === colSpan.columnKey || (key1 !== 0 ? JSON.columns[key1 - 1].key === colSpan.columnKey : false)) {
                                let column = getGenerateColSpanJSON(columnsItem, styleObj, colSpan)
                                reportJSON[key].JSON.columns[key1] = column
                            } else {
                                let column = getGenerateJSON(columnsItem, styleObj)
                                reportJSON[key].JSON.columns[key1] = column
                            }
                        })

                    } else {
                        let styleObj = {}
                        if (!JSON.tableConfiguration || !JSON.tableConfiguration.style) {
                            styleObj = {
                                "textAlign": "center",
                                //  "fontWeight": "normal",
                                "evenRowColor": "rgb(251, 250, 250)",
                                "oldRowColor": "#fff"
                            }
                        } else {
                            styleObj = {
                                "textAlign": JSON.tableConfiguration.style.textAlign ? JSON.tableConfiguration.style.textAlign : "center",
                                // "fontWeight": JSON.tableConfiguration.style.fontWeight ? JSON.tableConfiguration.style.fontWeight : "normal",
                                "evenRowColor": JSON.tableConfiguration.style.evenRowColor ? JSON.tableConfiguration.style.evenRowColor : "rgb(251, 250, 250)",
                                "oldRowColor": JSON.tableConfiguration.style.oldRowColor ? JSON.tableConfiguration.style.oldRowColor : "#fff",
                                "colSpan": JSON.tableConfiguration.style.colSpan ? JSON.tableConfiguration.style.colSpan : []
                            }
                        }
                        if (columnsItem.children) {
                            children = []
                            children = columnsItem.children.map((child) => {
                                return getGenerateJSON(child, JSON.tableConfiguration.style)
                            })
                            reportJSON[key].JSON.columns[key1].children = children
                        } else {
                            let column = getGenerateJSON(columnsItem, styleObj)
                            reportJSON[key].JSON.columns[key1] = column
                        }
                    }
                })
            }
        })
    }
    return reportJSON
}

function getGenerateJSON(columns, tableConfig) {
    let { textAlign, fontWeight } = tableConfig
    if (columns && columns.key && columns.key === 'Status') {
        return {
            ...columns,
            render(text, record, key) {
                return {
                    // props: {
                    //     style: { background: Math.abs(key % 2) === 1 ? 'rgb(251, 250, 250)' : '#fff' },
                    // },
                    children: <div style={{ fontSize: '15px', textAlign: 'center' }}>    {
                        text === true || text === "true" ?
                            <Button style={{ backgroundColor: "#389e0d", color: "#fff" }} >Avaliable</Button> : <Button type="danger">Offline</Button>
                    }   </div>,
                };
            },
        }
    }
    // else if(columns && columns.key && columns.key === 'Date'){
    //     return {
    //         ...columns,
    //         render(text, record, key) {
    //             return {
    //                 props: {
    //                     style: { background:  columns.background  },
    //                 },
    //                 children: <div style={{ textAlign, fontWeight }}>{moment(text).format('DD-MM-YYYY')}</div>,
    //             };
    //         },

    //     }
    // }
    else {
        return {
            ...columns,
            render(text) {
                return {
                    props: {
                        style: { background: columns.background },
                    },
                    children: <div style={{ textAlign, fontWeight }}>{ text !== "null" ? text : ""}</div>,
                };
            },

        }
    }


}

function getGenerateColSpanJSON(columns, tableConfig, colSpan) {
    let { textAlign, fontWeight } = tableConfig
    let { columnKey, noOfSpan, rowToColSpan } = colSpan

    if (columns.key !== columnKey) {

        return {//columns for next Column Names colspan
            ...columns,
            render: (text, row, index) => {
                const obj = {
                    props: {
                        style: { background: columns.background },
                    },
                    children: <div style={{ textAlign, fontWeight }}>{text}</div>,
                };
                if (index === rowToColSpan) {
                    obj.props.colSpan = 0;
                }
                return obj;
            }
        }
    } else {//Column for colspan
        return {
            ...columns,
            render: (text, row, index) => {

                if (index !== rowToColSpan) {
                    return {
                        props: {
                            style: { background: columns.background },
                        },
                        children: <div style={{ textAlign, fontWeight }}>{text}</div>
                    };
                }
                return {
                    props: {
                        style: { background: columns.background },
                        colSpan: noOfSpan,
                    },
                    children: <div style={{ textAlign, fontWeight }}>{text}</div>,
                };
            },
        }
    }


}

export function getTranslatedDatasource(columns, language, translationCache) {
    let finalObject = []
    let finalArray = {}
    if (columns && Array.isArray(columns) && columns.length > 0) {
        columns.map((series, key) => {

            if (series.children) {

                if (translationCache && translationCache[series.title] !== undefined && translationCache[series.title]) {
                    finalArray = {
                        ...series,
                        "title": translationCache[series.title]
                    }

                } else {
                    finalArray = {
                        ...series,
                        "title": series.title
                    }
                }
                let childrenn = getChildrenTranslation(series.children, translationCache, language)
                finalArray['children'] = childrenn


                finalObject.push(finalArray)



            } else {

                if (translationCache !== undefined) {
                    if (translationCache[series.title] !== undefined) {
                        if (translationCache[series.title]) {
                            finalArray = {
                                ...series,
                                "title": translationCache[series.title]
                            }
                        }

                    } else {
                        finalArray = {
                            ...series,
                            "title": series.title
                        }
                    }
                    finalObject.push(finalArray)
                }

            }




        })
    }

    return finalObject
}

function getChildrenTranslation(treeData, translationCache, language) {
    let objectArray = []

    if (treeData && Array.isArray(treeData)) {
        treeData.map((childrenArray) => {
            let newObj = {}
            if (childrenArray && childrenArray.children) {

                childrenArray.children.map((child) => {
                    newObj['children'] = getChildrenTranslation(childrenArray.children, translationCache, language)
                })

                if (translationCache && translationCache[childrenArray.title] !== undefined && translationCache[childrenArray.title]) {
                    newObj = {
                        ...childrenArray,
                        "title": translationCache[childrenArray.title]
                    }
                } else {
                    newObj = {
                        ...childrenArray,
                        "title": childrenArray.title
                    }
                }
                objectArray.push(newObj)
            } else {
                let newObj = {}
                if (translationCache && childrenArray && translationCache[childrenArray.title] !== undefined && translationCache[childrenArray.title]) {
                    newObj = {
                        ...childrenArray,
                        "title": translationCache[childrenArray.title]
                    }
                } else if (childrenArray) {
                    newObj = {
                        ...childrenArray,
                        "title": childrenArray.title
                    }
                }
                objectArray.push(newObj)
            }
        })
        return objectArray
    } else {
        return treeData
    }
}

export function  getPDFName(tableConfiguration, childTable, columns, currentDatasource, childDatasource) {

    let pdfName = "";
    if (
      tableConfiguration &&
      tableConfiguration.downloadPdf &&
      (tableConfiguration.downloadPdf === true || tableConfiguration.downloadPdf === "true")
    ) {
      if (
        tableConfiguration.downloadPdfPayload &&
        tableConfiguration.downloadPdfPayload.summaryType &&
        (this.props.tableConfiguration.downloadPdfPayload.summaryType === "true" ||
          tableConfiguration.downloadPdfPayload.summaryType === true) &&
        childTable && childTable[0] && childTable[0].columns
      ) {
        pdfName = getPdfNametoDownload(
          columns,
          currentDatasource,
          tableConfiguration.downloadPdfPayload,
          childTable[0].columns,
          childDatasource
        );
      } else {
        pdfName = getPdfNametoDownload(
          columns,
          currentDatasource,
          tableConfiguration.downloadPdfPayload
        );
      }

    }
    return pdfName
}

export function  getExcelReportName(tableConfiguration, childTable, columns, currentDatasource, childDatasource) {
    let reportName = ""

    if (
      tableConfiguration &&
      tableConfiguration.downloadXLXS &&
      (tableConfiguration.downloadXLXS === true || tableConfiguration.downloadXLXS === "true")
    ) {
      if (
        tableConfiguration.downloadExcelPayload &&
        tableConfiguration.downloadExcelPayload.summaryType &&
        (tableConfiguration.downloadExcelPayload.summaryType === "true" ||
          tableConfiguration.downloadExcelPayload.summaryType === true) &&
        childTable && childTable[0] && childTable[0].columns
      ) {
        reportName = getReportNametoDownload(
          columns,
          currentDatasource,
          tableConfiguration.downloadExcelPayload,
          childTable[0].columns,
          childDatasource
        );
      } else {
        reportName = getReportNametoDownload(
          columns,
          currentDatasource,
          tableConfiguration.downloadExcelPayload
        );
      }

    }
    return reportName
}