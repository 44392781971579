import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { Input, Button, Form, Drawer, Select, Row, Col, Icon, Table, message } from 'antd'

import localStorage from '../../../utils/localStorage'
import {
    StyledDashboard,
    DrawerFooter
} from './styles'

import { getCurrentLanguage, getTranslationCache } from "../../../selectors/language";

import { constant } from "./Constants";
import TextArea from 'antd/lib/input/TextArea'

import {
    StyledTable,
    lightTheme
} from './style'
import './style.css'
const { Option } = Select

class MasterConfiguration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            editObject: {},
            masterList: [],
            masterTableNames: [],
            translationCache: props.translationCache || [],
            language: props.language || "english",
        }
    }

    componentDidMount = () => {
        this._getMasterList()
        this._getTableMasterList()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.translationCache !== this.props.translationCache || prevProps.language !== this.props.language) {
            this.setState({
                translationCache: this.props.translationCache, language: this.props.language
            });
        }
    }

    _getTableMasterList = () => {
        const accessToken = localStorage.get('accessToken')
        const Object = {
            method: 'GET',
            url: `/api/masterconfiguration/getTablelist`,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }
        axios(Object)
            .then((response) => {
                this.setState({
                    masterTableNames: response.data
                })
            }).catch((err) => {
            })
    }

    _getMasterList = () => {
        const accessToken = localStorage.get('accessToken')
        const Object = {
            method: 'GET',
            url: `/api/masterconfiguration/list`,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }
        axios(Object)
            .then((response) => {
                this.setState({
                    masterList: response.data
                })
            }).catch((err) => {
            })
    }

    addNew = () => {
        let { editObject } = this.state
        const newObj = {
            title: "",
            key: "",
            widget: "input",
            isMandatory: false,
            disableOnUpdate: false
        };
        editObject = {
            ...editObject,
            columns: [
                ...(editObject && editObject.columns ? editObject.columns : []),
                newObj
            ]
        }
        this.setState({
            editObject
        })
    }


    updateArray = (array, index, updateFn) => {
        return [
            ...array.slice(0, index),
            updateFn(array[index]),
            ...array.slice(index + 1)
        ];
    };


    handleFieldChange = (e, parameter, index) => {
        let { editObject } = this.state;
        let arrayUpdated = this.updateArray(
            editObject.columns,
            index,
            item => ({ ...item, [parameter]: e })
        );
        editObject = {
            ...editObject,
            columns: arrayUpdated
        }
        this.setState({ editObject });
    };

    _curdOperation = (e, operation) => {
        const { editObject } = this.state
        if (operation === 'create') {
            e.preventDefault()
            this.props.form.validateFields(async (err, values) => {
                if (!err) {
                    let newObj = {
                        "MasterName": values.MasterName,
                        "TableName": values.TableName,
                        "JSON": {
                            "MasterName": values.MasterName,
                            "TableName": values.TableName,
                            "columns": values && values.title.map((title, i) => {
                                return {
                                    title,
                                    key: values.key[i],
                                    dataIndex: values.key[i],
                                    widget: values.widget[i],
                                    isMandatory: values.isMandatory[i],
                                    disableOnUpdate: values.disableOnUpdate[i]
                                }
                            }),
                            "uniqueness": values.uniqueness,
                            "api": {
                                "read": {
                                    "url": values.readURL,
                                    "method": values.readMethod
                                },
                                "create": {
                                    "url": values.createURL,
                                    "method": values.createMethod
                                },
                                "update": {
                                    "url": values.updateURL,
                                    "method": values.updateMethod
                                }
                            }
                        }


                    }
                    let saveResponse = await this._saveMasterData(newObj)
                    if (saveResponse && saveResponse.message) {
                        this.setState({
                            visible: false
                        })
                        this._getMasterList()
                        message.success(saveResponse.message);
                    }
                }
            })
        } else if (operation === 'delete') {
            e.preventDefault()
            this.props.form.validateFields(async (err, values) => {
                if (!err) {
                    let deleteResponse = await this._deleteMasterData(values.MasterName)
                    if (deleteResponse && deleteResponse.message) {
                        this.setState({
                            visible: false
                        })
                        this._getMasterList()
                        message.success(deleteResponse.message);
                    }
                }
            })

        } else {
            e.preventDefault()
            this.props.form.validateFields(async (err, values) => {
                if (!err) {
                    let newObj = {
                        "MasterName": values.MasterName,
                        "TableName": values.TableName,
                        "JSON": {
                            "MasterName": values.MasterName,
                            "TableName": values.TableName,
                            // "columns": values && values.title.map((title, i) => {
                            //     return {
                            //         title,
                            //         key: values.key[i],
                            //         dataIndex: values.key[i],
                            //         widget: values.widget[i],
                            //         isMandatory: values.isMandatory[i],
                            //         disableOnUpdate: values.disableOnUpdate[i]
                            //     }
                            // }),
                            "columns": editObject.columns,
                            "uniqueness": values.uniqueness,
                            "api": {
                                "read": {
                                    "url": values.readURL,
                                    "method": values.readMethod
                                },
                                "create": {
                                    "url": values.createURL,
                                    "method": values.createMethod
                                },
                                "update": {
                                    "url": values.updateURL,
                                    "method": values.updateMethod
                                }
                            }
                        }


                    }
                    let updateResponse = await this._updateMasterData(newObj)
                    if (updateResponse && updateResponse.message) {
                        this.setState({
                            visible: false
                        })
                        this._getMasterList()
                        message.success(updateResponse.message);
                    }
                }

            })
        }
    }

    _saveMasterData = (data) => {
        const accessToken = localStorage.get('accessToken')
        const Object = {
            method: 'POST',
            url: `/api/masterconfiguration/insert`,
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            data
        }
        return axios(Object)
            .then((response) => {
                return response.data
            }).catch((error) => {
                return { "message": "Error" }
            })
    }

    _updateMasterData = (data) => {
        const accessToken = localStorage.get('accessToken')
        const Object = {
            method: 'PATCH',
            url: `/api/masterconfiguration/update`,
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            data
        }
        return axios(Object)
            .then((response) => {
                return response.data
            }).catch((error) => {
                return { "message": "Error" }
            })
    }

    _deleteMasterData = (MasterName) => {
        const accessToken = localStorage.get('accessToken')
        const Object = {
            method: 'POST',
            url: `/api/masterconfiguration/delete`,
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            data: {
                MasterName
            }
        }
        return axios(Object)
            .then((response) => {
                return response.data
            }).catch((error) => {
                return { "message": "Error" }
            })
    }

    onClose = () => {
        this.props.form.resetFields();
        this.setState({
            visible: false,
            editObject: {}
        })
    }

    remove = (k, object) => {
        let { editObject } = this.state
        if (!object._key) {
            let columns = editObject.columns.filter(function (value, index) {
                return index !== k
            })
            editObject = {
                ...editObject,
                columns
            }
            this.setState({ editObject })
            message.error('Record deleted')
        } else {
            message.error('Cant delete this record..')
        }
    }

    translation = (keyword) => {
        let { translationCache } = this.props
        return translationCache &&
            translationCache[keyword] ?
            translationCache[keyword] : keyword
    }

    render() {
        let { visible, masterList, editObject, masterTableNames } = this.state
        const { getFieldDecorator, getFieldValue } = this.props.form;

        let columns = [
            {
                title: this.translation(constant.Master_Name),
                dataIndex: "MasterName",
                key: "MasterName",
                render: text => <Link to={{ pathname: `/rubus/settingsPage/master/${text}` }}>{text}</Link>
            }, {
                title: this.translation(constant.Table_Name),
                dataIndex: "TableName",
                key: "TableName"
            }
        ]

        return (
            <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
                <Button type="primary" style={{ margin: "10px 0px", background: "rgb(33, 73, 114)" }} onClick={() => this.setState({ visible: true })}>
                    {this.translation(constant.Add)}
                </Button>
                <StyledTable theme={lightTheme}>
                    <Table
                        className="masterTable"
                        columns={columns}
                        dataSource={masterList}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => {
                                    this.setState({
                                        visible: true,
                                        editObject: record,
                                    });
                                },
                            };
                        }}
                    />
                </StyledTable>
                <Drawer
                    title=
                    {this.translation(constant.Configuration)}
                    placement="right"
                    closable={true}
                    width={1200}
                    onClose={this.onClose}
                    visible={visible}
                >
                    <Form layout='vertical'>
                        <Form.Item label=
                            {this.translation(constant.Master_Name)}
                        >
                            {getFieldDecorator('MasterName', {
                                initialValue: editObject.MasterName,
                                rules: [{ required: true, message: "Master Name is required" }]
                            })(
                                <Input style={{ width: 300 }} placeholder="Master Name" disabled={editObject.MasterName ? true : false}></Input>
                            )}
                        </Form.Item>
                        <Form.Item label={this.translation(constant.Table_Name)}>
                            {getFieldDecorator('TableName', {
                                initialValue: editObject.TableName,
                                rules: [{ required: true, message: "Table Name is required" }]
                            })(
                                <Select style={{ width: 300 }} placeholder="Table Name" disabled={editObject.TableName ? true : false}>
                                    {
                                        masterTableNames && Object.keys(masterTableNames)
                                            .filter(fil => {
                                                return !(masterList && masterList.map((master) => {
                                                    return master.TableName
                                                }).includes(fil))
                                            }
                                            )
                                            .map((master) => {
                                                return <Option value={master} >{master}</Option>
                                            })
                                    }
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item label={this.translation(constant.Columns)}>
                            <Button type="primary"
                                style={{ margin: "10px 0px" }}
                                onClick={this.addNew}
                                disabled={
                                    editObject && editObject.columns && masterTableNames
                                        && masterTableNames[getFieldValue('TableName')]
                                        && masterTableNames[getFieldValue('TableName')].length <= editObject.columns.length ? true : false
                                }
                            >{this.translation(constant.Add_New)}
                            </Button>
                            {
                                editObject && editObject.columns && editObject.columns.map((k, index) => {
                                    return <Row>

                                        <Col span={5}>
                                            <Form.Item
                                                label=
                                                {index === 0 ? this.translation(constant.Title) : ""}
                                                key={k.title}
                                            >
                                                {getFieldDecorator(`title[${index}]]`, {
                                                    initialValue: k.title,
                                                    rules: [{ required: true, message: "Title is required" }]
                                                })(
                                                    <Input
                                                        style={{ width: "90%" }}
                                                        placeholder="Enter Title"
                                                        onBlur={e => {
                                                            this.handleFieldChange(e.target.value, "title", index);
                                                        }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>

                                        <Col span={5}>
                                            <Form.Item label={index === 0 ? this.translation(constant.Key) : ""} key={k.title}>
                                                {getFieldDecorator(`key[${index}]]`, {
                                                    initialValue: k.key,
                                                    rules: [{ required: true, message: "key is required" }]
                                                })(
                                                    <Select
                                                        showArrow
                                                        showSearch
                                                        defaultValue={k.key}
                                                        openOnFocus
                                                        style={{ width: "90%" }}
                                                        onChange={e => this.handleFieldChange(e, "key", index)}
                                                        placeholder="Key"
                                                        filterOption={(input, option) =>
                                                            option.props.children
                                                                .toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {
                                                            masterTableNames && masterTableNames[getFieldValue('TableName')] &&
                                                            masterTableNames[getFieldValue('TableName')]
                                                                .filter(fil => {
                                                                    return !(editObject && editObject.columns && editObject.columns.map((master) => {
                                                                        return master.key
                                                                    }).includes(fil))
                                                                }
                                                                )
                                                                .map(keyNames => {
                                                                    return <Option value={keyNames}>{keyNames}</Option>;
                                                                })}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </Col>

                                        <Col span={5}>
                                            <Form.Item label={index === 0 ? this.translation(constant.Widget) : ""}
                                                key={k}>
                                                {getFieldDecorator(`widget[${index}]`, {
                                                    initialValue: k.widget,
                                                    rules: [{ required: true, message: "widget is required" }]
                                                })(
                                                    <div>
                                                        <Select
                                                            showArrow
                                                            showSearch
                                                            defaultValue={k.widget}
                                                            openOnFocus
                                                            style={{ width: "90%" }}
                                                            onChange={e => this.handleFieldChange(e, "widget", index)}
                                                            placeholder="Widget"
                                                            filterOption={(input, option) =>
                                                                option.props.children
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {["input", "select"].map(widget => {
                                                                return <Option value={widget}>{widget}</Option>;
                                                            })}
                                                        </Select>
                                                    </div>
                                                )}
                                                {k.widget === "select" ?
                                                    <div>
                                                        <Form.Item
                                                            label={index === 0 ? this.translation(constant.Url) : ""}
                                                            key={k.url}
                                                        >
                                                            {getFieldDecorator(`url[${index}]]`, {
                                                                initialValue: k.url,
                                                                rules: [{ required: true, message: "Url is required" }]
                                                            })(
                                                                <Input
                                                                    style={{ width: "90%", marginTop: "10%" }}
                                                                    placeholder="Enter Url"
                                                                    onBlur={e => {
                                                                        this.handleFieldChange(e.target.value, "url", index);
                                                                    }}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                        <Form.Item label={index === 0 ? this.translation(constant.Method) : ""}
                                                            key={k.method}>
                                                            {getFieldDecorator(`method[${index}]`, {
                                                                initialValue: k.method,
                                                                rules: [{ required: true, message: "Method is required" }]
                                                            })(
                                                                <Select
                                                                    defaultValue={k.method}
                                                                    openOnFocus
                                                                    onChange={e => this.handleFieldChange(e, "method", index)}
                                                                    style={{ width: "90%" }}
                                                                    placeholder="Select Method"
                                                                >
                                                                    <Option value="POST">POST</Option>
                                                                    <Option value="GET">GET</Option>
                                                                </Select>

                                                            )}
                                                        </Form.Item>
                                                        {k.method === "POST" ?
                                                            <Form.Item
                                                                label={index === 0 ? this.translation(constant.Payload) : ""}
                                                                key={k.payload}
                                                            >
                                                                {getFieldDecorator(`payload[${index}]]`, {
                                                                    initialValue: k.payload,
                                                                    rules: [{ required: true, message: "Payload is required" }]
                                                                })(
                                                                    <TextArea
                                                                        rows={4}
                                                                        style={{ width: "90%" }}
                                                                        placeholder="Enter Payload"
                                                                        onBlur={e => {
                                                                            this.handleFieldChange(e.target.value, "payload", index);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                            : null}
                                                    </div>
                                                    : null}
                                            </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                            <Form.Item label={index === 0 ? this.translation(constant.Is_Mandatory) : ""} key={k}>
                                                {getFieldDecorator(`isMandatory[${index}]`, {
                                                    initialValue: k.isMandatory,
                                                    rules: [{ required: true, message: "isMandatory is required" }]
                                                })(
                                                    <Select
                                                        defaultValue={k.isMandatory}
                                                        openOnFocus
                                                        style={{ width: "90%" }}
                                                        onChange={e => this.handleFieldChange(e, "isMandatory", index)}
                                                        placeholder="Is Mandatory ?"
                                                    >
                                                        <Option value={true}>true</Option>
                                                        <Option value={false}>false</Option>
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </Col>

                                        <Col span={5}>
                                            <Form.Item label={index === 0 ? this.translation(constant.Disable_On_Update) : ""}
                                                key={k}>
                                                {getFieldDecorator(`disableOnUpdate[${index}]`, {
                                                    initialValue: k.disableOnUpdate,
                                                    rules: [{ required: true, message: "disableOnUpdate is required" }]
                                                })(
                                                    <Select
                                                        defaultValue={k.disableOnUpdate}
                                                        openOnFocus
                                                        style={{ width: "90%" }}
                                                        onChange={e => this.handleFieldChange(e, "disableOnUpdate", index)}
                                                        placeholder="Is Mandatory ?"
                                                    >
                                                        <Option value={true}>true</Option>
                                                        <Option value={false}>false</Option>
                                                    </Select>
                                                )}
                                                {columns.length > 0 ? (
                                                    <Icon
                                                        style={{ marginLeft: "4px" }}
                                                        className="dynamic-delete-button"
                                                        type="minus-circle-o"
                                                        disabled={columns.length === 1}
                                                        onClick={() => this.remove(index, k)}
                                                    />
                                                ) : null}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                })
                            }


                        </Form.Item>

                        <Form.Item label={this.translation(constant.Unquie_Column_Relation)}>
                            <div>

                                {getFieldDecorator('uniqueness', {
                                    initialValue: editObject && editObject.uniqueness ? editObject.uniqueness : [],
                                    rules: [{ required: false, message: "uniqueness is required" }]
                                })(
                                    <Select style={{ width: 300 }} placeholder="Uniqueness" mode="multiple">
                                        {
                                            editObject && editObject.columns && editObject.columns.map((columns) => {
                                                return <Option value={columns.key}>{columns.key}</Option>
                                            })
                                        }
                                    </Select>
                                )}
                            </div>

                        </Form.Item>

                        <Form.Item label={this.translation(constant.Read_API)}>
                            <div>
                                {getFieldDecorator('readURL', {
                                    initialValue: editObject && editObject.api && editObject.api.read && editObject.api.read.url ? editObject.api.read.url : "",
                                    rules: [{ required: true, message: "Read URL is required" }]
                                })(
                                    <Input style={{ width: 300, marginRight: "5px" }} placeholder="Read API URL"></Input>
                                )}
                                {getFieldDecorator('readMethod', {
                                    initialValue: editObject && editObject.api && editObject.api.read && editObject.api.read.method ? editObject.api.read.method : undefined,
                                    rules: [{ required: true, message: "Read Method is required" }]
                                })(
                                    <Select style={{ width: 300 }} placeholder="Read method">
                                        <Option value="POST">POST</Option>
                                        <Option value="GET">GET</Option>
                                        <Option value="PATCH">PATCH</Option>
                                    </Select>
                                )}

                            </div>

                        </Form.Item>

                        <Form.Item label={this.translation(constant.Create_API)}>
                            <div>
                                {getFieldDecorator('createURL', {
                                    initialValue: editObject && editObject.api && editObject.api.create && editObject.api.create.url ? editObject.api.create.url : "",
                                    rules: [{ required: true, message: "Create URL is required" }]
                                })(
                                    <Input style={{ width: 300, marginRight: "5px" }} placeholder="Create API URL"></Input>
                                )}
                                {getFieldDecorator('createMethod', {
                                    initialValue: editObject && editObject.api && editObject.api.create && editObject.api.create.method ? editObject.api.create.method : undefined,
                                    rules: [{ required: true, message: "Create Method is required" }]
                                })(
                                    <Select style={{ width: 300 }} placeholder="Create method">
                                        <Option value="POST">POST</Option>
                                        <Option value="GET">GET</Option>
                                        <Option value="PATCH">PATCH</Option>
                                    </Select>
                                )}

                            </div>

                        </Form.Item>

                        <Form.Item label={this.translation(constant.Update_API)}>

                            <div>
                                {getFieldDecorator('updateURL', {
                                    initialValue: editObject && editObject.api && editObject.api.update && editObject.api.update.url ? editObject.api.update.url : "",
                                    rules: [{ required: true, message: "Update URL is required" }]
                                })(
                                    <Input style={{ width: 300, marginRight: "5px" }} placeholder="Update API URL"></Input>
                                )}
                                {getFieldDecorator('updateMethod', {
                                    initialValue: editObject && editObject.api && editObject.api.create && editObject.api.create.method ? editObject.api.create.method : undefined,
                                    rules: [{ required: true, message: "Update Method is required" }]
                                })(
                                    <Select style={{ width: 300 }} placeholder="Update method">
                                        <Option value="POST">POST</Option>
                                        <Option value="GET">GET</Option>
                                        <Option value="PATCH">PATCH</Option>
                                    </Select>
                                )}

                            </div>

                        </Form.Item>

                    </Form>
                    <DrawerFooter>
                        <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                            {this.translation(constant.Cancel)}
                        </Button>
                        <Button onClick={(e) => this._curdOperation(e, "delete")} type="danger" style={{ marginRight: 8 }}>
                            {this.translation(constant.Delete)}
                        </Button>
                        {
                            editObject.Id === undefined
                                ? <Button onClick={(e) => this._curdOperation(e, "create")} type="primary">
                                    {this.translation(constant.Create)}
                                </Button>
                                : <Button onClick={(e) => this._curdOperation(e, "update")} type="primary">
                                    {this.translation(constant.Update)}
                                </Button>
                        }
                    </DrawerFooter>

                </Drawer>
            </StyledDashboard>
        )
    }
}
const mapStateToProps = createStructuredSelector({
    language: getCurrentLanguage(),
    translationCache: getTranslationCache(),

});
export default connect(mapStateToProps)(Form.create()(MasterConfiguration));

