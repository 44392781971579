import React from "react"
import moment from "moment"
import { DatePicker } from 'antd'

import Bar from './Bar'

import { DatePickerSlider } from '../CSS/style'

class DateBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphprops: {
        ...props.graphprops,
        dateExists: 'true'
      } || {},
      selectionDate: {
         date: moment().utc().format("YYYY-MM-DD 00:00:00"),
       // date: moment().format("YYYY-MM-DD HH:mm:ss"),
        timeBucket: '1h'
      }
    };
  }


  requestCheck = true;
  abortController = new window.AbortController();


  onChangeDatePicker = (value, dateString) => {
    this.setState({
      selectionDate: {
        date: moment(dateString).format('YYYY-MM-DD 00:00:00'),
        timeBucket: '1h'
      }
    })
  }

  disabledDate = (current) => {
    return current > moment().endOf('day');
  }


  render() {
    let { selectionDate } = this.state
    console.log("selectionDate",selectionDate,"ss",moment(selectionDate.date));
    return (
      <div style={{ height: '70%', marginTop: '40px' }}>
        <DatePickerSlider>
          <DatePicker
            format="YYYY-MM-DD"
            value={moment(selectionDate.date)}
            onChange={this.onChangeDatePicker}
            disabledDate={this.disabledDate}
          />
        </DatePickerSlider>

        <Bar graphprops={this.state.graphprops} selectionDate={this.state.selectionDate} />
      </div>

    );
  }

}
export default DateBar
