import React, { Component } from "react";
import moment from "moment";
import axios from "axios";
import { Spin, Select, DatePicker } from "antd";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Panel from "./Panel";
import NewDashboard from "./NewDashboard";
import localStorage from "../../../utils/localStorage";
import history from "../../../commons/history";
import DayMonthYearSelection from '../DashboardComponents/Widgets/Selection/DayMonthYearSelection'
import HierarchyDropdownSelection from "./Widgets/Selection/HierarchyDropdownSelection";
import { makeSelectThemeing, makeSelectTheme } from "../../../selectors/theme";
import { getMenuCollapse } from "../../../selectors/layout"
import { getNavigationMode } from "../../../selectors/theme";

import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../selectors/language";
import { getDashboardByName } from "./DashboardCalls";
import { panelTranslation } from "../Utils/Adapter/translationAdapter";
import {
  DashboardDiv,
  DashboardHeaderDiv,
} from "./CSS/styles.js";

import "./CSS/Dashboard.css";

const { MonthPicker, WeekPicker, RangePicker } = DatePicker
const { Option } = Select;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardName: "",
      visible: false,
      panels: [],
      graphDataForEdit: "",
      translationCache: props.translationCache || {},
      showDateRange: false,
      selectionDateRange: {
        startDate: moment().subtract(1, "hour").format('YYYY-MM-DD HH:mm'),
        endDate: moment().format('YYYY-MM-DD HH:mm'),
        timeBucket: '1m'
      },
      deviceList: [],
      deviceId: undefined,
      selectionDate: moment().subtract(4, 'day').format("YYYY-MM-DD HH:mm:ss"),
      selectionMonth: moment().format('YYYY-MM'),
      selectionWeek: moment().startOf('week'),
      dayMonthYear: this.props.match.params.name === "SEC Deviation Chart" ? {
        startDate: moment().startOf('week'),
        endDate: moment().subtract(1, "day").format('YYYY-MM-DD 23:59:59'),
        timeBucket: '1d',
        selectionType: "week",
      } : {
        startDate: moment().subtract(1, "day").format('YYYY-MM-DD 00:00:00'),
        endDate: moment().subtract(1, "day").format('YYYY-MM-DD 23:59:59'),
        timeBucket: '1h',
        selectionType: "day",
      },
      shift: "Morning Shift",
      loading: true,
      selectedThemeing: this.props.selectedThemeing,
      selectedTheme: this.props.selectedTheme,
      dropdownSelection: {}
    };
  }

  componentWillMount() {
    let dashboardName = this.props.match.params.name;
    document.title = "Dashboard-" + dashboardName
    let deviceId = this.props.match.params.deviceId
    this.selectDashboard(dashboardName, deviceId);
  }

  componentDidUpdate(preprops) {
    if (
      this.props.match.params.name !== preprops.match.params.name ||
      this.props.language !== preprops.language ||
      this.props.match.params.deviceId !== this.state.deviceId
      || this.props.menuCollapse !== preprops.menuCollapse
      || preprops.navigationMode !== this.props.navigationMode
    ) {
      if (document.getElementsByClassName("layout")[0]) {
        if (document.getElementsByClassName('ant-layout-sider-trigger')[0]) {
          this.divWidth = parseInt(document.getElementsByClassName('ant-layout-sider-trigger')[0].style.width, 10);
        }
      }
      document.title = "Dashboard - " + this.props.match.params.name
      this.selectDashboard(
        this.props.match.params.name,
        this.props.match.params.deviceId
      );
      this.setState({
        selectionDateRange: {
          startDate: moment().subtract(2, "day").add(6, "hour").format('YYYY-MM-DD 00:00:00'),
          endDate: moment().subtract(1, "day").format('YYYY-MM-DD 23:59:59'),
          timeBucket: '1m'
        },
        selectionDate: moment().subtract(1, "day").format("YYYY-MM-DD HH:mm:ss"),
        selectionMonth: moment().format('YYYY-MM'),
        selectionWeek: moment().startOf('week'),
        dayMonthYear: this.props.match.params.name === "SEC Deviation Chart" ? {
          startDate: moment().startOf('week'),
          endDate: moment().subtract(1, "day").format('YYYY-MM-DD 23:59:59'),
          timeBucket: '1d',
          selectionType: "week",
        } : {
          startDate: moment().subtract(1, "day").format('YYYY-MM-DD 00:00:00'),
          endDate: moment().subtract(1, "day").format('YYYY-MM-DD 23:59:59'),
          timeBucket: '1h',
          selectionType: "day",
        },
      })
    }
  }

  _getDevicesList = (payload) => {
    const accessToken = localStorage.get("accessToken");
    let payloadData = payload && payload.payload ? payload.payload : ""
    const ObjectHeader = {
      method: payload.method,
      url: payload.url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: payloadData || {}
      // ...(payload && payload.payload ? { data: payload.payload } : {})
    };
    axios(ObjectHeader).then((response) => {
      if (Array.isArray(response.data)) {
        this.setState({ deviceList: response.data });
      } else {
        this.setState({
          deviceList: []
        })
      }

    }).catch((error) => {
      this.setState({
        deviceList: []
      })
    });
  };

  disabledDate = (current) => {
    return current > moment().startOf('day');
  };

  onChangeDatePicker = (value, dateString) => {
    this.setState({
      selectionDate: moment(dateString).format("YYYY-MM-DD 00:00:00")
    });
  };

  onChangeMonthPicker = (value, dateString) => {
    this.setState({
      selectionMonth: dateString,
    });
  };

  onChangeWeekPicker = (value, dateString) => {
    this.setState({
      selectionWeek: value,
    });
  };

  selectionpayload = (payload, type) => {
    this.setState({
      dayMonthYear: payload
    })
  }


  onDateRangePickerChange = (currentDate, dateString) => {
    let timeBucket = ""
    let selectionDateRange = {}
    if (currentDate && currentDate[0] && currentDate[1]) {
      let dayCount = currentDate[1].diff(currentDate[0], 'days')
      let hourCount = currentDate[1].diff(currentDate[0], 'hours')

      if (dayCount <= 2) {
        if (hourCount <= 1) {
          timeBucket = '1m'
        } else if (hourCount > 1 && hourCount <= 2) {
          timeBucket = '5m'
        } else if (hourCount >= 3 && hourCount <= 23) {
          timeBucket = '15m'
        } else {
          timeBucket = '1h'
        }

      } else if (dayCount > 2 && dayCount <= 60) {
        timeBucket = '1d'
      } else if (dayCount > 60) {
        timeBucket = '1week'
      } else {
        timeBucket = ''
      }

      selectionDateRange = {
        startDate: currentDate[0].format('YYYY-MM-DD HH:mm:ss'),
        endDate: currentDate[1].format('YYYY-MM-DD HH:mm:ss'),
        timeBucket
      }

    } else {

      selectionDateRange = {
        startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        timeBucket: '1h'
      }
    }
    this.setState({
      selectionDateRange
    })

  }

  handleDropdownChange = (value) => {
    let { dropdownSelection } = this.state
    dropdownSelection = {
      ...dropdownSelection,
      ...value
    }
    this.setState({
      dropdownSelection
    })
  }

  hierarchyDropdownChange = (value) => {
    let { dropdownSelection } = this.state
    let selectedValue = value && Array.isArray(value) && value.length > 0 ? value[value.length - 1] : undefined
    if (selectedValue) {
      this.setState({
        dropdownSelection: { ...dropdownSelection, "deviceId": selectedValue }
      })
    }

  }

  render() {
    let colorTheme = this.state.selectedTheme;
    const styles = {
      background: colorTheme,
    };
    const {
      selectionDate,
      selectionMonth,
      selectionWeek,
      selectionDateRange,
      panels,
      deviceList,
      loading,
      deviceId,
      shift,
      dayMonthYear,
      dropdownSelection, dashboardName
    } = this.state;

    if (this.state.dashboardName === "newdashboard") {
      return (
        <NewDashboard
          DeviceData={this.props.DeviceData}
          AssetData={this.props.AssetData}
          panels={this.state.panels}
        />
      );
    } else {
      return (
        <DashboardDiv className={"testDiv"} id="testdiv" style={styles}>
          {
            (panels && panels[0] && panels[0].dashboardType === "dynamic") ||
              (panels && panels[0] && panels[0].dashboardType === "multi") ||
              (panels && panels[0] && panels[0].dashboardType === "hierarchy") ||
              (panels && panels[0] && panels[0]["dateExists"] === "true") ||
              (panels && panels[0] && panels[0]["monthExists"] === "true") ||
              (panels && panels[0] && panels[0]["weekExists"] === "true") ||
              (panels && panels[0] && panels[0]['dayMonthYearExists'] === "true") ||
              (panels && panels[0] && panels[0]["dateRangeExists"] === "true") ? (
              <DashboardHeaderDiv>

                {panels && panels[0] && panels[0].dashboardType === "dynamic" ?
                  <Select
                    style={{ width: 200, marginTop: "10px", marginRight: "10px" }}
                    value={
                      deviceId
                        ? deviceId
                        : deviceList && Array.isArray(deviceList) && deviceList.length > 0
                          ? (deviceList[0].DeviceCode)
                          : ""
                    }
                    placeholder="Select device"
                    onChange={(e) => {
                      this.setState({ deviceId: e });
                      history.push(
                        `/rubus/dashboard/${this.state.dashboardName}/${e}`
                      );
                    }}
                  >
                    {
                      deviceList && Array.isArray(deviceList) && deviceList.map((device) => {
                        return (
                          <Option value={device.DeviceCode}>{device.DeviceCode}</Option>
                        );
                      })}
                  </Select>
                  : null}
                {
                  panels && panels[0] && panels[0].dashboardType === "hierarchy" ?
                    <div>
                      <HierarchyDropdownSelection handleDropdownChange={this.hierarchyDropdownChange} />
                    </div>
                    : null
                }

                {
                  panels && panels[0] && panels[0]['dayMonthYearExists'] === "true" ?
                    <DayMonthYearSelection selectionpayload={this.selectionpayload} dashboardName={dashboardName} />
                    : null
                }

                {panels && panels[0] && panels[0]["dateExists"] === "true" ? (
                  <DatePicker
                    style={{ marginTop: "10px", marginRight: "10px" }}
                    format="YYYY-MM-DD"
                    onChange={this.onChangeDatePicker}
                    value={moment(this.state.selectionDate)}
                    disabledDate={this.disabledDate}
                  />
                ) : null}

                {panels &&
                  panels[0] &&
                  panels[0]["dateRangeExists"] === "true" ? (
                  <RangePicker
                    style={{ marginTop: "10px", marginRight: "10px" }}
                    placeholder={["Start Date", "End Date"]}
                    value={[
                      moment(this.state.selectionDateRange.startDate, "YYYY-MM-DD HH:mm"),
                      moment(this.state.selectionDateRange.endDate, "YYYY-MM-DD HH:mm"),
                    ]}
                    disabledDate={this.disabledDate}
                    onChange={this.onDateRangePickerChange}
                  />
                ) : null}

                {panels && panels[0] && panels[0]["monthExists"] === "true" ? (
                  <MonthPicker
                    style={{ marginTop: "10px", marginRight: "10px" }}
                    disabledDate={this.disabledDate}
                    placeholder="Select month"
                    onChange={this.onChangeMonthPicker}
                    format='YYYY-MM'
                    value={moment(this.state.selectionMonth, "YYYY-MM")}
                  />
                ) : null}

                {panels && panels[0] && panels[0]["weekExists"] === "true" ? (
                  <WeekPicker
                    style={{ marginTop: "10px", marginRight: "10px" }}
                    disabledDate={this.disabledDate}
                    placeholder="Select Week"
                    onChange={this.onChangeWeekPicker}
                    value={this.state.selectionWeek}
                  />
                ) : null}

                {panels && panels[0] && panels[0]["shiftExists"] === "true" ? (
                  <Select
                    onChange={(e) => {
                      this.setState({ shift: e });
                    }}
                    style={{ marginTop: "10px", marginRight: "10px" }}
                    value={this.state.shift}
                  >
                    <Option value="Morning Shift">Morning Shift</Option>
                    <Option value="Night Shift">Night Shift</Option>
                  </Select>
                ) : null}

              </DashboardHeaderDiv>
            ) : null}

          <div>
            {this.state.panels &&
              this.state.panels.length > 0 &&
              loading === false ? (
              <Panel
                dashboardName={this.state.dashboardName}
                panels={this.state.panels}
                drilldownRedirect={this.drilldownRedirect}
                editGraph={this.editGraph}
                deviceId={
                  deviceId
                    ? deviceId
                    : deviceList && deviceList.length > 0
                      ? deviceList[0].DeviceCode
                      : ""
                }
                selectionDate={selectionDate}
                selectionMonth={selectionMonth}
                selectionWeek={selectionWeek}
                selectionDateRange={selectionDateRange}
                dayMonthYear={dayMonthYear}
                shift={shift}
                divWidth={this.divWidth}
                navigationMode={this.props.navigationMode}
                dropdownSelection={dropdownSelection}
              />
            ) : loading === true ? (
              <div
                style={{
                  textAlign: "center",
                  color: "white",
                }}
              >
                <h2 style={{ color: "white" }}>
                  <Spin
                    spinning={loading}
                    tip="Loading..."
                    size="large"
                  // style={{ marginTop: "25%" }}
                  ></Spin>
                </h2>
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  color: "white",
                  // marginTop: "25%",
                }}
              >
                <h2 style={{ color: "white" }}>No Panels Configured</h2>
              </div>
            )}
          </div>
        </DashboardDiv>
      );
    }
  }

  selectDashboard(dashboardName, deviceId) {
    if (dashboardName === "newdashboard") {
      this.setState({
        panels: [],
        dashboardName,
        deviceId: undefined,
        selectionDateRange: {
          startDate: moment()
            .subtract(1, "hour")
            .format("YYYY-MM-DD HH:mm"),
          endDate: moment().format("YYYY-MM-DD HH:mm"),
          timeBucket: "1m",
        },
        selectionDate: moment().subtract(1, 'day').format("YYYY-MM-DD HH:mm:ss"),
        selectionMonth: moment().format('YYYY-MM'),
        selectionWeek: moment().startOf('week'),
      });
    } else {
      this.setState({
        panels: [],
        dashboardName,
        loading: true,
        selectionDateRange: {
          startDate: moment()
            .subtract(1, "hour")
            .format("YYYY-MM-DD HH:mm"),
          endDate: moment().format("YYYY-MM-DD HH:mm"),
          timeBucket: "1m",
        },
        selectionDate: moment().subtract(1, 'day').format("YYYY-MM-DD HH:mm:ss"),
        selectionMonth: moment().format('YYYY-MM'),
        selectionWeek: moment().startOf('week'),
        deviceId,
      });
      this.getDashboard(dashboardName);
    }
  }

  getDashboard = async (e) => {
    let dashboards = await getDashboardByName(e);
    let deviceList = []
    if (dashboards) {
      if (dashboards.json && dashboards.json[0] && dashboards.json[0].dashboardType && dashboards.json[0].dashboardType === "dynamic") {
        if (dashboards.json[0].apiPayload && dashboards.json[0].apiPayload.method && dashboards.json[0].apiPayload.url) {
          deviceList = await this._getDevicesList(dashboards.json[0].apiPayload);
        } else {
          deviceList = []
        }
      }
      let dashboardTranslation = await panelTranslation(
        dashboards,
        this.props.translationCache,
        this.props.language
      );
      this.setState({
        panels: dashboardTranslation.json,
        dashboardName: dashboardTranslation.Name,
        loading: false,
        deviceList
      });
    } else {
      this.setState({
        loading: false
      })
    }
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  logout = () => {
    this.props.history.push("/");
  };

  updateDashboard(name) {
    if (name.length > 0) {
      localStorage.set("dashboardName", this.state.dashboardName);
      localStorage.set("panels", JSON.stringify(this.state.panels));
      this.props.history.push("newdashboard");
    } else {
      alert("Please Select Dashboard to edit....");
    }
  }

  deleteDashboard(name) {
    if (name.length > 0) {
    } else {
      alert("Please Select Dashboard to delete....");
    }
  }

  editGraph = (graphData) => {
    let {
      deviceId,
      selectionDate,
      selectionMonth,
      selectionWeek,
      selectionDateRange,
      shift,
      dayMonthYear,
      deviceList
    } = this.state;

    localStorage.set("graphData", JSON.stringify(graphData));
    this.props.history.push({
      pathname: `/rubus/dashboard/editpanel/${graphData.dashboardName}/${graphData.id}?kiosk=false`,
      selection: {
        deviceId: deviceId
          ? deviceId
          : deviceList && deviceList.length > 0
            ? deviceList[0].DeviceCode
            : "",
        selectionDate,
        selectionMonth,
        selectionWeek,
        selectionDateRange,
        dayMonthYear,
        shift,
      },
    });
  };

  drilldownRedirect = (url) => {
    this.props.history.push(`/rubus/dashboard/${url}`);
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  selectedTheme: makeSelectTheme(),
  menuCollapse: getMenuCollapse(),
  navigationMode: getNavigationMode()
});

export default connect(mapStateToProps)(Dashboard);

