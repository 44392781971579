import React from "react";
import {
  Modal,
  Checkbox,
  Input,
  Select,
  Tabs,
  Radio,
  Form,
  Tag,
  Icon
} from "antd";
import { SelectDropDown } from "./CSS/styles.js";
import { defaultGraphTypeProps, graphTypeList } from "./DefaultGraphTypeProps"
import TagInputWidget from "./Widgets/Selection/TagInputWidget";
import ColorInputWidget from "./Widgets/Selection/ColorInputWidget"

const Option = Select.Option;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;

class ConfigurationModalContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      graph: "",
      type: "",
      activeKey: "1",
      query: "",
      query1: "",
      query2: "",

      refreshTime: "",
      checked: false,
      enableExcel: false

    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.editData !== this.props.editData) {
      let editData = this.props.editData
      if (editData.length > 0) {
        this.setState({
          title: editData[0].title,
          graph: editData[0].graph,
          type: editData[0].type,
          checked: editData[0].checked,
          query: editData[0].query,
          query1: editData[0].query1,
          query2: editData[0].query2,
          queryRowsToColumns: editData[0].queryRowsToColumns,
          enableMock: editData[0].enableMock,
          enableExcel: editData[0].enableExcel,
          refreshTime: editData[0].refreshTime,
          [editData[0].graph]: editData[0][editData[0].graph]
        })
      }
    }
  }

  renderWidget(graphTypeProps) {
    let { graph } = this.state
    switch (graphTypeProps.Widget) {
      case 'Input':
        return <Form.Item label={graphTypeProps.DisplayName}>
          <Input
            value={this.state[graph] && this.state[graph][graphTypeProps.Key] ?
              this.state[graph][graphTypeProps.Key] : ""}
            onChange={(e) =>
              this.setState({
                [graph]: {
                  ...(this.state[graph]),
                  [graphTypeProps.Key]: e.target.value
                }
              })
            }
          />
        </Form.Item>
      case 'TextArea':
        return <Form.Item label={graphTypeProps.DisplayName}>
          <Input.TextArea rows={3}
            value={this.state[graph] && this.state[graph][graphTypeProps.Key] ?
              this.state[graph][graphTypeProps.Key] : ""}
            onChange={(e) =>
              this.setState({
                [graph]: {
                  ...(this.state[graph]),
                  [graphTypeProps.Key]: e.target.value
                }
              })
            }
          />
        </Form.Item>
      case 'Select':
        return <Form.Item label={graphTypeProps.DisplayName}>
          <Select
            value={this.state[graph] && this.state[graph][graphTypeProps.Key] ? this.state[graph][graphTypeProps.Key] : ""}
            onChange={(e) =>
              this.setState({
                [graph]: {
                  ...(this.state[graph]),
                  [graphTypeProps.Key]: e
                }
              })}
          >
            {
              graphTypeProps && graphTypeProps.Options && Array.isArray(graphTypeProps.Options) && graphTypeProps.Options.map((optionObject) => {
                return <Option value={optionObject}>{optionObject}</Option>
              })
            }

          </Select>
        </Form.Item>
      case 'TagList':
        return <Form.Item label={graphTypeProps.DisplayName}>
          <TagInputWidget
            graphTypeProps={graphTypeProps}
            value={this.state[graph] && this.state[graph][graphTypeProps.Key] ? this.state[graph][graphTypeProps.Key] : ""}
            addedTagList={(e) => this.addedTagList(e, graphTypeProps.Key)}
          />
        </Form.Item>
      case 'ColorList':
        return <Form.Item label={graphTypeProps.DisplayName}>
          <ColorInputWidget
            graphTypeProps={graphTypeProps}
            value={this.state[graph] && this.state[graph][graphTypeProps.Key] ? this.state[graph][graphTypeProps.Key] : ""}
            addedTagList={(e) => this.addedTagList(e, graphTypeProps.Key)}
          />
        </Form.Item>
    }
  }

  addedTagList = (valueList, key) => {

    let { graph } = this.state
    this.setState({
      [graph]: {
        ...(this.state[graph]),
        [key]: valueList
      }
    })
  }

  render() {
    let { activeKey, graph, title, refreshTime, checked, type } = this.state
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
    };
    let defaultProps = {
      activeKey: "1",
      title: "",
      graph: "",
      type: "",
      query: "",
      query1: "",
      query2: "",
      queryRowsToColumns: false,
      enableMock: false,
      refreshTime: "",
      checked: true
    }
    return (
      <Modal
        width={1200}
        title="Panel Configuration"
        visible={this.props.visible}
        onOk={() => {
          this.props.onOk(this.state);
          this.setState({
            ...defaultProps
          });
        }}
        onCancel={() => {
          this.props.onCancel();
          this.setState({
            ...defaultProps
          });
        }}
      >
        <Tabs activeKey={activeKey} onChange={(activeKey) => this.setState({ activeKey })}>
          <TabPane tab="Config" key="1">
            <Form {...formItemLayout} >
              <Form.Item label="Panel Name">
                <Input
                  type="text"
                  value={title}
                  onChange={(e) => this.setState({ title: e.target.value })}
                />
              </Form.Item>
              <Form.Item label="Graph Type">
                <SelectDropDown
                  onChange={(graph) => {
                    this.setState({ graph })
                  }}
                  value={graph}
                >
                  {
                    graphTypeList && Array.isArray(graphTypeList) && graphTypeList.map((graphType) => {
                      return <Option value={graphType.value}>{graphType.key}</Option>
                    })
                  }

                </SelectDropDown>
              </Form.Item>
              <Form.Item label="Type">
                <Radio.Group value={type}
                  onChange={(e) => {
                    this.setState({
                      type: e.target.value
                    })
                  }} >
                  <Radio.Button value="live">Live</Radio.Button>
                  <Radio.Button value="historic">Historic</Radio.Button>
                </Radio.Group>
              </Form.Item>

              {
                defaultGraphTypeProps && defaultGraphTypeProps[graph] && Array.isArray(defaultGraphTypeProps[graph]) &&
                defaultGraphTypeProps[graph].map((graphTypeProps) => {
                  return (this.renderWidget(graphTypeProps))
                })
              }

              <Form.Item label="Refresh Time">
                <Input
                  type="text"
                  value={refreshTime}
                  onChange={(e) => {
                    this.setState({
                      refreshTime: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label=" ">
                <Checkbox
                  checked={checked}
                  style={{ color: "black" }}
                  onChange={(e) => {
                    this.setState({
                      checked: e.target.checked,
                    });
                  }}
                >
                  Refresh TimeStamp
                </Checkbox>
              </Form.Item>
            </Form>

          </TabPane>
          <TabPane tab="Query" key="2">
            <div>
              <Checkbox checked={this.state.queryRowsToColumns} onChange={(value) => {
                this.setState({
                  queryRowsToColumns: value.target.checked
                })
              }}>Query RowsToColumns</Checkbox>
              <Checkbox checked={this.state.enableMock} onChange={(value) => {
                this.setState({
                  enableMock: value.target.checked
                })
              }}>Enable mock</Checkbox>

              <Input.TextArea
                style={{ marginTop: "10px" }}
                placeholder="Custom Query"
                autosize={{ minRows: 4 }}
                onChange={(e) => {
                  this.setState({ query: e.target.value });
                }}
                value={this.state.query}
              />
              {
                this.state.graph === "dateRangeNotificationScatter"
                  || this.state.graph === "notificationScatter"
                  // || this.state.graph === "TableDatePicker"
                   ?
                  <Input.TextArea
                    style={{ marginTop: "10px" }}
                    placeholder="Custom Query"
                    autosize={{ minRows: 4 }}
                    onChange={(e) => {
                      this.setState({ query1: e.target.value });
                    }}
                    value={this.state.query1}
                  />
                  : ""
              }
             
            </div>
          </TabPane>
          <TabPane tab="Excel" key="3">
            <Checkbox
              onChange={(e) => this.setState({ enableExcel: e.target.checked })}
              checked={this.state.enableExcel}
            >
              Enable Excel Download
            </Checkbox>

          </TabPane>
        </Tabs>
      </Modal>
    );
  }


}

export default ConfigurationModalContent;
