import React from 'react'
import moment from 'moment'
import { DatePicker, Radio, Menu, Select } from 'antd'

import { DatePickerSlider } from '../../../ChartComponents/CSS/style'
import "../../CSS/style.css"
const { Option } = Select;
const { WeekPicker } = DatePicker

class DayMonthYearSelection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentDate: moment().subtract(1, 'day'),
            endDate: moment(),
            disableNext: false,
            currentTab: this.props.dashboardName !== "SEC Deviation Chart" ? 'today' : "week",
            week: moment().startOf('week'),
            month: moment().format('MMMM'),
            year: moment().year(),
            payload: {
                startDate: moment().format('YYYY-MM-DD 00:00:00'),
                endDate: moment().format('YYYY-MM-DD 23:59:59'),
                selectionType: "day",
                timeBucket: '1h'
            } || {}
        };
    }

    disabledDate = (current) => {
        return current > moment().startOf('day')
    }

    onChange = async (date) => {
        let startDateSelected = moment(date).format('YYYY-MM-DD 00:00:00')
        let endDateSelected = moment(date).format('YYYY-MM-DD 23:59:59')
        let payload = {
            startDate: moment(startDateSelected).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(endDateSelected).format('YYYY-MM-DD HH:mm:ss'),
            timeBucket: '1h',
            selectionType: "day"
        }
        this.setState({ currentDate: date, payload })
        this.props.selectionpayload(payload, "daily")
    }

    onRadioChange = (e) => {
        if (e.target.value === 'month') {
            let currentMonth = moment().format('MMMM')
            let currentYear = moment().year()
            this.handleMenuClick(currentMonth, 'month')
            this.setState({
                currentTab: e.target.value,
                month: currentMonth,
                year: currentYear
            })
        } else if (e.target.value === 'year') {
            let currentYear = moment().year()
            this.handleMenuClick(currentYear, 'year')
            this.setState({
                currentTab: e.target.value,
                year: currentYear
            })
        } else if (e.target.value === 'week') {
            let currentWeek = moment().startOf('isoWeek')
            this.handleMenuClick(currentWeek, 'week')
            this.setState({
                currentTab: e.target.value,
                week: currentWeek
            })
        } else {
            this.onChange(moment().subtract(1, 'day'))
            this.setState({
                currentTab: e.target.value,
            })
        }
    }

    getNavMenuItems = (menusData, parent) => {
        if (!menusData) {
            return []
        }
        return menusData
            .map(item => {
                return <Menu.Item key={item} >{item}</Menu.Item>
            })
            .filter(item => item)
    };

    handleMenuClick = async (value, parent) => {
        if (parent === 'month') {
            // let currentYear = this.state.year
            let currentYear = moment().year()
            let month = moment(value, 'MMMM').month()
            let payload = {

                startDate: moment([currentYear, month]).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                endDate: currentYear === moment().year() && month === parseInt(moment().format("M") - 1) ?
                    this.props.dashboardName === "Crusher Consumption Dashboard" ?
                        moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss') : moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss') :

                    moment([currentYear, month]).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
                timeBucket: '1d',
                selectionType: "month",
            }
            this.setState({ currentDate: value, payload, month: value, })
            this.props.selectionpayload(payload, "monthly")
        } else if (parent === 'year') {
            let payload = {
                startDate: moment([value, 0]).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                endDate: value === moment().year() ? this.props.dashboardName === "Crusher Consumption Dashboard" ?
                    moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss') : moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss') :
                    moment([value, 11]).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
                timeBucket: '30d',
                selectionType: "year",
            }
            this.setState({ currentDate: value, payload, year: value, })
            this.props.selectionpayload(payload, "yearly")
        } else if (parent === 'week') {

            let currentWeek = moment().startOf('isoWeek')
            let payload = {
                startDate: moment(value).startOf('week').format('YYYY-MM-DD HH:mm:ss'),
                endDate: value.isSame(currentWeek) ? this.props.dashboardName === "Crusher Consumption Dashboard" ?
                    moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss') : moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss') :
                    moment(value).endOf('week').format('YYYY-MM-DD HH:mm:ss'),
                timeBucket: '1d',
                selectionType: "week",
            }
            this.setState({ currentDate: value, payload, week: moment(value).startOf('week') })
            this.props.selectionpayload(payload, "weekly")
        }
    }

    componentWillUnmount() {
        clearInterval(this.timeTicket)
    }

    render() {
        let { currentTab } = this.state

        if (moment().year() > 2019) {
            var yearList = [];
            for (var i = 2019; i <= moment().year(); i++) {
                yearList.push(i);
            }
            yearList.sort((a, b) => b - a)
        }

        return (
            <div style={{ height: '70%', marginTop: '10px' }}>

                <DatePickerSlider>
                    <Radio.Group defaultValue={this.state.currentTab} onChange={this.onRadioChange} style={{ marginLeft: '20px' }}>
                        {this.props.dashboardName !== "SEC Deviation Chart" ?
                            <Radio.Button value="today">Daily</Radio.Button> : null}
                        <Radio.Button value="week">Week</Radio.Button>
                        <Radio.Button value="month">Month</Radio.Button>
                        <Radio.Button value="year">Year</Radio.Button>
                    </Radio.Group>
                    {
                        currentTab === "today" ?
                            this.props.dashboardName !== "SEC Deviation Chart" ?
                                <DatePicker
                                    onChange={this.onChange}
                                    value={moment(this.state.currentDate)}
                                    disabledDate={this.disabledDate} /> : null
                            : null
                    }
                    {
                        currentTab === "week" ?

                            <WeekPicker
                                value={this.state.week}
                                style={{ width: '200px' }}
                                disabledDate={this.disabledDate}
                                placeholder="Select Week"
                                onChange={(e) => this.handleMenuClick(e, "week")}
                            />
                            : null
                    }
                    {
                        currentTab === "month" ?
                            <Select
                                value={this.state.month}
                                onChange={(e) => this.handleMenuClick(e, "month")}
                                style={{ width: '100px' }}
                            >
                                {
                                    moment.months().map((value) => {
                                        return <Option value={value}>{value}</Option>
                                    })
                                }
                            </Select>

                            : null
                    }
                    {
                        currentTab === "year" || currentTab === "month" ?
                            <Select
                                value={this.state.year}
                                onChange={(e) => this.handleMenuClick(e, "year")}
                                style={{ width: '100px' }}
                            >
                                {
                                    yearList.map((value) => {
                                        return <Option value={value}>{value}</Option>
                                    })
                                }
                            </Select>
                            : null
                    }
                </DatePickerSlider>
            </div>
        );
    }



}

export default DayMonthYearSelection;
