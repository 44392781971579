import styled from "styled-components";
import { Select, Button, Input, Tabs, Checkbox, Modal } from "antd";
import { Picky } from "react-picky";

import "react-picky/dist/picky.css";
//import Paragraph from "antd/lib/skeleton/Paragraph";

export const StyledDashboard = styled.div`
background: rgb(26, 54, 82);
margin: 5px;
padding: 30px;
`;

export const Tabstyled = styled(Tabs)`

.ant-tabs-nav-container {
}
.ant-tabs-nav {
}
.ant-tabs-tab {
  background: #1a3652 !important;
  border-radius: 8px;
  color: white !important;
  font-weight: 800;
  border:1px solid #fff !important;
}

.ant-tabs-extra-content {
  margin-right: 6%;
  margin-top: 0.6%;
}
 .ant-tabs-tab-active{
  background: #fff !important;
  border-radius: 8px;
  color: black !important;
  font-weight: 800;
  border:1px solid #1a3652 !important
  
}
`;

export const StyledAppdiv = styled.div`
  background: rgb(41, 65, 90);
  backgroundborder-style: ridge;
  border-width: 5px;
  // color: white;
  // border:1px solid #000;
  border-radius: 5px;
`;
export const RuleTypeSelectionDiv = styled.div`
  padding-left: 1%;
  margin-top: 1%;
  display: -webkit-inline-box;
`;

export const BlockedLabel = styled.label`
  display: block;
  background-color: initial;
  font-weight: 700;
  color: white;
  margin-bottom: 7px;
`;

export const BlockedLabelBlack = styled.label`
  display: block;
  color: #fff !important;
  font-weight: 700;
  margin-bottom: 7px;
`;
export const BlockedParaBlack = styled.p`
  display: block;
  color: #fff;
  font-weight: 700;
  margin-bottom: 0px;
`;
export const ConditionSelect = styled(Select)`
  width: 120px;
  display: block;
  margin-bottom: 1%;
  margin-left: 29.2%;
  margin-right:1% !important;
`;
export const StyledSpan = styled.span`
 
  font-weight: 700;
`;


export const TableConditionSelect = styled(Select)`
  width: 120px;
  display: block;
  margin-bottom: 1%;
  margin-left: 24.8%;
`;

export const StyledModel = styled(Modal)`
  width: 40% !important;

  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-modal-header {
    border: none;
    border-radius: 20px;
  }
`;

export const GoBackButton = styled(Button)`
  margin: 1%;  
  background: #154a80 !important;
  border-color: #fff !important;  
  font-weight: bold !important;
`;


export const RestConditionSelect = styled(Select)`
  width: 120px;
  display: block;
  margin-bottom: 1%;
  margin-left: 21.1%;
`;

export const ActionSelectionDiv = styled.div`
  margin-bottom: 1%;
  margin-top: 1%;
  border: 2px dotted gray;
  display: inline-block;
  padding: 10px;
`;

export const AddParagraph = styled.p`
  float: right;
  margin-right: 1%;
  color: #fff;
  font-weight: 700;
  font-size:15px
`;

export const BlankDiv = styled.div`
  margin-bottom: 1%;
  margin-left: 1%;
  display: inline-block;
  padding: 10px;
`;

export const SaveButton = styled(Button)`
  float: right;
  margin-right: 6%;
  top: 10px;
  background: #154a80 !important;
  border-color  : #fff !important
  font-weight: bold !important;
`;
export const RuleButton = styled(Button)`
 
  background: #178efd !important;
  border-color  :#178efd !important
  font-weight: bold !important;
`;
export const LogicAddButton = styled(Button)`
  background-color: #193652 !important;
  border:1px solid #193652 !important;
  font-weight: 700 !important;
`;

export const DeviceSelect = styled(Select)`
  width: 225px;
  display: inline-block;
  margin-right: 1% !important;
`;
export const TagNameSelect = styled(Select)`
  width:250px;
  margin-right: 1% !important;
`;

export const ColumnNameSelect = styled(Select)`
  width: 120px;
  margin-right: 1%;
`;

export const OperaterSelect = styled(Select)`
  width: 150px;
  margin-right: 1% !important;
`;

export const ValueInput = styled(Input)`
  width: 120px;
  margin-right: 1%;
`;

export const ButtonBoxDiv = styled.div`
  margin-left: 10px;
  width: 40%;
  display: inline-block;
  margin-top:10px;
`;

export const PickyStyleing = styled(Picky)`
  .picky__input {
    width: 100%;
    background-color: white;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 5px;
    text-align: left;
    position: relative;
    box-sizing: border-box;
    height: 32px;
  }
  .picky__placeholder {
    color: rgba(0, 0, 0, 0.9) !important;
    font-size: 14px;
    font-variant: tabular-nums;
    position: relative;
    bottom: 2px;
    left: 5px;
  }
  .picky__input::after {
    top: 12px;
  }
`;

export const ActionCheckbox = styled(Checkbox.Group)`
  font-weight: 600;
  
`;

export const Boxdiv = styled.div`
  padding: 1%;
  border-radius:8px;
  margin: 10px;
  width:100%;
  // border-bottom: 2px solid #ebedf0;
  // background: #ececec;
  color:#000;
  
`;

export const MarginRightButton = styled(Button)`
  margin-right: 10px;
  background: #fd4d4f !important;
  font-weight: 700 !important;
  color: #ffffff !important;
`;
export const OnchangeCheckbox = styled(Checkbox)`
  padding-top: 25px;
  margin-left: 10px;
  color: #fff !important;
  font-weight: 700;
`;
export const StatusCheckBox = styled(Checkbox)`

`;
// export const StyledDashboard = styled.div`
// background: #193652;
// margin: 20px;
// padding: 30px;
// `
export const darkTheme = {
  text: "rgba(0,0,0,.85)",
  even: "#595959",
  odd: "#8c8c8c",
  hover: "#f5f5f5",
};

export const lightTheme = {
  text: "black",
  even: "#7a8793",
  odd: "#bdc0c2",
  hover: "#dedede",
};

export const StyledTable = styled.table`
 //background:#bfbfbf !important;
   caption-side: top;
 
 
   color: ${(props) => props.theme.text} !important;
   thead[class*="ant-table-thead"] th{
     background-color: #214972 !important;
     color: #fff !important;
     text-align: center;
   }
   width: 100%;
   /* border-collapse: separate; */
   /* border-spacing: 5px 10px; */
 
   caption-side: bottom;
   /* empty-cell: show | hide;  */
   /* empty-cell is a property of table or the cells themselves */
 
   /* vertical-align: baseline | sub | super | text-top | 
                 text-bottom | middle | top | bottom | 
                 <percentage> | <length> */
 
   /* tbody {
     vertical-align: top;
   }              */
   td,
   th {
     border: none;
   }
   /* td,
   th {
       color:${(props) => props.theme.text} !important;
     border: 1px solid red;
    
   } */
 
   td {
     padding: 11px 10px;
 
   }
   .ant-table-thead > tr > th{
     font-weight:bold !important
     font-size: 1.2em !important;
      // color:red !important
   }
   text[style] {
     font-weight:bold !important
     font-size: 1.15em !important;
     color: ${(props) => props.theme.text} !important;
  }
   tbody tr {
     :nth-of-type(odd) {
       background-color:${(props) => props.theme.odd} !important;
     }
     :hover {
       background-color:${(props) => props.theme.hover} !important; 
     }
   }
   tbody tr {
     :nth-of-type(even) {
       background-color:${(props) => props.theme.even} !important;
     }
     :hover {
       background-color:${(props) => props.theme.hover} !important; 
     }
     text-align: center;
   }
   thead > tr {
     color: ${(props) => props.theme.text} !important;
     background-color: #262626 !important;
   }
   caption {
     font-size: 0.9em;
     padding: 5px;
     font-weight: bold;
   }
 `;
export const PickyStyling = styled(Picky)`
  .picky__input {
    width: 100%;
    background-color: white;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 5px;
    text-align: left;
    position: relative;
    box-sizing: border-box;
    height: 32px;
  }
  .picky__placeholder {
    color: rgba(0, 0, 0, 0.9) !important;
    font-size: 14px;
    font-variant: tabular-nums;
    position: relative;
    bottom: 2px;
    left: 5px;
  }
  .picky__input::after {
    top: 12px;
  }
`;

export const TextParagraph = styled.p`
  color: #fff;
  margin-left: 2%;
  display: inline-block;
  font-size: 14px;
  font-weight:bold;
  background: #1a3652;
  // border: 1px solid #609de1;
  padding: 3px;
  border-radius: 4px;
}

`;
