import React from "react";
import styled from "styled-components";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Input, Button, message, Select, Form } from "antd";

import history from '../../../commons/history'
import { insertDashboard, updateDashboard } from "./DashboardCalls";
import ConfigurationModalContent from "./ConfigurationModalContent";
import DashboardLevelConfig from './DashboardLevelConfig'
import { getDashboardByName } from './DashboardCalls'
import { getUserProfile } from "../../../selectors/layout";
import { createStructuredSelector } from "reselect";
import localstorage from '../../../utils/localStorage'
import { connect } from "react-redux";

import "./CSS/NewDashboard.css";
import "react-grid-layout/css/styles.css";

const Option = Select.Option;

const Title = styled.h3`
  color: white;
  font-family: Roboto-Medium;
  text-align: center;
  font-size: 0.9em;
  padding: 0.7em 0em;
  margin: 0px;
`;

const PanelDiv = styled.div`
  background-color: #0d1115;
`;

const EditDashboardDiv = styled.div`
  background-color: rgb(9, 26, 45);
  min-height: 100vh;
  margin-top: -58px;
`;

const SaveDashboardBtn = styled(Button)`
  float: right;
  margin-left: 10px;
  margin-top: 20px;
`;

const DashboardNameTxtBox = styled(Input)`
  width: 230px;
  float: right;
  margin-left: 10px;
`;

const DashboardTheme = styled(Select)`
  float: right;
`;

const AddPanelBtn = styled(Button)`
  margin-left: 20px;
  float: left;
`;

const DeletePanelDiv = styled.div`
  text-align: center;
  margin-top: 15px;
`;

const AddConfigurationDiv = styled.div`
  text-align: center;
`;

const ResponsiveReactGridLayout = WidthProvider(Responsive);
class NewDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      panels: [],
      visible: false,
      editData: [],
      dashboardName: "",
      id: "",
      mode: "insert",
      dashboardType: "static",
      dateExists: "false",
      dateRangeExists: "false",
      monthExists: "false",
      shiftExists: "false",
      weekExists: "false",
      dayMonthYearExists: "false",
      apiPayload: {

      },
      userProfile: props && props.userProfile ? props.userProfile : {},
      hierarchyLevels: []
    };
    this.counter = 0;
  }
  componentDidUpdate = prevProps => {
    let siteId = localstorage.get('currentSite');
    if (prevProps.userProfile !== this.props.userProfile) {
      this.setState({
        userProfile: this.props.userProfile,
        hierarchyLevels: this.props.userProfile &&
          this.props.userProfile.belongsTo.sites[siteId]
          ? this.props.userProfile.belongsTo.sites[siteId].hierarchy
          : [],

      });
    }
  };

  componentDidMount() {
    let dashboardName = this.props.history.location;
    if (dashboardName && dashboardName.state && dashboardName.state !== "new") {
      this.getDashboardDatayName(dashboardName.state);
    }
  }

  getDashboardDatayName = async (dashboardName) => {
    let dashboardData = await getDashboardByName(dashboardName)
    this.setState({
      mode: "update",
      dashboardData,
      dashboardName: dashboardData && dashboardData.Name ? dashboardData.Name : "",
      dashboardId: dashboardData && dashboardData.Id ? dashboardData.Id : "",
      dashboardType: dashboardData && dashboardData.json && dashboardData.json[0] && dashboardData.json[0].dashboardType ? dashboardData.json[0].dashboardType : "static",
      panels: dashboardData && dashboardData.json ? dashboardData.json : [],
      dateExists: dashboardData && dashboardData.json && dashboardData.json[0] && dashboardData.json[0].dateExists ? dashboardData.json[0].dateExists : "false",
      dateRangeExists: dashboardData && dashboardData.json && dashboardData.json[0] && dashboardData.json[0].dateRangeExists ? dashboardData.json[0].dateRangeExists : "false",
      monthExists: dashboardData && dashboardData.json && dashboardData.json[0] && dashboardData.json[0].monthExists ? dashboardData.json[0].monthExists : "false",
      shiftExists: dashboardData && dashboardData.json && dashboardData.json[0] && dashboardData.json[0].shiftExists ? dashboardData.json[0].shiftExists : "false",
      weekExists: dashboardData && dashboardData.json && dashboardData.json[0] && dashboardData.json[0].weekExists ? dashboardData.json[0].weekExists : "false",
      dayMonthYearExists: dashboardData && dashboardData.json && dashboardData.json[0] && dashboardData.json[0].dayMonthYearExists ? dashboardData.json[0].dayMonthYearExists : "false",
      apiPayload: dashboardData && dashboardData.json && dashboardData.json[0] && dashboardData.json[0].apiPayload ? dashboardData.json[0].apiPayload : {}
    })
  }


  enterURL = (e) => {
    let { apiPayload } = this.state
    this.setState({
      apiPayload: {
        url: e.target.value,
        method: apiPayload && apiPayload.method ? apiPayload.method : "",
        ...(apiPayload && apiPayload.method === "POST" ? { payload: apiPayload.payload } : {})
      }
    })
  }

  selectMethod = (value) => {
    let { apiPayload } = this.state
    this.setState({
      apiPayload: {
        url: apiPayload && apiPayload.url ? apiPayload.url : "",
        method: value,
        ...(value && value === "POST" ? {
          payload: {
            "siteId": 1,
            "assetCode": "2"
          }
        } : {})
      }
    })
  }

  // selectPayload = (value) => {
  //   let { apiPayload } = this.state
  //   this.setState({
  //     apiPayload: {
  //       url: apiPayload && apiPayload.url ? apiPayload.url : "",
  //       method: apiPayload && apiPayload.method ? apiPayload.method : "GET",
  //       payload : value
  //     }
  //   })
  // }
  hierarchyOnChange = (value) => {
  }

  render() {
    let { dashboardName, dashboardType, panels, apiPayload, dateExists,
      dateRangeExists, monthExists, weekExists, dayMonthYearExists, shiftExists } = this.state
    return (
      <EditDashboardDiv>
        <div style={{ padding: "10px", marginTop: "64px" }}>
          <SaveDashboardBtn
            type="danger"
            shape="round"
            icon="save"
            onClick={this.saveDashboard}
          >
            Save Dashboard
          </SaveDashboardBtn>
          <div>.</div>
          <DashboardNameTxtBox
            type="text"
            value={dashboardName}
            placeholder="Dashboard Name"
            onChange={(e) => {
              this.setState({ dashboardName: e.target.value });
            }}
          />
          <DashboardTheme
            onChange={(e) => {
              this.setState({ dashboardType: e, apiPayload: e && e === 'static' ? {} : apiPayload });
            }}
            value={dashboardType}
          >
            <Option value="static">Static</Option>
            <Option value="dynamic">Dynamic</Option>
            <Option value="hierarchy">Hierarchy</Option>
          </DashboardTheme>

          <DashboardTheme
            onChange={(e) => {
              this.setState({ dateExists: e });
            }}
            value={dateExists}
          >
            <Option value="true"> Date true</Option>
            <Option value="false">Date false</Option>
          </DashboardTheme>

          <DashboardTheme
            onChange={(e) => {
              this.setState({ dateRangeExists: e });
            }}
            value={dateRangeExists}
          >
            <Option value="true"> DateRange true</Option>
            <Option value="false">DateRange false</Option>
          </DashboardTheme>

          <DashboardTheme
            onChange={(e) => {
              this.setState({ monthExists: e });
            }}
            value={monthExists}
          >
            <Option value="true">Month true</Option>
            <Option value="false">Month false</Option>
          </DashboardTheme>

          <DashboardTheme
            onChange={(e) => {
              this.setState({ weekExists: e });
            }}
            value={weekExists}
          >
            <Option value="true">Week true</Option>
            <Option value="false">Week false</Option>
          </DashboardTheme>

          <DashboardTheme
            onChange={(e) => {
              this.setState({ dayMonthYearExists: e });
            }}
            value={dayMonthYearExists}
          >
            <Option value="true"> DayMonthYear true</Option>
            <Option value="false">DayMonthYear false</Option>
          </DashboardTheme>

          <DashboardTheme
            onChange={(e) => {
              this.setState({ shiftExists: e });
            }}
            value={shiftExists}
          >
            <Option value="true">Shift true</Option>
            <Option value="false">Shift false</Option>
          </DashboardTheme>

          <AddPanelBtn
            type="danger"
            shape="round"
            icon="plus-circle"
            onClick={this.addPanel}
          >
            Add Panel
          </AddPanelBtn>
          <label className="fileContainer">
            <i
              aria-label="icon: plus-circle"
              class="anticon anticon-plus-circle"
              style={{ marginLeft: "10px" }}
            >
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="import"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M888.3 757.4h-53.8c-4.2 0-7.7 3.5-7.7 7.7v61.8H197.1V197.1h629.8v61.8c0 4.2 3.5 7.7 7.7 7.7h53.8c4.2 0 7.7-3.4 7.7-7.7V158.7c0-17-13.7-30.7-30.7-30.7H158.7c-17 0-30.7 13.7-30.7 30.7v706.6c0 17 13.7 30.7 30.7 30.7h706.6c17 0 30.7-13.7 30.7-30.7V765.1c0-4.3-3.5-7.7-7.7-7.7zM902 476H588v-76c0-6.7-7.8-10.5-13-6.3l-141.9 112a8 8 0 000 12.6l141.9 112c5.3 4.2 13 .4 13-6.3v-76h314c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z"></path>
              </svg>
            </i>
            <span style={{ marginLeft: "8px" }}>import</span>
            <input
              type="file"
              onChange={(e) => this.handleChangeFile(e.target.files)}
              accept=".json"
            />
          </label>
          <div style={{ textAlign: "center" }}>
            <h3 style={{ color: "white" }}>New dashboard creation</h3>
          </div>
        </div>
        {
          this.state.dashboardType === "dynamic" ?
            <div style={{ padding: "10px", display: "flex", flexDirection: "row-reverse" }}>
              <Select style={{ width: "200px" }}
                value={apiPayload && apiPayload.method ? apiPayload.method : undefined}
                placeholder={"Select Method"}
                onChange={this.selectMethod}>
                <Option value="GET">GET</Option>
                <Option value="POST">POST</Option>
              </Select>
              <Input style={{ width: "300px" }} value={apiPayload && apiPayload.url ? apiPayload.url : ""}
                placeholder="Enter URL"
                onChange={this.enterURL} />
              {/* {
              apiPayload && apiPayload.method && apiPayload.method === "POST" ? 
              <Input style={{ width: "300px" }}
               value={apiPayload && apiPayload.payload && Object.keys(apiPayload.payload)
                && Object.keys(apiPayload.payload).length > 0 ? JSON.parse(apiPayload.payload) : ""} placeholder="Enter payload" onBlur={this.selectPayload} />
           
              : null
            } */}


            </div>

            : null
        }
        <div>{panels.length > 0 ? this.createPanels() : ""}</div>
        <ConfigurationModalContent
          DeviceData={this.props.DeviceData}
          AssetData={this.props.AssetData}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          editData={this.state.editData}
        />
        <DashboardLevelConfig />
      </EditDashboardDiv>
    );
  }

  handleChangeFile = (e) => {
    let context = this;
    var reader = new FileReader();
    reader.readAsText(e[0]);
    reader.onloadend = function () {
      let fileData = JSON.parse(reader.result);
      context.setState({ panels: fileData.json });
    };
  };

  setTitle = (e) => {
    this.setState({ title: e.target.value, id: this.state.id });
  };

  redirect() {
    let { dashboardName } = this.state
    this.props.history.push(
      dashboardName.length === 0
        ? "/dashboard/"
        : "/dashboard/" + dashboardName
    );
  }

  addPanel = () => {
    // let { dashboardName } = this.state
    this.counter = this.state.panels.length + 1;
    this.state.panels.push({
      id: this.counter,
      title: "new panel",
      graph: "guage",
      source: "mqtt",
      query: "",
      query1: "",
      query2:"",
      position: { x: 0, y: 0, w: 4, h: 6 },
    });

    this.setState({ panels: [...this.state.panels] });
  };

  createPanels = () => {
    return (
      <ResponsiveReactGridLayout
        className="layout"
        draggableHandle=".headerdrag"
        //key={new Date().getTime()}
        onResizeStop={(data) => this.updatePosition(data)}
        onDragStop={(data) => {
          this.updatePosition(data);
        }}
        rowHeight={30}
      >
        {this.getPanels()}
      </ResponsiveReactGridLayout>
    );
  };

  updatePosition = (data) => {
    this.state.panels.map((v, i) => {
      data[i]["id"] = v.id;
    });
    data.map((value1, index1) => {
      this.state.panels.map((value2, index2) => {
        if (value1.id === value2.id) {
          value2.position.x = value1.x;
          value2.position.y = value1.y;
          value2.position.w = value1.w;
          value2.position.h = value1.h;
        }
      });
    });

    this.setState({ panels: [...this.state.panels] });
  };

  getPanels = () => {
    let { panels } = this.state
    let data = panels && Array.isArray(panels) && panels.map((value, index) => {
      const { x, y, w, h } = value.position;
      return (
        <PanelDiv
          key={index}
          data-grid={{
            x,
            y,
            w,
            h,
            isResizable: true,
          }}
        >
          <div className="headerdrag">
            <Title>{value.title}</Title>
          </div>

          <AddConfigurationDiv>
            <Button
              type="primary"
              shape="round"
              icon="plus-circle"
              onClick={() => this.addConfiguration(value)}
            >
              {value.title === "new panel"
                ? "Add Configuration"
                : "Edit Configuration"}
            </Button>
          </AddConfigurationDiv>
          <DeletePanelDiv>
            <Button
              type="danger"
              shape="round"
              icon="delete"
              onClick={() => this.deletePanel(value.id)}
            >
              Delete Panel
            </Button>
          </DeletePanelDiv>
        </PanelDiv>
      );
    });

    return data;
  };

  deletePanel = (id) => {
    this.state.panels.map((value, index) => {
      if (id === value.id) {
        this.state.panels.splice(index, 1);
      }
    });
    let panels = this.state.panels.map((value, index) => {
      value.id = index + 1;
      return value;
    });

    this.setState({
      panels: [...panels],
      id: "",
    });
  };

  addConfiguration(value) {
    this.state.id = value.id;
    let editdata = this.state.panels.filter((value, index) => {
      if (value.id === this.state.id) {
        return value;
      }
    });

    this.showModal(editdata);
  }
  showModal = (value) => {
    this.state.editData = [];
    this.setState({
      visible: true,
      editData: [...value],
    });
  };

  handleOk = (config) => {
    let { title, graph, type, refreshTime, checked, query, query1, query2,enableMock, queryRowsToColumns, enableExcel } = config
    try {
      this.state.panels.map((value) => {
        if (value.id === this.state.id) {
          value.title = title;
          value.graph = graph;
          value.type = type;
          value.refreshTime = refreshTime;
          value.checked = checked;
          value.query = query;
          value.query1 = query1;
          value.query2 = query2;
          value.enableMock = enableMock;
          value.queryRowsToColumns = queryRowsToColumns;
          value.enableExcel = enableExcel;
          value[graph] = config[graph] || {}
        }
      });

      this.setState({
        visible: false,
        panels: [...this.state.panels],
        id: "",
      });
    } catch (e) {
      message.error("Configuration Error!");
    }
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  saveDashboard = async () => {
    let { dashboardName } = this.state
    if (dashboardName.trim().length !== 0) {
      let panels = this.state.panels;
      panels.map((value) => {
        value.dashboardName = dashboardName;
        // value.theme = this.state.theme;
        value.dateExists = this.state.dateExists;
        value.dateRangeExists = this.state.dateRangeExists;
        value.monthExists = this.state.monthExists;
        value.shiftExists = this.state.shiftExists;
        value.weekExists = this.state.weekExists;
        value.dayMonthYearExists = this.state.dayMonthYearExists;
        value.dashboardType = this.state.dashboardType;
        value.apiPayload = this.state.apiPayload
      });
      if (this.state.mode === "insert") {
        let msg = await insertDashboard(panels, dashboardName, this.state.dashboardId);
        message.success(msg);
        history.push("/rubus/dashboard")
        this.setState({
          panels: [],
          visible: false,
          editData: [],
          dashboardName: "",
          mode: this.state.mode,
          id: "",
          apiPayload: {}
        });
      } else {
        let msg = await updateDashboard(panels, dashboardName, this.state.dashboardId);
        message.success(msg);
        history.push("/rubus/dashboard")
        this.setState({
          panels: [],
          visible: false,
          editData: [],
          dashboardName: "",
          mode: this.state.mode,
          id: "",
          apiPayload: {}
        });
      }
    } else {
      alert("Enter Dashboard Name");
    }
  };
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
});

export default connect(mapStateToProps)(Form.create()(NewDashboard));


