import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import LoginComponent from './LoginComponent'
import { userLoginAuth } from '../../../modules/login/login.duck'
import { getUserToken } from '../../../selectors/login'

const mapStateToProps = createStructuredSelector({
  userToken: getUserToken()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(
      {
        userLoginAuth
      },
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent)
