import React from 'react';
import { Drawer, Table, Icon } from 'antd';

export default class BookMarkList extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            data: []
        };
    }

    onClose = () => {
        this.setState({
            visible: true,
        });
    };

    render() {
        const columns = [
            {
                title: 'Bookmark Name',
                dataIndex: 'Name',
                key: 'Name',
                render: (text, row) => (
                    <div
                        // style={{ cursor: "pointer", color: "#1d8bf1" }}
                        // onClick={() => {
                        //     this.props.viewBookmark(row.Id);
                        // }}
                    >
                        {text}
                    </div>
                ),
            },
            {
                title: 'Edit',
                dataIndex: 'edit',
                render: (text, row) => (
                    <Icon type="edit" style={{ cursor: "pointer" }} onClick={() => {
                        this.props.editBookmark(row);
                    }} />)
            },
            {
                title: 'Delete',
                dataIndex: 'delete',
                render: (text, row) => (
                    <Icon type="delete" style={{ cursor: "pointer", color: "red" }} onClick={() => {
                        this.props.deleteBookMark(row.Name, row.Id);
                    }} />

                ),
            },
        ];

        return (
            <Drawer
                title="Trends Bookmarks List"
                width={500}
                placement="right"
                closable={true}
                onClose={this.props.onCloseList}
                visible={this.props.listView}
            >
                <Table columns={columns} dataSource={this.props.list} />
            </Drawer>
        );
    }
}
