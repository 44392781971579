import React from 'react'
import cloneDeep from 'lodash.clonedeep'
import { Table, Button, InputNumber, message, Popconfirm, Divider, Select } from 'antd'

import '../device.css'
import styles from '.././style.less'
const { Option } = Select

class DeviceCondition extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      conditionReference: props.conditionReference || [],
      conditionList: cloneDeep(props.conditionList) || [],
      data: props.conditionReference.ParameterConditions || [],
      loading: false
    }
    this.index = 0
    this._afterAddedRemoveFromList()
  }

  componentWillReceiveProps ({ conditionList, conditionReference }) {
    this.setState({ conditionList: cloneDeep(conditionList), conditionReference })
    this._afterAddedRemoveFromList()
  }

  getRowByKey (key, newData) {
    const { data } = this.state
    return (newData || data).filter(item => item.id === key)[0]
  }

  componentWillUnmount () {
    this.setState({ conditionReference: [] })
  }

  saveToggleEditable = (e, key) => {
    e.preventDefault()
    const { data } = this.state
    const newData = data.map(item => ({ ...item }))
    const target = this.getRowByKey(key, newData)
    if (target) {
      if (!target.editable && this.cacheOriginData) {
        this.cacheOriginData[key] = { ...target }
      }
      target.editable = !target.editable
      this.setState({ data: newData })

      this.props.saveConditonList(newData, this.props.conditionReference.ParameterId) //Save Condition
      this._afterAddedRemoveFromList()
    }
  };

  _afterAddedRemoveFromList () {
    const { conditionReference } = this.state

    const conditionListCopy = cloneDeep(this.props.conditionList)
    const duplicateList = []
    const elements = []
    conditionReference && conditionReference.ParameterConditions && conditionReference.ParameterConditions.forEach((usedDP) => {
      conditionListCopy.forEach((conditions, index) => {
        if (conditions.Id === usedDP.id) {
          duplicateList.push(index)
        }
      })
    })

    conditionListCopy && conditionListCopy.forEach((ele, eleid) => {
      if (duplicateList.indexOf(eleid) === -1) {
        elements.push(ele)
      }
    })

    this.setState({ conditionList: elements })
  }

  toggleEditable= (key) => {
    const { data } = this.state
    const newData = data.map(item => ({ ...item }))
    const target = this.getRowByKey(key, newData)
    if (target) {
      target.editable = !target.editable
      this.setState({ data: newData })
    }
  };

  newMember = () => {
    const { data } = this.state
    this._afterAddedRemoveFromList()
    const newData = data.map(item => ({ ...item }))
    newData.push({
      id: `${this.index}`,
      Id: '',
      Value: '',
      editable: true,
      isNew: true
    })
    this.index += 1
    this.setState({ data: newData })
  };

  remove (key) {
    const { data } = this.state
    if (key.isNew) {
      const newData = data.filter(item => item.id !== key.id)
      this.setState({ data: newData })
      this.props.saveConditonList(newData, this.props.conditionReference.ParameterId)
      this._afterAddedRemoveFromList()
    } else {
      message.error('Cant delete this record..')
    }
  }

  handleKeyPress (e, key) {
    if (e.key === 'Enter') {
      this.saveRow(e, key)
    }
  }

  handleFieldChange (index, fieldName, key) {
    let e = ''
      e = index

    const { data } = this.state
    const newData = data.map(item => ({ ...item }))
    const target = this.getRowByKey(key, newData)
    if (target) {
        target[fieldName] = e
      this.setState({ data: newData })
    }
  }

  saveRow (e, key) {
    e.persist()
    this.setState({
      loading: true
    })
    setTimeout(() => {
      if (this.clickedCancel) {
        this.clickedCancel = false
        return
      }
      const target = this.getRowByKey(key) || {}
      if (!target.Id || !(!target.Value || !target.Value !== 0)) {
        message.error('Please fill in the complete member information')
        e.target.focus()
        this.setState({
          loading: false
        })
        return
      }
      this.saveToggleEditable(e, key)
      this.setState({
        loading: false
      })
    }, 500)
  }

  editRow (key) {
    this._afterAddedRemoveFromList()
    this.toggleEditable(key)
  }

  cancel (e, key) {
    this.clickedCancel = true
    e.preventDefault()
    const { data } = this.state
    const newData = data.map(item => ({ ...item }))
    const target = this.getRowByKey(key, newData)
    if (this.cacheOriginData !== undefined) {
      if (this.cacheOriginData[key]) {
        Object.assign(target, this.cacheOriginData[key])
        delete this.cacheOriginData[key]
      }
    }
    target.editable = false
    this.setState({ data: newData })
    this.clickedCancel = false
  }

  render () {
    const { loading, data } = this.state
    let {conditionList} = this.props

    const columns = [
      {
        title: 'Id',
        dataIndex: 'Id',
        key: 'Id',
        width: '30%',
        render: (text, record) => {
          if (record.editable) {
            return (
              <Select
                defaultValue={text}
                autoFocus
                style={{ width: 250 }}
                onChange={e => this.handleFieldChange(e, 'Id', record.id)}
                onKeyPress={e => this.handleKeyPress(e, record.id)}
                placeholder="Id"
              >
                {
                  conditionList.map((condition, index) => {
                    return (<Option value={condition.Id}>{condition.Code}</Option>)
                  })
                }
              </Select>
            )
          }
          let filterObject =  conditionList && conditionList.filter((condition)=>{
            return condition.Id === text
          })
          return filterObject && filterObject[0] ? filterObject[0].Name : ""
        }
      },
      {
        title: 'Value',
        dataIndex: 'Value',
        key: 'Value',
        width: '25%',
        render: (text, record) => {
          if (record.editable) {
            return (
              <InputNumber
                value={text}
                onChange={e => this.handleFieldChange(e, 'Value', record.id)}
                onKeyPress={e => this.handleKeyPress(e, record.id)}
                placeholder="value"
              />
            )
          }
          return text
        }
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => {
          const { loading } = this.state
          if (!!record.editable && loading) {
            return null
          }
          if (record.editable) {
            if (record.isNew) {
              return (
                <span>
                  <button type="button" className="link-button" onClick={e => this.saveRow(e, record.id)}>Save</button>
                  <Divider type="vertical" />
                  <Popconfirm title="Do you want to delete ?" onConfirm={() => this.remove(record)}>
                    <button type="button" className="link-button">Delete</button>
                  </Popconfirm>
                </span>
              )
            }
            return (
              <span>
                <button type="button" className="link-button" onClick={e => this.saveRow(e, record.id)}>Save </button>
                <Divider type="vertical" />
                <button type="button" className="link-button" onClick={e => this.cancel(e, record.id)}>Cancel</button>
              </span>
            )
          } else {
            return (
              <span>
                <button type="button" className="link-button" onClick={(e) => this.editRow(record.id, e)}>Edit </button>
                <Divider type="vertical" />
                <Popconfirm title="Do you want to delete ?" onConfirm={() => this.remove(record)}>
                  <button type="button" className="link-button">Delete</button>
                </Popconfirm>
              </span>
            )
          }
        }
      }]

    return (
      <div style={{ marginTop: '15px', backgroundColor: '#eaeaea' }}>
        <Button
          style={{ marginTop: 10, marginBottom: 3, marginLeft: 15 }}
          type="primary"
          size="small"
          onClick={this.newMember}
          icon="plus"
        >
         Add Condition
        </Button>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={false}
          size="small"
          rowClassName={record => (record.editable ? styles.editable : '')}
          bordered
        />

      </div>
    )
  }
}

export default (DeviceCondition)
