
import React from 'react'

import ChangeFirstPasswordComponent from './ChangeFirstPasswordComponent'
import { LoginPageBackground, LoginBox, LoginSection, Login } from './styles'

const ChangeFirstPassword = () => {
  document.title = 'Change Password - Industrial IOT Platform'
  return (
    <Login>
      <LoginSection>
        <LoginPageBackground style={{ backgroundImage: `url('../covers/rubus_login.jpg')` }} />
        <LoginBox>
          <ChangeFirstPasswordComponent />
        </LoginBox>
      </LoginSection>
    </Login>
  )
}

export {
  ChangeFirstPassword
}
