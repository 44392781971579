import React from 'react'
import axios from 'axios'
import { Form, Input, message, Button } from 'antd'
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getCurrentLanguage, getTranslationCache } from "../.././../selectors/language";
import { constant } from "../Constants";
import localStorage from '../../../utils/localStorage'
import styles from '../../../commons/styles'

const { StyledWrapper, StyledWrapperLeft, StyledWrapperRight, StyledHeading } = styles.layout

const FormItem = Form.Item

class ChangePassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      translationCache: props.translationCache || [],
      language: props.language || "English",
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache || prevProps.language !== this.props.language) {
      this.setState({
        translationCache: this.props.translationCache, language: this.props.language
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this._changePasswordAPI(values)
      }
    })
  }

  _changePasswordAPI = (value) => {
    const accessToken = localStorage.get('accessToken')
    const changePassword = {
      method: 'POST',
      url: '/api/auth/changePassword',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: value
    }
    axios(changePassword)
      .then((response) => {
        message.success(response.data.message)
        localStorage.set('accessToken', 'null')
        this.props.history.push('/login')
      })
      .catch((error) => {
        if (error.response.status === 400) {
          message.error(error.response.data.message)
        }

        // return [];
      })
  }

  translation = (keyword) => {
    let { translationCache } = this.props
    return translationCache &&
      translationCache[keyword] ?
      translationCache[keyword] : keyword
  }


  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div>
        <StyledWrapper>
          <StyledWrapperLeft>
            <StyledHeading>
              {this.translation(constant.Change_Password)}
            </StyledHeading>
            <p>
              {this.translation(constant.Password_Description)}
            </p>
          </StyledWrapperLeft>
          <StyledWrapperRight style={{ width: '70%' }}>
            <Form onSubmit={this.handleSubmit}>
              <FormItem label={this.translation(constant.Old_Password)}>
                {getFieldDecorator('oldPassword', {
                  rules: [{ required: true, message: 'Current Password' }],
                  initialValue: ''
                })(
                  <Input.Password placeholder={this.translation(constant.Current_Password)}/>
                )}
              </FormItem>
              <FormItem label={this.translation(constant.New_Password)}>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'New Password' }]
                })(
                  <Input.Password placeholder={this.translation(constant.New_Password)}/>
                )}
              </FormItem>
              <FormItem label={this.translation(constant.Confirm_New_Password)}>
                {getFieldDecorator('confirmPassword', {
                  rules: [{ required: true, message: 'Password Confirmation' }]
                })(
                  <Input.Password placeholder={this.translation(constant.Password_confirmation)}/>
                )}
              </FormItem>
              <FormItem>
                <Button type="primary" htmlType="submit" >
                  {this.translation(constant.Save_Password)}
                </Button>
              </FormItem>
            </Form>
          </StyledWrapperRight>
        </StyledWrapper>
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),

});


export default connect(mapStateToProps)(Form.create()(ChangePassword));

