import React, { Component } from "react";
import { Table, Form, Button, Drawer, Row, Col, message } from "antd";
import "antd/dist/antd.css";
import axios from 'axios';
import { Select } from "antd4";
import localStorage from "../../../utils/localStorage";
// import {
//     StyledApplication,
//     StyledTable,
//     lightTheme,
//     DrawerFooter
// } from "./../AdapterConfiguration/OPCDAAdapter/style"
import {
    StyledApplication, StyledTable, lightTheme,
    DrawerFooter
} from "../UserManagement/style";
// import "antd4/dist/antd.css";


const { Option } = Select;
const FormItem = Form.Item;
class MasterMapping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            mappingList: [],
            mappingObject: {},
            tableNameList: [],
            masterTableNames: [],
            columnsList: [],
            masterList: []
        };
    }
    componentDidMount = () => {
        this._getmappingList();
        this._getMasterList();
        this._getTableMasterList();
       
    }
    onClose = () => {
        this.props.form.resetFields();
        this.setState({
            visible: false,
            mappingObject: {}
        });
    };
    showDrawer = () => {
        this.props.form.resetFields();
        this.setState({
            visible: true,
            mappingObject: {}
        });
    };
    _getmappingList = () => {
        const accessToken = localStorage.get('accessToken')
        const axiosObject = {
            method: 'GET',
            url: `/api/mastermapping/list`,
            headers: {
                Authorization: `Bearer ${accessToken}` }
        }
        axios(axiosObject)
            .then((response) => {
                this.setState({
                    mappingList: response.data
                })
            }).catch((error) => {
                return { "message": "Error" }
            })
    }
    _getTableMasterList = () => {
        const accessToken = localStorage.get('accessToken')
        const Object = {
            method: 'GET',
            url: `/api/masterconfiguration/getTableList`,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }
        axios(Object)
            .then((response) => {
                this.setState({
                    masterTableNames: response.data
                })
            }).catch((err) => {
            })
    }
    _getMasterList = () => {
        const accessToken = localStorage.get('accessToken')
        const Object = {
            method: 'GET',
            url: `/api/masterconfiguration/list`,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }
        axios(Object)
            .then((response) => {
                this.setState({
                    masterList: response.data
                })
            }).catch((err) => {
            })
    }
    _getColumnNamesByTableName = () => {
        const Object = {
            method: 'POST',
            url: `/api/masterconfiguration/getColumnNamesByTableName `,
            // headers: {
            //     'Authorization': `Bearer ${accessToken}`
            // },
        }
        axios(Object)
            .then((response) => {
                this.setState({
                    masterTableNames: response.data
                })
            }).catch((error) => {
                return { "message": "Error" }
            })
    }

    savemastermapping = (type) => {
        const accessToken = localStorage.get('accessToken')
        if (type === "save") {
            this.props.form.validateFields((err, values) => {
                let payloadData = {
                    "ParentTable": values.ParentTable,
                    "ParentColumn": values.ParentColumn,
                    "ChildTable": values.ChildTable,
                    "ChildColumn": values.ChildColumn,
                    "DisplayColumn": values.DisplayColumn
                }
                let savemapping = {
                    method: 'POST',
                    url: `/api/mastermapping/insert`,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                    data: payloadData
                }
                axios(savemapping)
                    .then((response) => {
                        this._getmappingList()
                        message.success('Maping created')
                        this.onClose()
                    })
                    .catch(function (error) {
                        message.error('Error')
                    })
            })
        }
        else if (type === "update") {
            this.props.form.validateFields((err, values) => {
                let updatemapping = {
                    method: 'POST',
                    url: `/api/mastermapping/update`,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                    data: {
                        ...values,
                        "Id": this.state.mappingObject.Id,
                    }
                }
                axios(updatemapping)
                    .then((response) => {
                        this._getmappingList()
                        message.success('Maping updated')
                        this.onClose()
                    })
                    .catch(function (error) {
                        message.error('Error')
                    })
            })
        }
        else if (type === "delete") {

            let deletemapping = {
                method: 'POST',
                url: `/api/mastermapping/delete`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
                data: {
                    "Id": this.state.mappingObject.Id,
                }
            }
            axios(deletemapping)
                .then((response) => {
                    this._getmappingList()
                    message.success('Maping deleted')
                    this.onClose()
                })
                .catch(function (error) {
                    message.error('Error')
                })

        }
    }

    getColumnData = async (value) => {
        let columnsList = await this.getAxiosColumnsFromTableName(value)
        this.setState({
            columnsList
        })
    }

    getAxiosColumnsFromTableName = (TableName) => {
        const Object = {
            method: 'POST',
            url: `/api/masterconfiguration/getColumnNamesByTableName`,
            data: {
                TableName
            }
        }
        return axios(Object)
            .then((response) => {
                if (response && response.data && Array.isArray(response.data)) {
                    return response.data
                } else {
                    return []
                }
            }).catch((error) => {
                return { "message": "Error" }
            })
    }

    render() {
        const { getFieldDecorator,getFieldValue} = this.props.form;
        const { masterList,mappingObject, mappingList, columnsList,masterTableNames } = this.state;
        const columns = [
            {
                title: "Parent Table",
                dataIndex: "ParentTable",
                key: "ParentTable",
            },

            {
                title: "Parent Column",
                dataIndex: "ParentColumn",
                key: "ParentColumn",
            },
            {
                title: "Child Table",
                dataIndex: "ChildTable",
                key: "ChildTable",
            },
            {
                title: "Child Column",
                dataIndex: "ChildColumn",
                key: "ChildColumn",
            },
            {
                title: "Child Display Column",
                dataIndex: "DisplayColumn",
                key: "DisplayColumn",
            }

        ];

        return (
            <StyledApplication style={{ minHeight: window.innerHeight - 226 }}>
                <div>
                    <Row>
                        <Col span={8}>
                            <Button
                                type="primary"
                                onClick={this.showDrawer}
                                style={{
                                    backgroundColor: "#000c17",
                                    border: "#000c17",
                                    marginBottom: "5%",
                                }}
                            >
                                Create
                            </Button>
                        </Col>
                        <StyledTable theme={lightTheme}>
                            <Table
                                size="medium"
                                columns={columns}
                                dataSource={
                                    mappingList && Array.isArray(mappingList)
                                        ? mappingList
                                        : []
                                }
                                pagination={
                                    mappingList && mappingList.length > 10
                                        ? {
                                            pageSize: "5",
                                        }
                                        : false
                                }
                                locale={{ emptyText: "No Data" }}
                                onRow={(record) => {
                                    return {
                                        onClick: () => {
                                            this.setState({
                                                visible: true,
                                                mappingObject: record,
                                            });
                                        },
                                    };
                                }}
                            />
                        </StyledTable>
                        <Drawer
                            title={!mappingObject.MasterMappingId ? "Create" : "Update"}
                            placement="right"
                            closable={true}
                            width={800}
                            onClose={this.onClose}
                            visible={this.state.visible}
                        >
                            <Form layout="vertical">
                                <Row>
                                    <Col span={24}>
                                        <FormItem label="Parent Table:">
                                            {getFieldDecorator("ParentTable", {
                                                initialValue: mappingObject.ParentTable,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Please select your Parent Table!",
                                                    },
                                                ],
                                            })(
                                                <Select
                                                    placeholder="Select Parent Table"
                                                    style={{ width: "300px" }}
                                                    onChange={e => this.getColumnData(e)}
                                                >
                                                     {
                                        masterTableNames && Object.keys(masterTableNames)
                                            .filter(fil => {
                                                return !(masterList && masterList.map((master) => {
                                                    return master.TableName
                                                }).includes(fil))
                                            }
                                            )
                                            .map((master) => {
                                                return <Option value={master} >{master}</Option>
                                            })
                                    }
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormItem label="Parent Column:">
                                            {getFieldDecorator("ParentColumn", {
                                                initialValue: mappingObject.ParentColumn,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Please select your Parent Column!",
                                                    },
                                                ],
                                            })(
                                                <Select
                                                    placeholder="Select Parent Column"
                                                    style={{ width: "300px" }}
                                                >
                                                    {columnsList && Array.isArray(columnsList) &&
                                                        columnsList.map((item) => {
                                                            return (
                                                                <Option value={item.ColumnName}>
                                                                    {item.ColumnName}
                                                                </Option>
                                                            );
                                                        })}
                                                         {
                                                            masterTableNames && masterTableNames[getFieldValue('ParentTable')] &&
                                                            masterTableNames[getFieldValue('ParentTable')]
                                                                // .filter(fil => {
                                                                //     return !(editObject && editObject.columns && editObject.columns.map((master) => {
                                                                //         return master.key
                                                                //     }).includes(fil))
                                                                // }
                                                                // )
                                                                .map(keyNames => {
                                                                    return <Option value={keyNames}>{keyNames}</Option>;
                                                                })
                                                         }
                                                         
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormItem label="Child Table:">
                                            {getFieldDecorator("ChildTable", {
                                                initialValue: mappingObject.ChildTable,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Please select your Child Table!",
                                                    },
                                                ],
                                            })(
                                                <Select
                                                    placeholder="Select Child Table"
                                                    style={{ width: "300px" }}
                                                    onChange={e => this.getColumnData(e)}
                                                >
                                                    {
                                        masterTableNames && Object.keys(masterTableNames)
                                            .filter(fil => {
                                                return !(masterList && masterList.map((master) => {
                                                    return master.TableName
                                                }).includes(fil))
                                            }
                                            )
                                            .map((master) => {
                                                return <Option value={master} >{master}</Option>
                                            })
                                    }
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormItem label="Child Column:">
                                            {getFieldDecorator("ChildColumn", {
                                                initialValue: mappingObject.ChildColumn,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Please select your Child Column!",
                                                    },
                                                ],
                                            })(
                                                <Select
                                                    placeholder="Select Child Column"
                                                    style={{ width: "300px" }}
                                                >
                                                     {
                                                            masterTableNames && masterTableNames[getFieldValue('ChildTable')] &&
                                                            masterTableNames[getFieldValue('ChildTable')]
                                                                // .filter(fil => {
                                                                //     return !(editObject && editObject.columns && editObject.columns.map((master) => {
                                                                //         return master.key
                                                                //     }).includes(fil))
                                                                // }
                                                                // )
                                                                .map(keyNames => {
                                                                    return <Option value={keyNames}>{keyNames}</Option>;
                                                                })
                                                         }
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormItem label="Child Display Column:">
                                            {getFieldDecorator("DisplayColumn", {
                                                initialValue: mappingObject.DisplayColumn,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Please select your Child Display Column !",
                                                    },
                                                ],
                                            })(
                                                <Select
                                                    placeholder="Select Child Display Column"
                                                    style={{ width: "300px" }}
                                                >
                                                   {
                                                            masterTableNames && masterTableNames[getFieldValue('ChildTable')] &&
                                                            masterTableNames[getFieldValue('ChildTable')]
                                                                // .filter(fil => {
                                                                //     return !(editObject && editObject.columns && editObject.columns.map((master) => {
                                                                //         return master.key
                                                                //     }).includes(fil))
                                                                // }
                                                                // )
                                                                .map(keyNames => {
                                                                    return <Option value={keyNames}>{keyNames}</Option>;
                                                                })
                                                         }
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>

                            </Form>
                            <DrawerFooter>
                                {
                                    <div>
                                        {!mappingObject.Id ? (
                                            <div>
                                                <Button
                                                    type="primary"
                                                    style={{
                                                        marginLeft: "5px",
                                                        marginBottom: "5px",
                                                        backgroundColor: "#000c17",
                                                        border: "#000c17",
                                                    }} onClick={() => this.savemastermapping('save')}
                                                >
                                                    Save
                                                </Button>
                                                <Button type="primary"
                                                    style={{
                                                        marginLeft: "5px",
                                                        marginBottom: "5px",
                                                        backgroundColor: "#000c17",
                                                        border: "#000c17",
                                                    }}
                                                    onClick={e => {
                                                        this.onClose(e)
                                                    }}
                                                >Cancel</Button>
                                            </div>
                                        ) : (
                                            <div>
                                                <Button
                                                    type="primary"
                                                    style={{
                                                        marginLeft: "5px",
                                                        marginBottom: "5px",
                                                        backgroundColor: "#000c17",
                                                        border: "#000c17",
                                                    }}
                                                    onClick={() => this.savemastermapping('update')}
                                                >
                                                    Update
                                                </Button>
                                                <Button
                                                    type="primary"
                                                    style={{
                                                        marginLeft: "5px",
                                                        marginBottom: "5px",
                                                        backgroundColor: "#000c17",
                                                        border: "#000c17",
                                                    }}
                                                    onClick={() => this.savemastermapping('delete')}
                                                >
                                                    Delete
                                                </Button>

                                            </div>
                                        )}
                                    </div>
                                }
                            </DrawerFooter>
                        </Drawer>
                    </Row>
                </div>
            </StyledApplication>
        );
    }
}

export default Form.create()(MasterMapping);
