import React from 'react'
import cloneDeep from 'lodash.clonedeep'
import { Table, Button, Input, message, Popconfirm, Divider, Select } from 'antd'

import './device.css'
import styles from './style.less'
const { Option } = Select

class DeviceTagReference extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      conditionReference: props.conditionReference || [],
      tagReferenceList: cloneDeep(props.tagReferenceList) || [],
      loading: false,
      data: props.conditionReference.ParameterReferences || []
    }
    this.index = 0
    this._afterAddedRemoveFromList()
  }

  componentWillReceiveProps ({ tagReferenceList, conditionReference }) {
    this.setState({ tagReferenceList: cloneDeep(tagReferenceList), conditionReference })
    this._afterAddedRemoveFromList()
  }

  getRowByKey (key, newData) {
    const { data } = this.state
    return (newData || data).filter(item => item.id === key)[0]
  }

  saveToggleEditable = (e, key) => {
    e.preventDefault()
    const { data } = this.state
    const newData = data.map(item => ({ ...item }))
    const target = this.getRowByKey(key, newData)
    if (target) {
      if (!target.editable) {
        this.cacheOriginData[key] = { ...target }
      }
      target.editable = !target.editable
      this.setState({ data: newData })
      this.props.saveTagReferenceList(newData, this.props.conditionReference.ParameterId) //Save Tag Reference
      this._afterAddedRemoveFromList()
    }
  };

  toggleEditable = (key) => {
    const { data } = this.state
    const newData = data.map(item => ({ ...item }))
    const target = this.getRowByKey(key, newData)
    if (target) {
      target.editable = !target.editable
      this.setState({ data: newData })
    }
  };

  newMember = () => {
    const { data } = this.state
    const newData = data.map(item => ({ ...item }))
    newData.push({
      id: `${this.index}`,
      Id: '',
      Reference: '',
      editable: true,
      isNew: true
    })
    this.index += 1
    this._afterAddedRemoveFromList()
    this.setState({ data: newData })
  };

  remove (key) {
    const { data } = this.state
    this._afterAddedRemoveFromList()
    if (key.isNew) {
      const newData = data.filter(item => item.id !== key.id)
      this.setState({ data: newData })
      this.props.saveTagReferenceList(newData, this.props.conditionReference.parameterId)
      this._afterAddedRemoveFromList()
      // onChange(newData);
    } else {
      message.error('Cant delete this record..')
    }
  }

  handleKeyPress (e, key) {
    if (e.key === 'Enter') {
      this.saveRow(e, key)
    }
  }

  handleFieldChange (index, fieldName, key) {
    const { tagReferenceList } = this.state
    let e = ''
    if (fieldName !== 'Reference') {
      e = tagReferenceList[index].Id
    } else {
      e = index
    }
    const { data } = this.state
    const newData = data.map(item => ({ ...item }))
    const target = this.getRowByKey(key, newData)
    if (target) {
      if (fieldName !== 'Reference') {
        // target['id'] = e
        target[fieldName] = tagReferenceList[index].Id
      } else {
        target[fieldName] = e
      }
      this.setState({ data: newData })
    }
  }

  saveRow (e, key) {
    e.persist()
    this.setState({
      loading: true
    })
    setTimeout(() => {
      if (this.clickedCancel) {
        this.clickedCancel = false
        return
      }
      const target = this.getRowByKey(key) || {}
      if (!target.Id || !(!target.Reference || !target.Reference !== 0)) {
        message.error('Please fill in the complete member information')
        e.target.focus()
        this.setState({
          loading: false
        })
        return
      }
      // delete target.isNew;
      this.saveToggleEditable(e, key)
      // this.onChange(data);
      this.setState({
        loading: false
      })
    }, 500)
  }

  _afterAddedRemoveFromList () {
    // let { tagReferenceList } = this.state
    // this.props.conditionReference.ParameterReferences.forEach((usedDP) => {
    //   tagReferenceList = _.remove(tagReferenceList, function (e) {
    //     return e.Id !== usedDP.id
    //   })
    //   this.setState({ tagReferenceList })
    // })

    const { conditionReference } = this.state

    const tagReferenceListCopy = cloneDeep(this.props.tagReferenceList)
    const duplicateList = []
    const elements = []
    conditionReference && conditionReference.ParameterReferences && conditionReference.ParameterReferences.forEach((usedDP) => {
      tagReferenceListCopy.forEach((tagReference, index) => {
        if (tagReference.Id === usedDP.id) {
          duplicateList.push(index)
        }
      })
    })

    tagReferenceListCopy.forEach((ele, eleid) => {
      if (duplicateList.indexOf(eleid) === -1) {
        elements.push(ele)
      }
    })

    this.setState({ tagReferenceList: elements })
  }

  editRow (key) {
    this.toggleEditable(key)
  }

  cancel (e, key) {
    this.clickedCancel = true
    e.preventDefault()
    const { data } = this.state
    const newData = data.map(item => ({ ...item }))
    const target = this.getRowByKey(key, newData)
    if (this.cacheOriginData !== undefined) {
      if (this.cacheOriginData[key]) {
        Object.assign(target, this.cacheOriginData[key])
        delete this.cacheOriginData[key]
      }
    }
    target.editable = false
    this.setState({ data: newData })
    this.clickedCancel = false
  }

  render () {
    const { loading, data, tagReferenceList } = this.state

    const columns = [
      {
        title: 'Id',
        dataIndex: 'Id',
        key: 'Id',
        width: '30%',
        render: (text, record) => {
          if (record.editable) {
            return (
              <Select
                defaultValue={text}
                autoFocus
                style={{ width: 250 }}
                onChange={e => this.handleFieldChange(e, 'Id', record.id)}
                onKeyPress={e => this.handleKeyPress(e, record.id)}
                placeholder="Id"
              >
                {
                  tagReferenceList &&  Array.isArray(tagReferenceList) && tagReferenceList.map((tagReference, index) => {
                    return (<Option value={tagReference.Id}>{tagReference.Name}</Option>)
                  })
                }
              </Select>
            )
          }
         
           let filterObject =  tagReferenceList && tagReferenceList.filter((tagRef)=>{
            return tagRef.Id === text
          })
          return filterObject && filterObject[0] ? filterObject[0].Name : ""
        }
      },
      {
        title: 'Reference',
        dataIndex: 'Reference',
        key: 'Reference',
        width: '25%',
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={e => this.handleFieldChange(e.target.value, 'Reference', record.id)}
                onKeyPress={e => this.handleKeyPress(e, record.id)}
                placeholder="Reference"
              />
            )
          }
          return text
        }
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => {
          const { loading } = this.state
          if (!!record.editable && loading) {
            return null
          }
          if (record.editable) {
            if (record.isNew) {
              return (
                <span>
                  <button type="button" className="link-button" onClick={e => this.saveRow(e, record.id)}>Save</button>
                  <Divider type="vertical" />
                  <Popconfirm title="Do you want to delete ?" onConfirm={() => this.remove(record)}>
                    <button type="button" className="link-button">Delete</button>
                  </Popconfirm>
                </span>
              )
            }
            return (
              <span>
                <button type="button" className="link-button" onClick={e => this.saveRow(e, record.id)}>Save </button>
                <Divider type="vertical" />
                <button type="button" className="link-button" onClick={e => this.cancel(e, record.id)}>Cancel</button>
              </span>
            )
          } else {
            return (
              <span>
                <button type="button" className="link-button" onClick={(e) => this.editRow(record.id, e)}>Edit </button>
                <Divider type="vertical" />
                <Popconfirm title="Do you want to delete ?" onConfirm={() => this.remove(record)}>
                  <button type="button" className="link-button" >Delete</button>
                </Popconfirm>
              </span>
            )
          }
        }
      }]

    return (
      <div style={{ marginTop: '15px', backgroundColor: '#eaeaea' }}>
        <Button
          style={{ marginTop: 10, marginBottom: 3, marginLeft: 15 }}
          type="primary"
          size="small"
          onClick={this.newMember}
          icon="plus"
        >
          Add Parameter Alias
        </Button>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={false}
          size="small"
          rowClassName={record => (record.editable ? styles.editable : '')}
          bordered
        />

      </div>
    )
  }
}

export default (DeviceTagReference)
