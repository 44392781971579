import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import ThirdLevelComponent from './ThirdLevelComponent'

import { getmenuSelected } from '../../../../../selectors/menu'
import { setMenuSelection } from '../../../../../modules/menu/menu.duck'
import { getMenuHierarchy } from '../../../../../selectors/layout'

const mapStateToProps = createStructuredSelector({
  selectedMenu: getmenuSelected(),
  menuHierarchy: getMenuHierarchy()
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setMenuSelection
      },
      dispatch
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ThirdLevelComponent)
