import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Icon, Tag } from "antd";
import { getChartDataWithoutSelection, getChartDataWithSelection } from "../ChartCalls";
import moment from "moment";

import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { defaultRefreshTime } from '../constant.json'
import { statusCounterMock } from "../../Mock/MockJson";
import { Fragment } from "react";

class StatusCounter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      uom: this.props.graphprops.uom ? this.props.graphprops.uom : "",
      statusCounter: 0,
      status: "down",
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props);
      }

    }, refreshTime);
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear
    ) {
      this.getData();
    }
  }

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <div
              style={{
                textAlign: "center",
                // display: "flex",
                // alignItems: "center",
                // color: "white",
                // flexDirection: "column",
                // justifyContent: "center",
                // height: "90%"
              }}
            >
              <div
                style={{
                  color: this.props.graphprops.titleColor ? this.props.graphprops.titleColor : "steelblue",
                  fontSize: this.props.graphprops.fontSize
                    ? this.props.graphprops.fontSize + "em"
                    : "5em",
                }}
              >

                {this.props.graphprops.statusCounterType === "notification" ? <div> <Icon
                  type="bell"
                  theme="filled"
                  style={{
                    marginRight: "4px",
                    fontWeight: "800",
                    fontSize: "0.8em",
                    color: this.props.graphprops.titleColor ? this.props.graphprops.titleColor : "red"
                  }}
                />{this.state.counter}{" "}
                  {/* {this.state.uom} */}
                  <Icon
                    type="bell"
                    theme="filled"
                    style={{
                      marginRight: "4px",
                      fontWeight: "800",
                      fontSize: "0.8em",
                      color: this.props.graphprops.iconColor ? this.props.graphprops.iconColor : "#389e0d"
                    }}
                  />{this.state.statusCounter}{" "}
                  {this.state.uom}
                </div>
                  : <div>{this.state.counter}{" "}
                    {this.state.uom}
                  </div>}


              </div>
              {this.props.graphprops.graph === "statusCounter" && this.state.status === "High" ? (
                <div
                  style={{
                    color: this.props.graphprops.titleColor,
                    marginRight: "2%",
                    fontSize: "1.5em",
                    textAlign: "center",
                    fontWeight: "600"
                  }}
                >
                  <Icon
                    type="arrow-up"
                    style={{
                      marginRight: "4px",
                      fontWeight: "800",
                      fontSize: "0.8em",
                    }}
                  />
                  {this.state.statusCounter}{" "}
                  {this.state.uom}
                </div>
              ) : this.props.graphprops.graph === "statusCounter" && this.state.status === "Low" ? (
                <div
                  style={{
                    color: this.props.graphprops.titleColor,
                    marginRight: "2%",
                    fontSize: "1.5em",
                    textAlign: "center",
                    fontWeight: "600"
                  }}
                >
                  <Icon
                    type="arrow-down"
                    style={{
                      marginRight: "4px",
                      fontWeight: "800",
                      fontSize: "0.8em",

                    }}
                  />
                  {this.state.statusCounter}{" "}
                  {this.state.uom}
                </div>
              ) : this.props.graphprops.graph === "statusCounter" && this.state.status === "Equal" ? (
                <div
                  style={{
                    color: this.props.graphprops.titleColor,
                    marginRight: "2%",
                    fontSize: "1.5em",
                    textAlign: "center",
                    fontWeight: "600"
                  }}
                >

                  {this.state.statusCounter}{" "}
                  {this.state.uom}
                </div>
              ) : null}


            </div>}
      </Fragment>
    )
  }

  getData = async () => {
    this.requestCheck = false;
    let { graphprops } = this.props;
    let data = [];
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      data = statusCounterMock;
      this.requestCheck = true
    } else {

      if (
        (graphprops &&
          graphprops.dashboardType === "dynamic" &&
          this.props.deviceId) ||
        (graphprops &&
          graphprops.dateExists === "true" &&
          this.props.selectionDate) ||
        (graphprops &&
          graphprops.monthExists === "true" &&
          this.props.selectionMonth) ||
        (graphprops &&
          graphprops.weekExists === "true" &&
          this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
        (graphprops &&
          graphprops.dateRangeExists === "true" &&
          this.props.selectionDateRange) ||
        (graphprops &&
          graphprops.dayMonthYearExists === "true" &&
          this.props.dayMonthYear)
      ) {
        data = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController
        );
        this.requestCheck = true;
      } else {
        data = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true;
      }
    }
    // data = [
    //   {
    //     interval_alias: "2020-05-12T21:49:05.161Z",
    //     tagname: "TRR_TIC301_PV",
    //     tagvalue: 395.4,
    //     status: "Low",
    //     statusValue: "54.0",
    //     tag_description: "Main stream temperature control",
    //   },
    // ];
    if ((data && data.message) || (Array.isArray(data) && (data.length === 0 &&
      graphprops.enableMock !== true))) {

      if (data.message) {
        errorInAPI = true
      } else if (data.length === 0) {
        noDataInDB = true
      }

    } else {

      if (data && Array.isArray(data) && data.length > 0 &&
        this.props.graphprops.statusCounterType === "number") {
        this.setState({ counter: Number(data[0].tagvalue).toFixed(2) });
        this.setState({
          statusCounter: data[0].statusValue,
          status: data[0].status,
        });
      } else if (
        data &&
        Array.isArray(data) &&
        data.length > 0 &&
        this.props.graphprops.statusCounterType === "string"
      ) {
        this.setState({ counter: data[0].tagvalue });
        this.setState({
          statusCounter: data[0].statusValue,
          status: data[0].status,
        });
      } else if (data && Array.isArray(data) && data.length > 0
        && this.props.graphprops.statusCounterType === "both") {
        this.setState({
          uom: this.props.graphprops.uom,
          statusCounter: data[0].statusValue,
          status: data[0].status,
          counter: Number(data[0].tagvalue).toFixed(2)
        });
      } else if (data && Array.isArray(data) && data.length > 0 &&
        this.props.graphprops.statusCounterType === "notification") {
        this.setState({
          //  uom: this.props.graphprops.uom,
          statusCounter: data[0].statusValue,
          //  status: data[0].status,
          counter: Number(data[0].tagvalue).toFixed(2)
        });

      }

      if (data && Array.isArray(data) && data[0] && data[0].tagvalue === null) {
        this.setState({ errorInAPI, noDataInDB: !noDataInDB })
      } else { this.setState({ errorInAPI, noDataInDB }) }
      //  this.setState({
      //   errorInAPI,
      //   noDataInDB
      // })
    }
  };
}



const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
});

export default connect(mapStateToProps)(StatusCounter);
