import axios from 'axios'
import moment from 'moment'

import localStorage from '../../utils/localStorage'

export async function reportTableCall(payload, reportType) {
  const siteId = localStorage.get('currentSite')
  const token = localStorage.get('accessToken')
  var Authorization = "Bearer " + token;
  let data = await fetch(`/api/report/reportData`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify({
      "ReportType": reportType,
      "ReportName": payload,
      "SiteId": siteId
    })
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json
    })
    .catch(error => {
      // if(error.response.data.name === 'TokenExpiredError'){
      //   jwtToken(error.response.data.message)
      // }
    });

  return data === undefined ? [] : data;
}

export async function getReportNametoDownloadMultiVaraint(columns, datasource, multiVaraintColumns, downloadExcelPayload) {
  let finalObject = []
  let parentHeaderkeys = columns && Array.isArray(columns) && columns.map((parentColumns) => {
    return parentColumns.key
  })
  let parentHeaderTitle = columns && Array.isArray(columns) && columns.map((parentColumns) => {
    return parentColumns.title
  })
  finalObject.push({
    "SNo": 1,
    "values": parentHeaderTitle && Array.isArray(parentHeaderTitle) ? parentHeaderTitle : [],
    "type": "parentHeader",
    "colorCode": "#FFDAE3F3"
  })
  datasource && Array.isArray(datasource) && datasource.map((datasource) => {
    let finalObjectLength = finalObject.length + 1
    let parentDatasource = parentHeaderkeys && Array.isArray(parentHeaderkeys) && parentHeaderkeys.map((parentHeader) => {
      return datasource[parentHeader] || ""
    })
    finalObject.push({
      "SNo": finalObjectLength,
      "values": parentDatasource && Array.isArray(parentDatasource) ? parentDatasource : [],
      "type": "",
      "colorCode": "",
      "textColor": datasource && datasource['color'] ? datasource['color'] : ""
    })
    finalObject.push({
      "SNo": finalObject.length + 1,
      "values": [],
      "type": "",
      "colorCode": ""
    })
    finalObjectLength = finalObject.length + 1

    let childHeaderTitle = multiVaraintColumns && multiVaraintColumns[0] && multiVaraintColumns[0].columns
      && Array.isArray(multiVaraintColumns[0].columns) && multiVaraintColumns[0].columns.map((childColumns) => {
        return childColumns.title
      })
    if (childHeaderTitle && Array.isArray(childHeaderTitle)) {
      childHeaderTitle = [
        null,
        ...childHeaderTitle,
      ]
    } else {
      childHeaderTitle = [
        null,
        childHeaderTitle,
      ]
    }

    let childHeaderkeys = multiVaraintColumns && multiVaraintColumns[0] && multiVaraintColumns[0].columns
      && Array.isArray(multiVaraintColumns[0].columns) && multiVaraintColumns[0].columns.map((childColumns) => {
        return childColumns.key
      })
    if (childHeaderkeys && Array.isArray(childHeaderkeys)) {
      childHeaderkeys = [
        null,
        ...childHeaderkeys,
      ]
    }
    else {
      childHeaderTitle = [
        null,
        childHeaderTitle,
      ]
    }

    finalObject.push({
      "SNo": finalObjectLength,
      "values": childHeaderTitle && Array.isArray(childHeaderTitle) ? childHeaderTitle : [],
      "type": "childHeader",
      "colorCode": "#FFDAE3F3"
    })

    datasource.multiVariantChildren && Array.isArray(datasource.multiVariantChildren) &&
      datasource.multiVariantChildren.map((childHeader) => {
        finalObjectLength = finalObject.length + 1
        let childDatasource = childHeaderkeys && Array.isArray(childHeaderkeys) && childHeaderkeys.map((childHeaderkeys) => {
          return childHeader[childHeaderkeys] ? childHeader[childHeaderkeys] : ""
        })
        finalObject.push({
          "SNo": finalObjectLength,
          "values": childDatasource && Array.isArray(childDatasource) ? childDatasource : [],
          "type": "",
          "colorCode": ""
        })
      })
    finalObject.push({
      "SNo": finalObject.length + 1,
      "values": [],
      "type": "",
      "colorCode": ""
    })

  })

  let payload = {
    // ...headerPayload,
    ...downloadExcelPayload,
    "date": moment().format('YYYY-MM-DD'),
    "time": moment().format('HH:mm:SS'),
    "data": finalObject
  }


  let accessToken = localStorage.get('accessToken')
  let options = {}
  options = {
    method: 'POST',
    url: `/api/report/generateExcel`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: JSON.stringify(payload)
  }
  return axios(options)
    .then(({ data }) => {
      return data
    }).catch(error => {
      return ""

    })

}

export async function getDataSourceFromBackend(apiObject) {
  let accessToken = localStorage.get('accessToken')
  const SiteId = localStorage.get('currentSite')
  let options = {}
  if (apiObject && apiObject.method === 'GET' && apiObject.uri) {
    options = {
      method: apiObject.method,
      url: `${apiObject.uri}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }
  } else if (apiObject && apiObject.method === 'POST' && apiObject.payload && apiObject.uri) {
    options = {
      method: apiObject.method,
      url: `${apiObject.uri}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: JSON.stringify(
        {
          ...apiObject.payload,
          SiteId
        }
      )
    }
  }

  let data = axios(options)
    .then(({ data }) => {
      if ((data && Array.isArray(data)) && typeof (data) !== "string") {
        return data
      } else {
        return []
      }
    }).catch(error => {
      return []
    });

  return data
}

function getChildren(columnChildren, datasourceObject) {
  let newChildrenObject = {}
  if (columnChildren && columnChildren.children) {
    columnChildren.children.map((subChildren) => {
      newChildrenObject[subChildren.title] = getChildren(subChildren, datasourceObject)
    })
    return newChildrenObject
  } else {
    if (columnChildren && columnChildren.key && datasourceObject[columnChildren.key] === undefined) {
      // newChildrenObject[columnChildren.title] = ""
      return ""
    } else {
      // newChildrenObject[columnChildren.title] = datasourceObject[columnChildren.key]
      return datasourceObject[columnChildren.key]
    }

  }
  // return newChildrenObject

}

export async function getReportNametoDownload(columns, datasource, downloadExcelPayload, currentTab, excelDate
  // childColumns, childDatasource, weekSummaryDatasource, headerPayload
) {
  let payload = {}
  if (columns && Array.isArray(columns) && datasource && Array.isArray(datasource)) {
    let reportPayload = []

    if (datasource && datasource.length > 0) {
      datasource && Array.isArray(datasource) && datasource.map((datasourceObject) => {
        let newObj = {}
        columns && Array.isArray(columns) && columns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {}
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, datasourceObject)

            })
            newObj[column.title] = {
              ...newChildrenObject
            }
          } else {
            if (datasourceObject[column.key] === undefined) {
            } else if (column && column.isUTC && column.isUTC === true) {
              let durationType = datasourceObject && datasourceObject["durationType"] ? datasourceObject["durationType"] : ""
              if (column && column['utcFormats'] && column['utcFormats'][durationType] && durationType === 'daily') {

                if (column && column.conditionType && column.conditionType === 'combineColumns') {
                  let combineColumnList = column && column.combineColumnList ? column.combineColumnList : []
                  let first = combineColumnList && combineColumnList[0] && datasourceObject[combineColumnList[0]] ?
                    moment.utc(datasourceObject[combineColumnList[0]]).local().format("HH:mm:ss") : ""
                  let second = combineColumnList && combineColumnList[1] && datasourceObject[combineColumnList[1]] ?
                    moment.utc(datasourceObject[combineColumnList[1]]).local().format("HH:mm:ss") : ""
                  newObj[column.title] = first + " - " + second
                } else {
                  newObj[column.title] = moment.utc(datasourceObject[column.key]).local().format(column['utcFormats'][durationType])
                }
              } else {
                newObj[column.title] = moment.utc(datasourceObject[column.key]).local().format(column['utcFormats'][durationType])
              }
            } else {
              newObj[column.title] = datasourceObject[column.key]
            }
          }


        })

        reportPayload.push(newObj)
      })
    } else {
      let newObj = {}
      columns && Array.isArray(columns) && columns.map((column) => {
        if (column && column.children) {
          let newChildrenObject = {}
          column.children.map((columnChildren) => {
            newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")
          })
          newObj[column.title] = {
            ...newChildrenObject
          }
        } else {
          newObj[column.title] = ""
        }

      })
      reportPayload.push(newObj)
    }
    payload = {
      // ...headerPayload,
      ...downloadExcelPayload,
      "date": moment().format('YYYY-MM-DD'),
      "time": moment().format('HH:mm:SS'),
      "data": reportPayload,
      // "summary": reportChildPayload,
      // "weeklySummary": reportWeekPayload
      excelDate,
      currentTime: moment().format('YYYY-MM-DD HH:mm:SS')
    }

  }

  let accessToken = localStorage.get('accessToken')
  let options = {}
  options = {
    method: 'POST',
    url: `/api/report/generateExcel`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: JSON.stringify(payload)
  }
  return axios(options)
    .then(({ data }) => {
      return data
    }).catch(error => {
      return ""

    })

}

export async function getPdfNametoDownload(columns, datasource, downloadExcelPayload, childColumns,
  childDatasource, weekSummaryDatasource, headerPayload) {
  let payload = {}
  if (columns && Array.isArray(columns) && datasource && Array.isArray(datasource)) {
    let reportPayload = []

    if (datasource && datasource.length > 0) {
      datasource && Array.isArray(datasource) && datasource.map((datasourceObject) => {
        let newObj = {}
        columns && Array.isArray(columns) && columns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {}
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, datasourceObject)

            })
            newObj[column.title] = {
              ...newChildrenObject
            }
          } else {
            if (datasourceObject[column.key] === undefined) {
              // newObj[column.title] = ""
            } else {
              newObj[column.title] = datasourceObject[column.key]
            }
          }


        })

        reportPayload.push(newObj)
      })
    } else {
      let newObj = {}
      columns && Array.isArray(columns) && columns.map((column) => {
        if (column && column.children) {
          let newChildrenObject = {}
          column.children.map((columnChildren) => {
            newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")
          })
          newObj[column.title] = {
            ...newChildrenObject
          }
        } else {
          newObj[column.title] = ""
        }

      })
      reportPayload.push(newObj)
    }
    let reportChildPayload = []
    if (childColumns && (downloadExcelPayload.summaryType === 'true' || downloadExcelPayload.summaryType === true)) {

      if (childDatasource && childDatasource.length > 0) {
        childDatasource.map((childDatasourceObject) => {
          let newObj = {}
          childColumns && childColumns.map((column) => {


            if (column && column.children) {
              let newChildrenObject = {}
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, childDatasourceObject)

              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {

              if (childDatasourceObject[column.key] === undefined) {
                // newObj[column.title] = ""
              } else {
                newObj[column.title] = childDatasourceObject[column.key]
              }
            }


          })
          reportChildPayload.push(newObj)
        })
      } else {
        let newObj = {}
        childColumns && childColumns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {}
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")

            })
            newObj[column.title] = {
              ...newChildrenObject
            }
          } else {
            newObj[column.title] = ""
          }

        })
        reportChildPayload.push(newObj)
      }
    }
    let reportWeekPayload = []
    if (childColumns && (downloadExcelPayload.summaryType === 'true' || downloadExcelPayload.summaryType === true)
      && (downloadExcelPayload.weekSummaryType === 'true' || downloadExcelPayload.weekSummaryType === true)) {

      if (weekSummaryDatasource && weekSummaryDatasource.length > 0) {
        weekSummaryDatasource.map((childDatasourceObject) => {
          let newObj = {}
          childColumns && childColumns.map((column) => {

            if (column && column.children) {
              let newChildrenObject = {}
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, childDatasourceObject)

              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {
              if (childDatasourceObject[column.key] === undefined) {
                newObj[column.title] = ""
              } else {
                newObj[column.title] = childDatasourceObject[column.key]
              }
            }

          })
          reportWeekPayload.push(newObj)
        })
      } else {
        let newObj = {}
        childColumns && childColumns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {}
            let index = []
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")

            })
            newObj[column.title] = {
              ...newChildrenObject
            }
          } else {
            newObj[column.title] = ""
          }

        })
        reportWeekPayload.push(newObj)
      }
    }
    payload = {
      ...headerPayload,
      ...downloadExcelPayload,
      "date": moment().format('YYYY-MM-DD'),
      "time": moment().format('HH:mm:SS'),
      "data": reportPayload,
      "summary": reportChildPayload,
      "weeklySummary": reportWeekPayload
    }

  } else {
    let reportWeekPayload = []
    let reportChildPayload = []
    let reportPayload = {}
    let tableData = []
    // columns && Object.keys(columns).map((colKey)=>{
    // let adhocColumns =columns[colKey]
    datasource && Object.keys(datasource).map((dataKey) => {
      let adhocDatasource = datasource[dataKey].table
      let adhocColumns = columns[dataKey]
      let indexValue = ++dataKey
      reportPayload["table" + indexValue] = []
      if (adhocDatasource && adhocDatasource.length > 0) {
        adhocDatasource && Array.isArray(adhocDatasource) && adhocDatasource.map((datasourceObject) => {
          let newObj = {}
          adhocColumns && Array.isArray(adhocColumns) && adhocColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {}
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, datasourceObject)
              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {
              if (datasourceObject[column.key] === undefined) {
                // newObj[column.title] = ""
              } else {
                newObj[column.title] = datasourceObject[column.key]
              }
            }


          })

          reportPayload["table" + indexValue].push(newObj)
        })
      } else {
        let newObj = {}
        columns && Array.isArray(columns) && columns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {}
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")
            })
            newObj[column.title] = {
              ...newChildrenObject
            }
          } else {
            newObj[column.title] = ""
          }

        })
        tableData.push(newObj)
      }
      let reportChildPayload = []
      if (childColumns && (downloadExcelPayload.summaryType === 'true' || downloadExcelPayload.summaryType === true)) {

        if (childDatasource && childDatasource.length > 0) {
          childDatasource.map((childDatasourceObject) => {
            let newObj = {}
            childColumns && childColumns.map((column) => {


              if (column && column.children) {
                let newChildrenObject = {}
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(columnChildren, childDatasourceObject)

                })
                newObj[column.title] = {
                  ...newChildrenObject
                }
              } else {

                if (childDatasourceObject[column.key] === undefined) {
                  // newObj[column.title] = ""
                } else {
                  newObj[column.title] = childDatasourceObject[column.key]
                }
              }


            })
            reportChildPayload.push(newObj)
          })
        } else {
          let newObj = {}
          childColumns && childColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {}
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")

              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {
              newObj[column.title] = ""
            }

          })
          reportChildPayload.push(newObj)
        }
      }
      let reportWeekPayload = []
      if (childColumns && (downloadExcelPayload.summaryType === 'true' || downloadExcelPayload.summaryType === true)
        && (downloadExcelPayload.weekSummaryType === 'true' || downloadExcelPayload.weekSummaryType === true)) {

        if (weekSummaryDatasource && weekSummaryDatasource.length > 0) {
          weekSummaryDatasource.map((childDatasourceObject) => {
            let newObj = {}
            childColumns && childColumns.map((column) => {

              if (column && column.children) {
                let newChildrenObject = {}
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(columnChildren, childDatasourceObject)

                })
                newObj[column.title] = {
                  ...newChildrenObject
                }
              } else {
                if (childDatasourceObject[column.key] === undefined) {
                  newObj[column.title] = ""
                } else {
                  newObj[column.title] = childDatasourceObject[column.key]
                }
              }

            })
            reportWeekPayload.push(newObj)
          })
        } else {
          let newObj = {}
          childColumns && childColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {}
              let index = []
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")

              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {
              newObj[column.title] = ""
            }

          })
          reportWeekPayload.push(newObj)
        }
      }

    })

    //  })
    payload = {
      ...headerPayload,
      ...downloadExcelPayload,
      "date": moment().format('YYYY-MM-DD'),
      "time": moment().format('HH:mm:SS'),
      "data": reportPayload,
      "summary": reportChildPayload,
      "weeklySummary": reportWeekPayload
    }
  }


  let accessToken = localStorage.get('accessToken')
  let options = {}
  options = {
    method: 'POST',
    url: `/api/report/generatePdf`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: JSON.stringify(payload)
  }
  return axios(options)
    .then(({ data }) => {
      return data
    }).catch(error => {
      return ""

    })

}

export async function getISOReportNametoDownload(columns, datasource, downloadExcelPayload,
  childColumns, childDatasource, weekSummaryColumns, weekSummaryDatasource,
  monthlySummaryColumns, monthSummaryDatasource, headerPayload) {
  let payload = {}

  if (columns && Array.isArray(columns) && datasource && Array.isArray(datasource)) {
    let reportPayload = []

    if (datasource && datasource.length > 0) {
      datasource && Array.isArray(datasource) && datasource.map((datasourceObject) => {
        let newObj = {}
        columns && Array.isArray(columns) && columns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {}
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, datasourceObject)

            })
            newObj[column.title] = {
              ...newChildrenObject
            }
          } else {
            if (datasourceObject[column.key] === undefined) {
              // newObj[column.title] = ""
            } else {
              newObj[column.title] = datasourceObject[column.key]
            }
          }


        })

        reportPayload.push(newObj)
      })
    } else {
      let newObj = {}
      columns && Array.isArray(columns) && columns.map((column) => {
        if (column && column.children) {
          let newChildrenObject = {}
          column.children.map((columnChildren) => {
            newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")
          })
          newObj[column.title] = {
            ...newChildrenObject
          }
        } else {
          newObj[column.title] = ""
        }

      })
      reportPayload.push(newObj)
    }

    let reportChildPayload = []
    if (childColumns && (downloadExcelPayload.childTable === 'true' || downloadExcelPayload.childTable === true)) {

      if (childDatasource && Array.isArray(childDatasource) && childDatasource.length > 0) {
        Array.isArray(childDatasource) && childDatasource.map((childDatasourceObject) => {
          let newObj = {}
          childColumns && childColumns.map((column) => {


            if (column && column.children) {
              let newChildrenObject = {}
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, childDatasourceObject)

              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {

              if (childDatasourceObject[column.key] === undefined) {
                // newObj[column.title] = ""
              } else {
                newObj[column.title] = childDatasourceObject[column.key]
              }
            }


          })
          reportChildPayload.push(newObj)
        })
      } else {
        let newObj = {}
        childColumns && childColumns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {}
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")

            })
            newObj[column.title] = {
              ...newChildrenObject
            }
          } else {
            newObj[column.title] = ""
          }

        })
        reportChildPayload.push(newObj)
      }
    }
    let reportWeekPayload = []
    let reportMonthPayload = []
    if (childColumns && (downloadExcelPayload.childTable === 'true' || downloadExcelPayload.childTable === true)
    ) {

      if (weekSummaryDatasource && Array.isArray(weekSummaryDatasource) && weekSummaryDatasource.length > 0) {
        weekSummaryDatasource.map((childDatasourceObject) => {
          let newObj = {}

          weekSummaryColumns && weekSummaryColumns.map((column) => {

            if (column && column.children) {
              let newChildrenObject = {}
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, childDatasourceObject)

              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {
              if (childDatasourceObject[column.key] === undefined) {
                newObj[column.title] = ""
              } else {
                newObj[column.title] = childDatasourceObject[column.key]
              }
            }

          })


          reportWeekPayload.push(newObj)
        })
      } else {
        let newObj = {}
        weekSummaryColumns && weekSummaryColumns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {}
            let index = []
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")

            })
            newObj[column.title] = {
              ...newChildrenObject
            }
          } else {
            newObj[column.title] = ""
          }

        })
        reportWeekPayload.push(newObj)
      }

      if (monthSummaryDatasource && monthSummaryDatasource.length > 0) {
        monthSummaryDatasource && Array.isArray(monthSummaryDatasource) && monthSummaryDatasource.map((childDatasourceObject) => {
          let newObj = {}

          monthlySummaryColumns && monthlySummaryColumns.map((column) => {

            if (column && column.children) {
              let newChildrenObject = {}
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, childDatasourceObject)

              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {
              if (childDatasourceObject[column.key] === undefined) {
                newObj[column.title] = ""
              } else {
                newObj[column.title] = childDatasourceObject[column.key]
              }
            }

          })


          reportMonthPayload.push(newObj)
        })
      } else {
        let newObj = {}
        monthlySummaryColumns && monthlySummaryColumns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {}
            let index = []
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")

            })
            newObj[column.title] = {
              ...newChildrenObject
            }
          } else {
            newObj[column.title] = ""
          }

        })
        reportMonthPayload.push(newObj)
      }
    }
    let childTables = {

    }

    if (downloadExcelPayload && downloadExcelPayload.childTable === "true" ||
      downloadExcelPayload && downloadExcelPayload.childTable === true &&
      reportChildPayload && reportChildPayload.length > 1) {
      childTables["child" + 1] = reportChildPayload
    } if (downloadExcelPayload && downloadExcelPayload.childTable === "true" ||
      downloadExcelPayload && downloadExcelPayload.childTable === true &&
      reportWeekPayload && reportWeekPayload.length > 1) {
      childTables["child" + 2] = reportWeekPayload
    } if (downloadExcelPayload && downloadExcelPayload.childTable === "true" ||
      downloadExcelPayload && downloadExcelPayload.childTable === "true" && reportMonthPayload
      && reportMonthPayload.length > 1) {
      childTables["child" + 3] = reportMonthPayload
    }
    payload = {
      ...headerPayload,
      ...downloadExcelPayload,
      "date": moment().format('YYYY-MM-DD'),
      "time": moment().format('HH:mm:SS'),
      "data": reportPayload,
      "childTablesData": childTables
      //  "summary": reportChildPayload,
      //  "weeklySummary": reportWeekPayload,
      //  "monthlySummary":reportMonthPayload
    }

  } else {
    let reportWeekPayload = []
    let reportChildPayload = []
    let reportMonthPayload = []
    let reportPayload = {}
    let tableData = []
    // columns && Object.keys(columns).map((colKey)=>{
    // let adhocColumns =columns[colKey]
    datasource && Object.keys(datasource).map((dataKey, v) => {
      let adhocDatasource = datasource[dataKey].table
      // // let header=datasource[dataKey].header
      let adhocColumns = columns[dataKey]
      let indexValue = parseInt(dataKey) + 1
      reportPayload["table" + indexValue] = {
        ["datasource"]: [],

        // ["headers"]: datasource[dataKey] &&datasource[dataKey].header
      }

      if (adhocDatasource && adhocDatasource.length > 0) {
        adhocDatasource && Array.isArray(adhocDatasource) && adhocDatasource.map((datasourceObject) => {
          let newObj = {}
          adhocColumns && Array.isArray(adhocColumns) && adhocColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {}
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, datasourceObject)
              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {
              if (datasourceObject[column.key] === undefined) {
                // newObj[column.title] = ""
              } else {
                newObj[column.title] = datasourceObject[column.key]
              }
            }


          })
          datasource[dataKey] && datasource[dataKey].header && Object.keys(datasource[dataKey].header).map((i) => {
            delete datasource[dataKey].header["KilnNo"]
          })
          reportPayload["table" + indexValue]["datasource"].push(newObj)
          reportPayload["table" + indexValue]["headers"] = datasource[dataKey] && datasource[dataKey].header
          // delete reportPayload["table"+indexValue] ["headers"]["KilnNo"]

          reportPayload["table" + indexValue]["remarks"] = datasource[dataKey] && datasource[dataKey].footer
        })
      } else {
        let newObj = {}
        columns && Array.isArray(columns) && columns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {}
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")
            })
            newObj[column.title] = {
              ...newChildrenObject
            }
          } else {
            newObj[column.title] = ""
          }

        })
        tableData.push(newObj)
      }
      let reportChildPayload = []
      if (childColumns && (downloadExcelPayload.childTable === 'true' || downloadExcelPayload.childTable === true)) {

        if (childDatasource && childDatasource.length > 0) {
          childDatasource.map((childDatasourceObject) => {
            let newObj = {}
            childColumns && childColumns.map((column) => {


              if (column && column.children) {
                let newChildrenObject = {}
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(columnChildren, childDatasourceObject)

                })
                newObj[column.title] = {
                  ...newChildrenObject
                }
              } else {

                if (childDatasourceObject[column.key] === undefined) {
                  // newObj[column.title] = ""
                } else {
                  newObj[column.title] = childDatasourceObject[column.key]
                }
              }


            })
            reportChildPayload.push(newObj)
          })
        } else {
          let newObj = {}
          childColumns && childColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {}
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")

              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {
              newObj[column.title] = ""
            }

          })
          reportChildPayload.push(newObj)
        }
      }
      let reportWeekPayload = []
      if (childColumns && (downloadExcelPayload.childTable === 'true' || downloadExcelPayload.childTable === true)
      ) {

        if (weekSummaryDatasource && weekSummaryDatasource.length > 0) {
          weekSummaryDatasource.map((childDatasourceObject) => {
            let newObj = {}
            childColumns && childColumns.map((column) => {

              if (column && column.children) {
                let newChildrenObject = {}
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(columnChildren, childDatasourceObject)

                })
                newObj[column.title] = {
                  ...newChildrenObject
                }
              } else {
                if (childDatasourceObject[column.key] === undefined) {
                  newObj[column.title] = ""
                } else {
                  newObj[column.title] = childDatasourceObject[column.key]
                }
              }

            })
            reportWeekPayload.push(newObj)
          })
        } else {
          let newObj = {}
          childColumns && childColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {}
              let index = []
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")

              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {
              newObj[column.title] = ""
            }

          })
          reportWeekPayload.push(newObj)
        }
      }

    })

    //  })
    payload = {
      ...headerPayload,
      ...downloadExcelPayload,
      "date": moment().format('YYYY-MM-DD'),
      "time": moment().format('HH:mm:SS'),
      "data": reportPayload,
      "summary": reportChildPayload,
      "weeklySummary": reportWeekPayload
    }
  }

  let accessToken = localStorage.get('accessToken')
  let options = {}
  options = {
    method: 'POST',
    url: `/api/report/generateExcel`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: JSON.stringify(payload)
  }
  return axios(options)
    .then(({ data }) => {
      return data
    }).catch(error => {
      return ""

    })

}

export async function getISOPdfNametoDownload(columns, datasource, downloadExcelPayload, childColumns,
  childDatasource, weekSummaryDatasource, weekSummaryColumns, monthSummaryDatasource, monthlySummaryColumns, headerPayload) {
  let payload = {}
  if (columns && Array.isArray(columns) && datasource && Array.isArray(datasource)) {
    let reportPayload = []

    if (datasource && datasource.length > 0) {
      datasource && Array.isArray(datasource) && datasource.map((datasourceObject) => {
        let newObj = {}
        columns && Array.isArray(columns) && columns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {}
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, datasourceObject)

            })
            newObj[column.title] = {
              ...newChildrenObject
            }
          } else {
            if (datasourceObject[column.key] === undefined) {
              // newObj[column.title] = ""
            } else {
              newObj[column.title] = datasourceObject[column.key]
            }
          }


        })

        reportPayload.push(newObj)
      })
    } else {
      let newObj = {}
      columns && Array.isArray(columns) && columns.map((column) => {
        if (column && column.children) {
          let newChildrenObject = {}
          column.children.map((columnChildren) => {
            newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")
          })
          newObj[column.title] = {
            ...newChildrenObject
          }
        } else {
          newObj[column.title] = ""
        }

      })
      reportPayload.push(newObj)
    }

    let reportChildPayload = []
    if (childColumns && (downloadExcelPayload.childTable === 'true' || downloadExcelPayload.childTable === true)) {

      if (childDatasource && Array.isArray(childDatasource) && childDatasource.length > 0) {
        Array.isArray(childDatasource) && childDatasource.map((childDatasourceObject) => {
          let newObj = {}
          childColumns && childColumns.map((column) => {


            if (column && column.children) {
              let newChildrenObject = {}
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, childDatasourceObject)

              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {

              if (childDatasourceObject[column.key] === undefined) {
                // newObj[column.title] = ""
              } else {
                newObj[column.title] = childDatasourceObject[column.key]
              }
            }


          })
          reportChildPayload.push(newObj)
        })
      } else {
        let newObj = {}
        childColumns && childColumns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {}
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")

            })
            newObj[column.title] = {
              ...newChildrenObject
            }
          } else {
            newObj[column.title] = ""
          }

        })
        reportChildPayload.push(newObj)
      }
    }
    let reportWeekPayload = []
    let reportMonthPayload = []
    if (childColumns && (downloadExcelPayload.childTable === 'true' ||
      downloadExcelPayload.childTable === true)
    ) {

      if (weekSummaryDatasource && Array.isArray(weekSummaryDatasource) && weekSummaryDatasource.length > 0) {
        weekSummaryDatasource.map((childDatasourceObject) => {
          let newObj = {}

          weekSummaryColumns && weekSummaryColumns.map((column) => {

            if (column && column.children) {
              let newChildrenObject = {}
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, childDatasourceObject)

              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {
              if (childDatasourceObject[column.key] === undefined) {
                newObj[column.title] = ""
              } else {
                newObj[column.title] = childDatasourceObject[column.key]
              }
            }

          })


          reportWeekPayload.push(newObj)
        })
      } else {
        let newObj = {}
        weekSummaryColumns && weekSummaryColumns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {}
            let index = []
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")

            })
            newObj[column.title] = {
              ...newChildrenObject
            }
          } else {
            newObj[column.title] = ""
          }

        })
        reportWeekPayload.push(newObj)
      }

      if (monthSummaryDatasource && Array.isArray(monthSummaryDatasource) && monthSummaryDatasource.length > 0) {
        monthSummaryDatasource.map((childDatasourceObject) => {
          let newObj = {}

          monthlySummaryColumns && monthlySummaryColumns.map((column) => {

            if (column && column.children) {
              let newChildrenObject = {}
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, childDatasourceObject)

              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {
              if (childDatasourceObject[column.key] === undefined) {
                newObj[column.title] = ""
              } else {
                newObj[column.title] = childDatasourceObject[column.key]
              }
            }

          })


          reportMonthPayload.push(newObj)
        })
      } else {
        let newObj = {}
        monthlySummaryColumns && monthlySummaryColumns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {}
            let index = []
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")

            })
            newObj[column.title] = {
              ...newChildrenObject
            }
          } else {
            newObj[column.title] = ""
          }

        })
        reportMonthPayload.push(newObj)
      }
    }
    let childTables = {

    }
    if (downloadExcelPayload && downloadExcelPayload.childTable === "true" || downloadExcelPayload &&
      downloadExcelPayload.childTable === true && reportChildPayload && reportChildPayload.length > 1) {
      childTables["child" + 1] = reportChildPayload
    } if (downloadExcelPayload && downloadExcelPayload.childTable === "true" ||
      downloadExcelPayload && downloadExcelPayload.childTable === true &&
      reportWeekPayload && reportWeekPayload.length > 1) {
      childTables["child" + 2] = reportWeekPayload
    } if (downloadExcelPayload && downloadExcelPayload.childTable === "true" ||
      downloadExcelPayload && downloadExcelPayload.childTable === true && reportMonthPayload && reportMonthPayload.length > 1) {
      childTables["child" + 3] = reportMonthPayload
    }
    payload = {
      ...headerPayload,
      ...downloadExcelPayload,
      "date": moment().format('YYYY-MM-DD'),
      "time": moment().format('HH:mm:SS'),
      "data": reportPayload,
      "childTablesData": childTables
      //  "summary": reportChildPayload,
      //  "weeklySummary": reportWeekPayload,
      //  "monthlySummary":reportMonthPayload
    }

  } else {
    let reportWeekPayload = []
    let reportMonthPayload = []
    let reportChildPayload = []
    let reportPayload = {}
    let tableData = []
    // columns && Object.keys(columns).map((colKey)=>{
    // let adhocColumns =columns[colKey]
    datasource && Object.keys(datasource).map((dataKey) => {
      let adhocDatasource = datasource[dataKey].table
      let headers = datasource[dataKey].header
      let adhocColumns = columns[dataKey]
      let indexValue = parseInt(dataKey) + 1
      reportPayload["table" + indexValue] = {
        ["datasource"]: [],
      }

      if (adhocDatasource && adhocDatasource.length > 0) {
        adhocDatasource && Array.isArray(adhocDatasource) && adhocDatasource.map((datasourceObject) => {
          let newObj = {}
          adhocColumns && Array.isArray(adhocColumns) && adhocColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {}
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, datasourceObject)
              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {
              if (datasourceObject[column.key] === undefined) {
                // newObj[column.title] = ""
              } else {
                newObj[column.title] = datasourceObject[column.key]
              }
            }
          })
          datasource[dataKey] && datasource[dataKey].header && Object.keys(datasource[dataKey].header).map((i) => {
            delete datasource[dataKey].header["KilnNo"]
          })
          reportPayload["table" + indexValue]["datasource"].push(newObj);
          reportPayload["table" + indexValue]["headers"] = datasource[dataKey] && datasource[dataKey].header
          // delete reportPayload["table"+indexValue] ["headers"]["KilnNo"]
          reportPayload["table" + indexValue]["remarks"] = datasource[dataKey] && datasource[dataKey].footer
        })
      } else {
        let newObj = {}
        columns && Array.isArray(columns) && columns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {}
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")
            })
            newObj[column.title] = {
              ...newChildrenObject
            }
          } else {
            newObj[column.title] = ""
          }

        })
        tableData.push(newObj)
      }
      let reportChildPayload = []
      if (childColumns && (downloadExcelPayload.childTable === 'true' || downloadExcelPayload.childTable === true)) {

        if (childDatasource && childDatasource.length > 0) {
          childDatasource.map((childDatasourceObject) => {
            let newObj = {}
            childColumns && childColumns.map((column) => {


              if (column && column.children) {
                let newChildrenObject = {}
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(columnChildren, childDatasourceObject)

                })
                newObj[column.title] = {
                  ...newChildrenObject
                }
              } else {

                if (childDatasourceObject[column.key] === undefined) {
                  // newObj[column.title] = ""
                } else {
                  newObj[column.title] = childDatasourceObject[column.key]
                }
              }


            })
            reportChildPayload.push(newObj)
          })
        } else {
          let newObj = {}
          childColumns && childColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {}
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")

              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {
              newObj[column.title] = ""
            }

          })
          reportChildPayload.push(newObj)
        }
      }
      let reportWeekPayload = []
      if (childColumns && (downloadExcelPayload.childTable === 'true' || downloadExcelPayload.childTable === true)
      ) {

        if (weekSummaryDatasource && weekSummaryDatasource.length > 0) {
          weekSummaryDatasource.map((childDatasourceObject) => {
            let newObj = {}
            childColumns && childColumns.map((column) => {

              if (column && column.children) {
                let newChildrenObject = {}
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(columnChildren, childDatasourceObject)

                })
                newObj[column.title] = {
                  ...newChildrenObject
                }
              } else {
                if (childDatasourceObject[column.key] === undefined) {
                  newObj[column.title] = ""
                } else {
                  newObj[column.title] = childDatasourceObject[column.key]
                }
              }

            })
            reportWeekPayload.push(newObj)
          })
        } else {
          let newObj = {}
          childColumns && childColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {}
              let index = []
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, "")

              })
              newObj[column.title] = {
                ...newChildrenObject
              }
            } else {
              newObj[column.title] = ""
            }

          })
          reportWeekPayload.push(newObj)
        }
      }

    })

    //  })
    payload = {
      ...headerPayload,
      ...downloadExcelPayload,
      "date": moment().format('YYYY-MM-DD'),
      "time": moment().format('HH:mm:SS'),
      "data": reportPayload,
      "summary": reportChildPayload,
      "weeklySummary": reportWeekPayload,
      "monthlySummary": reportMonthPayload
    }
  }
  let accessToken = localStorage.get('accessToken')
  const SiteId = localStorage.get('currentSite')
  let options = {}
  options = {
    method: 'POST',
    url: `/api/report/generatePdf`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: { ...payload, SiteId }
  }
  return axios(options)
    .then(({ data }) => {
      return data
    }).catch(error => {
      return ""

    })
}

export async function _saveReportAPI(payload) {
  const siteId = localStorage.get("currentSite");
  const accessToken = localStorage.get("accessToken");
  const deviceTypeObject = {
    method: "POST",
    url: `/api/report/insert`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: { ...payload, SiteId: siteId },
  };
  axios(deviceTypeObject)
    .then((response) => {
    }).catch(err => {
      console.log("err");
    })
}

export async function getDropDownDataFromBackend(apiObject) {
  let accessToken = localStorage.get('accessToken')
  const siteId = localStorage.get('currentSite')
  let options = {
    method: 'POST',
    url: `/api/report/reportGroupsList`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      ReportName: apiObject.payload.reportName
    },
  }

  return axios(options)
    .then(({ data }) => {
      if (Array.isArray(data)) {
        return data
      } else {
        return [
          {
            title: 'Node1',
            value: 'node1',
            key: 'node1'
          },
          {
            title: 'Node2',
            value: 'node2',
            key: 'node2',
            children: [
              {
                title: 'Child Node21',
                value: 'cnode21',
                key: 'cnode21',
              },
              {
                title: 'Child Node22',
                value: 'cnode22',
                key: 'cnode22',
              },
            ],
          }
        ]
      }


    })


}



