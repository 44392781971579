import { types } from './types'
import { takeLatest, call, put } from 'redux-saga/effects'

import translation from './translation.requests'
import { setTranslationCacheSuccess } from './translation.duck'

export function* getTranslationCacheSaga(language) {
  try {    
    const translationReponse = yield call(translation.getTranslationCache,language.language)
    if(translationReponse){
      yield put(setTranslationCacheSuccess(translationReponse))
    }
  } catch (err) {
    console.log(err)
  }
}


export default function* translationSaga() {
  yield [
    takeLatest(types.GET_TRANSLATION_CACHE, getTranslationCacheSaga)
  ]
} 
