
export const defaultGraphTypeProps = {
  "report": [{
    "DisplayName": "Report Name",
    "Widget": "Input",
    "Key": "ReportName"
  }],
  "guage": [
    {
      "DisplayName": "Title Color",
      "Widget": "ColorList",
      "Key": "titleColor"
    }, {
      "DisplayName": "Unit of measure",
      "Widget": "Input",
      "Key": "uom"
    }, {
      "DisplayName": "Type of guage",
      "Widget": "Select",
      "Key": "guageType",
      "Options": ["normalGuage", "speedGuage"]
    },
    {
      "DisplayName": "Range",
      "Widget": "Input",
      "Key": "range"
    },
    {
      "DisplayName": "SplitNumber",
      "Widget": "Input",
      "Key": "SplitNumber"
    }
  ],
  "counter": [
    {
      "DisplayName": "Counter Type",
      "Widget": "Select",
      "Key": "counterType",
      "Options": ["number", "string", "both", "boolean"]
    }, {
      "DisplayName": "Font Color",
      "Widget": "Input",
      "Key": "titleColor"
    }, {
      "DisplayName": "Font Size",
      "Widget": "Input",
      "Key": "fontSize"
    }
  ],
  "statusCounter": [
    {
      "DisplayName": "FontSize",
      "Widget": "Input",
      "Key": "fontSize"
    },
    {
      "DisplayName": "IconColor",
      "Widget": "ColorList",
      "Key": "iconColor"
    },
    {
      "DisplayName": "TitleColor",
      "Widget": "Input",
      "Key": "TitleColor"
    },
    {
      "DisplayName": "Unit of measure",
      "Widget": "Input",
      "Key": "uom"
    },
    {
      "DisplayName": "Status Counter Type",
      "Widget": "Select",
      "Key": "statusCounterType",
      "Options": ["number", "string", "both", "notification"]
    }
  ],
  "progressBar": [
    {
      "DisplayName": "Bar Type",
      "Widget": "Select",
      "Key": "barType",
      "Options": ["circle", "line", "dashboard"]
    }
  ],
  "singleCandleStick": [
    {
      "DisplayName": "Range",
      "Widget": "Input",
      "Key": "range",
    },
    {
      "DisplayName": "Title color",
      "Widget": "ColorList",
      "Key": "titleColor",
    }
  ],
  "dateRangeSingleBar": [
    {
      "DisplayName": "Range",
      "Widget": "Input",
      "Key": "range",
    },
    {
      "DisplayName": "Title color",
      "Widget": "ColorList",
      "Key": "titleColor",
    }
  ],
  "singleBar": [
    {
      "DisplayName": "Range",
      "Widget": "Input",
      "Key": "range"
    },
    {
      "DisplayName": "Title color",
      "Widget": "ColorList",
      "Key": "titleColor",
    }
  ],
  "dateRangeSingleCandleStick": [
    {
      "DisplayName": "Range",
      "Widget": "Input",
      "Key": "range"
    },
    {
      "DisplayName": "Title color",
      "Widget": "ColorList",
      "Key": "titleColor",
    }
  ],
  "dateRangeLine": [
    {
      "DisplayName": "Colors",
      "Widget": "ColorList",
      "Key": "colors"
    },
    {
      "DisplayName": "Line Type",
      "Widget": "Input",
      "Key": "linetype"
    },
    {
      "DisplayName": "YAxis",
      "Widget": "TextArea",
      "Key": "YAxis"
    },
    {
      "DisplayName": "VisualMap",
      "Widget": "Input",
      "Key": "visualmap"
    }
  ],

  "bar": [
    {
      "DisplayName": "Tags",
      "Widget": "TagList",
      "Key": "tags"
    },
    {
      "DisplayName": "Colors",
      "Widget": "ColorList",
      "Key": "colors"
    },
    {
      "DisplayName": "YAxis",
      "Widget": "TextArea",
      "Key": "YAxis"
    },
    {
      "DisplayName": "Show MinMax ",
      "Widget": "Select",
      "Key": "minMax",
      "Options": ["true", "false"]

    },
    {
      "DisplayName": "xAxisLabel",
      "Widget": "Select",
      "Key": "xAxisLabel",
      "Options": ["string", "timeStamp"]

    },
    {
      "DisplayName": "Linecolor",
      "Widget": "ColorList",
      "Key": "Linecolor"
    }
  ],
  "area": [
    {
      "DisplayName": "Colors",
      "Widget": "ColorList",
      "Key": "colors"
    },
    {
      "DisplayName": "Line Type",
      "Widget": "Input",
      "Key": "linetype"
    },
    {
      "DisplayName": "YAxis",
      "Widget": "TextArea",
      "Key": "YAxis"
    },
  ],

  "sliderStatus": [
    {
      "DisplayName": "Tags",
      "Widget": "TagList",
      "Key": "tags"
    },
    {
      "DisplayName": "Colors",
      "Widget": "ColorList",
      "Key": "colors"
    },
    {
      "DisplayName": "YAxis",
      "Widget": "TextArea",
      "Key": "YAxis"
    },
    {
      "DisplayName": "Linecolor",
      "Widget": "Input",
      "Key": "linecolor"
    }],
  "GuageLine": [
    {
      "DisplayName": "Range",
      "Widget": "Input",
      "Key": "range"
    },
    {
      "DisplayName": "Colors",
      "Widget": "ColorList",
      "Key": "colors"
    },
    {
      "DisplayName": "TitleColor",
      "Widget": "Input",
      "Key": "titleColor"
    },
    {
      "DisplayName": "Linecolor",
      "Widget": "Input",
      "Key": "linecolor"
    }, {
      "DisplayName": "Unit of measure",
      "Widget": "Input",
      "Key": "uom"
    }
  ],
  "cardsList": [
    {
      "DisplayName": "Colors",
      "Widget": "ColorList",
      "Key": "colors"
    },
    {
      "DisplayName": "View Type",
      "Widget": "Select",
      "Key": "viewType",
      "Options": ["listOfItems", "listOfCards"]
    },
    {
      "DisplayName": "NumberOfColumns",
      "Widget": "Input",
      "Key": "numberOfColumns"
    }],

  "notificationTable": [
    {
      "DisplayName": "Activate Lights",
      "Widget": "Input",
      "Key": "activateLights"
    }, {
      "DisplayName": "DeActivate Lights",
      "Widget": "Input",
      "Key": "deActivateLights"
    },

  ],
  "scatter": [{
    "DisplayName": "Colors",
    "Widget": "ColorList",
    "Key": "colors"
  }],
  "pie": [{
    "DisplayName": "Colors",
    "Widget": "ColorList",
    "Key": "colors"
  }
  ],
  "dottedBar": [
    {
      "DisplayName": "Colors",
      "Widget": "ColorList",
      "Key": "colors"
    }
  ],
  "trend": [
    {
      "DisplayName": "Colors",
      "Widget": "ColorList",
      "Key": "colors"
    },
    {
      "DisplayName": "Line Type",
      "Widget": "Input",
      "Key": "linetype"
    },
    {
      "DisplayName": "YAxis",
      "Widget": "TextArea",
      "Key": "YAxis"
    },
    {
      "DisplayName": "Line Color",
      "Widget": "ColorList",
      "Key": "Linecolor"
    },
    {
      "DisplayName": "VisualMap",
      "Widget": "Input",
      "Key": "VisualMap"
    }
  ],
  "dateHeatmapCartesion": [
    {
      "DisplayName": "Display Type",
      "Widget": "Select",
      "Key": "cartesionDisplayType",
      "Options": ["percentage", "nopercentage", "none", "scatter"]
    },
    {
      "DisplayName": "rangebar",
      "Widget": "Select",
      "Key": "rangeBar",
      "Options": ["true", "false"]
    },
    {
      "DisplayName": "Range",
      "Widget": "Input",
      "Key": "range"
    },
    {
      "DisplayName": "VisualMap",
      "Widget": "Input",
      "Key": "visualmap"
    }
  ],
  "HeatmapCartesion": [
    {
      "DisplayName": "Display Type",
      "Widget": "Select",
      "Key": "cartesionDisplayType",
      "Options": ["percentage", "nopercentage", "none", "scatter"]
    },
    {
      "DisplayName": "rangebar",
      "Widget": "Select",
      "Key": "rangeBar",
      "Options": ["true", "false"]
    },
    {
      "DisplayName": "Range",
      "Widget": "Input",
      "Key": "range"
    },
    {
      "DisplayName": "VisualMap",
      "Widget": "Input",
      "Key": "visualmap"
    }
  ],
  rangeBar: [{
    "DisplayName": "YAxis",
    "Widget": "TextArea",
    "Key": "YAxis"
  }],
  "donut": [
    {
      "DisplayName": "Colors",
      "Widget": "Tag",
      "Key": "colors"
    }
  ],
  "pieSingleLine": [
    {
      "DisplayName": "Colors",
      "Widget": "ColorList",
      "Key": "colors"
    },
    {
      "DisplayName": "Line Color",
      "Widget": "ColorList",
      "Key": "Linecolor"
    }
  ],
  "stackedBar": [
    {
      "DisplayName": "Colors",
      "Widget": "ColorList",
      "Key": "Colors"
    },
    {
      "DisplayName": "View Type",
      "Widget": "Select",
      "Key": "viewType",
      "Options": ["vertical", "horizontal"]
    },
    {
      "DisplayName": "Colors",
      "Widget": "ColorList",
      "Key": "colors"
    }, {
      "DisplayName": "Table",
      "Widget": "Select",
      "Key": "colors",
      "Options": ["yes", "no"]
    }
  ],
  "stackedLine": [
    {
      "DisplayName": "Colors",
      "Widget": "ColorList",
      "Key": "Colors"
    },
    {
      "DisplayName": "Show Min Max",
      "Widget": "Select",
      "Key": "minMax",
      "Options": ["true", "false"]
    },
    {
      "DisplayName": "YAxis",
      "Widget": "TextArea",
      "Key": "YAxis"
    },
    {
      "DisplayName": "Table",
      "Widget": "Select",
      "Key": "table",
      "Options": ["yes", "no"]
    },
    {
      "DisplayName": "Tags",
      "Widget": "TagList",
      "Key": "tags"
    }
  ],
  "dateRangePredictions": [
    {
      "DisplayName": "Prediction Type",
      "Widget": "Select",
      "Key": "predictionType",
      "Options": ["Array of Object", "Array of Array"]
    }
  ],
  "barTable": [
    {
      "DisplayName": "Tags",
      "Widget": "TagList",
      "Key": "tags"
    },
    {
      "DisplayName": "Colors",
      "Widget": "ColorList",
      "Key": "colors"
    },
    {
      "DisplayName": "YAxis",
      "Widget": "TextArea",
      "Key": "YAxis"
    }
  ],
  "stackbar": [
    {
      "DisplayName": "Colors",
      "Widget": "ColorList",
      "Key": "colors"
    }
  ],
  "line": [
    {
      "DisplayName": "Display Type",
      "Widget": "Input",
      "Key": "displayType"
    },
    {
      "DisplayName": "Colors",
      "Widget": "ColorList",
      "Key": "colors"
    }
  ],
  image: [{
    "DisplayName": "HMI Path",
    "Widget": "Input",
    "Key": "path"
  }],
  video: [{
    "DisplayName": "Path",
    "Widget": "Input",
    "Key": "path"
  }],
  table: [
    {
      "DisplayName": "Table Size",
      "Widget": "Input",
      "Key": "tableSize"
    }
  ]

  // range: [],
  // refreshTime: "",
  // checked: false,
  // excelKeyValues: [{ key: "", value: "" }],
  // enableExcel: false,
}

export const graphTypeList = [
  {
    "value": "table",
    "key": "Table"
  },
  {
    "value": "report",
    "key": "Report",
  },
  {
    "value": "guage",
    "key": "Guage"
  },
  {
    "value": "singleBar",
    "key": "SingleBar"
  },
  {
    "value": "dateRangeSingleBar",
    "key": "DateRangeSingleBar"
  },
  {
    "value": "image",
    "key": "Image"
  },
  {
    "value": "counter",
    "key": "Counter"
  },
  {
    "value": "cardsList",
    "key": "CardsList"
  },
  {
    "value": "bar",
    "key": "Bar"
  },
  {
    "value": "dateBar",
    "key": "DateBar"
  },
  {
    "value": "dateBarDotted",
    "key": "DateDottedBar"
  },
  {
    "value": "rangeBar",
    "key": "BarDatePicker"
  },
  {
    "value": "dateRangeTable",
    "key": "DateRangeTable"
  },
  {
    "value": "rangeTable",
    "key": "RangeTable"
  },
  {
    "value": "candleStick",
    "key": "CandleStick"
  },
  {
    "value": "trend",
    "key": "Trend"
  },
  {
    "value": "dateRangeCandleStick",
    "key": "DateRangeCandleStick"
  },
  {
    "value": "dateRangeSingleCandleStick",
    "key": "DateRangeSingleCandleStick"
  },


  {
    "value": "line",
    "key": "Line"
  },
  {
    "value": "dateRangeLine",
    "key": "DateRange Line"
  },
  {
    "value": "rangeLine",
    "key": "DatePicker"
  },
  {
    "value": "rangeLine",
    "key": "DatePicker"
  },
  {
    "value": "progressBar",
    "key": "ProgressBar"
  },
  {
    "value": "multiline",
    "key": "MultiLine"
  },
  {
    "value": "funnel",
    "key": "Funnel"
  },
  {
    "value": "donut",
    "key": "Donut"
  },
  {
    "value": "pie",
    "key": "Pie"
  },
  {
    "value": "pieRose",
    "key": "PieRose"
  },
  {
    "value": "area",
    "key": "Area"
  }, {
    "value": "regression",
    "key": "Regression"
  }, {
    "value": "pieBar",
    "key": "PieBar"
  }, {
    "value": "bubbleChart",
    "key": "BubbleChart"
  }, {
    "value": "candleStickBrush",
    "key": "CandleStickBrush"
  }, {
    "value": "stackBar",
    "key": "StackBar"
  }, {
    "value": "treeGraph",
    "key": "TreeGraph"
  }, {
    "value": "boxPlot",
    "key": "BoxPlot"
  }, {
    "value": "liquidGuage",
    "key": "LiquidGuage"
  }, {
    "value": "beijingLine",
    "key": "BeijingLine"
  }, {
    "value": "rangeDualTable",
    "key": "RangeDualTable"
  }, {
    "value": "singleCandleStick",
    "key": "SingleCandleStick"
  },
  {
    "value": "barTable",
    "key": "BarTable"
  }, {
    "value": "lineTable",
    "key": "LineTable"
  }, {
    "value": "dateBarTable",
    "key": "DateBarTable"
  },
  {
    "value": "statusCounter",
    "key": "StatusCounter"
  },
  {
    "value": "scatter",
    "key": "Scatter"
  }, {
    "value": "HeatmapCartesion",
    "key": "HeatmapCartesion"
  }, {
    "value": "heatmapCalendar",
    "key": "HeatmapCalendar"
  }, {
    "value": "dottedBar",
    "key": "ScatterDottedBar"
  },
  {
    "value": "guageLine",
    "key": "GuageLine"
  },
  {
    "value": "pieSingleLine",
    "key": "PieSingleLine"
  }, {
    "value": "pieLine",
    "key": "PieLine"
  },
  {
    "value": "cardsList",
    "key": "CardsList"
  }, {
    "value": "windBarb",
    "key": "WindBarb"
  },
  {
    "value": "stackedBar",
    "key": "StackedBar"
  },
  {
    "value": "stackedLine",
    "key": "StackedLine"
  }, {
    "value": "feedbackTable",
    "key": "FeedbackTable"
  }, {
    "value": "dateFeedbackTable",
    "key": "DateFeedbackTable"
  }, {
    "value": "imageGallery",
    "key": "ImageGallery"
  }, {
    "value": "video",
    "key": "VideoComponent"
  }, {
    "value": "GuageRing",
    "key": "GuageRing"
  },
  {
    "value": "dateRangePredictions",
    "key": "DateRangePredictions"

  },
  {
    "value": "notificationTable",
    "key": "NotificationTable"
  }, {
    "value": "selectImageDetails",
    "key": "SelectImageDetails"
  }, {
    "value": "pieNest",
    "key": "PieNestComponent"
  }, {
    "value": "sliderStatus",
    "key": "SliderStatus"
  }, {
    "value": "cards",
    "key": "BarwithLine"
  },
  {
    "value": "dateHeatmapCartesion",
    "key": "dateHeatmapCartesion"
  },
  {
    "value": "notificationScatter",
    "key": "NotificationScatter"
  },
  {
    "value": "dateRangeNotificationScatter",
    "key": "DateRangeNotificationScatter"
  }
]
