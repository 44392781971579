import React, { Fragment } from "react";
import moment from 'moment'
import { Table, message, Form, Icon, Button, Input, Select } from "antd";
import { DatePicker } from "antd4";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getWarningTypes, getRootCauseInfo } from "./Apicalls";
import { StyledDashboard, StyledTable, lightTheme } from "./style";
import 'antd4/lib/date-picker/style/css'
import constants from "./constants";
import history from "../../commons/history";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../selectors/language";
import { getparameterList } from "./Apicalls";
const { Option } = Select

const { RangePicker } = DatePicker
class RootCause extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      DeviceId: "",
      parameterList: [],
      ParameterCode:[],
      endTime: "",
      WarningType:["Warning-1","Warning-2","Warning-3"],
      warningLimits:[],
      selectedDate:{
        // startDate: moment().subtract(1, "hour").format('YYYY-MM-DD 00:00'),
        // endDate: moment().format('YYYY-MM-DD 23:59'),
      }
    };
  }
  async componentWillMount() {
    let ParameterCode = []
    let queryString = this.props.location.search
    let DeviceId = queryString.match(/Device=([^&]*)/i)[1]
    let endTime = queryString.match(/EndTime=([^&]*)/i)[1]
    let parameter = queryString.match(/Parameter=([^&]*)/i)[1]
    let endDate = moment.utc(endTime, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm")
    let selectedDate={ startDate: moment(endDate).subtract(1, "hour").format('YYYY-MM-DD HH:mm'),
                       endDate: moment(endDate).format('YYYY-MM-DD HH:mm')}
     ParameterCode.push(parameter)
    this.setState({ DeviceId,ParameterCode,selectedDate})
    try {
      // let data = await ruleList();
      let warningLimits= await getWarningTypes()
      let paramList = await getparameterList(DeviceId, "Device")
      this.setState({ parameterList: paramList.data ,warningLimits:warningLimits.data});
      let rootCauseList = await getRootCauseInfo(this.state);
    }catch(error){
      message.error(error.response.data.message);
  }

  }
  multiSelectHandle=(value,type) =>{
   if(type==="ParameterCode"){
    this.setState({ ParameterCode: value });
   }else if(type==="WarningType"){
    this.setState({ WarningType: value });
   } 
     
   
  }
  onChange=(value, dateString)=> {
    // this.getRuleHistory(value)
    this.setState({ selectedDate:{ startDate: moment(value[0]).format('YYYY-MM-DD HH:mm'), endDate: moment(value[1]).format('YYYY-MM-DD HH:mm'), } })
  }
  getRuleHistory = async (value) => {
    try {
     
   
        let rootCauseList = await getRootCauseInfo(value);
        this.setState({ tableData: rootCauseList.data });
      
    } catch (error) {
      message.error(`${error.response.data.message}`);
    }
  }
  
  handleSubmit = async () => {
    
   this.setState({ loading: true });
   
    this.props.form.validateFields(async (err, values) => {
     
          try{
            let rootCauseList = await getRootCauseInfo(this.state);
            this.setState({ tableData: rootCauseList.data });
    }catch(error){
        message.error(error.response.data.message);
    }
       
     
        
    })
    // setTimeout(() => {
    //   this.setState({ loading: false, visible: false });
    // }, 3000);
  };


  render() {
    const { getFieldDecorator } = this.props.form
    const { translationCache, language } = this.props;
    let { parameterList, DeviceId,ParameterCode,WarningType,selectedDate,warningLimits } = this.state
    const columns = [
      {
        title:"Created Time",
        dataIndex: "CREATEDTIME",
        key: "CREATEDTIME",
      },
      {
        title:"Warning Type",
        dataIndex: "WarningType",
        key: "WarningType",
      },
      {
        title:"Parameter",
        dataIndex: "Parameter",
        key: "Parameter",
      },
      {
        title:"Value",
        dataIndex: "Value",
        key: "Value",
      }
    ];
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        <Fragment>
        <Button type="primary" style={{marginBottom:"10px"}} onClick={() => {
              history.goBack()
            }}><Icon type="left" />  {translationCache &&
              translationCache[`${constants.Go_Back}`] &&
              translationCache[`${constants.Go_Back}`]
              ? translationCache[`${constants.Go_Back}`]
              : `${constants.Go_Back}`} </Button>
          <Form layout="inline"  >

            <Form.Item >
              {getFieldDecorator('DeviceId', {
                rules: [{ required: true, message: 'Please input your username!' }],
                initialValue:DeviceId
              })(
                <Input
                  // value={"test"}
                disabled
                  placeholder="Device ID"
                />,
              )}
            </Form.Item>

            <Form.Item >
              {getFieldDecorator('ParameterCode', {
                   initialValue:ParameterCode,
                rules: [{ required: true, message: "Please Select Parameters" }]
              })(
                <Select mode="multiple" placeholder="Select ParameterList" style={{ width: "500px" }} 
                onChange={(e) => this.multiSelectHandle(e,"ParameterCode")} >
                  {parameterList.map((param) => {
                    return (
                      <Option value={param.ParameterCode}>
                        {param.ParameterCode}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item >
{getFieldDecorator('WarningType', {
     initialValue:WarningType,
  rules: [{ required: true, message: "Please Select Warning Type" }]
})(
  <Select mode="multiple" placeholder="Select ParameterList" style={{ width: "500px" }}   onChange={(e) => this.multiSelectHandle(e,"WarningType")}>
    {/* <Option value="Warning-1">Warning-1</Option>
      <Option value="Warning-2">Warning-2</Option>
      <Option value="Warning-3">Warning-3</Option> */}
       {warningLimits.map((param) => {
                    return (
                      <Option value={param.WarningType}>
                        {param.WarningType}
                      </Option>
                    );
                  })}
  </Select>
)}
</Form.Item>
<Form.Item><RangePicker
                 showTime={{ format: 'HH:mm' }}
                 style={{ width: "300px",float:"right" }}
                 format="YYYY-MM-DD HH:mm"
                 value={[moment(selectedDate.startDate), moment(selectedDate.endDate)]}
                 onChange={this.onChange}
                  disabled={[false, true]}
                 disabledDate={this.disabledDate}
                 onOk={this.onOk} /></Form.Item>
            <Form.Item>
              <Button type="primary" onClick={this.handleSubmit} >
               Submit
              </Button>
            </Form.Item>
          </Form>
          <div>
            <StyledTable theme={lightTheme}>
              <Table
                style={{ marginTop: "3%" }}
                columns={columns}
                dataSource={this.state.tableData}
              // onRow={(record, rowIndex) => {
              //   return {
              //     onClick: (event) => {
              //       this.handleRowClick(record.Id);
              //     }, // click row
              //   };
              // }}
              />
            </StyledTable>
          </div>
        </Fragment>
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
});

export default connect(mapStateToProps)(Form.create()(RootCause));
