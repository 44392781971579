import React, { Component } from "react";
import posed from "react-pose";
import { findDOMNode } from "react-dom";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import {
  List,
  Menu,
  Icon,
  Dropdown,
  Card,
  Radio,
  message,
  Popconfirm,
} from "antd";

import history from '../../../commons/history'
import {
  selectDashboard,
  deleteDashboard,
  getDashboardByName
} from "../DashboardComponents/DashboardCalls";

import { getCurrentLanguage, getTranslationCache } from "../../../selectors/language";
import { constant } from "./Constants"

import {
  StyledDashboard,
  StyledAddButton,
  StyledIFrame,
  StyledAnimation,
  LinkOverlay,
} from "./styles";
import styles from "./CardList.less";
import "./cardcover.css";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "table",
      DashboardList: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
    };
  }

  componentDidMount() {
    this.getDashboards();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache || prevProps.language !== this.props.language) {
      this.setState({
        translationCache: this.props.translationCache, language: this.props.language
      });
    }
  }

  showEditModal = (item) => {
    this.setState({
      visible: true,
      current: item,
    });
  };

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  listContent(list) {
    return (
      <div style={{ fontSize: "1" }}>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            marginLeft: "40px",
          }}
        >
          <span style={{ lineHeight: "20px", color: "rgba(0, 0, 0, 0.65)" }}>
            Name
          </span>
          <p
            style={{ marginTop: "4px", marginBottom: "0", lineHeight: "22px" }}
          >
            <button
              type="button"
              className="link-button"
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push({ pathname: `dashboard/${list.name}` });
              }}
            >
              {list.name}
            </button>
          </p>
        </div>
      </div>
    );
  }

  downLoadJson = async (name) => {
    const dashboardsdata = await getDashboardByName(name);
    let contentType = "application/json;charset=utf-8;";
    let a = document.createElement("a");
    a.download = `${dashboardsdata.Name}`;
    a.href =
      "data:" +
      contentType +
      "," +
      encodeURIComponent(JSON.stringify(dashboardsdata));
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  render() {
    const { value, translationCache, language } = this.state;
    const MoreBtn = (props) => (
      <Dropdown
        overlay={
          <Menu>
            {/* <Can I="update" a="dashboard"> */}
            <Menu.Item
              key="edit"
              onClick={() => {
                this.selectDashboard(props.current.name);
              }}
            >
              {translationCache &&
                translationCache[`${constant.Edit}`] &&
                translationCache[`${constant.Edit}`] ?
                translationCache[`${constant.Edit}`] :
                `${constant.Edit}`}
            </Menu.Item>
            {/* </Can> */}
            {/* <Can I="update" a="dashboard"> */}
            <Menu.Item key="delete">
              <Popconfirm
                title={"Do you want to delete " + props.current.name + "?"}
                onConfirm={() => {
                  this.confirm(props.current.Id);
                }}
                onCancel={this.cancel}
                okText="Yes"
                cancelText="No"
              >
                <button
                  type="button"
                  className="link-button"
                  style={{ color: "#797979" }}
                >
                  {translationCache &&
                    translationCache[`${constant.Delete}`] &&
                    translationCache[`${constant.Delete}`] ?
                    translationCache[`${constant.Delete}`] :
                    `${constant.Delete}`}
                </button>
              </Popconfirm>
            </Menu.Item>
            {/* </Can> */}
          </Menu>
        }
      >
        <button type="button" className="link-button">
          {translationCache &&
            translationCache[`${constant.More}`] &&
            translationCache[`${constant.More}`] ?
            translationCache[`${constant.More}`] :
            `${constant.More}`} <Icon type="down" />
        </button>
      </Dropdown>
    );

    const Box = posed.div({
      hoverable: true,
      init: {
        scale: 1,
        boxShadow: "0px 0px 0px rgba(0,0,0,0)",
      },
      hover: {
        scale: (1.029, 1.029),
        boxShadow: "0px 5px 10px rgba(0,0,0,0.2)",
      },
    });

    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "30px",
          }}
        >
          <div>
            {/* <Can I="create" a="dashboard"> */}
            <StyledAddButton
              type="primary"
              icon="plus"
              onClick={() => {
                history.push({ pathname: "/rubus/editdashboard", state: "new" });
              }}
              ref={(component) => {
                this.addBtn = findDOMNode(component);
              }}
            >
              {translationCache &&
                translationCache[`${constant.Add_Dashboard}`] &&
                translationCache[`${constant.Add_Dashboard}`] ?
                translationCache[`${constant.Add_Dashboard}`] :
                `${constant.Add_Dashboard}`}
            </StyledAddButton>
            {/* </Can> */}
          </div>
          <RadioGroup
            size="medium"
            style={{ margin: "5px 25px" }}
            onChange={this.onChange}
            value={this.state.value}
            defaultValue={this.state.value}
          >
            <RadioButton value="card">
              <Icon type="appstore" />
            </RadioButton>
            <RadioButton value="table">
              <Icon type="bars" />
            </RadioButton>
          </RadioGroup>
        </div>

        {value === "table" ? (
          <List
            style={{ padding: "5px 30px" }}
            size="large"
            rowKey="id"
            dataSource={this.state.DashboardList}
            pagination={
              this.state.DashboardList.length > 5
                ? {
                  pageSize: "5",
                }
                : false
            }
            renderItem={(item) => (
              <List.Item
                actions={[
                  <button
                    type="button"
                    className="link-button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push({
                        pathname: `dashboard/${item.name}`,
                        routes: item.name,
                      });
                      localStorage.setItem("dashboardName", item.name);
                    }}
                  >
                    {translationCache &&
                      translationCache[`${constant.View}`] &&
                      translationCache[`${constant.View}`] ?
                      translationCache[`${constant.View}`] :
                      `${constant.View}`}
                  </button>,
                  <button
                    type="button"
                    className="link-button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.downLoadJson(item.name);
                    }}
                  >
                    {translationCache &&
                      translationCache[`${constant.Download}`] &&
                      translationCache[`${constant.Download}`] ?
                      translationCache[`${constant.Download}`] :
                      `${constant.Download}`}
                  </button>,

                  <MoreBtn current={item} />,
                ]}
              >
                <List.Item.Meta
                  title="Name"
                  description={
                    <button
                      type="button"
                      className="link-button"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push({
                          pathname: `dashboard/${item.name}`,
                        });
                      }}
                    >
                      {" "}
                      {item.name}
                    </button>
                  }
                />
                {/* {this.listContent(item)} */}
              </List.Item>
            )}
          />
        ) : (
          <div style={{ padding: "30px" }}>
            <StyledAnimation
              rowKey="id"
              grid={{ gutter: 24, lg: 3, md: 2, sm: 1, xs: 4 }}
              dataSource={this.state.DashboardList}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <Box className="box">
                    <Card
                      hoverable
                      className={styles.card}
                      cover={
                        <div>
                          <LinkOverlay
                            to={{ pathname: `/rubus/dashboard/${item.name}` }}
                          />
                          <StyledIFrame
                            height="300px"
                            scrolling="no"
                            src={`/rubus/dashboard/${item.name}?kiosk=true`}
                          />
                        </div>
                      }
                      actions={[
                        // <Can I="read" a="dashboard">
                        <button
                          type="button"
                          className="link-button"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push({
                              pathname: `dashboard/${item.name}`,
                              routes: item.name,
                            });
                            localStorage.setItem("dashboardName", item.name);
                          }}
                        >
                          {translationCache &&
                            translationCache[`${constant.View}`] &&
                            translationCache[`${constant.View}`] ?
                            translationCache[`${constant.View}`] :
                            `${constant.View}`}
                        </button>,
                        //  </Can>
                        // <Can I="update" a="dashboard">
                        <button
                          type="button"
                          className="link-button"
                          onClick={() => {
                            this.selectDashboard(item.name);
                          }}
                        >
                          {translationCache &&
                            translationCache[`${constant.Edit}`] &&
                            translationCache[`${constant.Edit}`] ?
                            translationCache[`${constant.Edit}`] :
                            `${constant.Edit}`}
                        </button>,
                        // </Can>
                        // <Can I="delete" a="dashboard">
                        <Popconfirm
                          title={"Do you want to delete " + item.name + "?"}
                          onConfirm={() => {
                            this.confirm(item.name);
                          }}
                          onCancel={this.cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <button type="button" className="link-button">
                            Delete
                          </button>
                        </Popconfirm>,
                        // </Can>
                      ]}
                    >
                      <Card.Meta
                        avatar={
                          <img
                            alt=""
                            className={styles.cardAvatar}
                            src={item.avatar}
                          />
                        }
                        title={
                          <button
                            type="button"
                            className="link-button"
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push({
                                pathname: `dashboard/${item.name}`,
                              });
                            }}
                          >
                            {" "}
                            {item.name}
                          </button>
                        }
                      />
                    </Card>
                  </Box>
                </List.Item>
              )}
            />
          </div>
        )}
      </StyledDashboard>
    );
  }

  selectDashboard = async (dashboardName) => {
    // const dashboardsdata = await getByNameDashboard(e);
    
    if (dashboardName) {
      // localStorage.setItem("panels", dashboardsdata["json"]);
      // localStorage.setItem("dashboardName", dashboardsdata["Name"]);
      // localStorage.setItem("dashboardId", dashboardsdata["Id"]);
      history.push({ pathname: "/rubus/editdashboard", state: dashboardName });
    }
  };

  confirm = (dashboardId) => {
    this.deleteDashboard(dashboardId);
  };

  cancel = (e) => {
    e.preventDefault();
  };

  deleteDashboard = async (dashboardId) => {
    const msg = await deleteDashboard(dashboardId);
    this.getDashboards();
    message.success(msg);
  };

  getDashboards = async () => {
    let dashboards = [];
    let dashboardsdata = await selectDashboard("");
    if (dashboardsdata !== undefined) {
      dashboardsdata.forEach((value, index) => {
        dashboards.push({
          id: index,
          name: value.Name,
          Id: value.Id
        });
      });
    }
    this.setState({ DashboardList: dashboards });
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
});
export default connect(mapStateToProps)(DashboardPage);
