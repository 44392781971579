
import React from "react";
import { Row, Col, Slider } from "antd";

// import sld from "../images/SLD.svg";
import "antd/dist/antd.css";

class Hmiditor extends React.Component {
  constructor() {
    super();
    this.state = {
      viewBoxX: 0,
      viewBoxY: 0,
      viewBoxVH: 0,
      viewBoxVW: 0,
      viewBoxTX: 0,
      viewBoxTY: 0,
      viewBoxFS: 10,
    };
  }

  onChangeX = (value) => {
    this.setState({
      viewBoxX: parseInt(value),
    });
  };
  onChangeY = (value) => {
    this.setState({
      viewBoxY: parseInt(value),
    });
  };
  onChangeTX = (value) => {
    this.setState({
      viewBoxTX: parseInt(value),
    });
  };
  onChangeTY = (value) => {
    this.setState({
      viewBoxTY: parseInt(value),
    });
  };
  onChangeVH = (value) => {
    this.setState({
      viewBoxVH: parseInt(value),
    });
  };
  onChangeVW = (value) => {
    this.setState({
      viewBoxVW: parseInt(value),
    });
  };
  onChangeFS = (value) => {
    this.setState({
      viewBoxFS: parseInt(value),
    });
  };
  toggleFilter = (event) => {
    this.setState({
      showOn: !this.state.showOn,
    });
  };
  render() {
    const {
      viewBoxX,
      viewBoxY,
      viewBoxVH,
      viewBoxVW,
      viewBoxTX,
      viewBoxTY,
      viewBoxFS,
    } = this.state;

    return (
      <div>
        <svg
          width="100%"
          height="100%"
          viewBox={`${viewBoxX} ${viewBoxY} ${viewBoxVW} ${viewBoxVH}`}
        >
          <image
            // xlinkHref={sld}
            width={`${viewBoxVW} `}
            height={`${viewBoxVH}`}
          />
          <text
            x={`${viewBoxTX} `}
            y={`${viewBoxTY} `}
            font-size={`${viewBoxFS}% `}
            fill="red"
            font-weight="bold"
          >
            RUBUS
          </text>
        </svg>

        <div
          className="settings"
          style={{
            opacity: "0.5",
            position: "absolute",
            top: "10%",
            right: "0",
            left: "0",
            padding: "5px",
            width: "100%",
            background: "grey",
            color: "black ",
            fontWeight: "bold",
            height: "10%",
           
          }}
        >
          <Row>
            <Col span={4}>
              <p>Text X {viewBoxTX} </p>
              <Slider
                min={0}
                max={3000}
                defaultValue={0}
                onChange={this.onChangeTX}
                tooltipPlacement="top"
              />
            </Col>
            <Col span={4}>
              <p>Text Y {viewBoxTY}</p>
              <Slider
                min={0}
                max={3000}
                defaultValue={0}
                onChange={this.onChangeTY}
                tooltipPlacement="top"
              />
            </Col>
            <Col span={4}>
              <p>FontSize {viewBoxFS}</p>
              <Slider
                min={0}
                max={300}
                defaultValue={10}
                onChange={this.onChangeFS}
                tooltipPlacement="top"
              />
            </Col>
            <Col span={4}>
              <p>x :"{viewBoxTX}"</p>
              <p>y :"{viewBoxTY}"</p>
              <p>fontSize:"{viewBoxFS}%"</p>
              {/* <p
              style={{
                width: "20%",
                background: "red",
                textAlign: "center",
                height: "5%",
              }}
              onClick={this.toggleFilter}
            >
              set
              </p> */}
            </Col>
            
          </Row>
        </div>
        <div
          className="settingsDiv"
          style={{
            opacity: "0.5",
            position: "absolute",
            top: "40%",
            right: "0",
            left: "0",
            padding: "5px",
            width: "100%",
            background: "grey",
            color: "black ",
            fontWeight: "bold",
            height: "10%",
            display: this.state.showOn ? "none" : "block",
          }}
        >
          <Row>
            <Col span={4}>
              <p>X</p>
              <Slider
                min={-1000}
                max={1000}
                defaultValue={0}
                onChange={this.onChangeX}
                tooltipPlacement="top"
              />
            </Col>
            <Col span={4}>
              <p>Y</p>
              <Slider
                min={-1000}
                max={1000}
                defaultValue={0}
                onChange={this.onChangeY}
                tooltipPlacement="top"
              />
            </Col>

            <Col span={4}>
              <p>Width</p>
              <Slider
                min={0}
                max={3000}
                defaultValue={0}
                onChange={this.onChangeVW}
                tooltipPlacement="top"
              />
            </Col>
            <Col span={4}>
              <p>Height</p>
              <Slider
                min={0}
                max={3000}
                defaultValue={0}
                onChange={this.onChangeVH}
                tooltipPlacement="top"
              />
            </Col>
            <Col span={4}>
              <p>
                viewBox : "{viewBoxX} {viewBoxY} {viewBoxVW} {viewBoxVH}"
              </p>
              <p> width : "{viewBoxVW} " </p>
              <p> height : "{viewBoxVH} " </p>
              {/* <p
              style={{
                width: "20%",
                background: "red",
                textAlign: "center",
                height: "10%",
              }}
              onClick={this.toggleFilter}
            >
              set
            </p> */}
            </Col>
            
          </Row>
        </div>
      </div>
    );
  }
}

export default Hmiditor

