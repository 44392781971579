import React, { Component } from "react";
import { Input, Form, Select, Button, message} from "antd";
import "./reportConfig.css";
let { Option } = Select;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class TableConfigCustomization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editObj: {},
      activeKey: [],
      dragDisabled: false,
      tableProperties: props.tableProperties || [],
      tableMaster: [],
    };
    this.columns = {};
  }

  componentWillMount() {
    // this._getTableMasters()
  }

  componentDidUpdate(prevprops) {
    if (this.props.tableProperties !== prevprops.tableProperties) {
      this.setState({
        tableProperties: this.props.tableProperties,
      });
    }
  }

  onDragEnd = (result) => {
    const { tableProperties } = this.state;
    if (!result.destination) {
      return;
    }
    const columnsPropertiess = reorder(
      tableProperties,
      result.source,
      result.destination
    );
    this.setState({
      tableProperties: columnsPropertiess,
    });
    this.props.jsonSaveObject(tableProperties, "tableConfiguration");
  };

  handleFieldChange(value, colName) {
    const { tableProperties } = this.state;
    tableProperties[colName] = value;

    this.setState({
      tableProperties,
    });

    this.props.jsonSaveObject(tableProperties, "tableConfiguration");
  }

  handleChange(value, keyName, key) {
    const { tableProperties } = this.state;
    tableProperties[keyName][key] = value;

    this.setState({
      tableProperties,
    });
    this.props.jsonSaveObject(tableProperties, "tableConfiguration");
  }

  tableSave = (e) => {
    const { tableProperties } = this.state;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.jsonSaveObject(tableProperties, "tableConfiguration");
        message.success("TableConfiguration Validated Successfully");
        this.setState({
          activeKey: [],
        });
      }
    });
  };

  callback = (key) => {
    if (key && key.length <= 1) {
      this.setState({
        activeKey: key,
      });
    } else if (key.length > 1) {
      key = key[key.length - 1];
      this.setState({
        activeKey: [key],
      });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      tableProperties
 } = this.state;
    let item = tableProperties;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
    };
    const evenRowColor = [
      "#f5222d",
      "#bfbfbf",
      "#1890ff",
      "#fadb14",
      "#a0d911",
      "#fa541c",
      "#eb2f96",
      "#13c2c2",
      "#000000",
      "#ffd8bf",
    ];
    const oddRowColor = [
      "#ff7875",
      "#f0f0f0",
      "##69c0ff",
      "#fff566",
      "#d3f261",
      "#ff9c6e",
      "#ffadd2",
      "##5cdbd3",
      "#f5f5f5",
      "#ffffb8",
    ];

    return (
      <div style={{ marginBottom: "50px" }}>
        {tableProperties ? (
          <div>
            <Form {...formItemLayout}>
              <Form.Item label="Bordered :">
                {getFieldDecorator("bordered", {
                  initialValue: item.bordered,
                  rules: [{ required: true, message: "Please Select Option!" }],
                })(
                  <Select
                    style={{ width: "250px" }}
                    onSelect={(value) =>
                      this.handleFieldChange(value, "bordered")
                    }
                  >
                    <Option value="true">TRUE</Option>
                    <Option value="false">FALSE</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="HorizontalScroll :">
                {getFieldDecorator("horizontalScroll", {
                  initialValue: item.horizontalScroll,
                  rules: [{ required: true, message: "Please Select Option!" }],
                })(
                  <Select
                    style={{ width: "250px" }}
                    onSelect={(value) =>
                      this.handleFieldChange(value, "horizontalScroll")
                    }
                  >
                    <Option value="true">TRUE</Option>
                    <Option value="false">FALSE</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="TableSize :">
                {getFieldDecorator("tableSize", {
                  initialValue: item.tableSize,
                  rules: [
                    { required: true, message: "Please Provide TableSize!" },
                  ],
                })(
                  <Input
                    value={item.tableSize}
                    style={{ width: "250px" }}
                    type="number"
                    onChange={(e) =>
                      this.handleFieldChange(e.target.value, "tableSize")
                    }
                  />
                )}
              </Form.Item>
              <Form.Item label="NoResultMessage">
                {getFieldDecorator("noResultMessage", {
                  initialValue: item.noResultMessage,
                  rules: [
                    {
                      required: true,
                      message: "Please Provide noResultMessage!",
                    },
                  ],
                })(
                  <Select
                    style={{ width: "250px", showArrow: true }}
                    onSelect={(value) =>
                      this.handleFieldChange(value, "noResultMessage")
                    }
                  >
                    <Option value="No Records">No Records</Option>
                    <Option value="No Data">No Data</Option>
                    <Option value="Data Not Found">Data Not Found</Option>
                  </Select>
                )}
              </Form.Item>

              <Form.Item label="IsCursorPointer :">
                {getFieldDecorator("isCursorPointer", {
                  initialValue: item.isCursorPointer,
                  rules: [
                    {
                      required: true,
                      message: "Please Provide noResultMessage!",
                    },
                  ],
                })(
                  <Select
                    style={{ width: "250px" }}
                    onSelect={(value) =>
                      this.handleFieldChange(value, "isCursorPointer")
                    }
                  >
                    <Option value="true">TRUE</Option>
                    <Option value="false">FALSE</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="DownloadXLXS :">
                {getFieldDecorator("downloadXLXS", {
                  initialValue: item.downloadXLXS,
                  rules: [{ required: true, message: "Please Select Option!" }],
                })(
                  <Select
                    style={{ width: "250px" }}
                    onSelect={(value) =>
                      this.handleFieldChange(value, "downloadXLXS")
                    }
                  >
                    <Option value="true">TRUE</Option>
                    <Option value="false">FALSE</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="DownloadExcelPayload :">
                <Form.Item label="PortName :">
                  {getFieldDecorator("portName", {
                    initialValue: item.downloadExcelPayload.portName,
                    rules: [
                      { required: true, message: "Please Provide PortName!" },
                    ],
                  })(
                    <Input
                      onChange={(e) =>
                        this.handleChange(
                          e.target.value,
                          "downloadExcelPayload",
                          "portName"
                        )
                      }
                    />
                  )}
                </Form.Item>
                <Form.Item label="Name :">
                  {getFieldDecorator("name", {
                    initialValue: item.downloadExcelPayload.name,
                    rules: [
                      { required: true, message: "Please Provide Name!" },
                    ],
                  })(
                    <Input
                      onChange={(e) =>
                        this.handleChange(
                          e.target.value,
                          "downloadExcelPayload",
                          "name"
                        )
                      }
                    />
                  )}
                </Form.Item>
                <Form.Item label="Consumption :">
                  {getFieldDecorator("consumption", {
                    initialValue: item.downloadExcelPayload.consumption,
                    rules: [
                      {
                        required: true,
                        message: "Please Provide Consumption!",
                      },
                    ],
                  })(
                    <Input
                      onChange={(e) =>
                        this.handleChange(
                          e.target.value,
                          "downloadExcelPayload",
                          "consumption"
                        )
                      }
                    />
                  )}
                </Form.Item>
              </Form.Item>
              <Form.Item label="Style :">
                <Form.Item label="TextAlign :">
                  <Select
                    style={{ width: "250px", showArrow: true }}
                    defaultValue={item.style.textAlign}
                    onSelect={(value) =>
                      this.handleChange(value, "style", "textAlign")
                    }
                  >
                    <Option value="left">Left</Option>
                    <Option value="center">Center</Option>
                    <Option value="right">Right</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="FontWeight :">
                  <Select
                    style={{ width: "250px", showArrow: true }}
                    defaultValue={item.style.fontWeight}
                    onSelect={(value) =>
                      this.handleChange(value, "style", "fontWeight")
                    }
                  >
                    <Option value="normal">Normal</Option>
                    <Option value="bold">Bold</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="EvenRowColor :">
                  <Select
                    style={{ width: "250px", showArrow: true }}
                    placeholder="select or enter color"
                    onSelect={(value) =>
                      this.handleChange(value, "style", "evenRowColor")
                    }
                  >
                    {evenRowColor.map((colorItem, index) => (
                      <Option
                        style={{ color: evenRowColor[index], fontSize: "20px" }}
                        value={colorItem}
                      >
                        {" "}
                        {colorItem}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="OddRowColor :">
                  <Select
                    style={{ width: "250px", showArrow: true }}
                    placeholder="select or enter color"
                    onSelect={(value) =>
                      this.handleChange(value, "style", "oddRowColor")
                    }
                  >
                    {oddRowColor.map((colorItem, index) => (
                      <Option
                        style={{ color: oddRowColor[index], fontSize: "20px" }}
                        value={colorItem}
                      >
                        {" "}
                        {colorItem}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
            </Form>
            {/* <div className="configButton"> */}
            <Button
              type="primary"
              style={{ marginLeft: "60px" }}
              onClick={(e) => this.tableSave(e)}
            >
              ValidateJson
            </Button>
            {/* </div> */}
          </div>
        ) : null}
      </div>
    );
  }
}
export default Form.create()(TableConfigCustomization);
