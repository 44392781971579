import React, { Component } from 'react'
import { Input, Form, Col, Row, Select, Upload, Button, Icon, message, Tabs } from "antd"
import axios from 'axios';

import localStorage from "../../../../utils/localStorage";
import { StyledApplication } from './style'

let id = 0;
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input

class XMLConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UniqueKeysList: [],
      uploadFileData: {},
      selectdeFileUploadData: {},
      filename: "",
      dataObject: ""
    }
  }

  //UniqueKeys for Multi Select Options
  getUniqueKeysList = (payload) => {
    const accessToken = localStorage.get('accessToken')
    const fileObject = {
      method: 'POST',
      url: `/api/xmlformat/uniqueColumnsByXmlData`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    }
    axios(fileObject)
      .then((response) => {
        this.setState({ UniqueKeysList: response.data });
      })
      .catch(function () {

      })
  }


  handleChange = info => {
    const { status } = info.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  //  * @author sapna.m
  //  * @description XMLConfigUpload function is used to read file data 
  //  * @return Get content of file
  XMLConfigUpload = (file) => {
    const reader = new FileReader();
    reader.onload = e => {
      this.setState({
        uploadFileData: e.target.result,
        filename: file.name
      })
    }
    reader.readAsText(file);
    const isLt2M = file.size / 1024 / 1024 < 10;
    const isXML = file.type === "text/xml";
    if (!isXML) {
      message.error("You can only upload xml file!");
    }
    if (!isLt2M) {
      message.error("xml file must be smaller than 10MB!");
    }
    return false

    //return isLt2M && isXML;

  }


  //  * @author sapna.m
  // * @description This function is used to Save Form data 
  SaveConfigurationUpload = (e) => {
    e.preventDefault();
    this.props.form.validateFields(["Name", "DataObject", "XmlFormat", "PayloadType", "UniqueKeys",
      "ObjecDatet1", "XMLData"],

      (err, values) => {
        if (!err) {
          const { uploadFileData } = this.state
          let fileObject = {
            "Name": values.Name,
            "DataObject": values.DataObject,
            "XmlFormat": uploadFileData,
            "XmlTypeId": "1",
            "PayloadType": values.PayloadType,
            "UniqueKeys": values.UniqueKeys,
            "child": values.DateObject1 && Array.isArray(values.DateObject1)
              && values.DateObject1.map((data, index) => {
                return {
                  "DataObject": data,
                  "xmlData": values.XMLData && Array.isArray(values.XMLData) && values.XMLData[index] ?
                    values.XMLData[index] : ""
                }
              })
          }
          this.saveConfigurationAPi(fileObject);
          this.props.form.resetFields();
          this.setState({
            uploadFileData: {},
            filename: ""
          })
        }
      });
  }


  //APi for save  
  saveConfigurationAPi = (payload) => {
    const accessToken = localStorage.get('accessToken')
    const fileObject = {
      method: 'POST',
      url: `/api/xmlformat/create`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    }
    axios(fileObject)
      .then((res) => {
        message.success("success")
        this.props.form.resetFields();
      })
      .catch(function (error) {
        message.error("Error")
      })
  }

  SelectedFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = e => {
      this.setState({
        selectdeFileUploadData: e.target.result
      })
    }
    reader.readAsText(file);

    const isLt2M = file.size / 1024 / 1024 < 10;
    const isXML = file.type === "text/xml";
    if (!isXML) {
      message.error("You can only upload xml file!");
    }
    if (!isLt2M) {
      message.error("xml file must be smaller than 10MB!");
    }
    return isLt2M && isXML;
  }

  handleSelectChange = info => {
    const { status } = info.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  _SaveDataEntryApi = (payload) => {
    const accessToken = localStorage.get('accessToken')
    const fileObject = {
      method: 'POST',
      url: `/api/SelectedFile/DataEntryUpload`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    }
    axios(fileObject)
      .then((res) => {
        this.props.form.resetFields();
      })
      .catch(function () {

      })
  }

  saveDataEntryUpload = (e) => {
    e.preventDefault();
    this.props.form.validateFields(["SelectedFile", "xmlFormat"],
      (err, values) => {
        if (!err) {
          const { selectdeFileUploadData } = this.state
          let fileObject = {
            "xmlFormat": selectdeFileUploadData,
            "SelectedFile": values.SelectedFile
          }
          this._SaveDataEntryApi(fileObject);
        }
      });
  }

  //  * @author sapna.m
  // * @description This Function is used for getting uniqueKeys for multi select option  
  handleDataObject = (e) => {
    e.preventDefault();
    this.props.form.validateFields(["DataObject"],
      (err, values) => {
        if (!err) {
          const { uploadFileData } = this.state
          let fileObject = {
            "DataObject": values.DataObject,
            "XmlFormat": uploadFileData,
          }
          this.getUniqueKeysList(fileObject); //uniqueKeys List will get from this function 
        }
      });

  }


  remove = k => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    if (keys.length === 1) {
      return;
    }
    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };
  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);
    form.setFieldsValue({
      keys: nextKeys,
    });
  };


  render() {
    const { UniqueKeysList } = this.state;

    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
      },
    };

    //formItems is for Adding Multiple Fields 
    getFieldDecorator('keys', { initialValue: [] })
    const keys = getFieldValue('keys')
    const formItems = keys.map((k, index) => (
      <Row type="flex" justify="center">
        <Col span={5}>
          <Form.Item
            label={'DateObject'}
            required={false}
            key={k}>
            {getFieldDecorator(`DateObject1[${k}]`, {
              initialValue: '',
              validateTrigger: ['onChange', 'onBlur']
            })(
              <Input style={{ width: '200px' }} placeholder="DateObject" />
            )}

          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label={'XMLData'}
            required={false}
            key={k}>
            {getFieldDecorator(`XMLData[${k}]`, {
              initialValue: '',
              validateTrigger: ['onChange', 'onBlur']
            })(
              <TextArea rows={2} style={{ width: '85%' }} placeholder="XMLData" />
            )}

            {keys.length > 1 ? (
              <Icon style={{ marginLeft: '20px' }}
                className="dynamic-delete-button"
                type="minus-circle-o"
                disabled={keys.length === 1}
                onClick={() => this.remove(k)}
              />
            ) : null}

          </Form.Item>
        </Col>

      </Row>
    ))


    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 170 }}>
        <Tabs defaultActiveKey="1" >
          {/* * @author sapna.m
              * @description This TabPane is for Configuration of form
          */}
          <TabPane tab="Configuration" key="1" >
            <div>
              <Form layout="horizontal"  {...formItemLayout}>
                <Row gutter={16}>
                  <Col span={24}>

                    <Form.Item label="Name :" >
                      {getFieldDecorator("Name", {
                        rules: [
                          { required: true, message: "Please enter Name" },
                        ],
                      })(
                        <Input
                          style={{ width: "350px" }}
                          placeholder="Please enter" />
                      )}
                    </Form.Item>
                    <Form.Item label="Upload File :">
                      {getFieldDecorator("XmlFormat", {
                        rules: [
                          { required: true, message: "please Upload file" },
                        ],
                      })(
                        <Upload
                          name="file"
                          accept="text/xml"
                          multiple="false"
                          beforeUpload={this.XMLConfigUpload}
                          onChange={this.handleChange}>
                          <Button style={{ width: "350px" }}>
                            <Icon type="upload" /> Click to Upload
                          </Button>
                        </Upload>
                      )}
                    </Form.Item>
                    <Form.Item label="DataObject :" >
                      {getFieldDecorator("DataObject", {
                        rules: [
                          { required: true, message: "Please enter DataObject" },
                        ],
                      })(
                        <Input
                          style={{ width: "350px" }}
                          placeholder="Please enter"
                          onBlur={this.handleDataObject}
                        />
                      )}
                    </Form.Item>
                    <Form.Item label="PayloadType :" >
                      {getFieldDecorator("PayloadType", {
                        rules: [
                          { required: true, message: "Please enter PayloadType" },
                        ],
                      })(
                        <Select
                          style={{ width: "350px" }}
                          placeholder="Please select"
                        >
                          <Option value="Request">Request</Option>
                          <Option value="Response">Response</Option>
                        </Select>
                      )}
                    </Form.Item>
                    <Form.Item label="UniqueKeys :" >
                      {getFieldDecorator("UniqueKeys", {
                        rules: [
                          { required: true, message: "Please select UniqueKeys" },
                        ],
                      })(
                        <Select
                          style={{ width: "350px" }}
                          mode="multiple"
                          placeholder="Please select"
                        >
                          {UniqueKeysList.map((uniquekey) => {
                            return (
                              <Option value={uniquekey}>
                                {uniquekey}
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </Form.Item>

                    <div>

                      <Form.Item {...formItemLayoutWithOutLabel} >
                        <Button type="dashed" onClick={this.add} style={{ marginLeft: "15%", marginTop: "40px" }}>
                          <Icon type="plus" /> Add field
                        </Button>
                      </Form.Item>
                      <div style={{ marginTop: "40px" }}> {formItems}</div>
                    </div>
                  </Col>
                </Row>
                <Button type="primary" style={{ marginLeft: "45%", marginTop: "30px", width: "100px" }}
                  onClick={this.SaveConfigurationUpload}>Save</Button>
              </Form>

            </div>
          </TabPane>
          {/* * @author sapna.m
              * @description This TabPane is for DataEntry of form
          */}
          <TabPane tab="Data entry" key="2">
            <Form layout="horizontal" hideRequiredMark {...formItemLayout}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="SelectedFile :" >
                    {getFieldDecorator("SelectedFile", {
                      rules: [
                        { required: true, message: "Please enter SelectedFile" },
                      ],
                    })(
                      <Select
                        style={{ width: "50%" }}
                        placeholder="Please select" >
                        <Option value="file1">file1</Option>
                        <Option value="file2">file2</Option>
                        <Option value="file3">file3</Option>
                        <Option value="file4">file4</Option>
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item label="Upload File :">
                    {getFieldDecorator("xmlFormat", {
                      rules: [
                        { required: true, message: "please Upload file" },
                      ],
                    })(
                      <Upload
                        name="file"
                        accept="text/xml"
                        multiple="false"
                        beforeUpload={this.SelectedFileUpload}
                        onChange={this.handleSelectChange}>

                        <Button style={{ width: "300px" }}>
                          <Icon type="upload" /> Click to Upload
                        </Button>
                      </Upload>
                    )}

                  </Form.Item>
                </Col>
              </Row>
              <Button type="primary"
                style={{ marginLeft: "45%", marginTop: "30px", width: "100px" }}
                onClick={this.saveDataEntryUpload}>Save</Button>
            </Form>
          </TabPane>
        </Tabs>
      </StyledApplication>

    )
  }
}

export default (Form.create()(XMLConfiguration))
