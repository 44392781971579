import styled from "styled-components";
import { Layout, Menu } from "antd";

const { Header } = Layout;

export const StyledContent = styled.div`
 margin-top:-5px
 flex-grow:2
`;
export const StyledHeaderLayout = styled(Header)`
  position: fixed;
  z-index: 1;
  height: 65px;
  width: 100%;
  background-color: ${props => props.theme.main};
`;

export const StyledMenu = styled(Menu)`
  line-height: 64px;
  white-space: normal;
  border-right: 0;
`;
