import { fromJS } from 'immutable'

import { types } from './types'

export const getConditionList = () => {
  return {
    type: types.GET_CONDITION_LIST
  }
}

export const getConditionListSuccess = (response) => {
  return {
    type: types.GET_CONDITION_LIST_SUCCESS,
    response
  }
}

// export const createDevice = (payload) => {
//   return {
//     type: types.CREATE_DEVICE,
//     payload
//   }
// }
// export const updateDevice = (payload) => {
//   return {
//     type: types.UPDATE_DEVICE,
//     payload
//   }
// }
const initialState = fromJS({
  conditionList: []
})

const condition = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CONDITION_LIST_SUCCESS: {
      return state.set('conditionList', action.response)
    }
    default: return state
  }
}

export default condition
