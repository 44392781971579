import { PrimaryButton } from './forms'
import { StyledTable } from './table'
import { StyledWrapper,
  StyledFloatLeft,
  StyledFloatRight,
  StyledWrapperRight,
  StyledWrapperLeft,
  StyledHeading,
  StyledSider,
  StyledMenu } from './layout'

export default {
  layout: {
    StyledWrapper,
    StyledFloatLeft,
    StyledFloatRight,
    StyledWrapperRight,
    StyledWrapperLeft,
    StyledHeading,
    StyledSider,
    StyledMenu

  },
  form: {
    PrimaryButton
  },
  table: {
    StyledTable
  }
}
