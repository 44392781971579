import React, { Fragment } from "react";
import _ from "lodash";
import { connect } from 'react-redux'
import Highlighter from 'react-highlight-words';
import {
    Button,
    Drawer,
    Table,
    Row,
    Col,
    Form,
    Input,
    Icon,
    Popconfirm,
    Select,
    Switch,
    Collapse,
    message,
    Divider
} from "antd";
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import DeviceCondition from "../Device/DeviceCondition";
import DeviceTagReference from "../Device/DeviceTagReference";
import {
    _getDeviceTypesList,
    _getDeviceParameterBasedOnType,
    _getDeviceParameterType,
    _getDeviceParameterBasedonDevice,
    _getDeviceBasedOnAssetID,
    _saveDeviceData,
    _updateDeviceData,
    _getParameterGroupList,
    _tagReferenceList,
    _saveConditionTagReference,
    _deleteDeviceParameters,
    _deleteDevice,
    _conditionList
} from './APICalls'
import {
    AddItemDiv,
    AssetDeviceFlowSearch,
    BadgeStyle,
    SpanItem,
    DrawerFooter,
    StyledApplication,
    StyledTable,
    lightTheme, 
} from "../AssetDeviceList/style";
import {
    currentDevice,
    setDeviceBreadcrumbs
} from '../../../modules/device/device.duck'
import { getDeviceBreadcrumbList } from "../../../selectors/device"

const { Option } = Select
const { Panel } = Collapse

const customPanel = {
    background: "#f7f7f7",
    borderRadius: 4,
    marginBottom: 24,
    border: 0,
    overflow: "hidden"
};

class DeviceComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            editObj: {},
            datasource: [],
            deviceTypeList: [],
            visibleConfiguration: false,
            deviceParameterBasedonDevice: [],
            conditionList: [],
            tagReferenceList: [],
            selectedDeviceParameterId: '',
            parameterGroupList: [],
            parentDevice: "",
            equipmentType: ""
        };
    }

    componentDidMount() {
        this._getDatasource(this.props.data, this.props.currentDevice)
        this._getDeviceType()
        this._getParameterGroupList()
        this._getTagReferenceList()
        this._getConditionList();
    }

    componentDidUpdate(preprops) {
        if (preprops.data !== this.props.data || preprops.currentDevice !== this.props.currentDevice) {
            this._getDatasource(this.props.data, this.props.currentDevice)
        }
    }

    _getTagReferenceList = async () => {
        let tagReferenceList = await _tagReferenceList()
        this.setState({
            tagReferenceList
        })
    }

    _getConditionList = async () => {
        let conditionList = await _conditionList()
        this.setState({
            conditionList
        })
    }

    _getParameterGroupList = async () => {
        let parameterGroupList = await _getParameterGroupList()
        this.setState({
            parameterGroupList
        })
    }

    _getDeviceType = async () => {
        let deviceTypeList = await _getDeviceTypesList()
        this.setState({
            deviceTypeList
        })
    }

    _getDatasource = async (data) => {
        let datasource = await _getDeviceBasedOnAssetID(data)
        this.setState({
            data,
            datasource
        })
    }

    editRowonRowEdit = (e, row) => {
        e.preventDefault();
        this.setState({
            deviceObject: row,
            visible: true
        });
    };

    onClose = () => {
        this.setState({
            visible: false
        })
    }

    editConfigRow = async deviceObject => {
        let { DeviceType, Id } = deviceObject
        let deviceParametersBasedOnType = await _getDeviceParameterBasedOnType(DeviceType);
        let deviceParameterBasedonDevice = await _getDeviceParameterBasedonDevice(Id);;
        let parameterType = await _getDeviceParameterType();
        this.setState({
            deviceParametersBasedOnType,
            deviceParameterBasedonDevice,
            deviceId: Id,
            parameterType,
            deviceObject,
            visibleConfiguration: true
        })
    };

    openAssetDrawer = (e) => {
        e.preventDefault()
        this.setState({
            deviceObject: {},
            visible: true
        })
    }

    onCloseVisibleConfiguration = () => {
        this.setState({
            visibleConfiguration: false
        })
    }

    createDevice = e => {
        let { data } = this.state
        e.preventDefault();
        this.props.form.validateFields(async (error, values) => {
            if (!error) {
                let payload = {
                    ...values,
                    EquipmentType: data.parentDeviceType,
                    ParentEquipment: data.parentDeviceId
                }
                let createDeviceResponse = await _saveDeviceData(payload)
                if (createDeviceResponse) {
                    message.success(createDeviceResponse.message)
                    this.setState({ visible: false });
                    this._getDatasource(this.props.data)
                }
            }
        });
    };

    updateDevice = e => {
        let { data } = this.state
        e.preventDefault();
        this.props.form.validateFields(async (error, values) => {
            if (!error) {
                let payload = {
                    ...values,
                    EquipmentType: data.parentDeviceType,
                    ParentEquipment: data.parentDeviceId
                }
                let updateDeviceResponse = await _updateDeviceData(payload)
                if (updateDeviceResponse) {
                    message.success(updateDeviceResponse.message)
                    this.setState({ visible: false });
                    this._getDatasource(this.props.data)
                }

            }
        });
    };

    addDeviceParamter = () => {
        const {
            deviceParameterBasedonDevice,
            selectedDeviceParameterId
        } = this.state;
        if (
            selectedDeviceParameterId
        ) {
            let deviceStatus = false;
            deviceParameterBasedonDevice.forEach(usedDP => {
                if (usedDP.ParameterId === selectedDeviceParameterId) {
                    deviceStatus = true;
                }
            });
            if (!deviceStatus) {
                const deviceParameter = {
                    "AliasCode": "",
                    "ParameterConditions": [],
                    "ParameterGroup": "",
                    "ParameterId": selectedDeviceParameterId,
                    "ParameterReferences": [],
                    "ParameterType": ""
                };
                deviceParameterBasedonDevice.push(deviceParameter);
                this.setState({
                    deviceParameterBasedonDevice,
                    selectedDeviceParameterId: ""
                });
            } else {
                message.error("Equipment parameter already exist");
            }
        } else {
            message.error("Please select device parameter");
        }
    };

    onChange = value => {
        this.setState({ selectedDeviceParameterId: value });
    };

    parameterTypeonChange = (value, parameterId) => {
        const { deviceParameterBasedonDevice } = this.state;
        deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
            if (deviceParameter.ParameterId === parameterId) {
                deviceParameterBasedonDevice[key].ParameterType = value;
            }
        });

        this.setState({ deviceParameterBasedonDevice });
    }

    parameterGrouponChange = (value, parameterId) => {
        const { deviceParameterBasedonDevice } = this.state;
        deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
            if (deviceParameter.ParameterId === parameterId) {
                deviceParameterBasedonDevice[key].ParameterGroup = value;
            }
        });

        this.setState({ deviceParameterBasedonDevice });
    }

    aliasOnChange = (value, parameterId) => {
        const { deviceParameterBasedonDevice } = this.state;
        deviceParameterBasedonDevice && deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
            if (deviceParameter.ParameterId === parameterId) {
                deviceParameterBasedonDevice[key].AliasCode = value;
            }
        });

        this.setState({ deviceParameterBasedonDevice });
    }

    saveConditonList = (updatedConditionList, parameterId) => {
        const { deviceParameterBasedonDevice } = this.state;

        deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
            if (deviceParameter.ParameterId === parameterId) {
                deviceParameterBasedonDevice[key].ParameterConditions = updatedConditionList;
            }
        });
        this.setState({ deviceParameterBasedonDevice });
    };

    saveTagReferenceList = (updatedTRList, parameterId) => {
        const { deviceParameterBasedonDevice } = this.state;

        deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
            if (deviceParameter.ParameterId === parameterId) {
                deviceParameterBasedonDevice[key].ParameterReferences = updatedTRList;
            }
        });
        this.setState({ deviceParameterBasedonDevice });
    };

    saveDeviceParameter = (saveDeviceParameter) => {
        if (saveDeviceParameter) {
            saveDeviceParameter && saveDeviceParameter.ParameterReferences && saveDeviceParameter.ParameterReferences.forEach((parameterAlias, index) => {
                if (parameterAlias.isNew) {
                    delete parameterAlias.isNew;
                    delete parameterAlias.editable;
                    saveDeviceParameter.ParameterReferences[index] = parameterAlias;
                }
            });
            saveDeviceParameter && saveDeviceParameter.ParameterConditions && saveDeviceParameter.ParameterConditions.forEach((conditions, index) => {
                if (conditions.isNew) {
                    delete conditions.isNew;
                    delete conditions.editable;
                    saveDeviceParameter.ParameterConditions[index] = conditions;
                }
            });
            _saveConditionTagReference(saveDeviceParameter, this.state.deviceId);
        }
    }

    _deleteDeviceParameter = async (parameterId) => {
        let deleteParameterStatus = await _deleteDeviceParameters(parameterId)
        if (deleteParameterStatus) {
            message.warn(deleteParameterStatus.message)
            this.editConfigRow(this.state.deviceObject)
        }
    }

    _deleteDevice = async (deviceObject) => {
        let deleteStatus = await _deleteDevice(deviceObject.Id)
        if (deleteStatus) {
            message.warn(deleteStatus.message)
            this._getDatasource(this.props.data)
        }
    }

    searchDeviceCode = async value => {
        let deviceDatasource = await _getDeviceBasedOnAssetID(this.props.data)
        const datasource = deviceDatasource && Array.isArray(deviceDatasource) && deviceDatasource.filter(function (device) {
            return (
                device.Name
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||
                device.DeviceCode
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
            );
        });
        this.setState({ datasource });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    navigateDeviceNextPage = (e, row) => {
        let { deviceBreadcrumbList } = this.props
        deviceBreadcrumbList.push(row)
        this.props.actions.setDeviceBreadcrumbs(deviceBreadcrumbList)
        this.props.actions.currentDevice(row)
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let {
            datasource,
            visible,
            deviceObject,
            deviceParameterBasedonDevice,
            conditionList,
            tagReferenceList,
            deviceParametersBasedOnType,
            selectedDeviceParameterId,
            data,
            deviceTypeList,
            parameterType,
            parameterGroupList
        } = this.state;

        return (
            //<StyledApplication style={{ minHeight: window.innerHeight - 226 }}>
            <div style={{ margin: "20px 0px" }}>
                <AddItemDiv>
                    <AssetDeviceFlowSearch
                        placeholder="Enter a string to reduce results"
                        onSearch={value => this.searchDeviceCode(value)}
                    />
                    <div>
                        <BadgeStyle count={datasource ? datasource.length : 0} />
                        <SpanItem>Items</SpanItem>
                        <Button
                            icon="plus"
                            type="primary"
                            onClick={(e) => this.openAssetDrawer(e, data.parentDeviceType)}
                        >
                            Add
                            <span style={{ padding: "0 5px" }}>
                                {data && data.parentDeviceType
                                    ? _.startCase(_.toLower(data.parentDeviceType))
                                    : _.startCase(_.toLower(''))}
                            </span>
                        </Button>

                    </div>
                </AddItemDiv>

                {/* <StyledTable theme={lightTheme}> */}
                <div className="Table">
                    <Table
                        columns={[
                            {
                                title: data && data.parentDeviceType
                                    ? `${_.startCase(_.toLower(data.parentDeviceType))} Code`
                                    : `${_.startCase(_.toLower(''))} Code`,
                                dataIndex: "DeviceCode",
                                key: "DeviceCode",
                                onCell: record => {
                                    return {
                                        onClick: e => {
                                            // this.editRowonRowEdit(e, record);
                                        }
                                    };
                                },
                                render: (text, row, key) => {
                                    return {
                                        props: {
                                            // style: {
                                            //     background:
                                            //         Math.abs(key % 2) === 1 ? "rgb(251, 250, 250)" : "#fff"
                                            // }
                                        },
                                        children: (
                                            <span
                                                // style={{ color: "#1890ff", cursor: "pointer" }}
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => this.navigateDeviceNextPage(e, row)}
                                            >
                                                {text}
                                            </span>
                                        )
                                    };
                                }
                            },
                            {
                                title: data && data.parentDeviceType
                                    ? `${_.startCase(_.toLower(data.parentDeviceType))} Name`
                                    : `${_.startCase(_.toLower(''))} Name`,
                                dataIndex: "Name",
                                key: "Name",
                                onCell: record => {
                                    return {
                                        onClick: e => {
                                            this.editRowonRowEdit(e, record);
                                        }
                                    };
                                },
                                render: (text, row, key) => {
                                    return {
                                        props: {
                                            // style: {
                                            //     background:
                                            //         Math.abs(key % 2) === 1 ? "rgb(251, 250, 250)" : "#fff"
                                            // }
                                        },
                                        children: <span>{text}</span>
                                    };
                                }
                            },
                            {
                                title: data && data.parentDeviceType
                                    ? `${_.startCase(_.toLower(data.parentDeviceType))} Type`
                                    : `${_.startCase(_.toLower(''))} Type`,
                                dataIndex: "DeviceType",
                                key: "DeviceType",
                                onCell: record => {
                                    return {
                                        onClick: e => {
                                            this.editRowonRowEdit(e, record);
                                        }
                                    };
                                },
                                render: (text, row, key) => {
                                    let name = deviceTypeList &&
                                        Array.isArray(deviceTypeList) &&
                                        deviceTypeList.filter((deviceType) => {
                                            return deviceType.Id === text
                                        })
                                    return {
                                        props: {
                                            // style: {
                                            //     background:
                                            //         Math.abs(key % 2) === 1 ? "rgb(251, 250, 250)" : "#fff"
                                            // }
                                        },
                                        children: <span>{name && name[0] && name[0].Name ? name[0].Name : ""}</span>

                                    }
                                },
                            },
                            {
                                title: data && data.parentDeviceType
                                    ? `${_.startCase(_.toLower(data.parentDeviceType))} TimeSeries`
                                    : `${_.startCase(_.toLower(''))} TimeSeries`,
                                dataIndex: "TimeSeries",
                                key: "TimeSeries",
                                render: (text, row, key) => {
                                    return {
                                        props: {
                                            // style: {
                                            //     background:
                                            //         Math.abs(key % 2) === 1 ? "rgb(251, 250, 250)" : "#fff"
                                            // }
                                        },
                                        children: (
                                            <span>{text ? text.toString() : text.toString()}</span>
                                        )
                                    };
                                },
                                // onCell: record => {
                                //     return {
                                //         onClick: e => {
                                //             this.editRowonRowEdit(e, record);
                                //         }
                                //     };
                                // }
                            },
                            {
                                title: "Actions",
                                dataIndex: "",
                                key: "",
                                render: (text, row, key) => {
                                    return {
                                        props: {
                                            // style: {
                                            //     background:
                                            //         Math.abs(key % 2) === 1 ? "rgb(251, 250, 250)" : "#fff"
                                            // }
                                        },
                                        children: (
                                            <button
                                                type="button"
                                                // className="ant-btn"
                                                style={{ marginRight: "5px" }}
                                                onClick={() => {
                                                    this.editConfigRow(row);
                                                }}
                                            >
                                                <Icon type="unordered-list" />
                                            </button>
                                        )
                                    };
                                }
                            },
                            {
                                title: "Action",
                                dataIndex: "_key",
                                key: "_key",
                                render: (text, row, key) => {
                                    return {
                                        props: {
                                            // style: {
                                            //     background:
                                            //         Math.abs(key % 2) === 1 ? "rgb(251, 250, 250)" : "#fff"
                                            // }
                                        },
                                        children: (
                                            <div>
                                                {/* <a> */}
                                                <Icon
                                                    type="edit"
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        this.editRowonRowEdit(e, row);
                                                    }}
                                                />
                                                {/* </a> */}
                                                <Divider type="vertical" />
                                                {/* <a> */}
                                                <Popconfirm
                                                    title="Are you sure delete Equipment?"
                                                    onConfirm={e => {
                                                        e.stopPropagation();
                                                        this._deleteDevice(row)
                                                    }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Icon
                                                        theme="twoTone" twoToneColor="red"
                                                        type="delete"
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                        }}
                                                    />
                                                </Popconfirm>
                                                {/* </a> */}
                                            </div>
                                        )
                                    };
                                }
                            }
                        ]}
                        dataSource={datasource}
                        size="large"
                        pagination={
                            datasource && Array.isArray(datasource) && datasource.length > 10
                                ? {
                                    pageSize: "10"
                                }
                                : false
                        }
                        locale={{ emptyText: "No Data" }}
                    />
                {/* </StyledTable> */}
                </div>

                <Drawer
                    title={
                        deviceObject && !deviceObject.Id
                            ? data && data.parentDeviceType
                                ? `Create ${_.startCase(_.toLower(data.parentDeviceType))}`
                                : `Create ${''}`
                            : data && data.parentDeviceType
                                ? `Update ${_.startCase(_.toLower(data.parentDeviceType))}`
                                : `Update ${_.startCase(_.toLower(''))}`
                    }
                    placement="right"
                    width={400}
                    onClose={this.onClose}
                    visible={visible}
                >
                    <Form layout="vertical" hideRequiredMark>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label={
                                        data && data.parentDeviceType
                                            ? `${_.startCase(_.toLower(data.parentDeviceType))} Code`
                                            : `${_.startCase(_.toLower(''))} Code`
                                    }
                                >
                                    {getFieldDecorator("DeviceCode", {
                                        initialValue: deviceObject ? deviceObject.DeviceCode : "",
                                        rules: [
                                            {
                                                required: true,
                                                message: data && data.parentDeviceType
                                                    ? `Please input your ${_.startCase(
                                                        _.toLower('')
                                                    )} Code`
                                                    : `Please enter ${_.startCase(
                                                        _.toLower('')
                                                    )} Code`
                                            }
                                        ]
                                    })(<Input
                                        placeholder="Please enter Equipment Code"
                                    //   disabled={deviceObject ? !!deviceObject.DeviceType : false}
                                    />)}
                                </Form.Item>
                                <Form.Item
                                    label={
                                        data && data.parentDeviceType
                                            ? `${_.startCase(_.toLower(data.parentDeviceType))} Name`
                                            : `${_.startCase(_.toLower(''))} Name`
                                    }
                                >
                                    {getFieldDecorator("Name", {
                                        initialValue: deviceObject ? deviceObject.Name : "",
                                        rules: [
                                            {
                                                required: true,
                                                message: data && data.parentDeviceType
                                                    ? `Please input your ${_.startCase(
                                                        _.toLower('')
                                                    )} Name`
                                                    : `Please enter ${_.startCase(
                                                        _.toLower('')
                                                    )} Name`
                                            }
                                        ]
                                    })(<Input placeholder="Please enter Equipment name" />)}
                                    {getFieldDecorator("Id", {
                                        initialValue: deviceObject ? deviceObject.Id : "",
                                    })(<Input style={{ display: "none" }} />)}
                                </Form.Item>
                                <Form.Item
                                    label={
                                        data && data.parentDeviceType
                                            ? `${_.startCase(_.toLower(data.parentDeviceType))} Type`
                                            : `${_.startCase(_.toLower(""))} Type`
                                    }
                                >
                                    {getFieldDecorator("DeviceType", {
                                        initialValue: deviceObject ? deviceObject.DeviceType : "",
                                        rules: [
                                            {
                                                required: true,
                                                message: data && data.parentDeviceType
                                                    ? `Please input your ${_.startCase(
                                                        _.toLower('')
                                                    )} Type`
                                                    : `Please enter ${_.startCase(
                                                        _.toLower('')
                                                    )} Type`
                                            }
                                        ]
                                    })(
                                        <Select
                                            placeholder="Please enter Equipment Type"
                                            defaultValue={deviceObject ? deviceObject.DeviceType : ""}
                                            style={{ width: 450 }}
                                            onChange={this.handleChange}
                                            disabled={deviceObject ? !!deviceObject.DeviceType : false}
                                        >
                                            {deviceTypeList
                                                ? deviceTypeList.map(deviceType => (
                                                    <Option value={deviceType.Id}>
                                                        {deviceType.Name}
                                                    </Option>
                                                ))
                                                : null}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label={
                                        data && data.parentDeviceType
                                            ? `${_.startCase(_.toLower(data.parentDeviceType))} TimeSeries`
                                            : `${_.startCase(_.toLower(''))} TimeSeries`
                                    }
                                >
                                    {getFieldDecorator("TimeSeries", {
                                        initialValue: deviceObject && deviceObject.TimeSeries ? deviceObject.TimeSeries : false,
                                        valuePropName: "checked"
                                    })(<Switch />)}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator("Asset", {
                                        initialValue: deviceObject && deviceObject.Asset ? deviceObject.Asset :
                                            this.props.data && this.props.data.parentAssetId ? this.props.data.parentAssetId : ""
                                    })(<Input style={{ display: "none" }} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                    <DrawerFooter>
                        <Button type="primary" onClick={this.onClose} style={{ backgroundColor:"red" ,marginRight: 8 }}>
                            Cancel
                        </Button>
                        {deviceObject && deviceObject.Id === undefined ? (
                            <Button onClick={this.createDevice} type="primary">
                                {data && data.parentDeviceType
                                    ? `Create ${_.startCase(_.toLower(data.parentDeviceType))}`
                                    : `Create ${_.startCase(_.toLower(''))}`}
                            </Button>
                        ) : (
                            <Button onClick={this.updateDevice} type="primary">
                                {data && data.parentDeviceType
                                    ? `Update ${_.startCase(_.toLower(data.parentDeviceType))}`
                                    : `Update ${_.startCase(_.toLower(''))}`}
                            </Button>
                        )}
                    </DrawerFooter>
                </Drawer>
                <Drawer
                    title="Configuration"
                    width={800}
                    onClose={this.onCloseVisibleConfiguration}
                    visible={this.state.visibleConfiguration}
                 
                >
                    <div>
                        <h4>Select Equipment Parameter :</h4>
                        <div>
                            <Select
                                defaultValue={selectedDeviceParameterId}
                                style={{ width: 300, marginRight: "15px" }}
                                onChange={this.onChange}
                                placeholder="Select Equipment Parameters"
                            >
                                {deviceParametersBasedOnType && Array.isArray(deviceParametersBasedOnType) &&
                                    deviceParametersBasedOnType.map((deviceParameters) => {                               
                                            return <Option value={deviceParameters.Id}>{deviceParameters.Name}</Option>
                                    })}
                            </Select>
                            <Button type="primary" onClick={this.addDeviceParamter}>
                                Add{" "}
                            </Button>
                        </div>
                        {deviceParameterBasedonDevice.map(device => {
                            let abc = deviceParametersBasedOnType &&
                                Array.isArray(deviceParametersBasedOnType) &&
                                deviceParametersBasedOnType.filter((deviceParameter) => {
                                    return deviceParameter.Id === device.ParameterId
                                })
                            return (
                                <Collapse
                                    bordered={false}
                                    style={{ marginTop: "20px" }}
                                    expandIcon={({ isActive }) => (
                                        <Icon type="caret-right" rotate={isActive ? 90 : 0}></Icon>
                                    )}
                                >
                                    <Panel
                                        header={
                                            <Fragment>
                                                <h4 style={{ display: "inline" }}>

                                                    {abc && abc[0] && abc[0].Name ? abc[0].Name : ""}
                                                    - {device.ParameterId}
                                                </h4>
                                                <Popconfirm
                                                    title="Are you sure delete Equipment Parameter?"
                                                    placement="top"
                                                    onConfirm={e => {
                                                        e.stopPropagation();
                                                        // this.deleteDeviceParameter(device.ParameterId);
                                                        this._deleteDeviceParameter(device.Id)
                                                    }}
                                                    onCancel={e => {
                                                        e.stopPropagation();
                                                    }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <button
                                                        type="button"
                                                        className="ant-btn"
                                                        style={{
                                                            float: "right",
                                                            marginRight: "5px",
                                                            marginTop: "-0.4vh"
                                                        }}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <Icon
                                                            type="delete"
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                            }}
                                                        />
                                                    </button>
                                                </Popconfirm>
                                            </Fragment>
                                        }
                                        style={customPanel}
                                    >
                                        <div style={{ padding: "0px 10px" }}>
                                            <Icon
                                                type="save"
                                                theme="twoTone"
                                                style={{
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "end"
                                                }}
                                                onClick={() => this.saveDeviceParameter(device)}
                                            ></Icon>
                                            <div>
                                                <label
                                                    style={{
                                                        display: "block",
                                                        fontWeight: "500",
                                                        marginBottom: "4px"
                                                    }}
                                                >
                                                    Parameter Type
                                                </label>
                                                <Select
                                                    defaultValue={device.ParameterType || undefined}
                                                    style={{ width: 300, marginRight: "15px" }}
                                                    onChange={e =>
                                                        this.parameterTypeonChange(e, device.ParameterId)
                                                    }
                                                    placeholder="Select ParameterType"
                                                >
                                                    {parameterType && parameterType.map((deviceParamsType, index) => {
                                                        return (
                                                            <Option value={deviceParamsType.Id}>
                                                                {deviceParamsType.Name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            <div>
                                                <label
                                                    style={{
                                                        display: "block",
                                                        fontWeight: "500",
                                                        marginBottom: "4px"
                                                    }}
                                                >
                                                    Parameter Group
                                                </label>
                                                <Select
                                                    defaultValue={device.ParameterGroup || undefined}
                                                    style={{ width: 300, marginRight: "15px" }}
                                                    onChange={e =>
                                                        this.parameterGrouponChange(e, device.ParameterId)
                                                    }
                                                    placeholder="Select Parameter Group"
                                                >
                                                    {parameterGroupList && Array.isArray(parameterGroupList) &&
                                                        parameterGroupList.map((parameterGroup) => {
                                                            return (
                                                                <Option value={parameterGroup.Id}>
                                                                    {parameterGroup.Name}
                                                                </Option>
                                                            );
                                                        })}
                                                </Select>
                                            </div>
                                            <div>
                                                <label
                                                    style={{
                                                        display: "block",
                                                        fontWeight: "500",
                                                        marginBottom: "4px"
                                                    }}
                                                >
                                                    Alias Code
                                                </label>
                                                <Input
                                                    value={device.AliasCode}
                                                    style={{ width: 300, marginRight: "15px" }}
                                                    placeholder="Input Alias Code"
                                                    onChange={e =>
                                                        this.aliasOnChange(e.target.value, device.ParameterId)
                                                    }
                                                />
                                            </div>

                                            <DeviceCondition
                                                conditionReference={device}
                                                conditionList={conditionList}
                                                saveConditonList={this.saveConditonList}
                                            />
                                            <DeviceTagReference
                                                conditionReference={device}
                                                tagReferenceList={tagReferenceList}
                                                saveTagReferenceList={this.saveTagReferenceList}
                                            />
                                        </div>
                                    </Panel>
                                </Collapse>
                            );
                        })}
                    </div>
                </Drawer>

            </div>
            // {/* </StyledApplication> */}
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                currentDevice,
                setDeviceBreadcrumbs
            },
            dispatch
        )
    }
}

const mapStateToProps = createStructuredSelector({
    deviceBreadcrumbList: getDeviceBreadcrumbList()
})

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(DeviceComponent));
