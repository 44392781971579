export const data = [
    {
      value: "fluid",
      label: "fluid",
    },
    {
      value: "fixed",
      label: "fixed",
    },
  ];
  
  export const dashboard = ["groups", "default"];
  
  export const langaguges = [
    {
      id: 1,
      langague: "English",
      key: "en",
    },
    {
      id: 2,
      langague: "Chinese",
      key: "zh",
    },
    {
      id: 3,
      langague: "Spanish",
      key: "es",
    },
  ];
  
  export const themes = [
    {
      id: 1,
      color: "#292961",
      backgroundColor: "#292961",
    },
    {
      id: 2,
      color: "#4b4ba3",
      backgroundColor: "#4b4ba3",
    },
    {
      id: 3,
      color: "#1a3652",
      backgroundColor: "#1a3652",
    },
    {
      id: 4,
      color: "#2261a1",
      backgroundColor: "#2261a1",
    },
    {
      id: 5,
      color: "#0d4524",
      backgroundColor: "#0d4524",
    },
    {
      id: 6,
      color: "#156b39",
      backgroundColor: "#156b39",
    },
    {
      id: 7,
      color: "#691a16",
      backgroundColor: "#691a16",
    },
    {
      id: 8,
      color: "#a62e21",
      backgroundColor: "#a62e21",
    },
    {
      id: 9,
      color: "#2e2e2e",
      backgroundColor: "#2e2e2e",
    },
  ];
  
  export const theme = [
    {
      id: 1,
      color: "lightTheme",
      backgroundColor: "#fff",
    },
    {
      id: 2,
      color: "darkTheme",
      backgroundColor: "#000",
    },
  ];
  