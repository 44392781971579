import { createSelector } from "reselect";

const allDeviceList = state => {
  return state.get("device").get("device");
};

const getDeviceListSelector = () =>
  createSelector(allDeviceList, state => {
    return state.get("allDeviceList");
  });

const getDeviceCMListSelector = () =>
  createSelector(allDeviceList, state => {
    return state.get("allCMDeviceList");
  });
const getCurrentDevice = () =>
  createSelector(allDeviceList, state => {
    return state.get("currentDevice");
  });
  
  const getDeviceBreadcrumbList = () =>
  createSelector(allDeviceList, state => {
    return state.get("devicebreadcrumbList");
  });
export { getDeviceListSelector, getDeviceCMListSelector, getCurrentDevice,getDeviceBreadcrumbList };
