import React, { Fragment } from "react";
import qureyString from "query-string";
import { message, Spin } from "antd";

import TrendSlider from "./trendSlider";
import { StyledTrends } from './styles'
import {
  getTrendData,
  getParameterList,
  getbookMarkByname,
} from "./ChartCalls";
import { constant } from "./constants";

export default class ViewTrends extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      trendData: [],
      singleView: true,
    };
  }

  abortController = new window.AbortController();

  async componentWillMount() {
    let queryStringData = qureyString.parse(this.props.location.search, {
      arrayFormat: "index",
    });

    if (queryStringData.type === "sld") {
      let finalPayload = await this.preparePayload(queryStringData);
      this.timeTicket = setInterval(() => {
        this.updateLiveChart(finalPayload);
      }, 60000);
    } else {
      let data = await getbookMarkByname(queryStringData.name);
      if (data && data && data[0]) {
        this.setState({ singleView: data && data[0] && data[0].Payload && data[0].Payload.singleView ? data[0].Payload.singleView : "" });
        if (data[0].Type === "live") {
          this.updateLiveChart(data[0].Payload);
          this.timeTicket = setInterval(() => {
            this.updateLiveChart(data[0].Payload);
          }, 60000);
        } else {
          this.updateLiveChart(data[0].Payload);
        }
      } else {
        this.setState({
          loading: false
        })
      }

    }
  }

  preparePayload = async (data) => {
    let parameterList = await getParameterList([data.device]);

    let filterParams = parameterList.data
      .filter((param) => {
        return data.parameterlist.includes(param.ParameterCode);
      })
      .map((obj) => ({
        parameterCode: obj.ParameterCode,
        deviceId: obj.DeviceId,
        tableName: obj.TableName,
        parameterType: obj.ParameterType,
        parameterDescription: obj.ParameterDescription,
      }));

    return {
      type: "live",
      singleView: true,
      data: filterParams,
    };
  };

  updateLiveChart = async (config) => {
    let trendData = await getTrendData(config, this.abortController);
    if (this.state.singleView) {
      if (trendData.length > 0) {
        this.setState({
          trendData: [...this.state.trendData, ...trendData],
          loading: false,
          payLoad: config,
          update: true,
        });
      } else {
        message.warn("NO DATA");
      }
    } else {
      if (trendData.length > 0) {
        if (this.state.trendData.length > 0) {
          let data = this.state.trendData.map((data, i) => {
            if (Array.isArray(trendData[i])) {
              if (trendData[i][0] !== undefined) {
                data.push(trendData[i][0]);
              }
            }
            return data;
          });
          this.setState({
            trendData: data,
            update: true,
          });
        } else {
          this.setState({
            trendData: [...this.state.trendData, ...trendData],
            loading: false,
            payLoad: config,
            update: true,
          });
        }
      }
    }
  };

  componentWillUnmount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
  }

  render() {
    const { loading, singleView, trendData } = this.state;

    const color = [
      "orange",
      "tomato",
      "steelblue",
      "green",
      "pink",
      "voilet",
      "yellow",
      "red",
      "blue",
      "gray",
    ];

    return (
      <Fragment>
        <StyledTrends style={{ minHeight: window.innerHeight - 216 }}>
          {singleView ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "55vh",
                marginTop: "40px",
              }}
            >
              {loading === true ? (
                <div
                  style={{
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <h2 style={{ color: "white" }}>
                    <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                  </h2>
                </div>
              ) : trendData.length > 0 ? (
                <React.Fragment>
                  <TrendSlider
                    panelData={{
                      graph: "trends",
                      trendData: trendData,
                      colors: color,
                      linetype: "",
                      update: this.state.update,                      
                      tunedData: this.state.dataToTrendSlider
                    }}
                  />
                </React.Fragment>
              ) : (
                <h2 style={{ color: "black" }}>{constant.Configure_TO_ViewTrends}</h2>
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "40px",
                flexWrap: "wrap",
              }}
            >
              {trendData.map((graphData, i) => {
                return (
                  <div
                    style={{
                      width: "48%",
                      height: "48vh",
                      marginTop: "1%",
                    }}
                  >
                    <TrendSlider
                      panelData={{
                        graph: "trends",
                        trendData: graphData,
                        colors: [color[i]],
                        linetype: "",
                        update: this.state.update,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </StyledTrends>
      </Fragment>
    );
  }
}
