import React from "react";
import moment from "moment";
import cloneDeep from "lodash.clonedeep";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Tag } from "antd";


import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import ReactEcharts from "../Echarts/Echarts";
import { getChartDataWithoutSelection, getChartDataWithSelection } from "../ChartCalls";
import { trendLabelTranslation } from "../../Utils/Adapter/translationAdapter";
import { barMockJson } from "../../Mock/MockJson";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { defaultRefreshTime } from '../constant.json'
import { Fragment } from "react";

class Area extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      checked: false
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }


    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);

  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection
    ) {
      this.getData(this.props);
    }
  }

  getData = async (props) => {
    let theme = this.props.selectedThemeing;
    this.requestCheck = false
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false
    let json = [];
    let { graphprops } = this.props;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = barMockJson;
      this.requestCheck = true
    } else {

      if (
        props.graphprops.graph === "rangeBar" &&
        props.graphprops.currentDateData !== undefined
      ) {
        json = props.graphprops.currentDateData;
      } else {
        if (
          (graphprops &&
            graphprops.dashboardType === "dynamic" &&
            this.props.deviceId) ||
          (graphprops &&
            graphprops.dateExists === "true" &&
            this.props.selectionDate) ||
          (graphprops &&
            graphprops.monthExists === "true" &&
            this.props.selectionMonth) ||
          (graphprops &&
            graphprops.weekExists === "true" &&
            this.props.selectionWeek) ||
          (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
          (graphprops &&
            graphprops.dateRangeExists === "true" &&
            this.props.selectionDateRange) ||
          (graphprops &&
            graphprops.dayMonthYearExists === "true" &&
            this.props.dayMonthYear)
        ) {
          json = await getChartDataWithSelection(
            graphprops,
            this.props.deviceId,
            this.props.selectionDate,
            this.props.selectionMonth,
            this.props.selectionWeek,
            this.props.shift,
            this.props.selectionDateRange,
            this.props.dayMonthYear,
            this.abortController,
            this.props.dropdownSelection
          );
          this.requestCheck = true
        } else {
          json = await getChartDataWithoutSelection(graphprops, this.abortController, this.props.dropdownSelection);
          this.requestCheck = true
        }
      }
    }

    if ((json && json.message) || (Array.isArray(json) && (json.length === 0) &&
      (graphprops.enableMock !== true))) {

      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
      }

    } else {


      let dates = [];
      let values = [];
      let name = "";
      let tagnames = [];
      let seriesarray = [];
      let legendsdata = [];
      json && Array.isArray(json) && json.map((val, index) => {
        if (val.tag_description !== name) {
          if (tagnames.indexOf(val.tag_description) === -1) {
            tagnames.push(val.tag_description);
            name = val.tag_description;
            legendsdata.push({
              name: val.tag_description,
              icon: "roundRect",
              textStyle: {
                color: theme === "lightTheme" ? "black" : "white",
                fontSize: 16,
                fontWeight: "bold"
              },
            });
          }
        }
      });

      let yAxisData = [];

      if (Array.isArray(this.props.graphprops.YAxis)) {
        yAxisData = [...this.props.graphprops.YAxis];
      }

      let yaxis = yAxisData.map((value, index) => {
        let axis = {};
        axis.splitLine = {
          show: true,
          lineStyle: {
            color: "#504c4c",
          },
          axisLabel: { fontSize: 15 },
        };
        axis.triggerEvent = true;
        axis.axisLine = {
          lineStyle: {
            // color: "white"
            color:
              this.props.graphprops.colors[index] !== undefined
                ? value && value.tags && value.tags.length > 1
                  ? "white"
                  : this.props.graphprops.colors[index]
                : "white",
          },
          axisLabel: { fontSize: 15 },
        };
        axis.max = value.max;
        axis.min = value.min;
        axis.offset = value.offset;
        axis.interval = value.interval;
        axis.position = value.position;
        axis.name = value.name;
        axis.tags = value.tags;
        return axis;
      });

      yaxis.unshift({
        splitLine: {
          show: true,
          lineStyle: {
            color: "#504c4c",
          },
        },
        triggerEvent: true,
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisLabel: { fontSize: 15 },
        tags: [],
      });

      tagnames.map((tagval, tagindex) => {

        values = [];
        let tagdescription = "";
        json.map((jsonval, jsonindex) => {
          if (tagnames[0] === jsonval.tag_description && tagindex === 0) {
            let date = {}
            if (this.props.graphprops.monthExists === "true" || this.props.graphprops.monthExists === true) {
              date = graphprops && graphprops.type === "live" ? moment.utc(jsonval.interval_alias).local().format("DD/MM/YYY") : moment(jsonval.interval_alias).local().format("DD/MM/YYYY")
            } else {
              date = graphprops && graphprops.type === "live" ? moment.utc(jsonval.interval_alias).local().format("DD/MM/YYY HH:mm:ss") : moment.utc(jsonval.interval_alias).local().format("DD/MM/YYYY HH:mm:ss")
            }
            dates.push(date);
          }
          if (tagval === jsonval.tag_description) {
            values.push(jsonval.tagvalue);
          }
          tagdescription = jsonval.tag_description;
        });
        if (this.props.graphprops.linetype === "area") {
          if (yaxis.length > 1) {
            yaxis.map((axis, axisindex) => {
              if (axis.tags.indexOf(tagval) !== -1) {
                let linetags = props.graphprops ? props.graphprops.tags : [];
                if (linetags && linetags.length > 0) {
                  linetags &&
                    linetags.map((tag) => {
                      seriesarray.push({

                        // type: legendsdata[tagindex]["name"] !== tag ? "bar" : "line",
                        name: legendsdata[tagindex]["name"],
                        data: values,
                        type: "line",
                        // markPoint: {
                        //   data: [
                        //     { type: 'max', name: legendsdata[tagindex]["name"] },
                        //     { type: 'min', name: legendsdata[tagindex]["name"] }
                        //   ]
                        // },
                        axisLabel: { fontSize: 15 },
                        yAxisIndex: axisindex,
                        areaStyle: {},
                        smooth: true,
                      });
                    });
                } else {
                  seriesarray.push({

                    type: "line",
                    name: legendsdata[tagindex]["name"],
                    data: values,
                    // markPoint: {
                    //   data: [
                    //     { type: 'max', name: legendsdata[tagindex]["name"] },
                    //     { type: 'min', name: legendsdata[tagindex]["name"] }
                    //   ]
                    // },
                    axisLabel: { fontSize: 15 },
                    yAxisIndex: axisindex,
                    areaStyle: {},
                    smooth: true,
                  });
                }

              }
            });
          } else {
            let linetags = props.graphprops ? props.graphprops.tags : [];
            if (linetags && linetags.length > 0) {
              linetags &&
                linetags.map((tag) => {
                  seriesarray.push({
                    name: legendsdata[tagindex]["name"],
                    data: values,
                    type: legendsdata[tagindex]["name"] !== tag ? "line" : "bar",
                    axisLabel: { fontSize: 15 },
                    areaStyle: {},
                    // markPoint: {
                    //   data: [
                    //     { type: 'max', name: legendsdata[tagindex]["name"] },
                    //     { type: 'min', name: legendsdata[tagindex]["name"] }
                    //   ]
                    // },
                    smooth: true,
                  });
                })
            } else {
              seriesarray.push({
                name: legendsdata[tagindex]["name"],
                data: values,
                type: "line",
                axisLabel: { fontSize: 15 },
                areaStyle: {},
                // markPoint: {
                //   data: [
                //     { type: 'max', name: legendsdata[tagindex]["name"] },
                //     { type: 'min', name: legendsdata[tagindex]["name"] }
                //   ]
                // },
                smooth: true,
              });
            }
          }
        } else {
          if (yaxis.length > 1) {
            yaxis.map((axis, axisindex) => {
              if (axis && axis.tags && axis.tags.indexOf(tagval) !== -1) {
                let linetags = props.graphprops ? props.graphprops.tags : [];
                if (linetags && linetags.length > 0) {
                  linetags &&
                    linetags.map((tag) => {
                      seriesarray.push({
                        name: legendsdata[tagindex]["name"],
                        data: values,
                        // markPoint: {
                        //   data: [
                        //     { type: 'max', name: legendsdata[tagindex]["name"] },
                        //     { type: 'min', name: legendsdata[tagindex]["name"] }
                        //   ]
                        // },
                        yAxisIndex: axisindex,
                        type: legendsdata[tagindex]["name"] !== tag ? "line" : "bar",
                        areaStyle: {},
                        axisLabel: { fontSize: 15 },
                      });
                    })
                } else {
                  seriesarray.push({
                    name: legendsdata[tagindex]["name"],
                    data: values,
                    yAxisIndex: axisindex,
                    type: "line",
                    areaStyle: {},
                    // markPoint: {
                    //   data: [
                    //     { type: 'max', name: legendsdata[tagindex]["name"] },
                    //     { type: 'min', name: legendsdata[tagindex]["name"] }
                    //   ]
                    // },
                    axisLabel: { fontSize: 15 },
                  });
                }
              }
            });
          } else {
            let linetags = props.graphprops ? props.graphprops.tags : [];
            if (linetags && linetags.length > 0) {
              linetags &&
                linetags.map((tag) => {
                  seriesarray.push({
                    name: legendsdata[tagindex]["name"],
                    data: values,
                    type: legendsdata[tagindex]["name"] !== tag ? "line" : "bar",
                    areaStyle: {},
                    axisLabel: { fontSize: 15 },
                    // markPoint: {
                    //   data: [
                    //     { type: 'max', name: legendsdata[tagindex]["name"] },
                    //     { type: 'min', name: legendsdata[tagindex]["name"] }
                    //   ]
                    // },
                  });
                })
            } else {
              seriesarray.push({
                name: legendsdata[tagindex]["name"],
                data: values,
                type: "line",
                axisLabel: { fontSize: 15 },
                areaStyle: {},
                // markPoint: {
                //   data: [
                //     { type: 'max', name: legendsdata[tagindex]["name"] },
                //     { type: 'min', name: legendsdata[tagindex]["name"] }
                //   ]
                // },
              });
            }
          }
        }
      });
      const option = cloneDeep(this.getOption());
      let series = await trendLabelTranslation(
        seriesarray,
        this.props.translationCache,
        this.props.language
      );
      option.xAxis.data = [...dates];
      option.yAxis = [...yaxis];
      option.series = [...series];
      option.legend = [...legendsdata];
      this.setState({
        option: option,
        refreshDateTime: moment()
      });
    };
    this.setState({
      errorInAPI,
      noDataInDB
    })
  }

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state
    let { graphprops } = this.props
    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <ReactEcharts
              option={this.state.option}
              notMerge={true}
              lazyUpdate={true}
              style={{ height: "80%", width: "100%" }}
              className="react_for_echarts"
            />
        }
      </Fragment>
    );
  }

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      legend: {
        textStyle: { color: theme === "lightTheme" ? "black" : "white" },
        data: [
          {
            name: "test1",
            icon: "roundRect",
            // textStyle: {
            //   fontSize: 18,
            // },
          },
        ],
      },
      tooltip: {
        trigger: "axis",
        // textStyle: {
        //   fontSize: 15,
        // },
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            backgroundColor: theme === "lightTheme" ? "black" : "black",
            title: "download",
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: "#8c8c8c",
              borderWidth: 2,
            },
          },
        },
      },
      xAxis: {
        type: "category",
        data: this.props.graphprops,
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisTick: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisLabel: {
          color: theme === "lightTheme" ? "black" : "white",
          // fontSize: 15,
        },
      },
      yAxis: {
        //type: "value",
        axisLine: {
          lineStyle: {
            // color: "white",
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
      },
      dataZoom: [
        {
          type: "slider",
          show: true,
          start: 0,
          end: 100,

          backgroundColor: "#9a9191",
          textStyle: {
            color: "white",
          },
        },
        {
          type: "inside",
          start: 0,
          end: 100,
        },
      ],
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(Area);
