// import layout from './images/singleMHC.svg'
const config = {
  // background: layout,
  viewbox: '0 0 100 48',
  width: '100',
  height: '48',
  fill: '#fff',
  fontWeight: 'bold',
  fontSize: '9%',
  fontFamily:'Roboto-Regular',
  annotations: [
    {
      x: '3',
      y: '5', 
      parameter: 'Vi_00_operating_hour',
      //displayName: 'Crane Run Hrs'
    },
    {
        x: '12.3',       
        y: '5.5', 
        parameter: 'Vi_11_operating_hour',
        //displayName: 'holdingGear'
      },
      {
        x: '2.5',
        y: '11.3',
        parameter: 'Vi_04_operating_hour',
       //displayName: 'hydraulic'
      },
      {
        x: '12',
        y: '11',
        parameter: 'Vi_12_operating_hour',
       //displayName: 'closingGear'
      },
      {
        x: '14.5',
        y: '17.2',
        parameter: '_11_N_actual_R',
        //displayName: 'Holding Gear Speed'
      },
      {
        x: '14.5',
        y: '19',
        parameter: '_11_I_actual_R',
        //displayName: 'Holding Gear current'
      },
      {
        x: '14.5',
        y: '20.8',  
        parameter: '_11_P_actual_R',
        //displayName: 'Holding Gear Power'
      },
      {
        x: '14.5',
        y: '22.7', 
        parameter: '_11_UN_actual_R',
        //displayName: 'Holding Gear Line Voltage'
      },
      {
        x: '14.5',
        y: '24.5', 
        parameter: '_11_fN_actual_R',
       //displayName: 'Holding Gear Line Frequency'
      },
      {
        x: '14.5',
        y: '26.3',
        parameter: '_11_UA_actual_R',
        //displayName: 'Holding Gear Armature Voltage'
      },
      {
        x: '14.5',
        y: '28.2',
        parameter: '_11_H_perm_R',
        //displayName: 'Holding Gear Permissible Hoisting Height'
      },
      {
        x: '14.5',
        y: '30.2', 
        parameter: '_11_H_height_actual_R',
        //displayName: 'Holding Gear Actual Hoisting Height'
      },
      {
        x: '3.5',
        y: '40',
        parameter: '_07_Fuel_level_cent_R',
       //displayName: 'fuelLevel'
      },
      {
        x: '13.2',
        y: '40.5',
        parameter: '_11_Gear_impuls',
       //displayName: 'Gearbox Pulse'
      },
      {
        x: '3.5',
        y: '46.2',
        parameter: '_00_P_actual_R',
        //displayName: 'DieselPower',
       
      },
      {
        x: '13.2',
        y: '46',  
        parameter: 'Vi_st_angle_lift_end',
        //displayName: 'slewingAngle'
      },
      {
        x: '53',
        y: '26',
        parameter: '_61_B1_RAD_Setvalue_R',
        //displayName: 'boomPosition'
      },
      {
        x: '73.5',
        y: '34',
        parameter: '_12_P_actual_R',
        //displayName: 'Closing Gear Power'
      },
      {
        x: '73.5',
        y: '35.9',
        parameter: '_12_UN_actual_R',
        //displayName: 'Closing Gear Line Voltage'
      },
      {
        x: '73.5',
        y: '37.7',  
        parameter: '_12_fN_actual_R',
        //displayName: 'Closing Gear Line Frequency'
      },
      {
        x: '73.5',
        y: '39.5', 
        parameter: '_12_UA_actual_R',
       //displayName: 'Closing Gear ArmatureVoltage'
      },
      {
        x: '89.3',
        y: '8.5',  
        parameter: '_61_Wind_actual_R',
       //displayName: 'windSpeed'
      },
      {
        x: '89.3',
        y: '17.5',
        parameter: '_63_Bruttoload_R',
        ////displayName: 'grossLoad'
      },
      {
        x: '89.3',
        y: '27.5',
        parameter: '_63_Taraload_R',
      // //displayName: 'tareLoad'
      },
      {
        x: '89.3',
        y: '36.5', 
        parameter: '_51_spreader_land_LI',
        ////displayName: 'speaderWeight'
      },
      
  ]
}

export {
  config
}
