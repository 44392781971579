import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect';
import { Table, Form, Col, Input, Drawer, Button, Card, Row, Icon, message, Popconfirm } from 'antd'
import localStorage from '../../../../utils/localStorage'
import { DrawerFooter, StyledTable, lightTheme } from '../style'
import { getUserProfile } from '../../../../selectors/layout';


class Site extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            datasource: [],
            editObj: {},
            orgId: ''
        }
    }

    componentWillMount() {
        let orgId = this.props.match.params.orgId
        if (orgId) {
            this.setState({ orgId })
            this._getSiteListById(orgId)
        }
    }

    _getSiteListById = (orgId) => {
        const accessToken = localStorage.get('accessToken')
        let orgHeaders = {
            method: 'GET',
            url: `/api/site/list`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
        axios(orgHeaders)
            .then((response) => {
                this.setState({ datasource: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    openModal = () => {
        this.props.form.resetFields()
        this.setState({
            visible: true,
            editObj: {}
        });
    };

    _createSite = (e) => {
        e.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (!error) {
                delete values.Id
                this._createSiteAPI(values)
            }
        })

    };

    _updateSite = (e) => {
        e.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (!error) {
                this._updateSiteAPI(values)
            }
        })

    };

    _updateSiteAPI = (updateObject) => {
        const accessToken = localStorage.get('accessToken')
        let orgUpdateHeaders = {
            method: 'POST',
            url: `/api/site/update`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: updateObject
        }
        axios(orgUpdateHeaders)
            .then((response) => {
                message.success("Record Updated")
                this._getSiteListById(this.state.orgId)
                this.setState({
                    visible: false,
                })
            })
            .catch(function (error) {
                console.log(error)
            })
    }


    _createSiteAPI = (createObject) => {
        const accessToken = localStorage.get('accessToken')
        let orgCreateHeaders = {
            method: 'POST',
            url: '/api/site/insert',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: {
                ...createObject,
                CreatedBy: (this.props.userProfile && this.props.userProfile['user'] ? this.props.userProfile['user'].Id : "")
            }
        }
        axios(orgCreateHeaders)
            .then((response) => {
                this._getSiteListById(this.state.orgId)
                this.setState({ visible: false });
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    _cancel = () => {
        this.setState({
            visible: false,
        });
    };

    editSite = (editObj) => {
        this.setState({
            editObj,
            visible: true,
        })
    }

    getRoles = (siteId) => {
        this.props.history.push({
            pathname: `/rubus/settingsPage/UserManagement/${this.state.orgId}/${siteId}/Roles`
        })
    }


    _deleteSite = (record) => {
        const accessToken = localStorage.get('accessToken')
        let orgDeleteHeaders = {
            method: 'POST',
            url: `/api/site/delete`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: {
                SiteId: record.SiteId,
                Id:record.Id
            }
        }
        axios(orgDeleteHeaders)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    message.success("Site Deleted")
                    this._getSiteListById(this.state.orgId)
                    this.setState({ visible: false })
                }
            })
            .catch(function (error) {
                console.log(error)
            })

    }


    render() {
        const { getFieldDecorator } = this.props.form
        const { datasource, visible, editObj, orgId } = this.state

        const columns = [{
            title: 'Site Name',
            dataIndex: 'Name',
            key: 'Name'
        }, {
            title: 'Site Id',
            dataIndex: 'SiteId',
            key: 'SiteId'
        }, {
            title: 'Address',
            dataIndex: 'Address',
            key: 'Address'
        }, {
            title: 'Description',
            dataIndex: 'Description',
        },
        {
            title: 'Action',
            dataIndex: '',
            key: '',
            render: (text, record) =>
                <div>
                    <button type='button' onClick={() => this.editSite(record)} style={{ marginRight: '10px' }} className='ant-btn' >
                        <Icon type='edit' />
                    </button>
                    <Popconfirm
                        title="Are you sure delete Site ?"
                        onConfirm={e => {
                            e.stopPropagation()
                            this._deleteSite(record)
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button type='button' className='ant-btn' style={{ marginRight: '10px' }}>
                            <Icon
                                type="delete"
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                            />
                        </button>
                    </Popconfirm>
                    <Button type='primary' onClick={() => this.getRoles(record.Id)} className='ant-btn' >
                        Get Roles
                    </Button>
                </div>
        }
        ]
        return (

            <Card bordered={false} >
                <Drawer
                    title="Create Site"
                    visible={visible}
                    width={600}
                    closable={false}
                    onClose={this._cancel}
                >

                    <Form layout="vertical">
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label="Site Name">
                                    {/* {getFieldDecorator("Name", {
                                        initialValue: editObj.Name || '',
                                    })(
                                        <Input placeholder="Please enter Site name" />
                                    )} */}
                                    {getFieldDecorator(`Name`, {
                                        initialValue: editObj.Name,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input your site name!',
                                            },
                                        ],
                                    })( <Input placeholder="Please enter Site name" />)
                                }
                                    {getFieldDecorator("OrganizationId", {
                                        initialValue: orgId,
                                    })(
                                        <Input style={{ display: 'none' }} />
                                    )}
                                    {getFieldDecorator("Id", {
                                        initialValue: editObj.Id || '',
                                    })(
                                        <Input style={{ display: 'none' }} />
                                    )}
                                </Form.Item>
                                <Form.Item label="Site Id">
                                    {/* {getFieldDecorator("SiteId", {
                                        initialValue: editObj.SiteId || '',
                                    })(
                                        <Input placeholder="Please enter Site id" />
                                    )} */}
                                     {getFieldDecorator(`SiteId`, {
                                        initialValue: editObj.SiteId,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input your SiteId!',
                                            },
                                        ],
                                    })( <Input placeholder="Please enter SiteId" />)
                                }
                                </Form.Item>
                                <Form.Item label="Geo Location">
                                    {/* {getFieldDecorator("Location", {
                                        initialValue: editObj.Location || '',
                                    })(
                                        <Input placeholder="Please enter Geo Location" />
                                    )} */}
                                    {getFieldDecorator(`Location`, {
                                        initialValue: editObj.Location,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input Geo Location!',
                                            },
                                        ],
                                    })( <Input placeholder="Please enter Geo Location" />)
                                }
                                </Form.Item>
                                <Form.Item label="Address">
                                    {/* {getFieldDecorator("Address", {
                                        initialValue: editObj.Address || '',
                                    })(
                                        <Input placeholder="Please enter address" />
                                    )} */}
                                     {getFieldDecorator(`Address`, {
                                        initialValue: editObj.Address,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input Address!',
                                            },
                                        ],
                                    })( <Input placeholder="Please enter Address" />)
                                }
                                </Form.Item>
                                <Form.Item label="Description">
                                    {getFieldDecorator(`Description`, {
                                        initialValue: editObj.Description,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input Description!',
                                            },
                                        ],
                                    })( <Input placeholder="Please enter Description" />)
                                }
                                </Form.Item>

                            </Col>
                        </Row>
                    </Form>
                    <DrawerFooter>
                        <Button onClick={this._cancel} style={{ marginRight: 8 }}>Cancel</Button>
                        {
                            !editObj.Id ?
                                <Button type="primary" onClick={this._createSite}>Create Site</Button>
                                : <Button type="primary" onClick={this._updateSite}>Update Site</Button>
                        }


                    </DrawerFooter>
                </Drawer>
                <Button type="primary" size={"large"} style={{ marginBottom: '10px', float: "right" }} onClick={this.openModal}>Add Site</Button>
                <StyledTable theme={lightTheme}>
                    <Table
                        columns={columns}
                        dataSource={datasource}
                        pagination={
                            datasource.length > 10
                                ? {
                                    pageSize: "10"
                                }
                                : false
                        }
                    />
                </StyledTable>
            </Card>

        )
    }
}


const mapStateToProps = createStructuredSelector({
    userProfile: getUserProfile(),
  });
  export default connect(mapStateToProps)(Form.create()(Site));
