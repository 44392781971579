import React from "react";
import cloneDeep from "lodash.clonedeep";
import { connect } from "react-redux";
import { Spin } from "antd";
import { createStructuredSelector } from "reselect";
import {Tag} from "antd";
import moment from "moment";
import ReactEcharts from "../Echarts/Echarts";
import { getChartDataWithoutSelection, getChartDataWithSelection } from "../ChartCalls";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { singleBarMock } from "../../Mock/MockJson";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { defaultRefreshTime } from '../constant.json'
import { Fragment } from "react";

class SingleBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      loading: false,
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime :moment(),
      errorInAPI: false,
      noDataInDB: false,
      range:[]
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange
    ) {
      this.getData();
    }
  }
  getData = async () => {
    this.requestCheck = false
    let { graphprops } = this.props;
    let json = [];
    this.setState({ loading: true });
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false 
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = singleBarMock;
      this.requestCheck = true
      this.setState({ loading: false });
    } else {
      if (
        (graphprops &&
          graphprops.dashboardType === "dynamic" &&
          this.props.deviceId) ||
        (graphprops &&
          graphprops.dateExists === "true" &&
          this.props.selectionDate) ||
        (graphprops &&
          graphprops.monthExists === "true" &&
          this.props.selectionMonth) ||
        (graphprops &&
          graphprops.weekExists === "true" &&
          this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
        (graphprops &&
          graphprops.dateRangeExists === "true" &&
          this.props.selectionDateRange)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.abortController
        );
        this.requestCheck = true
        this.setState({ loading: false });
      } else {
        json = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true
        this.setState({ loading: false });
      }
    }
    if ((json && json.message) || (Array.isArray(json) && (json.length === 0 )&& 
    (graphprops.enableMock !== true))) {
    
  
      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
      }
  
    } else {

    if (json && Array.isArray(json) && json.length > 0) {
      const option = cloneDeep(this.state.option);
      option.series[0].data[0] = Number(json[0].tagvalue).toFixed(2);

      let stringRange = this.props.graphprops.dateRangeSingleBar.range
      let range = JSON.parse(stringRange);
      this.setState({
        range:range
      })
      
      option.yAxis.min = range[0];
      option.yAxis.max = range[1];

      return this.setState({ 
        option: option,
        refreshDateTime :moment()

       });
    }
  };
  this.setState({
    errorInAPI,
    noDataInDB
  })
} 

  componentWillUnmount() {
    this.abortController.abort()
    clearInterval(this.timeTicket);
  }

  render() {
    let theme = this.props.selectedThemeing;
    let { loading ,errorInAPI, noDataInDB ,refreshDateTime}  = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
         {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
            noDataInDB === true || errorInAPI === true ?
              noDataInDB === true ?
              <NoRecordComponent/>
              : <ErrorComponent/>
              :
              <span>
              {loading === false ? (
                
                <ReactEcharts
                  option={this.state.option}
                  style={{ height: "90%", width: "100%" }}
                  className="react_for_echarts"
                />
                
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    color: theme === "lightTheme" ? "black" : "white",
                    marginTop: "15%",
                  }}
                >
                  <h2 style={{ color: theme === "lightTheme" ? "black" : "white" }}>
                    <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                  </h2>
                </div>
              )}
            </span>
  }
      </Fragment>
     
    );
  }

  getOption = () => {
    // let {range }= this.state
    let theme = this.props.selectedThemeing;
    let option = {
      grid: { right: "30%", left: "30%" },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        textStyle: {
          fontSize: 15,
        },
      },
      xAxis: {
        name: this.props.graphprops.title,
        nameLocation: "center",
        data: [""],
        axisTick: {
          show: false,
        },
        nameTextStyle: {
          color: theme === "lightTheme" ? "black" : "white",
          fontSize: 20,
        },
        type: "category",
        // data: ['Mon'],

        show: true,
        axisLine: {
          show: false,
        },
      },
      yAxis: {
        type: "value",
        position: "left",
        axisLine: {
          show: false,
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",

          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          fontSize: 15,
          color: theme === "lightTheme" ? "black" : "white"
        },
        splitLine: {
          show: true,
          lineStyle: {
            // Dark and light colors will be used in turns
            color: theme === "lightTheme" ? "black" : "white"
            //  ["smokewhite"],
          },
        },


        // min: range && range[0] ? range[0] : 300,
        // max: range && range[1] ? range[1]: -300,
        // min: this.props.graphprops.range[0],
        // max: this.props.graphprops.range[1],
        //   interval: 1000
      },
      series: [
        {
          name: this.props.graphprops.title,
          data: [],
          type: "bar",
          color: this.props.graphprops.titleColor ? this.props.graphprops.titleColor : theme === "lightTheme" ? "black" : "white",
          label: {
            show: true,
            position: "insideTop",
            fontSize: 18,
          },
        },
      ],
      toolbox: {
        showTitle: false,
        orient: "vertical",
        itemSize: 25,
        feature: {
          saveAsImage: {
            backgroundColor: "black",
            title: "download",
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: "#8c8c8c",
              borderWidth: 2,
            },
          },
        },
      },
      // toolbox: {
      //   showTitle: false,
      //   orient: "vertical",
      //   feature: {
      //     saveAsImage: { 
      //       title: "download",
      //       name: this.props.graphprops.title,
      //       backgroundColor: "black"
      //     }
      //   }
      // },
    };

    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(SingleBar);
