
import axios from 'axios'
import localStorage from '../../utils/localStorage'
import { jwtToken } from '../../commons/jwtTokenExpiry'

export const  getHmiAllParams= async(deviceID,hmiName,hmiType,checkStatus)=> {
  const siteId = localStorage.get('currentSite')
  const token = localStorage.get('accessToken')
  var Authorization = 'Bearer ' + token

  const data = await fetch(`/api/hmi/hmiModal`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      deviceID: deviceID,
      "hmiName": hmiName,
      "hmiType":hmiType,
      SiteId: siteId
    })
  })
    .then(response => {
      return response.json()
    })
    .then(json => {
      if (json.name === 'TokenExpiredError') {
        jwtToken(json.message)
      } else if (json) {
        return json
      }
    })
    .catch(() => {
    })

  return data === undefined ? {} : data
}

export const  getReportNametoDownload = async(columns, datasource, downloadExcelPayload) =>{
  let reportPayload = []
  datasource.map((datasourceObject) => {
    let newObj = {}
   
    columns.map((column) => (
      newObj[column.dataIndex] = datasourceObject[column.key]
      ))
    reportPayload.push(newObj)
  })
  let payload = {
    ...downloadExcelPayload,
    "data": reportPayload
  }
  let accessToken = localStorage.get('accessToken')
  let options = {}
  options = {
    method: 'POST',
    url: `/api/report/generateExcel`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: JSON.stringify(payload)
  }
  return axios(options)
    .then(({ data }) => {
      return data
    }).catch(error => {
      return ""
      // console.log('ERROR', error)

    })

}

export const getHMICalls = async(payload)=> {
  const accessToken = localStorage.get('accessToken')
  const header = {
    ...payload,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
  }
  return axios(header)
    .then((response) => {
      return response.data
    })
    .catch( ()=> {
      return {
        "message": "Error in API"
      }
    })
}