import { createSelector } from 'reselect'

const language = state => {
  return state.get('asset').get('asset')
}

const getCurrentLanguage = () =>
  createSelector(language, state => {
    return state.get('currentLanguage')
  })

const translation = state => {
  return state.get('translation').get('translation')
}

const getTranslationCache = () =>
  createSelector(translation, state => {
    return state.get('translationCache')
  })


export {
  getCurrentLanguage,
  getTranslationCache
}
