import React from "react";
import moment from "moment";
import { Spin, Tag } from "antd";
import cloneDeep from "lodash.clonedeep";
import axios from "axios";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import ReactEcharts from "../Echarts/Echarts";
import {
  getChartDataWithoutSelection,
  getChartDataWithSelection

} from "../ChartCalls";
import { trendLabelTranslation } from "../../Utils/Adapter/translationAdapter";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
// import { defaultExcelAdapter } from "./ExcelAdapters/defaultAdapter";
import localStorage from "../../../../utils/localStorage";
import { barMockJson } from "../../Mock/MockJson";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { defaultRefreshTime } from '../constant.json'
import { Fragment } from "react";

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      loading: false,
      colors: this.props.graphprops.colors,
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      reportName: "",
      errorInAPI: false,
      noDataInDB: false
    };
  }

  data = [];
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }
    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection
    ) {
      this.getData(this.props);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  render() {
    let theme = this.props.selectedThemeing;
    let { graphprops } = this.props
    let { loading, errorInAPI, noDataInDB, refreshDateTime } = this.state;

    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }

        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <span>
              {loading === false ? (
                <ReactEcharts
                  option={this.state.option}
                  notMerge={true}
                  lazyUpdate={true}
                  style={{ height: "80%", width: "100%" }}
                  className="react_for_echarts"
                />
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    color: theme === "lightTheme" ? "black" : "white",
                    marginTop: "10%",
                  }}
                >
                  <h2 style={{ color: "black" }}>
                    <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                  </h2>
                </div>
              )}
            </span>
        }
      </Fragment>


    );
  }

  getReportNametoDownload(excelData) {
    let payload = {
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm:SS"),
      data: excelData || [],
      ...this.props.graphprops.excelObj,
    };

    let accessToken = localStorage.get("accessToken");
    let options = {
      method: "POST",
      url: `/api/report/generateExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify(payload),
    };
    return axios(options)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return "";
      });
  }

  // downLoadExcel = () => {
  //   const { reportName } = this.state;
  //   let url = `${excelDownloadUrl}/${reportName}`;
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", reportName); //or any other extension
  //   document.body.appendChild(link);
  //   link.click();
  // };

  getData = async (props) => {
    this.requestCheck = false
    let theme = this.props.selectedThemeing;

    let json = [];
    let { graphprops } = this.props;
    this.setState({ loading: true });
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = barMockJson;
      this.requestCheck = true
      this.setState({ loading: false });
    } else {
      if (
        props.graphprops.graph === "rangeLine" &&
        props.graphprops.currentDateData !== undefined
      ) {
        json = props.graphprops.currentDateData;
      } else {
        if (
          ((graphprops &&
            graphprops.dashboardType === "dynamic" && this.props.deviceId) ||
            (graphprops.dashboardType === "dropdownHide" && this.props.deviceId)) ||
          (graphprops &&
            graphprops.dateExists === "true" &&
            this.props.selectionDate) ||
          (graphprops &&
            graphprops.monthExists === "true" &&
            this.props.selectionMonth) ||
          (graphprops &&
            graphprops.weekExists === "true" &&
            this.props.selectionWeek) ||
          (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
          (graphprops &&
            graphprops.dateRangeExists === "true" &&
            this.props.selectionDateRange) ||
          (graphprops &&
            graphprops.dayMonthYearExists === "true" &&
            this.props.dayMonthYear) ||
          (graphprops &&
            graphprops.dashboardType === "hierarchy" &&
            this.props.dropdownSelection)
        ) {
          json = await getChartDataWithSelection(
            graphprops,
            this.props.deviceId,
            this.props.selectionDate,
            this.props.selectionMonth,
            this.props.selectionWeek,
            this.props.shift,
            this.props.selectionDateRange,
            this.props.dayMonthYear,
            this.abortController,
            this.props.dropdownSelection
          );
          this.requestCheck = true
          this.setState({ loading: false, errorInAPI: false, noDataInDB: false });
        } else {
          if (graphprops.dashboardType === "static") {
            json = await getChartDataWithoutSelection(graphprops, this.abortController, this.props.dropdownSelection);
          }
          this.requestCheck = true
          this.setState({ loading: false });
        }
      }
    }
    if (json && json.message ||
      (Array.isArray(json) && json.length === 0 || (graphprops.enableMock && graphprops.enableMock !== true))) {
      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
      }
    } else {
      // let reportName = await this.getReportNametoDownload(
      //   // defaultExcelAdapter(json)
      // );
      if (json && Array.isArray(json)) {
        let dates = [];
        let values = [];
        let name = "";
        let tagnames = [];
        let seriesarray = [];
        let legendsdata = [];
        json.map((val, index) => {
          if (val.tagname !== name) {
            if (tagnames.indexOf(val.tagname) === -1) {
              tagnames.push(val.tagname);
              name = val.tagname;
              legendsdata.push({
                name: val.tag_description,
                icon: "roundRect",
                textStyle: {
                  color: theme === "lightTheme" ? "black" : "white",
                  fontSize: 14,
                  fontWeight: "bold",
                },
              });
            }
          }
        });
        let yAxisData = [];
        if (this.props.graphprops && this.props.graphprops.dateRangeLine &&
          this.props.graphprops.dateRangeLine.YAxis) {

          yAxisData = JSON.parse(this.props.graphprops.dateRangeLine.YAxis)
        }
        else {
          yAxisData = []
        }
        let yaxis = yAxisData.map((value, index) => {
          let axis = {};
          axis.splitLine = {
            show: true,
            lineStyle: {
              color: "#504c4c",
            },
            // axisLabel: { fontSize: 15 },
          };
          axis.triggerEvent = true;
          axis.axisLine = {
            show: true,
            lineStyle: {
              //  color: "white"
              color:
                this.props.graphprops.dateRangeLine.colors[index] !== undefined
                  ? value && value.tags && value.tags.length > 1
                    ? "white"
                    : this.props.graphprops.dateRangeLine.colors[index]
                  : "white",
            },
            // axisLabel: { fontSize: 15 },
          };
          axis.max = value.max;
          axis.min = value.min;
          axis.offset = value.offset;
          axis.interval = value.interval;
          axis.position = value.position;
          axis.name = value.name;
          axis.tags = value.tags;
          return axis;
        });

        yaxis.unshift({
          splitLine: {
            show: true,
            lineStyle: {
              color: "#504c4c",
            },
          },
          triggerEvent: true,
          axisLine:
          {
            show: true,
            lineStyle: {
              color: theme === "lightTheme" ? "black" : "white",
            },
          },
          axisLabel: { fontSize: 15 },
          tags: [],
        });

        tagnames.map((tagval, tagindex) => {

          values = [];
          json.map((jsonval, jsonindex) => {

            if (tagnames[0] === jsonval.tagname && tagindex === 0) {

              let date = graphprops && graphprops.type === "live" ? moment.utc(jsonval.interval_alias).local().format("DD/MM/YYYY HH:mm:ss") : moment(jsonval.interval_alias).format("DD/MM/YYYY HH:mm:ss")
              dates.push(date);
            }
            let tagdescription = "";
            if (tagval === jsonval.tagname) {
              values.push(jsonval.tagvalue);
            }
            tagdescription = jsonval.tag_description;
          });
          if (this.props.graphprops.linetype === "area") {
            if (yaxis.length > 1) {
              yaxis.map((axis, axisindex) => {
                if (axis.tags.indexOf(tagval) !== -1) {
                  seriesarray.push({
                    name: legendsdata[tagindex]["name"],
                    data: values,
                    type: "line",
                    // axisLabel: { fontSize: 15 },
                    yAxisIndex: axisindex,
                    areaStyle: {},
                    smooth: true,
                    markPoint: {
                      data: [
                        { type: 'max', name: legendsdata[tagindex]["name"] },
                        { type: 'min', name: legendsdata[tagindex]["name"] }
                      ]
                    },
                  });
                }
              });
            } else {
              seriesarray.push({
                name: legendsdata[tagindex]["name"],
                data: values,
                type: "line",
                // axisLabel: { fontSize: 15 },
                areaStyle: {},
                smooth: true,
                markPoint: {
                  data: [
                    { type: 'max', name: legendsdata[tagindex]["name"] },
                    { type: 'min', name: legendsdata[tagindex]["name"] }
                  ]
                },
              });
            }
          } else {
            if (yaxis.length > 1) {
              yaxis.map((axis, axisindex) => {
                if (axis.tags.indexOf(tagval) !== -1) {
                  seriesarray.push({
                    name: legendsdata[tagindex]["name"],
                    data: values,
                    yAxisIndex: axisindex,
                    type: "line",
                    axisLabel: { fontSize: 15 },
                    markPoint: {
                      data: [
                        { type: 'max', name: legendsdata[tagindex]["name"] },
                        { type: 'min', name: legendsdata[tagindex]["name"] }
                      ]
                    },
                  });
                }
              });
            } else {
              seriesarray.push({
                name: legendsdata[tagindex]["name"],
                data: values,
                type: "line",
                axisLabel: { fontSize: 15 },
                markPoint: {
                  data: [
                    { type: 'max', name: legendsdata[tagindex]["name"] },
                    { type: 'min', name: legendsdata[tagindex]["name"] }
                  ]
                },
              });
            }
          }
        });

        const option = cloneDeep(this.getOption());
        if (this.props.graphprops && this.props.graphprops.dateRangeLine && this.props.graphprops.dateRangeLine.colors
          && this.props.graphprops.dateRangeLine.colors.length > 0
        ) {
          option.color = this.props.graphprops.dateRangeLine.colors
        }
        // if (props.graphprops.enableExcel) {
        //   const graphContext = this;
        //   option.toolbox.feature.myTool1 = {
        //     show: true,
        //     title: "Download Excel",
        //     icon:
        //       "path:M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494zM514.1 580.1l-61.8-102.4c-2.2-3.6-6.1-5.8-10.3-5.8h-38.4c-2.3 0-4.5.6-6.4 1.9-5.6 3.5-7.3 10.9-3.7 16.6l82.3 130.4-83.4 132.8a12.04 12.04 0 0 0 10.2 18.4h34.5c4.2 0 8-2.2 10.2-5.7L510 664.8l62.3 101.4c2.2 3.6 6.1 5.7 10.2 5.7H620c2.3 0 4.5-.7 6.5-1.9 5.6-3.6 7.2-11 3.6-16.6l-84-130.4 85.3-132.5a12.04 12.04 0 0 0-10.1-18.5h-35.7c-4.2 0-8.1 2.2-10.3 5.8l-61.2 102.3z",

        //     onclick: function () {
        //       graphContext.downLoadExcel();
        //     },
        //   };
        // }

        let series = await trendLabelTranslation(
          seriesarray,
          this.props.translationCache,
          this.props.language
        );
        option.xAxis.data = [...dates];
        option.yAxis = [...yaxis];
        option.series = [...series];
        option.legend = [...legendsdata];
        this.setState({
          option: option,
          // reportName: reportName && reportName.file ? reportName.file : "",
          refreshDateTime: moment(),
        });

        return option;
      }
    };

    this.setState({
      errorInAPI,
      noDataInDB
    })
  }

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      legend: {
        textStyle: { color: theme === "lightTheme" ? "black" : "white" },
        data: [
          {
            name: "test1",
            icon: "roundRect",
            textStyle: {
              // fontSize: 15,
            },
          },
        ],
      },
      tooltip: {
        trigger: "axis",
        textStyle: {
          // fontSize: 15,
        },
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: "download",
            name: this.props.graphprops.title,
            backgroundColor: theme === "lightTheme" ? "white" : "black",
          },
        },
      },
      xAxis: {
        type: "category",
        data: this.props.graphprops,
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisTick: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisLabel: {
          color: theme === "lightTheme" ? "black" : "white",
          // fontSize: 15,
        },
      },
      yAxis: {
        type: "value",
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        // min: min,
        // max: max,
      },
      dataZoom: [
        {
          type: "slider",
          show: true,
          start: 0,
          end: 100,

          backgroundColor: "#9a9191",
          textStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        {
          type: "inside",
          start: 0,
          end: 100,
        },
      ],
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(Slider);
