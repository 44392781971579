import React, { Fragment } from "react";
import moment from "moment";
import cloneDeep from "lodash.clonedeep";
import { connect } from "react-redux";
import axios from "axios";
import { Spin } from "antd";
import { Icon, Tag } from "antd";
import { createStructuredSelector } from "reselect";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import ReactEcharts from "../Echarts/Echarts";
import {
  getChartDataWithoutSelection,
  getChartDataWithSelection,
  treeGraphData,
} from "../ChartCalls";
import { treeDataTranslation } from "../../Utils/Adapter/translationAdapter";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import { treeGraphMock } from "../../Mock/MockJson";
import { makeSelectThemeing } from "../../../../selectors/theme";
// import { treeAdapter } from "./ExcelAdapters/defaultAdapter";
import localStorage from "../../../../utils/localStorage";
// import { excelDownloadUrl } from "./ExcelAdapters/const";

import { treeMock } from "../../Mock/treeMock";
import { defaultRefreshTime } from '../constant.json'


class TreeGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      loading: false,
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange
    ) {
      this.getData(this.props);
    }
  }

  getData = async (props) => {
    this.requestCheck = false
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false
    // this.setState({ loading: true });
    // if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
    //   json = treeGraphMock;
    //   this.requestCheck = true
    // } else {
    //   if (
    //     (graphprops &&
    //       graphprops.dashboardType === "dynamic" &&
    //       this.props.deviceId) ||
    //     (graphprops &&
    //       graphprops.dateExists === "true" &&
    //       this.props.selectionDate) ||
    //     (graphprops &&
    //       graphprops.monthExists === "true" &&
    //       this.props.selectionMonth) ||
    //     (graphprops &&
    //       graphprops.weekExists === "true" &&
    //       this.props.selectionWeek) ||
    //     (graphprops && graphprops.shift === "true" && this.props.shift) ||
    //     (graphprops &&
    //       graphprops.dateRangeExists === "true" &&
    //       this.props.selectionDateRange) ||
    //     (graphprops &&
    //       graphprops.dayMonthYearExists === "true" &&
    //       this.props.dayMonthYear)
    //   ) {
    //     json = await getChartDataWithSelection(
    //       graphprops,
    //       this.props.deviceId,
    //       this.props.selectionDate,
    //       this.props.selectionMonth,
    //       this.props.selectionWeek,
    //       this.props.shift,
    //       this.props.selectionDateRange,
    //       this.props.dayMonthYear,
    //       this.abortController
    //     );
    //     this.requestCheck = true
    //     this.setState({ loading: false });
    //   } else {
    //     json = await getChartDataWithoutSelection(graphprops, this.abortController);
    //     this.requestCheck = true
    //     this.setState({ loading: false });
    //   }
    // }

    // if ((json && json.message) || (Array.isArray(json) && (json.length === 0) &&
    //   (graphprops.enableMock !== true))) {
    //   if (json.message) {
    //     errorInAPI = true
    //   } else if (json.length === 0) {
    //     noDataInDB = true
    //   }
    // } else {

      json = await treeGraphData(this.abortController);
      // json = treeMock;
      let reportName = await this.getReportNametoDownload();
      const option = cloneDeep(this.getOption());
      if (json && Array.isArray(json)) {
        let series = await treeDataTranslation(
          json,
          this.state.translationCache,
          this.props.language
        );
        option.series[0].data = series;
        this.setState({
          option: option,
          reportName: reportName && reportName.file ? reportName.file : "",
          excelDownload: props.graphprops.enableExcel,
          refreshDateTime: moment()
        });
      }
    // };
    this.setState({
      errorInAPI,
      noDataInDB
    })
  }

  getReportNametoDownload(excelData) {
    let payload = {
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm:SS"),
      data: excelData || [],
      ...this.props.graphprops.excelObj,
    };

    let accessToken = localStorage.get("accessToken");
    // const siteId = localStorage.get("currentSite");
    let options = {
      method: "POST",
      url: `/api/report/generateExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify(payload),
    };
    return axios(options)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return "";
        // console.log('ERROR', error)
      });
  }

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
      },
      toolbox: {
        showTitle: false,
        orient: "vertical",
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: "download",
            backgroundColor: theme === "lightTheme" ? "black" : "white",
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: "#8c8c8c",
              borderWidth: 2,
            },
          },
        },
      },
      // toolbox: {
      //   showTitle: false,
      //   orient: "vertical",
      //   feature: {
      //     saveAsImage: {
      //       title: "download",
      //       name: this.props.graphprops.title,
      //       backgroundColor: "black"
      //     }
      //   }
      // },
      series: [
        {
          type: "tree",

          data: [],

          top: "1%",
          left: "10%",
          bottom: "1%",
          right: "30%",
          symbol: "roundRect",
          layout: "orthogonal",
          orient: "horizontal",
          symbolSize: 7,

          label: {
            position: "top",
            fontSize: 13,
            fontFamily: "Arial Black",
            verticalAlign: "middle",
            color: theme === "lightTheme" ? "black" : "white",
          },

          leaves: {
            label: {
              position: "right",
              verticalAlign: "middle",
              align: "left",
            },
          },
          lineStyle: {
            width: 1,
            color: "steelblue",
          },

          expandAndCollapse: true,
          animationDuration: 550,
          animationDurationUpdate: 750,
        },
      ],
    };
    return option;
  };

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  render() {
    let { loading, reportName, excelDownload, errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props
    return (
      <span>
        {loading === false ? (
          <Fragment>
            {
              graphprops && graphprops.checked === true ?
                <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
                  {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
                </Tag>
                : null
            }
            {
              noDataInDB === true || errorInAPI === true ?
                noDataInDB === true ?
                  <NoRecordComponent />
                  : <ErrorComponent />
                :
                <ReactEcharts
                  option={this.state.option}
                  notMerge={true}
                  lazyUpdate={true}
                  style={{ height: "90%", width: "100%" }}
                  className="react_for_echarts"
                />
            }
            {/* {reportName && excelDownload === "true" ? (
              <a
                href={`${excelDownloadUrl}/${reportName}`}
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                <Icon
                  type="download"
                  style={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    marginRight: "25px",
                    marginTop: "6px",
                  }}
                />
              </a>
            ) : null} */}

          </Fragment>
        ) : (
          <div
            style={{
              textAlign: "center",
              color: "white",
              marginTop: "10%",
            }}
          >
            <h2 style={{ color: "white" }}>
              <Spin spinning={loading} tip="Loading..." size="large"></Spin>
            </h2>
          </div>
        )}
      </span>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(TreeGraph);
