
import React from "react";
import cloneDeep from "lodash.clonedeep";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import moment from "moment";
import {Tag} from "antd";
import ReactEcharts from "../Echarts/Echarts";
import { trendLabelTranslation } from "../../Utils/Adapter/translationAdapter";
import {
  getChartDataWithoutSelection,
  getChartDataWithSelection,
} from "../ChartCalls";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { pieSingleMockJson } from "../../Mock/MockJson";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { Fragment } from "react";

class PieSingleLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      titleText: "",
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime:moment(),
      errorInAPI: false,
      noDataInDB: false
    };
    this.pieName = "";
  }

  data = [];
  timeTicket = null;
  abortController = new window.AbortController();
  requestCheck = true;
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = 1 * 60000;
    }
    // componentWillReceiveProps(props) {
    //     this.getData(props);
    // }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
      this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear
    ) {
      this.getData(this.props, this.props.selectedThemeing);
    }
  }
  // componentWillReceiveProps(props) {
  //     this.getData(props);
  // }

  onChartClick = (cellData) => {
    if (cellData.componentIndex === 2) {
      this.pieName = cellData.name;
      this.getData(this.props);
    }
  };

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  render() {
    let onEvents = {
      click: (cellData) => this.onChartClick(cellData),
    };
    const {errorInAPI, noDataInDB ,refreshDateTime} = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
            <NoRecordComponent/>
            : <ErrorComponent/>
            :
            <ReactEcharts
        option={this.state.option}
        notMerge={true}
        lazyUpdate={true}
        onEvents={onEvents}
        style={{ height: "90%", width: "100%" }}
        className="react_for_echarts"
      />
        }
      </Fragment>
     
    );
  }

  getData = async (props, theme) => {
    let json = [];
    this.requestCheck = false
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false 

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = pieSingleMockJson;
      
      this.requestCheck = true
    } else {
    if (
      (graphprops &&
        graphprops.dashboardType === "dynamic" &&
        this.props.deviceId) ||
      (graphprops &&
        graphprops.dateExists === "true" &&
        this.props.selectionDate) ||
      (graphprops &&
        graphprops.monthExists === "true" &&
        this.props.selectionMonth) ||
      (graphprops &&
        graphprops.weekExists === "true" &&
        this.props.selectionWeek) ||
      (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
      (graphprops &&
        graphprops.dateRangeExists === "true" &&
        this.props.selectionDateRange) ||
      (graphprops &&
        graphprops.dayMonthYearExists === "true" &&
        this.props.dayMonthYear)
    ) {
      json = await getChartDataWithSelection(
        graphprops,
        this.props.deviceId,
        this.props.selectionDate,
        this.props.selectionMonth,
        this.props.selectionWeek,
        this.props.shift,
        this.props.selectionDateRange,
        this.props.dayMonthYear,
        this.abortController
      );
      this.requestCheck = true
    } else if (graphprops) {
      json = await getChartDataWithoutSelection(graphprops,
        this.abortController);
        this.requestCheck = true
    }
  }
  
  if ((json && json.message) || (Array.isArray(json) && (json.length === 0) && 
  (graphprops.enableMock !== true))) {

    if (json.message) {
      errorInAPI = true
    } else if (json.length === 0) {
      noDataInDB = true
    }

  } else {

    // var line = json.line;
    // var pie = json.pie;
    
    let lineObject = [];
    var line = json && json.line;
    var pie = json && json.pie;
  
    // let barObject = [];

    if (this.pieName) {
      lineObject = line[this.pieName];
      this.setState({ titleText: this.pieName });
    } else if (pie) {
      lineObject = line[Object.keys(pie)[0]];
    }

    let yAxis = [
      {
        type: "value",

        // axisLabel: {
        //   interval: 0,
        //   rotate: 30,
        // },
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisTick: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisLabel: {
          color: theme === "lightTheme" ? "black" : "white",
          interval: 0,
          rotate: 30,
        },
      },
    ];
    let xAxis = [
      {
        type: "category",
        data: lineObject.length > 0 && lineObject.map(function (item) {
          return item[0];
        }),
        axisLine: {
          lineStyle: {
            // color: "white",
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisTick: {
          lineStyle: {
            // color: "white",
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisLabel: {
          // color: "white",
          color: theme === "lightTheme" ? "black" : "white",
        },
      },
    ];
    let toolBox = {
      showTitle: false,
      orient: "vertical",
      itemSize: 25,
      feature: {
        saveAsImage: {
          title: "download",
          backgroundColor: theme === "lightTheme" ? "white" : "black",
          name: this.props.graphprops.title,
          // backgroundColor: "black",
          iconStyle: {
            // color: "none",
            borderColor: "#8c8c8c",
            borderWidth: 2,
          },
        },
      },
    };
    let tooltip = {
      trigger: "item",
      formatter: "{b} : {c} ({d}%)",
      textStyle: {
        fontSize: 15,
      },
    };
   
    // let title = {
    //   left: "center",
    //   top: "45%",
    //   textStyle: {
    //     color: "white",
    //   },
    //   text: this.state.titleText  && this.state.titleText != "undefined" ?this.state.titleText : null,
    // };
    let dataZoom = [
      {
        type: "slider",
        show: true,
        start: 0,
        end: 100,
        backgroundColor: "#9a9191",

        textStyle: {
          color: "white",
        },
      },
      {
        type: "inside",
        start: 0,
        end: 100,
      },
    ];
    let series = [
      {
        type: "line",
        stack: "chart",
        z: 3,
        itemStyle: {
          normal: {
            color: this.props.graphprops.lineColor
              ? this.props.graphprops.lineColor
              : "steelblue",
          },
        },

        data: lineObject.length > 0 && lineObject.map(function (item) {
          return item[1];
        }),
      },
      {
        type: "line",

        silent: true,
        itemStyle: {
          normal: {
            color: this.props.graphprops.lineColor
              ? this.props.graphprops.lineColor
              : "red",
          },
        },
        data: lineObject.length > 0 && lineObject.map(function (item) {
          return item[1];
        }),
      },

      {
        type: "pie",
        radius: "30%",
        center: ["50%", "25%"],
        //   color:["steelblue","grey","ora"],
        data: pie && Object.keys(pie).map(function (key) {
          return {
            name: key,
            value: pie[key],
          };
        }),
      },
    ];

    series = await trendLabelTranslation(
      series,
      this.props.translationCache,
      this.props.language
    );
    const option = cloneDeep(this.getOption());
    if (
      this.props.graphprops.colors !== null &&
      this.props.graphprops.colors !== undefined &&
      this.props.graphprops.colors.length !== 0
    ) {
      option.color = [...this.props.graphprops.colors];
    }
    option.series = series;
    option.yAxis = yAxis;
    option.xAxis = xAxis;
    option.dataZoom = dataZoom;
    option.toolbox = toolBox;
    option.tooltip = tooltip;
    // option.title = title;
    this.setState({ 
      option: option,
      refreshDateTime:moment()
     });
  };
  this.setState({
    errorInAPI,
    noDataInDB
  })
} 

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      tooltip: {
        trigger: "item",
        formatter: "{b} : {c} ({d}%)",
      },
      label: {
        color: theme === "lightTheme" ? "black" : "white",
        fontWeight: "bold",
        fontSize: 14,
      },
      textStyle: {
        color: theme === "lightTheme" ? "black" : "white",
        fontWeight: "bold",
        fontSize: 18,

      },
      legend: {
        left: "center",
        textStyle: {
          color: theme === "lightTheme" ? "black" : "white",
        },
      
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: "download",
            backgroundColor: theme === "lightTheme" ? "white" : "black",
            name: this.props.graphprops.title,
            iconStyle: {
              // color: "none",
              borderColor: "#8c8c8c",
              borderWidth: 2,
            },
          },
        },
      },

      grid: { top: "55%" },
      xAxis: [
        {
          type: "value",

          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: theme === "lightTheme" ? "black" : "white",
            },
          },
          axisTick: {
            lineStyle: {
              color: theme === "lightTheme" ? "black" : "white",
            },
          },
          axisLabel: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
      ],
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(PieSingleLine);

