import React, { Component } from "react";
import axios from "axios";
import { Link } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import MonacoEditor from "react-monaco-editor";
import {
  Table,
  Drawer,
  Button,
  Collapse,
  Icon,
  Popover,
  Input,
  Form,
  Row,
  Col,
  Select,
  Tooltip,
} from "antd";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getUserDetails } from "../../../selectors/layout";
import { getCurrentLanguage, getTranslationCache } from "../../../selectors/language";

import { constant } from './Constants';
import localStorage from "../../../utils/localStorage";
import { StyledApplication } from "./style";
import history from "../../../commons/history";

import {
  StyledTable,
  lightTheme,
} from './style'

const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;

const customPanel = {
  background: "#f7f7f7",
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: "hidden",
};


class ReportConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      manocoEditorValue: "",
      editList: [],
      clicked: false,
      tabName: "",
      datasource: [],
      editObj: {},
      reportTypeList: [],
      type: "",
      userId: props.userDeatils,
      translationCache: props.translationCache || [],
      language: props.language || "english",
    };
    this.query = "";
    this.showHideTextArea = false;
  }

  componentWillMount() {
    this._getReportList();
    this._getReportTypeList();
  }
  componentDidUpdate(prevProps) {


    if (prevProps.UserDetails !== this.props.UserDetails) {
      this.setState({
        userId: this.props.UserDetails,
      });
    }
    if (prevProps.translationCache !== this.props.translationCache || prevProps.language !== this.props.language) {
      this.setState({
        translationCache: this.props.translationCache, language: this.props.language
      });
    }

  }
  _getReportList = () => {
    const siteId = localStorage.get("currentSite");
    let accessToken = localStorage.get("accessToken");
    const healthHeaders = {
      method: "GET",
      url: `/api/report/list?SiteId=${siteId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios(healthHeaders).then((response) => {
      if (Array.isArray(response.data)) {
        this.setState({ datasource: response.data });
      }
    }).catch((err) => {
      console.log("err");
    })
  };

  _getReportTypeList = () => {
    const siteId = localStorage.get("currentSite");
    let accessToken = localStorage.get("accessToken");
    const healthHeaders = {
      method: "GET",
      url: `/api/report/reportTypesList?SiteId=${siteId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios(healthHeaders).then((response) => {
      if (Array.isArray(response.data)) {
        this.setState({ reportTypeList: response.data });
      }
    }).catch((err) => {
      console.log("err");
    })
  };

  onClose = () => {
    this.query = "";
    this.showHideTextArea = false;
    this.setState({
      visible: false,
    });
  };

  addNewTab = (e) => {
    let { editList, tabName } = this.state;
    let newObj = {
      TabName: tabName,
      JSON: {},
      type: "save",
    };
    this.query = "";
    this.showHideTextArea = false;
    editList.push(newObj);
    this.setState({ editList, clicked: false });
  };

  handleClickChange = (visible) => {
    this.setState({
      clicked: visible,
    });
  };

  hide = () => {
    this.setState({
      clicked: false,
    });
  };

  onTabNameInput = (e) => {
    this.setState({ tabName: e.target.value });
  };

  addNewReport = () => {
    this.props.form.resetFields();
    this.query = "";
    this.showHideTextArea = false;
    this.setState({
      visible: true,
      editObj: {},
      editList: [],
    });
  };

  saveReport = (object, operation, userId) => {
    if (operation === "delete") {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          let newObj = {
            ReportType: values.ReportType,
            ReportName: values.ReportName,
            TabName: object.TabName,
          };
          this._deleteReportAPI(newObj);
        }
      });
    } else {

      this.props.form.validateFields((err, values) => {
        if (!err) {
          const model = this.refs.monaco.editor.getModel();
          const value = model.getValue();
          if (typeof JSON.parse(value) !== "string") {

            let newObj = {
              JSON: JSON.parse(value),
              TabName: object.TabName,
              ReportType: values.ReportType,
              ReportName: values.ReportName,
              CreatedBy: userId,
              type: operation,
            };

            if (this.query && this.query !== "") {

              newObj.JSON.api.query = this.query;
            }
            this._saveReportAPI(newObj);
          } else {
            alert("Error");
          }
        }
      });
    }
  };

  _saveReportAPI = (payload) => {
    const siteId = localStorage.get("currentSite");
    const accessToken = localStorage.get("accessToken");
    const deviceTypeObject = {
      method: "POST",
      url: `/api/report/insert`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: { ...payload, SiteId: siteId },
    };
    axios(deviceTypeObject)
      .then(() => {
        this.setState({
          visible: false,
        });
        this.props.form.resetFields();
        this._getReportList();
        this.query = "";
        this.showHideTextArea = false;
      })
      .catch(function () { });
  };

  _getReportJsonList = (payload) => {
    const siteId = localStorage.get("currentSite");
    const accessToken = localStorage.get("accessToken");
    const deviceTypeObject = {
      method: "POST",
      url: `/api/report/reportData`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: { ...payload, SiteId: siteId }
    };
    axios(deviceTypeObject)
      .then((response) => {
        let editList = response.data.map((edit) => {
          let newObj = {
            ...edit,
            editEditor: edit.JSON,
          };
          return newObj;
        });

        this.setState({
          editList,
        });
      })
      .catch(function () { });
  };

  _deleteReportAPI = (payload) => {
    const siteId = localStorage.get("currentSite");
    const accessToken = localStorage.get("accessToken");
    const deviceTypeObject = {
      method: "POST",
      url: `/api/report/delete`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: { ...payload, SiteId: siteId },
    };
    axios(deviceTypeObject)
      .then((response) => {
        this.setState({
          visible: false,
        });
        this._getReportList();
      })
      .catch(function () { });
  };

  editorDidMount = (editor, monaco) => {
    setTimeout(function () {
      editor.getAction("editor.action.formatDocument").run();
    }, 300);
  };

  editorWillMount = (monaco) => {
    monaco.languages.typescript.typescriptDefaults.setCompilerOptions({});
  };

  addQuery = (e) => {
    this.query = e.target.value;
  };

  textArea = () => {
    this.showHideTextArea = !this.showHideTextArea;
    this.setState({ showHideTextArea: this.showHideTextArea });
  };

  handleImport = (e) => {
    let context = this;
    var reader = new FileReader();
    reader.readAsText(e[0]);
    reader.onloadend = function () {
      let fileData = JSON.parse(reader.result);
      delete fileData.editObj.ReportName
      fileData.editList.map((list) => {
        return list.type = "save"
      })

      context.setState({ ...fileData });
    };
  };

  handleExport = () => {
    let contentType = "application/json;charset=utf-8;";
    let a = document.createElement("a");
    a.download = `${this.state.editObj.ReportName}`;
    a.href =
      "data:" +
      contentType +
      "," +
      encodeURIComponent(JSON.stringify(this.state));
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record && record[dataIndex] && record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  render() {
    let userDeatils = this.props.UserDetails
    const userId = userDeatils && userDeatils.Id
    const { translationCache, language } = this.state
    const options = {
      selectOnLineNumbers: false,
    };
    const columns = [
      {
        title: translationCache &&
          translationCache[`${constant.Report_Name}`] &&
          translationCache[`${constant.Report_Name}`] ?
          translationCache[`${constant.Report_Name}`] :
          `${constant.Report_Name}`,
        dataIndex: "ReportName",
        key: "ReportName",
        ...this.getColumnSearchProps('ReportName'),
        width: "60%",
        render: (text, record) => {
          return <Link
            style={{ color: "#173049" }}
            to={{ pathname: `/rubus/report/${record.ReportType}/${record.ReportName}` }}
          >{text}</Link>
          // return <div style={{ color: "rgb(24, 144, 255)" }}>{text}</div>
        },
      },
      {
        title: translationCache &&
          translationCache[`${constant.Report_Type}`] &&
          translationCache[`${constant.Report_Type}`] ?
          translationCache[`${constant.Report_Type}`] :
          `${constant.Report_Type}`,
        dataIndex: "ReportType",
        key: "ReportType",
        ...this.getColumnSearchProps('ReportType'),
      },
    ];
    const { getFieldDecorator } = this.props.form;
    const { editObj, reportTypeList } = this.state;

    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 170 }}>
        <Drawer
          title={
            <div>
              <label>Configuration</label>

              <div
                style={{
                  float: "right",
                  color: "black",
                  background: "#ffffff",
                  width: "40px",
                  padding: "0px",
                  borderRadius: "0px",
                  marginLeft: "0px",
                }}
                className="fileContainer"
              >
                <i
                  aria-label="icon: arrow-down"
                  className="anticon anticon-arrow-down"
                  style={{
                    float: "right",
                    fontSize: "x-large",
                    marginRight: "15px",
                  }}
                >
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    className=""
                    data-icon="arrow-down"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0 0 48.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path>
                  </svg>
                  <input
                    type="file"
                    onChange={(e) => this.handleImport(e.target.files)}
                    accept=".json"
                  />
                </i>
              </div>

              <Tooltip placement="bottomLeft" title="Export Json File">
                <Icon
                  style={{
                    float: "right",
                    fontSize: "x-large",
                    marginRight: "15px",
                  }}
                  type="arrow-up"
                  onClick={() => {
                    this.handleExport();
                  }}
                />
              </Tooltip>
            </div>
          }
          placement="right"
          width={1100}
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Report Name :">
                  {getFieldDecorator("ReportName", {
                    initialValue: editObj.ReportName,
                    rules: [
                      { required: true, message: "Please enter Report Name" },
                    ],
                  })(
                    <Input
                      placeholder="Please enter Report Name"
                      disabled={!editObj.ReportName ? false : true}
                    />
                  )}
                </Form.Item>
                <Form.Item label="Report Type :">
                  {getFieldDecorator("ReportType", {
                    initialValue: editObj.ReportType,
                    rules: [
                      { required: true, message: "Please enter Report type" },
                    ],
                  })(
                    <Select
                      placeholder="Please select Report Type"
                      disabled={!editObj.ReportType ? false : true}
                    >
                      {reportTypeList.map((reportType) => {
                        return (
                          <Option value={reportType.ReportType}>
                            {reportType.ReportType}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Popover
            content={
              <div style={{ display: "flex" }}>
                <Input
                  placeholder="Enter TabName"
                  size="small"
                  onChange={this.onTabNameInput}
                />
                <Icon
                  type="enter"
                  style={{
                    fontSize: "22px",
                    marginTop: "1px",
                    marginLeft: "4px",
                    cursor: "pointer",
                  }}
                  onClick={this.addNewTab}
                />
              </div>
            }
            title="Enter TabName"
            trigger="click"
            visible={this.state.clicked}
            onVisibleChange={this.handleClickChange}
          >
            <Button type={"primary"}>Add Items</Button>
          </Popover>
          {this.state.editList.map((list, key) => {
            return (
              <Collapse
                bordered={false}
                style={{ marginTop: "20px" }}
                expandIcon={({ isActive }) => (
                  <Icon type="caret-right" rotate={isActive ? 90 : 0}></Icon>
                )}
              >
                <Panel header={list.TabName} style={customPanel}>
                  <Button
                    type="primary"
                    style={{ marginBottom: "20px" }}
                    onClick={this.textArea}
                  >
                    Add Query
                  </Button>
                  {this.showHideTextArea ? (
                    <TextArea
                      onChange={this.addQuery}
                      style={{ marginBottom: "20px" }}
                    >
                      Add Query
                    </TextArea>
                  ) : null}
                  {
                    <Button
                      type="primary"
                      ghost
                      style={{ marginBottom: "20px", marginLeft: "20px" }}
                      onClick={(e) =>
                        history.push("/rubus/ReportTypeConfiguration")
                      }
                    >
                      Click to redirect to Form Config
                    </Button>
                  }

                  <MonacoEditor
                    width="1000"
                    height="500"
                    language="json"
                    theme="vs-dark"
                    value={JSON.stringify(list.editEditor)}
                    options={options}
                    ref="monaco"
                    // onChange={(e) => this.monacoEditorOnChange(e, key)}
                    editorWillMount={this.editorWillMount}
                    editorDidMount={this.editorDidMount}
                  />
                  {list.type && list.type === "save" ? (
                    <Button
                      type="primary"
                      shape="round"
                      ghost
                      onClick={() => this.saveReport(list, "save", userId)}
                      style={{
                        background: "rgb(190, 200, 200)",
                        marginTop: "10px",
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      shape="round"
                      ghost
                      onClick={() => this.saveReport(list, "update", userId)}
                      style={{
                        background: "rgb(190, 200, 200)",
                        marginTop: "10px",
                      }}
                    >
                      Update
                    </Button>
                  )}
                  {
                    <Button
                      type="primary"
                      shape="round"
                      ghost
                      onClick={() => this.saveReport(list, "delete", userId)}
                      style={{
                        background: "rgb(190, 200, 200)",
                        marginTop: "10px",
                        marginLeft: "5px"
                      }}
                    >
                      Delete
                    </Button>
                  }
                </Panel>
              </Collapse>
            );
          })}
        </Drawer>
        <Button className="ant-btn Ant ant-btn-primary"
          type="primary"
          onClick={this.addNewReport}
          style={{ marginBottom: "10px" }}
        >
          {translationCache &&
            translationCache[`${constant.New_Report}`] &&
            translationCache[`${constant.New_Report}`] ?
            translationCache[`${constant.New_Report}`] :
            `${constant.New_Report}`}
        </Button>
        <div className="Table">
          {/* //  theme={lightTheme} style={{ marginTop: '20px' }}> */}
          <Table
            columns={columns}
            dataSource={this.state.datasource}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  this.setState({
                    visible: true,
                    editObj: record,
                  });
                  this._getReportJsonList(record);
                },
              };
            }}
          />
        </div>
        {/* </StyledTable> */}
      </StyledApplication>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  UserDetails: getUserDetails(),
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),

});
export default connect(mapStateToProps)(Form.create()(ReportConfiguration));