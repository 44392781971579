

export const areaMockJson = [
  // {"interval_alias":"2022-03-15","tagname":"ReliablityFactor","tagvalue":20,"tag_description":"ReliablityFactor"},{"interval_alias":"2022-03-15","tagname":"AvailabilityFactor","tagvalue":30,"tag_description":"AvailabilityFactor"},{"interval_alias":"2022-03-15","tagname":"PerformanceFactor","tagvalue":61,"tag_description":"PerformanceFactor"},{"interval_alias":"2022-03-01","tagname":"ReliablityFactor","tagvalue":26,"tag_description":"ReliablityFactor"},{"interval_alias":"2022-03-01","tagname":"AvailabilityFactor","tagvalue":42,"tag_description":"AvailabilityFactor"},{"interval_alias":"2022-03-01","tagname":"PerformanceFactor","tagvalue":1095,"tag_description":"PerformanceFactor"},{"interval_alias":"2022-03-04","tagname":"ReliablityFactor","tagvalue":12,"tag_description":"ReliablityFactor"},{"interval_alias":"2022-03-04","tagname":"AvailabilityFactor","tagvalue":54,"tag_description":"AvailabilityFactor"},{"interval_alias":"2022-03-04","tagname":"PerformanceFactor","tagvalue":648,"tag_description":"PerformanceFactor"},{"interval_alias":"2022-03-07","tagname":"ReliablityFactor","tagvalue":32,"tag_description":"ReliablityFactor"},{"interval_alias":"2022-03-07","tagname":"AvailabilityFactor","tagvalue":75,"tag_description":"AvailabilityFactor"},{"interval_alias":"2022-03-07","tagname":"PerformanceFactor","tagvalue":2402,"tag_description":"PerformanceFactor"}
  {
    "interval_alias": "2020-05-06T00:05:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.01",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T00:05:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.25",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T00:05:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "0.94",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T00:10:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "0.95",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T00:10:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.56",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T00:10:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "0.99",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T00:20:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.14",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T00:20:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.66",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T00:20:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "1.06",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T00:25:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.12",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T00:25:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.74",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T00:25:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "1.08",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T00:45:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.31",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T00:45:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.35",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T00:45:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "1.00",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T00:50:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.08",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T00:50:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.54",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T00:50:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "1.09",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T00:55:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.27",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T00:55:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.61",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T00:55:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "1.21",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T01:00:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.11",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T01:00:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.81",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T01:00:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "1.02",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T01:15:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.12",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T01:15:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.58",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T01:15:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "0.97",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T01:25:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.35",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T01:25:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.41",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T01:25:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "0.98",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T01:30:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.06",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T01:30:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.45",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T01:30:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "1.01",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T01:40:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.00",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T01:40:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.41",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T01:40:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "1.31",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T01:45:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.14",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T01:45:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.57",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T01:45:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "0.86",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T01:50:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.04",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T01:50:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.65",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T01:50:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "1.22",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T01:55:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.31",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T01:55:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "2.08",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T01:55:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "1.19",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T02:00:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.14",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T02:00:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.42",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T02:00:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "1.25",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T02:05:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.43",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T02:05:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.61",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T02:05:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "1.03",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T02:20:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.41",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T02:20:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.45",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T02:20:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "0.92",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T02:25:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.29",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T02:25:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.61",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T02:25:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "1.10",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T02:35:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.14",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T02:35:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.72",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T02:35:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "1.31",
    "tag_description": "HAccPkZP"
  },
  {
    "interval_alias": "2020-05-06T02:50:00.000Z",
    "tagname": "HAccPkXP",
    "tagvalue": "1.16",
    "tag_description": "HAccPkXP"
  },
  {
    "interval_alias": "2020-05-06T02:50:00.000Z",
    "tagname": "HAccPkYP",
    "tagvalue": "1.64",
    "tag_description": "HAccPkYP"
  },
  {
    "interval_alias": "2020-05-06T02:50:00.000Z",
    "tagname": "HAccPkZP",
    "tagvalue": "1.06",
    "tag_description": "HAccPkZP"
  }
]
export const barMockJson = [
  {
    "interval_alias": "2021-06-25 11:12:15",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:12:15",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:12:15",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:13:15",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:13:15",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:13:15",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:14:15",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:14:15",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:14:15",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:15:15",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:15:15",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:15:15",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:16:15",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:16:15",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:16:15",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:17:15",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:17:15",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:17:15",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:18:14",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:18:14",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:18:14",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:19:14",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:19:14",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:19:14",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:20:14",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:20:14",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:20:14",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:21:14",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:21:14",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:21:14",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:22:14",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:22:14",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:22:14",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:23:14",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:23:14",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:23:14",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:24:14",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:24:14",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:24:14",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:25:14",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:25:14",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:25:14",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:26:14",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:26:14",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:26:14",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:27:14",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:27:14",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:27:14",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:28:15",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:28:15",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:28:15",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:29:15",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:29:15",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:29:15",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:30:15",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:30:15",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:30:15",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:31:15",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:31:15",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:31:15",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:32:15",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:32:15",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:32:15",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:33:15",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:33:15",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:33:15",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:34:15",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:34:15",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:34:15",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:35:15",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:35:15",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:35:15",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:36:14",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:36:14",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:36:14",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:37:14",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:37:14",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:37:14",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:38:14",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:38:14",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:38:14",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:39:14",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:39:14",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:39:14",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:40:14",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:40:14",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:40:14",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  },
  {
    "interval_alias": "2021-06-25 11:41:14",
    "tagname": "Conductivity",
    "tagvalue": 0.29,
    "tag_description": "Conductivity (S/m)"
  },
  {
    "interval_alias": "2021-06-25 11:41:14",
    "tagname": "Salinity",
    "tagvalue": 0.29,
    "tag_description": "Salinity (PSU)"
  },
  {
    "interval_alias": "2021-06-25 11:41:14",
    "tagname": "Temperature_of_water",
    "tagvalue": 29,
    "tag_description": "Temperature of water (DegC)"
  }
]
export const barTableMockJson = [
  {
    "interval_alias": "MDB-12",
    "tagname": "Active_Energy_Delivered_Into_Load",
    "tagvalue": 14988,
    "tag_description": "MDB Energy Meters",
    "anomalyList": [
      {
        "S.No": "1",
        "locationName": "MDB-12",
        "value": 14988,
        "impact": "-1.75 %",
        "severity": "Low"
      }
    ]
  },
  {
    "interval_alias": "Line#02 Polishing#02",
    "tagname": "Active_Energy_Delivered_Into_Load",
    "tagvalue": 3212.4,
    "tag_description": "MDB Energy Meters",
    "anomalyList": [
      {
        "S.No": "1",
        "locationName": "Line#02 Polishing#02",
        "value": 3212.4,
        "impact": "1.57 %",
        "severity": "High"
      }
    ]
  },
  {
    "interval_alias": "Line#02 Polishing#01",
    "tagname": "Active_Energy_Delivered_Into_Load",
    "tagvalue": 2363,
    "tag_description": "MDB Energy Meters",
    "anomalyList": [
      {
        "S.No": "1",
        "locationName": "Line#02 Polishing#01",
        "value": 2363,
        "impact": "2.14 %",
        "severity": "High"
      }
    ]
  },
  {
    "interval_alias": "Line#02 Final squaring",
    "tagname": "Active_Energy_Delivered_Into_Load",
    "tagvalue": 2252.7,
    "tag_description": "MDB Energy Meters",
    "anomalyList": [
      {
        "S.No": "1",
        "locationName": "Line#02 Final squaring",
        "value": 2252.7,
        "impact": "-10.20 %",
        "severity": "Low"
      }
    ]
  },
  {
    "interval_alias": "Line#02 Calibration#03",
    "tagname": "Active_Energy_Delivered_Into_Load",
    "tagvalue": 1223.4,
    "tag_description": "MDB Energy Meters",
    "anomalyList": [
      {
        "S.No": "1",
        "locationName": "Line#02 Calibration#03",
        "value": 1223.4,
        "impact": "-1.26 %",
        "severity": "Low"
      }
    ]
  },
  {
    "interval_alias": "Line#02 Calibration#04",
    "tagname": "Active_Energy_Delivered_Into_Load",
    "tagvalue": 1068.6,
    "tag_description": "MDB Energy Meters",
    "anomalyList": [
      {
        "S.No": "1",
        "locationName": "Line#02 Calibration#04",
        "value": 1068.6,
        "impact": "13.87 %",
        "severity": "High"
      }
    ]
  },
  {
    "interval_alias": "Line#02 Calibration#01",
    "tagname": "Active_Energy_Delivered_Into_Load",
    "tagvalue": 1030.8,
    "tag_description": "MDB Energy Meters",
    "anomalyList": [
      {
        "S.No": "1",
        "locationName": "Line#02 Calibration#01",
        "value": 1030.8,
        "impact": "13.42 %",
        "severity": "High"
      }
    ]
  },
  {
    "interval_alias": "Line#02 Calibration#02",
    "tagname": "Active_Energy_Delivered_Into_Load",
    "tagvalue": 810.9,
    "tag_description": "MDB Energy Meters",
    "anomalyList": [
      {
        "S.No": "1",
        "locationName": "Line#02 Calibration#02",
        "value": 810.9,
        "impact": "-15.94 %",
        "severity": "Low"
      }
    ]
  },
  {
    "interval_alias": "Line#02 Sorting",
    "tagname": "Active_Energy_Delivered_Into_Load",
    "tagvalue": 458.9,
    "tag_description": "MDB Energy Meters",
    "anomalyList": [
      {
        "S.No": "1",
        "locationName": "Line#02 Sorting",
        "value": 458.9,
        "impact": "1.10 %",
        "severity": "High"
      }
    ]
  },
  {
    "interval_alias": "Line#02 Nano Polishing#01",
    "tagname": "Active_Energy_Delivered_Into_Load",
    "tagvalue": 86,
    "tag_description": "MDB Energy Meters",
    "anomalyList": [
      {
        "S.No": "1",
        "locationName": "Line#02 Nano Polishing#01",
        "value": 86,
        "impact": "-0.46 %",
        "severity": "Low"
      }
    ]
  },
  {
    "interval_alias": "Line#02 Pre Squaring",
    "tagname": "Active_Energy_Delivered_Into_Load",
    "tagvalue": 72.9,
    "tag_description": "MDB Energy Meters",
    "anomalyList": [
      {
        "S.No": "1",
        "locationName": "Line#02 Pre Squaring",
        "value": 72.9,
        "impact": "-2.67 %",
        "severity": "Low"
      }
    ]
  },
  {
    "interval_alias": "Line#02 Polishing#03",
    "tagname": "Active_Energy_Delivered_Into_Load",
    "tagvalue": 57.6,
    "tag_description": "MDB Energy Meters",
    "anomalyList": [
      {
        "S.No": "1",
        "locationName": "Line#02 Polishing#03",
        "value": 57.6,
        "impact": "-0.86 %",
        "severity": "Low"
      }
    ]
  },
  {
    "interval_alias": "Line#02 Nano Polishing#02",
    "tagname": "Active_Energy_Delivered_Into_Load",
    "tagvalue": 22.2,
    "tag_description": "MDB Energy Meters",
    "anomalyList": [
      {
        "S.No": "1",
        "locationName": "Line#02 Nano Polishing#02",
        "value": 22.2,
        "impact": "0.91 %",
        "severity": "High"
      }
    ]
  }
];
export const beijingChartMockJson =
  [{
    "interval_alias": "2020-05-05T00:10:00.000Z", "tagvalue": "350", "tag_description": "VelPkXP", "tagname": "VelPkXP"
  },
  {
    "interval_alias": "2020-05-05T00:10:10.000Z", "tagvalue": "300", "tag_description": "VelPkXP", "tagname": "VelPkXP"
  },
  {
    "interval_alias": "2020-05-05T00:10:20.000Z", "tagvalue": "250", "tag_description": "VelPkXP", "tagname": "VelPkXP"
  },
  {
    "interval_alias": "2020-05-05T00:10:30.000Z", "tagvalue": "200", "tag_description": "VelPkXP", "tagname": "VelPkXP"
  },
  {
    "interval_alias": "2020-05-05T00:10:40.000Z", "tagvalue": "150", "tag_description": "VelPkXP", "tagname": "VelPkXP"
  }, {
    "interval_alias": "2020-05-05T00:10:55.000Z", "tagvalue": "100", "tag_description": "VelPkXP", "tagname": "VelPkXP"
  }, {
    "interval_alias": "2020-05-05T00:11:00.000Z", "tagvalue": "50", "tag_description": "VelPkXP", "tagname": "VelPkXP"
  }]
export const boxPlotMockJson = [
  [850, 740, 900, 1070, 930, 850, 950, 980, 980, 880, 1000, 980, 930, 650, 760, 810, 1000, 1000, 960, 960],
  [960, 940, 960, 940, 880, 800, 850, 880, 900, 840, 830, 790, 810, 880, 880, 830, 800, 790, 760, 800],
]
export const bubbleChartMockJson = [
  [[28604, 77, 17096869, 'Australia', 1990], [31163, 77.4, 27662440, 'Canada', 1990], [1516, 68, 1154605773, 'China', 1990], [13670, 74.7, 10582082, 'Cuba', 1990], [28599, 75, 4986705, 'Finland', 1990], [29476, 77.1, 56943299, 'France', 1990], [31476, 75.4, 78958237, 'Germany', 1990], [28666, 78.1, 254830, 'Iceland', 1990], [1777, 57.7, 870601776, 'India', 1990], [29550, 79.1, 122249285, 'Japan', 1990], [2076, 67.9, 20194354, 'North Korea', 1990], [12087, 72, 42972254, 'South Korea', 1990], [24021, 75.4, 3397534, 'New Zealand', 1990], [43296, 76.8, 4240375, 'Norway', 1990], [10088, 70.8, 38195258, 'Poland', 1990], [19349, 69.6, 147568552, 'Russia', 1990], [10670, 67.3, 53994605, 'Turkey', 1990], [26424, 75.7, 57110117, 'United Kingdom', 1990], [37062, 75.4, 252847810, 'United States', 1990]],
  [[44056, 81.8, 23968973, 'Australia', 2015], [43294, 81.7, 35939927, 'Canada', 2015], [13334, 76.9, 1376048943, 'China', 2015], [21291, 78.5, 11389562, 'Cuba', 2015], [38923, 80.8, 5503457, 'Finland', 2015], [37599, 81.9, 64395345, 'France', 2015], [44053, 81.1, 80688545, 'Germany', 2015], [42182, 82.8, 329425, 'Iceland', 2015], [5903, 66.8, 1311050527, 'India', 2015], [36162, 83.5, 126573481, 'Japan', 2015], [1390, 71.4, 25155317, 'North Korea', 2015], [34644, 80.7, 50293439, 'South Korea', 2015], [34186, 80.6, 4528526, 'New Zealand', 2015], [64304, 81.6, 5210967, 'Norway', 2015], [24787, 77.3, 38611794, 'Poland', 2015], [23038, 73.13, 143456918, 'Russia', 2015], [19360, 76.5, 78665830, 'Turkey', 2015], [38225, 81.4, 64715810, 'United Kingdom', 2015], [53354, 79.1, 321773631, 'United States', 2015]]
];
export const CandleStickMockJson = [
  {
    "date": "20-01-2020",
    "values": [10, 20, 30, 40]
  },
  {
    "date": "20-01-2020",
    "values": [10, 40, 30, 40]
  }
];
export const candleStickBrushMockJson = [
  [
    "2004-01-02",
    10452.74,
    10409.85,
    10367.41,
    10554.96,
    168890000
  ],]
export const countMock = [{ "interval_alias": "2020-05-12T21:49:05.161Z", "tagname": "TRR_TIC301_PV", "tagvalue": 395.4, "tag_description": "Main stream temperature control" }]
export const cardListMockJson = [{
  title: "Current",
  value: "395.40"
}, {
  title: "Voltage",
  value: "395.50"
}, {
  title: "Watts",
  value: "395.60"
}, {
  title: "Mode of Operation",
  value: "395.40"
}, {
  title: "Illumination(LUX)",
  value: "395.50"
}, {
  title: "Lights status",
  value: "395.60"
}]
export const donutMockJson = [
  {
    interval_alias: "2020-04-08 15:31:33",
    tagname: "Bearing In Board",
    tagvalue: 81,
  },
  {
    interval_alias: "2020-04-08 15:31:33",
    tagname: "Bearing Out Board",
    tagvalue: 43,
  },
];
export const dottedBarMockJson = [
  {
    intervals: [2020, 2021, 2022, 2023, 2024, 2025],
    lineData: {
      tagName: "EnergyMeterer 1",
      values: [2, 4, 6, 8, 10, 12],
    },
    barData: {
      tagName: "EnergyMeterer 2",
      values: [1, 2, 3, 4, 5, 6],
    },
  },
];
export const dynamicMock = []
export const funnelMockJson = [{ "interval_alias": "2020-04-08 00:00:03", "tagname": "Vi_07_fuel_level_perc", "tagvalue": 90 },
{ "interval_alias": "2020-04-08 00:00:03", "tagname": "Vi_07_Pressure_level_perc", "tagvalue": 70 },
{ "interval_alias": "2020-04-08 00:00:03", "tagname": "Vi_08_level_perc", "tagvalue": 60 },
{ "interval_alias": "2020-04-08 00:00:03", "tagname": "Vi_09_level_perc", "tagvalue": 50 },
{ "interval_alias": "2020-04-08 00:00:03", "tagname": "Vi_10_level_perc", "tagvalue": 30 },]
export const guageMockJson = [{ "interval_alias": "2020-05-12T19:29:04.919Z", "tagname": "TRR_PWC1002_PV", "tagvalue": 1998, "tag_description": "Constant control on generating power" }]
export const guageLineMockJson =
{
  line: [
    {
      interval_alias: "2020-09-21 05:46:00",
      tagname: "DWLC_PV",
      tagvalue: "21.88",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:46:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "-7.14",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:47:00",
      tagname: "DWLC_PV",
      tagvalue: "1.58",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:47:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "-4.77",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:48:00",
      tagname: "DWLC_PV",
      tagvalue: "-1.85",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:48:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "-4.08",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:49:00",
      tagname: "DWLC_PV",
      tagvalue: "-1.78",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:49:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "-3.20",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:50:00",
      tagname: "DWLC_PV",
      tagvalue: "1.92",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:50:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "-1.81",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:51:00",
      tagname: "DWLC_PV",
      tagvalue: "4.53",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:51:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "0.06",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:52:00",
      tagname: "DWLC_PV",
      tagvalue: "4.00",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:52:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "0.83",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:53:00",
      tagname: "DWLC_PV",
      tagvalue: "2.38",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:53:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "2.33",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:54:00",
      tagname: "DWLC_PV",
      tagvalue: "-2.79",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:54:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "3.87",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:55:00",
      tagname: "DWLC_PV",
      tagvalue: "-6.66",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:55:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "4.71",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:56:00",
      tagname: "DWLC_PV",
      tagvalue: "-4.57",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:56:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "20.63",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:57:00",
      tagname: "DWLC_PV",
      tagvalue: "-2.37",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:57:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "25.82",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:58:00",
      tagname: "DWLC_PV",
      tagvalue: "2.88",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:58:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "23.21",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:59:00",
      tagname: "DWLC_PV",
      tagvalue: "7.54",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:59:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "20.35",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 06:00:00",
      tagname: "DWLC_PV",
      tagvalue: "11.68",
      tag_description: "Deaerator water level control PV",
    },
  ],
  guage: 
 [ {
    "interval_alias": "2020-04-08 00:00:03", "tagname": "Vi_07_fuel_level_perc", "tagvalue": 90 }]
};
export const heatmapCalendarMockJson = [{
  intervalAlias: "2021-01-10",
  tagValue: "1000"
}, {
  intervalAlias: "2021-02-10",
  tagValue: "2000"
}, {
  intervalAlias: "2021-03-10",
  tagValue: "3000"
}, {
  intervalAlias: "2021-04-10",
  tagValue: "4000"
}, {
  intervalAlias: "2021-05-10",
  tagValue: "5000"
}, {
  intervalAlias: "2021-06-10",
  tagValue: "6000"
}, {
  intervalAlias: "2021-07-10",
  tagValue: "7000"
}, {
  intervalAlias: "2021-08-10",
  tagValue: "8000"
}, {
  intervalAlias: "2021-09-10",
  tagValue: "9000"
}, {
  intervalAlias: "2021-10-10",
  tagValue: "10000"
}, {
  intervalAlias: "2021-11-10",
  tagValue: "5000"
}, {
  intervalAlias: "2021-12-10",
  tagValue: "8000"
},
]
export const heatmapCartesionMockJson = [
  [0, 0, 5], [0, 1, 1], [0, 2, 0], [0, 3, 0], [0, 4, 0], [0, 5, 0], [0, 6, 0], [0, 7, 0], [0, 8, 0],
  [0, 9, 0], [0, 10, 0], [0, 11, 2], [0, 12, 4], [0, 13, 1], [0, 14, 1], [0, 15, 3], [0, 16, 4], [0, 17, 6],
  [0, 18, 4], [0, 19, 4], [0, 20, 3], [0, 21, 3], [0, 22, 2], [0, 23, 5],
  [1, 0, 7], [1, 1, 0], [1, 2, 0], [1, 3, 0], [1, 4, 0], [1, 5, 0], [1, 6, 0], [1, 7, 0], [1, 8, 0], [1, 9, 0],
  [1, 10, 5], [1, 11, 2], [1, 12, 2], [1, 13, 6], [1, 14, 9], [1, 15, 11], [1, 16, 6], [1, 17, 7], [1, 18, 8],
  [1, 19, 12], [1, 20, 5], [1, 21, 5], [1, 22, 7], [1, 23, 2],
  [2, 0, 1], [2, 1, 1], [2, 2, 0], [2, 3, 0], [2, 4, 0], [2, 5, 0], [2, 6, 0], [2, 7, 0], [2, 8, 0], [2, 9, 0],
  [2, 10, 3], [2, 11, 2], [2, 12, 1], [2, 13, 9], [2, 14, 8], [2, 15, 10], [2, 16, 6], [2, 17, 5], [2, 18, 5],
  [2, 19, 5], [2, 20, 7], [2, 21, 4], [2, 22, 2], [2, 23, 4],
  [3, 0, 7], [3, 1, 3], [3, 2, 0], [3, 3, 0], [3, 4, 0], [3, 5, 0], [3, 6, 0], [3, 7, 0], [3, 8, 1], [3, 9, 0],
  [3, 10, 5], [3, 11, 4], [3, 12, 7], [3, 13, 14], [3, 14, 13], [3, 15, 12], [3, 16, 9], [3, 17, 5], [3, 18, 5],
  [3, 19, 10], [3, 20, 6], [3, 21, 4], [3, 22, 4], [3, 23, 1],
  [4, 0, 1], [4, 1, 3], [4, 2, 0], [4, 3, 0], [4, 4, 0], [4, 5, 1], [4, 6, 0], [4, 7, 0], [4, 8, 0], [4, 9, 2], [4, 10, 4],
  [4, 11, 4], [4, 12, 2], [4, 13, 4], [4, 14, 4], [4, 15, 14], [4, 16, 12], [4, 17, 1], [4, 18, 8], [4, 19, 5], [4, 20, 3],
  [4, 21, 7], [4, 22, 3], [4, 23, 0],
  [5, 0, 2], [5, 1, 1], [5, 2, 0], [5, 3, 3], [5, 4, 0], [5, 5, 0], [5, 6, 0], [5, 7, 0], [5, 8, 2], [5, 9, 0], [5, 10, 4],
  [5, 11, 1], [5, 12, 5], [5, 13, 10], [5, 14, 5], [5, 15, 7], [5, 16, 11], [5, 17, 6], [5, 18, 0], [5, 19, 5], [5, 20, 3],
  [5, 21, 4], [5, 22, 2], [5, 23, 0], [6, 0, 1], [6, 1, 0], [6, 2, 0], [6, 3, 0], [6, 4, 0], [6, 5, 0], [6, 6, 0], [6, 7, 0],
  [6, 8, 0], [6, 9, 0], [6, 10, 1], [6, 11, 0], [6, 12, 2], [6, 13, 1], [6, 14, 3], [6, 15, 4], [6, 16, 0], [6, 17, 0],
  [6, 18, 0], [6, 19, 0], [6, 20, 1], [6, 21, 2], [6, 22, 2], [6, 23, 6]
]
export const liquidgaugeMockJson = [{ "interval_alias": "2020-04-08 00:00:03", "tagname": "Vi_07_fuel_level_perc", "tagvalue": 90 },]

export const pieMockJson = [
  { tagValue: 1548, tagName: "HAccPkYY" },
  { tagValue: 535, tagName: "HAccPkXP" },
  { tagValue: 510, tagName: "HAccPkYP" },
  { tagValue: 634, tagName: "HAccPkZP" },
  { tagValue: 735, tagName: "HAccPkYZ" }
]
export const pieBarMockJson = {
  "bar": {
    "Envision": {
      "frontend": 5000,
      "backend": 6000,
      "database": 2364,
      "Implementation": 1234
    },
    "Rubus": {
      "frontend": 3237,
      "backend": 2164,
      "database": 2364,
      "Implementation": 1234
    },
    "Iportman": {
      "frontend": 3237,
      "backend": 2164,
      "database": 2364,
      "Implementation": 1234
    },
    "Maximo": {
      "frontend": 3237,
      "backend": 2164,
      "database": 2364,
      "Implementation": 1234
    }
  },
  "pie": {
    "Envision": 17365,
    "Rubus": 4079,
    "Iportman": 6929,
    "Maximo": 14890
  }
}

export const pieNestJson ={
  "inner": [
      { tagValue: 1548, tagName: 'Search Engine' },
      { tagValue: 775, tagName: 'Direct' },
      { tagValue: 679, tagName: 'Marketing' }
  ],
  "outer": [
      { tagValue: 1048, tagName: 'Baidu' },
      { tagValue: 335, tagName: 'Direct' },
      { tagValue: 310, tagName: 'Email' },
      { tagValue: 251, tagName: 'Google' },
      { tagValue: 234, tagName: 'Union Ads' },
      { tagValue: 147, tagName: 'Bing' },
      { tagValue: 135, tagName: 'Video Ads' },
      { tagValue: 102, tagName: 'Others' }]
}
export const PieNestJSon = {
  "inner": [
      { value: 1548, name: 'Search Engine' },
      { value: 775, name: 'Direct' },
      { value: 679, name: 'Marketing' }
  ],
  "outer": [
      { value: 1048, name: 'Baidu' },
      { value: 335, name: 'Direct' },
      { value: 310, name: 'Email' },
      { value: 251, name: 'Google' },
      { value: 234, name: 'Union Ads' },
      { value: 147, name: 'Bing' },
      { value: 135, name: 'Video Ads' },
      { value: 102, name: 'Others' }]
  }
export const pieLineMockJson =
{
  line: [
    {
      interval_alias: "2020-09-21 05:46:00",
      tagname: "DWLC_PV",
      tagvalue: "21.88",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:46:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "-7.14",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:47:00",
      tagname: "DWLC_PV",
      tagvalue: "1.58",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:47:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "-4.77",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:48:00",
      tagname: "DWLC_PV",
      tagvalue: "-1.85",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:48:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "-4.08",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:49:00",
      tagname: "DWLC_PV",
      tagvalue: "-1.78",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:49:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "-3.20",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:50:00",
      tagname: "DWLC_PV",
      tagvalue: "1.92",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:50:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "-1.81",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:51:00",
      tagname: "DWLC_PV",
      tagvalue: "4.53",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:51:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "0.06",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:52:00",
      tagname: "DWLC_PV",
      tagvalue: "4.00",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:52:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "0.83",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:53:00",
      tagname: "DWLC_PV",
      tagvalue: "2.38",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:53:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "2.33",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:54:00",
      tagname: "DWLC_PV",
      tagvalue: "-2.79",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:54:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "3.87",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:55:00",
      tagname: "DWLC_PV",
      tagvalue: "-6.66",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:55:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "4.71",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:56:00",
      tagname: "DWLC_PV",
      tagvalue: "-4.57",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:56:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "20.63",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:57:00",
      tagname: "DWLC_PV",
      tagvalue: "-2.37",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:57:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "25.82",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:58:00",
      tagname: "DWLC_PV",
      tagvalue: "2.88",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:58:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "23.21",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 05:59:00",
      tagname: "DWLC_PV",
      tagvalue: "7.54",
      tag_description: "Deaerator water level control PV",
    },
    {
      interval_alias: "2020-09-21 05:59:00",
      tagname: "ESCTWLC_PV",
      tagvalue: "20.35",
      tag_description:
        "Exhaust steam condenser tank water level  Control PV",
    },
    {
      interval_alias: "2020-09-21 06:00:00",
      tagname: "DWLC_PV",
      tagvalue: "11.68",
      tag_description: "Deaerator water level control PV",
    },
  ],
  pie: {
    "Exhaust steam condenser tank water level  Control PV": 17365,
    "Deaerator water level control PV": 4079,
  },
};

export const pieRoseMockJson = [
  { tagValue: 1548, tagName: "HAccPkYY" },
  { tagValue: 22, tagName: "HAccPkXP" },
  { tagValue: 510, tagName: "HAccPkYP" },
  { tagValue: 634, tagName: "HAccPkZP" },
  { tagValue: 735, tagName: "HAccPkYZ" }
]
export const pieSingleMockJson = { "line": { "Site_1": [["2000-06-05", 116], ["2000-06-06", 129], ["2000-06-07", 135], ["2000-06-08", 86], ["2000-06-09", 73], ["2000-06-10", 85], ["2000-06-11", 73], ["2014-07-25", 134], ["2014-07-26", 188], ["2014-07-27", 194], ["2014-07-28", 159], ["2014-07-29", 159], ["2014-07-30", 169], ["2014-07-31", 244], ["2014-08-01", 199], ["2014-08-02", 163], ["2014-08-03", 149], ["2014-08-05", 80]], "Site_2": [["2000-06-05", 116], ["2000-06-06", 129], ["2000-06-07", 135], ["2000-06-08", 86], ["2000-06-09", 73], ["2000-06-10", 85], ["2000-06-11", 73], ["2014-07-25", 134], ["2014-07-26", 188], ["2014-07-27", 194], ["2014-07-28", 159], ["2014-07-29", 159], ["2014-07-30", 169], ["2014-07-31", 244], ["2014-08-01", 199], ["2014-08-02", 163], ["2014-08-03", 149], ["2014-08-05", 80], ["2014-08-06", 67], ["2014-08-07", 162], ["2014-08-08", 140]], "Site_3": [["2000-06-05", 116], ["2000-06-06", 129], ["2000-06-07", 135], ["2000-06-08", 86], ["2000-06-09", 73], ["2000-06-10", 85], ["2000-06-11", 73], ["2014-07-25", 134], ["2014-07-26", 188], ["2014-07-27", 194], ["2014-07-28", 159], ["2014-07-29", 159], ["2014-07-30", 169], ["2014-07-31", 244], ["2014-08-01", 199], ["2014-08-02", 163], ["2014-08-03", 149], ["2014-08-05", 80], ["2014-08-06", 67], ["2014-08-07", 162], ["2014-08-08", 140]], "Site_4": [["2000-06-05", 116], ["2000-06-06", 129], ["2000-06-07", 135], ["2000-06-08", 86], ["2000-06-09", 73], ["2000-06-10", 85], ["2000-06-11", 73], ["2014-07-25", 134], ["2014-07-26", 188], ["2014-07-27", 194], ["2014-07-28", 159], ["2014-07-29", 159], ["2014-07-30", 169], ["2014-07-31", 244], ["2014-08-01", 199], ["2014-08-02", 163], ["2014-08-03", 149], ["2014-08-05", 80], ["2014-08-06", 67], ["2014-08-07", 162]] }, "pie": { "Site_1": 17365, "Site_2": 4079, "Site_3": 6929, "Site_4": 14890 } }

export const regressionMockJson = [
  [1, 4862.4],
  [2, 5294.7],
  [3, 5934.5],
  [4, 7171.0],
  [5, 8964.4],
  [6, 10202.2],
  [7, 11962.5],
  [8, 14928.3],
  [9, 16909.2],
  [10, 18547.9],
  [11, 21617.8],
  [12, 26638.1],
  [13, 34634.4],
  [14, 46759.4],
  [15, 58478.1],
  [16, 67884.6],
  [17, 74462.6],
  [18, 1200.7]
];
export const scatterMock = [{
  scatter: [
    ["2020-08-12 12:48:02", 7, "Pressure"],
    ["2020-08-12 12:50:02", 5, "Pressure"],
    ["2020-08-12 12:52:02", 7, "Pressure"],
    ["2020-08-12 12:53:02", 9, "Pressure"],
    ["2020-08-12 12:53:02", 5, "Pressure"],
    ["2020-08-12 12:52:03", 5, "Pressure"],
    ["2020-08-12 12:55:02", 8, "Pressure"],
    ["2020-08-12 12:54:02", 6, "Pressure"],
    ["2020-08-12 12:57:02", 10, "Pressure"],
    ["2020-08-12 12:58:02", 7, "Pressure"],
    ["2020-08-12 12:58:02", 9, "Pressure"],
    ["2020-08-12 12:59:02", 4, "Pressure"],
    ["2020-08-12 12:59:02", 5, "Pressure"],
    ["2020-08-12 13:00:02", 4, "Pressure"]
  ],
  effectScatter: [
    ["2020-08-12 12:51:02", 13, "Pressure"],
    ["2020-08-12 12:56:02", 15, "Pressure"]
  ]
},
{
  scatter: [
    ["2020-08-12 12:48:02", 8, "Temprature"],
    ["2020-08-12 12:50:02", 4, "Temprature"],
    ["2020-08-12 12:52:02", 8, "Temprature"],
    ["2020-08-12 12:53:02", 10, "Temprature"],
    ["2020-08-12 12:53:02", 6, "Temprature"],
    ["2020-08-12 12:52:03", 4, "Temprature"],
    ["2020-08-12 12:55:02", 9, "Temprature"],
    ["2020-08-12 12:54:02", 7, "Temprature"],
    ["2020-08-12 12:57:02", 9, "Temprature"],
    ["2020-08-12 12:58:02", 10, "Temprature"],
    ["2020-08-12 12:58:02", 7, "Temprature"],
    ["2020-08-12 12:59:02", 6, "Temprature"],
    ["2020-08-12 12:59:02", 8, "Temprature"],
    ["2020-08-12 13:00:02", 6, "Temprature"]
  ],
  effectScatter: [
    ["2020-08-12 12:51:02", 19, "Temprature"],
    ["2020-08-12 12:56:02", 18, "Temprature"],
  ]
}

]
export const singleBarMock = [{
  interval_alias: "2020-09-21 05:57:00",
  tagname: "ESCTWLC_PV",
  tagvalue: "25.82",
  tag_description:
    "Exhaust steam condenser tank water level  Control PV",
},]
export const singleCandleStickMock = [{
  interval_alias: "2020-09-21 05:57:00",
  tagname: "ESCTWLC_PV",
  tagvalue: "25.82",
  tag_description:
    "Exhaust steam condenser tank water level  Control PV",
},]

export const sliderMockJson = []
export const stackBarMockJson = []
export const sliderstatus = [
  {
    "interval_alias": "2021-06-25 11:12:15",
    "tagname": "Load",
    "tagvalue": 20,
    "tag_description": "Load",
    "color": "green",
    "gt": 0,
    "lte": 8
  },
  {
    "interval_alias": "2021-06-25 11:12:15",
    "tagname": "BeltSpeed",
    "tagvalue": 20,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 0,
    "lte": 8
  },
  {
    "interval_alias": "2021-06-25 11:13:15",
    "tagname": "Load",
    "tagvalue": 29,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:13:15",
    "tagname": "BeltSpeed",
    "tagvalue": 19,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 0,
    "lte": 8
  },
  {
    "interval_alias": "2021-06-25 11:13:15",
    "tagname": "BeltSpeed",
    "tagvalue": 19,
    "tag_description": "BeltSpeed",
    "color": "red",
    "gt": 8,
    "lte": 15
  },
  {
    "interval_alias": "2021-06-25 11:14:15",
    "tagname": "Load",
    "tagvalue": 29,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:14:15",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 15,
    "lte": 20
  },
  {
    "interval_alias": "2021-06-25 11:15:15",
    "tagname": "Load",
    "tagvalue": 25,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:15:15",
    "tagname": "BeltSpeed",
    "tagvalue": 25,
    "tag_description": "BeltSpeed",
    "color": "red",
    "gt": 20,
    "lte": 25
  },
  {
    "interval_alias": "2021-06-25 11:16:15",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:16:15",
    "tagname": "BeltSpeed",
    "tagvalue": 27,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:17:15",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:17:15",
    "tagname": "BeltSpeed",
    "tagvalue": 9,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:18:14",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:18:14",
    "tagname": "BeltSpeed",
    "tagvalue": 19,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:19:14",
    "tagname": "Load",
    "tagvalue": 29,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:19:14",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:20:14",
    "tagname": "Load",
    "tagvalue": 29,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:20:14",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:21:14",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:21:14",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:22:14",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:22:14",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:23:14",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:23:14",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:24:14",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:24:14",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:25:14",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:25:14",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:26:14",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:26:14",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:27:14",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:27:14",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:28:15",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:28:15",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:29:15",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:29:15",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:30:15",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:30:15",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:31:15",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:31:15",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:32:15",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:32:15",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:33:15",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:33:15",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:34:15",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:34:15",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:35:15",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:35:15",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:36:14",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:36:14",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:37:14",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:37:14",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:38:14",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:38:14",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:39:14",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:39:14",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:40:14",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:40:14",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  },
  {
    "interval_alias": "2021-06-25 11:41:14",
    "tagname": "Load",
    "tagvalue": 27,
    "tag_description": "Load"
  },
  {
    "interval_alias": "2021-06-25 11:41:14",
    "tagname": "BeltSpeed",
    "tagvalue": 29,
    "tag_description": "BeltSpeed",
    "color": "green",
    "gt": 25,
    "lte": null
  }
]
export const stackedBarMock =
{
  "bar": [
    {
      "intervalAlias": ["11-11-2020", "12-11-2020", "13-11-2020", "14-11-2020", "15-11-2020", "16-11-2020", "17-11-2020"
      ]
    },
    {
      "tagName": "meter1",
      "tagValue": [100, 200, 300, 400, 500, 600, 700
      ]
    },
    {
      "tagName": "meter2",
      "tagValue": [120, 130, 140, 150, 160, 170, 180]
    },
    {
      "tagName": "meter3",
      "tagValue": [220, 230, 240, 250, 260, 270, 280
      ]
    },
    {
      "tagName": "meter4",
      "tagValue": [320, 330, 340, 350, 350, 360, 370]

    },
    {
      "tagName": "meter5",
      "tagValue": [420, 430, 440, 450, 460, 470, 480]
    }
  ],
  "table": [{ "name": "Meter1", "consumption": 2800, "location": "Boiler1" }, { "name": "Meter2", "consumption": 1050, "location": "Conveyor" },
  { "name": "Meter3", "consumption": 1750, "location": "Turbine" }, { "name": "Meter4", "consumption": 2070, "location": "Boiler2" },
  { "name": "Meter5", "consumption": 3150, "location": "Compressor" }]
}
export const progressBarMock = { "interval_alias": "2020-05-13T14:22:16.303Z", "tagname": "TRR_PIC301_PV", "tagvalue": 45, "tag_description": "Main steam pressure control" }
export const statusCounterMock =
  [
    {
      interval_alias: "2020-05-12T21:49:05.161Z",
      tagname: "TRR_TIC301_PV",
      tagvalue: 395.4,
      status: "Low",
      statusValue: "54.0",
      tag_description: "Main stream temperature control",
    },
  ];

export const tableMock = [{ "PredictionTime": "1 minute", "Main stream temperature control": 421.56, "Main steam pressure control": 5.72, "Main steam volume": 12.26, "Moisture": 72 }, { "PredictionTime": "2 minutes", "Main stream temperature control": 421.55, "Main steam pressure control": 5.72, "Main steam volume": 12.11, "Moisture": 68 }, { "PredictionTime": "3 minutes", "Main stream temperature control": 421.48, "Main steam pressure control": 5.67, "Main steam volume": 12.48, "Moisture": 74 }, { "PredictionTime": "4 minutes", "Main stream temperature control": 420.15, "Main steam pressure control": 5.57, "Main steam volume": 12.81, "Moisture": 76 }, { "PredictionTime": "5 minutes", "Main stream temperature control": 421.22, "Main steam pressure control": 5.68, "Main steam volume": 12.92, "Moisture": 73 }]
export const treeGraphMock = []
export const windBarbMock = [
  {
    time: "2017-06-27T11:00:00.000Z",
    windSpeed: 9,
    R: "NNW",
    waveHeight: 2.64,
  },
  {
    time: "2017-06-27T12:30:00.000Z",
    windSpeed: 10,
    R: "NNW",
    waveHeight: 2.57,
  },
  {
    time: "2017-06-27T14:00:00.000Z",
    windSpeed: 12,
    R: "NNW",
    waveHeight: 2.49,
  },
  {
    time: "2017-06-27T15:30:00.000Z",
    windSpeed: 12,
    R: "NNW",
    waveHeight: 2.44,
  },
  {
    time: "2017-06-27T17:00:00.000Z",
    windSpeed: 11,
    R: "NNW",
    waveHeight: 2.38,
  },
  {
    time: "2017-06-27T18:30:00.000Z",
    windSpeed: 10,
    R: "NNW",
    waveHeight: 2.34,
  },
  {
    time: "2017-06-27T20:00:00.000Z",
    windSpeed: 10,
    R: "N",
    waveHeight: 2.3,
  },
  {
    time: "2017-06-27T21:30:00.000Z",
    windSpeed: 11,
    R: "NNW",
    waveHeight: 2.26,
  },
  {
    time: "2017-06-27T23:00:00.000Z",
    windSpeed: 12,
    R: "NNW",
    waveHeight: 2.22,
  },
  {
    time: "2017-06-28T00:30:00.000Z",
    windSpeed: 12,
    R: "N",
    waveHeight: 2.18,
  },
  {
    time: "2017-06-28T02:00:00.000Z",
    windSpeed: 12,
    R: "N",
    waveHeight: 2.13,
  },
  {
    time: "2017-06-28T03:30:00.000Z",
    windSpeed: 12,
    R: "N",
    waveHeight: 2.09,
  },
  {
    time: "2017-06-28T05:00:00.000Z",
    windSpeed: 12,
    R: "NNE",
    waveHeight: 2.04,
  },
  {
    time: "2017-06-28T06:30:00.000Z",
    windSpeed: 10,
    R: "N",
    waveHeight: 2.01,
  },
  {
    time: "2017-06-28T08:00:00.000Z",
    windSpeed: 9,
    R: "N",
    waveHeight: 1.99,
  },
  {
    time: "2017-06-28T09:30:00.000Z",
    windSpeed: 8,
    R: "NNW",
    waveHeight: 2,
  },
  {
    time: "2017-06-28T11:00:00.000Z",
    windSpeed: 8,
    R: "NW",
    waveHeight: 2.01,
  },
  {
    time: "2017-06-28T12:30:00.000Z",
    windSpeed: 8,
    R: "NW",
    waveHeight: 2.06,
  },
  {
    time: "2017-06-28T14:00:00.000Z",
    windSpeed: 9,
    R: "WNW",
    waveHeight: 2.12,
  },
  {
    time: "2017-06-28T15:30:00.000Z",
    windSpeed: 10,
    R: "WNW",
    waveHeight: 2.19,
  },
  {
    time: "2017-06-28T17:00:00.000Z",
    windSpeed: 11,
    R: "WNW",
    waveHeight: 2.27,
  },
  {
    time: "2017-06-28T18:30:00.000Z",
    windSpeed: 12,
    R: "WNW",
    waveHeight: 2.33,
  },
  {
    time: "2017-06-28T20:00:00.000Z",
    windSpeed: 13,
    R: "NW",
    waveHeight: 2.39,
  },
  {
    time: "2017-06-28T21:30:00.000Z",
    windSpeed: 13,
    R: "NW",
    waveHeight: 2.43,
  },
  {
    time: "2017-06-28T23:00:00.000Z",
    windSpeed: 14,
    R: "NW",
    waveHeight: 2.46,
  },
  {
    time: "2017-06-29T00:30:00.000Z",
    windSpeed: 16,
    R: "NW",
    waveHeight: 2.48,
  },
  {
    time: "2017-06-29T02:00:00.000Z",
    windSpeed: 18,
    R: "NNW",
    waveHeight: 2.49,
  },
  {
    time: "2017-06-29T03:30:00.000Z",
    windSpeed: 20,
    R: "WNW",
    waveHeight: 2.53,
  },
  {
    time: "2017-06-29T05:00:00.000Z",
    windSpeed: 22,
    R: "W",
    waveHeight: 2.58,
  },
  {
    time: "2017-06-29T06:30:00.000Z",
    windSpeed: 26,
    R: "WSW",
    waveHeight: 2.89,
  },
  {
    time: "2017-06-29T08:00:00.000Z",
    windSpeed: 30,
    R: "WSW",
    waveHeight: 3.21,
  },
  {
    time: "2017-06-29T09:30:00.000Z",
    windSpeed: 30,
    R: "SW",
    waveHeight: 3.58,
  },
  {
    time: "2017-06-29T11:00:00.000Z",
    windSpeed: 29,
    R: "SW",
    waveHeight: 3.94,
  },
  {
    time: "2017-06-29T12:30:00.000Z",
    windSpeed: 29,
    R: "SW",
    waveHeight: 4.08,
  },
  {
    time: "2017-06-29T14:00:00.000Z",
    windSpeed: 29,
    R: "SW",
    waveHeight: 4.22,
  },
  {
    time: "2017-06-29T15:30:00.000Z",
    windSpeed: 28,
    R: "SW",
    waveHeight: 4.25,
  },
  {
    time: "2017-06-29T17:00:00.000Z",
    windSpeed: 28,
    R: "SW",
    waveHeight: 4.28,
  },
  {
    time: "2017-06-29T18:30:00.000Z",
    windSpeed: 29,
    R: "SSW",
    waveHeight: 4.37,
  },
  {
    time: "2017-06-29T20:00:00.000Z",
    windSpeed: 30,
    R: "SSW",
    waveHeight: 4.45,
  },
  {
    time: "2017-06-29T21:30:00.000Z",
    windSpeed: 29,
    R: "SSW",
    waveHeight: 4.45,
  },
  {
    time: "2017-06-29T23:00:00.000Z",
    windSpeed: 27,
    R: "SSW",
    waveHeight: 4.45,
  },
  {
    time: "2017-06-30T00:30:00.000Z",
    windSpeed: 26,
    R: "SSW",
    waveHeight: 4.32,
  },
  {
    time: "2017-06-30T02:00:00.000Z",
    windSpeed: 25,
    R: "SSW",
    waveHeight: 4.2,
  },
  {
    time: "2017-06-30T03:30:00.000Z",
    windSpeed: 22,
    R: "SSW",
    waveHeight: 4.01,
  },
  {
    time: "2017-06-30T05:00:00.000Z",
    windSpeed: 20,
    R: "SSW",
    waveHeight: 3.82,
  },
  {
    time: "2017-06-30T06:30:00.000Z",
    windSpeed: 19,
    R: "SSW",
    waveHeight: 3.66,
  },
  {
    time: "2017-06-30T08:00:00.000Z",
    windSpeed: 19,
    R: "SSW",
    waveHeight: 3.51,
  },
  {
    time: "2017-06-30T09:30:00.000Z",
    windSpeed: 17,
    R: "SSW",
    waveHeight: 3.37,
  },
  {
    time: "2017-06-30T11:00:00.000Z",
    windSpeed: 14,
    R: "SSW",
    waveHeight: 3.22,
  },
  {
    time: "2017-06-30T12:30:00.000Z",
    windSpeed: 12,
    R: "SSW",
    waveHeight: 3.09,
  },
  {
    time: "2017-06-30T14:00:00.000Z",
    windSpeed: 10,
    R: "SW",
    waveHeight: 2.96,
  },
  {
    time: "2017-06-30T15:30:00.000Z",
    windSpeed: 9,
    R: "WSW",
    waveHeight: 2.83,
  },
  {
    time: "2017-06-30T17:00:00.000Z",
    windSpeed: 9,
    R: "W",
    waveHeight: 2.7,
  },
  {
    time: "2017-06-30T18:30:00.000Z",
    windSpeed: 10,
    R: "W",
    waveHeight: 2.58,
  },
  {
    time: "2017-06-30T20:00:00.000Z",
    windSpeed: 10,
    R: "WNW",
    waveHeight: 2.45,
  },
  {
    time: "2017-06-30T21:30:00.000Z",
    windSpeed: 10,
    R: "WNW",
    waveHeight: 2.33,
  },
  {
    time: "2017-06-30T23:00:00.000Z",
    windSpeed: 10,
    R: "WNW",
    waveHeight: 2.21,
  },
  {
    time: "2017-07-01T00:30:00.000Z",
    windSpeed: 10,
    R: "NW",
    waveHeight: 2.13,
  },
  {
    time: "2017-07-01T02:00:00.000Z",
    windSpeed: 10,
    R: "NW",
    waveHeight: 2.05,
  },
  {
    time: "2017-07-01T03:30:00.000Z",
    windSpeed: 10,
    R: "NNW",
    waveHeight: 2.02,
  },
  {
    time: "2017-07-01T05:00:00.000Z",
    windSpeed: 10,
    R: "N",
    waveHeight: 2,
  },
  {
    time: "2017-07-01T06:30:00.000Z",
    windSpeed: 10,
    R: "N",
    waveHeight: 2,
  },
  {
    time: "2017-07-01T08:00:00.000Z",
    windSpeed: 10,
    R: "N",
    waveHeight: 1.99,
  },
  {
    time: "2017-07-01T09:30:00.000Z",
    windSpeed: 10,
    R: "N",
    waveHeight: 1.98,
  },
  {
    time: "2017-07-01T11:00:00.000Z",
    windSpeed: 10,
    R: "N",
    waveHeight: 1.96,
  },
  {
    time: "2017-07-01T12:30:00.000Z",
    windSpeed: 10,
    R: "N",
    waveHeight: 1.94,
  },
  {
    time: "2017-07-01T14:00:00.000Z",
    windSpeed: 11,
    R: "N",
    waveHeight: 1.93,
  },
  {
    time: "2017-07-01T15:30:00.000Z",
    windSpeed: 11,
    R: "N",
    waveHeight: 1.93,
  },
  {
    time: "2017-07-01T17:00:00.000Z",
    windSpeed: 11,
    R: "NNW",
    waveHeight: 1.93,
  },
  {
    time: "2017-07-01T18:30:00.000Z",
    windSpeed: 10,
    R: "NNW",
    waveHeight: 1.94,
  },
  {
    time: "2017-07-01T20:00:00.000Z",
    windSpeed: 10,
    R: "NNW",
    waveHeight: 1.96,
  },
  {
    time: "2017-07-01T21:30:00.000Z",
    windSpeed: 10,
    R: "NNW",
    waveHeight: 1.97,
  },
  {
    time: "2017-07-01T23:00:00.000Z",
    windSpeed: 10,
    R: "NNW",
    waveHeight: 1.97,
  },
  {
    time: "2017-07-02T00:30:00.000Z",
    windSpeed: 10,
    R: "N",
    waveHeight: 1.96,
  },
  {
    time: "2017-07-02T02:00:00.000Z",
    windSpeed: 11,
    R: "N",
    waveHeight: 1.94,
  },
  {
    time: "2017-07-02T03:30:00.000Z",
    windSpeed: 11,
    R: "N",
    waveHeight: 1.91,
  },
  {
    time: "2017-07-02T05:00:00.000Z",
    windSpeed: 10,
    R: "N",
    waveHeight: 1.88,
  },
  {
    time: "2017-07-02T06:30:00.000Z",
    windSpeed: 10,
    R: "N",
    waveHeight: 1.84,
  },
  {
    time: "2017-07-02T08:00:00.000Z",
    windSpeed: 10,
    R: "N",
    waveHeight: 1.8,
  },
  {
    time: "2017-07-02T09:30:00.000Z",
    windSpeed: 11,
    R: "N",
    waveHeight: 1.78,
  },
  {
    time: "2017-07-02T11:00:00.000Z",
    windSpeed: 11,
    R: "N",
    waveHeight: 1.76,
  },
  {
    time: "2017-07-02T12:30:00.000Z",
    windSpeed: 12,
    R: "N",
    waveHeight: 1.76,
  },
  {
    time: "2017-07-02T14:00:00.000Z",
    windSpeed: 13,
    R: "N",
    waveHeight: 1.77,
  },
  {
    time: "2017-07-02T15:30:00.000Z",
    windSpeed: 14,
    R: "N",
    waveHeight: 1.81,
  },
  {
    time: "2017-07-02T17:00:00.000Z",
    windSpeed: 15,
    R: "N",
    waveHeight: 1.85,
  },
  {
    time: "2017-07-02T18:30:00.000Z",
    windSpeed: 14,
    R: "N",
    waveHeight: 1.86,
  },
  {
    time: "2017-07-02T20:00:00.000Z",
    windSpeed: 13,
    R: "N",
    waveHeight: 1.87,
  },
  {
    time: "2017-07-02T21:30:00.000Z",
    windSpeed: 13,
    R: "N",
    waveHeight: 1.88,
  },
  {
    time: "2017-07-02T23:00:00.000Z",
    windSpeed: 13,
    R: "N",
    waveHeight: 1.9,
  },
  {
    time: "2017-07-03T00:30:00.000Z",
    windSpeed: 13,
    R: "N",
    waveHeight: 1.91,
  },
  {
    time: "2017-07-03T02:00:00.000Z",
    windSpeed: 13,
    R: "N",
    waveHeight: 1.93,
  },
  {
    time: "2017-07-03T03:30:00.000Z",
    windSpeed: 13,
    R: "N",
    waveHeight: 1.96,
  },
  {
    time: "2017-07-03T05:00:00.000Z",
    windSpeed: 13,
    R: "NNE",
    waveHeight: 1.99,
  },
  {
    time: "2017-07-03T06:30:00.000Z",
    windSpeed: 12,
    R: "NNE",
    waveHeight: 2.03,
  },
  {
    time: "2017-07-03T08:00:00.000Z",
    windSpeed: 11,
    R: "NNE",
    waveHeight: 2.08,
  },
  {
    time: "2017-07-03T09:30:00.000Z",
    windSpeed: 11,
    R: "N",
    waveHeight: 2.12,
  },
  {
    time: "2017-07-03T11:00:00.000Z",
    windSpeed: 11,
    R: "NNW",
    waveHeight: 2.16,
  },
  {
    time: "2017-07-03T12:30:00.000Z",
    windSpeed: 15,
    R: "N",
    waveHeight: 2.22,
  },
  {
    time: "2017-07-03T14:00:00.000Z",
    windSpeed: 20,
    R: "N",
    waveHeight: 2.27,
  },
  {
    time: "2017-07-03T15:30:00.000Z",
    windSpeed: 20,
    R: "N",
    waveHeight: 2.33,
  },
  {
    time: "2017-07-03T17:00:00.000Z",
    windSpeed: 19,
    R: "N",
    waveHeight: 2.39,
  },
  {
    time: "2017-07-03T18:30:00.000Z",
    windSpeed: 17,
    R: "N",
    waveHeight: 2.44,
  },
  {
    time: "2017-07-03T20:00:00.000Z",
    windSpeed: 15,
    R: "N",
    waveHeight: 2.49,
  },
  {
    time: "2017-07-03T21:30:00.000Z",
    windSpeed: 16,
    R: "NNW",
    waveHeight: 2.49,
  },
  {
    time: "2017-07-03T23:00:00.000Z",
    windSpeed: 17,
    R: "WNW",
    waveHeight: 2.49,
  },
  {
    time: "2017-07-04T00:30:00.000Z",
    windSpeed: 18,
    R: "W",
    waveHeight: 2.47,
  },
  {
    time: "2017-07-04T02:00:00.000Z",
    windSpeed: 20,
    R: "SW",
    waveHeight: 2.44,
  },
  {
    time: "2017-07-04T03:30:00.000Z",
    windSpeed: 21,
    R: "SW",
    waveHeight: 2.5,
  },
  {
    time: "2017-07-04T05:00:00.000Z",
    windSpeed: 21,
    R: "WSW",
    waveHeight: 2.56,
  },
  {
    time: "2017-07-04T06:30:00.000Z",
    windSpeed: 22,
    R: "WSW",
    waveHeight: 2.69,
  },
  {
    time: "2017-07-04T08:00:00.000Z",
    windSpeed: 22,
    R: "WSW",
    waveHeight: 2.83,
  },
  {
    time: "2017-07-04T09:30:00.000Z",
    windSpeed: 23,
    R: "WSW",
    waveHeight: 2.94,
  },
  {
    time: "2017-07-04T11:00:00.000Z",
    windSpeed: 23,
    R: "WSW",
    waveHeight: 3.06,
  },
  {
    time: "2017-07-04T12:30:00.000Z",
    windSpeed: 24,
    R: "WSW",
    waveHeight: 3.06,
  },
  {
    time: "2017-07-04T14:00:00.000Z",
    windSpeed: 24,
    R: "SW",
    waveHeight: 3.06,
  },
  {
    time: "2017-07-04T15:30:00.000Z",
    windSpeed: 25,
    R: "SW",
    waveHeight: 3.04,
  },
  {
    time: "2017-07-04T17:00:00.000Z",
    windSpeed: 25,
    R: "SW",
    waveHeight: 3.03,
  },
  {
    time: "2017-07-04T18:30:00.000Z",
    windSpeed: 26,
    R: "SW",
    waveHeight: 3,
  },
  {
    time: "2017-07-04T20:00:00.000Z",
    windSpeed: 26,
    R: "SW",
    waveHeight: 2.97,
  },
];


export const rangleDualTableJson =
{
  "actual":
    [{ "Period": "Daily", "Planned Amount(kW)": 37471, "Actual Amount(kW)": "29294.89", "Achievement Rate(%)": "78.18" }, { "Period": "Monthly", "Planned Amount(kW)": 1161600, "Actual Amount(kW)": "555718.73", "Achievement Rate(%)": "47.84" }, { "Period": "Yearly", "Planned Amount(kW)": 13939200, "Actual Amount(kW)": "5433099.44", "Achievement Rate(%)": "38.98" }], "planned": [{ "Period": "Daily", "Planned Amount(kW)": 37471, "Actual Amount(kW)": "29294.89", "Achievement Rate(%)": "78.18" }, { "Period": "Monthly", "Planned Amount(kW)": 1161600, "Actual Amount(kW)": "555718.73", "Achievement Rate(%)": "47.84" }, { "Period": "Yearly", "Planned Amount(kW)": 13939200, "Actual Amount(kW)": "5433099.44", "Achievement Rate(%)": "38.98" }]
}

export const guageRingMock = [
  {
    value: 40,
    name: "Temperature",
  },
  {
    value: 80,
    name: "Pressure",
  },
];

export const progressMock = [
  { "interval_alias": "2020-05-13T14:22:16.303Z", "tagname": "TRR_PIC301_PV", "tagvalue": 0, "tag_description": "Main steam pressure control" }
]

export const videoMock = [{
  id: 1,
  category: 'fruits',
  imageUrl: 'http://placeimg.com/1200/800/nature',
  videoUrl: 'http://polzavred.ru/wp-content/uploads/klubnika-poleznie-svoistva.jpg'
}, {
  id: 2,
  category: 'fruits',
  imageUrl: 'http://placeimg.com/800/1200/nature'
},
{
  id: 3,
  category: 'fruits',
  imageUrl: 'http://placeimg.com/1920/1080/nature'
}, {
  id: 4,
  category: 'vegetables',
  imageUrl: 'http://placeimg.com/1500/500/nature'
}];

export const notificationtableMock = [{
  eventStatus:
  {
    interval_alias: "2020-05-12T21:49:05.161Z",
    tagname: "TRR_TIC301_PV",
    raisedEvents: "5",
    // status: "Low",
    closedEvents: "3",
    tag_description: "Main stream temperature control",
  },
  tabledata: [{
    "S.No": "1",
    "Location": "Substation-02",
    "Meter Id": "1",
    "Meter Type": "Energy Meter",
    "Meter Name": "Incomer-1",
    "Status": "Available",
    "Timestamp": "2021-05-29 16:19:06"
  },
  {
    "S.No": "2",
    "Location": "Substation-02",
    "Meter Id": "2",
    "Meter Type": "Energy Meter",
    "Meter Name": "Incomer-2",
    "Status": "Available",
    "Timestamp": "2021-05-29 16:19:06"
  },
  {
    "S.No": "3",
    "Location": "Substation-03",
    "Meter Id": "3",
    "Meter Type": "Energy Meter",
    "Meter Name": "Incomer-3",
    "Status": "NotAvailable",
    "Timestamp": "2021-05-29 16:19:06"
  }]
}
]

export const imageMock = [{
  id: 1,
  category: 'fruits',
  imageUrl: 'http://placeimg.com/1200/800/nature',
  videoUrl: 'http://polzavred.ru/wp-content/uploads/klubnika-poleznie-svoistva.jpg'
}, {
  id: 2,
  category: 'fruits',
  imageUrl: 'http://placeimg.com/800/1200/nature'
},
{
  id: 3,
  category: 'fruits',
  imageUrl: 'http://placeimg.com/1920/1080/nature'
}, {
  id: 4,
  category: 'vegetables',
  imageUrl: 'http://placeimg.com/1500/500/nature'
}]

export const selectImageMock = {
  "options": [
    {
      "value": "AP23ef1234",
      "label": "AP23ef1234",
      "children": [
        {
          "value": "123456",
          "label": "123456"
        }
      ]
    },
    {
      "value": "AP23ef12345",
      "label": "AP23ef12345",
      "children": [
        {
          "value": "12345",
          "label": "12345"
        }
      ]
    }
  ],
  "data": [
    {
      "imagesList": [
        "http://placeimg.com/1920/1080/nature",
        "http://placeimg.com/1500/500/nature"
      ],
      "parentKey": "AP23ef1234",
      "childKey": "123456",
      "truckDetails": [
        {
          "title": "truckId",
          "value": "1234"
        },
        {
          "title": "truckNumber",
          "value": "AP23ef1234"
        }
      ]
    },
    {
      "imagesList": [
        "url1",
        "url2"
      ],
      "parentKey": "AP23ef1234",
      "childKey": "124567",
      "truckDetails": [
        {
          "title": "truckId",
          "value": "567"
        },
        {
          "title": "truckNumber",
          "value": "AP23ef567"
        }
      ]
    },
    {
      "imagesList": [
        "url1",
        "url2"
      ],
      "parentKey": "AP23ef1235",
      "childKey": "12345",
      "truckDetails": [
        {
          "title": "truckId",
          "value": "456"
        },
        {
          "title": "truckNumber",
          "value": "AP23ef456"
        }
      ]
    },
    {
      "imagesList": [
        "http://placeimg.com/1920/1080/nature",
        "http://placeimg.com/1500/500/nature"
      ],
      "parentKey": "AP23ef1235",
      "childKey": "124567",
      "truckDetails": [
        {
          "title": "truckId",
          "value": "789"
        },
        {
          "title": "truckNumber",
          "value": "AP23ef789"
        }
      ]
    }
  ]
}