import React from 'react'

import LoginComponent from './LoginContainer'

import { LoginPageBackground, LoginBox, LoginSection, Login } from './styles'

const LoginPage = () => {
  document.title = 'Login - Industrial IOT Platform'
  return (
    <Login>
      <LoginSection>
        <LoginPageBackground style={{ backgroundImage: `url('covers/rubus_login.jpg')` }} />
        <LoginBox>
          <LoginComponent />
        </LoginBox>
      </LoginSection>
    </Login>
  )
}

export {
  LoginPage
}
