import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";

import Trends from "./Trends";
import { getAssetDeviceCache } from "../../modules/asset/asset.duck";

import { getAssetList, getDeviceCache } from "../../selectors/asset";
import { getDeviceListSelector } from "../../selectors/device";
import { getDeviceTypeListSelector } from "../../selectors/deviceType";

const mapStateToProps = createStructuredSelector({
  assetList: getAssetList(),
  deviceCache: getDeviceCache(),
  deviceList: getDeviceListSelector(),
  deviceTypeList: getDeviceTypeListSelector()
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      getAssetDeviceCache
    }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Trends);
