import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import MailSettingsComponent from './MailSettingsComponent'

const mapStateToProps = createStructuredSelector({

})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(
      {
        
      },
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MailSettingsComponent)
