import React from "react";
import axios from "axios";
import { Table } from "antd";

import localStorage from "../../../../utils/localStorage";
import {
  StyledTable,
  lightTheme,
} from '../style'
import { columns } from "./mock";

class Organisation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      columns,
      datasource: [],
    };
  }

  componentWillMount() {
    this._getOrganisationList();
  }

  _getOrganisationList = () => {
    const accessToken = localStorage.get("accessToken");
    let orgHeaders = {
      method: "GET",
      url: "/api/organisation/list",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios(orgHeaders)
      .then(({ data }) => {
        this.setState({ datasource: data });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  render() {
    let { columns, datasource } = this.state;
    
    return (
      <StyledTable theme={lightTheme}>
        <Table
          columns={columns}
          dataSource={datasource}
          onRow={(rowData) => {
            return {
              onClick: () => {
                this.props.history.push({
                  pathname: `/rubus/settingsPage/UserManagement/Organisation/${rowData.Id}`,
                });
              },
            };
          }}
          pagination={false}
        />
      </StyledTable>
    );
  }
}
export default Organisation;
