import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import TagReference from './TagReference'
import { getTagReferenceList } from '../../../../modules/tagReference/tagReference.duck'
import { getTagReferenceListSelector } from '../../../../selectors/tagReference'

const mapStateToProps = createStructuredSelector({
  tagReferenceList: getTagReferenceListSelector()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(
      {
        getTagReferenceList
      },
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagReference)
