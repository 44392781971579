import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import SlideMenuComponent from './SlideMenuComponent'
import { getmenuSelected } from '../../../../../selectors/menu'
import { getuserCurrentMenu } from '../../../../../selectors/login'
import { getCurrentLanguage, getTranslationCache } from '../../../../../selectors/language'
import { getUserProfile } from '../../../../../selectors/layout'
import { getLoggedInProfile } from '../../../../../modules/layout/layout.duck'
import { setMenuSelection } from '../../../../../modules/menu/menu.duck'
import { getAssetDeviceCache, getAssetDeviceList, setCurrentLanguage, } from '../../../../../modules/asset/asset.duck'
import { setCurrentSite, setMenuHierarchy } from '../../../../../modules/layout/layout.duck'
import { getTranslationsCache } from "../../../../../modules/translation/translation.duck"
import { userCurrentMenu } from '../../../../../modules/login/login.duck.js'
import { getCurrentSite, getMenuHierarchy } from '../../../../../selectors/layout'
import { getNavigationMode } from '../../../../../selectors/theme'

const mapStateToProps = createStructuredSelector({
  currentMenu: getuserCurrentMenu(),
  currentLanguage: getCurrentLanguage(),
  currentSite: getCurrentSite(),
  userProfile: getUserProfile(),
  selectedMenu: getmenuSelected(),
  translationCache: getTranslationCache(),
  navigationMode: getNavigationMode(),
  menuHierarchy: getMenuHierarchy()
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getAssetDeviceCache,
        getAssetDeviceList,
        setCurrentSite,
        userCurrentMenu,
        setCurrentLanguage,
        setMenuHierarchy,
        getLoggedInProfile,
        setMenuSelection,
        getTranslationsCache
      },
      dispatch
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SlideMenuComponent)
