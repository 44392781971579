import React, { Fragment } from "react";
import moment from 'moment'
import { Table, message, Popconfirm, Icon, Switch } from "antd";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { ruleList, deleteRule, updateRuleStatus } from "./Apicalls";
import { StyledDashboard, StyledTable, SaveButton, RuleButton, lightTheme } from "./style";

import constants from "./constants";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../selectors/language";

class RulesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
    };
  }
  async componentWillMount() {
    try {
      let data = await ruleList();
      this.setState({ tableData: data.data });
    } catch (err) {
      message.warn("OOPS Something went Wrong")
    }

  }

  deleteRule = async (key) => {
    try {
      let data = await deleteRule(key);
      if (data.status === 200) {
        message.success("Deleted Rule");
        try {
          let tableData = await ruleList();
          this.setState({ tableData: tableData.data });
        } catch (err) {
          message.warn("Something went Wrong !")
        }
      }
    } catch (err) {
      message.warn("Something went Wrong !")
    }

  };

  ruleStatusUpdate = async (rule, index) => {
    let payLoad = {
      Id: rule.Id,
      Type: rule.Type,
      NotificationStatus: !rule.NotificationStatus,
    };
    if (rule.Type === "batch") {
      payLoad.FilterOption = rule.Priority;
    } else {
      payLoad.FilterOption = rule.ParameterType;
    }

    let data = await updateRuleStatus(payLoad);
    if (data.status === 200) {
      let data = await ruleList();
      this.setState({ tableData: data.data });
      // let data = this.state.tableData;
      // data[index]["NotificationStatus"] = !rule.NotificationStatus;
      // this.setState({ tableData: data });
    } else {
      message.error("something Went Wrong");
    }
  };

  handleRowClick = (ruleId) => {
    this.props.history.push(`/rubus/RulesFeedback/?ruleId=${ruleId}`);
  };

  render() {
    const { translationCache, language } = this.props;

    const columns = [
      {
        title:
          translationCache &&
            translationCache[`${constants.Rule_Name}`] &&
            translationCache[`${constants.Rule_Name}`]
            ? translationCache[`${constants.Rule_Name}`]
            : `${constants.Rule_Name}`,
        dataIndex: "Name",
        key: "name",
      },
      {
        title:
          translationCache &&
            translationCache[`${constants.Rule_Description}`] &&
            translationCache[`${constants.Rule_Description}`]
            ? translationCache[`${constants.Rule_Description}`]
            : `${constants.Rule_Description}`,
        dataIndex: "Description",
        key: "description",
      },
      // {
      //   title:
      //     translationCache &&
      //       translationCache[`${constants.createdBy}`] &&
      //       translationCache[`${constants.createdBy}`]
      //       ? translationCache[`${constants.createdBy}`]
      //       : `${constants.createdBy}`,
      //   dataIndex: "CreatedBy",
      //   key: "createdby",
      // },
      {
        title:
          translationCache &&
            translationCache[`${constants.createdTime}`] &&
            translationCache[`${constants.createdTime}`]
            ? translationCache[`${constants.createdTime}`]
            : `${constants.createdTime}`,
        dataIndex: "CreatedTime",
        key: "createdtime",
        render: (text, record) => (
          <span>
            {moment(text).format("YYYY-MM-DD HH:mm:ss")}
          </span>
        )
      },
      {
        title:
          translationCache &&
            translationCache[`${constants.edit}`] &&
            translationCache[`${constants.edit}`]
            ? translationCache[`${constants.edit}`]
            : `${constants.edit}`,
        key: "Edit",
        render: (text, record) => (
          <span>
            <Icon
              type="form"
              //theme="filled"
              style={{ color: "#1a3652" }}
              onClick={(e) => {
                e.stopPropagation();
                this.props.history.push(`RuleEngine/${record.Id}`);
              }}
            />
          </span>
        ),
      },
      {
        title:
          translationCache &&
            translationCache[`${constants.delete}`] &&
            translationCache[`${constants.delete}`]
            ? translationCache[`${constants.delete}`]
            : `${constants.delete}`,
        key: "Delete",
        render: (text, record) => (
          <span>
            <Popconfirm
              placement="topRight"
              title="Are you sure delete this Rule?"
              onConfirm={(e) => {
                e.stopPropagation();
                this.deleteRule(record.Id);
              }}
              onCancel={(e) => {
                e.stopPropagation();
              }}
              okText="Yes"
              cancelText="No"
            >
              <Icon
                type="delete"
                //  theme="filled"
                style={{ color: "#f5222d" }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Popconfirm>
          </span>
        ),
      },
      {
        title:
          translationCache &&
            translationCache[`${constants.status}`] &&
            translationCache[`${constants.status}`]
            ? translationCache[`${constants.status}`]
            : `${constants.status}`,
        key: "NotificationStatus",
        render: (text, record, index) => {
          return (
            <span>
              <Switch checkedChildren="ON" unCheckedChildren="OFF" checked={record.NotificationStatus} onChange={(e) => {
                // e.stopPropagation();
                this.ruleStatusUpdate(record, index);
              }} />

              {/* <Checkbox checked={record.NotificationStatus}
                onChange={(e) => {
                  e.stopPropagation();
                  this.ruleStatusUpdate(record, index);
                }}></Checkbox> */}
            </span>
          );
        },
      },
      {
        title: "",
        key: "",
        render: (text, record, index) => {
          return (
            <RuleButton type="primary" onClick={(e) => {
              this.handleRowClick(record.Id);
            }}>Rule History
            </RuleButton>
          );
        },
      }
    ];
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        <Fragment>
          <div>
            <SaveButton
              style={{ display: "flex", flexDirection: "row-reverse",margin: "23px" }}
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                this.props.history.push(`/rubus/RuleEngine`);
              }}
            >
              {translationCache &&
                translationCache[`${constants.addrule}`] &&
                translationCache[`${constants.addrule}`]
                ? translationCache[`${constants.addrule}`]
                : `${constants.addrule}`}
            </SaveButton>
          </div>
          <div>
            <StyledTable theme={lightTheme}>
              <Table
                columns={columns}
                dataSource={this.state.tableData}
              />
            </StyledTable>
          </div>
        </Fragment>
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
});

export default connect(mapStateToProps)(RulesList);
