import React, { Fragment, Component } from 'react';

import { Empty, Icon } from 'antd'

class VideoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVideo: false
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.graphprops !== this.props.graphprops || prevProps.imageClick !== this.props.imageClick) {
      this.getData();
    }
  }

  getData = async () => {
    let { graphprops } = this.props
    this.setState({
      camera: graphprops && graphprops.video && graphprops.video.path ? graphprops.video.path : ""
    })
  }

  changeVideoSettings = () => {
    let { showVideo } = this.state
    this.setState({
      showVideo: !showVideo
    })
  }

  HeartSvg = () => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="15em" height="15em" viewBox="0 0 1024 1024" fill="#434343">
      <path d="M815.8,264.8h-612c-15,0-27.2,12-27.2,26.9V332h68l0.6,70h-68.6v68.6h68.6v68.6h-68.6v68.6h68.6v58.8h-68.6v41.7c0,14.9,12.2,26.9,27.2,26.9h612c15,0,27.2-12,27.2-26.9v-41.7h-68.6v-58.8H843v-68.6h-68.6v-68.6H843V402h-68.6l0.6-70h68v-40.3C843,276.8,830.8,264.8,815.8,264.8z M715.6,676.4H304v-343h411.6L715.6,676.4L715.6,676.4z M826.7,10H173.3C83.1,10,10,83.1,10,173.3v653.3C10,916.9,83.1,990,173.3,990h653.3c90.2,0,163.3-73.1,163.3-163.3V173.3C990,83.1,916.9,10,826.7,10z M911.6,790.9c0,61.3-59.5,120.7-120.7,120.7H199.3c-61.3,0-110.9-59.5-110.9-120.7V199.3c0-61.3,49.7-110.9,110.9-110.9h591.6c61.3,0,120.7,49.7,120.7,110.9V790.9z M435,399.2v201.6L605,500L435,399.2z" />
    </svg>
  );

  render() {

    let { camera, showVideo } = this.state
    const HeartIcon = props => <Icon component={this.HeartSvg} onClick={() => this.changeVideoSettings()} style={{ cursor: "pointer" }} {...props} />;

    return (
      <Fragment>
        {
          showVideo ?
            <iframe style={{ width: "100%", height: "90%", border: "none" }} src={`${camera}`}></iframe>
            :
            <Empty
              image={false}
              imageStyle={{
                height: 120,
              }}
              description={false}
            >
              <HeartIcon style={{ color: 'hotpink' }} />
            </Empty>
        }
      </Fragment >

    );

  }
}


export default VideoComponent