import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import MonacoEditor from 'react-monaco-editor'
import {
    Table, Drawer, Button, Icon, Input, Form, Row, Col, Select, message
} from 'antd'
import { getCurrentLanguage, getTranslationCache } from "../../../selectors/language";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { constant } from './constants'
import { getHMICalls } from '../hmicalls'

import { getUserDetails } from "../../../selectors/layout";
import localStorage from '../../../utils/localStorage'
import { StyledApplication } from './style'

import {
    StyledTable,
    lightTheme,
} from './style'

const { Option } = Select

class HmiConfiguration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            theme: "vs-dark",
            editObj: {},
            datasource: [],
            userId: props.userDeatils,
            translationCache: props.translationCache || [],
            language: props.language || "english"
        }
    }

    onClose = () => {
        this.setState({
            visible: false,
        });
    }

    addNewHmi = () => {
        this.props.form.resetFields();
        this.setState({
            visible: true,
            editObj: {},
            listType: "save"
        })
    }

    componentWillMount() {
        this._getHmiList()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.UserDetails !== this.props.UserDetails) {
            this.setState({
                userId: this.props.UserDetails,
            });
        }
        if (prevProps.translationCache !== this.props.translationCache || prevProps.language !== this.props.language) {
            this.setState({
                translationCache: this.props.translationCache, language: this.props.language
            });
        }
    }

    _getHmiList = async (msg) => {
        const siteId = localStorage.get('currentSite')
        let payload = {
            method: 'POST',
            url: `/api/hmi/list`,
            data: { SiteId: siteId }
        }
        let datasource = await getHMICalls(payload)
        if (datasource && datasource.message) {
            console.log("error");
        } else {
            this.setState({ datasource })
        }
    }

    editorDidMount = editor => {
        this.editor = editor;
    }

    hmiOperation = async (operation, userId) => {
        let datasource = undefined
        let payload = {}
        const siteId = localStorage.get('currentSite')
        if (operation === 'save') {
            this.props.form.validateFields((err, values) => {
                const model = this.refs.monaco.editor.getModel();
                const value = model.getValue();

                if (typeof JSON.parse(value) !== 'string') {
                    payload = {
                        method: 'POST',
                        url: `/api/hmi/insert`,
                        data: {
                            "Name": values.Name,
                            "Type": values.Type,
                            "JSON": JSON.parse(value),
                            "CreatedBy": userId,
                            "SiteId": siteId
                        }
                    }
                } else {
                    alert("Error")
                }
            })

        } else if (operation === 'update') {
            this.props.form.validateFields(async (err, values) => {
                const model = this.refs.monaco.editor.getModel();
                const value = model.getValue();
                if (typeof JSON.parse(value) !== 'string') {
                    payload = {
                        method: 'PATCH',
                        url: `/api/hmi/update`,
                        data: {
                            "Name": values.Name,
                            "Type": values.Type,
                            "JSON": JSON.parse(value),
                            "UpdatedBy": userId,
                            "Id": this.state.editObj.Id,
                            "SiteId": siteId
                        }
                    }
                } else {
                    alert("Error")
                }
            })
        }
        else if (operation === 'delete') {
            this.props.form.validateFields(async (err, values) => {
                if (!err) {
                    payload = {
                        method: 'POST',
                        url: `/api/hmi/delete`,
                        data: {
                            "Id": this.state.editObj.Id,
                            "SiteId": siteId
                        }
                    }
                }
            })
        }

        datasource = await getHMICalls(payload)
        if (datasource && datasource.message) {
            if (datasource.message === "Error in API") {
                message.error(datasource.message)
            } else {
                message.success(datasource.message)
                this.setState({
                    visible: false
                })
                this.props.form.resetFields();
                this._getHmiList("Uodate")
            }
        } else {
            this.setState({
                visible: false
            })
            this.props.form.resetFields();
            this._getHmiList("Update")
        }
    }

    render() {
        let userDeatils = this.props.UserDetails
        const { getFieldDecorator } = this.props.form
        const { editObj, translationCache, language, datasource } = this.state
        const userId = userDeatils && userDeatils.Id
        const options = {
            selectOnLineNumbers: true,
            roundedSelection: false,
            readOnly: false,
            cursorStyle: "line",
            automaticLayout: false,
            lineDecorationsWidth: 0,
        };
        const columns = [
            {
                title: translationCache &&
                    translationCache[`${constant.HMI_Name}`] &&
                    translationCache[`${constant.HMI_Name}`] ?
                    translationCache[`${constant.HMI_Name}`] :
                    `${constant.HMI_Name}`,
                dataIndex: 'Name',
                key: 'Name',
                width: "50%",
                render: (text, record) => {
                    return <Link
                        style={{ color: "#173049" }}
                        to={{ pathname: `/rubus/singleHMI/static/${record.Name}` }}
                    >{text}</Link>
                    
                },
            },
            {
                title: translationCache &&
                    translationCache[`${constant.HMI_Type}`] &&
                    translationCache[`${constant.HMI_Type}`] ?
                    translationCache[`${constant.HMI_Type}`] :
                    `${constant.HMI_Type}`,
                dataIndex: 'Type',
                key: 'Type'
            },

        ]
        return (
            <StyledApplication style={{ minHeight: window.innerHeight - 170 }}>
                <Button type="primary" onClick={this.addNewHmi} style={{ marginBottom: '10px' }}>  <Icon type="plus" />  {translationCache &&
                    translationCache[`${constant.New_HMI}`] &&
                    translationCache[`${constant.New_HMI}`] ?
                    translationCache[`${constant.New_HMI}`] :
                    `${constant.New_HMI}`}</Button>
                <Drawer
                    title="Create a new HMI configuration"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{ paddingBottom: 80 }}
                >
                    <Form layout="vertical" hideRequiredMark>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label="HMI Name :">
                                    {getFieldDecorator('Name', {
                                        initialValue: editObj.Name,
                                        rules: [{ required: true, message: "Enter HMI Name" }]
                                    })(
                                        <Input placeholder="Please enter HMI Name" />
                                    )}
                                </Form.Item>
                                <Form.Item label="HMI Type :">
                                    {getFieldDecorator('Type', {
                                        initialValue: editObj.Type,
                                        rules: [{ required: true, message: "Please enter HMI Type" }]
                                    })(
                                        <Select placeholder="Select HMI Type" >
                                            <Option value="static">Static</Option>
                                            <Option value="dynamic">Dynamic</Option>

                                        </Select>
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>
                    </Form>

                    <div>Enter HMI JSON</div>
                    <MonacoEditor
                        width="680"
                        height="650"
                        language="json"
                        theme="vs-dark"
                        defaultValue={JSON.stringify(editObj.JSON)}
                        options={options}
                        ref="monaco"
                        value={JSON.stringify(editObj.JSON, null, '\t')}
                        editorWillMount={this.editorWillMount}
                        editorDidMount={this.editorDidMount}
                    />
                    {
                        this.state.listType === 'save' ?
                            <Button type="primary" shape="round"
                                onClick={() => this.hmiOperation('save', userId)} ghost style={{ marginRight: '10px', background: 'rgb(190, 200, 200)', marginTop: '10px' }}>
                                Save
                            </Button>
                            :
                            <Button type="primary" shape="round" onClick={() => this.hmiOperation('update', userId)} ghost style={{ background: 'rgb(190, 200, 200)', marginTop: '10px' }}>
                                Update
                            </Button>
                    }

                    {
                        <Button type="primary" shape="round" onClick={() => this.hmiOperation('delete', userId)} ghost style={{
                            background: 'rgb(190, 200, 200)', marginTop: '10px', marginLeft
                                : "5px"
                        }}>
                            Delete
                        </Button>
                    }
                </Drawer>
                <div className="Table">
                    {/* <StyledTable theme={lightTheme} style={{ marginTop: '20px' }}> */}
                    <Table
                        columns={columns}
                        dataSource={datasource}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    this.setState({
                                        visible: true,
                                        editObj: record,
                                        listType: "update",
                                        editEditor: record["JSON"]
                                    })

                                },
                            };
                        }}
                    />
                    {/* </StyledTable> */}
                </div>
            </StyledApplication>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    UserDetails: getUserDetails(),
    language: getCurrentLanguage(),
    translationCache: getTranslationCache(),
});

export default connect(mapStateToProps)(Form.create()(HmiConfiguration));

