import styled from 'styled-components'
import { Button, TreeSelect, Badge } from 'antd'

export const StyledApplication = styled.div`
background: white;
margin: 20px;
padding: 30px;
min-height: 750px;
`
export const StyledAddButton = styled(Button)`
margin:24px;
`
export const DrawerFooter = styled.div`
position: absolute;
left: 0;
bottom: 0;
width: 100%;
border-top: 1px solid #e9e9e9;
padding: 10px 16px;
background: #fff;
text-align: right;
`
export const ListHeader = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 20px;
margin-top: 20px;
`
export const BadgeHeader = styled(Badge)`
 background-color: #fff;
 color: #999;
 margin-right: 1em;
`
export const StyledTreeSelect = styled(TreeSelect)`
width: 42em;
margin-left: 680px;
`
export const darkTheme = {
    text: "rgba(0,0,0,.85)",
    even: "red",
    odd: "#8c8c8c",
    hover: "#f5f5f5",
  };
  
  export const lightTheme = {
    text: "rgba(0,0,0,.85)",
    even: "#d9d9d9",
    odd: "#efefef",
    hover: "#f5f5f5",
  };
  
  export const StyledTable = styled.table`
    caption-side: top;
  
  
    color: ${(props) => props.theme.text} !important;
  
    width: 100%;
    /* border-collapse: separate; */
    /* border-spacing: 5px 10px; */
  
    caption-side: bottom;
    /* empty-cell: show | hide;  */
    /* empty-cell is a property of table or the cells themselves */
  
    /* vertical-align: baseline | sub | super | text-top | 
                  text-bottom | middle | top | bottom | 
                  <percentage> | <length> */
  
    /* tbody {
      vertical-align: top;
    }              */
    th{
        background:#13c2c2 !important;
        border: 1px solid #bfbfbf !important;
        text-align:center !important;
        color:#fff !important;
    }
    td
     {lightTheme
        border: 1px solid #bfbfbf !important;
        text-align:center !important;
      color: ${(props) => props.theme.text} !important;
    }
    
  
    td {
      padding: 11px 10px;
      font-size: 1.2em !important;
  
    }
    .ant-table-thead > tr > th{
      font-weight:bold !important
      font-size: 1.2em !important;
      
       // color:red !important
    }
    text[style] {
      font-weight:bold !important
      font-size: 2em !important;
      color: ${(props) => props.theme.text} !important;
   }
    tbody tr {
      :nth-of-type(odd) {
        background-color:${(props) => props.theme.odd} !important;
      }
      :hover {
        background-color:${(props) => props.theme.hover} !important; 
      }
    }
    tbody tr {
      :nth-of-type(even) {
        background-color:${(props) => props.theme.even} !important;
      }
      :hover {
        background-color:${(props) => props.theme.hover} !important; 
      }
    }
    thead > tr {
      color: ${(props) => props.theme.text} !important;
      background-color: #262626 !important;
    }
    caption {
      font-size: 0.9em;
      padding: 5px;
      font-weight: bold;
    }
  `;
  