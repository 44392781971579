import { createSelector } from 'reselect'

const menuList = state => {
    return state.get('menu').get('menu')
}

const getmenuSelected = () =>
  createSelector(menuList, state => {
    return state.get('menuSelected')
  })

export {
    getmenuSelected
}
