import React from 'react'
import axios from 'axios'
import { Form, Input, Button, message, Select } from 'antd'

import localStorage from '../../../utils/localStorage'
const FormItem = Form.Item
const {Option} = Select

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
}

class SMSSettingsComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      smsSetings: {}
    }
  }

  componentWillMount() {
    this._getInitialSMSSettingData()
  }

  _getInitialSMSSettingData = () => {
    const accessToken = localStorage.get('accessToken')
    const siteId = localStorage.get('currentSite')
    let object = {
      method: 'POST',
      url: `/api/notification/getsettings`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        type : "SMS", SiteId:siteId
      }
    }

    axios(object)
      .then((response) => {
        if (response && Array.isArray(response.data) && response.data && response.data.length > 0) {
          this.setState({
            smsSetings: response.data[0]
          })
        } else {
          this.setState({
            smsSetings: {}
          })
        }


      })
      .catch(() => {
        this.setState({
          smsSetings: {}
        })
      })
  }

  _updateSMSSettings = (payload) => {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get('accessToken')
    const object = {
      method: 'PATCH',
      url: `/api/notification/updatesettings`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        ...payload,
        type: "SMS",
        SiteId:siteId
      }
    }
    axios(object)
      .then(({data}) => {
        if(data && data.message){
          message.success(data.message)
        }

      })
      .catch(function () {
      })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this._updateSMSSettings(values)
      }
    })
  }

  // [{"SMSProvider":"GSM","ModemPortNumber":"587","ModemPortSpeed":"1.1Mbps","MessageCenterNumber":"8156","SimPinNumber":"2478"}]


  render () {
    const { getFieldDecorator } = this.props.form
    const {smsSetings} = this.state

    return (
      <Form style={{ marginRight: '180px' }} {...formItemLayout} onSubmit={this.handleSubmit}>
        <Form.Item label="SMS Provider">
          {getFieldDecorator('SMSProvider', {
               initialValue: smsSetings.SMSProvider || "",
            rules: [{ required: true}],
          })(
            <Select >
            <Option value='GSM Modem'>GSM Modem</Option>
        </Select>
          )}
        </Form.Item>
        <Form.Item label="Modem Port Number">
          {getFieldDecorator('ModemPortNumber', {
               initialValue: smsSetings.ModemPortNumber || "",
            rules: [{ required: true, message: 'Please Enter Modem Port Number' }]
          })(
            <Input/>
          )}
        </Form.Item>
        <FormItem label="Modem Port Speed">
          {getFieldDecorator('ModemPortSpeed', {
              initialValue: smsSetings.ModemPortSpeed || "",
            rules: [{ required: true, message: 'Please Enter Modem port speed' }]
          })(
            <Input />
          )}
        </FormItem>

        <Form.Item label="Message center number">
          {getFieldDecorator('MessageCenterNumber', {
              initialValue: smsSetings.MessageCenterNumber || "",
            rules: [{ required: true, message: 'Please Enter Message center number' }]
          })(
            <Input/>
          )}
        </Form.Item>

        <Form.Item label="SIM Pin Number">
          {getFieldDecorator('SimPinNumber', {
              initialValue: smsSetings.SimPinNumber || "",
            rules: [{ required: true, message: 'Please Enter SIM Pin Number' }]
          })(
            <Input/>
          )}
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Update Settings
          </Button>
          <Button >
            Cancel
          </Button>
        </Form.Item>
      </Form>

    )
  }
}

export default Form.create()(SMSSettingsComponent)
