import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Input, Row, Col, message, Icon } from "antd";
import cloneDeep from "lodash.clonedeep";

import history from "../../commons/history";
import {
  saveRule,
  ruleListID,
  editRule,
  urlList,
  deviceList,
  recipientList,
  getparameterList,
  nodeviceParameterList,
  tablesList,
  getColumnsList,
  frequencyList,
} from "./Apicalls";
import { DeviceRuleView, Restapi, NodeviceView, TableRuleView } from "./Tabs";
import {
  defaultState,
  predictionDefaultState,
  noDeviceDefault,
  tableState,
  recipientState
} from "./utils/const";
import scriptGenerator, { replicateRule } from "./scriptGenerator";
import { BlockedLabel, SaveButton, StyledDashboard, Tabstyled, GoBackButton } from "./style";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../selectors/language";
import "antd/dist/antd.css";
import constants from "./constants";

const { TabPane } = Tabstyled;

class RuleEngine extends React.Component {
  constructor(props) {
    super(props);
    let objDefault = cloneDeep(defaultState);
    this.state = objDefault;
  }

  async componentWillMount() {
    if (this.props.match.params.id) {
      try {
        let data = await ruleListID(this.props.match.params.id);
        data.data[0].Payload.edit = true;
        let paramList = await getparameterList(
          data.data[0].Payload.selectedDevices
        );
        data.data[0].Payload.parametersList = paramList.data;
        this.setState({ ...data.data[0].Payload });
      } catch (error) {
        console.log(error);
      }
    }

    let recipientData = await recipientList();
    recipientState.recipientList = recipientData.data;

    let priorityFrequencyList = await frequencyList();
    let data = await deviceList();
    let deviceListData = data && data.data && Object.keys(data.data).map((devicekey) => {
      return data.data[devicekey]
    })
    this.setState({
      deviceList: deviceListData,
      recipientList: recipientData.data,
      priorityTypes: priorityFrequencyList ? priorityFrequencyList : []
    });
    defaultState.deviceList = deviceListData;

    let urlData = await urlList();
    predictionDefaultState.urlList = urlData;

    let noDeviceData = await nodeviceParameterList();
    if (noDeviceData && Array.isArray(noDeviceData)) {
      noDeviceDefault.nodeviceparametersList = noDeviceData.data;
    }


    let tableList = await tablesList();
    tableState.tableList = tableList;
    tableState.deviceList = deviceListData;

  }

  handleInputChange = (e, index, type, mode) => {
    if (type == "select") {
      const list = [...this.state[mode]];
      list[index]["operater"] = e;
      this.setState({ [mode]: list });
    } else {
      const { name, value } = e.target;
      const list = [...this.state[mode]];
      list[index][name] = value;
      this.setState({ [mode]: list });
    }
  };

  handleTextAreaChange = (e, index, mode, dignosis) => {
    let list = [...this.state[mode]];
    list[index][dignosis] = e.target.value;
    this.setState({ [mode]: list });
  };

  handleRemoveClick = (index, mode) => {
    const list = [...this.state[mode]];
    if (index === list.length - 1) {
      let currentIndex = list.length - 2;
      let prevIndex = list.length - 1;
      list[currentIndex]["action"] = list[prevIndex]["action"];
      list[currentIndex]["delay"] = list[prevIndex]["delay"];
      list[currentIndex]["priventive"] = list[prevIndex]["priventive"];
      list[currentIndex]["rootcause"] = list[prevIndex]["rootcause"];
      list[currentIndex]["recommandation"] = list[prevIndex]["recommandation"];
      list.splice(index, 1);
    } else {
      list.splice(index, 1);
    }
    this.setState({ [mode]: list });
  };

  handleAddClick = (mode) => {
    let typeList = [...this.state[mode]];

    let index = typeList.length - 1;
    let newItem = {
      tagName: "",
      operater: "",
      value: "",
      action: typeList[index]["action"],
      delay: typeList[index]["delay"],
      priventive: typeList[index]["priventive"],
      rootcause: typeList[index]["rootcause"],
      recommandation: typeList[index]["recommandation"],
      "54765": "",
    };

    delete typeList[index]["action"];
    delete typeList[index]["delay"];
    delete typeList[index]["priventive"];
    delete typeList[index]["rootcause"];
    delete typeList[index]["recommandation"];

    if (this.state.ruleType === "realTime") {
      if (typeList[0].device === "") {
        message.error("Please Select Device to Add New Condition in Real Time");
      } else {
        newItem.device = typeList[0].device;
        this.setState({ [mode]: [...typeList, newItem] });
      }
    } else {
      this.setState({ [mode]: [...typeList, newItem] });
    }
  };

  handleConditionchange = (e, index, mode) => {
    const list = [...this.state[mode]];
    list[index]["54765"] = e;
    this.setState({ [mode]: list });
  };

  saveRules = async () => {
    const result = scriptGenerator(this.state);

    if (result.errorClearnce) {
      const {
        ruleName,
        ruleDesc,
        ruleType,
        batchPriority,
        onChange,
      } = this.state;

      for (let prop in this.state) {
        let flage = prop.startsWith("param");
        if (flage) this.state[prop] = [];
      }
      const replicateList = this.state.replicateDevices;
      this.state.replicateDevices = [];
      try {
        let payLoad = {
          name: ruleName,
          description: ruleDesc,
          payload: this.state,
          tags: result.tagNames,
          script: result.script,
          ruleType,
          batchPriority,
          onChange,
          replicate: "false",
          mailRecipients: this.state.mailRecipients ? this.state.mailRecipients : [],
          url: this.state.url ? this.state.url : []
        };
        let data = await saveRule(payLoad);
        if (data.status === 201) {
          message.success("Rule Created");

          if (ruleType == "batch" && replicateList.length > 0) {
            let deviceList = result.tagNames.map((tag) => {
              return tag.device;
            });
            const uniqueDevice = [...new Set(deviceList)];
            if (uniqueDevice.length > 1) {
              this.setState(defaultState);
              message.error("Rule Cannot Be Replicated For multiple Devices");
              return;
            }
          }
          if (replicateList.length > 0) {
            replicateList.map(async (replicateDevice, i) => {
              let replicatedState = {
                ...replicateRule(this.state, replicateDevice.DeviceId),
              };

              const result = scriptGenerator(replicatedState);
              replicatedState.replicateDevices = [];
              try {
                let repData = await saveRule({
                  name: ruleName,
                  description: ruleDesc,
                  payload: replicatedState,
                  tags: result.tagNames,
                  script: result.script,
                  ruleType,
                  batchPriority,
                  onChange,
                  replicate: "true",
                });
                if (repData.status === 201) {
                  message.success("Rule Created");
                }
                if (i === replicateList.length - 1) {
                  this.setState(cloneDeep(defaultState));
                }
              } catch (error) {
                message.error(error.response.data.message);
                if (i === replicateList.length - 1) {
                  this.setState(cloneDeep(defaultState));
                }
              }
            });
          }

          if (this.state.ruleType === "restApi") {
            this.setState(cloneDeep(predictionDefaultState));
          } else if (this.state.ruleType === "noDevice") {
            this.setState(cloneDeep(noDeviceDefault));
          } else {
            this.setState(cloneDeep(defaultState));
          }
        }
      } catch (error) {
        message.error(error.response.data.message);
      }
    }
  };

  handleelseif = () => {
    if (!this.state.conelse) {
      const elsifLength = [...this.state.elsifLength];
      let prop = `elseif${this.state.elsifNumber + 1}`;
      elsifLength.push(prop);

      let obj = {
        conif: true,
        conelseif: true,
        conelse: false,
        elsifNumber: this.state.elsifNumber + 1,
        elsifLength,
        [prop]: [
          {
            tagName: "",
            operater: "",
            value: "",
            action: [],
          },
        ],
      };

      if (this.state.ruleType === "realTime") {
        if (this.state.inputList[0].device === "") {
          message.error(
            "Please Select Device In If Condition for RealTime Rule"
          );
        } else {
          obj[prop][0].device = this.state.inputList[0].device;
          this.setState(obj);
        }
      } else {
        this.setState(obj);
      }
    }
  };

  handleelse = () => {
    const { elseList } = this.state;
    if (elseList.length === 0) {
      let obj = {
        conif: true,
        conelseif: true,
        conelse: true,
        elseList: [{ tagName: "", operater: "", value: "", action: [] }],
      };
      if (this.state.ruleType === "realTime") {
        if (this.state.inputList[0].device === "") {
          message.error(
            "Please Select Device In If Condition for RealTime Rule"
          );
        } else {
          obj["elseList"][0].device = this.state.inputList[0].device;
          this.setState(obj);
        }
      } else {
        this.setState(obj);
      }
    }
  };

  checkonChange = (values, type, index) => {
    const { translationCache, language } = this.props;
    let translationAction;
    if (language !== undefined && language !== "") {
      if (language === "English") {
        translationAction = values;
      } else {
        let keys = Object.keys(translationCache);

        let arrConverter = values.map((action) => {
          let data = keys.filter((labels) => {
            return translationCache[labels] === action;
          });
          if (data[0] === undefined) {
            return [action];
          } else {
            return data;
          }
        });
        translationAction = arrConverter.map((indexedArry) => {
          return indexedArry[0];
        });
      }
    }

    let typeList = [...this.state[type]];
    typeList[index]["action"] = translationAction;
    this.setState({ [type]: typeList });
  };

  handleDetailsName = (e) => {
    this.setState({ ruleName: e.target.value });
  };

  handleDetailsDesc = (e) => {
    this.setState({ ruleDesc: e.target.value });
  };

  editrule = async () => {
    const result = scriptGenerator(this.state);
    const replicateList = this.state.replicateDevices;
    delete this.state.replicateDevices;
    if (result.errorClearnce) {
      const {
        ruleName,
        ruleDesc,
        ruleType,
        batchPriority,
        onChange,
      } = this.state;

      let payLoad = {
        name: ruleName,
        description: ruleDesc,
        payload: this.state,
        tags: result.tagNames,
        script: result.script,
        ruleType: ruleType,
        batchPriority: batchPriority,
        onChange: onChange,
        mailRecipients: this.state.mailRecipients ? this.state.mailRecipients : [],
        url: this.state.url ? this.state.url : []
      };
      let data = await editRule(this.props.match.params.id, payLoad);
      if (data.status === 200) {
        replicateList.map(async (replicateDevice, i) => {
          let replicatedState = {
            ...replicateRule(this.state, replicateDevice.DeviceId),
          };

          const result = scriptGenerator(replicatedState);
          replicatedState.replicateDevices = [];
          try {
            let repData = await saveRule({
              name: ruleName,
              description: ruleDesc,
              payload: replicatedState,
              tags: result.tagNames,
              script: result.script,
              ruleType,
              batchPriority,
              onChange,
              replicate: "true",
            });
            if (repData.status === 201) {
              message.success("Rule Created");
            }
            if (i === replicateList.length - 1) {
              this.props.history.push(`/rubus/RulesList`);
            }
          } catch (error) {
            message.error(error.response.data.message);
            if (i === replicateList.length - 1) {
              this.props.history.push(`/rubus/RulesList`);
            }
          }
        });
        if (replicateList.length === 0) {
          this.props.history.push(`/rubus/RulesList`);
        }
      }
    }
  };

  handleRuleType = (value, mode) => {
    this.setState({ [mode]: value });
  };
  handleRecipientList = (value, mode) => {
    if (value && Array.isArray(value) && value.length > 5) {
      message.error("Max 5 Users are allowed")
    } else {
      this.setState({ [mode]: value });
    }

  };
  handleurl = (e, mode) => {
    this.setState({ [mode]: e.target.value });
  };
  handleRulebody = (e, mode) => {
    this.setState({ [mode]: e.target.value });

  };
  handledeviceSelect = async (value, index, mode) => {
    const list = [...this.state[mode]];
    list[index]["device"] = value;
    if (this.state.selectedDevices.includes(value)) {
      this.setState({ [mode]: list });
    } else {
      try {
        if (this.state.tabActivationkey === "DeviceRules") {
          this.state.selectedDevices.push(value);

          let parametersData = await getparameterList(
            this.state.selectedDevices
          );
          this.setState({ [mode]: list, parametersList: parametersData.data });
        } else {
          this.state.selectedDevices.push(value);
          let columsData = await getColumnsList(this.state.selectedDevices);
          this.setState({ [mode]: list, columnList: columsData.data });
        }
      } catch (error) {
        message.error("Could Not Able To Load ParametersList");
      }
    }
  };

  handleTableDeviceSelect = async (value, index, mode) => {
    const list = [...this.state[mode]];
    list[index]["tableDevice"] = value;
    this.setState({ [mode]: list });
  };

  handleVisibleChange = (visible, type) => {
    this.setState({ [type]: visible });
  };

  hanblePopoverOnclick = (index, mode, type, dignosis, flag) => {
    if (type === "close") {
      let list = [...this.state[mode]];
      list[index][dignosis] = "";
      this.setState({ [flag]: false });
    } else {
      this.setState({ [dignosis]: false });
    }
  };

  handleTagSelect = (value, index, mode) => {
    const list = [...this.state[mode]];
    list[index]["tagName"] = value;
    this.setState({ [mode]: list });
  };

  handleDelay = (value, type, index) => {
    let time = value._d.toTimeString().split(" ")[0];
    let splitTime = time.split(":");
    let delayTime =
      parseInt(splitTime[0] * 3600) +
      parseInt(splitTime[1] * 60) +
      parseInt(splitTime[2]);

    let typeList = [...this.state[type]];
    typeList[index]["delay"] = [delayTime, time];
    this.setState({ [type]: typeList });
  };

  handleRadioButton = (e) => {
    this.setState({ onChange: e.target.checked });
  };
  handlePickyChange = (values) => {
    this.setState({ replicateDevices: values });
  };

  handleTabs = (value) => {
    switch (value) {
      case "DeviceRules":
        let devicObj = cloneDeep(defaultState);
        devicObj.tabActivationkey = value;
        devicObj.ruleName = this.state.ruleName;
        devicObj.ruleDesc = this.state.ruleDesc;
        this.setState(devicObj);
        break;
      case "RestApi":
        let predicObj = cloneDeep(predictionDefaultState);
        predicObj.tabActivationkey = value;
        predicObj.ruleName = this.state.ruleName;
        predicObj.ruleDesc = this.state.ruleDesc;
        this.setState(predicObj);
        break;
      case "noDevice":
        let nodevicObj = cloneDeep(noDeviceDefault);
        nodevicObj.tabActivationkey = value;
        nodevicObj.ruleName = this.state.ruleName;
        nodevicObj.ruleDesc = this.state.ruleDesc;
        this.setState(nodevicObj);
        break;
      case "table":
        let tableObj = cloneDeep(tableState);
        tableObj.tabActivationkey = value;
        tableObj.ruleName = this.state.ruleName;
        tableObj.ruleDesc = this.state.ruleDesc;
        this.setState(tableObj);
        break;
    }
  };

  render() {
    const { ruleName, ruleDesc, rulebody } = this.state;
    const { translationCache, language } = this.props;
    const {
      handledeviceSelect,
      handleelseif,
      handleelse,
      checkonChange,
      handleRuleType,
      handleRecipientList,
      handleurl,
      handleRulebody,
      handleDelay,
      handleTagSelect,
      handlePickyChange,
      handleInputChange,
      handleAddClick,
      handleRemoveClick,
      handleRadioButton,
      handleVisibleChange,
      hanblePopoverOnclick,
      handleTextAreaChange,
      handleConditionchange,
      handleTableDeviceSelect,
    } = this;

    const Methods = {
      handledeviceSelect,
      handleelseif,
      handleelse,
      handleRuleType,
      handleRecipientList,
      handleurl,
      handleRulebody,
      handleDelay,
      checkonChange,
      handleTagSelect,
      handlePickyChange,
      handleInputChange,
      handleRadioButton,
      handleAddClick,
      handleRemoveClick,
      handleVisibleChange,
      hanblePopoverOnclick,
      handleTextAreaChange,
      handleConditionchange,
      handleTableDeviceSelect,
    };
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        <GoBackButton type="primary" onClick={() => {
          history.goBack()
        }}> <Icon type="left" />   {translationCache &&
          translationCache[`${constants.Go_Back}`] &&
          translationCache[`${constants.Go_Back}`]
          ? translationCache[`${constants.Go_Back}`]
          : `${constants.Go_Back}`} </GoBackButton>

        <div style={{ marginLeft: "2%" }}>

          <Row>
            <Col span={12}>
              <BlockedLabel>
                {translationCache &&
                  translationCache[`${constants.Rule_Name}`] &&
                  translationCache[`${constants.Rule_Name}`]
                  ? translationCache[`${constants.Rule_Name}`]
                  : `${constants.Rule_Name}`}
              </BlockedLabel>
              <Input
                style={{ width: "55%" }}
                placeholder={
                  translationCache &&
                    translationCache[`${constants.phName}`] &&
                    translationCache[`${constants.phName}`]
                    ? translationCache[`${constants.phName}`]
                    : `${constants.phName}`
                }
                name="ruleName"
                value={ruleName}
                maxlength="25"
                onChange={(e) => {
                  e.persist();
                  this.handleDetailsName(e);
                }}
              />
            </Col>
            <Col span={12}>
              <BlockedLabel>
                {translationCache &&
                  translationCache[`${constants.Rule_Description}`] &&
                  translationCache[`${constants.Rule_Description}`]
                  ? translationCache[`${constants.Rule_Description}`]
                  : `${constants.Rule_Description}`}
              </BlockedLabel>
              <Input
                style={{ width: "55%" }}
                placeholder={
                  translationCache &&
                    translationCache[`${constants.phDescription}`] &&
                    translationCache[`${constants.phDescription}`]
                    ? translationCache[`${constants.phDescription}`]
                    : `${constants.phDescription}`
                }
                maxlength="50"
                name="ruleDesc"
                value={ruleDesc}
                onChange={(e) => this.handleDetailsDesc(e)}
              />
            </Col>
          </Row>
          <Tabstyled
            activeKey={this.state.tabActivationkey}
            onChange={this.handleTabs}
            type="card"
          >
            <TabPane
              tab={
                translationCache &&
                  translationCache[`${constants.deviceRules}`] &&
                  translationCache[`${constants.deviceRules}`]
                  ? translationCache[`${constants.deviceRules}`]
                  : `${constants.deviceRules}`
              }
              key="DeviceRules"

            >
              <DeviceRuleView
                config={this.state}
                Methods={Methods}
                translationCache={this.props.translationCache}
                language={this.props.language}
              />
            </TabPane>
            {/* <TabPane
              tab={
                translationCache &&
                translationCache[`${constants.nodeviceRules}`] &&
                translationCache[`${constants.nodeviceRules}`]
                  ? translationCache[`${constants.nodeviceRules}`]
                  : `${constants.nodeviceRules}`
              }
              key="noDevice"
            >
              <NodeviceView
                config={this.state}
                Methods={Methods}
                translationCache={this.props.translationCache}
                language={this.props.language}
              />
            </TabPane>
            <TabPane
              tab={
                translationCache &&
                translationCache[`${constants.restApiRules}`] &&
                translationCache[`${constants.restApiRules}`]
                  ? translationCache[`${constants.restApiRules}`]
                  : `${constants.restApiRules}`
              }
              key="RestApi"
            >
              <Restapi
                config={this.state}
                Methods={Methods}
                translationCache={this.props.translationCache}
                language={this.props.language}
              />
            </TabPane>
            <TabPane
              tab={
                translationCache &&
                translationCache[`${constants.tableRules}`] &&
                translationCache[`${constants.tableRules}`]
                  ? translationCache[`${constants.tableRules}`]
                  : `${constants.tableRules}`
              }
              key="table"
            >
              <TableRuleView
                config={this.state}
                Methods={Methods}
                translationCache={this.props.translationCache}
                language={this.props.language}
              />
            </TabPane> */}
          </Tabstyled>
          {this.state.edit ? (
            <SaveButton
              type="primary"
              onClick={() => {
                this.editrule();
              }}
            >
              {translationCache &&
                translationCache[`${constants.update}`] &&
                translationCache[`${constants.update}`]
                ? translationCache[`${constants.update}`]
                : `${constants.update}`}
            </SaveButton>
          ) : (
            <SaveButton
              type="primary"
              onClick={() => {
                this.saveRules();
                
              }}
            >
              {translationCache &&
                translationCache[`${constants.save}`] &&
                translationCache[`${constants.save}`]
                ? translationCache[`${constants.save}`]
                : `${constants.save}`}
            </SaveButton>
          )}
        </div>
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
});

export default connect(mapStateToProps)(RuleEngine);
