import React from 'react'
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Form, message, Button, Tabs, Checkbox, Row, Col, Card, Collapse, Upload, Icon } from 'antd'

import { getHMICalls,_getReportData, selectDashboard, getDatasourceData, } from './ApiCalls';
import { getCurrentLanguage, getTranslationCache } from "../.././../selectors/language";
import localStorage from '../../../utils/localStorage'
import "./style.css"

const { TabPane } = Tabs;
const { Panel } = Collapse;
let accessToken = localStorage.get("accessToken");


class BulkUploadComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      datasource: [],
      dashboardData: [],
      selectedData: [],
      selectDashboard: [],
      elogData: [],
      selectElogData: [],
      reportData:[],
      selectReport:[],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      uploading: false,
    }
  }
  componentWillMount() {
    this._getHmiList()
  }
  
  onChange = (checkedValues) => {
    let { datasource } = this.state
    let selectedData = []
    checkedValues.map((checkItem) => {
      let select = datasource.filter(data => data.Name === checkItem)
      selectedData.push(...select)
      this.setState({ selectedData })
    })
    
  }
  onChangeDashboard = (checkedValues) => {
    let { dashboardData } = this.state
    let selectDashboard = []
    checkedValues.map((checkItem) => {
      let select = dashboardData.filter(data => data.Name === checkItem)
      selectDashboard.push(...select)
      this.setState({ selectDashboard })
    })
  }
  
  onChangeElog = (checkedValues) => {
    let { elogData } = this.state
    let selectElogData = []
    checkedValues.map((checkItem) => {
      let select = elogData.filter(data => data.ShortDescription === checkItem)
      selectElogData.push(...select)
      this.setState({ selectElogData })
    })
    
  }
  
  onChangeReport = (checkedValues) => {
    let {reportData} = this.state
     let selectReport = []
     checkedValues.map((checkItem) => {
       let select = reportData.filter(data => data.ReportName === checkItem )
       selectReport.push(...select)
       this.setState({selectReport})
     })
  }
  
  _getHmiList = async () => {
    let payload = {
      method: 'GET',
      url:`/api/hmi/hmiList?SiteName=RakCeramics%20Site`,
    }
    let datasource = await getHMICalls(payload)
    let reportData = await _getReportData()
    let dashboardData = await selectDashboard()
    let elogData = await getDatasourceData();
      this.setState({ datasource, reportData, dashboardData, elogData })
    
  }
  dashboardUpload = (file) => {
    const dashString = file.name.includes("dash")
  if (dashString) {
    message.success('success');
  }else {
    message.error("Error in File Upload!")
  }
  }
  ElogUpload = (file) => {
    const ElogString = file.name.includes("Elog")
  if (ElogString) {
    message.success('success');
  }else {
    message.error("Error in File Upload!")
  }
  }
  HmiUpload = (file) => {
    const hmiString = file.name.includes("hmi")
  if (hmiString) {
    message.success('success');
  }else {
    message.error("Error in File Upload!")
  }
  }
  reportUpload = (file) => {
    const reportString = file.name.includes("report")
  if (reportString) {
    message.success('success');
  }else {
    message.error("Error in File Upload!")
  }
  }
  render() {
    const { datasource, selectedData, selectDashboard, dashboardData, elogData, selectElogData,reportData,selectReport } = this.state
     
    return (
      <section style={{ width: '100%',background: "white", minHeight: window.innerHeight - 170,padding:"30px" }} >

        <Tabs defaultActiveKey="1" >
          <TabPane tab="Export" key="1">
            <div style={{ padding: "30px" }}>
              <Collapse  >
                <Panel header="HMI Configuration" key="1">
                  <div style={{ background: '#ECECEC', padding: '30px' }}>
                    <Row>
                      <Checkbox.Group onChange={this.onChange}>
                        {datasource && Array.isArray(datasource)&&datasource.map((name) => {
                          let list = name.Name
                          return (<Col span={3}><Card style={{ margin: "5px", minHeight: "70px", height: "80px", }}><Checkbox style={{ width: "100%" }} value={list}>{list}</Checkbox>

                          </Card></Col>)
                        })}
                      </Checkbox.Group>
                      {selectedData.length >= 1 ? <Button icon="download" type="primary"
                        href={`data:text/json;charset=utf-8,${encodeURIComponent(
                          JSON.stringify(selectedData)
                        )}`}
                        download="hmiConfiguration.json"
                      >
                        {`Download `}
                      </Button> : ""}

                    </Row>
                  </div>
                </Panel>
                <Panel header="Dashboard Configuration" key="2">
                  <div style={{ background: '#ECECEC', padding: '30px' }}>
                    <Row>
                      <Checkbox.Group onChange={this.onChangeDashboard}>
                        {dashboardData && Array.isArray(dashboardData)&& dashboardData.map((name) => {
                          let list = name.Name
                          return (<Col span={3}><Card 
                      style={{ margin: "5px", minHeight: "80px", height: "100px", textAlign: "center" }}>
                        <Checkbox style={{ width: "100%" }} value={list}>{list}</Checkbox>

                          </Card></Col>)
                        })}
                      </Checkbox.Group>
                      {selectDashboard.length >= 1 ? <Button icon="download" type="primary"
                        href={`data:text/json;charset=utf-8,${encodeURIComponent(
                          JSON.stringify(selectDashboard)
                        )}`}
                        download="dashboardConfiguration.json"  
                      >
                        {`Download`}
                      </Button> : ""}
                    </Row>
                  </div>
                </Panel>
                
                <Panel header="Elog Configuration" key="3" >
                  <div style={{ background: '#ECECEC', padding: '30px' }}>
                    <Row>
                      <Checkbox.Group onChange={this.onChangeElog}>
                        {elogData && Array.isArray(elogData)&&elogData.map((name) => {
                          let list = name.ShortDescription
                          return (<Col span={3}><Card style={{ margin: "5px", minHeight: "80px", height: "100px", textAlign: "center" }}><Checkbox style={{ width: "100%" }} value={list}>{list}</Checkbox>

                          </Card></Col>)
                        })}
                      </Checkbox.Group>

                      {selectElogData.length >= 1 ? <Button icon="download" type="primary"
                        href={`data:text/json;charset=utf-8,${encodeURIComponent(
                          JSON.stringify(selectElogData)
                        )}`}
                        download="ElogConfiguration.json"
                      >
                        {`Download `}
                      </Button> : ""}
                    </Row>
                  </div>
                </Panel>
                <Panel header = "Report Configuration" key="4">
                <div style={{ background: '#ECECEC', padding: '30px' }}>
                  <Row>
                    <Checkbox.Group onChange = {this.onChangeReport}>
                     {reportData && Array.isArray(reportData)&&reportData.map((name) => {
                       let list = name.ReportName
                       return(
                         <Col span={3}>
                           <Card style={{ margin: "5px", minHeight: "80px", 
                           height: "100px", textAlign: "center" }}>
                           <Checkbox style={{ width: "100%" }} value={list}>{list}</Checkbox>
                           </Card>
                         </Col>
                       )
                     })}
                    </Checkbox.Group>
                    {selectReport.length >= 1 ? <Button icon="download" type="primary"
                    href={`data:text/json;charset=utf-8,${encodeURIComponent(
                      JSON.stringify(selectReport)
                    )}`}
                    download= "reportConfiguration.json"
                    >
                      {`Download`}
                    </Button>:""}
                  </Row>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </TabPane>
          <TabPane tab="Import" key="2">
            <div style={{ background: '#ECECEC', padding: '30px' }}>
              <Row gutter={16}>
              <Col span={6} className="importCard">
                      <Card title="Report Configuration" bordered={false}>
                         <Upload.Dragger
                          name="file"
                       action="/api/report/bulkUpload"
                           accept="application/json"
                          multiple="false"
                          headers={{
                            Authorization: `Bearer ${accessToken}`,
                          }}
                         beforeUpload={this.reportUpload}
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="" />
                          </p>inbox
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">1. Your can only upload .json file</p>
                          <p className="ant-upload-hint">2. File should be less than 10MB</p>
                        </Upload.Dragger> 
                      </Card>
                      
                    </Col>
                    <Col span={6} className="importCard">
                      <Card title="Dashboard Configuration" bordered={false}>
                         <Upload.Dragger
                          name="file"
                       action="/api/dashboard/bulkUpload"
                          accept="application/json"
                          multiple="false"
                          headers={{
                            Authorization: `Bearer ${accessToken}`,
                          }}
                         beforeUpload={this.dashboardUpload}
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="" />
                          </p>inbox
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">1. Your can only upload .json file</p>
                          <p className="ant-upload-hint">2. File should be less than 10MB</p>
                        </Upload.Dragger> 
                      </Card>
                    </Col>
                    <Col span={6} className="importCard">
                      <Card title="HMI Configuration" bordered={false}>
                         <Upload.Dragger
                          name="file"
                        action="/api/hmi/bulkUpload"
                           accept="application/json"
                          multiple="false"
                          headers={{
                            Authorization: `Bearer ${accessToken}`,
                          }}
                         beforeUpload={this.HmiUpload}
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="" />
                          </p>inbox
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">1. Your can only upload .json file</p>
                          <p className="ant-upload-hint">2. File should be less than 10MB</p>
                        </Upload.Dragger> 
                      </Card>
                      
                    </Col>
                    <Col span={6} className="importCard">
                      <Card title="ELOG Configuration" bordered={false}>
                         <Upload.Dragger
                          name="file"
                       action="/api/logbook/bulkUpload"
                           accept="application/json"
                          multiple="false"
                          headers={{
                            Authorization: `Bearer ${accessToken}`,
                          }}
                         beforeUpload={this.ElogUpload}
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="" />
                          </p>inbox
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">1. Your can only upload .json file</p>
                          <p className="ant-upload-hint">2. File should be less than 10MB</p>
                        </Upload.Dragger> 
                      </Card>
                    </Col>
              </Row>
            </div>
          </TabPane>
        </Tabs>
      </section>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),

});


export default connect(mapStateToProps)(Form.create()(BulkUploadComponent));


