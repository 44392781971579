import { createSelector } from 'reselect'

const tagRef = state => {
  return state.get('tagReference').get('tagReference')
}

const getTagReferenceListSelector = () =>
  createSelector(tagRef, state => {
    return state && state.get('tagReferenceList')
  })

export {
  getTagReferenceListSelector
}
