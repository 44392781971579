import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import {
    Tabs, Select, message, Form
} from 'antd'

import DataWatchTable from './DataWatchTable'
import localStorage from '../../../utils/localStorage'
import history from '../../../commons/history'
import {
    dataWatchTableCall
} from './DataWatchCalls'
import {
    StyledDashboard,
} from './styles'
import {
    tableColumnAdapter
} from './DataWatchAdapter'
import { getDeviceListSelector } from '../../../selectors/device'
import { getAllDeviceList } from '../../../modules/device/device.duck'

const { TabPane } = Tabs;
const { Option } = Select

class DataWatch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataWatchName: '',
            dataWatchJSON: [],
            craneList: props.deviceList || [],
            currentDatasource: [],
            currentTab: "",
            groupName: '',
            craneName: "",
            groupList: [],
        }
    }

    componentWillMount() {
        this.props.actions.getAllDeviceList()
        this._getGroupList();
        let dataWatchName = this.props.match.params.datawatch;
        let craneName = this.props.match.params.craneName;
        let groupName = this.props.match.params.groupname;
        this.getDataWatchJSON(dataWatchName, craneName, groupName, "initial");
    }

    componentDidUpdate() {
        if (this.props.match.params.datawatch !== this.state.dataWatchName) {
            let dataWatchName = this.props.match.params.datawatch;
            let craneName = this.props.match.params.craneName;
            let groupName = this.props.match.params.groupname;
            this.getDataWatchJSON(dataWatchName, craneName, groupName);
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            craneList: nextProps.deviceList
        })
    }

    getDataWatchJSON = async (dataWatchName, craneName, groupName, name) => {
        let dataWatchJSON = await dataWatchTableCall(dataWatchName)
        if (name === 'initial' && !this.state.dataWatchName) {
            let dataWatchObject = await tableColumnAdapter(dataWatchJSON)
            this.setState({ dataWatchName, dataWatchJSON: dataWatchObject })
            if (dataWatchJSON.length > 0) {
                this.setState({
                    currentTab: dataWatchJSON[0].TabName,
                    craneName,
                    groupName
                })
            }
        } else {
            let dataWatchObject = await tableColumnAdapter(dataWatchJSON)
            this.setState({ dataWatchName, dataWatchJSON: dataWatchObject, craneName, groupName })
        }
    }

    callback = (currentTab) => {
        this.setState({
            currentTab
        })
    }

    _getGroupList = () => {
        const siteId = localStorage.get('currentSite')
        const accessToken = localStorage.get('accessToken')
        const Object = {
            method: 'POST',
            url: `/api/site/${siteId}/timeScale/userGroupsMaster`,
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            data: {
                "group": "Data Watch"
            }
        }
        axios(Object)
            .then((response) => {
                this.setState({ groupList: response.data })
            }).catch(error => {
                console.log('ERROR', error)
                this.setState({ groupList: [] })
            })
    }

    onCraneSelection = (value) => {
        this.setState({ craneName: value })
        if (this.state.groupName !== undefined) {
            history.push(`/rubus/DataWatch/${this.state.dataWatchName}/${value}/${this.state.groupName}`)
        } else {
            history.push(`/rubus/DataWatch/${this.state.dataWatchName}/${value}`)
        }

    }

    onGroupSelection = (group) => {
        if (this.state.dataWatchName !== undefined && this.state.craneName !== undefined) {
            this.setState({ groupName: group })
            history.push(`/rubus/DataWatch/${this.state.dataWatchName}/${this.state.craneName}/${group}`)
        } else {
            message.warn("Please select Crane")
        }
    }

    render() {
        let { dataWatchJSON, currentTab, craneList, groupList, groupName, craneName } = this.state
        return (
            <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
                <Form layout="inline">
                    {
                        currentTab === "Operational DataWatch" || currentTab === "Alert DataWatch" ?
                            <Form.Item label="Crane">
                                <Select
                                    onChange={this.onCraneSelection}
                                    value={craneName}
                                    style={{ width: 120 }}
                                >
                                    {
                                        craneList.map((crane) => {
                                            return <Option value={crane.deviceCode}>{crane.deviceCode}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            : null
                    }

                    {
                        currentTab === "Operational DataWatch" ?
                            <Form.Item label="Group">
                                <Select
                                    onChange={this.onGroupSelection}
                                    value={groupName}
                                    style={{ width: 120 }}
                                >
                                    {
                                        groupList.map((group) => {
                                            return <Select value={group.GroupName}>{group.GroupName}</Select>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            : null
                    }

                </Form>




                {
                    dataWatchJSON && currentTab && dataWatchJSON.length > 1 ?
                        (<Tabs tabPosition={this.state.tabPosition} onChange={this.callback} style={{ marginTop: '10px' }} >
                            {
                                this.state.dataWatchJSON.map((tab) => {


                                    let tabObject = {
                                        tab,
                                        ...tab.JSON,
                                        currentTab: currentTab,
                                        deviceId: craneName,
                                        groupName: groupName,

                                    }
                                    return <TabPane tab={tab.TabName} key={tab.TabName}>
                                        {
                                            tab.JSON ?
                                                <DataWatchTable {...tabObject} />
                                                : null
                                        }
                                    </TabPane>
                                })
                            }
                        </Tabs>)
                        :
                        <div>
                            {
                                dataWatchJSON && dataWatchJSON[0] && currentTab ?
                                    (<div>
                                        {
                                            <DataWatchTable {...{
                                                ...dataWatchJSON[0].JSON,
                                                deviceId: craneName,
                                                groupName: groupName,
                                                currentTab: currentTab
                                            }} />
                                        }

                                    </div>)
                                    : <div />
                            }
                        </div>
                }
            </StyledDashboard>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    deviceList: getDeviceListSelector()
})

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                getAllDeviceList
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataWatch)
