import React from "react";
import axios from "axios";
import _ from "lodash";
import Highlighter from "react-highlight-words";
import {
  Button,
  Input,
  Form,
  Table,
  Select,
  Row,
  Switch,
  Col,
  Drawer,
  Icon,
  Collapse,
  message
} from "antd";

import DeviceCondition from "./DeviceCondition";
import DeviceTagReference from "./DeviceTagReference";
import localStorage from "../../../utils/localStorage";
import adapter from "./adapter";
import {
  StyledApplication,
  DrawerFooter
} from "./styles";
import {
  _getDeviceParameterBasedOnType,
  _getDeviceParameterType,
  _getDeviceParameterBasedonDevice,
  _saveConditionTagReference,
  _getParameterGroupList,
  _deleteDeviceParameters
} from '../AssetDeviceList/APICalls'

const { Option } = Select;
const Panel = Collapse.Panel;

class DeviceListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleDeviceDrawer: false,
      currentLanguage: props.currentLanguage || "es",
      deviceDataSource: props.deviceList || [],
      asset: props.asset.assetParent || {},
      deviceTypeList: props.deviceTypeList || [],
      deviceParametersBasedOnType: [],
      deviceObject: {},
      ability: undefined,
      visibleConfiguration: false,
      tagReferenceList: props.tagReferenceList || [],
      selectedDeviceParameterId: "",
      selectedDeviceParameterName: "",
      // conditionList: props.conditionList || [],
      deviceParameterBasedonDevice: [],
      deviceId: "",
      searchText: "",
      parameterGroupList: []
    };
    this.paramTypeonChange = this.paramTypeonChange.bind(this);
  }

  componentWillMount() {
    this.props.actions.getTagReferenceList();
    this.props.actions.getConditionList();
    this.props.actions.getDeviceTypeList();
    this.props.actions.getAllDeviceList();
    this._getParameterGroupList()
  }

  componentWillReceiveProps({
    deviceList,
    tagReferenceList,
    asset,
    deviceTypeList,
    currentLanguage
  }) {
    const deviceDataSource = adapter.filterJsonData(deviceTypeList, deviceList);
    this.setState({
      deviceDataSource: deviceDataSource,
      tagReferenceList,
      deviceTypeList,
      asset: asset.assetParent,
      currentLanguage
    });
  }

  _getParameterGroupList = async () => {
    let parameterGroupList = await _getParameterGroupList()
    this.setState({
      parameterGroupList
    })
  }

  showDrawerDevice = () => {
    this.setState({
      deviceObject: {},
      visibleDeviceDrawer: true
    });
  };

  onCloseNew = () => {
    this.props.form.resetFields();
    this.setState({
      visibleDeviceDrawer: false,
      deviceObject: {}
    });
  };

  onCloseVisibleConfiguration = () => {
    this.setState({
      visibleConfiguration: false
    });
  };

  showDrawerOldDevice = (e, row) => {
    e.preventDefault();
    this.setState({
      deviceObject: row,
      visibleDeviceDrawer: true
    });
  };

  handleChange = value => {
    const { form } = this.props;
    form.setFieldsValue({
      deviceType: value
    });
  };

  createDevice = e => {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        this.props.actions.createDevice(values);
        this.setState({ visible: false });
        this.props.actions.getAllDeviceList();
      }
    });
  };

  updateDevice = e => {
    const { visibleDeviceDrawer } = this.state;
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        this.props.actions.updateDevice(values);
        this.setState({ visibleDeviceDrawer: !visibleDeviceDrawer });
        this.props.actions.getAllDeviceList();
      }
    });
  };

  editRow = async deviceObject => {
    let { DeviceType, Id } = deviceObject
    let deviceParametersBasedOnType = await _getDeviceParameterBasedOnType(DeviceType);
    let deviceParameterBasedonDevice = await _getDeviceParameterBasedonDevice(Id);
    let parameterType = await _getDeviceParameterType();
    this.setState({
      deviceParametersBasedOnType,
      deviceParameterBasedonDevice,
      deviceId: Id,
      parameterType,
      deviceObject,
      visibleConfiguration: true
    })
  };

  _getDeviceParameterBasedonDevice = deviceId => {

    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get("accessToken");
    const deviceTypeObject = {
      method: "GET",
      url: `/api/site/${siteId}/device/relatedParams/${deviceId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(deviceTypeObject)
      .then(response => {
        this.setState({ deviceId });
        this.setState({ deviceParameterBasedonDevice: response.data });
      })
      .catch(function () { });
  };

  paramTypeonChange(index, parameterId) {
    const { parameterType, deviceParameterBasedonDevice } = this.state;
    deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
      if (deviceParameter.ParameterId === parameterId) {
        deviceParameterBasedonDevice[key].parameterType =
          parameterType[index].code;
      }
    });

    this.setState({ deviceParameterBasedonDevice });
  }

  parameterTypeonChange = (value, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;
    deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
      if (deviceParameter.ParameterId === parameterId) {
        deviceParameterBasedonDevice[key].ParameterType = value;
      }
    });

    this.setState({ deviceParameterBasedonDevice });
  }

  parameterGrouponChange = (value, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;
    deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
      if (deviceParameter.ParameterId === parameterId) {
        deviceParameterBasedonDevice[key].ParameterGroup = value;
      }
    });

    this.setState({ deviceParameterBasedonDevice });
  }

  aliasOnChange = (value, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;
    deviceParameterBasedonDevice && deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
      if (deviceParameter.ParameterId === parameterId) {
        deviceParameterBasedonDevice[key].AliasCode = value;
      }
    });

    this.setState({ deviceParameterBasedonDevice });
  }

  deleteDevice(id) {
  }

  _getDeviceParameterBasedOnType = deviceTypeId => {

    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get("accessToken");
    const deviceTypeObject = {
      method: "GET",
      url: `/api/site/${siteId}/device/getParameters/${deviceTypeId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(deviceTypeObject)
      .then(response => {
        this.setState({ deviceParametersBasedOnType: response.data });
      })
      .catch(function () { });
  };

  addDeviceParamter = () => {
    const {
      deviceParameterBasedonDevice,
      selectedDeviceParameterId
    } = this.state;
    if (
      selectedDeviceParameterId
    ) {
      let deviceStatus = false;
      deviceParameterBasedonDevice.forEach(usedDP => {
        if (usedDP.ParameterId === selectedDeviceParameterId) {
          deviceStatus = true;
        }
      });
      if (!deviceStatus) {
        const deviceParameter = {
          "AliasCode": "",
          "ParameterConditions": [],
          "ParameterGroup": "",
          "ParameterId": selectedDeviceParameterId,
          "ParameterReferences": [],
          "ParameterType": ""
        };
        deviceParameterBasedonDevice.push(deviceParameter);
        this.setState({
          deviceParameterBasedonDevice
        });
      } else {
        message.error("Equipment parameter already exist");
      }
    } else {
      message.error("Please select device parameter");
    }
  };

  onChange = value => {
    this.setState({ selectedDeviceParameterId: value });
  };

  _afterAddedRemoveFromList() {
    let {
      deviceParametersBasedOnType,
      deviceParameterBasedonDevice
    } = this.state;

    deviceParameterBasedonDevice.forEach(usedDP => {
      deviceParametersBasedOnType = _.remove(
        deviceParametersBasedOnType,
        function (e) {
          return e._key !== usedDP.ParameterId;
        }
      );
      this.setState({ deviceParametersBasedOnType });
    });
  }

  aliasOnChange = (value, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;
    deviceParameterBasedonDevice && deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
      if (deviceParameter.ParameterId === parameterId) {
        deviceParameterBasedonDevice[key].AliasCode = value;
      }
    });

    this.setState({ deviceParameterBasedonDevice });
  }

  saveDeviceParameter = saveDeviceParameter => {
    if (saveDeviceParameter) {
      saveDeviceParameter && saveDeviceParameter.ParameterReferences && saveDeviceParameter.ParameterReferences.forEach((parameterAlias, index) => {
        if (parameterAlias.isNew) {
          delete parameterAlias.isNew;
          delete parameterAlias.editable;
          saveDeviceParameter.ParameterReferences[index] = parameterAlias;
        }
      });
      saveDeviceParameter && saveDeviceParameter.ParameterConditions && saveDeviceParameter.ParameterConditions.forEach((conditions, index) => {
        if (conditions.isNew) {
          delete conditions.isNew;
          delete conditions.editable;
          saveDeviceParameter.ParameterConditions[index] = conditions;
        }
      });
      _saveConditionTagReference(saveDeviceParameter, this.state.deviceId);
    }
  };

  saveConditonList = (updatedConditionList, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;

    deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
      if (deviceParameter.ParameterId === parameterId) {
        deviceParameterBasedonDevice[key].ParameterConditions = updatedConditionList;
      }
    });
    this.setState({ deviceParameterBasedonDevice });
  };

  saveTagReferenceList = (updatedTRList, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;

    deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
      if (deviceParameter.ParameterId === parameterId) {
        deviceParameterBasedonDevice[key].ParameterReferences = updatedTRList;
      }
    });
    this.setState({ deviceParameterBasedonDevice });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  _getDeviceParameterType() {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get("accessToken");
    const ParameterTypeObject = {
      method: "GET",
      url: `/api/site/${siteId}/parameterType/list`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(ParameterTypeObject)
      .then(response => {
        this.setState({ parameterType: response.data });
      })
      .catch(function () { });
  }


  _deleteDeviceParameter = async (parameterId) => {
    await _deleteDeviceParameters(parameterId)
  }


  render() {
    const {
      deviceDataSource,
      selectedDeviceParameterId,
      deviceObject,
      deviceTypeList,
      deviceParametersBasedOnType,
      deviceParameterBasedonDevice,
      parameterGroupList,
      parameterType
    } = this.state;
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: "Equipment Name",
        dataIndex: "Name",
        key: "Name",
        ...this.getColumnSearchProps("Name"),
        render: text => <div>{text}</div>,
        onCell: record => {
          return {
            onClick: e => {
              this.showDrawerOldDevice(e, record);
            }
          };
        }
      },
      {
        title: "Equipment Code",
        dataIndex: "DeviceCode",
        key: "DeviceCode",
        ...this.getColumnSearchProps("DeviceCode"),
        render: text => <div>{text}</div>,
        onCell: record => {
          return {
            onClick: e => {
              this.showDrawerOldDevice(e, record);
            }
          };
        }
      },
      {
        title: "Equipment Type",
        dataIndex: "DeviceTypeName",
        key: "DeviceTypeName",
        ...this.getColumnSearchProps("DeviceTypeName"),
        render: text => <span>{text}</span>,
        onCell: record => {
          return {
            onClick: e => {
              this.showDrawerOldDevice(e, record);
            }
          };
        }
      },
      {
        title: "Time Series",
        dataIndex: "TimeSeries",
        key: "TimeSeries",
        ...this.getColumnSearchProps("TimeSeries"),
        render: text => <span>{text ? text.toString() : ""}</span>,
        onCell: record => {
          return {
            onClick: e => {
              this.showDrawerOldDevice(e, record);
            }
          };
        }
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        render: (text, row) => (
          <div>
            <button
              type="button"
              className="ant-btn"
              style={{ marginRight: "5px" }}
              onClick={() => {
                this.editRow(row);
              }}
            >
              <Icon type="unordered-list"></Icon>
            </button>
          </div>
        )
      }
    ];

    const customPanel = {
      background: "#f7f7f7",
      borderRadius: 4,
      marginBottom: 24,
      border: 0,
      overflow: "hidden"
    };

    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 226 }}>
        <Drawer
          title="Configuration"
          width={800}
          onClose={this.onCloseVisibleConfiguration}
          visible={this.state.visibleConfiguration}
        >
          <div>
            <h4>Select Equipment Parameter :</h4>
            <div>
              <Select
                defaultValue={selectedDeviceParameterId || undefined}
                style={{ width: 300, marginRight: "15px" }}
                onChange={this.onChange}
                placeholder="Select Equipment Parameters"
              >
                {deviceParametersBasedOnType && Array.isArray(deviceParametersBasedOnType) &&
                  deviceParametersBasedOnType.map((deviceParameters) => {
                    return <Option value={deviceParameters.Id}>{deviceParameters.Name}</Option>;
                  })}
              </Select>
              <Button type="primary" onClick={this.addDeviceParamter}>
                Add{" "}
              </Button>
            </div>
            {deviceParameterBasedonDevice.map(device => {

              return (
                <Collapse
                  bordered={false}
                  style={{ marginTop: "20px" }}
                  expandIcon={({ isActive }) => (
                    <Icon type="caret-right" rotate={isActive ? 90 : 0}></Icon>
                  )}
                >
                  <Panel
                    header={
                      <h4>
                        {parameterType && parameterType[0] && parameterType[0].Name ? parameterType[0].Name : ""}  - {device.ParameterId}
                      </h4>
                    }
                    key={device.id}
                    style={customPanel}
                  >
                    <div style={{ padding: "0px 10px" }}>
                      <Icon
                        type="save"
                        theme="twoTone"
                        style={{
                          fontSize: "20px",
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "end"
                        }}
                        onClick={() => this.saveDeviceParameter(device)}
                      ></Icon>

                      <div>
                        <label
                          style={{
                            display: "block",
                            fontWeight: "500",
                            marginBottom: "4px"
                          }}
                        >
                          Parameter Type
                        </label>
                        <Select
                          defaultValue={device.ParameterType || undefined}
                          style={{ width: 300, marginRight: "15px" }}
                          onChange={e =>
                            this.parameterTypeonChange(e, device.ParameterId)
                          }
                          placeholder="Select ParameterType"
                        >
                          {parameterType && parameterType.map((deviceParamsType, index) => {
                            return (
                              <Option value={deviceParamsType.Id}>
                                {deviceParamsType.Name}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                      <div>
                        <label
                          style={{
                            display: "block",
                            fontWeight: "500",
                            marginBottom: "4px"
                          }}
                        >
                          Parameter Group
                        </label>
                        <Select
                          defaultValue={device.ParameterGroup || undefined}
                          style={{ width: 300, marginRight: "15px" }}
                          onChange={e =>
                            this.parameterGrouponChange(e, device.ParameterId)
                          }
                          placeholder="Select Parameter Group"
                        >
                          {parameterGroupList && Array.isArray(parameterGroupList) &&
                            parameterGroupList.map((parameterGroup, index) => {
                              return (
                                <Option value={parameterGroup.Id}>
                                  {parameterGroup.Name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div>
                        <label
                          style={{
                            display: "block",
                            fontWeight: "500",
                            marginBottom: "4px"
                          }}
                        >
                          Alias Code
                        </label>
                        <Input
                          value={device.AliasCode || undefined}
                          style={{ width: 300, marginRight: "15px" }}
                          placeholder="Input Alias Code"
                          onChange={e =>
                            this.aliasOnChange(e.target.value, device.ParameterId)
                          }
                        />
                      </div>
                      <DeviceCondition
                        conditionReference={device}
                        saveConditonList={this.saveConditonList}
                      />
                      <DeviceTagReference
                        conditionReference={device}
                        tagReferenceList={this.props.tagReferenceList}
                        saveTagReferenceList={this.saveTagReferenceList}
                      />
                    </div>
                  </Panel>
                </Collapse>
              );
            })}
          </div>
        </Drawer>

        <div>
          <Drawer
            title={!deviceObject.Id ? "Create Equipment" : "Update Equipment"}
            width={500}
            onClose={this.onCloseNew}
            visible={this.state.visibleDeviceDrawer}
          >
            <Form layout="vertical" hideRequiredMark>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Equipment Name">
                    {getFieldDecorator("Name", {
                      initialValue: deviceObject.Name,
                      rules: [
                        {
                          required: true,
                          message: "Please input your device name!"
                        }
                      ]
                    })(<Input placeholder="Please enter Equipment name" />)}
                    {getFieldDecorator("Id", {
                      initialValue: deviceObject.Id
                    })(<Input style={{ display: "none" }} />)}
                  </Form.Item>
                  <Form.Item label="Equipment Code">
                    {getFieldDecorator("DeviceCode", {
                      initialValue: deviceObject.DeviceCode,
                      rules: [
                        {
                          required: true,
                          message: "Please input your device code!"
                        }
                      ]
                    })(<Input placeholder="please enter Equipment code" />)}
                  </Form.Item>
                  <Form.Item label="Equipment Type">
                    {getFieldDecorator("DeviceType", {
                      initialValue: deviceObject.DeviceType,
                      rules: [
                        {
                          required: true,
                          message: "Please input your Equipment type!"
                        }
                      ]
                    })(
                      <Select
                        placeholder="please enter Equipment Type"
                        defaultValue={deviceObject.DeviceType}
                        style={{ width: 450 }}
                        onChange={this.handleChange}
                        disabled={!!deviceObject.DeviceType}
                      >
                        {deviceTypeList && Array.isArray(deviceTypeList)
                          ? deviceTypeList.map(deviceType => (
                            <Option value={deviceType.Id}>
                              {deviceType.Name}
                            </Option>
                          ))
                          : null}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item label="Equipment TimeSeries">
                    {getFieldDecorator("TimeSeries", {
                      initialValue: deviceObject.TimeSeries || false,
                      valuePropName: "checked"
                    })(<Switch />)}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("Asset", {
                      initialValue: ""
                    })(<Input style={{ display: "none" }} />)}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <DrawerFooter>
              <Button type="primary" onClick={this.onCloseNew} style={{ marginRight: 8 }}>
                {" "}
                Cancel
              </Button>
              {deviceObject.Id === undefined ? (
                <Button onClick={this.createDevice} type="primary">
                  Create Equipment
                </Button>
              ) : (
                <Button onClick={this.updateDevice} type="primary">
                  Update Equipment
                </Button>
              )}
            </DrawerFooter>
          </Drawer>
        </div>
        <div className="Table">
        <Table
          size="large"
          columns={columns}
          dataSource={deviceDataSource && Array.isArray(deviceDataSource) ? deviceDataSource : []}
          pagination={
            deviceDataSource && Array.isArray(deviceDataSource) && deviceDataSource.length > 5
              ? {
                pageSize: "5"
              }
              : false
          }
          locale="No Data"
        />
        </div>
      </StyledApplication>
    );
  }
}

export default Form.create()(DeviceListComponent);
