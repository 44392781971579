import React, { Component } from "react";
import moment from "moment";
import cloneDeep from "lodash.clonedeep";
import { connect } from "react-redux";
import clone from "lodash.clonedeep";
import { message, Spin, Modal, Table, Input, Form, Radio } from "antd";
import { createStructuredSelector } from "reselect";
import "../../CSS/Table.css"
import ReactEcharts from "../../Echarts/Echarts";
import localStorage from "../../../../../utils/localStorage";
import axios from 'axios'
import ModalView from "./ModalView"
import {
  trendLabelTranslation,
  predictionsLegendsTranslation,
} from "../../../../../Adapter/TranslationAdapter"
import {
  historicPredictionsChartDataa,
  historicPredictionsWithPayloadChartDataa,
} from "../../ChartCalls";
import { getCurrentLanguage, getTranslationCache } from "../../../../../selectors/language"
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 10 },
};
class PredictedGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(props),
      data: [],
      loading: false,
      openModal: false,
      feedbackForm: this.props.feedbackFormData ? this.props.feedbackFormData : {},
      translationCache: props.translationCache || [],
      language: props.language || "English",
      modalVisible: "",
      tableData: []
    };
  }
  timeTicket = null;

  componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = 1 * 60000;
    }

    this.timeTicket = setInterval(() => {
      this.getData(this.props);
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.refereshData !== this.props.refereshData
    ) {
      this.getData();
    }
    if (prevProps.feedbackFormData !== this.props.feedbackFormData) {
      this.setState({ feedbackForm: this.props.feedbackFormData })
    }
  }

  // componentWillReceiveProps(props) {
  //     this.getData(props);
  // }

  componentWillUnmount() {
    clearInterval(this.timeTicket);
  }

  getData = async (props) => {
    let json = [];
    let series = [];
    const { graphprops } = this.props;
    const option = cloneDeep(this.state.option);
    this.setState({ loading: true });

    if (
      (graphprops &&
        graphprops &&
        graphprops.dashboardType === "dynamic" &&
        this.props.deviceId) ||
      (graphprops &&
        graphprops.dateExists === "true" &&
        this.props.selectionDate) ||
      (graphprops &&
        graphprops.monthExists === "true" &&
        this.props.selectionMonth) ||
      (graphprops &&
        graphprops.weekExists === "true" &&
        this.props.selectionWeek) ||
      (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
      (graphprops &&
        graphprops.dateRangeExists === "true" &&
        this.props.selectionDateRange)
    ) {
      json = await historicPredictionsWithPayloadChartDataa(
        "predictions",
        graphprops,
        this.props.deviceId,
        this.props.selectionDate,
        this.props.selectionMonth,
        this.props.selectionWeek,
        this.props.shift,
        this.props.selectionDateRange
      );
      this.setState({ loading: false });
    } else if (graphprops) {
      json = await historicPredictionsChartDataa("predictions", graphprops);
      this.setState({ loading: false });
    }

    if (json && Array.isArray(json)) {
      this.setState({
        data: json,
      });

      if (graphprops.predictionsType === "arrayOfArray") {
        option.xAxis.data = json.map(function (item) {
          return moment
            .utc(item[0], "YYYY-MM-DD HH:mm")
            .local()
            .format("YYYY-MM-DD HH:mm");
          // return moment(item[0]).format("DD/MM/YYYY HH:mm:ss");
        });

        series = [
          {
            name: "Anomaly",
            symbolSize: 12,
            data: json.map(function (item) {
              return item[1];
            }),
            type: "scatter",
            symbolSize: function (data) {
              return 15
            },
          },
          {
            symbolSize: 0,
            data: json.map(function (item) {
              return 4;
            }),
            type: "line",
            lineStyle: {
              color: "skyblue",
            },
          },
          {
            name: "Actual",
            symbolSize: 12,
            data: json.map(function (item) {
              return item[2];
            }),
            type: "line",
            symbol: "triangle",
            lineStyle: {
              color: "skyblue",
            },
          },
        ];
      } else {
        option.xAxis.data = json.map(function (item) {
          return moment
            .utc(item.time, "YYYY-MM-DD HH:mm")
            .local()
            .format("YYYY-MM-DD HH:mm");
        });
        series = [
          {
            name: "Predicted Abnormal",
            barWidth: "60%",
            data: json.map(function (item) {
              if (item && item.count && item.Future !== true) {// Change               
                return item.count;
              } else {
                return "null"
              }

            }),
            type: "bar",
            color: "steelblue"

          },
          {
            name: "Actual Abnormal",
            symbolSize: 9,
            data: json.map(function (item) {
              if (item && item.count && item.count > 0 && item.status === 4) {// Change
                return item.count + 1;
              } else if (item.status === 4 && item.count === 0) {
                return 5
              } else {
                return "null"
              }

            }),
            type: "scatter",
            color: "yellow"
          }, {
            name: "Future Abnormal",
            // symbolSize: 6,
            barWidth: "60%",
            data: json.map(function (item) {
              if (item && item.Future && item.Future === true) {// Change
                return item.count;
              } else {
                return "null"
              }
            }),
            type: "bar",
            color: "red"
          }

        ];
      }
    }

    series = await trendLabelTranslation(
      series,
      this.props.translationCache,
      this.props.language
    );
    let legends = await predictionsLegendsTranslation(
      option.legend,
      this.props.translationCache,
      this.props.language
    );
    option.legends = legends;
    option.series = series;
    this.setState({ option });
    return option;
  };

  getOption = (props) => {
    let option = {
      title: {
        left: "center",
        textStyle: {
          color: "white",
          align: "center",
        },
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: "download",
            backgroundColor: "black",
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: "#8c8c8c",
              borderWidth: 2,
            },
          },
        },
      },
      xAxis: {
        type: "category",
        axisLine: {
          lineStyle: {
            color: "white",
          },
        },
        axisTick: {
          lineStyle: {
            color: "white",
          },
        },
        axisLabel: {
          color: "white",
          formatter: function (value, index) {
            return moment(value).format("HH:mm")
          }
        },
      },
      yAxis: {
        splitLine: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "white",
          },
        },
        axisTick: {
          lineStyle: {
            color: "white",
          },
        },
        axisLabel: {
          color: "white",
        },
      },
      dataZoom: [
        {
          // startValue: '2020-04-20'
        },
        {
          type: "inside",
        },
      ],
      legend: {
        right: 60,
        data: this.props.graphprops && this.props.graphprops.predictionsType === "arrayOfObject"
          ? ["Predicted Abnormal", "Actual Abnormal", "Future Abnormal"] : ["Anomaly", "Actual"],
        textStyle: {
          color: "white",
        },
      }
    };
    return option;
  };

  onChartClick = (cellData) => {
    let { graphprops } = this.props;
    if (graphprops.dateRangePredictions.predictionType === "arrayOfArray") {
      if (cellData.seriesType === "scatter" && cellData.seriesIndex === 0) {
        let predictedObject = this.state.data.filter((predicted) => {
          return (
            moment.utc(predicted[0], "YYYY-MM-DD HH:mm").local().format('YYYY-MM-DD HH:mm') === cellData.name
          );
        });

        if (predictedObject[0] && predictedObject[0][3]) {
          this.props.summaryTable(predictedObject[0], graphprops.predictionsType);
        } else {
          message.warn("No Data");
        }
      }
      // this.setState({ modalVisible: true })
      // if (cellData.seriesType === "bar" && cellData.seriesIndex === 0) {
      //   this.setState({ modalVisible: true })
      //   let predictedObject = this.state.data.filter((predicted) => {

      //     return (
      //       moment
      //         .utc(predicted[0], "YYYY-MM-DD HH:mm")
      //         .local()
      //         .format("YYYY-MM-DD HH:mm") === cellData.name
      //     );
      //   });

      //   if (predictedObject[0] && predictedObject[0][3]) {
      //     this.props.summaryTable(
      //       predictedObject[0],
      //       graphprops.predictionsType
      //     );
      //   } else {
      //     message.warn("No Data");
      //   }

      //   if (predictedObject[0] && predictedObject[0][4]) {
      //     if (predictedObject[0] && predictedObject[0][3]) {
      //       this.props.feedbackForm(clone(predictedObject[0][4]));
      //       this.setState({
      //         feedbackForm: predictedObject[0][4]

      //       });
      //     } else {
      //       this.setState({
      //         feedbackForm: predictedObject[0][4],
      //         openModal: true,
      //       });
      //     }
      //   } else {
      //     this.props.feedbackForm({});
      //   }
      // }
    } else {
      if (graphprops.dateRangePredictions.predictionType === "Array of Object") {
        if (cellData.seriesType === "bar" && cellData.seriesIndex === 0) {
          this.setState({ modalVisible: true })
          let predictedObject = this.state.data.filter((predicted) => {
            return (
              moment
                .utc(predicted.time, "YYYY-MM-DD HH:mm")
                .local()
                .format("YYYY-MM-DD HH:mm") === cellData.name
            );
          });
          if (
            predictedObject[0] &&
            predictedObject[0].tagsList &&
            predictedObject[0].tagsList.length > 0
          ) {
            this.props.summaryTable(
              predictedObject[0],
              graphprops.predictionsType
            );
          } else {
            // message.warn("No Data");
          }

          if (predictedObject[0] && predictedObject[0].notification) {
            if (
              predictedObject[0] &&
              predictedObject[0].tagsList &&
              predictedObject[0].tagsList.length > 0
            ) {
              this.props.feedbackForm(clone(predictedObject[0].notification));
            } else {
              this.setState({
                feedbackForm: predictedObject[0].notification,
                openModal: true,
              });

            }
          } else {
            this.props.feedbackForm({});
            // message.warn("No Data");
          }
        }
      }
    }
  };


  handleOk = (feedbackText) => {
    const feedbackForm = feedbackText;

    let payload = {
      ...feedbackForm,
      "Notification Id": feedbackForm.NotificationId,
      "Tag Description": feedbackForm.TagDescription,
    };
    const siteId = localStorage.get("currentSite");
    const accessToken = localStorage.get("accessToken");
    const deviceTypeObject = {
      method: "POST",
      url: `/api/dashboard/feedback`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: payload,
    };
    return axios(deviceTypeObject)
      .then((response) => {
        this.setState({
          modalVisible: false,
        });
        this.props.refereshData("close");
      })
      .catch(function () {
        return "error";
      });
  };

  handleCancel = (e) => {
    this.setState({
      modalVisible: false,
    });
    this.props.refereshData("close");
  };

  render() {
    let { loading, modalVisible, feedbackForm } = this.state;
    let onEvents = {
      click: (cellData) => this.onChartClick(cellData),
    };

    return (
      <span>
        {loading === false ? (
          <div style={{ padding: "10px" }}>
            <ReactEcharts
              onEvents={onEvents}
              option={this.state.option}
              className="react_for_echarts"
            />
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              color: "white",
              marginTop: "15%",
            }}
          >
            <h2 style={{ color: "white" }}>
              <Spin spinning={loading} tip="Loading..." size="large"></Spin>
            </h2>
          </div>
        )}

        {
          this.state.modalVisible ?
            <ModalView feedbackForm={feedbackForm} visible={modalVisible} onOk={this.handleOk}
              onCancel={this.handleCancel} /> : null
        }

      </span>

    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
});

export default connect(mapStateToProps)(Form.create()(PredictedGraph));
