import React, { Component } from 'react'
import { Fragment } from "react";
import ReactEcharts from "../Echarts/Echarts";
import { makeSelectThemeing } from "../../../../selectors/theme";
import cloneDeep from "lodash.clonedeep";
import { connect } from "react-redux";
import moment from "moment";
import { Tag, Form, Select } from "antd";
import {
  getChartDataWithoutSelection,
  getChartDataWithSelection,
} from "../ChartCalls";
import { createStructuredSelector } from "reselect";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import history from '../../../../commons/history';
import { defaultRefreshTime } from '../constant.json'
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { PieNestJSon } from '../../Mock/MockJson';

const { Option } = Select

class PieNestComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      deviceType: "Total"
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing, this.state.deviceType);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing, this.state.deviceType);
      }

    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection
    ) {
      this.getData(this.props, this.props.selectedThemeing, this.state.deviceType);
    }
  }

  componentWillUnmount() {
    this.abortController.abort()
    clearInterval(this.timeTicket);
  }

  getData = async (props, theme, deviceType) => {
    this.requestCheck = false
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = PieNestJSon;
      this.requestCheck = true
    } else {
      if (
        (graphprops &&
          graphprops.dashboardType === "dynamic" &&
          this.props.deviceId) ||
        (graphprops &&
          graphprops.dateExists === "true" &&
          this.props.selectionDate) ||
        (graphprops &&
          graphprops.monthExists === "true" &&
          this.props.selectionMonth) ||
        (graphprops &&
          graphprops.weekExists === "true" &&
          this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
        (graphprops &&
          graphprops.dateRangeExists === "true" &&
          this.props.selectionDateRange) ||

        (graphprops && graphprops.dayMonthYearExists === 'true' && this.props.dayMonthYear)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          deviceType,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection
        );
        this.requestCheck = true
      } else {
        json = await getChartDataWithoutSelection(graphprops, this.abortController,
          this.props.dropdownSelection);
        this.requestCheck = true
      }
    }
    if ((json && json.message) || (Array.isArray(json) && (json.length === 0) &&
      (graphprops.enableMock !== true))) {

      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
      }

    } else {
      var inner = json && json.inner;
      var outer = json && json.outer;
      let tagnames = [];
      let name = "";
      let legendsdata = []

      let jsonData = { ...inner, ...outer }
      if (json && Object.keys(json).length !== 0) {
        let option = cloneDeep(this.state.option);

        let legend = {
          legend: {
            data: Object.keys(jsonData).map((val) => {
              if (val.name !== name) {
                if (tagnames.indexOf(jsonData[val].name) === -1) {
                  tagnames.push(val.name);
                  name = jsonData[val].name;
                  legendsdata.push({
                    name: jsonData[val].name,
                    icon: "roundRect",
                    textStyle: {
                      color: theme === "lightTheme" ? "black" : "white",
                      fontSize: 16,
                      fontWeight: "bold",
                    },
                  });
                }
              }
            })
          }
        }

        let series = [
          {
            name: 'Equipment (Mins)',
            type: 'pie',
            selectedMode: 'single',
            radius: [0, '30%'],
            label: {
              position: 'inner',
              fontSize: 14
            },
            labelLine: {
              show: false
            },
            data: json.inner
          },
          {
            name: 'Equipment(Mins)',
            type: 'pie',
            radius: ['45%', '60%'],
            labelLine: {
              length: 30
            },
            label: {
              formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
              backgroundColor: '#F6F8FC',
              borderColor: '#8C8D8E',
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: '#6E7079',
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#8C8D8E',
                  width: '100%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  color: '#4C5058',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                },
                per: {
                  color: '#fff',
                  backgroundColor: '#4C5058',
                  padding: [3, 4],
                  borderRadius: 4
                }
              }
            },
            data: json.outer
          }
        ]

        option.series = series
        option.legend = legendsdata;
        this.setState({
          option,
          refreshDateTime: moment()
        });
      }
    };
    this.setState({
      errorInAPI,
      noDataInDB
    })
  }

  handleChange = (e) => {
    this.setState({ deviceType: e })
    if (e === "Reason") {
      history.push(`/rubus/report/Down Time Report`)
    }
    this.getData(this.props, this.props.selectedThemeing, e);
  }

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime, deviceType } = this.state;
    let { graphprops } = this.props

    return (
      <Fragment>

        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        <Form>
          <Select style={{ width: "200px" }} onChange={this.handleChange} defaultValue={deviceType}>
            <Option value="Total">Total</Option>
            <Option value="Planned">Planned</Option>
            <Option value="UnPlanned">UnPlanned</Option>
            <Option value="Reason">Reason</Option>
          </Select>
        </Form>
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <div>

              <ReactEcharts
                option={this.state.option}
                style={{ width: "100%", height: "45vh" }}
                className="react_for_echarts"
              />
            </div>
        }



      </Fragment>
    )
  }

  getOption = () => {
    // let theme = this.props.selectedThemeing;

    let option = {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            backgroundColor: "lightTheme" ? "black" : "white",
            title: "download",
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: "#8c8c8c",
              borderWidth: 2,
            },
          },
        },
      },
      legend: {
        data: []
      },
      series: [
        {
          type: 'pie',
          selectedMode: 'single',
          radius: [0, '30%'],
          label: {
            position: 'inner',
            fontSize: 14
          },
          labelLine: {
            show: false
          },
          data: []
        },
        {
          type: 'pie',
          radius: ['45%', '60%'],
          labelLine: {
            length: 30
          },
          label: {
            formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
            backgroundColor: '#F6F8FC',
            borderColor: '#8C8D8E',
            borderWidth: 1,
            borderRadius: 4,
            rich: {
              a: {
                color: '#6E7079',
                lineHeight: 22,
                align: 'center'
              },
              hr: {
                borderColor: '#8C8D8E',
                width: '100%',
                borderWidth: 1,
                height: 0
              },
              b: {
                color: '#4C5058',
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 33
              },
              per: {
                color: '#fff',
                backgroundColor: '#4C5058',
                padding: [3, 4],
                borderRadius: 4
              }
            }
          },
          data: []
        }
      ]
    };

    return option;
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(PieNestComponent);
