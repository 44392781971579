function dropDownAdapter(assetlist, devicelist) {
  let assetArr = Object.keys(assetlist);
  let dropdownArray = [];

  assetArr.map(assetId => {
    if (assetlist[assetId]) {
      if (assetlist[assetId].parent === null) {
        let tempObj = {};
        tempObj.value = assetlist[assetId].name;
        tempObj.label = assetlist[assetId].name;
        tempObj._key = assetlist[assetId]._key;
        tempObj.isLeaf = false;
        dropdownArray.push(tempObj);
      }
    }
  });
 return dropdownArray;
}

export function filterAsset(selectedOption, assetList) {
  let targetOption = selectedOption[selectedOption.length - 1];
  let assetArr = Object.keys(assetList);
  let filteredAssets = [];

  assetArr.map(assetId => {
    if (assetList[assetId]) {
      if (assetList[assetId].parent === targetOption._key) {
        let asset = assetList[assetId];
        asset.value = asset.name;
        asset.label = `${asset.name}`;
        asset.key = asset._key;
        asset.isLeaf = false;
        filteredAssets.push(assetList[assetId]);
      }
    }
  });
  let device = {
    value: 'device',
    label: 'Device',
  };

  filteredAssets.push(device);
  return filteredAssets;
}

export function getFilterDevicefrmCache(asset, paramlist) {
 let deviceList = [];
  let parameterArr = Object.keys(paramlist);
  parameterArr.map(param => {
    if (paramlist[param].asset === asset._key) {
      deviceList.push(paramlist[param]);
    }
  });
  return deviceList;
}

export function prepareOtionsforEdit(assetlist, relationData) {
  let assetArr = Object.keys(assetlist);
  let dropdownArray = [];

  relationData && Array.isArray(relationData) && relationData.map((selectedOption, i) => {
    if (i === 0) {
      assetArr.map(assetId => {
        if (assetlist[assetId]) {
          if (assetlist[assetId].name === selectedOption) {
            let tempObj = {};
            tempObj.value = assetlist[assetId].name;
            tempObj.label = assetlist[assetId].name;
            tempObj.key = assetlist[assetId]._key;
            tempObj.isLeaf = false;
            dropdownArray.push(tempObj);
          }
          if (assetlist[assetId].name) {
            let tempObj = {};
            tempObj.value = assetlist[assetId].name;
            tempObj.label = assetlist[assetId].name;
            tempObj.key = assetlist[assetId]._key;
            tempObj.isLeaf = false;
            dropdownArray.push(tempObj);
          }
        }
      });
    } else if (selectedOption !== 'device' && i > 0) {
     
      let indexParent = assetArr.map(asset => {
          return assetlist[asset].name;
        })
        .indexOf(selectedOption);

      let data = [];
      assetArr.map(assetId => {
        if (assetlist[assetId]) {
          if (
            assetlist && assetlist[assetId].parent === assetlist && assetlist[assetArr[indexParent]].parent
          ) {
            let tempObj = {};
            tempObj.value = assetlist[assetId].name;
            tempObj.label = assetlist[assetId].name;
            tempObj.key = assetlist[assetId]._key;
            tempObj.isLeaf = false;
            data.push(tempObj);
          }
        }
      });
      if (i === 1) {       
        dropdownArray[0]['children'] = data;
      } else if (i === 2) {
        dropdownArray[0]['children'][0]['children'] = data;
      }
    } else {
      let device = {
        value: 'device',
        label: 'Device',
      };
      if (i === 1) {
       
        dropdownArray[0]['children'] = [device];
      }else if (i === 2) {
        dropdownArray[0]['children'][0]['children'] = [device];
      }
    }
  });
  return dropdownArray;
}

export default dropDownAdapter;
