import React from "react";
import cloneDeep from "lodash.clonedeep";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import moment from "moment";
import { Tag } from "antd";

import ReactEcharts from "../Echarts/Echarts";
import { trendLabelTranslationNew, predictionsLegendsTranslation } from "../../Utils/Adapter/translationAdapter";
import {
  getChartDataWithoutSelection,
  getChartDataWithSelection,
} from "../ChartCalls";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { pieMockJson } from "../../Mock/MockJson";
import { defaultRefreshTime } from '../constant.json'
import { Fragment } from "react";

class Pie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      fromedit: this.props.graphprops.fromedit,
      data: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection
    ) {
      this.getData(this.props, this.props.selectedThemeing);
    }
  }

  componentWillUnmount() {
    this.abortController.abort()
    clearInterval(this.timeTicket);
  }

  getData = async (props, theme) => {
    this.requestCheck = false
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = pieMockJson;
      this.requestCheck = true
    } else {
      if (
        (graphprops &&
          graphprops.dashboardType === "dynamic" &&
          this.props.deviceId) ||
        (graphprops &&
          graphprops.dateExists === "true" &&
          this.props.selectionDate) ||
        (graphprops &&
          graphprops.monthExists === "true" &&
          this.props.selectionMonth) ||
        (graphprops &&
          graphprops.weekExists === "true" &&
          this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
        (graphprops &&
          graphprops.dateRangeExists === "true" &&
          this.props.selectionDateRange) ||

        (graphprops && graphprops.dayMonthYearExists === 'true' && this.props.dayMonthYear)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection
        );
        this.requestCheck = true
      } else {
        json = await getChartDataWithoutSelection(graphprops, this.abortController, this.props.dropdownSelection);
        this.requestCheck = true
      }
    }
    //mock data    
    // json = [
    //   { tagValue: 1548, tagName: "HAccPkYY" },
    //   { tagValue: 535, tagName: "HAccPkXP" },
    //   { tagValue: 510, tagName: "HAccPkYP" },
    //   { tagValue: 634, tagName: "HAccPkZP" },
    //   { tagValue: 735, tagName: "HAccPkYZ" }
    // ]
    if ((json && json.message) || (Array.isArray(json) && (json.length === 0) &&
      (graphprops.enableMock !== true))) {

      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
      }

    } else {

      if (json && Array.isArray(json)) {
        let option = cloneDeep(this.state.option);
        let legend = {
          left: "center",
          textStyle: {
            color: theme === "lightTheme" ? "black" : "white",
            fontSize: 18,
            fontWeight: "bold",
          },
          data: json.map((data) => {
            return data.tagName;
          }),
        };

        let series = await trendLabelTranslationNew(
          json,
          this.props.translationCache,
          this.props.language
        );
        let legends = await predictionsLegendsTranslation(
          legend,
          this.props.translationCache,
          this.props.language
        );

        option.series[0].data = series;
        option.legend = legends;
        this.setState({
          option,
          refreshDateTime: moment()
        });
      }
    };
    this.setState({
      errorInAPI,
      noDataInDB
    })
  }


  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <ReactEcharts
              option={this.state.option}
              style={{ height: "90%", width: "100%" }}
              className="react_for_echarts"
            />
        }
      </Fragment>

    );
  }
  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      title: {
        left: "center",
      },
      tooltip: {
        trigger: "item",
        formatter: "{b} : {c} ({d}%)",
      },
      label: {
        color: theme === "lightTheme" ? "black" : "white",
        fontWeight: "bold",
        fontSize: 14,
      },
      textStyle: {
        color: theme === "lightTheme" ? "black" : "white",
        fontWeight: "bold",
        fontSize: 18,

      },
      legend: {
        left: "center",
        textStyle: {
          color: theme === "lightTheme" ? "black" : "white",
        },
        data: [],
      },
      toolbox: {
        showTitle: false,
        orient: "vertical",
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: "download",
            backgroundColor: "black",
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: "#8c8c8c",
              borderWidth: 2,
            },
          },
        },
      },
      series: [
        {
          type: 'pie',
          radius: '70%',
          avoidLabelOverlap: false,
          // center: ["50%", "50%"],
          selectedMode: "single",
          label: {
            // formatter: ' {b|{b}：}{c}  {per|{d}%}  ',
            formatter: " {b|{c}}  {per|{d}%}  ",

            color: theme === "lightTheme" ? "black" : "white",
            backgroundColor: "#eee",
            borderColor: "#aaa",
            borderWidth: 1,
            borderRadius: 4,
            // shadowBlur:3,
            // shadowOffsetX: 2,
            //     shadowOffsetY: 2,
            //     shadowColor: '#999',
            padding: [0, 4],
            rich: {
              b: {
                fontSize: 14,
                fontWeight: "bold",
                color: theme === "lightTheme" ? "black" : "white",
                lineHeight: 22,
                backgroundColor: "#c23531",
                padding: [3, 5],
                borderRadius: 2,
              },
              per: {
                fontSize: 14,
                fontWeight: "bold",
                color: theme === "lightTheme" ? "black" : "white",
                backgroundColor: "#52c41a",
                padding: [3, 5],
                borderRadius: 2,
              },
            },
          },
          data: [],

          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],

    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(Pie);
