import React from "react";
import { Route } from "react-router";

import {
  ConfigurationUpload,
  HierarchyUpload
} from "../pages";

export default class ApplicationSettingsRouter extends React.Component {
  render() {
    return (
      <div>
        <Route path="/rubus/configurationSettings/configurationUpload" component={ConfigurationUpload} />
        <Route path="/rubus/configurationSettings/hierarchyUpload" component={HierarchyUpload} />
      </div>
    );
  }
}
