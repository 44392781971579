export function panelTranslation(dashboardPanel, translationCache, language) {
  if (dashboardPanel.length > 0 && translationCache !== undefined) {
    if (dashboardPanel[0].json.length > 0) {
      dashboardPanel[0].json.map((panel, key) => {
        if (translationCache[panel.title] !== undefined) {
          if (translationCache[panel.title]) {
            dashboardPanel[0].json[key].title = translationCache[panel.title]
          }
        }
      })
    }
  }
  return dashboardPanel
}


export function menuTranslationAdapter(menuHierarchy, translationCache) {
  if (menuHierarchy !== undefined && translationCache !== undefined) {
    menuHierarchy.map((menuItem, key) => {
      if (translationCache[menuItem.name] !== undefined) {
        if (translationCache[menuItem.name]) {
          menuHierarchy[key].name = translationCache[menuItem.name]
        }
      }
    })
  }
  return menuHierarchy
}

export function tableTranslation(columnName, translationCache) {
  if (columnName.length > 0 && columnName !== undefined && translationCache !== undefined) {
    columnName.map((column, key) => {
      if (translationCache[column.title] !== undefined) {
        if (translationCache[column.title]) {
          columnName[key].title = translationCache[column.title]
        }
      }
    })
  }

  return columnName
}

export function trendLabelTranslation(seriesArray, translationCache, language) {
  if (seriesArray.length > 0) {
    seriesArray.map((series, key) => {
      if (translationCache !== undefined) {
        if (translationCache[series.name] !== undefined) {
          if (translationCache[series.name]) {
            seriesArray[key].name = translationCache[series.name]
          }
        }
      }
    })
  }
  return seriesArray
}

export function trendLabelTranslationNew(seriesArray, translationCache, language) {
  if (seriesArray.length > 0) {
    seriesArray.map((series, key) => {
      if (translationCache !== undefined) {
        if (translationCache[series.tagName] !== undefined) {
          if (translationCache[series.tagName]) {
            seriesArray[key].name = translationCache[series.tagName]
            seriesArray[key].value = series.tagValue
          }else{
            seriesArray[key].name = series.tagName
            seriesArray[key].value = series.tagValue
          }
        }else{
          seriesArray[key].name = series.tagName
          seriesArray[key].value = series.tagValue
        }
      }else{
        seriesArray[key].name = series.tagName
        seriesArray[key].value = series.tagValue
      }
    })
  }
  return seriesArray
}

export function columnsAdapter(columnsArray, translationCache, language) {
  if (columnsArray.length > 0) {
    columnsArray.map((column, key) => {
      if (translationCache !== undefined) {
        if (translationCache[column.title] !== undefined) {
          if (translationCache[column.title]) {
            columnsArray[key].title = translationCache[column.title]
          }
        }
      }
    })
  }
  return columnsArray
}

export function treeDataTranslation(seriesArray, translationCache, language) {
  let finalObject = []
  let finalArray = {}
  if (seriesArray.length > 0) {
    seriesArray.map((series, key) => {
      if (series.children) {
        if (translationCache && translationCache[series.name] !== undefined && translationCache[series.name]) {
          finalArray["name"] = translationCache[series.name]
        } else {
          finalArray["name"] = series.name
        }
        let childrenn = getChildrenTranslation(series.children, translationCache, language)
        finalArray['children'] = childrenn
        finalObject.push(finalArray)
      } else {

        if (translationCache !== undefined) {
          if (translationCache[series.name] !== undefined) {
            if (translationCache[series.name]) {
              finalArray.name = translationCache[series.name]
            }

          } else {
            finalArray.name = series.name
          }
          finalObject.push(finalArray)
        }

      }
    })
  }
  return finalObject
}


function getChildrenTranslation(treeData, translationCache, language) {
  let objectArray = []

  if (treeData && Array.isArray(treeData)) {
    treeData.map((childrenArray) => {
      let newObj = {}
      if (childrenArray && childrenArray.children) {

        childrenArray.children.map((child) => {
          newObj['children'] = getChildrenTranslation(childrenArray.children, translationCache, language)
        })

        if (translationCache && translationCache[childrenArray.name] !== undefined && translationCache[childrenArray.name]) {
          newObj["name"] = translationCache[childrenArray.name]
        } else {
          newObj["name"] = childrenArray.name
        }
        objectArray.push(newObj)

      } else {
        let newObj = {}
        if (translationCache && childrenArray && translationCache[childrenArray.name] !== undefined && translationCache[childrenArray.name]) {
          newObj["name"] = translationCache[childrenArray.name]
        } else if (childrenArray) {
          newObj["name"] = childrenArray.name
        }

        objectArray.push(newObj)
      }
    })

    return objectArray
  } else {
    return treeData
  }
}

export function predictionsLegendsTranslation(seriesArray, translationCache, language) {
  if (seriesArray && seriesArray.data) {
    seriesArray.data.map((series, key) => {
      if (translationCache !== undefined) {
        if (translationCache[series] !== undefined) {
          if (translationCache[series]) {
            seriesArray.data[key] = translationCache[series]
          }
        }
      }
    })
  }
  return seriesArray
}

export function candleStickTranslation(labelName, translationCache, language) {
  if (labelName && translationCache && translationCache[labelName]) {
    if (translationCache[labelName]) {
      return translationCache[labelName]
    }

  }
  return labelName
}
