import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { Form, Icon, Input, message, Anchor, Tooltip, Avatar } from 'antd'

import localStorage from '../../../utils/localStorage'
import maximo from '../../../images/rubus_new/maximo.png'
import { HeaderLogo, Welcome, Add, LoginButton, RedirectLink } from './styles'
import rubusLogo from '../../../images/rubus_new/horizontal_logo.png'
import config from '../../../constants/config.json'

import './Login.css'

const FormItem = Form.Item
class LoginComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userToken: props.userToken || ''
    }
  }

  componentWillReceiveProps({ userToken }) {
    this.setState({ userToken })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        localStorage.set('loggedIn', true)
        this.props.actions.userLoginAuth(values)
      } else {
        message.error('Please enter valid username and password')
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { signUp, reset } = config
    // const {userToken} = this.state
    const userToken = localStorage.get('accessToken')
    const menu = 'Equipment Overview'
    this.props.dispatch({ type: '@USER/CURRENTMENU', menu })


    if (userToken !== 'undefined' && userToken !== 'null' && userToken !== '' && userToken !== undefined && userToken !== null) {
      return <Redirect to="/rubus/dashboard/PlantOverview" />
    }

    return (
      <section style={{ width: '100%' }}>
        <HeaderLogo><img src={rubusLogo} alt="logo" style={{ height: '42px' }} /></HeaderLogo>

        <Welcome style={{ fontSize: '17px', color: '#444343' }}>Welcome back, sign in with your account</Welcome>

        <Form onSubmit={this.handleSubmit} className="form-v1" style={{ textAlign: 'center' }}>
          <FormItem>
            {getFieldDecorator('identifier', {
              rules: [{ required: true, message: 'Please input your username/email!' }]
            })(
              <Input size='medium' style={{ width: '380px' }} prefix={<Icon type='team' />} placeholder='Username/Email' />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please input your Password!' }]
            })(
              <Input size="medium" style={{ width: '380px' }} prefix={<Icon type="lock" />} type="password" placeholder="Password" />
            )}
          </FormItem>
          <FormItem>
            <LoginButton style={{ backgroundColor: '#0066b8', borderColor: '#0066b8', width: '380px' }} type="primary" htmlType="submit" >Log in</LoginButton>
          </FormItem>
        </Form>
        <RedirectLink>
          <Tooltip title="Redirect to Maximo" placement="top">
            <a styehref="https://bpt-maximo.bmecomosolution.com/maximo/webclient/login/login.jsp" target="_blank" >
              <Avatar shape="square" size={50} src={maximo}>
              </Avatar></a></Tooltip>

          <span class="img_link">
            <a style={{ color: "#015599", fontWeight: "500" }} href="https://bpt-maximo.bmecomosolution.com/maximo/webclient/login/login.jsp" target="_blank" >設備保全管理システム</a>
          </span>
        </RedirectLink>
        {signUp ? <Add>Don't have an account yet?  <Link to={{ pathname: `/signup` }}>Sign up</Link></Add> : ''}
        {reset ? <Add>Forgot your password? <Link to={{ pathname: `/forget-password` }}>Reset password</Link></Add> : ''}
      </section>
    )
  }
}

const LoginComponentForm = Form.create()(LoginComponent)
export default connect()(LoginComponentForm)
