import React, { Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Tabs } from 'antd'

import AssetComponent from './AssetComponent'
import DeviceHierarchy from './DeviceHierarchy'
import AssetDeviceBreadcrumbs from './AssetDeviceBreadcrumbs'
import history from '../../../commons/history'
import hierarchy from './hierarchy.json'
import { getCurrentAsset } from '../../../selectors/asset'

import { StyledApplication, HomeSubMenu } from './style'
const { TabPane } = Tabs

class AssetDeviceComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hierarchy: hierarchy || {},
            nextAssetTypeList: [],
            parentAssetId: ''
        }
    }


    componentDidMount() {
        let { match } = this.props
        this.props.actions.getConditionList();
        this.generateObject(match.params.location)
    }

    componentDidUpdate = (prevprops) => {
        let { currentAsset, match } = this.props
        if ((prevprops.match.params.location !== match.params.location) || (currentAsset !== prevprops.currentAsset)) {
            this.generateObject(match.params.location)
        }
    }

    // _getHierarchyJSON = async () => {
    //     let hierarchy = await _getHierarchyJSONAPI()
    //     this.setState({ hierarchy })
    // }

    generateObject = async (assetCode) => {
        await this._prepareView(assetCode)
    }

    _prepareView = () => {
        let { hierarchy } = this.state
        let { currentAsset } = this.props

        let parentAssetType = null
        let parentAssetId = null
        if (history.location.pathname !== "/rubus/assetConfiguration") {
            parentAssetId = currentAsset && currentAsset.Id ? currentAsset.Id : null
            parentAssetType = currentAsset && currentAsset.AssetType ? currentAsset.AssetType : null
        }
        let currentAssetTypeList = []
        let nextAssetTypeList = hierarchy && Object.keys(hierarchy.asset).filter((assetTypeKeys) => {
            currentAssetTypeList = hierarchy.asset[assetTypeKeys].parent
            if (typeof currentAssetTypeList !== "string" && currentAssetTypeList !== null) {
                return currentAssetTypeList.includes(parentAssetType)
            } else {
                return currentAssetTypeList === parentAssetType
            }
        })
        if (hierarchy && hierarchy.device && parentAssetType !== null) {
            let parentDeviceObject = Object.keys(hierarchy.device).filter((deviceHierarchy) => {
                return hierarchy.device[deviceHierarchy].asset === true
            })
            if (parentDeviceObject &&
                parentDeviceObject[0] &&
                hierarchy.device[parentDeviceObject[0]].parent &&
                hierarchy.device[parentDeviceObject[0]].parent.includes(parentAssetType)) {
                nextAssetTypeList.push("device")
            }
        }
        this.setState({
            nextAssetTypeList,
            parentAssetId
        })
    }

    changeTab = activeKey => {
        if (activeKey !== "device") {
            this.props.actions.setDeviceBreadcrumbs([])
            this.props.actions.currentDevice({})

        }
    };
    render() {
        let { nextAssetTypeList, parentAssetId } = this.state
        let { history, currentAsset } = this.props

        return (
            <Fragment>
                <AssetDeviceBreadcrumbs history={history} />
                <StyledApplication style={{ minHeight: window.innerHeight - 226 }}>
                    <Tabs tabPosition="top" type="card" style={{ margin: '30px' }} onChange={this.changeTab}>
                        {
                            nextAssetTypeList && nextAssetTypeList.map((AssetType) => {
                                return (
                                    <TabPane tab={
                                        <HomeSubMenu>
                                            {AssetType ?
                                                AssetType === "device" ? "Equipment" : _.startCase(_.toLower(AssetType))
                                                : _.startCase(_.toLower(''))}
                                        </HomeSubMenu>
                                    } key={AssetType}
                                    >
                                        <div>
                                            {
                                                AssetType !== 'device'
                                                    ? <AssetComponent
                                                        data={{
                                                            parentAssetId,
                                                            AssetType
                                                        }}
                                                    />
                                                    : <DeviceHierarchy
                                                        data={{
                                                            ...currentAsset,
                                                            parentAssetId
                                                        }}
                                                    />
                                            }
                                        </div>
                                    </TabPane>
                                )
                            })
                        }
                    </Tabs>
                </StyledApplication >
            </Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    currentAsset: getCurrentAsset()
})

export default connect(mapStateToProps)(AssetDeviceComponent)
