import { types } from './types'
import { takeLatest, call, put } from 'redux-saga/effects'

import { getConditionListSuccess } from './condition.duck'
import condition from './condition.requests'

export function * listConditionSaga () {
  const conditionSagaSuccess = yield call(condition.getList)
  yield put(getConditionListSuccess(conditionSagaSuccess))
}


export default function * conditionSaga () {
  yield [
    takeLatest(types.GET_CONDITION_LIST, listConditionSaga)
  
  ]
}
