import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import AssetDeviceBreadcrumbs from './AssetDeviceBreadcrumbs'
import { breadcrumbList, currentAsset } from '../../../../modules/asset/asset.duck'
import { currentDevice } from '../../../../modules/device/device.duck'
import { getAllNewBreadcrumbList } from '../../../../selectors/asset'

const mapStateToProps = createStructuredSelector({
  breadcrumbList: getAllNewBreadcrumbList()
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        breadcrumbList,
        currentAsset,
        currentDevice
      },
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetDeviceBreadcrumbs)
