import React, { Fragment } from "react";
import {
  Input,
  Select,
  Popover,
  Col,
  Row,
  Icon
} from "antd";
import { TimePicker } from "antd4";
import {
  Boxdiv,
  StyledAppdiv,
  RuleTypeSelectionDiv,
  BlockedLabelBlack,
  ConditionSelect,
  ActionSelectionDiv,
  AddParagraph,
  LogicAddButton,
  BlankDiv,
  DeviceSelect,
  TagNameSelect,
  OperaterSelect,
  ButtonBoxDiv,
  MarginRightButton,
  OnchangeCheckbox,
  ActionCheckbox,
  TextParagraph,
  BlockedParaBlack,
} from "../style";
import { actionList } from "../utils/const";
import moment from "moment";
import constants from "../constants";

import "antd4/lib/date-picker/style/css"

const { Option } = Select;
const { TextArea } = Input;

// export default function DeviceRuleView(props) {
export default class DeviceTypeRUle extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     ...(this.props)
  //   }
  // }

  // componentDidUpdate(prevprops) {
  //   if (prevprops.config !== this.props.config) {
  //     this.state = {
  //       ...(this.props)
  //     }
  //   }
  // }

  render() {
    const { translationCache, language, config, Methods } = this.props;
    const {
      inputList,
      conif,
      conelseif,
      conelse,
      elsifLength,
      elseList,
      ruleType,
      mailRecipients,
      rulebody,
      url,
      deviceTypeList,
      recipientList,
      batchPriority,
      onChange,
      previsibleType,
      rootTypeCause,
      recommandationTypeStatus,
      elseprevisible,
      elseroot,
      elserecommandation,
      parametersList,
    } = config;
    const {
      handledeviceSelect,
      handleelseif,
      handleelse,
      handleDelay,
      checkonChange,
      handleTagSelect,
      handleInputChange,
      handleAddClick,
      handleRuleType,
      handleRecipientList,
      handleRulebody,
      handleurl,
      handleRadioButton,
      handleRemoveClick,
      handleVisibleChange,
      hanblePopoverOnclick,
      handleTextAreaChange,
      handleConditionchange,
    } = Methods;
    
    let translationAction;
    if (language !== undefined && language !== "" && translationCache) {
      if (language === "English") {
        translationAction = actionList;
      } else {
        translationAction = actionList.map((action) => {
          let data =
            translationCache[action] !== undefined
              ? translationCache[action]
              : action;
          return data;
        });
      }
    }
    return (
      <StyledAppdiv>
        <RuleTypeSelectionDiv>
          <div style={{ marginRight: "12%",marginLeft:"10px" }}>
            <BlockedLabelBlack>
              {translationCache &&
                translationCache[`${constants.type}`] &&
                translationCache[`${constants.type}`]
                ? translationCache[`${constants.type}`]
                : `${constants.type}`}
            </BlockedLabelBlack>
            <Select
              placeholder="RuleType"
              style={{ width: "120px", display: "inline-block" }}
              value={ruleType}
              onChange={(value) => handleRuleType(value, "ruleType")}
            >
              <Option
                value="realTime"
                title="Rules related to Single Device Can be Created"
              >
                Real Time
              </Option>
              <Option
                value="batch"
                title="Rules Related to multiple Devices Can be Created"
              >
                Batch
              </Option>
              <Option
                value="hybrid"
                title="First Condition in rule is RealTime And Remaining Conditions are Batch"
              >
                Hybrid
              </Option>
            </Select>
          </div>
          {ruleType === "batch" ? (
            <div style={{ marginRight: "12%" }}>
              <label style={{ display: "block" }}>
                {translationCache &&
                  translationCache[`${constants.priority}`] &&
                  translationCache[`${constants.priority}`]
                  ? translationCache[`${constants.priority}`]
                  : `${constants.priority}`}
              </label>
              <Select
                placeholder={
                  translationCache &&
                    translationCache[`${constants.priority}`] &&
                    translationCache[`${constants.priority}`]
                    ? translationCache[`${constants.priority}`]
                    : `${constants.priority}`
                }
                style={{ width: "120px", display: "inline-block" }}
                value={batchPriority === "" ? undefined : batchPriority}
                onChange={(value) => handleRuleType(value, "batchPriority")}
              >
                {config.priorityTypes.map((type) => (
                  <Option value={type.PriorityType}>{type.PriorityType}</Option>
                ))}
              </Select>
            </div>
          ) : null}
          {/* <div style={{ marginRight: "12%", width: "350px" }}> 
          <BlockedLabelBlack>
            {translationCache &&
            translationCache[`${constants.replicate}`] &&
            translationCache[`${constants.replicate}`]
              ? translationCache[`${constants.replicate}`]
              : `${constants.replicate}`}
          </BlockedLabelBlack>
          <PickyStyling
            id="replicate"
            numberDisplayed="2"
            options={deviceTypeList}
            labelKey="Name"
            valueKey="Id"
            multiple={true}
            value={replicateDevices}
            includeFilter
            onChange={handlePickyChange}
          />
        </div> */}
          <OnchangeCheckbox
            checked={onChange}
            onChange={(value) => handleRadioButton(value)}
          >
            {translationCache &&
              translationCache[`${constants.onchange}`] &&
              translationCache[`${constants.onchange}`]
              ? translationCache[`${constants.onchange}`]
              : `${constants.onchange}`}
          </OnchangeCheckbox>
        </RuleTypeSelectionDiv>
        {conif
          ? inputList.map((x, i) => {
            return (
              <Boxdiv key={`if${i}`}>
                {i === 0 ? (
                  <div style={{ display: "block" }}>
                   {/* // <div style={{ width: "2%", float: "left" }}> */}
                      <BlockedParaBlack>{`if`}</BlockedParaBlack>
                   {/* // </div> */}
                    {/* <div
                      style={{
                        width: "78%",
                        float: "right",
                        marginRight: "20%",
                      }}
                    >
                      <StyledSpan>
                        {translationCache &&
                          translationCache[`${constants.equipmentType}`] &&
                          translationCache[`${constants.equipmentType}`]
                          ? translationCache[`${constants.equipmentType}`]
                          : `${constants.equipmentType}`}
                      </StyledSpan>
                      <StyledSpan style={{ marginLeft: "12%" }}>
                        {translationCache &&
                          translationCache[
                          `${constants.equipmentTagIDandname}`
                          ] &&
                          translationCache[`${constants.equipmentTagIDandname}`]
                          ? translationCache[
                          `${constants.equipmentTagIDandname}`
                          ]
                          : `${constants.equipmentTagIDandname}`}
                      </StyledSpan>
                      <StyledSpan style={{ marginLeft: "6%" }}>
                        {translationCache &&
                          translationCache[`${constants.conditions}`] &&
                          translationCache[`${constants.conditions}`]
                          ? `${translationCache[`${constants.conditions}`]
                          }`
                          : `${constants.conditions}`}
                      </StyledSpan>

                      <StyledSpan style={{ marginLeft: "6%" }}>
                        {translationCache &&
                          translationCache[`${constants.value}`] &&
                          translationCache[`${constants.value}`]
                          ? `     ${translationCache[`${constants.value}`]
                          }`
                          : `${constants.value}`}
                      </StyledSpan>
                    </div> */}
                  </div>
                ) : null}
                {i > 0 ? (
                  <ConditionSelect
                    name="operater"
              
                    placeholder={
                      translationCache &&
                        translationCache[`${constants.selectCondition}`] &&
                        translationCache[`${constants.selectCondition}`]
                        ? translationCache[`${constants.selectCondition}`][
                        language
                        ]
                        : `${constants.selectCondition}`
                    }
                    size="default"
                    value={x["54765"] === "" ? undefined : x["54765"]}
                    onChange={(e) => handleConditionchange(e, i, "inputList")}
                  >
                    <Option value="&&">AND</Option>
                    <Option value="||">OR</Option>
                  </ConditionSelect>
                ) : null}
                <DeviceSelect
                  placeholder={
                    translationCache &&
                      translationCache[`${constants.device}`] &&
                      translationCache[`${constants.device}`]
                      ? translationCache[`${constants.device}`]
                      : `${constants.device}`
                  }
                  size="default"
                  disabled={x["54765"] === undefined ? false : true}
                  value={x.device === "" ? undefined : x.device}
                  onChange={(value) =>
                    handledeviceSelect(value, i, "inputList")
                  }
                >
                  {deviceTypeList  && Array.isArray(deviceTypeList)&& deviceTypeList.map((device, i) => {
                    return (
                      <Option key={`ifdeviceList${i}`} value={device.Id}>
                        {device.Name}
                      </Option>
                    );
                  })}
                </DeviceSelect>
                <TagNameSelect
                  showSearch
                  name="tagName"
                  size="default"
                  placeholder={
                    translationCache &&
                      translationCache[`${constants.selectparameters}`] &&
                      translationCache[`${constants.selectparameters}`]
                      ? translationCache[`${constants.selectparameters}`][
                      language
                      ]
                      : `${constants.selectparameters}`
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  value={x.tagName === "" ? undefined : x.tagName}
                  onChange={(e) => handleTagSelect(e, i, "inputList")}
                >
                  {parametersList
                    // .filter((param) => param.DeviceId === x.device)
                    .map((param, j) => {
                      return (
                        <Option
                          key={`inputTagname${i}${j}`}
                          value={param.ParameterCode}
                        >
                          {param.ParameterCode}
                          {/* {`${param.TagId}:${param.ParameterDescription}`} */}
                        </Option>
                      );
                    })}
                </TagNameSelect>
                <OperaterSelect
                  name="operater"
                  size="default"
                  placeholder={
                    translationCache &&
                      translationCache[`${constants.selectoperator}`] &&
                      translationCache[`${constants.selectoperator}`]
                      ? translationCache[`${constants.selectoperator}`][
                      language
                      ]
                      : `${constants.selectoperator}`
                  }
                  value={x.operater === "" ? undefined : x.operater}
                  onChange={(e) =>
                    handleInputChange(e, i, "select", "inputList")
                  }
                >
                  <Option value="==" title="EqualTo">
                    ==
                  </Option>
                  <Option value="!=" title="Not EqualTo">
                    !=
                  </Option>
                  <Option value=">" title="Greater Than">
                    {">"}
                  </Option>
                  <Option value="<" title="Less Than">
                    {"<"}
                  </Option>
                  <Option value=">=" title="Greater Than Or EqualTo">
                    {">="}
                  </Option>
                  <Option value="<=" title="Less Than Or Equal To">
                    {"<="}
                  </Option>
                </OperaterSelect>
                {/* <ValueInput
                  size="default"
                  name="value"
                  maxLength={6}
                  placeholder={
                    translationCache &&
                      translationCache[`${constants.value}`] &&
                      translationCache[`${constants.value}`]
                      ? translationCache[`${constants.value}`]
                      : `${constants.value}`
                  }
                  value={x.value}
                  onChange={(e) =>
                    handleInputChange(e, i, "input", "inputList")
                  }
                /> */}
                <ButtonBoxDiv>
                  {inputList.length !== 1 && (
                    <MarginRightButton
                      size="default"
                      onClick={() => handleRemoveClick(i, "inputList")}
                    >
                      {translationCache &&
                        translationCache[`${constants.remove}`] &&
                        translationCache[`${constants.remove}`]
                        ? translationCache[`${constants.remove}`]
                        : `${constants.remove}`}
                    </MarginRightButton>
                  )}
                  {inputList.length - 1 === i && (
                    <Fragment>
                      <LogicAddButton
                        type={"primary"}
                        size="default"
                       
                        onClick={() => handleAddClick("inputList")}
                      >
                        {translationCache &&
                          translationCache[`${constants.add}`] &&
                          translationCache[`${constants.add}`]
                          ? translationCache[`${constants.add}`]
                          : `${constants.add}`}
                      </LogicAddButton>
                      <Popover
                        content={
                          <div>
                            <TextArea
                              value={inputList[i] && inputList[i]["priventive"] ? inputList[i]["priventive"] : ""} 
                              rows={5}
                              maxLength="150"
                              onChange={(e) =>
                                handleTextAreaChange(
                                  e,
                                  i,
                                  "inputList",
                                  "priventive"
                                )
                              }
                            />
                             <span
                            style={{ color: "#1890ff", cursor: "pointer" }}
                              onClick={(e) => {
                                hanblePopoverOnclick(
                                  i,
                                  "inputList",
                                  "close",
                                  "priventive",
                                  "previsibleType"
                                );
                              }}
                            >
                              Cancel
                            </span>
                            <span
                            style={{ color: "#1890ff", cursor: "pointer" , float: "right" }}
                              onClick={(e) => {
                                hanblePopoverOnclick(
                                  i,
                                  "inputList",
                                  "ok",
                                  "previsibleType"
                                );
                              }}
                            >
                              Ok
                            </span>
                          </div>
                        }
                        visible={previsibleType}
                        onVisibleChange={(value) =>
                          handleVisibleChange(value, "previsibleType")
                        }
                        title={
                          translationCache &&
                            translationCache[`${constants.preventivemeasure}`] &&
                            translationCache[`${constants.preventivemeasure}`][
                            language
                            ]
                            ? translationCache[
                            `${constants.preventivemeasure}`
                            ]
                            : `${constants.preventivemeasure}`
                        }
                        trigger="click"
                      >
                        <TextParagraph>
                          {translationCache &&
                            translationCache[`${constants.preventivemeasure}`] &&
                            translationCache[`${constants.preventivemeasure}`][
                            language
                            ]
                            ? translationCache[
                            `${constants.preventivemeasure}`
                            ]
                            : `${constants.preventivemeasure}`}
                        </TextParagraph>
                        {
                          inputList[i]["priventive"] && inputList[i]["priventive"].length > 0 ?
                            <span style={{ color: "#60c40e" }}><Icon type="check-circle" /></span>
                            : null
                        }
                      </Popover>

                      {/* <Divider style={{ background: "black" }} type="vertical" /> */}
                      <Popover
                        content={
                          <div>
                            <TextArea
                              value={inputList[i]["rootcause"]}
                              rows={5}
                              maxLength="150"
                              onChange={(e) => handleTextAreaChange( e, i, "inputList", "rootcause" ) }
                            />
                             <span
                            style={{ color: "#1890ff", cursor: "pointer" }}onClick={(e) => { hanblePopoverOnclick( i, "inputList", "close", "rootcause", "rootTypeCause" ); }} > Cancel </span>
                             <span
                            style={{ color: "#1890ff", cursor: "pointer", float: "right" }} onClick={(e) => { hanblePopoverOnclick( i, "inputList", "ok", "rootTypeCause" ); }} > Ok </span>
                          </div>
                        }
                        visible={rootTypeCause}
                        onVisibleChange={(value) =>
                          handleVisibleChange(value, "rootTypeCause")
                        }
                        title={ translationCache && translationCache[`${constants.rootcauseanalysis}`] && translationCache[`${constants.rootcauseanalysis}`][ language ] ? translationCache[ `${constants.rootcauseanalysis}` ] : `${constants.rootcauseanalysis}` }
                        trigger="click"
                      >
                        <TextParagraph>
                          {translationCache &&
                            translationCache[`${constants.rootcauseanalysis}`] &&
                            translationCache[`${constants.rootcauseanalysis}`][
                            language
                            ]
                            ? translationCache[
                            `${constants.rootcauseanalysis}`
                            ]
                            : `${constants.rootcauseanalysis}`}
                        </TextParagraph>
                        {
                          inputList[i]["rootcause"] && inputList[i]["rootcause"].length > 0 ?
                            <span style={{ color: "#60c40e" }}><Icon type="check-circle" /></span>
                            : null
                        }
                      </Popover>
                      {/* <Divider style={{ background: "black" }} type="vertical" /> */}
                      <Popover
                        content={
                          <div>
                            <TextArea
                              value={inputList[i]["recommandation"]}
                              rows={5}
                              maxLength="150"
                              onChange={(e) => handleTextAreaChange( e, i, "inputList", "recommandation" ) }
                            />
                             <span
                            style={{ color: "#1890ff", cursor: "pointer" }} onClick={(e) => { hanblePopoverOnclick( i, "inputList", "close", "recommandation", "recommandationTypeStatus" ); }} > Cancel </span>
                            <span
                            style={{ color: "#1890ff", cursor: "pointer", float:"right" }} onClick={(e) => { hanblePopoverOnclick( i, "inputList", "ok", "recommandationTypeStatus" ); }} > Ok </span>
                          </div>
                        }
                        visible={recommandationTypeStatus}
                        onVisibleChange={(value) =>
                          handleVisibleChange(value, "recommandationTypeStatus")
                        }
                        title={ translationCache && translationCache[`${constants.recomandations}`] && translationCache[`${constants.recomandations}`][ language ] ? translationCache[ `${constants.recomandations}` ] : `${constants.recomandations}` }
                        trigger="click"
                      >
                        <TextParagraph>
                          {translationCache &&
                            translationCache[`${constants.recomandations}`] &&
                            translationCache[`${constants.recomandations}`][
                            language
                            ]
                            ? translationCache[
                            `${constants.recomandations}`
                            ]
                            : `${constants.recomandations}`}
                             {
                          inputList[i]["recommandation"] && inputList[i]["recommandation"].length > 0 ?
                            <span style={{ color: "#60c40e" }}><Icon type="check-circle" /></span>
                            : null
                        }
                        </TextParagraph>
                      </Popover>
                    </Fragment>
                  )}
                </ButtonBoxDiv>
                {i === inputList.length - 1 ? (
                  <ActionSelectionDiv>
                    <BlockedLabelBlack>
                      {translationCache &&
                        translationCache[`${constants.action}`] &&
                        translationCache[`${constants.action}`]
                        ? translationCache[`${constants.action}`]
                        : `${constants.action}`}
                      <span style={{ color: "red" }}>*</span>
                    </BlockedLabelBlack>
                    <ActionCheckbox
                      options={translationAction}
                      value={
                        language === "English"
                          ? config.inputList[i]["action"]
                          : config.inputList[i]["action"] !== undefined
                            ? config.inputList[i]["action"].map(
                              (covert) => {
                                return translationCache[covert] !== undefined
                                  ? translationCache[covert]
                                  : covert;
                              }
                            )
                            : null
                      }
                      onChange={(checkedValues) => {
                        checkonChange(checkedValues, "inputList", i);
                      }}
                    />
                    <TimePicker
                      defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                      value={
                        config.inputList && config.inputList[i] && config.inputList[i]["delay"] === undefined
                          ? moment('00:02:30', 'HH:mm:ss')
                          : moment(
                            config.inputList && config.inputList[i] && config.inputList[i]["delay"][1],
                            "HH:mm:ss"
                          )
                      }
                      onChange={(e) => handleDelay(e, "inputList", i)}
                      placeholder={
                        translationCache &&
                          translationCache[`${constants.selectDelay}`] &&
                          translationCache[`${constants.selectDelay}`]
                          ? translationCache[`${constants.selectDelay}`]
                          : `${constants.selectDelay}`
                      }
                      allowClear={false}
                      size="small"
                    />
                    {
                      config && config.inputList &&
                        config.inputList[config.inputList.length - 1].action.indexOf("RestAPI") > -1 ?
                        <Input
                          style={{ width: "55%", marginTop: "2%" }}
                          placeholder="Enter Url"
                          name="value"
                          value={url}
                          onChange={(e) => handleurl(e, "url")}
                        />
                        : null
                    }

                  </ActionSelectionDiv>
                ) : null}
                {i=== inputList.length -1 ?( <div>
          <Row>
            <Col span={7}>
              <BlockedLabelBlack>
                {translationCache &&
                  translationCache[`${constants.members}`] &&
                  translationCache[`${constants.members}`]
                  ? translationCache[`${constants.members}`]
                  : `${constants.members}`}
              </BlockedLabelBlack>
              <Select
                placeholder="RecipientList"
                mode="multiple"
                value={mailRecipients}
                style={{ width: "100%", display: "inline-block" }}
                onChange={(value) => handleRecipientList(value, "mailRecipients")}
              >
                {recipientList && Array.isArray(recipientList) && recipientList.map((item) => {
                  return (
                    <Option
                      value={item.Email}>
                      {item.FirstName} {item.LastName}

                    </Option>
                  );
                })}
              </Select>
            </Col>
            {/* <Col span={5}>
              <BlockedLabelBlack>
                {translationCache &&
                  translationCache[`${constants.bodymessage}`] &&
                  translationCache[`${constants.bodymessage}`]
                  ? translationCache[`${constants.bodymessage}`]
                  : `${constants.bodymessage}`}
              </BlockedLabelBlack>
              <TextArea
                size="default"
                name="value"
                rows={4}
                value={rulebody}
                placeholder="Text"
                onChange={(e) => handleRulebody(e, "rulebody")}
              />
            </Col> */}
          </Row>

        </div>):null}
              </Boxdiv>

            );
          })
          : null}
       
        {conelseif
          ? elsifLength.map((elsepropName, index) => {
            return config[elsepropName].map((x, i) => {
              return (
                <Boxdiv   key={`${index}${i}`}>
                  {i === 0 ? (
                    <BlockedLabelBlack>else if</BlockedLabelBlack>
                  ) : null}
                  {i > 0 ? (
                    <ConditionSelect
                      size="default"
                      name="operater"
                      value={x["54765"] === "" ? undefined : x["54765"]}
                      placeholder={
                        translationCache &&
                          translationCache[`${constants.selectCondition}`] ?
                          translationCache[`${constants.selectCondition}`]
                          : `${constants.selectCondition}`
                      }
                      onChange={(e) =>
                        handleConditionchange(e, i, elsepropName)
                      }
                    >
                      <Option value="&&">AND</Option>
                      <Option value="||">OR</Option>
                    </ConditionSelect>
                  ) : null}
                  <DeviceSelect
                    placeholder={
                      translationCache &&
                        translationCache[`${constants.device}`] 
                        ? translationCache[`${constants.device}`]
                        : `${constants.device}`
                    }
                    disabled
                    size="default"
                    value={x.device}
                    onChange={(value) =>
                      handledeviceSelect(value, i, elsepropName)
                    }
                  >
                    {deviceTypeList && deviceTypeList.map((device, i) => {
                      return (
                        <Option
                          key={`elseif${index}deviceList${i}`}
                          value={device.Id}
                        >
                          {device.Name}
                        </Option>
                      );
                    })}
                  </DeviceSelect>

                  <TagNameSelect
                    showSearch
                    name="tagName"
                    size="default"
                    placeholder={
                      translationCache &&
                        translationCache[`${constants.selectparameters}`] &&
                        translationCache[`${constants.selectparameters}`][
                        language
                        ]
                        ? translationCache[`${constants.selectparameters}`][
                        language
                        ]
                        : `${constants.selectparameters}`
                    }
                    value={x.tagName === "" ? undefined : x.tagName}
                    onChange={(e) => handleTagSelect(e, i, elsepropName)}
                  >
                    {parametersList
                      //.filter((param) => param.DeviceId === x.device)
                      .map((param, j) => {
                        return (
                          <Option
                            key={`${elsepropName}Tagname${i}${j}`}
                            value={param.ParameterCode}
                          >
                            {param.ParameterCode}
                            {/* {`${param.AliasCode}:${param.ParameterName}`} */}
                          </Option>
                        );
                      })}

                  </TagNameSelect>
                  <OperaterSelect
                    size="default"
                    value={x.operater === "" ? undefined : x.operater}
                    name="operater"
                    placeholder={
                      translationCache &&
                        translationCache[`${constants.selectoperator}`] &&
                        translationCache[`${constants.selectoperator}`]
                        ? translationCache[`${constants.selectoperator}`][
                        language
                        ]
                        : `${constants.selectoperator}`
                    }
                    onChange={(e) =>
                      handleInputChange(e, i, "select", elsepropName)
                    }
                  >
                    <Option value="==" title="EqualTo">
                      ==
                    </Option>
                    <Option value="!=" title="Not EqualTo">
                      !=
                    </Option>
                    <Option value=">" title="Greater Than">
                      {">"}
                    </Option>
                    <Option value="<" title="Less Than">
                      {"<"}
                    </Option>
                    <Option value=">=" title="Greater Than Or EqualTo">
                      {">="}
                    </Option>
                    <Option value="<=" title="Less Than Or Equal To">
                      {"<="}
                    </Option>
                  </OperaterSelect>
                  {/* <ValueInput
                    name="value"
                    size="default"
                    maxLength={6}
                    placeholder={
                      translationCache &&
                        translationCache[`${constants.value}`] &&
                        translationCache[`${constants.value}`]
                        ? translationCache[`${constants.value}`]
                        : `${constants.value}`
                    }
                    value={x.value}
                    onChange={(e) =>
                      handleInputChange(e, i, "input", elsepropName)
                    }
                  /> */}
                  <ButtonBoxDiv>
                    {config[elsepropName].length !== 1 && (
                      <MarginRightButton
                        size="default"
                        onClick={() => handleRemoveClick(i, elsepropName)}
                      >
                        {translationCache &&
                          translationCache[`${constants.remove}`] &&
                          translationCache[`${constants.remove}`]
                          ? translationCache[`${constants.remove}`]
                          : `${constants.remove}`}
                      </MarginRightButton>
                    )}
                    {config[elsepropName].length - 1 === i && (
                      <Fragment>
                        <LogicAddButton
                          type={"primary"}
                          size="default"
                          onClick={() => handleAddClick(elsepropName)}
                        >
                          {translationCache &&
                            translationCache[`${constants.add}`] &&
                            translationCache[`${constants.add}`]
                            ? translationCache[`${constants.add}`]
                            : `${constants.add}`}
                        </LogicAddButton>
                        <Popover
                          content={
                            <div>
                              <TextArea
                                value={ config[elsepropName][i]["priventive"] }
                                rows={5}
                                maxLength="150"
                                onChange={(e) => handleTextAreaChange( e, i, elsepropName, "priventive" ) }
                              />
                              <span
                            style={{ color: "#1890ff", cursor: "pointer" }} onClick={(e) => { hanblePopoverOnclick( i, elsepropName, "close", "priventive", `previsibleType${elsepropName}` ); }} > Cancel </span>
                              <span
                            style={{ color: "#1890ff", cursor: "pointer" ,float:"right" }} onClick={(e) => { hanblePopoverOnclick( i, elsepropName, "ok", `previsibleType${elsepropName}` ); }} > Ok </span>
                            </div>
                          }
                          visible={config[`previsibleType${elsepropName}`]}
                          onVisibleChange={(value) => handleVisibleChange( value, `previsibleType${elsepropName}` ) }
                          title={ translationCache && translationCache[ `${constants.preventivemeasure}` ] && translationCache[`${constants.preventivemeasure}`][ language ] ? translationCache[ `${constants.preventivemeasure}` ] : `${constants.preventivemeasure}` }
                          trigger="click"
                        >
                          <TextParagraph>
                            {translationCache &&
                              translationCache[
                              `${constants.preventivemeasure}`
                              ] &&
                              translationCache[`${constants.preventivemeasure}`][
                              language
                              ]
                              ? translationCache[
                              `${constants.preventivemeasure}`
                              ]
                              : `${constants.preventivemeasure}`}
                          </TextParagraph>
                          {
                          config[elsepropName][i]["priventive"] &&  config[elsepropName][i]["priventive"].length > 0 ?
                            <span style={{ color: "#60c40e" }}><Icon type="check-circle" /></span>
                            : null
                        }
                        </Popover>

                        {/* <Divider   style={{ background: "black" }} type="vertical" /> */}
                        <Popover
                          content={
                            <div>
                              <TextArea
                                value={
                                  config[elsepropName][i]["rootcause"]
                                }
                                rows={5}
                                maxLength="150"
                                onChange={(e) => handleTextAreaChange( e, i, elsepropName, "rootcause" ) }
                              />
                              <span
                            style={{ color: "#1890ff", cursor: "pointer" }}onClick={(e) => { hanblePopoverOnclick( i, elsepropName, "close", "rootcause", `elseifroot${elsepropName}` ); }} > Cancel </span>
                               <span
                            style={{ color: "#1890ff", cursor: "pointer", float: "right" }} onClick={(e) => { hanblePopoverOnclick( i, elsepropName, "ok", `elseifroot${elsepropName}` ); }} > Ok </span>
                            </div>
                          }
                          visible={config[`elseifroot${elsepropName}`]}
                          onVisibleChange={(value) => handleVisibleChange( value, `elseifroot${elsepropName}` ) }
                          title={ translationCache && translationCache[ `${constants.preventivemeasure}` ] && translationCache[`${constants.preventivemeasure}`][ language ] ? translationCache[ `${constants.preventivemeasure}` ] : `${constants.preventivemeasure}` }
                          trigger="click"
                        >
                          <TextParagraph>
                            {translationCache &&
                              translationCache[
                              `${constants.rootcauseanalysis}`
                              ] &&
                              translationCache[`${constants.rootcauseanalysis}`][
                              language
                              ]
                              ? translationCache[
                              `${constants.rootcauseanalysis}`
                              ]
                              : `${constants.rootcauseanalysis}`}
                          </TextParagraph>
                          {
                       config[elsepropName][i]["rootcause"] && config[elsepropName][i]["rootcause"].length > 0 ?
                            <span style={{ color: "#60c40e" }}><Icon type="check-circle" /></span>
                            : null
                        }
                        </Popover>
                        {/* <Divider   style={{ background: "black" }} type="vertical" /> */}
                        <Popover
                          content={
                            <div>
                              <TextArea
                                value={ config[elsepropName][i]["recommandation"] }
                                rows={5}
                                maxLength="150"
                                onChange={(e) => handleTextAreaChange( e, i, elsepropName, "recommandation" ) }
                              />
                               <span
                            style={{ color: "#1890ff", cursor: "pointer" }}onClick={(e) => { hanblePopoverOnclick( i, elsepropName, "close", "recommandation", `elseifrootr${elsepropName}` ); }} > Cancel </span>
                              <span
                            style={{ color: "#1890ff", cursor: "pointer",float:"right" }} onClick={(e) => { hanblePopoverOnclick( i, elsepropName, "ok", `elseifrecom${elsepropName}` ); }} > Ok </span>
                            </div>
                          }
                          visible={config[`elseifrecom${elsepropName}`]}
                          onVisibleChange={(value) => handleVisibleChange( value, `elseifrecom${elsepropName}` ) }
                          title={ translationCache && translationCache[ `${constants.recomandations}` ] && translationCache[`${constants.recomandations}`][ language ] ? translationCache[ `${constants.recomandations}` ] : `${constants.recomandations}` }
                          trigger="click"
                        >
                          <TextParagraph>
                            {translationCache &&
                              translationCache[
                              `${constants.recomandations}`
                              ] &&
                              translationCache[`${constants.recomandations}`][
                              language
                              ]
                              ? translationCache[
                              `${constants.recomandations}`
                              ]
                              : `${constants.recomandations}`}
                          </TextParagraph>
                        </Popover>
                        {
                          config[elsepropName][i]["recommandation"] && config[elsepropName][i]["recommandation"].length > 0 ?
                            <span style={{ color: "#60c40e" }}><Icon type="check-circle" /></span>
                            : null
                        }
                      </Fragment>
                    )}
                  </ButtonBoxDiv>
                  {config[elsepropName].length - 1 === i && (
                    <ActionSelectionDiv>
                      <BlockedLabelBlack>
                        {translationCache &&
                          translationCache[`${constants.action}`] &&
                          translationCache[`${constants.action}`]
                          ? translationCache[`${constants.action}`]
                          : `${constants.action}`}
                        <span style={{ color: "red" }}>*</span>
                      </BlockedLabelBlack>
                      <ActionCheckbox
                        options={translationAction}
                        value={
                          language === "English"
                            ? config[elsepropName][i]["action"]
                            : config[elsepropName][i]["action"].map(
                              (covert) => {
                                return translationCache[covert] !== undefined
                                  ? translationCache[covert]
                                  : covert;
                              }
                            )
                        }
                        onChange={(checkedValues) => {
                          checkonChange(checkedValues, elsepropName, i);
                        }}
                      />
                      <TimePicker
                        defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                        value={
                          config[elsepropName][i]["delay"] === undefined
                            ? undefined
                            : moment(
                              config[elsepropName][i]["delay"][1],
                              "HH:mm:ss"
                            )
                        }
                        onChange={(e) => handleDelay(e, elsepropName, i)}
                        placeholder={
                          translationCache &&
                            translationCache[`${constants.selectDelay}`] &&
                            translationCache[`${constants.selectDelay}`]
                            ? translationCache[`${constants.selectDelay}`][
                            language
                            ]
                            : `${constants.selectDelay}`
                        }
                        size="small"
                        allowClear={false}
                      />
                       {
                      config &&  config[elsepropName][i] &&
                      config[elsepropName][i].action.indexOf("RestAPI") > -1 ?
                      <Input
                        style={{ width: "55%", marginTop: "2%" }}
                        placeholder="Enter Url"
                        name="value"
                        value={url}
                        onChange={(e) => handleurl(e, "url")} />: null}
                    </ActionSelectionDiv>
                  )}
                  {i === config[elsepropName].length - 1 ? (
                    <div>
                      <Row>
                        <Col span={7}>
                          <BlockedLabelBlack>
                            {translationCache &&
                              translationCache[`${constants.members}`] &&
                              translationCache[`${constants.members}`]
                              ? translationCache[`${constants.members}`]
                              : `${constants.members}`}
                          </BlockedLabelBlack>
                          <Select
                            placeholder="RecipientList"
                            mode="multiple"
                            value={mailRecipients}
                            style={{ width: "90%", display: "inline-block" }}
                            onChange={(value) => handleRecipientList(value, "mailRecipients")}
                          >
                            {recipientList && Array.isArray(recipientList) && recipientList.map((item) => {
                              return (
                                <Option value={item.Email}>
                                  {item.FirstName} {item.LastName}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>
                        <Col span={6}>
                          <BlockedLabelBlack>
                            {translationCache &&
                              translationCache[`${constants.bodymessage}`] &&
                              translationCache[`${constants.bodymessage}`]
                              ? translationCache[`${constants.bodymessage}`]
                              : `${constants.bodymessage}`}
                          </BlockedLabelBlack>
                          <TextArea
                            size="default"
                            name="value"
                            rows={1}
                            value={rulebody}
                            placeholder={
                              translationCache &&
                                translationCache[`${constants.text}`] &&
                                translationCache[`${constants.text}`]
                                ? translationCache[`${constants.text}`]
                                : `${constants.text}`
                            }
                            onChange={(e) => handleRulebody(e, "rulebody")}
                          />
                        </Col>
                      </Row>

                    </div>

                  ) : null}

                </Boxdiv>
              );
            });
          })
          : null}

        {conelse
          ? elseList.map((x, i) => {
            return (
              <Boxdiv key={`else${i}`}>
                {i === 0 ? <BlockedLabelBlack>else</BlockedLabelBlack> : null}
                {i > 0 ? (
                  <ConditionSelect
                    name="operater"
                    size="default"
                    value={x["54765"] === "" ? undefined : x["54765"]}
                    placeholder={
                      translationCache &&
                        translationCache[`${constants.selectCondition}`] 
                        ? translationCache[`${constants.selectCondition}`]
                        : `${constants.selectCondition}`
                    }
                    onChange={(e) => handleConditionchange(e, i, "elseList")}
                  >
                    <Option value="&&">AND</Option>
                    <Option value="||">OR</Option>
                  </ConditionSelect>
                ) : null}
                <DeviceSelect
                  placeholder={
                    translationCache &&
                      translationCache[`${constants.device}`] &&
                      translationCache[`${constants.device}`]
                      ? translationCache[`${constants.device}`]
                      : `${constants.device}`
                  }
                  disabled
                  size="default"
                  value={x.device}
                  onChange={(value) => handledeviceSelect(value, i, "elseList")}
                >
                  {deviceTypeList.map((device, i) => {
                    return (
                      <Option
                        key={`elsedeviceList${i}`}
                        value={device.Id}
                      >
                        {device.Name}
                      </Option>
                    );
                  })}
                </DeviceSelect>
                <TagNameSelect
                  showSearch
                  name="tagName"
                  size="default"
                  placeholder={
                    translationCache &&
                      translationCache[`${constants.selectparameters}`] &&
                      translationCache[`${constants.selectparameters}`]
                      ? translationCache[`${constants.selectparameters}`][
                      language
                      ]
                      : `${constants.selectparameters}`
                  }
                  value={x.tagName === "" ? undefined : x.tagName}
                  onChange={(e) => handleTagSelect(e, i, "elseList")}
                >
                  {parametersList
                    //.filter((param) => param.DeviceId === x.device)
                    .map((param, j) => {
                      return (
                        <Option
                          key={`elseListTagname${i}${j}`}
                          value={param.ParameterCode}
                        >
                    {param.ParameterCode}
                          {/* {`${param.AliasCode}:${param.ParameterName}`} */}
                        </Option>
                      );
                    })}
                </TagNameSelect>
                <OperaterSelect
                  name="operater"
                  size="default"
                  placeholder={
                    translationCache &&
                      translationCache[`${constants.selectoperator}`] &&
                      translationCache[`${constants.selectoperator}`]
                      ? translationCache[`${constants.selectoperator}`][
                      language
                      ]
                      : `${constants.selectoperator}`
                  }
                  value={x.operater === "" ? undefined : x.operater}
                  onChange={(e) =>
                    handleInputChange(e, i, "select", "elseList")
                  }
                >
                  <Option value="==" title="EqualTo">
                    ==
                  </Option>
                  <Option value="!=" title="Not EqualTo">
                    !=
                  </Option>
                  <Option value=">" title="Greater Than">
                    {">"}
                  </Option>
                  <Option value="<" title="Less Than">
                    {"<"}
                  </Option>
                  <Option value=">=" title="Greater Than Or EqualTo">
                    {">="}
                  </Option>
                  <Option value="<=" title="Less Than Or Equal To">
                    {"<="}
                  </Option>
                </OperaterSelect>
                {/* <ValueInput
                  size="default"
                  name="value"
                  maxLength={6}
                  placeholder={
                    translationCache &&
                      translationCache[`${constants.value}`] &&
                      translationCache[`${constants.value}`]
                      ? translationCache[`${constants.value}`]
                      : `${constants.value}`
                  }
                  value={x.value}
                  onChange={(e) => handleInputChange(e, i, "input", "elseList")}
                /> */}
                <ButtonBoxDiv>
                  {elseList.length !== 1 && (
                    <MarginRightButton
                      size="default"
                      onClick={() => handleRemoveClick(i, "elseList")}
                      icon="delete"
                    >
                      {translationCache &&
                        translationCache[`${constants.remove}`] &&
                        translationCache[`${constants.remove}`]
                        ? translationCache[`${constants.remove}`]
                        : `${constants.remove}`}
                    </MarginRightButton>
                  )}
                  {elseList.length - 1 === i && (
                    <Fragment>
                      <LogicAddButton
                        type={"primary"}
                        size="default"
                        onClick={() => handleAddClick("elseList")}
                      >
                        {translationCache &&
                          translationCache[`${constants.add}`] &&
                          translationCache[`${constants.add}`]
                          ? translationCache[`${constants.add}`]
                          : `${constants.add}`}
                      </LogicAddButton>
                      <Popover
                        content={
                          <div>
                            <TextArea
                              value={elseList[i]["priventive"]}
                              rows={5}
                              maxLength="150"
                              onChange={(e) =>
                                handleTextAreaChange(
                                  e,
                                  i,
                                  "elseList",
                                  "priventive"
                                )
                              }
                            />
                            <span
                            style={{ color: "#1890ff", cursor: "pointer" }}
                              onClick={(e) => {
                                hanblePopoverOnclick(
                                  i,
                                  "elseList",
                                  "close",
                                  "priventive",
                                  `elseprevisible`
                                );
                              }}
                            >
                              Cancel
                            </span>
                            <span
                            style={{ color: "#1890ff", cursor: "pointer" , float:"right" }}
                              onClick={(e) => {
                                hanblePopoverOnclick(
                                  i,
                                  "elseList",
                                  "ok",
                                  `elseprevisible`
                                );
                              }}
                            >
                              Ok
                            </span>
                          </div>
                        }
                        visible={elseprevisible}
                        onVisibleChange={(value) =>
                          handleVisibleChange(value, `elseprevisible`)
                        }
                        title={
                          translationCache &&
                            translationCache[`${constants.preventivemeasure}`] &&
                            translationCache[`${constants.preventivemeasure}`][
                            language
                            ]
                            ? translationCache[
                            `${constants.preventivemeasure}`
                            ]
                            : `${constants.preventivemeasure}`
                        }
                        trigger="click"
                      >
                        <TextParagraph>
                          {translationCache &&
                            translationCache[`${constants.preventivemeasure}`] &&
                            translationCache[`${constants.preventivemeasure}`][
                            language
                            ]
                            ? translationCache[
                            `${constants.preventivemeasure}`
                            ]
                            : `${constants.preventivemeasure}`}
                        </TextParagraph>
                        {
                          elseList[i]["priventive"] && elseList[i]["priventive"].length > 0 ?
                            <span style={{ color: "#60c40e" }}><Icon type="check-circle" /></span>
                            : null
                        } 
                      </Popover>
                      {/* <Divider   style={{ background: "black" }}type="vertical" /> */}
                      <Popover
                        content={
                          <div>
                            <TextArea
                              value={elseList[i]["rootcause"]}
                              rows={4}
                              maxLength="150"
                              onChange={(e) => handleTextAreaChange( e, i, "elseList", "rootcause" ) }
                            />
                             <span
                            style={{ color: "#1890ff", cursor: "pointer" }}onClick={(e) => { hanblePopoverOnclick( i, "elseList", "close", "rootcause", `elseroot` ); }} > Cancel </span>
                             <span
                            style={{ color: "#1890ff", cursor: "pointer" ,float:"right" }} onClick={(e) => { hanblePopoverOnclick( i, "elseList", "ok", `elseroot` ); }} > Ok </span>
                          </div>
                        }
                        visible={elseroot}
                        onVisibleChange={(value) =>
                          handleVisibleChange(value, `elseroot`)
                        }
                        title={ translationCache && translationCache[`${constants.rootcauseanalysis}`] && translationCache[`${constants.rootcauseanalysis}`][ language ] ? translationCache[ `${constants.rootcauseanalysis}` ] : `${constants.rootcauseanalysis}` }
                        trigger="click"
                      >
                        <TextParagraph>
                          {translationCache &&
                            translationCache[`${constants.rootcauseanalysis}`] &&
                            translationCache[`${constants.rootcauseanalysis}`][
                            language
                            ]
                            ? translationCache[
                            `${constants.rootcauseanalysis}`
                            ]
                            : `${constants.rootcauseanalysis}`}
                        </TextParagraph>
                        {
                          elseList[i]["rootcause"] && elseList[i]["rootcause"].length > 0 ?
                            <span style={{ color: "#60c40e" }}><Icon type="check-circle" /></span>
                            : null
                        } 
                      </Popover>
                      {/* <Divider  style={{ background: "black" }}type="vertical" /> */}
                      <Popover
                        content={
                          <div>
                            <TextArea
                              value={elseList[i]["recommandation"]}
                              rows={4}
                              maxLength="150"
                              onChange={(e) => handleTextAreaChange( e, i, "elseList", "recommandation" ) }
                            />
                             <span
                            style={{ color: "#1890ff", cursor: "pointer" }}onClick={(e) => { hanblePopoverOnclick( i, "elseList", "close", "recommandation", `elserecommandation` ); }} > Cancel </span>
                             <span
                            style={{ color: "#1890ff", cursor: "pointer" ,float:"right" }} onClick={(e) => { hanblePopoverOnclick( i, "elseList", "ok", `elserecommandation` ); }} > Ok </span>
                          </div>
                        }
                        visible={elserecommandation}
                        onVisibleChange={(value) =>
                          handleVisibleChange(value, `elserecommandation`)
                        }
                        title={ translationCache && translationCache[`${constants.recomandations}`] && translationCache[`${constants.recomandations}`][ language ] ? translationCache[ `${constants.recomandations}` ] : `${constants.recomandations}` }
                        trigger="click"
                      >
                        <TextParagraph>
                          {translationCache &&
                            translationCache[`${constants.recomandations}`] &&
                            translationCache[`${constants.recomandations}`][
                            language
                            ]
                            ? translationCache[
                            `${constants.recomandations}`
                            ]
                            : `${constants.recomandations}`}
                        </TextParagraph>
                      </Popover>
                      {
                          elseList[i]["recommandation"] && elseList[i]["recommandation"].length > 0 ?
                            <span style={{ color: "#60c40e" }}><Icon type="check-circle" /></span>
                            : null
                        } 
                    </Fragment>
                  )}
                </ButtonBoxDiv>
                {elseList.length - 1 === i && (
                  <ActionSelectionDiv>
                    <BlockedLabelBlack>
                      {translationCache &&
                        translationCache[`${constants.action}`] &&
                        translationCache[`${constants.action}`]
                        ? translationCache[`${constants.action}`]
                        : `${constants.action}`}
                      <span style={{ color: "red" }}>*</span>
                    </BlockedLabelBlack>
                    <ActionCheckbox
                      options={translationAction}
                      value={
                        language === "English"
                          ? config.elseList[i]["action"]
                          : config.elseList[i]["action"].map((covert) => {
                            return translationCache[covert] !== undefined
                              ? translationCache[covert]
                              : covert;
                          })
                      }
                      onChange={(checkedValues) => {
                        checkonChange(checkedValues, "elseList", i);
                      }}
                    />

                    <TimePicker
                      defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                      value={
                        config.elseList[i]["delay"] === undefined
                          ? undefined
                          : moment(
                            config.elseList[i]["delay"][1],
                            "HH:mm:ss"
                          )
                      }
                      onChange={(e) => handleDelay(e, "elseList", i)}
                      placeholder={
                        translationCache &&
                          translationCache[`${constants.selectDelay}`] &&
                          translationCache[`${constants.selectDelay}`]
                          ? translationCache[`${constants.selectDelay}`]
                          : `${constants.selectDelay}`
                      }
                      size="small"
                      allowClear={false}
                    />
                      {
                      config &&   config.elseList[i] &&
                      config.elseList[i].action.indexOf("RestAPI") > -1 ?
                    <Input
                      style={{ width: "55%", marginTop: "2%" }}
                      placeholder="Enter Url"
                      name="value"
                      value={url}
                      onChange={(e) => handleurl(e, "url")} /> :null}
                  </ActionSelectionDiv>
                )}
                {i === elseList.length - 1 ? (
                  <div>
                    <Row>
                      <Col span={7}>
                        <BlockedLabelBlack>
                          {translationCache &&
                            translationCache[`${constants.members}`] &&
                            translationCache[`${constants.members}`]
                            ? translationCache[`${constants.members}`]
                            : `${constants.members}`}
                        </BlockedLabelBlack>
                        <Select
                          placeholder="RecipientList"
                          value={mailRecipients}
                          mode="multiple"
                          style={{ width: "90%", display: "inline-block" }}
                          onChange={(value) => handleRecipientList(value, "mailRecipients")}
                        >
                          {recipientList && Array.isArray(recipientList) && recipientList.map((item) => {
                            return (
                              <Option value={item.Email}>
                                {item.FirstName} {item.LastName}
                              </Option>
                            );
                          })}
                        </Select>
                      </Col>
                      <Col span={5}>
                        <BlockedLabelBlack>
                          {translationCache &&
                            translationCache[`${constants.bodymessage}`] &&
                            translationCache[`${constants.bodymessage}`]
                            ? translationCache[`${constants.bodymessage}`]
                            : `${constants.bodymessage}`}
                        </BlockedLabelBlack>
                        <TextArea
                          size="default"
                          name="value"
                          rows={1}
                          value={rulebody}
                          placeholder={
                            translationCache &&
                              translationCache[`${constants.text}`] &&
                              translationCache[`${constants.text}`]
                              ? translationCache[`${constants.text}`]
                              : `${constants.text}`
                          }
                          onChange={(e) => handleRulebody(e, "rulebody")}
                        />
                      </Col>
                    </Row>

                  </div>
                ) : null}
              </Boxdiv>
            );
          })
          : null}
        <AddParagraph onClick={handleelse}>
          {translationCache &&
            translationCache[`${constants.addelse}`] &&
            translationCache[`${constants.addelse}`]
            ? translationCache[`${constants.addelse}`]
            : `${constants.addelse}`}
        </AddParagraph>
        <AddParagraph onClick={handleelseif}>
          {translationCache &&
            translationCache[`${constants.addelseif}`] &&
            translationCache[`${constants.addelseif}`]
            ? translationCache[`${constants.addelseif}`]
            : `${constants.addelseif}`}
        </AddParagraph>
        <BlankDiv></BlankDiv>
      </StyledAppdiv>
    );
  }
}
