import localStorage from "../../../utils/localStorage";

export async function getReportTypeList() {
  let siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");
  var Authorization = "Bearer " + token;
  let data = await fetch(`/api/site/${siteId}/report/reportTypesList `, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}
