
import React from "react";
import cloneDeep from "lodash.clonedeep";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Tag} from "antd";
import moment from "moment";
import ReactEcharts from "../Echarts/Echarts";
import * as echarts from 'echarts';
import {
  getChartDataWithoutSelection,
  getChartDataWithSelection,
} from "../ChartCalls";
import { trendLabelTranslation } from "../../Utils/Adapter/translationAdapter";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { dottedBarMockJson } from "../../Mock/MockJson";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { defaultRefreshTime } from '../constant.json'
import { Fragment } from "react";

class DottedBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
      option: this.getOption(),
      data: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if(this.requestCheck){
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear
    ) {
      this.getData(this.props);
    }
  }

  getData = async (props) => {
    this.requestCheck = false
    let theme = this.props.selectedThemeing;
    let option = cloneDeep(this.state.option);
    let json = [];
    let { graphprops } = this.props;
    let xaxisLabels = [];
    let lineData = [];
    let barData = [];
    let tagName = [];
    let series = [];
    let xAxis = {};
    let legend = [];
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false 
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = dottedBarMockJson;
      this.requestCheck = true
    } else {
    if (
      props.graphprops.graph === "dottedBar" &&
      props.graphprops.currentDateData !== undefined
    ) {
      json = props.graphprops.currentDateData;
    } else {
      if (
        (graphprops &&
          graphprops.dashboardType === "dynamic" &&
          this.props.deviceId) ||
        (graphprops &&
          graphprops.dateExists === "true" &&
          this.props.selectionDate) ||
        (graphprops &&
          graphprops.monthExists === "true" &&
          this.props.selectionMonth) ||
        (graphprops &&
          graphprops.weekExists === "true" &&
          this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
        (graphprops &&
          graphprops.dateRangeExists === "true" &&
          this.props.selectionDateRange) ||
        (graphprops &&
          graphprops.dayMonthYearExists === "true" &&
          this.props.dayMonthYear)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController
        );
        this.requestCheck = true
      } else {
        json = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true
      }
    }
  }
    // json = [
    //   {
    //     intervals: [2020, 2021, 2022, 2023, 2024, 2025],
    //     lineData: {
    //       tagName: "EnergyMeterer 1",
    //       values: [2, 4, 6, 8, 10, 12],
    //     },
    //     barData: {
    //       tagName: "EnergyMeterer 2",
    //       values: [1, 2, 3, 4, 5, 6],
    //     },
    //   },
    // ];
    if ((json && json.message) || (Array.isArray(json) && (json.length === 0 && 
    graphprops.enableMock !== true))) {
  
      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
      }
  
    } else {
    if (json && Array.isArray(json)) {
      json.map((item, index) => {
        tagName.push(item.lineData.tagName);
        tagName.push(item.barData.tagName);
        lineData = item.lineData.values;
        barData = item.barData.values;
       item.intervals.map((intervals)=>{
        let date={}
        if(this.props.graphprops.monthExists === "true" || this.props.graphprops.monthExists === true){
         
        date=moment.utc(intervals).local().format("DD/MM/YYYY")
        }else{
          date=moment.utc(intervals).local().format("DD/MM/YYYY HH:mm:ss")
        }
          xaxisLabels.push(date)

        });
        
      });
    }
    xAxis = {
      data: xaxisLabels,
      axisLine: {
        lineStyle: {
          color: theme === "lightTheme" ? "black" : "white",
        },
      },
      axisTick: {
        lineStyle: {
          color: theme === "lightTheme" ? "black" : "white",
        },
      },
      axisLabel: {
        color: theme === "lightTheme" ? "black" : "white",
        // fontSize: 15,
      },
    };
    tagName.map((item) => {
      legend.push({
        name: item,
        icon: "roundRect",
        textStyle: {
          color: theme === "lightTheme" ? "black" : "white",
          fontSize: 18,
          fontWeight:"bold",
        },
      });

    })
    series = [
      {
        name: tagName[0],
        type: "line",
        smooth: true,
        showAllSymbol: true,
        symbol: "emptyCircle",
        itemStyle: {
          normal: {
            color: this.props.graphprops.colors[0]
              ? this.props.graphprops.colors[0]
              : "red",
          },
        },
        symbolSize: 15,
        data: lineData,
      },
      {
        name: tagName[1],
        type: "bar",
        barWidth: 10,
        itemStyle: {
          barBorderRadius: 5,
          color: this.props.graphprops.colors[1]
            ? this.props.graphprops.colors[1]
            : new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#14c8d4" },
              { offset: 1, color: "#43eec6" },
            ]),
        },
        data: barData,
      },
      {
        name: tagName[0],
        type: "bar",
        barGap: "-100%",
        barWidth: 10,
        itemStyle: {
          color: this.props.graphprops.colors[2]
            ? this.props.graphprops.colors[2]
            : new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "rgba(20,200,212,0.5)" },
              { offset: 0.2, color: "rgba(20,200,212,0.2)" },
              { offset: 1, color: "rgba(20,200,212,0)" },
            ]),
        },
        z: -12,
        data: lineData,
      },
      {
        name: tagName[1],
        type: "pictorialBar",
        symbol: "rect",
        itemStyle: {
          color: this.props.graphprops.colors[2]
            ? this.props.graphprops.colors[2]
            : "#0f375f",
        },
        symbolRepeat: true,
        symbolSize: [12, 4],
        symbolMargin: 1,
        z: -10,
        data: lineData,
      },
    ];
    let seriesData = await trendLabelTranslation(
      series,
      this.props.translationCache,
      this.props.language
    );

    legend = await trendLabelTranslation(
      legend,
      this.props.translationCache,
      this.props.language
    );

    option = this.getOption();
    option.series = seriesData;
    option.xAxis = xAxis;
    option.legend = {
      textStyle: { color: theme === "lightTheme" ? "black" : "white" },
      data: [...legend],
    };
    this.setState({
       option,
       refreshDateTime: moment()
       });
  };
  this.setState({
    errorInAPI,
    noDataInDB
  })
};

  render() {
    const {errorInAPI, noDataInDB,refreshDateTime } = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
         {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
            <NoRecordComponent/>
            : <ErrorComponent/>
            :
            <ReactEcharts
        option={this.state.option}
        notMerge={true}
        lazyUpdate={true}
        style={{ height: "90%", width: "100%" }}
        className="react_for_echarts"
      />
        }
      </Fragment>
      
    );
  }

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let colorCode = this.props.graphprops && this.props.graphprops.colors ? this.props.graphprops.colors : ['red',"green"]
    let option = {
      // tooltip: {
      //   trigger: "axis",
      //   formatter: "{b} <br/>{a}: {c} <br/> {a1} : {c1}",
      // //   formatter: function (params) {          
      // //     var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';
      // //     let rez = '<p>' + params[0].axisValue + '</p>';
      // //     params.forEach((item,index) => {
      // //         if(index <= 1 ){
      // //           var xx = '<p>' + colorSpan(colorCode[index]) + ' ' + item.seriesName + ': ' + item.data  + '</p>'
      // //           rez += xx;
      // //         }
            
      // //     });
  
      // //     return rez;
      // // }      ,
      
      //   axisPointer: {
      //     type: "shadow",
      //     animation: true
      //   },

      //   textStyle: {
      //       // color: theme === "lightTheme" ? "black" : "white",
      //       fontSize: 16,
      //   },
      // },
      tooltip: {
        trigger: "axis",
       // formatter: "{b} <br/>{a}: {c} <br/> {a1} : {c1}",
       formatter: function (params) {       
        var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';
        let rez = '<p>' + params[0].axisValue + '</p>';
        params.forEach((item,index) => {
          if(index <= 1 ){
              var xx = '<p>' + colorSpan(colorCode[index]) + '' + item.seriesName + ': ' + item.data  + '</p>'
              rez += xx;
            }
           
        });
       let difference =params[0].data-params[1].data
     rez += '<p>' +  colorSpan(colorCode[colorCode.length-1]) +  '' + '<b>'+'Difference'+  ': ' + Number(difference).toFixed(2)  + '</b>'  +  '</p>'
     return rez;
    } ,
        axisPointer: {
          type: "shadow",
          animation: true
        },

        textStyle: {
            // color: theme === "lightTheme" ? "black" : "white",
            fontSize: 16,
        },
      },
      legend: {
        data: ["line", "bar"],
    
        textStyle: {
          color: theme === "lightTheme" ? "black" : "white",
        },
      },

      yAxis: {
        splitLine: { show: false },
        axisLabel: {
          // fontSize: 15,
        },
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            // backgroundColor: "black",
            title: "download",
            backgroundColor: theme === "lightTheme" ? "black" : "black",
            name: this.props.graphprops.title,
            iconStyle: {
              // color: "none",
              borderColor: "#8c8c8c",
              borderWidth: 2,
            },
          },
        },
      },
      dataZoom: [
        {
          type: "slider",
          show: true,
          start: 0,
          end: 100,
          backgroundColor: "#9a9191",

          textStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        {
          type: "inside",
          start: 0,
          end: 100,
        },
      ],

      xAxis: {
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisTick: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisLabel: {
          color: theme === "lightTheme" ? "black" : "white",
          // fontSize: 15,
        },
      },
      // legend: {
      //   textStyle: { color: theme === "lightTheme" ? "black" : "white" },
      // },
      series: [],
    };

    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(DottedBar);