import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import DefaultLandingPageComponent from './DefaultLandingPageComponent'
import { getLandingPage } from '../../../../selectors/layout'

const mapStateToProps = createStructuredSelector({
  landingPage: getLandingPage()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(
      {
        
      },
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLandingPageComponent)
