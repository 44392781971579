import styled from "styled-components";
import { Layout } from "antd";
const { Sider } = Layout;

export const StyledSider = styled(Sider)`

 
  .ant-menu-submenu > .ant-menu {
   
    opacity: 0.9;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
   
  }

  .ant-menu-inline > .ant-menu-item {
    font-size: 14px !important;
    font-weight: 100 !important;
  }
`;
