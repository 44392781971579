import React, { Fragment } from 'react'
import { Table, Spin, Icon,Badge } from 'antd'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import moment from "moment";

import { getChartDataWithoutSelection, getChartDataWithSelection } from '../ChartCalls'
import { tableTranslation } from "../../Utils/Adapter/translationAdapter";
import { getCurrentLanguage, getTranslationCache } from '../../../../selectors/language'
import { defaultRefreshTime } from '../constant.json';
import {
  ErrorComponent,
  NoRecordComponent
} from '../Utils'
import { tableMock } from "../../Mock/MockJson";

import 'antd/dist/antd.css'
import '../CSS/Table.css'

class TableComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [],
      tabledata: [],
      loading: false,
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    }
  }

  columns = [];

  tabledata = [];

  config = {
    redirectTo: 'counter'
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  rowUpdateInterval = null;

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props);
      }

    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload
    ) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  updateData = async (data) => {
    try {
      this.columns = []
      this.tabledata = []
      const columnNames = Object.keys(data[0])

      columnNames.map((value, key) => {
        //   if (value === "tagname") {
        if (value.length > 0 && value !== "color" && value !== "Severity" && value !== "noData") {
          this.columns.push({
            title: value,
            dataIndex: value,
            key: value,
            render: (text, row, key) => {
              if (row.Severity === "High" && value === "Impact") {
                return {
                  props: {
                    style: {

                      color: row.Severity
                        ? this.getColor(row.Severity)
                        : "red",
                    },
                  },

                  children: <span> <Icon type="arrow-up" style={{ marginRight: "4px", fontWeight: "800", fontSize: "0.8em" }} />{text}</span>,
                };
              } else if (row.Severity === "Low" && value === "Impact") {
                return {
                  props: {
                    style: {
                      color: row.Severity
                        ? this.getColor(row.Severity)
                        : "green",
                    },
                  },

                  children: <span> <Icon type="arrow-down" style={{ marginRight: "4px", fontWeight: "800", fontSize: "0.8em" }} />{text}</span>,
                };
              } else if (row.Severity === "Equal" && value === "Impact") {
                return {
                  props: {
                    style: {
                      color: row.Severity
                        ? this.getColor(row.Severity)
                        : "orange",
                    },
                  },

                  children: <span>{text}</span>,
                };
              } else {
                return {
                  props: {
                    //style: { background: Math.abs(key % 2) === 1 ? '#1a1a1a' : '#000' },
                    // style: { color: '#000' },
                  },
                  children: <span>{text}</span>,
                };
              }

            },
            // render(text, record, key) {
            //   return {
            //     props: {
            //       //style: { background: Math.abs(key % 2) === 1 ? '#1a1a1a' : '#000' },
            //       style: { color: record && record.color ? record.color : '#000' },
            //     },
            //     children: <text style={{ color: record && record.color ? record.color : '#8c8c8c' }}>{text}</text>,
            //   };
            // },
          })
        }
        //  } else if (value === "tagvalue") {
        //  this.columns.push({
        //   title: "Tag Value",
        //   dataIndex: value,
        //  key: value,
        //   render: text => <text style={{ color: "orange" }}>{text}</text>
        //  });
        // }
      })

      if (data && data[0] && data[0]['noData']) {

      } else {
        data.map((dataval, dataindex) => {
          dataval.key = dataindex + 1
          this.tabledata.push(dataval)
        })
      }


      // this.setState({
      //   columns: this.columns,
      //   tabledata: this.tabledata.slice(0, 2)
      // });

      // let columnDisplaySize = (() => {
      //   var columncount = 0;
      //   let inner = () => {
      //     if (columncount < this.tabledata.length) {
      //       columncount = columncount + 2;

      //       var sliced = this.tabledata.slice(columncount - 2, columncount);
      //       return sliced;
      //     } else {
      //       columncount = 2;
      //       var sliced = this.tabledata.slice(columncount - 2, columncount);
      //       return sliced;
      //     }
      //   };
      //   return inner;
      // })();

      // this.rowUpdateInterval = setInterval(() => {
      // alert(JSON.stringify(this.columns))
      let translationTable = await tableTranslation(this.columns, this.props.translationCache, this.props.language)
      this.setState({
        columns: translationTable,
        tabledata: this.tabledata
      })
      // }, 3000);
    } catch (e) {
      this.setState({ columns: [], tabledata: [] })
    }
  };
  getColor = (status) => {
    if (status === "Low") {
      return "red";
    } else if (status === "High") {
      return "green";
    } else {
      return "orange";
    }
  };
  getData = async () => {
    this.requestCheck = false;
    let data = []
    let { graphprops } = this.props
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false
    // this.setState({ loading: true });
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      data = tableMock;
      this.requestCheck = true
    } else {

      if (
        (graphprops &&
          graphprops.dashboardType === "dynamic" &&
          this.props.deviceId) ||
        (graphprops &&
          graphprops.dateExists === "true" &&
          this.props.selectionDate) ||
        (graphprops &&
          graphprops.monthExists === "true" &&
          this.props.selectionMonth) ||
        (graphprops &&
          graphprops.weekExists === "true" &&
          this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
        (graphprops &&
          graphprops.dateRangeExists === "true" &&
          this.props.selectionDateRange) ||
        (graphprops &&
          graphprops.dayMonthYearExists === "true" &&
          this.props.dayMonthYear) ||
        (graphprops &&
          graphprops.dashboardType === "hierarchy" &&
          this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload)
      ) {
        data = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection,
          this.props.dashboardPayload
        );
        this.requestCheck = true;
        this.setState({ loading: false });
      } else {
        data = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true;
        this.setState({
          loading: false,
          refreshDateTime: moment()

        });
      }
    }
    if ((data && data.message) || (Array.isArray(data)
      && (data.length === 0 && graphprops.enableMock !== true))) {

      if (data.message) {
        errorInAPI = true
      } else if (data.length === 0) {
        noDataInDB = true
      }

    } else {
      // data=[
      //   {
      //     "SRId": "",
      //     "SRDescription": "",
      //     "Location": "",
      //     "Asset": "",
      //     "SRStatus": "",
      //     "WorkOrderId": "",
      //     "WorkOrderDescription": "",
      //     "WorkOrderStatus": "",
      //     "WorkOrderDate": "",
      //     "noData": true
      //   }
      // ]
      this.updateData(data)
    };
    this.setState({
      errorInAPI,
      noDataInDB
    })
  }

  render() {
    let { loading, columns, errorInAPI, noDataInDB, tabledata } = this.state;
    let { title } = this.props.graphprops 
    let tableSize = 10
    if(this.props && this.props.graphprops && 
      this.props.graphprops.table && this.props.graphprops.table.tableSize){
        tableSize = this.props.graphprops.table.tableSize
    }
 
    return (
      <div>
        <div style={{ marginTop: "5px", padding: '10px' }}>
          {title == "Alerts" ? <div style={{ display: "flex", flexDirection: "row-reverse" }}>

            <div style={{ marginRight: '10px', color: 'white' }}>- {this.state.language !== "English" ? "警告-3低" : "Warning-3 Low"}</div>  <Badge color="rgb(176, 71, 71)" />
            <div style={{ marginRight: '10px', color: 'white' }}>- {this.state.language !== "English" ? "警告-3高" : "Warning-3 High"}</div>  <Badge color="rgb(168, 3, 3)" />
            <div style={{ marginRight: '10px', color: 'white' }}>- {this.state.language !== "English" ? "警告-2低" : "Warning-2 Low"}</div>  <Badge color="rgb(208, 151, 98)" />
            <div style={{ marginRight: '10px', color: 'white' }}>- {this.state.language !== "English" ? "警告-2高" : "Warning-2 High"}</div>  <Badge color="rgb(176, 116, 5)" />
            <div style={{ marginRight: '10px', color: 'white' }}>- {this.state.language !== "English" ? "警告-1低" : "Warning-1 Low"}</div>  <Badge color="rgb(137, 183, 185)" />
            <div style={{ marginRight: '10px', color: 'white' }}>- {this.state.language !== "English" ? "警告-1高" : "Warning-1 High"}</div>  <Badge color="rgb(3, 160, 168)" />

          </div> : null}
        </div>

        <Fragment>
          {/* <Tag color="blue" style={{float:"",position:"relative",zIndex:"2"}} >
           {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm') : ""}
           </Tag> */}
          {
            noDataInDB === true || errorInAPI === true ?
              noDataInDB === true ?
                <NoRecordComponent />
                : <ErrorComponent />
              :
              <span>
                {loading === false && columns && Array.isArray(columns) && columns.length > 0 ?
                  <div>
                    {
                      tabledata && Array.isArray(tabledata) ?
                        <Table
                          // scroll={{ x: (this.tabledata.length > 5) ? true : false }}
                          className="TableWidget"
                          style={{ padding: '0px 10px', marginTop: '12px' }}
                          columns={columns}
                          dataSource={tabledata}
                          scroll={{y: "auto" , x:200 }}
                          //scroll={{ x: 200 }}
                          pagination={{pageSize: tableSize} }
                         
                        />
                        : null
                    }
                  </div>
                  :
                  <div
                    style={{
                      textAlign: "center",
                      color: "white",
                      marginTop: "10%"
                    }}
                  >
                    <h2 style={{ color: "white" }}>
                      <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                    </h2>
                  </div>
                }
              </span>
          }
        </Fragment>

      </div>

    )
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache()
})


export default connect(mapStateToProps)(TableComponent)
