import { fromJS } from 'immutable'

import { types } from './types'

export const getAllDeviceList = () => {
  return {
    type: types.DEVICE_LIST
  }
}

export const getAllDeviceList_CM = () => {
  return {
    type: types.DEVICE_LIST_CM
  }
}

export const createDevice = (payload) => {
  return {
    type: types.CREATE_DEVICE,
    payload
  }
}

export const updateDevice = (payload) => {
  return {
    type: types.UPDATE_DEVICE,
    payload
  }
}

export const getAllDeviceListSuccess = (deviceList) => {
  return {
    type: types.DEVICE_LIST_SUCCESS,
    deviceList
  }
}


export const getAllDeviceListCMSuccess = (deviceList) => {
  return {
    type: types.DEVICE_CM_LIST_SUCCESS,
    deviceList
  }
}

export const currentDevice = device => {
  return {
    type: types.Current_Device,
    device
  };
};

export const deleteDevice = (deviceId) => {
  return {
    type: types.DELETE_DEVICE,
    deviceId
  }
}

export const setDeviceBreadcrumbs = (breadCrumbList) => {
  return {
    type: types.SETDEVICE_BREADCRUMB_LIST,
    breadCrumbList
  };
}

const initialState = fromJS({
  allDeviceList: [],
  allCMDeviceList: [],
  currentDevice: {},
  devicebreadcrumbList: []
})

const device = (state = initialState, action) => {

  switch (action.type) {
    case types.DEVICE_LIST_SUCCESS: {
      return state.set('allDeviceList', action.deviceList)
    }
    case types.DEVICE_CM_LIST_SUCCESS: {
      return state.set('allCMDeviceList', action.deviceList)
    }
    case types.Current_Device: {
      return state.set('currentDevice', action.device)
    }
    case types.SETDEVICE_BREADCRUMB_LIST: {
      return state.set("devicebreadcrumbList",action.breadCrumbList);
    }
    default: return state
  }
}

export default device
