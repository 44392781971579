import React, { Component } from "react";
import axios from "axios";
import { Button, Form, Input, Table, Select, DatePicker, Tooltip, Row, Col, } from "antd";
import moment from "moment";
import localStorage from "../../../utils/localStorage";
//import { StyledDashboard } from "../../DashboardPage/styles";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import NoRecordComponent from "./NoRecords"
import { getUserDetails } from "../../../selectors/layout";
import { StyledDashboard } from "./styles.js";
import { reportTableCall, getDataSourceFromBackend, _saveReportAPI, } from "../ReportCalls";
import { getISOReportNametoDownload, getISOPdfNametoDownload, } from "../ReportCalls";
import "../style.css";
import { Fragment } from "react";
import SelectComponent from "./SelectComponent";
import { formatCountdown } from "antd/lib/statistic/utils";
const { Option } = Select;

class ISOReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      datasource: [],
      childColumns: [],
      childDatasource: [],
      tableConfiguration: {},
      isoSelectionWidget: [],
      api: {},
      childApi: {},
      reportNames: [],
      selectionPayload: {},
      userId: props.userDeatils,
      date: moment(),
      shift: "",
      equipment: "",
      reportName: "",
      pdfName: "",
      adhocColumns: [],
      adhocCurrentDatasource: [],
      weekSummaryDatasource: [],
      // monthSummaryDatasource: [],
      showReport: false,
      headerData: [],
      footerData: []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.UserDetails !== this.props.UserDetails) {
      this.setState({
        userId: this.props.UserDetails,
        columns: [],
        childColumns: [],
        datasource: [],
        weekSummaryDatasource: [],
        monthSummaryDatasource: [],
        adhocColumns: [],
        adhocCurrentDatasource: [],
        headerData: [],
        footerData: []
      });
    }
  }
  componentWillMount = () => {
    this._getReportNames();
  };
  _getReportNames = () => {
    const SiteId = localStorage.get("currentSite");
    const accessToken = localStorage.get("accessToken");
    const deviceTypeObject = {
      method: "POST",
      url: `/api/report/reportList`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        SiteId,
        ReportType: "ISOReport",
      },
    };
    axios(deviceTypeObject)
      .then((response) => {
        let reportNames = [];
        response.data.map((item) => {
          reportNames.push(item.ReportName);
        });
        this.setState({
          reportNames,
        });
      })
      .catch(function () { });
  };

  onChange = async (reportName) => {
    this.setState({
      date: moment(),
      shift: "",
      equipment: "",
      reportName: "",
      pdfName: "",
      headerData: [],
      footerData: [],
      selectionPayload: {}
    });
    let reportJSON = await reportTableCall(reportName, "ISOReport");
    this.setState({
      showReport: false,
      reportJSON: reportJSON,
      columns: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.columns ? reportJSON[0].JSON.columns : [],
      childColumns: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.childTable && reportJSON[0].JSON.childTable && reportJSON[0].JSON.childTable[0] && reportJSON[0].JSON.childTable[0].columns ? reportJSON[0].JSON.childTable[0].columns : [],
      weekSummaryColumns: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.weekSummaryTable && reportJSON[0].JSON.weekSummaryTable && reportJSON[0].JSON.weekSummaryTable[0] && reportJSON[0].JSON.weekSummaryTable[0].columns ? reportJSON[0].JSON.weekSummaryTable[0].columns : [],
      tableColumns: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.table && reportJSON[0].JSON.table.columns ? reportJSON[0].JSON.table.columns : {},
      monthlySummaryColumns: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.monthlySummaryTable && reportJSON[0].JSON.monthlySummaryTable && reportJSON[0].JSON.monthlySummaryTable[0] && reportJSON[0].JSON.monthlySummaryTable[0].columns ? reportJSON[0].JSON.monthlySummaryTable[0].columns : [],
      tableName: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.table && reportJSON[0].JSON.table.name ? reportJSON[0].JSON.table.name : "",
      tableConfiguration: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.tableConfiguration ? reportJSON[0].JSON.tableConfiguration : {},
      childTable: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.childTable,
      isoSelectionWidget: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.isoSelectionWidget ? reportJSON[0].JSON.isoSelectionWidget : [],
      api: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.api ? reportJSON[0].JSON.api : {},
      childApi: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.childTable && reportJSON[0].JSON.childTable[0] && reportJSON[0].JSON.childTable[0].api ? reportJSON[0].JSON.childTable[0].api : {},
      weekApi: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.weekSummaryTable && reportJSON[0].JSON.weekSummaryTable[0] && reportJSON[0].JSON.weekSummaryTable[0].api ? reportJSON[0].JSON.weekSummaryTable[0].api : {},
      monthApi: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.monthlySummaryTable && reportJSON[0].JSON.monthlySummaryTable[0] && reportJSON[0].JSON.monthlySummaryTable[0].api ? reportJSON[0].JSON.monthlySummaryTable[0].api : {},
      tableApi: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON && reportJSON[0].JSON.table && reportJSON[0].JSON.table && reportJSON[0].JSON.table.api ? reportJSON[0].JSON.table.api : {},
      downloadXLXS: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.tableConfiguration ? reportJSON[0].JSON.tableConfiguration.downloadXLXS : false,
      downloadExcelPayload: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.tableConfiguration ? reportJSON[0].JSON.tableConfiguration.downloadExcelPayload : {},
      downloadPdf: reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.tableConfiguration ? reportJSON[0].JSON.tableConfiguration.downloadPdf : false,
      downloadPdfPayload: reportJSON && reportJSON[0].JSON && reportJSON[0].JSON.tableConfiguration ? reportJSON[0].JSON.tableConfiguration.downloadPdfPayload : {},
      topHeader: reportJSON && Array.isArray(reportJSON) && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.topHeader,
      header: reportJSON && Array.isArray(reportJSON) && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.header,
      footer: reportJSON && Array.isArray(reportJSON) && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.footer ? reportJSON && Array.isArray(reportJSON) && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.footer : [],
      remarksFooter: reportJSON && Array.isArray(reportJSON) && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.remarksFooter,
      legend: reportJSON && Array.isArray(reportJSON) && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.legend,
      signatures: reportJSON && Array.isArray(reportJSON) && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.signatures,
      reportType: reportJSON && Array.isArray(reportJSON) && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.reportType && reportJSON[0].JSON.reportType,
    });
  };

  onSelectChange = (optionObject, value, datString) => {
    let { selectionPayload } = this.state;
    selectionPayload = {
      ...selectionPayload,
      [optionObject.key]: value,
    };
    if (optionObject.key == "date") {
      this.setState({ date: moment(datString) });
    } else if (optionObject.key == "equipment") {
      this.setState({ equipment: value });
    } else {
      this.setState({ shift: value });
    }
    this.setState({
      selectionPayload,
      datasource: []
    });
  };
  renderSelectionWidgets = (widgetObject) => {
    switch (widgetObject.widget) {
      case "select":
        return (
          <SelectComponent style={{ width: 300 }} column={widgetObject} record={{ key: widgetObject }} text={widgetObject && widgetObject.key && widgetObject.key == "shift" ? this.state.shift : this.state.equipment} handleFieldChange={(e) => this.onSelectChange(widgetObject, e)} />
        );
      case "date-picker":
        return (
          <DatePicker style={{ width: 300 }} onChange={(e, dateString) => this.onSelectChange(widgetObject, dateString, e)} allowClear={false} disabledDate={this.disabledDate} format="YYYY-MM-DD" value={this.state.date} />
        );
      default:
        return;
    }
  };
  onReportGenerate = async () => {
    let {
      api, childApi, weekApi, monthApi, selectionPayload, reportJSON, childTable, columns,
      childColumns, adhocColumns, reportType, weekSummaryColumns, monthlySummaryColumns, weekSummaryDatasource,
      monthSummaryDatasource, footer, remarksFooter, legend, signatures, header, topHeader, tableApi, tableName, tableColumns
    } = this.state;
    let reportName = "";
    let pdfName = "";
    let adhocDatasource = [];


    let table = {
      ["1"]: [],
      ["2"]: [],
      ["3"]: [],
      ["4"]: []
    }
    if (selectionPayload && selectionPayload.date === undefined) {
      selectionPayload.date = moment().format("YYYY-MM-DD")
    } else {

    }
    let apiObject = {
      ...api,
      payload: {
        ...api.payload,
        ...selectionPayload,
      },
    };
    let childApiObject = {
      ...childApi,
      payload: {
        ...childApi.payload,
        ...selectionPayload,
      },
    };
    let weekApiObject = {
      ...weekApi,
      payload: {
        ...weekApi.payload,
        ...selectionPayload,
      },
    };
    let monthApiObject = {
      ...monthApi,
      payload: {
        ...monthApi.payload,
        ...selectionPayload,
      },
    };
    let tableApiObject = {
      ...tableApi,
      payload: {
        ...tableApi.payload,
        ...selectionPayload,
      },
    };
    let datasource = []
    let childDatasource = []
    let weekDatasource = []
    let monthDatasorce = []
    if (columns && Array.isArray(columns) && apiObject) {
      datasource = await getDataSourceFromBackend(apiObject);
      this.setState({ datasource })
    } else {
      this.setState({ datasource })
    } if (childColumns && Array.isArray(childColumns) && childApiObject) {
      childDatasource = await getDataSourceFromBackend(childApiObject);
      this.setState({ childDatasource })
    } else {
      this.setState({ childDatasource })
    }
    if (weekSummaryColumns && Array.isArray(weekSummaryColumns) && weekApiObject) {
      weekDatasource = await getDataSourceFromBackend(weekApiObject);
      this.setState({ weekSummaryDatasource: weekDatasource })
    } else {
      this.setState({ weekSummaryDatasource: weekDatasource })
    }
    if (monthlySummaryColumns && Array.isArray(monthlySummaryColumns) && monthApiObject) {
      monthDatasorce = await getDataSourceFromBackend(monthApiObject);
      this.setState({ monthSummaryDatasource: monthDatasorce })
    }
    else {
      this.setState({ monthSummaryDatasource: monthDatasorce })
    }
    if (reportJSON && Array.isArray(reportJSON) && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.reportType && reportJSON[0].JSON.reportType === "adhoc") {
      adhocDatasource = await getDataSourceFromBackend(apiObject);
      this.setState({ adhocColumns: adhocDatasource.columns, adhocCurrentDatasource: adhocDatasource.dataSource })
      let json = reportJSON[0].JSON;
      json.columns = adhocDatasource.columns;
      let Data = await getDataSourceFromBackend(tableApiObject)
      let tableData = Data[0]
      tableColumns && tableColumns["2"] && Array.isArray(tableColumns["2"]) && tableColumns["2"].map((item) => {
        if (item && item !== "") {
          table["1"].push(tableData && tableData[item])
        } else {
          table["1"].push("")
        }
      })
      tableColumns && tableColumns["3"] && Array.isArray(tableColumns["3"]) && tableColumns["3"].map((item) => {
        if (item && item !== "") {
          table["4"].push(tableData && tableData[item])
        } else {
          table["4"].push("")
        }
        table["2"] = tableColumns["2"]
        table["3"] = tableColumns["3"]
      })
      this.setState({ table })
      let reportJSONUpdated = {
        JSON: json,
        tabName: reportJSON[0].TabName,
        reportType: "ISOReport",
        reportName: apiObject.reportName,
        createdBy: this.state.userId,
        type: "update",
        // "SiteId": "1"
      };
      let status = await _saveReportAPI(reportJSONUpdated);
    }
    this.setState({
      reportJSON: reportJSON,
      adhocColumns: adhocDatasource && adhocDatasource.columns,
      adhocCurrentDatasource: adhocDatasource && adhocDatasource.dataSource,
    });
    let data = datasource && Array.isArray(datasource) && datasource[0] && datasource[0]
    if (header && Array.isArray(header) && header.length !== 0 && footer && Array.isArray(footer) && footer.length !== 0) {
      datasource && Array.isArray(datasource) && datasource.map((item) => {
        if (item && item.Kiln && item.Kiln !== null) {
          data = item
        }
      })
    }
    let legends = []
    let topHeaders = {}
    let remarks = {}
    let downloadHeaderPayload = {}
    downloadHeaderPayload = {
      ["mainHeader"]: selectionPayload,
    }
    let signaturesPayload = []
    let headerData = []
    let footerData = []
    header && Array.isArray(header) && header.length !== 0 && header.map((headerItem, index) => {
      if (reportType && reportType === "adhoc") {
        let item = {}
        downloadHeaderPayload = {
          ["mainHeader"]: {
            ...selectionPayload,
            [headerItem.key]: adhocDatasource && adhocDatasource.dataSource && adhocDatasource.dataSource[0] &&
              adhocDatasource.dataSource[0].header && adhocDatasource.dataSource[0].header[headerItem.value]
          },
          [tableName]: table

        }
        item["value"] = adhocDatasource && adhocDatasource.dataSource && adhocDatasource.dataSource[0] &&
          adhocDatasource.dataSource[0].header && adhocDatasource.dataSource[0].header[headerItem.value];
        item["key"] = headerItem.key
        headerData.push(item)
      }
      else {
        let item = {}
        if (data && data[headerItem.value]) {
          item["value"] = data && data[headerItem.value]
        } else {
          item["value"] = ""
        }
        item["key"] = headerItem.key
        item["parameter"] = headerItem && headerItem.parameter
        headerData.push(item)
      }
      this.setState({ headerData })
    })
    footer && Array.isArray(footer) && footer.length !== 0 && footer.map((footItem) => {
      let Item = {}
      if (data && data[footItem.value]) {
        Item["value"] = data && data[footItem.value]

      } else {

        Item["value"] = ""
      }
      Item["key"] = footItem.key
      footerData.push(Item)
      this.setState({ footerData })
    })

    remarksFooter && Array.isArray(remarksFooter) && remarksFooter.length !== 0 && remarksFooter.map((remarkItem) => {
      if (data && data[remarkItem.value]) {
        remarks["remarks"] = data && data[remarkItem.value]
      } else {
        remarks["remarks"] = ""
      }
      this.setState({ remarks })
    })

    legend && Array.isArray(legend) && legend.length !== 0 && legend.map((legendItem) => {
      // if(legendItem.key && legendItem.value){
      if (legendItem.key && legendItem.key !== "") {
        legends.push(legendItem.key + " : " + legendItem.value)
      } else {
        legends.push(legendItem.value)
      }
    })
    //  topHeader["Date"] =moment().format("DD/MM/YYYY") 
    this.setState({ topHeader: topHeader })
    signatures && Array.isArray(signatures) && signatures.length !== 0 && signatures.map((sigItem) => {
      if (sigItem.key && sigItem.key !== "") {
        signaturesPayload.push(sigItem.key + " : " + sigItem.value)
      } else {
        signaturesPayload.push(sigItem.value)
      }
    })
    if (reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload && reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload.topHeaderType && reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload.topHeaderType === "true") {
      downloadHeaderPayload = {
        ...downloadHeaderPayload,
        ["topHeader"]: topHeader
      }
    } else {
      downloadHeaderPayload = {
        ...downloadHeaderPayload,
        // ["headers"]:[]

      }
    }
    if (reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload && reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload.headersType && reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload.headersType === "true" && reportType !== "adhoc") {
      downloadHeaderPayload = {
        ...downloadHeaderPayload,
        ["headers"]: headerData && headerData
      }
    } else {
      downloadHeaderPayload = {
        ...downloadHeaderPayload,
        // ["headers"]:[]

      }
    }
    if (reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload && reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload.footersType && reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload.footersType === "true") {
      downloadHeaderPayload = {
        ...downloadHeaderPayload,
        ["footers"]: footerData &&
          footerData
      }
    } else {
      downloadHeaderPayload = {
        ...downloadHeaderPayload,
      }
    }
    if (reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload && reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload.legendsType && reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload.legendsType === "true") {
      downloadHeaderPayload = {
        ...downloadHeaderPayload,
        ["legends"]: legends && legends
      }
    }
    else {
      downloadHeaderPayload = {
        ...downloadHeaderPayload,

        // ["legends"]:[]
      }
    }
    if (reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload && reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload.signatureType && reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload.signatureType === "true") {
      downloadHeaderPayload = {
        ...downloadHeaderPayload,
        ["signatures"]: signaturesPayload && signaturesPayload
      }
    }
    else {
      downloadHeaderPayload = {
        ...downloadHeaderPayload,
      }
    }
    if (reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload && reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload.remarksType && reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload.remarksType === "true") {
      downloadHeaderPayload = {
        ...downloadHeaderPayload,
        ["remarks"]: remarks.remarks
      }
    }
    else {
      downloadHeaderPayload = {
        ...downloadHeaderPayload,
      }
    }
    if (reportJSON && reportJSON && reportJSON[0].JSON && reportJSON[0].JSON.tableConfiguration && reportJSON[0].JSON.tableConfiguration.downloadPdf && ((reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.tableConfiguration.downloadPdf === true) || (reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdf === "true"))) {
      if (reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload && reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload.childTable && reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload.childTable === "true" && childTable && childTable[0] && childTable[0].columns) {
        pdfName = await getISOPdfNametoDownload(
          columns,
          datasource,
          reportJSON[0].JSON.tableConfiguration.downloadPdfPayload,
          childColumns,
          childDatasource,
          weekDatasource, weekSummaryColumns, monthSummaryDatasource, monthlySummaryColumns,
          downloadHeaderPayload
        );
      } else {
        let adhoccolumns = adhocDatasource && adhocDatasource.columns
        let datas = adhocDatasource && adhocDatasource.dataSource
        {
          reportType && reportType === "adhoc"
            ?

            pdfName = await getISOPdfNametoDownload(
              adhoccolumns,
              datas,
              reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload, [], [], [], weekSummaryColumns, monthSummaryDatasource, monthlySummaryColumns,
              downloadHeaderPayload
            )
            :
            (pdfName = await getISOPdfNametoDownload(
              columns,
              datasource,
              reportJSON && reportJSON[0].JSON.tableConfiguration.downloadPdfPayload, [], [], [], weekSummaryColumns, monthSummaryDatasource, monthlySummaryColumns, downloadHeaderPayload
            ));
        }


      }
      this.setState({
        pdfName: pdfName && pdfName.file ? pdfName.file : "",
      });
    } else {
      // this.setState({
      //  weekSummaryDatasource,
      // });
    }

    if (reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.tableConfiguration && reportJSON[0].JSON.tableConfiguration.downloadXLXS && (reportJSON[0].JSON.tableConfiguration.downloadXLXS === true || reportJSON[0].JSON.tableConfiguration.downloadXLXS === "true")) {
      if (reportJSON && reportJSON[0] && reportJSON[0].JSON && reportJSON[0].JSON.tableConfiguration.downloadExcelPayload && reportJSON[0].JSON.tableConfiguration.downloadExcelPayload.childTable && (reportJSON[0].JSON.tableConfiguration.downloadExcelPayload.childTable === "true" || reportJSON[0].JSON.tableConfiguration.downloadExcelPayload.childTable === true) && childTable && childTable[0] && childTable[0].columns) {
        reportName = await getISOReportNametoDownload(
          columns,
          datasource,
          reportJSON[0].JSON.tableConfiguration.downloadExcelPayload,
          childColumns,
          childDatasource,
          weekSummaryColumns, weekDatasource, monthlySummaryColumns, monthDatasorce,
          downloadHeaderPayload
        );

      } else {

        let adhoccolumns = adhocDatasource && adhocDatasource.columns
        let data = adhocDatasource && adhocDatasource.dataSource
        {
          reportType && reportType === "adhoc"
            ? reportName = await getISOReportNametoDownload(
              adhoccolumns,
              data,
              reportJSON[0].JSON.tableConfiguration.downloadExcelPayload, [], [], [], weekSummaryColumns, monthSummaryDatasource, monthlySummaryColumns,
              downloadHeaderPayload
            ) : (reportName = await getISOReportNametoDownload(
              columns,
              datasource,
              reportJSON[0].JSON.tableConfiguration.downloadExcelPayload, [], [], [], weekSummaryColumns, monthSummaryDatasource, monthlySummaryColumns, downloadHeaderPayload
            ));
        }
      }
      this.setState({
        reportName: reportName && reportName.file ? reportName.file : "",
      });
    } else {
    }
    this.setState({
      pdfName: pdfName && pdfName.file ? pdfName.file : "",
      reportName: reportName && reportName.file ? reportName.file : "",
      showReport: true,
      downloadHeaderPayload
    });

  };
  onChangeRemarks = (e) => {
    this.setState({
      remarks: e.target.value,
    });
  };
  equipmentListRender = (table, widgetObject) => {
    const accessToken = localStorage.get("accessToken");
    const siteId = localStorage.get("currentSite");
    let objectHeader = {};
    if (table && Array.isArray(table)) {
      this.setState({
        optionList: table,
      });
      return table;
    }
    if (table && table.url && !table.method) {
      objectHeader = {
        //URL,GET
        method: "GET",
        url: `/api/${table.url}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
    } else if (
      table &&
      table.url &&
      table.method &&
      table.payload &&
      table.method === "POST"
    ) {
      objectHeader = {
        method: table.method,
        url: `${table.url}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          ...table.payload,
          SiteId: siteId,
          // conditionList: conditionList ? conditionList : []
        },
      };
    } else {
      //Table Name
      objectHeader = {
        method: "POST",
        url: `/api/master/dropdown`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          tableName: table,
        },
      };
    }
    axios(objectHeader)
      .then((response) => {
        if (Array.isArray(response.data)) {
          let optionList = response.data;
          // this.setState({
          //     optionList
          // })
          let optionData = [];
          return (
            <Select showSearch style={{ width: 200 }} placeholder={widgetObject.placeholder} onChange={(e) => this.onSelectChange(widgetObject, e)} > {" "} {optionList.map((item) => { return <Option value={item.Equipment}>{item.Equipment}</Option>; })} </Select>
          );
        }
      })
      .catch((error) => {
        return [];
      });
  };
  disabledDate = (current) => {
    return current > moment().endOf("day");
  };
  render() {
    const {
      formLayout, columns, childColumns, datasource, childDatasource, tableConfiguration, isoSelectionWidget, reportNames, downloadXLXS, reportName, pdfName, downloadPdf, adhocColumns, reportType, remarks,
      adhocCurrentDatasource, headerData, showReport, downloadHeaderPayload, table, footer, tableName, footerData, remarksFooter, legend, weekSummaryDatasource, monthSummaryDatasource, weekSummaryColumns, monthlySummaryColumns } = this.state;
    let mainHeader = downloadHeaderPayload && downloadHeaderPayload.mainHeader
    const formItemLayout =
      formLayout === "horizontal"
        ? {
          // labelCol: { span: 4 },
          // wrapperCol: { span: 14 },
        }
        : null;
    const buttonItemLayout =
      formLayout === "horizontal"
        ? {
          wrapperCol: { span: 14, offset: 4 },
        }
        : null;

    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        <b>
        </b>

        <div style={{ color:"white" }}>
          <Form>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label={<span style={{ fontWeight: "bold", color: "white" }}>Form Name</span>} {...formItemLayout}>
                  <Select showSearch style={{ width: 370 }} placeholder="Form Name" optionFilterProp="children" onChange={this.onChange} filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} >
                    {reportNames && Array.isArray(reportNames) && reportNames.map((report) => {
                      return <Option value={report}>{report}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {isoSelectionWidget && Array.isArray(isoSelectionWidget) ? isoSelectionWidget.map((widgetObject) => {
                return widgetObject.displayWidget === "true" ? (
                  <Col span={5}>
                    <Form.Item label={<span style={{ fontWeight: "bold", color: "white" }}>{widgetObject.label}</span>} {...formItemLayout}>
                      {this.renderSelectionWidgets(widgetObject)}
                    </Form.Item>
                  </Col>
                ) : null;
              })
                : null}
              <Col span={2} >
                <Form.Item {...buttonItemLayout} name=" " style={{ marginTop: "40px" }}>
                  <Button type="primary" onClick={this.onReportGenerate} shape="round" style={{ background: "#124b84", borderColor: "rgb(18, 116, 215)" }}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        {showReport && showReport === true ?
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              {downloadXLXS && reportName ? (
                <Tooltip placement="top" title={"Click to Download Excel"}>
                  <a href={`https://bpt.bmecomosolution.com/api/reports/${reportName}`}>
                    {" "}
                    <Button type="primary" shape="round" icon="download" size="medium" style={{ background: "green" }} >
                      Excel Download
                    </Button>
                  </a>
                </Tooltip>
              ) : null}
              {downloadPdf && pdfName ? (
                <Tooltip placement="top" title={"Click to Download PDF"}>
                  <a href={`https://bpt.bmecomosolution.com/api/${pdfName}`} target="_blank" style={{ marginRight: "5px" }}>
                    <Button type="danger" shape="round" icon="file-pdf" size="medium" >
                      PDF Download
                    </Button>
                  </a>
                </Tooltip>
              ) : null}
            </div>
            <Row>

              {reportType && reportType === "adhoc" ?

                <div>
                  {headerData && Array.isArray(headerData) && headerData.length !== 0 && adhocCurrentDatasource && adhocCurrentDatasource[0] && adhocCurrentDatasource[0]["table"] && adhocCurrentDatasource[0]["table"].length !== 0
                    ? (
                      <Row className="headerForm">
                        <Form
                          style={{ marginLeft: "10px", size: "10px" }}
                          layout={"inline"}
                        >
                          {headerData &&
                            Array.isArray(headerData) &&
                            headerData.map((item) => {
                              return (
                                <Col span={8} className="headerColumn">
                                  <Form.Item label={item.key} {...formItemLayout}>
                                    {/* {datasource &&
                                Array.isArray(datasource) &&
                                datasource[0] &&
                                datasource[0][item.value]} */}

                                    {/* { mainHeader[item.key]} */}
                                    {item.value}
                                  </Form.Item>
                                </Col>
                              );
                            })}
                        </Form>
                      </Row>
                    ) : null}
                </div> : null}
              <div className="mainContainer">
                <span style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center", marginLeft: "32%" }}> {tableName ? tableName : null}</span>
                {reportType && reportType === "adhoc" ? table && Object.keys(table).map((item) => {
                  return <Row className="container">

                    {table && table[item] && table[item].map((key) => {
                      return <Col span={1} className="containerDiv">{key}</Col>

                    })}
                  </Row>
                })
                  : null}
              </div>
              {reportType && reportType === "adhoc" && adhocColumns !== undefined ? adhocColumns && Object.keys(adhocColumns).map((item) => {
                return (
                  <Col span={12}>
                    <div style={{ backgroundColor: "#e4dfdf", padding: "30px", marginTop: "-5px", }} >
                      <Form style={{ marginLeft: "10px", size: "10px" }} layout={"inline"} >
                        <Form.Item label="MODEL :" {...formItemLayout}>
                          {adhocCurrentDatasource[item]["header"]["MODEL"]}
                        </Form.Item>
                        <Form.Item label="SIZE :" {...formItemLayout}>
                          {adhocCurrentDatasource[item]["header"]["SIZE"]}
                        </Form.Item>
                        <Form.Item label="TIME :" {...formItemLayout}>
                          {adhocCurrentDatasource[item]["header"]["TIME"]}
                        </Form.Item>
                      </Form>

                      <Table
                        className="reportChildTable"
                        dataSource={adhocCurrentDatasource && adhocCurrentDatasource[item]["table"]}
                        columns={adhocColumns[item]}
                        bordered={tableConfiguration && tableConfiguration.bordered ? tableConfiguration.bordered : false}
                        size={tableConfiguration && tableConfiguration.tableSize ? tableConfiguration.tableSize : "default"}
                        locale={{ emptyText: tableConfiguration && tableConfiguration.noResultMessage ? tableConfiguration.noResultMessage : "", }}
                        isCursorPointer={tableConfiguration && tableConfiguration.isCursorPointer === true ? "not-allowed" : "pointer"}
                        loading={false}
                        scroll={{ x: 120, y: 550 }}
                        pagination={false}
                        footer={() => (
                          <div style={{ margin: "10px 0px" }}>
                            <div className="remarksText">Remarks :</div>
                            <Input.TextArea
                              value={adhocCurrentDatasource[item] && adhocCurrentDatasource[item]["footer"] && adhocCurrentDatasource[item]["footer"]["remarks"] ? adhocCurrentDatasource[item]["footer"]["remarks"] : null}
                              onChange={(e) => this.onChangeRemarks(e)}
                              autoSize={{ minRows: 2, maxRows: 6 }}
                              style={{ width: "100%" }}
                              maxLength={250}
                            />

                          </div>
                        )}
                      />
                    </div>

                  </Col>
                );
              })
                : reportType === "adhoc" ? <NoRecordComponent /> : null}
            </Row>
            {columns && Array.isArray(columns) ? (
              <Fragment>
                <div style={{ backgroundColor: "#e4dfdf", padding: "40px", marginTop: "20px", }} >
                  {datasource && Array.isArray(datasource) && headerData && Array.isArray(headerData) && headerData.length !== 0 && datasource.length !== 0
                    ? (
                      <Row className="headerForm">
                        <Form style={{ marginLeft: "10px", size: "10px" }} layout={"inline"} >
                          {headerData && Array.isArray(headerData) && headerData.map((item) => {
                            return (
                              <Col span={8} className="headerColumn">
                                <Form.Item label={item.key} {...formItemLayout}>
                                  {item.value} {item.parameter ? ("(" + `${item.parameter}` + ")") : ""}
                                </Form.Item>
                              </Col>
                            );
                          })}
                        </Form>
                      </Row>
                    ) : null}

                  <Table
                    className="reportChildTable"
                    dataSource={datasource}
                    columns={columns}
                    bordered={tableConfiguration && tableConfiguration.bordered ? tableConfiguration.bordered : false}
                    size={tableConfiguration && tableConfiguration.tableSize ? tableConfiguration.tableSize : "default"}
                    locale={{ emptyText: tableConfiguration && tableConfiguration.noResultMessage ? tableConfiguration.noResultMessage : "", }}
                    isCursorPointer={tableConfiguration && tableConfiguration.isCursorPointer === true ? "not-allowed" : "pointer"}
                    loading={false}
                    scroll={{ x: 120, y: 550 }}
                    pagination={false}
                    footer={() => datasource && Array.isArray(datasource) && datasource.length !== 0 && remarks && remarksFooter && Array.isArray(remarksFooter) && remarksFooter.length !== 0 && remarks ?
                      <div style={{ margin: "20px 0px" }}>
                        <div className="remarksText">Remarks :</div>
                        <Input.TextArea
                          value={remarks && Object.keys(remarks).map((item) => { return remarks[item] })}
                          onChange={(e) => this.onChangeRemarks(e)}
                          autoSize={{ minRows: 2, maxRows: 6 }}
                          style={{ width: "100%" }}
                          maxLength={250}
                        />
                      </div> : null
                    }
                  />
                  {datasource && Array.isArray(datasource) && legend && Array.isArray(legend) && legend.length !== 0 && datasource.length !== 0 ? (
                    <Row className="headerForm" style={{ background: "white", fontSize: "12px", padding: "20px" }}>
                      <header style={{ color: "rgb(27, 77, 128)", forntWeight: "bold", marginLeft: "2px", fontSize: "17px", fontWeight: "bold" }}>LEGENDS :</header>
                      <Form layout={"inline"} >
                        {legend && Array.isArray(legend) && legend.map((item) => {
                          return (
                            <Col span={4} className="headerColumn" style={{ color: "#124b84", fontSize: "12px" }}>
                              <b>{item.key}</b> : {item.value}
                            </Col>
                          );
                        })}
                      </Form>
                    </Row>
                  ) : null}

                  {datasource && Array.isArray(datasource) && footer && Array.isArray(footer) && footer.length !== 0 && datasource.length !== 0 ? (
                    <Row className="footerRow">
                      <Form
                        // style={{ marginLeft: "1px", size: "10px" }}
                        layout={"inline"}
                      >
                        {footerData && Array.isArray(footerData) && footerData.length !== 0 && footerData.map((item) => {
                          return (
                            <Col span={8} className="footerCol">
                              <Form.Item label={item.key} {...formItemLayout}>
                                {item && item.value ? item.value : null}
                              </Form.Item>
                            </Col>
                          );
                        })}
                      </Form>
                    </Row>
                  ) : null}
                </div>
                {childColumns && Array.isArray(childColumns) && childColumns.length !== 0 ? (
                  <div style={{ backgroundColor: "#e4dfdf", padding: "40px" }}>
                    <Table
                      className="reportChildTable"
                      dataSource={childDatasource}
                      columns={childColumns}
                      bordered={tableConfiguration && tableConfiguration.bordered ? tableConfiguration.bordered : false}
                      size={tableConfiguration && tableConfiguration.tableSize ? tableConfiguration.tableSize : "default"}
                      locale={{ emptyText: tableConfiguration && tableConfiguration.noResultMessage ? tableConfiguration.noResultMessage : "", }}
                      isCursorPointer={tableConfiguration && tableConfiguration.isCursorPointer === true ? "not-allowed" : "pointer"}
                      loading={false}
                      scroll={{ x: 120, y: 550 }}
                      pagination={false}
                    />
                  </div>
                ) : null}
                {weekSummaryColumns && Array.isArray(weekSummaryColumns) && weekSummaryColumns.length !== 0 ? (
                  <div style={{ backgroundColor: "#e4dfdf", padding: "40px" }}>
                    <Table
                      className="reportChildTable"
                      dataSource={weekSummaryDatasource}
                      columns={weekSummaryColumns}
                      bordered={tableConfiguration && tableConfiguration.bordered ? tableConfiguration.bordered : false}
                      size={tableConfiguration && tableConfiguration.tableSize ? tableConfiguration.tableSize : "default"}
                      locale={{ emptyText: tableConfiguration && tableConfiguration.noResultMessage ? tableConfiguration.noResultMessage : "", }}
                      isCursorPointer={tableConfiguration && tableConfiguration.isCursorPointer === true ? "not-allowed" : "pointer"}
                      loading={false}
                      scroll={{ x: 120, y: 550 }}
                      pagination={false}
                    />
                  </div>
                ) : null}
                {monthlySummaryColumns && Array.isArray(monthlySummaryColumns) && monthlySummaryColumns.length !== 0 ? (
                  <div style={{ backgroundColor: "#e4dfdf", padding: "40px" }}>
                    <Table
                      className="reportChildTable"
                      dataSource={monthSummaryDatasource}
                      columns={monthlySummaryColumns}
                      bordered={tableConfiguration && tableConfiguration.bordered ? tableConfiguration.bordered : false}
                      size={tableConfiguration && tableConfiguration.tableSize ? tableConfiguration.tableSize : "default"}
                      locale={{ emptyText: tableConfiguration && tableConfiguration.noResultMessage ? tableConfiguration.noResultMessage : "", }}
                      isCursorPointer={tableConfiguration && tableConfiguration.isCursorPointer === true ? "not-allowed" : "pointer"}
                      loading={false}
                      scroll={{ x: 120, y: 550 }}
                      pagination={false}
                    />
                  </div>
                ) : null}


              </Fragment>
            ) : null}{" "}
          </div> : <div className="configure">Please Configure For Reports</div>}
      </StyledDashboard>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  UserDetails: getUserDetails(),
});
export default connect(mapStateToProps)(Form.create()(ISOReports));
