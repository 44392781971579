import React from "react";
import axios from "axios";
import { Table, Form, Button, Input, Drawer, Row, Col } from "antd";

import localStorage from "../../../../utils/localStorage";
import { StyledApplication, DrawerFooter,AddTableButton } from '../style'

const FormItem = Form.Item;

class UnitOfMeasure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      UOMList: [],
      currentLanguage: "English",
      UOMObject: {}
    };
  }

  componentWillMount() {
    this._getDeviceParameterFactorList();
  }

  _getDeviceParameterFactorList = () => {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get("accessToken");
    const uomObject = {
      method: "GET",
      url: `/api/site/${siteId}/parameterType/list`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(uomObject)
      .then(response => {
        this.setState({ UOMList: response.data });
      })
      .catch(function() {});
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(err => {
      if (!err) {
      }
    });
  };

  showDrawer = () => {
    this.props.form.resetFields();
    this.setState({
      visible: true,
      UOMObject: {}
    });
  };

  onClose = () => {
    this.props.form.resetFields();
    this.setState({
      visible: false
    });
  };

  resetForm = () => {
    this.props.form.resetFields();
  };

  showDrawerOldDevice = (e, row) => {
    this.props.form.resetFields();
    e.preventDefault();
    this.setState({
      UOMObject: row,
      visible: true
    });
  };

  createDeviceParameterType = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this._saveDeviceParameterType(values);
      }
    });
  };

  updateDeviceParameterFactor = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this._updateDeviceParameterType(values);
      }
    });
  };

  _saveDeviceParameterType = payload => {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get("accessToken");
    const uomObject = {
      method: "POST",
      url: `/api/site/${siteId}/parameterType/create`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(uomObject)
      .then(() => {
        this.setState({ visible: false });
        this._getDeviceParameterFactorList();
      })
      .catch(function() {});
  };

  _updateDeviceParameterType = payload => {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get("accessToken");
    const uomObject = {
      method: "PATCH",
      url: `/api/site/${siteId}/parameterType/update/${payload._key}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(uomObject)
      .then(() => {
        this._getDeviceParameterFactorList();
        this.setState({ visible: false });
      })
      .catch(function() {});
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { UOMList, UOMObject, visible } = this.state;
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "Code",
        dataIndex: "code",
        key: "code"
      }
    ];

    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 338 }}>
        <AddTableButton type="primary" onClick={this.showDrawer}>
          Create Parameter Type
        </AddTableButton>
        <Drawer
          title={
            !UOMObject._key ? "Create Parameter Type" : "Update Parameter Type"
          }
          placement="right"
          closable={true}
          width={500}
          onClose={this.onClose}
          visible={visible}
        >
          <Form layout="vertical" onSubmit={this.handleSubmit}>
            <Row>
              <Col span={24}>
                <FormItem label="Name">
                  {getFieldDecorator("name", {
                    initialValue: UOMObject.name,
                    rules: [
                      { required: true, message: "Please Input Your Name!" }
                    ]
                  })(<Input placeholder="Enter Name" />)}
                  {getFieldDecorator("_id", {
                    initialValue: UOMObject._id
                  })(<Input style={{ display: "none" }} />)}
                  {getFieldDecorator("_key", {
                    initialValue: UOMObject._key
                  })(<Input style={{ display: "none" }} />)}
                  {getFieldDecorator("_rev", {
                    initialValue: UOMObject._rev
                  })(<Input style={{ display: "none" }} />)}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label="Code">
                  {getFieldDecorator("code", {
                    initialValue: UOMObject.code,
                    rules: [
                      { required: true, message: "Please Input Your Code!" }
                    ]
                  })(
                    <Input placeholder="Enter Code" disabled={UOMObject._key} />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row></Row>
          </Form>
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            {UOMObject._key === undefined ? (
              <Button onClick={this.createDeviceParameterType} type="primary">
                Create Parameter Type
              </Button>
            ) : (
              <Button onClick={this.updateDeviceParameterFactor} type="primary">
                Update Parameter Type
              </Button>
            )}
          </DrawerFooter>
        </Drawer>
        <Table
          columns={columns}
          dataSource={UOMList}
          size="large"
          pagination={
            UOMList.length > 5
              ? {
                  pageSize: "5"
                }
              : false
          }
          locale={{ emptyText: "No Data" }}
          onRow={record => ({
            onClick: e => {
              e.stopPropagation();
              this.showDrawerOldDevice(e, record);
            }
          })}
        />
      </StyledApplication>
    );
  }
}

export default Form.create()(UnitOfMeasure);
