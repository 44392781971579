import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import { getConditionList } from '../../../../modules/condition/condition.duck'
import { getConditionListSelector } from '../../../../selectors/condition'
import Conditions from './Conditions'

const mapStateToProps = createStructuredSelector({
  conditionList: getConditionListSelector()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(
      {
        getConditionList
      },
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Conditions)
