import React from 'react';
import { Progress } from 'antd';
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import moment from "moment"
import { Tag } from "antd";

import { getChartDataWithoutSelection, getChartDataWithSelection } from "../ChartCalls";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { progressMock } from "../../Mock/MockJson";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import '../CSS/style.css'
import { defaultRefreshTime } from '../constant.json'
import { Fragment } from 'react';

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percent: 0,
      type: "circle",
      title: "",
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = ""
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange
    ) {
      this.getData();
    }
  }

  // componentWillReceiveProps(props) {
  //     this.getData(props);
  // }

  componentWillUnmount() {
    this.abortController.abort()
    clearInterval(this.timeTicket);
  }

  getData = async () => {
    let json = []
    let { graphprops } = this.props
    let { errorInAPI, noDataInDB } = this.state
    this.requestCheck = false
    errorInAPI = false
    noDataInDB = false

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = progressMock;
      this.requestCheck = true
    } else {
      if (
        (graphprops && graphprops.dashboardType === 'dynamic' && this.props.deviceId) ||
        (graphprops && graphprops.dateExists === 'true' && this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops && graphprops.dateRangeExists === 'true' && this.props.selectionDateRange) ||
        (graphprops && graphprops.dayMonthYearExists === 'true' && this.props.dayMonthYear)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController
        );
        this.requestCheck = true
      } else if (graphprops) {
        json = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true
      }
    }

    if ((json && json.message )|| (Array.isArray(json) && (json.length === 0 &&
      graphprops.enableMock !== true))) {

      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
      }

    
    } 
    else {
      if (json && json[0] && json[0].tagvalue) {
       
        this.setState({
          percent: json[0].tagvalue,
          refreshDateTime: moment()
        })
       
      }
     
      this.setState({
        errorInAPI,
        noDataInDB
      })
    }
  }

  // componentWillUnmount() {
  //   clearInterval(this.timeTicket);
  // }


  render() {
    const { errorInAPI, noDataInDB, refreshDateTime, percent } = this.state;
    let theme = this.props.selectedThemeing;
    let { graphprops } = this.props
    return (
      <Fragment>
         {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <div>           { //Check if message failed
              (this.props.graphprops && this.props.graphprops.barType === 'line')
                ?

                <div className="progressbarline">
                  <Progress strokeWidth={20}
                    strokeColor={{
                      '0%': '#108ee9',
                      '50%': '#87d068',
                    }}
                    style={{ color: this.props.graphprops.titleColor ? this.props.graphprops.titleColor : theme === "lightTheme" ? "black" : "white" }}
                    type={this.props.graphprops.barType}
                    percent={percent}
                    status="active"
                  />
                </div>

                :
                <div className="progressbarcircle">
                  <Progress style={{ color: this.props.graphprops.titleColor }} type={this.props.graphprops.barType} percent={this.state.percent} status="active" />
                </div>
            }

            </div>
        }
      </Fragment>


    );

  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing()
})


export default connect(mapStateToProps)(ProgressBar)
