import React from 'react'
import axios from 'axios'
import { Table, Form, Col, Input, Drawer, Button, Card, Row, Icon, message, Select, Popconfirm, Divider } from 'antd'
import { createStructuredSelector } from "reselect";
import { getCurrentLanguage, getTranslationCache } from '../../../../selectors/language';
import { connect } from "react-redux";
import localStorage from '../../../../utils/localStorage'
import SiteRoleComponents from './SiteRoleComponent'
import constants from '../../../../constants/config.json'
import { DrawerFooter, StyledTable, lightTheme } from '../style'
import { constant } from '../../Constants'
const { Option } = Select

class User extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            datasource: [],
            editObj: {},
            roleEdge: [],
            departmentList: [],
            siteList: [],
            roleList: [],
            translationCache: props.translationCache || [],

        }
    }

    componentWillMount() {
        this._getUserList()
        this._getSiteList()
        this._getDepartmentList()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.translationCache !== this.props.translationCache || prevProps.language !== this.props.language) {
            this.setState({
                translationCache: this.props.translationCache, language: this.props.language
            });
        }
    }

    _getSiteList = () => {
        const accessToken = localStorage.get('accessToken')
        let orgHeaders = {
            method: 'GET',
            url: `/api/site/list`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
        axios(orgHeaders)
            .then((response) => {
                this.setState({ siteList: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    _getDepartmentList = () => {
        const accessToken = localStorage.get('accessToken')
        let headers = {
            method: 'GET',
            url: `/api/department/list`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
        axios(headers)
            .then(({ data }) => {

                this.setState({ departmentList: data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    _getRolesList = (siteId) => {
        const accessToken = localStorage.get('accessToken')
        let orgHeaders = {
            method: 'GET',
            url: `/api/role/list?SiteId=${siteId}`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
        axios(orgHeaders)
            .then((response) => {
                this.setState({ roleList: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    _getUserList = () => {
        const accessToken = localStorage.get('accessToken')
        let orgHeaders = {
            method: 'GET',
            url: `/api/user/list`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
        axios(orgHeaders)
            .then((response) => {

                this.setState({ datasource: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    openModal = () => {
        this.props.form.resetFields()
        this.setState({
            visible: true,
            roleEdge: [],
            editObj: {}
        });
    };

    _createUser = (e) => {
        e.preventDefault();
        let { roleEdge } = this.state
        this.props.form.validateFields(async (error, values) => {
            if (!error) {
                let saveObject = {
                    ...values,
                    "Password": constants.defaultPassword,
                    "ConfirmPassword": constants.defaultPassword
                }
                delete saveObject['RoleId']
                delete saveObject['SiteId']
                let saveObjects = await this._createUserAPI(saveObject)

                if (saveObjects) {
                    for (var i = 0; i < roleEdge.length; i++) {
                        let newObj = {
                            ...roleEdge[i],
                            UserId: saveObjects.Id
                        }
                        this._linkSiteRole(newObj, "create")
                    }
                }

            }
        })

    };

    _updateUser = (e) => {
        e.preventDefault();
        let { roleEdge, editObj } = this.state
        this.props.form.validateFields(async (error, saveObject) => {
            if (!error) {
                saveObject["Id"] = editObj.Id
                delete saveObject['RoleId']
                delete saveObject['SiteId']
                await this._updateUserAPI(saveObject)

                for (var i = 0; i < roleEdge.length; i++) {
                    let newObj = {
                        ...roleEdge[i],
                        UserId: editObj.Id
                    }
                    this._linkSiteRole(newObj, "update")
                }
            }
        })
    };

    _linkSiteRole = (payload, operation) => {
        const accessToken = localStorage.get('accessToken')
        let orgCreateHeaders = {
            method: 'POST',
            url: operation === "create" ? `/api/member/insert` : `/api/member/update`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: payload
        }
        axios(orgCreateHeaders)
            .then(async (response) => {
                await this._getUserList()
                message.success("RoleSite Linked", response);
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    _updateUserAPI = (updateObject) => {
        const accessToken = localStorage.get('accessToken')
        let orgUpdateHeaders = {
            method: 'POST',
            url: `/api/user/update`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: (updateObject)
        }
        return axios(orgUpdateHeaders)
            .then((response) => {
                message.success("Record Updated")
                this._getUserList()
                this.setState({
                    visible: false
                })
                this.props.form.resetFields()
                return response.data
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    _createUserAPI = (createObject) => {
        const accessToken = localStorage.get('accessToken')
        let orgCreateHeaders = {
            method: 'POST',
            url: `/api/user/insert`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: createObject
        }
        return axios(orgCreateHeaders)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    message.success("Record Created")
                    this._getUserList()
                    this.setState({ visible: false });
                    this.props.form.resetFields()
                    return response.data
                } 
            })
            .catch(function (error) {
                message.error(error.response.data.message);
            })
    }

    _cancel = () => {
        this.props.form.resetFields()
        this.setState({
            visible: false,
        });
    };

    editUser = async (editObj) => {
        let roleEdge = await this.getSiteRole(editObj.Id)
        this.setState({
            editObj,
            roleEdge: roleEdge ? roleEdge : [],
            visible: true,
        })
    }

    getSiteRole = (UserId) => {
        const accessToken = localStorage.get('accessToken')
        let headers = {
            method: 'POST',
            url: `/api/member/get`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: {
                UserId
            }
        }
        return axios(headers)
            .then((response) => {
                return response.data
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    _deleteUser = (deleteKey) => {
        let payload = {
            Id: deleteKey
        }
        const accessToken = localStorage.get('accessToken')
        let orgDeleteHeaders = {
            method: 'POST',
            url: `/api/user/delete`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: payload
        }
        axios(orgDeleteHeaders)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    message.success("Department Deleted")
                    this._getUserList()
                    this.setState({ visible: false })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    add = () => {
        let { roleEdge } = this.state;
        if (roleEdge && Array.isArray(roleEdge)) {


            const newObj = {
                "SiteId": "",
                "RoleId": ""
            };
            roleEdge.push(newObj);

        } else {
            roleEdge = []
            const newObj = {
                "SiteId": "",
                "RoleId": ""
            };
            roleEdge.push(newObj);
        }
        this.setState({ roleEdge });
    };


    handleFieldChange = (e, parameter, index) => {
        const { roleEdge } = this.state;
        const arrayUpdated = this.updateArray(
            roleEdge,
            index,
            item => ({ ...item, [parameter]: e })
        );

        this._getRolesList(e)
        this.setState({ roleEdge: arrayUpdated });
    };

    updateArray = (array, index, updateFn) => {
        return [
            ...array.slice(0, index),
            updateFn(array[index]),
            ...array.slice(index + 1)
        ];
    };


    remove = (k, object) => {
        const { roleEdge } = this.state;
        if (!object._key) {
            var filtered = roleEdge.filter(function (value, index) {
                return index !== k
            })
            if (object) {
                this._unlinkSite(object)
            }

            this.setState({ roleEdge: filtered })
        }
    }

    _unlinkSite = (payload) => {
        const accessToken = localStorage.get('accessToken')
        let orgCreateHeaders = {
            method: 'POST',
            url: `/api/member/delete`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: payload
        }
        return axios(orgCreateHeaders)
            .then(() => {
                message.success("Site Unlinked")
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    getData = (list, text, type) => {
        var result = list.filter(obj => {
            return obj.Id === text
        })
        if (result && Array.isArray(result) && result.length > 0 && type === "department") {
            return result[0].Name
        }
        return ""
    }

    translation = (keyword) => {
        let { translationCache } = this.props
        return translationCache &&
            translationCache[keyword] ?
            translationCache[keyword] : keyword
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const { datasource, roleEdge, visible, editObj, departmentList } = this.state

        const formItems = roleEdge && Array.isArray(roleEdge) && roleEdge.map((site, index) =>
            site !== null ? (
                <SiteRoleComponents
                    site={site}
                    index={index}
                    form={this.props.form}
                    handleFieldChange={this.handleFieldChange}
                    remove={this.remove}
                />
            ) : null
        );

        const columns = [
            {
                title: this.translation('First Name'),
                dataIndex: 'FirstName',
                key: 'FirstName',
            }, {
                title: this.translation('Last Name'),
                dataIndex: 'LastName',
                key: 'LastName',
            }, {
                title: this.translation('User Name'),
                dataIndex: 'UserName',
                key: 'UserName',
            }, {
                title: this.translation('Email'),
                dataIndex: 'Email',
                key: 'Email',
            }, {
                title: this.translation('Mobile Number'),
                dataIndex: 'MobileNumber',
                key: 'MobileNumber',
            }, {
                title: this.translation('Department'),
                dataIndex: 'DepartmentId',
                key: 'DepartmentId',
                render: (text, row, key) => {
                    return {
                        children: <span>
                            {
                                this.getData(departmentList, text, "department")
                            }
                        </span>
                    };
                }
            },
            {
                title: this.translation(constant.Action),
                dataIndex: '',
                key: '',
                render: (text, record) => {
                    if (record && record.Email && (record.Email !== "admin@envisionesl.com" && record.Email !== "admin@rubusdigital.com")) {
                        return <div>
                            <button type='button' onClick={() => this.editUser(record)} style={{ marginRight: '10px' }} className='ant-btn' >
                                <Icon type='edit' />
                            </button>
                            <Popconfirm
                                title={this.translation("Are you sure delete User ?")}
                                onConfirm={e => {
                                    e.stopPropagation()
                                    this._deleteUser(record.Id)
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <button type='button' className='ant-btn'>
                                    <Icon
                                        type="delete"
                                        onClick={e => {
                                            e.stopPropagation();
                                        }}
                                    />
                                </button>
                            </Popconfirm>
                        </div>
                    }
                }
            }
        ]

        return (
            <Card bordered={false} >
                <Drawer
                    title="Create User"
                    visible={visible}
                    width={600}
                    closable={false}
                    onClose={this._cancel}
                >
                    <Form layout="vertical">
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label={this.translation(constant.Salutation)}>

                                    {getFieldDecorator(`Salutation`, {
                                        initialValue: editObj.Salutation,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please Select Salutation !',
                                            },
                                        ],
                                    })(<Select defaultValue="Ms" style={{ width: 120 }}>
                                        {
                                            ["Ms", "Mr", "Mrs", "Jr", "Sr"].map((salutation) => {
                                                return <Option value={salutation}>{salutation}</Option>
                                            })
                                        }
                                    </Select>)
                                    }

                                </Form.Item>
                                <Form.Item label={this.translation(constant.First_Name)}>

                                    {getFieldDecorator(`FirstName`, {
                                        initialValue: editObj.FirstName,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input FirstName!',
                                            },
                                        ],
                                    })(<Input placeholder={this.translation(constant.Please_enter_FirstName)} />)
                                    }

                                </Form.Item>
                                <Form.Item label={this.translation(constant.Last_Name)}>
                                    {getFieldDecorator(`LastName`, {
                                        initialValue: editObj.LastName,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input LastName!',
                                            },
                                        ],
                                    })(<Input placeholder={this.translation(constant.Please_enter_LastName)} />)
                                    }
                                </Form.Item>
                                <Form.Item label={this.translation(constant.User_Name)}>
                                    {getFieldDecorator(`UserName`, {
                                        initialValue: editObj.UserName,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input UserName !',
                                            },
                                        ],
                                    })(<Input placeholder={this.translation(constant.Please_enter_UserName)} />)
                                    }
                                </Form.Item>

                                <Form.Item label={this.translation(constant.Email)}>
                                    {getFieldDecorator(`Email`, {
                                        initialValue: editObj.Email,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input Email !',
                                            },
                                        ],
                                    })(<Input placeholder={this.translation(constant.Please_enter_Email)} />)
                                    }
                                </Form.Item>
                                <Form.Item label={this.translation(constant.Mobile_Number)}>
                                    {getFieldDecorator(`MobileNumber`, {
                                        initialValue: editObj.MobileNumber,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input MobileNumber !',
                                            },
                                        ],
                                    })(<Input placeholder={this.translation(constant.Please_enter_MobileNumber)} />)
                                    }
                                </Form.Item>

                                <Form.Item label={this.translation(constant.Department)}>
                                    {getFieldDecorator(`DepartmentId`, {
                                        initialValue: editObj.DepartmentId,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please select Department !',
                                            },
                                        ],
                                    })(<Select >
                                        {
                                            departmentList.map((value) => {
                                                return <Option value={value.Id}>{value.Name}</Option>
                                            })
                                        }
                                    </Select>)}
                                </Form.Item>
                            </Col>

                            <div>
                                <Divider>{this.translation("SiteRole")}</Divider>
                                <Form.Item>
                                    <Button type="dashed" onClick={this.add}>
                                        <Icon type="plus" />{this.translation(constant.Add_Item)}
                                    </Button>
                                </Form.Item>
                                {formItems}
                            </div>

                        </Row>
                    </Form>
                    <DrawerFooter>
                        <Button onClick={this._cancel} style={{ marginRight: 8 }}>{this.translation("Cancel")}</Button>
                        {
                            !editObj.Id ?
                                <Button type="primary" onClick={this._createUser}>{this.translation(constant.Create_User)}</Button>
                                : <Button type="primary" onClick={this._updateUser}>{this.translation(constant.Update_User)}</Button>
                        }
                    </DrawerFooter>
                </Drawer>
                <Button type="primary" size={"large"} style={{ marginBottom: '10px', float: "right", }} onClick={this.openModal}>
                    {this.translation(constant.Add_User)}
                </Button>
                <StyledTable theme={lightTheme}>
                    <Table
                        columns={columns}
                        dataSource={datasource}
                        pagination={
                            datasource.length > 10
                                ? {
                                    pageSize: "10"
                                }
                                : false
                        }
                    />

                </StyledTable>
            </Card>

        )
    }
}
const mapStateToProps = createStructuredSelector({
    language: getCurrentLanguage(),
    translationCache: getTranslationCache(),
});

export default connect(mapStateToProps)(Form.create()(User));

