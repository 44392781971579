import React from "react";
import {
    Modal,
    Tabs,
    Input,
    Form,
    Button,
    Select
} from "antd";

const { TabPane } = Tabs
const { Option } = Select
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
    },
};
class DashboardLevelConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dropdownObject: {

            }
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { dropdownObject } = this.state
                dropdownObject[values.key] = {
                    ...values
                }
                this.setState({ dropdownObject })

            }
        });
    };

    hasErrors = (fieldsError) => {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    render() {
        


        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        let { dropdownObject } = this.state
        const nameError = isFieldTouched('name') && getFieldError('name');
        const keyError = isFieldTouched('key') && getFieldError('key');
        return (
            <Modal
                width={700}
                title="Dashboard Header Configuration"
                visible={this.state.visible}
                onOk={() => {
                    // this.props.onOk(this.state);
                    this.setState({});
                }}
                onCancel={() => { }}
            >
                <Tabs >
                    <TabPane tab="Multi Dropdown" key="1">
                        <Form layout="inline" onSubmit={this.handleSubmit}>
                            <Form.Item
                                validateStatus={nameError ? 'error' : ''} help={nameError || ''}
                            >
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: 'Please input your Name!' }],
                                })(
                                    <Input placeholder="Name" />
                                )}
                            </Form.Item>
                            <Form.Item
                                validateStatus={keyError ? 'error' : ''} help={keyError || ''}
                            >
                                {getFieldDecorator('key', {
                                    rules: [{ required: true, message: 'Please input your key!' }],
                                })(
                                    <Input placeholder="Key" />
                                )}
                            </Form.Item>
                            <Form.Item >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={this.hasErrors(getFieldsError())}
                                >
                                    Add
                                    </Button>

                            </Form.Item>
                            </Form>
                            {
                                dropdownObject && Object.keys(dropdownObject).length > 0 &&
                                Object.keys(dropdownObject).map((dropdownName) => {
                                    return <div>

                                        {dropdownObject[dropdownName].name}
                                        <Form {...formItemLayout}>
                                            <Form.Item
                                                label={"Show Dropdown"}
                                            >
                                                <Input placeholder="dropdown" width="300" />
                                            </Form.Item>
                                            <Form.Item
                                                label={"Place holder"}
                                            >
                                                <Input placeholder="Placeholder" width="300" />
                                            </Form.Item>
                                            <Form.Item
                                                label={"Request Input"}
                                            >
                                                <Select placeholder="Select Input Type" style={{ width: '300' }} >
                                                    <Option value="array">Array</Option>
                                                    <Option value="object">Object</Option>
                                                    <Option value="string">String</Option>
                                                </Select>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                })
                            }
                       

                    </TabPane>
                </Tabs>

            </Modal>
        );
    }



}

export default Form.create()(DashboardLevelConfig);
