export const types = {

  GET_LOGGEDIN_PROFILE: '@/GET_LOGGEDIN_PROFILE',
  GET_LOGGEDIN_PROFILE_SUCCESS: '@/GET_LOGGEDIN_PROFILE_SUCCESS',

  SET_MENU_SELECTION: '@/SET_MENU_SELECTION',

  SET_CURRENTSITE: '@/SET_CURRENTSITE',
  SET_MENUHIERARCHY:'@/SET_MENUHIERARCHY',
  SET_LandingPage: "@/SET_LandingPage",
  SET_UserDetails:"@/SET_UserDetails",

  SET_MenuCollapse: "@/SET_MenuCollapse"
}
