import React from 'react'
import axios from 'axios'
import { Table, Form, Col, Input, Drawer, Button, Card, Row, message, Icon, Popconfirm } from 'antd'
import localStorage from '../../../../utils/localStorage'
import { DrawerFooter, StyledTable, lightTheme } from '../style'
import { createStructuredSelector } from "reselect";
import { getCurrentLanguage, getTranslationCache } from '../../../../selectors/language';
import { connect } from "react-redux";

class LandingPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            datasource: [],
            editObj: {},
            translationCache: props.translationCache || [],
        }
    }

    componentWillMount() {
        this._getLandingPageList()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.translationCache !== this.props.translationCache || prevProps.language !== this.props.language) {
            this.setState({
                translationCache: this.props.translationCache, language: this.props.language
            });
        }
    }

    _getLandingPageList = () => {
        const accessToken = localStorage.get('accessToken')
        let headers = {
            method: 'GET',
            url: `/api/landingPage/list`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
        axios(headers)
            .then(({ data }) => {
                this.setState({ datasource: data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    openModal = () => {
        this.props.form.resetFields()
        this.setState({
            visible: true,
            editObj: {}
        });
    };

    _cancel = () => {
        this.props.form.resetFields()
        this.setState({
            visible: false,
        });
    };

    editLandingPage = (editObj) => {
        this.props.form.resetFields()
        this.setState({
            editObj,
            visible: true,
        })
    }

    _createLandingPage = (e) => {
        e.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (!error) {
                this._createLandingPageAPI(values)
            }
        })
    };

    _updateLandingPage = (e) => {
        e.preventDefault();
        let { editObj } = this.state
        // this.props.form.resetFields()
        this.props.form.validateFields((error, values) => {
            if (!error) {
                let updateObject = {
                    ...values,
                    Id: editObj.Id
                }
                this._updateLandingPageAPI(updateObject)
            }
        })
    };

    _deleteLandingPage = (deleteKey) => {
        let payload = {
            Id: deleteKey
        }
        const accessToken = localStorage.get('accessToken')
        let orgDeleteHeaders = {
            method: 'POST',
            url: `/api/landingPage/delete`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: payload
        }
        axios(orgDeleteHeaders)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    message.success("LandingPage Deleted")
                    this._getLandingPageList()
                    this.setState({ visible: false })
                }
            })
            .catch(function (error) {
                console.log(error)
            })

    }

    _createLandingPageAPI = (createObject) => {
        const accessToken = localStorage.get('accessToken')
        let orgCreateHeaders = {
            method: 'POST',
            url: '/api/landingPage/insert',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: createObject
        }
        axios(orgCreateHeaders)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    message.success("LandingPage Saved")
                    this._getLandingPageList()
                    this.setState({ visible: false })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    _updateLandingPageAPI = (updateObject) => {
        const accessToken = localStorage.get('accessToken')
        let orgUpdateHeaders = {
            method: 'POST',
            url: `/api/landingPage/update`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: updateObject
        }
        axios(orgUpdateHeaders)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    message.success("LandingPage Updated")
                    this._getLandingPageList()
                    this.setState({ visible: false })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    translation = (keyword) => {
        let { translationCache } = this.props
        return translationCache &&
            translationCache[keyword] ?
            translationCache[keyword] : keyword
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const { datasource, visible, editObj } = this.state

        const columns = [{
            title: this.translation('Landing Page Name'),
            dataIndex: 'Name',
        }, {
            title: this.translation('LandingPage Code'),
            dataIndex: 'Code',
        }, {
            title: this.translation('Description'),
            dataIndex: 'Description'
        }, {
            title: this.translation('Path'),
            dataIndex: 'Path'
        }, {
            title: this.translation('Action'),
            render: (text, record) =>
                <div>
                    <button type='button' onClick={() => this.editLandingPage(record)} style={{ marginRight: '10px' }} className='ant-btn' >
                        <Icon type='edit' />
                    </button>
                    <Popconfirm
                        title={this.translation("Are you sure delete LandingPage ?")}
                        onConfirm={e => {
                            e.stopPropagation()
                            this._deleteLandingPage(record.Id)
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button type='button' className='ant-btn'>
                            <Icon
                                type="delete"
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                            />
                        </button>
                    </Popconfirm>
                </div>
        }
        ]

        return (
            <Card bordered={false} >
                <Drawer
                    title={this.translation("Create LandingPage")}
                    visible={visible}
                    width={600}
                    closable={false}
                    onClose={this._cancel}
                >

                    <Form layout="vertical">
                        <Row gutter={16}>
                            <Col span={24}>

                                <Form.Item label={this.translation("Landing Page Name")}>
                                    {getFieldDecorator(`Name`, {
                                        initialValue: editObj.Name,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please Enter Name !',
                                            },
                                        ],
                                    })(<Input placeholder={this.translation("Please Enter Name")} />)
                                    }
                                </Form.Item>

                                <Form.Item label={this.translation("LandingPage Code")}>
                                    {getFieldDecorator("Code", {
                                        initialValue: editObj.Code || '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please enter LandingPage Code !'
                                            }
                                        ],
                                    })(
                                        <Input placeholder={this.translation("please enter LandingPage Code ")} />
                                    )}
                                </Form.Item>

                                <Form.Item label={this.translation("Description")}>
                                    {getFieldDecorator(`Description`, {
                                        initialValue: editObj.Description,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please enter Description !',
                                            },
                                        ],
                                    })(<Input placeholder={this.translation("Description")} />)
                                    }
                                </Form.Item>

                                <Form.Item label={this.translation("LandingPage Path")}>
                                    {getFieldDecorator("Path", {
                                        initialValue: editObj.Path || '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please enter LandingPage Path !'
                                            }
                                        ],
                                    })(
                                        <Input placeholder={this.translation("please enter LandingPage Path " )}/>
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>
                    </Form>
                    <DrawerFooter>
                        <Button onClick={this._cancel} style={{ marginRight: 8, }}>{this.translation("Cancel")}</Button>
                        {
                            !editObj.Id ?
                                <Button type="primary" onClick={this._createLandingPage}>{this.translation("Create LandingPage")}</Button>
                                :
                                <Button type="primary" onClick={this._updateLandingPage}>{this.translation("Update LandingPage")}</Button>
                        }
                    </DrawerFooter>
                </Drawer>
                <Button type="primary" size={"large"} style={{ marginBottom: '10px', float: "right" }} onClick={this.openModal}>{this.translation("Add LandingPage")}</Button>
                <StyledTable theme={lightTheme}>
                    <Table
                        columns={columns}
                        dataSource={datasource}
                        pagination={
                            datasource.length > 10
                                ? {
                                    pageSize: "10"
                                }
                                : false
                        }
                    />
                </StyledTable>
            </Card>

        )
    }
}

const mapStateToProps = createStructuredSelector({
    language: getCurrentLanguage(),
    translationCache: getTranslationCache(),
});

export default connect(mapStateToProps)(Form.create()(LandingPage));
