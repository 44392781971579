import React from "react"
import moment from "moment"
import { DatePicker } from 'antd'

import  Table  from './Table'
import { DatePickerSlider } from '../CSS/style'

const { RangePicker } = DatePicker

class DateRangeTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphprops: {
        ...props.graphprops,
        dateRangeExists: 'true'
      } || {},
      selectionDateRange: {
        startDate: moment().subtract(1, "hour").format('YYYY-MM-DD HH:mm'),
        endDate: moment().format('YYYY-MM-DD HH:mm'),
        timeBucket: '1m'
      }
    };
  }
  requestCheck = true;
  abortController = new window.AbortController();
  onDateRangePickerChange = (currentDate, dateString) => {
    // let timeBucket = ""
    let selectionDateRange = {}
    if (currentDate && currentDate[0] && currentDate[1]) {
      // let dayCount = currentDate[1].diff(currentDate[0], 'days')
      // let hourCount = currentDate[1].diff(currentDate[0], 'hours')

      // if (dayCount <= 2) {
      //   if (hourCount <= 1) {
      //     timeBucket = '1m'
      //   } else if (hourCount > 1 && hourCount <= 2) {
      //     timeBucket = '5m'
      //   } else if (hourCount >= 3 && hourCount <= 23) {
      //     timeBucket = '15m'
      //   } else {
      //     timeBucket = '1h'
      //   }
      // } else if (dayCount > 2 && dayCount <= 60) {
      //   timeBucket = '1d'
      // } else if (dayCount > 60) {
      //   timeBucket = '1week'
      // } else {
      //   timeBucket = ''
      // }

      selectionDateRange = {
        startDate: currentDate[0].format('YYYY-MM-DD HH:mm:ss'),
        endDate: currentDate[1].format('YYYY-MM-DD HH:mm:ss'),
        timeBucket:"1m"
      }

    // } else {
      // selectionDateRange = {
      //   startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      //   endDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      //   timeBucket: '1m'
      // }
    }
    this.setState({
      selectionDateRange,
      initialRender: false
    })

  }


  render() {
    let {selectionDateRange} = this.state
   return (
     <div style={{ height: '70%', marginTop: '40px'}}>
         <DatePickerSlider>
       <RangePicker
         format="YYYY-MM-DD HH:mm:ss"
         placeholder={["Start Date", "End Date"]}
         onChange={this.onDateRangePickerChange}
         value={[moment(selectionDateRange.startDate), moment(selectionDateRange.endDate)]}
         style={{
          width: "400px",
          cursor: "pointer",
       }}
          />
        </DatePickerSlider>
        <Table graphprops={this.state.graphprops} selectionDateRange={this.state.selectionDateRange} />
      </div>

    );
  }

}
export default DateRangeTable
