import React, { Fragment } from 'react';
import moment from "moment";
import { Icon, Tag } from "antd";
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ImageViewer from 'react-simple-image-viewer';

import {  getChartDataWithSelection, } from "../ChartCalls";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import { makeSelectThemeing } from "../../../../selectors/theme";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
// import { excelDownloadUrl } from "./ExcelAdapters/const";
import { imageMock } from "../../Mock/MockJson";
import { defaultRefreshTime } from '../constant.json'
import '../CSS/style.css'

class ImageGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      isViewerOpen: false,
      images: [],
      imagesList: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }

    this.getData(this.props);

    let refreshTime = ""
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear
    ) {
      this.getData();
    }

  }

  // componentWillReceiveProps(props) {
  //     this.getData(props);
  // }

  componentWillUnmount() {
    this.abortController.abort()
    clearInterval(this.timeTicket);
  };

  imageData = ((imagesList) => {
    let images = []
    imagesList && Array.isArray(imagesList) && imagesList.length > 0 && imagesList.map((src) => {
      images.push(src.imageURL)
    })
    this.setState({
      images: images
      // percent:50
    })


  })
  openImageViewer = ((index) => {
    this.setState({
      currentImage: index,
      isViewerOpen: true
    })
  });

  closeImageViewer = () => {
    this.setState({
      currentImage: 0,
      isViewerOpen: false

      // percent:50
    })
  };
  getData = async () => {
    this.requestCheck = false
    let json = []
    json =[
      {
        "imageURL": "https://www.ship-technology.com/wp-content/uploads/sites/16/2020/05/CSP-China.jpg"
      },
      {
        "imageURL": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeZeDQSmW4Nt9OH-zV4XSxOoS1GPPzD3wkAw&usqp=CAU"
      },
      {
        "imageURL": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeZeDQSmW4Nt9OH-zV4XSxOoS1GPPzD3wkAw&usqp=CAU"
      },
      {
        "imageURL": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRF1xnk8-XJgK2xFFRRNJhXfjXt5qDK1ab9FM8yv-Sx4CRszfP1ra-5OA7UW_UHWF2mQlU&usqp=CAU"
      }
    ]
    let { graphprops } = this.props
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = imageMock;
      this.requestCheck = true
    } else {
      if ((graphprops && graphprops.dashboardType === 'dynamic' && this.props.deviceId) ||
        (graphprops && graphprops.dateExists === 'true' && this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops && graphprops.dateRangeExists === 'true' && this.props.selectionDateRange)
        ||
        (graphprops && graphprops.dayMonthYearExists === 'true' && this.props.dayMonthYear)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController
        )
        this.requestCheck = true
      } else if (graphprops) {
        //json = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true
      }
    }
    if ((json && json.message) || (Array.isArray(json) && (json.length === 0) &&
      (graphprops.enableMock !== true))) {

      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
      }

    } else {

      if (json) {
        this.imageData(json)
        this.setState({
          imagesList: json,
          refreshDateTime: moment()
        })
      }
    }
    this.setState({
      errorInAPI,
      noDataInDB
    })
  }


  // componentWillUnmount() {
  //   this.abortController.abort();
  //   clearInterval(this.timeTicket);
  // }

  render() {
    let { isViewerOpen, currentImage, images, imagesList, reportName, excelDownload, refreshDateTime, errorInAPI, noDataInDB } = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
          {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <div>
              {imagesList && Array.isArray(imagesList) && imagesList.length > 0 && imagesList.map((src, index) => (

                <img
                  src={src.imageURL}
                  onClick={() => { this.openImageViewer(index) }}
                  width="200"
                  height="200"
                  key={index}
                  style={{ margin: '2px' }}
                  alt="" />
              ))}
              {isViewerOpen && (
                <ImageViewer
                  src={images}
                  currentIndex={currentImage}
                  onClose={this.closeImageViewer}
                />
              )}

            </div>

        }


        {/* {
          reportName && excelDownload === "true" ? (
            <a
              href={`${excelDownloadUrl}/${reportName}`}
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              <Icon
                type="download"
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginRight: "25px",
                  marginTop: "6px",
                }}
              />
            </a>
          ) : null
        } */}
      </Fragment >

    );

  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing()
})


export default connect(mapStateToProps)(ImageGallery)