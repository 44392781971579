import { message } from "antd";

export function replicateRule(state, device) {
  state.inputList.forEach((condition) => {
    condition.device = device;
  });

  state.elseList.forEach((condition) => {
    condition.device = device;
  });

  state.elsifLength.forEach((elseif) => {
    state[elseif].forEach((condition) => {
      condition.device = device;
    });
  });

  return state;
}

export default function (state) {
  const { inputList, elsifLength, elseList, ruleType, ruleMode } = state;
  if (ruleMode === "DeviceType") {
    let errorClearnce = true;
    let tagNames = [];
    if ((state.ruleType === "batch" || state.ruleType === "tableRule") && state.batchPriority === "") {
      message.error(`Please Select The Priority For The Rule`);
      errorClearnce = false;
    }

    let string = "if(";
    let tagsobj = `{`;
    inputList.forEach((con, i) => {
      let condata = Object.keys(con);
      condata.forEach((prop) => {
        if (prop === "tagName") {
          let obj = {
            deviceType: con["device"],
            tagName: con[prop],
          };
          if (ruleType === "tableRule") {
            obj.tableDevice = con["tableDevice"];
          }
          tagNames.push(obj);
          if (
            ruleType === "restApi" ||
            ruleType === "noDeviceRealTime" ||
            ruleType === "noDeviceBatch" ||
            ruleType === "noDeviceHybri"
          ) {
            if (con[prop] !== "") {
              string = string.concat(`data["${con[prop]}"]`);
              tagsobj = tagsobj.concat(`"${con[prop]}" :data["${con[prop]}"],`);
            } else {
              let propName = ruleType === "restApi" ? "Url" : "Parameter";
              message.error(`Please Select The ${propName} In If Condition`);
              errorClearnce = false;
            }
          } else {
            if (con[prop] !== "") {
              if (ruleType === "tableRule" && con["tableDevice"] !== undefined) {
                string = string.concat(
                  `data["${con["device"]}_${con["tableDevice"]}_${con[prop]}"]`
                );
                tagsobj = tagsobj.concat(
                  `"${con["device"]}$$$${con["tableDevice"]}$$$${con[prop]}" :data["${con["device"]}_${con["tableDevice"]}_${con[prop]}"],`
                );
              } else {
                string = string.concat(`data["${con[prop]}"]`);

                tagsobj = tagsobj.concat(
                  `"${con[prop]}" :data["${con[prop]}"],`
                );
              }
            } else {
              message.error("Please Select The Parameter In If Condition");
              errorClearnce = false;
            }
          }
        } else if (prop === "priventive" || prop === "rootcause" || prop === "recommandation") {
          if (con[prop] !== "" && con[prop] !== undefined) {
            tagsobj = tagsobj.concat(`"${prop}" :${JSON.stringify(con[prop])},`);
          }
        } else {
          if (
            prop !== "action" &&
            prop !== "device" &&
            prop !== "priventive" &&
            prop !== "recommandation" &&
            prop !== "rootcause" &&
            prop !== "delay" &&
            prop !== "tableDevice" &&
            prop !== "value"
          ) {
            if (con[prop] !== "") {
              string = string.concat(con[prop] + "limitData" + `["$${con["tagName"]}"]`);
            } else {
              let propMsg = prop === "54765" ? "Condition" : prop;
              message.error(`Please Select The ${propMsg} In If Condition`);
              errorClearnce = false;
            }
          }
        }
      });
      if (i === inputList.length - 1) {
        tagsobj = tagsobj.concat(`}`);
        if (inputList[i]["action"].length > 0) {
          let actionString = '"' + inputList[i]["action"].join('","') + '"';
          string = string.concat(
            `){return [${tagsobj},[${actionString}],"condition1",${JSON.stringify(
              inputList[i]["delay"] === undefined
                ? [0, "00:00:00"]
                : inputList[i]["delay"]
            )}]}`
          );
        } else {
          errorClearnce = false;
          message.error("Please Select Atleast one Action in IF Condition");
        }
      }
    });
    if (!errorClearnce) {
      return { errorClearnce };
    }

    elsifLength.forEach((elseif, index) => {
      string = string.concat("else if(");
      let elsetagsObj = "{";
      state[elseif].forEach((con, i) => {
        let condata = Object.keys(con);
        condata.forEach((prop) => {
          if (prop === "tagName") {
            let obj = {
              deviceType: con["device"],
              tagName: con[prop],
            };
            if (ruleType === "tableRule") {
              obj.tableDevice = con["tableDevice"];
            }
            tagNames.push(obj);
            if (
              ruleType === "restApi" ||
              ruleType === "noDeviceRealTime" ||
              ruleType === "noDeviceBatch" ||
              ruleType === "noDeviceHybri"
            ) {
              if (con[prop] !== "") {
                string = string.concat(`data["${con[prop]}"]`);
                elsetagsObj = elsetagsObj.concat(
                  `"${con[prop]}" :data["${con[prop]}"],`
                );
              } else {
                let propName = ruleType === "restApi" ? "Url" : "Parameter";
                message.error(
                  `Please Select The ${propName} In 221Else If Condition`
                );
                errorClearnce = false;
              }
            } else {
              if (con[prop] !== "") {
                string = string.concat(`data["${con[prop]}"]`
                );
                elsetagsObj = elsetagsObj.concat(
                  `"${con[prop]}" :data["${con[prop]}"],`
                );
              } else {
                message.error("Please Select The Parameter In Else If Condition");
                errorClearnce = false;
              }
            }
          } else if (prop === "priventive" || prop === "rootcause" || prop === "recommandation") {
            if (con[prop] !== "" && con[prop] !== undefined) {
              elsetagsObj = elsetagsObj.concat(
                `"${prop}" :${JSON.stringify(con[prop])},`
              );
            }
          } else {
            if (
              prop !== "action" &&
              prop !== "device" &&
              prop !== "priventive" &&
              prop !== "recommandation" &&
              prop !== "rootcause" &&
              prop !== "delay" && prop !== "value"
            ) {
              if (con[prop] !== "") {
                string = string.concat(con[prop] + "limitData" + `["$${con["tagName"]}"]`);
              } else {
                let propMsg = prop === "54765" ? "Condition" : prop;
                message.error(
                  `Please Select The ${propMsg} In Else If Condition`
                );
                errorClearnce = false;
              }
            }
          }
        });
        if (i === state[elseif].length - 1) {
          elsetagsObj = elsetagsObj.concat(`}`);
          if (state[elseif][i]["action"].length > 0) {
            let actionString = '"' + state[elseif][i]["action"].join('","') + '"';
            string = string.concat(
              `){return [${elsetagsObj},[${actionString}],"condition${index +
              2}",${JSON.stringify(
                state[elseif][i]["delay"] === undefined
                  ? [0, "00:00:00"]
                  : state[elseif][i]["delay"]
              )}]}`
            );
          } else {
            errorClearnce = false;
            message.error(
              "Please Select Atleast one Action in Else IF Condition"
            );
          }
        }
      });
    });

    if (!errorClearnce) {
      return { errorClearnce };
    }

    let elsetags = "{";
    elseList.forEach((con, i) => {
      if (elseList.length > 0) {
        if (i === 0) {
          string = string.concat("else if(");
        }
        let condata = Object.keys(con);
        condata.forEach((prop) => {
          if (prop === "tagName") {
            let obj = {
              deviceType: con["device"],
              tagName: con[prop],
            };
            tagNames.push(obj);
            if (
              ruleType === "restApi" ||
              ruleType === "noDeviceRealTime" ||
              ruleType === "noDeviceBatch" ||
              ruleType === "noDeviceHybri"
            ) {
              if (con[prop] !== "") {
                string = string.concat(`data["${con[prop]}"]`);
                elsetags = elsetags.concat(
                  `"${con[prop]}" :data["${con[prop]}"],`
                );
              } else {
                let propName = ruleType === "restApi" ? "Url" : "Parameter";
                message.error(`Please Select The ${propName} In Else Condition`);
                errorClearnce = false;
              }
            } else {
              if (con[prop] !== "") {
                string = string.concat(`data["${con[prop]}"]`);
                elsetags = elsetags.concat(
                  `"${con[prop]}" :data["${con[prop]}"],`
                );
              } else {
                message.error("Please Select The Parameter In Else Condition");
                errorClearnce = false;
              }
            }
          } else if (prop === "priventive" || prop === "rootcause" || prop === "recommandation") {
            if (con[prop] !== "" && con[prop] !== undefined) {
              elsetags = elsetags.concat(
                `"${prop}" :${JSON.stringify(con[prop])},`
              );
            }
          } else {
            if (
              prop !== "action" &&
              prop !== "device" &&
              prop !== "priventive" &&
              prop !== "recommandation" &&
              prop !== "rootcause" &&
              prop !== "delay" && prop !== "value"
            ) {
              if (con[prop] !== "") {
                string = string.concat(con[prop]);
              } else {
                let propMsg = prop === "54765" ? "Condition" : prop;
                message.error(`Please Select The ${propMsg} In Else Condition`);
                errorClearnce = false;
              }
            }
          }
        });
        if (i === elseList.length - 1) {
          elsetags = elsetags.concat(`}`);
          if (elseList[i]["action"].length > 0) {
            let actionString = '"' + elseList[i]["action"].join('","') + '"';
            string = string.concat(
              `){return [${elsetags},[${actionString}],"condition${elsifLength.length +
              2}",${JSON.stringify(
                elseList[i]["delay"] === undefined
                  ? [0, "00:00:00"]
                  : elseList[i]["delay"]
              )}]}`
            );
          } else {
            errorClearnce = false;
            message.error("Please Select Atleast one Action in Else Condition");
          }
        }
      }
    });
    return { script: string, tagNames, errorClearnce };
  } else {
    let errorClearnce = true;
    let tagNames = [];
    if ((state.ruleType === "batch" || state.ruleType === "tableRule") && state.batchPriority === "") {
      message.error(`Please Select The Priority For The Rule`);
      errorClearnce = false;
    }

    let string = "if(";
    let tagsobj = `{`;
    inputList.forEach((con, i) => {
      let condata = Object.keys(con);
      condata.forEach((prop) => {
        if (prop === "tagName") {
          let obj = {
            device: con["device"],
            tagName: con[prop],
          };
          if (ruleType === "tableRule") {
            obj.tableDevice = con["tableDevice"];
          }
          tagNames.push(obj);
          if (
            ruleType === "restApi" ||
            ruleType === "noDeviceRealTime" ||
            ruleType === "noDeviceBatch" ||
            ruleType === "noDeviceHybri"
          ) {
            if (con[prop] !== "") {
              string = string.concat(`data["${con[prop]}"]`);
              tagsobj = tagsobj.concat(`"${con[prop]}" :data["${con[prop]}"],`);
            } else {
              let propName = ruleType === "restApi" ? "Url" : "Parameter";
              message.error(`Please Select The ${propName} In If Condition`);
              errorClearnce = false;
            }
          } else {
            if (con[prop] !== "") {
              if (ruleType === "tableRule" && con["tableDevice"] !== undefined) {
                string = string.concat(
                  `data["${con["device"]}_${con["tableDevice"]}_${con[prop]}"]`
                );
                tagsobj = tagsobj.concat(
                  `"${con["device"]}$$$${con["tableDevice"]}$$$${con[prop]}" :data["${con["device"]}_${con["tableDevice"]}_${con[prop]}"],`
                );
              } else {
                string = string.concat(`data["${con["device"]}_${con[prop]}"]`);
                tagsobj = tagsobj.concat(
                  `"${con["device"]}$$$${con[prop]}" :data["${con["device"]}_${con[prop]}"],`
                );
              }
            } else {
              message.error("Please Select The Parameter In If Condition");
              errorClearnce = false;
            }
          }
        } else if (prop === "priventive" || prop === "rootcause" || prop === "recommandation") {
          if (con[prop] !== "" && con[prop] !== undefined) {
            tagsobj = tagsobj.concat(`"${prop}" :${JSON.stringify(con[prop])},`);
          }
        } else {
          if (
            prop !== "action" &&
            prop !== "device" &&
            prop !== "priventive" &&
            prop !== "recommandation" &&
            prop !== "rootcause" &&
            prop !== "delay" &&
            prop !== "tableDevice"
          ) {
            if (con[prop] !== "") {
              string = string.concat(con[prop]);
            } else {
              let propMsg = prop === "54765" ? "Condition" : prop;
              message.error(`Please Select The ${propMsg}ii In If Condition`);
              errorClearnce = false;
            }
          }
        }
      });
      if (i === inputList.length - 1) {
        tagsobj = tagsobj.concat(`}`);
        if (inputList[i]["action"].length > 0) {
          let actionString = '"' + inputList[i]["action"].join('","') + '"';
          string = string.concat(
            `){return [${tagsobj},[${actionString}],"condition1",${JSON.stringify(
              inputList[i]["delay"] === undefined
                ? [0, "00:00:00"]
                : inputList[i]["delay"]
            )}]}`
          );
        } else {
          errorClearnce = false;
          message.error("Please Select Atleast one Action in IF Condition");
        }
      }
    });
    if (!errorClearnce) {
      return { errorClearnce };
    }

    elsifLength.forEach((elseif, index) => {
      string = string.concat("else if(");
      let elsetagsObj = "{";
      state[elseif].forEach((con, i) => {
        let condata = Object.keys(con);
        condata.forEach((prop) => {
          if (prop === "tagName") {
            let obj = {
              device: con["device"],
              tagName: con[prop],
            };
            if (ruleType === "tableRule") {
              obj.tableDevice = con["tableDevice"];
            }
            tagNames.push(obj);
            if (
              ruleType === "restApi" ||
              ruleType === "noDeviceRealTime" ||
              ruleType === "noDeviceBatch" ||
              ruleType === "noDeviceHybri"
            ) {
              if (con[prop] !== "") {
                string = string.concat(`data["${con[prop]}"]`);
                elsetagsObj = elsetagsObj.concat(
                  `"${con[prop]}" :data["${con[prop]}"],`
                );
              } else {
                let propName = ruleType === "restApi" ? "Url" : "Parameter";
                message.error(
                  `Please Select The ${propName} In Else If Condition`
                );
                errorClearnce = false;
              }
            } else {
              if (con[prop] !== "") {
                string = string.concat(`data["${con["device"]}_${con[prop]}"]`);
                elsetagsObj = elsetagsObj.concat(
                  `"${con["device"]}$$$${con[prop]}" :data["${con["device"]}_${con[prop]}"],`
                );
              } else {
                message.error("Please Select The Parameter In Else If Condition");
                errorClearnce = false;
              }
            }
          } else if (prop === "priventive" || prop === "rootcause" || prop === "recommandation") {
            if (con[prop] !== "" && con[prop] !== undefined) {
              elsetagsObj = elsetagsObj.concat(
                `"${prop}" :${JSON.stringify(con[prop])},`
              );
            }
          } else {
            if (
              prop !== "action" &&
              prop !== "device" &&
              prop !== "priventive" &&
              prop !== "recommandation" &&
              prop !== "rootcause" &&
              prop !== "delay"
            ) {
              if (con[prop] !== "") {
                string = string.concat(con[prop]);
              } else {
                let propMsg = prop === "54765" ? "Condition" : prop;
                message.error(
                  `Please Select The ${propMsg} In Else If Condition`
                );
                errorClearnce = false;
              }
            }
          }
        });
        if (i === state[elseif].length - 1) {
          elsetagsObj = elsetagsObj.concat(`}`);
          if (state[elseif][i]["action"].length > 0) {
            let actionString = '"' + state[elseif][i]["action"].join('","') + '"';
            string = string.concat(
              `){return [${elsetagsObj},[${actionString}],"condition${index +
              2}",${JSON.stringify(
                state[elseif][i]["delay"] === undefined
                  ? [0, "00:00:00"]
                  : state[elseif][i]["delay"]
              )}]}`
            );
          } else {
            errorClearnce = false;
            message.error(
              "Please Select Atleast one Action in Else IF Condition"
            );
          }
        }
      });
    });

    if (!errorClearnce) {
      return { errorClearnce };
    }

    let elsetags = "{";
    elseList.forEach((con, i) => {
      if (elseList.length > 0) {
        if (i === 0) {
          string = string.concat("else if(");
        }
        let condata = Object.keys(con);
        condata.forEach((prop) => {
          if (prop === "tagName") {
            let obj = {
              device: con["device"],
              tagName: con[prop],
            };
            tagNames.push(obj);
            if (
              ruleType === "restApi" ||
              ruleType === "noDeviceRealTime" ||
              ruleType === "noDeviceBatch" ||
              ruleType === "noDeviceHybri"
            ) {
              if (con[prop] !== "") {
                string = string.concat(`data["${con[prop]}"]`);
                elsetags = elsetags.concat(
                  `"${con[prop]}" :data["${con[prop]}"],`
                );
              } else {
                let propName = ruleType === "restApi" ? "Url" : "Parameter";
                message.error(`Please Select The ${propName} In Else Condition`);
                errorClearnce = false;
              }
            } else {
              if (con[prop] !== "") {
                string = string.concat(`data["${con["device"]}_${con[prop]}"]`);
                elsetags = elsetags.concat(
                  `"${con["device"]}$$$${con[prop]}" :data["${con["device"]}_${con[prop]}"],`
                );
              } else {
                message.error("Please Select The Parameter In Else Condition");
                errorClearnce = false;
              }
            }
          } else if (prop === "priventive" || prop === "rootcause" || prop === "recommandation") {
            if (con[prop] !== "" && con[prop] !== undefined) {
              elsetags = elsetags.concat(
                `"${prop}" :${JSON.stringify(con[prop])},`
              );
            }
          } else {
            if (
              prop !== "action" &&
              prop !== "device" &&
              prop !== "priventive" &&
              prop !== "recommandation" &&
              prop !== "rootcause" &&
              prop !== "delay"
            ) {
              if (con[prop] !== "") {
                string = string.concat(con[prop]);
              } else {
                let propMsg = prop === "54765" ? "Condition" : prop;
                message.error(`Please Select The ${propMsg} In Else Condition`);
                errorClearnce = false;
              }
            }
          }
        });
        if (i === elseList.length - 1) {
          elsetags = elsetags.concat(`}`);
          if (elseList[i]["action"].length > 0) {
            let actionString = '"' + elseList[i]["action"].join('","') + '"';
            string = string.concat(
              `){return [${elsetags},[${actionString}],"condition${elsifLength.length +
              2}",${JSON.stringify(
                elseList[i]["delay"] === undefined
                  ? [0, "00:00:00"]
                  : elseList[i]["delay"]
              )}]}`
            );
          } else {
            errorClearnce = false;
            message.error("Please Select Atleast one Action in Else Condition");
          }
        }
      }
    });
    return { script: string, tagNames, errorClearnce };
  }
}