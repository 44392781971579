import React from "react";
import _ from "lodash";
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux'
import {
    Button,
    Drawer,
    Table,
    Row,
    Col,
    Form,
    Input,
    Icon,
    Popconfirm,
    message,
    Divider
} from "antd";
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import history from '../../../commons/history'
import {
    currentAsset,
    setBreadcrumbs
} from '../../../modules/asset/asset.duck'
import {
    _getAssetsBasedOnAssetID,
    _saveAssetData,
    _updateAssetData,
    _deleteAssetObject
} from './APICalls'
import {
    AddItemDiv,
    AssetDeviceFlowSearch,
    BadgeStyle,
    SpanItem,
    DrawerFooter,
    StyledApplication,
    StyledTable,
    lightTheme
} from "../AssetDeviceList/style";
import {
    setDeviceBreadcrumbs,
    currentDevice
} from "../../../modules/device/device.duck"

class AssetComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            editObj: {},
            data: {},
            datasource: [],
            searchText: ""
        };
    }
    componentDidMount() {
        this._getDatasource(this.props.data)
    }
    componentDidUpdate(preprops) {
        if (preprops.data !== this.props.data) {
            this._getDatasource(this.props.data)
        }
    }

    _getDatasource = async (data) => {
        let datasource = await _getAssetsBasedOnAssetID(data)
        this.setState({
            data,
            datasource: datasource || []
        })
    }

    searchAssetCode = async value => {
        let assetDatasource = await _getAssetsBasedOnAssetID(this.props.data)
        const datasource = assetDatasource && Array.isArray(assetDatasource) && assetDatasource.filter(function (asset) {
            return (
                asset.Name
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||
                asset.AssetCode
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
            );
        });
        this.setState({ datasource });
    };

    openAssetDrawer = (e) => {
        e.preventDefault()
        this.setState({
            editObj: {},
            visible: true
        })
    }

    navigateNextPage = (e, row) => {
        this.props.actions.currentAsset(row)
        this.props.actions.setBreadcrumbs(row)
        this.props.actions.setDeviceBreadcrumbs([])
        this.props.actions.currentDevice({})

        history.push({ pathname: `/rubus/assetConfiguration/${row.AssetCode}` })
    }

    onClose = () => {
        this.setState({ visible: false });
        this.props.form.resetFields();
    };

    saveAsset = e => {
        e.preventDefault();
        this.props.form.validateFields(async (error, values) => {
            if (!error) {
                let saveResponse = await _saveAssetData(values)
                if (saveResponse && saveResponse.message) {
                    message.success(saveResponse.message)
                    this._getDatasource(this.props.data)
                    this.setState({ visible: false });
                    this.props.form.resetFields();
                }
            }
        });
    };

    updateAsset = e => {
        e.preventDefault();
        this.props.form.validateFields(async (error, values) => {
            if (!error) {
                let updateResponse = await _updateAssetData(values)
                if (updateResponse && updateResponse.message) {
                    message.success(updateResponse.message)
                    this._getDatasource(this.props.data)
                    this.setState({ visible: false });
                    this.props.form.resetFields();
                }
            }
        });
    };

    editRow = (e, editObj) => {
        this.setState({ editObj });
        this.setState({ visible: true });
    };

    _deleteAssetData = async (deleteObject) => {
        let deleteResponse = await _deleteAssetObject(deleteObject.Id)
        if (deleteResponse) {
            message.success(deleteResponse.message)
        }
        this._getDatasource(this.props.data)
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        let {
            datasource,
            visible,
            editObj,
            data
        } = this.state

        return (
            // <StyledApplication style={{ minHeight: window.innerHeight - 226 }}>
                <div style={{ margin: "20px 0px" }}>
                    <AddItemDiv>
                        <AssetDeviceFlowSearch
                            placeholder="Enter a string to reduce results"
                            onSearch={value => this.searchAssetCode(value)}
                        />
                        <div>
                            <BadgeStyle count={datasource ? datasource.length : 0} />
                            <SpanItem>Items</SpanItem>
                            <Button
                                icon="plus"
                                type="primary"
                                onClick={(e) => this.openAssetDrawer(e, data.AssetType)}
                            >
                                Add
                                <span style={{ padding: "0 5px" }}>
                                    {data && data.AssetType
                                        ? _.startCase(_.toLower(data.AssetType))
                                        : _.startCase(_.toLower(''))}
                                </span>
                            </Button>

                        </div>
                    </AddItemDiv>

                    <Drawer
                        title={
                            !editObj.Id
                                ? data && data.AssetType
                                    ? `Create ${_.startCase(_.toLower(data.AssetType))}`
                                    : `Create ${''}`
                                : data.AssetType
                                    ? `Update ${_.startCase(_.toLower(data.AssetType))}`
                                    : `Update ${_.startCase(_.toLower(''))}`
                        }
                        placement="right"
                        width={400}
                        onClose={this.onClose}
                        visible={visible}
                    >
                        <Form layout="vertical">
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        label={
                                            data && data.AssetType
                                                ? `${_.startCase(_.toLower(data.AssetType))} Code`
                                                : `${_.startCase(_.toLower(''))} Code`
                                        }
                                    >
                                        {getFieldDecorator("AssetCode", {
                                            initialValue: editObj.AssetCode,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: data && data.AssetType
                                                        ? `Please input ${_.startCase(
                                                            _.toLower(data.AssetType)
                                                        )} Code`
                                                        : `Please enter ${_.startCase(
                                                            _.toLower('')
                                                        )} Code`
                                                }
                                            ]
                                        })(
                                            <Input
                                                placeholder={
                                                    data && data.AssetType
                                                        ? `Please enter ${_.startCase(
                                                            _.toLower(data.AssetType)
                                                        )} Code`
                                                        : `Please enter ${_.startCase(
                                                            _.toLower('')
                                                        )} Code`
                                                }
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            data && data.AssetType
                                                ? `${_.startCase(_.toLower(data.AssetType))} Name`
                                                : `${_.startCase(_.toLower(''))} Name`
                                        }
                                    >
                                        {getFieldDecorator("Name", {
                                            initialValue: editObj.Name,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: data && data.AssetType
                                                        ? `Please input your  ${_.startCase(
                                                            _.toLower(data.AssetType)
                                                        )} Name`
                                                        : `Please enter ${_.startCase(
                                                            _.toLower('')
                                                        )} Name`
                                                }
                                            ]
                                        })(
                                            <Input
                                                placeholder={
                                                    data && data.AssetType
                                                        ? `Please enter ${_.startCase(
                                                            _.toLower(data.AssetType)
                                                        )} Name`
                                                        : `Please enter ${_.startCase(
                                                            _.toLower('')
                                                        )} Name`
                                                }
                                            />
                                        )}
                                        {getFieldDecorator("Id", {
                                            initialValue: editObj.Id
                                        })(<Input style={{ display: "none" }} />)}
                                        {getFieldDecorator("AssetType", {
                                            initialValue: data && data.AssetType ? data.AssetType : ""
                                        })(<Input style={{ display: "none" }} />)}
                                        {getFieldDecorator("ParentAsset", {
                                            initialValue: data && data.parentAssetId ? data.parentAssetId : null
                                        })(<Input style={{ display: "none" }} />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        <DrawerFooter>
                            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                                Cancel
                            </Button>
                            {!editObj.Id ? (
                                <Button type="primary" onClick={this.saveAsset}>
                                    {
                                        data && data.AssetType
                                            ? `Create ${_.startCase(_.toLower(data.AssetType))}`
                                            : `Create ${_.startCase(_.toLower(''))}`}
                                </Button>
                            ) : (
                                <Button type="primary" onClick={this.updateAsset}>
                                    {
                                        data && data.AssetType
                                            ? `Update ${_.startCase(_.toLower(data.AssetType))}`
                                            : `Update ${_.startCase(_.toLower(''))}`}
                                </Button>
                            )}
                        </DrawerFooter>
                    </Drawer>
                    <div className="Table">
                        <Table
                            columns={[
                                {
                                    title: data && data.AssetType
                                        ? `${_.startCase(_.toLower(data.AssetType))} Code`
                                        : `${_.startCase(_.toLower(""))} Code`,
                                    dataIndex: "AssetCode",
                                    key: "AssetCode",
                                    ...this.getColumnSearchProps('AssetCode'),
                                    render: (text, row, key) => {
                                        return {
                                            props: {
                                                // style: {
                                                //     background:
                                                //         Math.abs(key % 2) === 1 ? "rgb(251, 250, 250)" : "#fff"
                                                // }
                                            },
                                            children: (
                                                <span
                                                    style={{ cursor: "pointer" }}
                                                    onClick={(e) => this.navigateNextPage(e, row)}
                                                >
                                                    {text}
                                                </span>
                                            )
                                        };
                                    }
                                },
                                {
                                    title: data && data.AssetType
                                        ? `${_.startCase(_.toLower(data.AssetType))} Name`
                                        : `${_.startCase(_.toLower(''))} Name`,
                                    dataIndex: "Name",
                                    key: "Name",
                                    ...this.getColumnSearchProps('Name'),
                                    render: (text, row, key) => {
                                        return {
                                            props: {
                                                // style: {
                                                //     background:
                                                //         Math.abs(key % 2) === 1 ? "rgb(251, 250, 250)" : "#fff"
                                                // }
                                            },
                                            children: <span>{text}</span>
                                        };
                                    }
                                },
                                {
                                    title: "Action",
                                    dataIndex: "Action",
                                    key: "Action",
                                    render: (text, row, key) => {
                                        return {
                                            props: {
                                                // style: {
                                                //     background:
                                                //         Math.abs(key % 2) === 1 ? "rgb(251, 250, 250)" : "#fff"
                                                // }
                                            },
                                            children: (
                                                <div>
                                                    {/* <a> */}
                                                    <Icon
                                                        type="edit"
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            this.editRow(e, row)
                                                        }}
                                                    />
                                                    {/* </a> */}
                                                    <Divider type="vertical" />
                                                    {/* <a> */}
                                                    <Popconfirm
                                                        title="Are you sure delete Asset?"
                                                        onConfirm={e => {
                                                            e.stopPropagation();
                                                            this._deleteAssetData(row)
                                                        }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Icon
                                                            theme="twoTone" twoToneColor="red"
                                                            type="delete"
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                            }}
                                                        />


                                                    </Popconfirm>
                                                    {/* </a> */}
                                                </div>
                                            )
                                        };
                                    }
                                }
                            ]}
                            dataSource={datasource}
                            size="large"
                            pagination={
                                datasource.length > 10
                                    ? {
                                        pageSize: "10"
                                    }
                                    : false
                            }
                            locale={{ emptyText: "No Data" }}
                        />
                    </div>
                </div>
            // </StyledApplication>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                currentAsset,
                setBreadcrumbs,
                setDeviceBreadcrumbs,
                currentDevice
            },
            dispatch
        )
    }
}
const mapStateToProps = createStructuredSelector({

})


export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(AssetComponent));
