import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import EditProfileComponent from './EditProfileComponent'
import { getLoggedInProfile } from '../../../modules/layout/layout.duck'
import { getUserProfile } from '../../../selectors/layout'

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(
      {
        getLoggedInProfile
      },
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileComponent)
