import localStorage from '../../utils/localStorage'

export default class layout {
  static getUserprofile () {
    
    const accessToken = localStorage.get('accessToken')
    return fetch('/api/auth/me', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken)
    function responseToken (response) {
      return response.json()
    }
  
  }
}
