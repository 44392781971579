import React, { Fragment } from "react";
import moment from "moment";
import { connect } from "react-redux";
import {
    Spin
} from 'antd'
import { createStructuredSelector } from "reselect";
import { NoRecordComponent } from "../Utils";
import { ErrorComponent } from "../Utils";
import ReactEcharts from "../Echarts/Echarts";
import {
    getCurrentLanguage,
    getTranslationCache,
} from "../../../../selectors/language";
import {
    historicChartData,
    historicWithPayloadTableChartDataHeatMap,
} from "../ChartCalls";
import { heatmapCartesionMockJson } from "../../Mock/heatmapMock"

class HeatmapCartesion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            option: {},
            data: [],
            loading: false,
            translationCache: props.translationCache || [],
            language: props.language || "English",
            refreshDateTime: moment(),
            errorInAPI: false,
            noDataInDB: false
        };
    }

    timeTicket = null;
    requestCheck = true;
    abortController = new window.AbortController();
    async componentDidMount() {
        if (this.timeTicket) {
            clearInterval(this.timeTicket);
        }
        this.getData(this.props);

        let refreshTime = "";
        if (this.props.graphprops.refreshTime) {
            refreshTime = this.props.graphprops.refreshTime * 60000;
        } else {
            refreshTime = 1 * 60000;
        }

        this.timeTicket = setInterval(() => {
            if (this.requestCheck) {
                this.getData(this.props, this.props.selectedThemeing);
            }
        }, refreshTime);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.selectionDate !== this.props.selectionDate ||
            prevProps.selectionMonth !== this.props.selectionMonth ||
            prevProps.selectionWeek !== this.props.selectionWeek ||
            prevProps.deviceId !== this.props.deviceId ||
            prevProps.shift !== this.props.shift ||
            prevProps.selectionDateRange !== this.props.selectionDateRange ||
            prevProps.dayMonthYear !== this.props.dayMonthYear
        ) {
            this.getData();
        }
    }

    componentWillUnmount() {
        this.abortController.abort()
        clearInterval(this.timeTicket);
    }

    getData = async () => {

        let { errorInAPI, noDataInDB } = this.state
        errorInAPI = false
        noDataInDB = false
        let series = [];
        let theme = this.props.selectedThemeing;

        const { graphprops } = this.props;
        let displayType =
            this.props &&
                this.props.graphprops &&
                this.props.graphprops.HeatmapCartesion &&
                this.props.graphprops.HeatmapCartesion.cartesionDisplayType ?
                this.props.graphprops.HeatmapCartesion.cartesionDisplayType : "" ||
                this.props &&
                this.props.graphprops &&
                this.props.graphprops.dateHeatmapCartesion &&
                this.props.graphprops.dateHeatmapCartesion.cartesionDisplayType ?
                this.props.graphprops.dateHeatmapCartesion.cartesionDisplayType : ""
        let json = {};
        let option = this.getOption();
        this.setState({ loading: true });
        if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
            json = heatmapCartesionMockJson;
            this.requestCheck = true
            this.setState({ loading: false });
        } else {
            if (
                (graphprops &&
                    graphprops.dashboardType === "dynamic" &&
                    this.props.deviceId) ||
                (graphprops &&
                    graphprops.dateExists === "true" &&
                    this.props.selectionDate) ||
                (graphprops &&
                    graphprops.monthExists === "true" &&
                    this.props.selectionMonth) ||
                (graphprops &&
                    graphprops.weekExists === "true" &&
                    this.props.selectionWeek) ||
                (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
                (graphprops &&
                    graphprops.dateRangeExists === "true" &&
                    this.props.selectionDateRange) ||
                (graphprops &&
                    graphprops.dayMonthYearExists === "true" &&
                    this.props.dayMonthYear)
            ) {
                json = await historicWithPayloadTableChartDataHeatMap(
                    graphprops,
                    this.props.deviceId,
                    this.props.selectionDate,
                    this.props.selectionMonth,
                    this.props.selectionWeek,
                    this.props.shift,
                    this.props.selectionDateRange,
                    this.props.dayMonthYear,
                    this.abortController
                );
                this.requestCheck = true
                this.setState({ loading: false });
            } else {
                json = await historicChartData(graphprops, this.abortController);
                this.requestCheck = true
                this.setState({ loading: false });
            }
        }
        if (json && json.message || (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)) {

            if (json.message) {
                errorInAPI = true
            } else if (json.length === 0) {
                noDataInDB = true
            }
        } else {
            let time = [];
            let data = [];
          
            let { translationCache } = this.props;
            let mainDataObject = [];
            if (json && Object.keys(json).length > 0) {
                time = Object.keys(json);
                let timeData = Object.keys(json).map((timeFormat) => {
                    return moment.utc(timeFormat, 'HH:mm:ss').local().format('HH:mm:ss')
                })
                if (time && time.length > 0 && json[time[0]]) {
                    data =
                        Array.isArray(json[time[0]]) &&
                        json[time[0]].map((yAxis) => {
                            return (
                                moment.utc(yAxis.intervalAlias, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD")
                            );
                        });
                }
                let yAxis = []
                for (let i = 6; i >= 0; i--) {
                    yAxis.push(moment(this.props.selectionDate.date).subtract(i, "days").format("YYYY-MM-DD"))
                }

                time && time.map((mainObject) => {
                    let newObject = []
                    if (json[mainObject] && Array.isArray(json[time[0]])) {
                        let mainObject1 = moment.utc(mainObject, 'HH:mm:ss').local().format('HH:mm:ss')
                        newObject = yAxis.map((yAxisData, childKey) => {
                            let splitedData = mainObject1.split(":");
                            let xAxis = ""
                            if (displayType && displayType === 'percentage') {
                                xAxis = mainObject
                            } else {
                                xAxis = splitedData.length === 3 ? splitedData[0] + ":" + splitedData[1] : mainObject;
                            }


                            let allIntervals = {}
                            json[mainObject].map((jsonData) => {
                                allIntervals = {
                                    ...allIntervals,
                                    [moment.utc(jsonData.intervalAlias).local().format("YYYY-MM-DD")]: jsonData
                                }
                            })

                            if (Object.keys(allIntervals).includes(yAxisData)) {
                                return [
                                    childKey,
                                    xAxis,
                                    allIntervals[yAxisData].tagValue,
                                    allIntervals[yAxisData].actualValue,
                                    allIntervals[yAxisData].predictedValue,
                                    allIntervals[yAxisData].description,
                                ];
                            }
                        })
                    }
                    newObject = newObject.filter(function (element) {
                        return element !== undefined;
                    })
                    mainDataObject = [
                        ...mainDataObject,
                        ...(newObject &&
                            Array.isArray(newObject)
                            ? newObject
                            : [])
                    ];
                });
                if (mainDataObject && Array.isArray(mainDataObject)) {
                    this.setState({
                        data: mainDataObject,
                    });
                }
                if (timeData && Array.isArray(timeData) && displayType !== 'percentage') {
                    option.xAxis.data = timeData.map((item) => {
                        let splitedData = item.split(":");
                        let xAxisData =
                            splitedData.length === 3
                                ? splitedData[0] + ":" + splitedData[1]
                                : item;
                        let xAxis =
                            translationCache &&
                                translationCache[xAxisData]
                                ? translationCache[xAxisData]
                                : xAxisData;
                        return xAxis;
                    });
                } else if (displayType === 'percentage') {
                    option.xAxis.data = time
                }
                if (data && Array.isArray(data)) {
                    option.yAxis.data = this.props.graphprops && this.props.graphprops.HeatmapCartesion &&
                        this.props.graphprops.HeatmapCartesion.cartesionDisplayType ||
                        this.props.graphprops && this.props.graphprops.dateHeatmapCartesion &&
                        this.props.graphprops.dateHeatmapCartesion.cartesionDisplayType   === "none" ? // custom logic ,to get last 7 days dates from selected date
                        yAxis
                        :
                        data.map(function (item) {
                            return item;
                        });
                }
                series = [
                    {
                        name: "",
                        type: displayType === "scatter" ? "scatter" : "heatmap",
                        symbolSize: 15,
                        itemStyle: {
                            borderWidth: 2,
                            borderType: "dotted",
                            borderColor: "rgba(187, 177, 177, 1)"
                        },
                        data:
                            mainDataObject &&
                            Array.isArray(mainDataObject) &&
                            mainDataObject.map((item) => {
                                if (item) {
                                    return [
                                        item[1],
                                        item[0],
                                        item[3] || 0,
                                        item[4] || 0,
                                        item[5] ? item[5] : "",
                                        item[2] || item[2] === 0 ? item[2] : "-",
                                    ];
                                }
                            }),
                        label: {
                            show: displayType === "none" ? false : true,
                            textStyle: {
                                color: theme === "lightTheme" ? "black" : "black",
                                fontSize: 12,
                            },
                            formatter: function (params) {
                                if (displayType === "percentage") {
                                    return params.value[5] + "%";
                                }
                                return params.value[5];
                            },
                            color: "#000"
                        }
                    }
                ];
            }

            option.series = series;
            this.setState({ option, refreshDateTime: moment() });
        }
        this.setState({
            errorInAPI,
            noDataInDB
        })
    };

    render() {
        let { errorInAPI, noDataInDB, loading } = this.state;
        console.log("option",this.state.option);
        return (
            <span>
                {loading === false ? (
                    <Fragment>
                        {/* <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
                              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm') : ""}
                            </Tag> */}
                        {
                            noDataInDB === true || errorInAPI === true ?
                                noDataInDB === true ?
                                    <NoRecordComponent />
                                    : <ErrorComponent />
                                :
                                <ReactEcharts
                                    option={this.state.option}
                                    style={{ height: "90%", width: "100%" }}
                                    className="react_for_echarts"
                                />
                        }
                    </Fragment>
                ) : (
                    <div
                        style={{
                            textAlign: "center",
                            color: "white",
                            marginTop: "10%",
                        }}
                    >
                        <h2 style={{ color: "white" }}>
                            <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                        </h2>
                    </div>
                )}
            </span>
        );
    }

    getOption = () => {
        let { translationCache, graphprops } = this.props;
        let theme = this.props.selectedThemeing;
        let rangeValue = []
        if (this.props.graphprops && this.props.graphprops.HeatmapCartesion &&
            this.props.graphprops.HeatmapCartesion.range) {
            rangeValue = JSON.parse(this.props.graphprops.HeatmapCartesion.range)
        }
        if (this.props.graphprops && this.props.graphprops.dateHeatmapCartesion &&
            this.props.graphprops.dateHeatmapCartesion.range) {
            rangeValue = JSON.parse(this.props.graphprops.dateHeatmapCartesion.range)
        }
        let option = {
            grid: {
                left: "10%",
                width: "87%",
            },
            tooltip: {
                position: "top",
                textStyle: {
                    color: theme === "lightTheme" ? "black" : "black",
                    fontSize: 16,
                },
                formatter: function (param) {
                    let xAxisLabel =
                        translationCache &&
                            translationCache[param.data[4]]
                            ? translationCache[param.data[4]]
                            : param.data[4];
                    // let actualValue =
                    //     translationCache &&
                    //         translationCache["Actual Value"]
                    //         ? translationCache["Actual Value"]
                    //         : "Actual Value";
                    // let predictedValue =
                    //     translationCache &&
                    //         translationCache["Predicted Value"]
                    //         ? translationCache["Predicted Value"]
                    //         : "Predicted Value";
                    if (
                        graphprops &&
                        graphprops.HeatmapCartesion &&
                        graphprops.HeatmapCartesion.cartesionDisplayType &&
                        graphprops.HeatmapCartesion.cartesionDisplayType === "percentage" ||
                        graphprops &&
                        graphprops.dateHeatmapCartesion &&
                        graphprops.dateHeatmapCartesion.cartesionDisplayType &&
                        graphprops.dateHeatmapCartesion.cartesionDisplayType === "percentage"
                    ) {
                        return [
                            param.marker,
                            " <bold>" +
                            xAxisLabel +
                            "</bold>" +
                            ": " +
                            param.data[5] +
                             +
                            "\n\n",
                            // " <bold>" + actualValue + ": " + param.value[2],
                            // "</bold>" + predictedValue + ": " + param.data[3],
                        ].join(" <br/> ");
                    } else if (param.data[5] === 1) {
                        return [
                            " <bold>" + param.marker + " <bold>" + xAxisLabel + "</br>" + param.data[4] + "</bold>"]
                    }
                },
            },
            animation: true,
            xAxis: {
                type: "category",
                splitArea: {
                    show: true,
                },
                axisLabel: {
                    textStyle: {
                        color: theme === "lightTheme" ? "black" : "white",
                        fontSize: 13,
                    },
                },
                axisTick: {
                    lineStyle: {
                        color: theme === "lightTheme" ? "black" : "white",
                    },
                },
                axisLine: {
                    lineStyle: {
                        color: theme === "lightTheme" ? "black" : "white",
                    },
                },
            },
            yAxis: {
                type: "category",
                splitArea: {
                    show: true,
                },
                axisLabel: {
                    textStyle: {
                        color: theme === "lightTheme" ? "black" : "white",
                        fontSize: 13,
                    },
                },
                axisTick: {
                    lineStyle: {
                        color: theme === "lightTheme" ? "black" : "white",
                    },
                },
                axisLine: {
                    lineStyle: {
                        color: theme === "lightTheme" ? "black" : "white",
                    },
                },
            },
            visualMap: [
                {
                    calculable: true,
                    orient: "horizontal",
                    type: 'piecewise',
                    show: this.props &&
                        this.props.graphprops &&
                        this.props.graphprops.HeatmapCartesion &&
                        this.props.graphprops.HeatmapCartesion.rangeBar &&
                        (this.props.graphprops.HeatmapCartesion.rangeBar === "true" || 
                        this.props.graphprops.HeatmapCartesion.rangeBar === true)
                        ? true : false || 
                        this.props &&
                        this.props.graphprops &&
                        this.props.graphprops.dateHeatmapCartesion &&
                        this.props.graphprops.dateHeatmapCartesion.rangeBar &&
                        (this.props.graphprops.dateHeatmapCartesion.rangeBar === "true" || 
                        this.props.graphprops.dateHeatmapCartesion.rangeBar === true)
                        ? true : false,
                    top: "1%",
                    left: "35%",
                    min: rangeValue && rangeValue[0] && rangeValue[0] ? rangeValue[0] : 0,
                    max: rangeValue && rangeValue && rangeValue[1] ? rangeValue[1] : 100,
                    textStyle: {
                        color: theme === "lightTheme" ? "black" : "white",
                        fontWeight: "bold",
                        fontSize: 16,
                    },
                    pieces: this.props.graphprops
                        && this.props.graphprops.HeatmapCartesion
                        && this.props.graphprops.HeatmapCartesion.visualMap
                        ? this.props.graphprops.HeatmapCartesion.visualMap : "" ||
                        this.props.graphprops
                        && this.props.graphprops.dateHeatmapCartesion
                        && this.props.graphprops.dateHeatmapCartesion.visualMap
                        ? this.props.graphprops.dateHeatmapCartesion.visualMap : "",
                    outOfRange: {
                        color: "#0d1115",
                    },
                    inRange: {
                        color: ["#f5d191", "red"],
                    }
                },
            ],
           
            series: [],
            toolbox: {
                showTitle: false,
                orient: "vertical",
                itemSize: 25,
                feature: {
                    saveAsImage: {
                        title: "Download",
                        backgroundColor: theme === "lightTheme" ? "white" : "black",
                        name: this.props.graphprops.title,
                        iconStyle: {
                            borderColor: "#8c8c8c",
                            borderWidth: 2
                        }
                    }
                }
            }
        };
      
        return option;
    };
}

const mapStateToProps = createStructuredSelector({
    language: getCurrentLanguage(),
    translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(HeatmapCartesion);



