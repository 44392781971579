import React, { Fragment } from "react";
import axios from "axios";
import moment from 'moment'
import { Table, Modal, Form, Input, Radio } from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import "../../CSS/Table.css"

import AnomalyFeedbackTable from "../AnomalyFeedbackTable";
import { getCurrentLanguage , getTranslationCache } from "../../../../../selectors/language"
import localStorage from "../../../../../utils/localStorage";
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 10 },
};

class SummaryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: props.summaryTable || {},
      date: "",
      translationCache: props.translationCache || [],
      language: props.language || "English",
      openModal: false,
      feedbackForm: props.feedbackForm || {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.summaryTable !== this.props.summaryTable) {
      if (this.props.predictionsType === "arrayOfArray") {
        this._generateColumnsFromDatasource(
          this.props.summaryTable[3],
          this.props.translationCache
        );
        this.setState({
          date: this.props.summaryTable[0],
          feedbackForm: this.props.feedbackForm
        });
      } else {
        this._generateColumnsFromDatasource(
          this.props.summaryTable.tagsList,
          this.props.translationCache
        );
        this.setState({
          date: this.props.summaryTable.time,
          feedbackForm: this.props.feedbackForm
        });
      }
    }
    if (prevProps.feedbackForm !== this.props.feedbackForm) {
      this.setState({
        feedbackForm: this.props.feedbackForm
      });
      this.props.refereshData("open");
    }
  }

  _generateColumnsFromDatasource = (dataSource, translationCache) => {
    let columns = [];
    if (dataSource && Array.isArray(dataSource) && dataSource.length > 0) {
      Object.keys(dataSource[0]).map((columnName) => {
        if (columnName !== "severity") {
          let col = {
            title:
              translationCache &&
              translationCache[_.startCase(columnName)] &&
              translationCache[_.startCase(columnName)][this.props.language]
                ? translationCache[_.startCase(columnName)][this.props.language]
                : _.startCase(columnName),
            dataIndex: columnName,
            key: columnName,
            render: (text, row, key) => {
              return {
                props: {
                  style: {
                    backgroundColor: "#000",
                    color: row.severity
                      ? this.getColor(row.severity)
                      : "#f9f9a3",
                  },
                },
                children: (
                  <span
                    onClick={(e) => {
                      this.setState({ openModal: true });
                    }}
                  >
                    {text}
                  </span>
                ),
              };
            },
          };
          columns.push(col);
        }
      });
    }

    this.setState({
      columns,
      dataSource,
      translationCache,
    });
  };

  getColor = (status) => {
    if (status === "critical") {
      return "rgb(251, 133, 133)";
    } else if (status === "medium") {
      return "#f7c66c";
    } else {
      return "#f9f9a3";
    }
  };

  handleOk = (e) => {
    const { feedbackForm } = this.state;

    let payload = {
      ...feedbackForm,
      "Notification Id": feedbackForm.NotificationId,
      "Tag Description": feedbackForm.TagDescription,
    };
    const siteId = localStorage.get("currentSite");
    const accessToken = localStorage.get("accessToken");
    const deviceTypeObject = {
      method: "POST",
      url: `/api/dashboard/feedback`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: payload,
    };
    return axios(deviceTypeObject)
      .then((response) => {
        this.setState({
          openModal: false,
        });
        this.props.refereshData("close");
      })
      .catch(function() {
        return "error";
      });
  };

  handleCancel = (e) => {
    this.setState({
      openModal: false,
    });
    this.props.refereshData("close");
  };

  render() {
    let { date, dataSource, columns, openModal, feedbackForm } = this.state;
    return (
      <div>
        {date ? (
          <div style={{ padding: "20px" }}>
            {dataSource.length > 0 ? (
              <Fragment>
                <div style={{ fontWeight: "bold", color: "white" }}>
                {moment.utc(date, "YYYY-MM-DD HH:mm").local().format('YYYY-MM-DD HH:mm')}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <div style={{ marginRight: "5px", color: "white" }}>
                    - {this.state.language !== "English" ? "低" : "Low"}
                  </div>
                  <div
                    style={{
                      width: "25px",
                      marginRight: "5px",
                      height: "20px",
                      backgroundColor: "#f9f9a3",
                    }}
                  ></div>
                  <div style={{ marginRight: "5px", color: "white" }}>
                    - {this.state.language !== "English" ? "中" : "Medium"}
                  </div>
                  <div
                    style={{
                      width: "25px",
                      marginRight: "5px",
                      height: "20px",
                      backgroundColor: "#f7c66c",
                    }}
                  ></div>
                  <div style={{ marginRight: "5px", color: "white" }}>
                    -{" "}
                    {this.state.language !== "English"
                      ? "クリティカル"
                      : "Critical"}
                  </div>{" "}
                  <div
                    style={{
                      width: "25px",
                      marginRight: "5px",
                      height: "20px",
                      backgroundColor: "rgb(251, 133, 133)",
                    }}
                  ></div>
                </div>

                <Table
                  className="TableLightWidget"
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  size={"middle"}
                  scroll={{ y: 240 }}
                  bordered
                />
              </Fragment>
            ) : Object.keys(this.props.feedbackForm).length > 0 ? (
              <AnomalyFeedbackTable
                notification={this.props.feedbackForm}
                openModel={true}
                refereshData={(refresh) => {
                  this.props.refereshData(refresh);
                }}
              />
            ) : null}
          </div>
        ) : null}
       <Modal
          visible={openModal}
          width={800}
          height={800}
          title={this.props && this.props.language !== "English" ? "予測フィードバック" : "Prediction Feedback" }
          okText="Submit"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form {...formItemLayout}>
            <Form.Item label={this.props && this.props.language !== "English" ? "時間" : "TimeStamp" }>
              <Input readOnly value={moment.utc(feedbackForm.Timestamp, "YYYY-MM-DD HH:mm").local().format('YYYY-MM-DD HH:mm')}></Input>
            </Form.Item>
            {feedbackForm.NotificationId ? (
            <Form.Item label={this.props && this.props.language !== "English" ? "通知ID" : "Notification Id" }>
              <Input readOnly value={feedbackForm.NotificationId}></Input>
            </Form.Item>
                ) : null}
            <Form.Item label={this.props && this.props.language !== "English" ? "タグ名" : "Tag Name" }>
              <Input readOnly value={feedbackForm.TagName}></Input>
            </Form.Item>
            {feedbackForm.NotificationId ? (
            <Form.Item label={this.props && this.props.language !== "English" ? "通知" : "Notification" }>
              <Input readOnly value={feedbackForm.TagDescription}></Input>
            </Form.Item>
              ) : null}
            <Form.Item label={this.props && this.props.language !== "English" ? "アクション" : "Action" }>
              {" "}
              <Radio.Group
                value={feedbackForm[`Action`]}
                onChange={(e) =>
                  this.setState({
                    feedbackForm: {
                      ...feedbackForm,
                      ["Action"]: e.target.value,
                    },
                  })
                }
              >
                <Radio value="yes">{this.props && this.props.language !== "English" ? "はい" : "Yes" }</Radio>
                <Radio value="no">{this.props && this.props.language !== "English" ? "いいえ" : "No" }</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={this.props && this.props.language !== "English" ? "コメント" : "Comment" }
            // onChange={(e) => this.setState({ feedbackText: e.target.value })}
            >
              <Input.TextArea
                value={feedbackForm[`Comment`]}
                onChange={(e) =>
                  this.setState({
                    feedbackForm: {
                      ...feedbackForm,
                      ["Comment"]: e.target.value,
                    },
                  })
                }
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
});

export default connect(mapStateToProps)(SummaryTable);
