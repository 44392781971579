import React from "react";
//import io from 'socket.io-client'
import { Modal, Button, Icon, Table, Form } from "antd";
//import { MailOutlined } from '@ant-design/icons';
import "./singlehmi.css";
import history from "../../commons/history";
import { getHmiAllParams, getReportNametoDownload } from "./hmicalls";
import queryString from "query-string";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getCurrentLanguage, getTranslationCache } from "../../selectors/language";
import { constant } from "./Constants";

// let socket = io.connect('172.18.216.71:8321')

const columns = [
  {
    title: "Parameter Name",
    dataIndex: "Parameter",
    key: "Parameter"
  },
  {
    title: "Value",
    dataIndex: "Value",
    key: "Value"
  },
  {
    title: "UOM",
    dataIndex: "UOM",
    key: "UOM"
  },
];



class Text extends React.Component {
  timeTicket = null;
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      visible: false,

      dataSource: [],
      //selectedRowKeys: [],
      selectedParameters: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",

    };
  }

  // onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
  //   let parameterlist = [];
  //   if (selectedRows.length <= 5) {
  //     const Parameters = selectedRows.map((item) => {
  //       parameterlist.push(item.Parameter);      
  //     });
  //   } else {
  //     message.error("You can select upto 5 parameters only ");
  //   }
  //   this.setState({ selectedRowKeys, selectedParameters: parameterlist });
  // };

  handleOk = () => {
    this.setState({ visible: false });
  };

  handleCancel = () => {
    clearInterval(this.timeTicket);
    this.setState({ visible: false });
  };

  componentWillUnmount() {
    clearInterval(this.timeTicket);
  }

  componentWillMount() {
   // this.getParams()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache || prevProps.language !== this.props.language) {
      this.setState({
        translationCache: this.props.translationCache, language: this.props.language
      });
    }
  }

  getParams = async () => {
    let{device,hmiName,hmiType,checkStatus}=this.props
      let allParams = await getHmiAllParams(device,hmiName,hmiType,checkStatus)
    this.setState({ dataSource: allParams })
  }

  _getDataSource = async (displayName, device, dataSource) => {

    let downloadExcelPayload = {
      "name": displayName,
      "consumption": `(${device}) `,
      "reportType": "table"
    }
    let reportName = await getReportNametoDownload(columns, dataSource, downloadExcelPayload)

    this.setState({

      reportName: reportName && reportName.file ? reportName.file : ""
    })


  }
  // onDownloadClick=()=>{
  //   this._getDataSource()
  // }
  onTrendClick = (parameters, deviceID) => {
    let data = queryString.stringify(
      { parameterlist: parameters, type: "sld", device: deviceID },
      { arrayFormat: "index" }
    );
    history.push(`/rubus/viewtrends?${data}`);
  };
  render() {
    // selectedRowKeys,
    const { selectedParameters, reportName, dataSource, translationCache, language } = this.state;
    // const rowSelection = {
    //   selectedRowKeys,
    //   onChange: this.onSelectedRowKeysChange,
    // };
    const columns = [
      {
        title: translationCache &&
          translationCache[`${constant.Select_Parameter}`] &&
          translationCache[`${constant.Select_Parameter}`] ?
          translationCache[`${constant.Select_Parameter}`] :
          `${constant.Select_Parameter}`,
        dataIndex: "Parameter",
        key: "Parameter"
      },
      {
        title: translationCache &&
          translationCache[`${constant.Value}`] &&
          translationCache[`${constant.Value}`] ?
          translationCache[`${constant.Value}`] :
          `${constant.Value}`,
        dataIndex: "Value",
        key: "Value"
      },
      {
        title: translationCache &&
          translationCache[`${constant.UOM}`] &&
          translationCache[`${constant.UOM}`] ?
          translationCache[`${constant.UOM}`] :
          `${constant.UOM}`,
        dataIndex: "UOM",
        key: "UOM"
      },
    ];
    let { x, y, fontSize, fontFamily, fill, payload, displayName, device } = this.props;

    return (
      <React.Fragment>
        <text
          x={x}
          y={y}
          style={{ cursor: "pointer" }}
          fontSize={fontSize}
          fontWeight={"800"}
          fontFamily={fontFamily}
          // fill={(this.props.payload >= this.props.conditions.lowerlimit && this.props.payload <= this.props.conditions.upperlimit) ? this.props.fill : 'red'}
          fill={fill}
          onClick={() => {
            if (this.timeInterval) {
              clearInterval(this.timeTicket);
            }
            this.setState({ visible: true });
            this.getParams()
            this._getDataSource(displayName, device, dataSource)
            this.timeTicket = setInterval(() => {
              this.getParams();
            }, 60000)
          }}
        >
          {(+payload).toFixed(2)}
        </text>
        {displayName !== "" || displayName ? (
          <Modal
            visible={this.state.visible}
            title={
              displayName !== undefined ? (
                <div>
                  {displayName}
                  <span>
                    {dataSource.length >= 1 ? (
                      <a href={`https://bpt.bmecomosolution.com/api/reports/${reportName}`}>
                        <Icon type="download" style={{ fontSize: "18px", padding: "5px" }} />
                      </a>
                    ) : null}
                    {selectedParameters.length >= 1 && selectedParameters.length <= 5 ? (
                      <p onClick={() => this.onTrendClick(selectedParameters, device)}>
                        <Icon type="area-chart" style={{ fontSize: "18px" }} />
                      </p>
                    ) : null}
                  </span>
                </div>
              ) : (
                <div>
                  Parameter List
                  <span style={{ float: "right", marginRight: "8%" }}>
                    {dataSource.length >= 1 ? (
                      <a href={`https://bpt.bmecomosolution.com/api/reports/${reportName}`}>
                        <Icon type="download" style={{ fontSize: "18px", padding: "5px" }} />
                      </a>
                    ) : null}

                    {selectedParameters.length >= 1 && selectedParameters.length <= 5 ? (
                      <p onClick={() => this.onTrendClick(selectedParameters, device)}>
                        <Icon type="area-chart" style={{ fontSize: "18px" }} />
                      </p>
                    ) : null}
                  </span>
                </div>
              )
            }
            width={750}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
                Close
              </Button>,
            ]}
          >
            <Table
              className="sldModalTable"
              // rowSelection={rowSelection}
              columns={columns}
              dataSource={dataSource}
            />

          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),

});
export default connect(mapStateToProps)(Form.create()(Text));
