import React from 'react'
import axios from 'axios'
import { Table, Form, Col, Input, Drawer, Button, Card, Row, message, Icon, Popconfirm } from 'antd'
import { constant } from '../../../Settings/Constants'
import localStorage from '../../../../utils/localStorage'
import { DrawerFooter, StyledTable, lightTheme } from '../style'
import { createStructuredSelector } from "reselect";
import { getCurrentLanguage, getTranslationCache } from '../../../../selectors/language';
import { connect } from "react-redux";
class Department extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            datasource: [],
            editObj: {},
            translationCache: props.translationCache || [],
        }
    }

    componentWillMount() {
        this._getDepartmentList()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.translationCache !== this.props.translationCache || prevProps.language !== this.props.language) {
            this.setState({
                translationCache: this.props.translationCache, language: this.props.language
            });
        }
    }

    _getDepartmentList = () => {
        const accessToken = localStorage.get('accessToken')
        let headers = {
            method: 'GET',
            url: `/api/department/list`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
        axios(headers)
            .then(({ data }) => {
                this.setState({ datasource: data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    openModal = () => {
        this.props.form.resetFields()
        this.setState({
            visible: true,
            editObj: {}
        });
    };

    _cancel = () => {
        this.props.form.resetFields()
        this.setState({
            visible: false,
        });
    };

    editDepartment = (editObj) => {
        this.props.form.resetFields()
        this.setState({
            editObj,
            visible: true,
        })
    }

    _createDepartment = (e) => {
        e.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (!error) {
                delete values._key;
                this._createDepartmentAPI(values)
            }
        })
    };

    _updateDepartment = (e) => {
        const { editObj } = this.state
        e.preventDefault();
        this.props.form.validateFields((error, values) => {
            values["Id"] = editObj["Id"]
            if (!error) {
                this._updateDepartmentAPI(values)
            }
        })
    };

    _deleteDepartment = (deleteKey) => {
        let payload = {
            Id: deleteKey
        }
        const accessToken = localStorage.get('accessToken')
        let orgDeleteHeaders = {
            method: 'POST',
            url: `/api/department/delete`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: payload
        }
        axios(orgDeleteHeaders)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    message.success("Department Deleted")
                    this._getDepartmentList()
                    this.setState({ visible: false })
                }
            })
            .catch(function (error) {
                console.log(error)
            })

    }

    _createDepartmentAPI = (createObject) => {
        const accessToken = localStorage.get('accessToken')
        let orgCreateHeaders = {
            method: 'POST',
            url: '/api/department/insert',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: createObject
        }
        axios(orgCreateHeaders)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    message.success("Department Saved")
                    this._getDepartmentList()
                    this.setState({ visible: false })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    _updateDepartmentAPI = (updateObject) => {

        const accessToken = localStorage.get('accessToken')
        let orgUpdateHeaders = {
            method: 'POST',
            url: `/api/department/update`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: updateObject
        }
        axios(orgUpdateHeaders)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    message.success("Department Updated")
                    this._getDepartmentList()
                    this.setState({ visible: false })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    translation = (keyword) => {
        let { translationCache } = this.props
        return translationCache &&
            translationCache[keyword] ?
            translationCache[keyword] : keyword
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const { datasource, visible, editObj } = this.state
        const columns = [{
            title: this.translation('Department Name'),
            dataIndex: 'Name',
        }, {
            title: this.translation('Department Code'),
            dataIndex: 'Code',
        }, {
            title: this.translation('Description'),
            dataIndex: 'Description'
        }, {
            title: this.translation('Action'),
            render: (text, record) =>
                <div>
                    <button type='button' onClick={() => this.editDepartment(record)} style={{ marginRight: '10px' }} className='ant-btn' >
                        <Icon type='edit' />
                    </button>
                    <Popconfirm
                        title={this.translation(constant.Are_you_sure_delete_Department)}
                        onConfirm={e => {
                            e.stopPropagation()
                            this._deleteDepartment(record.Id)
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button type='button' className='ant-btn'>
                            <Icon
                                type="delete"
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                            />
                        </button>
                    </Popconfirm>
                </div>
        }
        ]

        return (
            <Card bordered={false} >
                <Drawer
                    title={this.translation(constant.Create_Department)}
                    visible={visible}
                    width={600}
                    closable={false}
                    onClose={this._cancel}
                >

                    <Form layout="vertical">
                        <Row gutter={16}>
                            <Col span={24}>

                                <Form.Item label={this.translation('Department Name')}>
                                    {getFieldDecorator(`Name`, {
                                        initialValue: editObj.Name,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please enter Department name !',
                                            },
                                        ],
                                    })(<Input placeholder={this.translation("Please enter Department name" )}/>)
                                    }
                                </Form.Item>

                                <Form.Item label={this.translation('Department Code')}>
                                    {getFieldDecorator("Code", {
                                        initialValue: editObj.Code || '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please enter Department Code !',
                                            },
                                        ],
                                    })(<Input placeholder={this.translation("Please enter Department Code " )}/>)}
                                </Form.Item>

                                <Form.Item label={this.translation('Description')}>
                                    {getFieldDecorator(`Description`, {
                                        initialValue: editObj.Description,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please enter Description !',
                                            },
                                        ],
                                    })(<Input placeholder={this.translation("Please enter Description" )}/>)
                                    }
                                </Form.Item>

                            </Col>
                        </Row>
                    </Form>
                    <DrawerFooter>
                        <Button onClick={this._cancel} style={{ marginRight: 8 }}>{this.translation("Cancel")}</Button>
                        {
                            !editObj.Id ?
                                <Button type="primary" onClick={this._createDepartment}>{this.translation("Create Department")}</Button>
                                :
                                <Button type="primary" onClick={this._updateDepartment}>{this.translation("Update Department")}</Button>
                        }
                    </DrawerFooter>
                </Drawer>
                <Button type="primary" size={"large"}
                    style={{ marginBottom: '10px', float: "right" }}
                    onClick={this.openModal}>{this.translation(constant.Add_Department)}</Button>
                <StyledTable theme={lightTheme}>
                    <Table
                        columns={columns}
                        dataSource={datasource}
                        pagination={
                            datasource.length > 10
                                ? {
                                    pageSize: "10"
                                }
                                : false
                        }
                    />
                </StyledTable>
            </Card>

        )
    }
}

const mapStateToProps = createStructuredSelector({
    language: getCurrentLanguage(),
    translationCache: getTranslationCache(),
  });

export default connect(mapStateToProps)(Form.create()(Department));
