import React, { Fragment } from "react";
import {
  Input,
  Select,
  Popover,
  Divider,
  TimePicker,
} from "antd";

import {
  Boxdiv,
  StyledAppdiv,
  RuleTypeSelectionDiv,
  BlockedLabelBlack,
  RestConditionSelect,
  ActionSelectionDiv,
  AddParagraph,
  BlankDiv,
  TagNameSelect,
  OperaterSelect,
  ValueInput,
  ButtonBoxDiv,
  MarginRightButton,
  OnchangeCheckbox,
  ActionCheckbox,
  TextParagraph,
  LogicAddButton,
} from "../style";
import { actionList } from "../utils/const";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;

export default function NoDeviceTab(props) {
  const {
    inputList,
    conif,
    conelseif,
    conelse,
    elsifLength,
    elseList,
    nodeviceparametersList,
    ruleType,
    batchPriority,
    onChange,
    noDeviceElseprevisible,
    noDeviceElseroot,
    noDevicePreventiveVisible,
    noDeviceRootCauseVis,
  } = props.config;
  const {
    handleelseif,
    handleelse,
    handleDelay,
    checkonChange,
    handleTagSelect,
    handleInputChange,
    handleAddClick,
    handleRuleType,
    handleRadioButton,
    handleRemoveClick,
    handleVisibleChange,
    hanblePopoverOnclick,
    handleTextAreaChange,
    handleConditionchange,
  } = props.Methods;

  return (
    <StyledAppdiv>
      <RuleTypeSelectionDiv>
        <div style={{ marginRight: "12%" }}>
          <BlockedLabelBlack>Type</BlockedLabelBlack>
          <Select
            placeholder="RuleType"
            style={{ width: "120px", display: "inline-block" }}
            value={ruleType}
            onChange={(value) => handleRuleType(value, "ruleType")}
          >
            <Option value="noDeviceRealTime">Real Time</Option>
            <Option value="noDeviceBatch">Batch</Option>
            <Option value="noDeviceHybrid">Hybrid</Option>
          </Select>
        </div>
        {ruleType === "noDeviceBatch" ? (
          <div style={{ marginRight: "12%" }}>
            <label style={{ display: "block" }}>Priority</label>
            <Select
              placeholder="Priority"
              style={{ width: "120px", display: "inline-block" }}
              value={batchPriority === "" ? undefined : batchPriority}
              onChange={(value) => handleRuleType(value, "batchPriority")}
            >
              {props.config.priorityTypes.map((type) => (
                <Option value={type.PriorityType}>{type.PriorityType}</Option>
              ))}
            </Select>
          </div>
        ) : null}
        <OnchangeCheckbox
          checked={onChange}
          onChange={(value) => handleRadioButton(value)}
        >
          OnChange
        </OnchangeCheckbox>
      </RuleTypeSelectionDiv>
      {conif
        ? inputList.map((x, i) => {
          return (
            <Boxdiv key={`if${i}`}>
              {i === 0 ? <BlockedLabelBlack>if</BlockedLabelBlack> : null}
              {i > 0 ? (
                <RestConditionSelect
                  name="operater"
                  placeholder="Select Condition"
                  size="default"
                  value={x["54765"] === "" ? undefined : x["54765"]}
                  onChange={(e) => handleConditionchange(e, i, "inputList")}
                >
                  <Option value="&&">AND</Option>
                  <Option value="||">OR</Option>
                </RestConditionSelect>
              ) : null}

              <TagNameSelect
                showSearch
                name="tagName"
                size="default"
                placeholder="Select Parameter"
                value={x.tagName === "" ? undefined : x.tagName}
                onChange={(e) => handleTagSelect(e, i, "inputList")}
              >
                {nodeviceparametersList.map((param, j) => {
                  return (
                    <Option
                      key={`ifparam${i}${j}`}
                      value={param.ParameterCode}
                    >
                      {param.ParameterCode}
                    </Option>
                  );
                })}
              </TagNameSelect>
              <OperaterSelect
                name="operater"
                size="default"
                placeholder="Select operator"
                value={x.operater === "" ? undefined : x.operater}
                onChange={(e) =>
                  handleInputChange(e, i, "select", "inputList")
                }
              >
                <Option value="==" title="EqualTo">
                  ==
                </Option>
                <Option value="!=" title="Not EqualTo">
                  !=
                </Option>
                <Option value=">" title="Greater Than">
                  {">"}
                </Option>
                <Option value="<" title="Less Than">
                  {"<"}
                </Option>
                <Option value=">=" title="Greater Than Or EqualTo">
                  {">="}
                </Option>
                <Option value="<=" title="Less Than Or Equal To">
                  {"<="}
                </Option>
              </OperaterSelect>
              <ValueInput
                size="default"
                name="value"
                placeholder="value"
                value={x.value}
                onChange={(e) =>
                  handleInputChange(e, i, "input", "inputList")
                }
              />
              <ButtonBoxDiv>
                {inputList.length !== 1 && (
                  <MarginRightButton
                    size="default"
                    onClick={() => handleRemoveClick(i, "inputList")}
                  >
                    Remove
                  </MarginRightButton>
                )}
                {inputList.length - 1 === i && (
                  <Fragment>
                    <LogicAddButton
                      type={"primary"}
                      size="default"
                      onClick={() => handleAddClick("inputList")}
                    >
                      Add
                    </LogicAddButton>
                    <Popover
                      content={
                        <div>
                          <TextArea
                            value={inputList[i]["priventive"]}
                            rows={5}
                            maxLength="150"
                            onChange={(e) =>
                              handleTextAreaChange(
                                e,
                                i,
                                "inputList",
                                "priventive"
                              )
                            }
                          />

                          <span
                            style={{ color: "#1890ff", cursor: "pointer" }}
                            onClick={(e) => {
                              hanblePopoverOnclick(
                                i,
                                "inputList",
                                "close",
                                "priventive",
                                "noDevicePreventiveVisible"
                              );
                            }}
                          >
                            Cancel
                          </span>
                          <span
                            style={{ color: "#1890ff", cursor: "pointer",float: "right" }}
                            onClick={(e) => {
                              hanblePopoverOnclick(
                                i,
                                "inputList",
                                "ok",
                                "noDevicePreventiveVisible"
                              );
                            }}
                          >
                            Ok
                          </span>
                        </div>
                      }
                      visible={noDevicePreventiveVisible}
                      onVisibleChange={(value) =>
                        handleVisibleChange(
                          value,
                          "noDevicePreventiveVisible"
                        )
                      }
                      title="Preventive Measures"
                      trigger="click"
                    >
                      <TextParagraph>Preventive Measures</TextParagraph>
                    </Popover>
                    <Divider type="vertical" />
                    <Popover
                      content={
                        <div>
                          <TextArea
                            value={inputList[i]["rootcause"]}
                            rows={5}
                            maxLength="150"
                            onChange={(e) =>
                              handleTextAreaChange(
                                e,
                                i,
                                "inputList",
                                "rootcause"
                              )
                            }
                          />
                        <span
                            style={{ color: "#1890ff", cursor: "pointer" }}
                            onClick={(e) => {
                              hanblePopoverOnclick(
                                i,
                                "inputList",
                                "close",
                                "rootcause",
                                "noDeviceRootCauseVis"
                              );
                            }}
                          >
                            Cancel
                          </span>
                          <span
                            style={{ color: "#1890ff", cursor: "pointer",float: "right" }}
                            onClick={(e) => {
                              hanblePopoverOnclick(
                                i,
                                "inputList",
                                "ok",
                                "noDeviceRootCauseVis"
                              );
                            }}
                          >
                            Ok
                          </span>
                        </div>
                      }
                      visible={noDeviceRootCauseVis}
                      onVisibleChange={(value) =>
                        handleVisibleChange(value, "noDeviceRootCauseVis")
                      }
                      title=" RootCause Analysis"
                      trigger="click"
                    >
                      <TextParagraph
                        style={{
                          color: "blue",
                          display: "inline-block",
                        }}
                      >
                        RootCause Analysis
                      </TextParagraph>
                    </Popover>
                  </Fragment>
                )}
              </ButtonBoxDiv>
              {i === inputList.length - 1 ? (
                <ActionSelectionDiv>
                  <BlockedLabelBlack>
                    Action<span style={{ color: "red" }}>*</span>
                  </BlockedLabelBlack>
                  <ActionCheckbox
                    options={actionList}
                    value={props.config.inputList[i]["action"]}
                    onChange={(checkedValues) => {
                      checkonChange(checkedValues, "inputList", i);
                    }}
                  />

                  <TimePicker
                    defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                    value={
                      props.config.inputList[i]["delay"] === undefined
                        ? undefined
                        : moment(
                          props.config.inputList[i]["delay"][1],
                          "HH:mm:ss"
                        )
                    }
                    onChange={(e) => handleDelay(e, "inputList", i)}
                    placeholder="Select Delay"
                    allowClear={false}
                    size="default"
                  />
                </ActionSelectionDiv>
              ) : null}
            </Boxdiv>
          );
        })
        : null}
      {conelseif
        ? elsifLength.map((elsepropName, index) => {
          return props.config[elsepropName].map((x, i) => {
            return (
              <Boxdiv key={`${index}${i}`}>
                {i === 0 ? (
                  <BlockedLabelBlack>else if</BlockedLabelBlack>
                ) : null}
                {i > 0 ? (
                  <RestConditionSelect
                    size="default"
                    name="operater"
                    value={x["54765"] === "" ? undefined : x["54765"]}
                    placeholder="Select condition"
                    onChange={(e) =>
                      handleConditionchange(e, i, elsepropName)
                    }
                  >
                    <Option value="&&">AND</Option>
                    <Option value="||">OR</Option>
                  </RestConditionSelect>
                ) : null}

                <TagNameSelect
                  showSearch
                  name="tagName"
                  size="default"
                  placeholder="Select Parameter"
                  value={x.tagName === "" ? undefined : x.tagName}
                  onChange={(e) => handleTagSelect(e, i, elsepropName)}
                >
                  {nodeviceparametersList.map((param, j) => {
                    return (
                      <Option
                        key={`elseifparam${i}${j}`}
                        value={param.ParameterCode}
                      >
                        {param.ParameterCode}
                      </Option>
                    );
                  })}
                </TagNameSelect>
                <OperaterSelect
                  size="default"
                  value={x.operater === "" ? undefined : x.operater}
                  name="operater"
                  placeholder="Select operator"
                  onChange={(e) =>
                    handleInputChange(e, i, "select", elsepropName)
                  }
                >
                  <Option value="==" title="EqualTo">
                    ==
                  </Option>
                  <Option value="!=" title="Not EqualTo">
                    !=
                  </Option>
                  <Option value=">" title="Greater Than">
                    {">"}
                  </Option>
                  <Option value="<" title="Less Than">
                    {"<"}
                  </Option>
                  <Option value=">=" title="Greater Than Or EqualTo">
                    {">="}
                  </Option>
                  <Option value="<=" title="Less Than Or Equal To">
                    {"<="}
                  </Option>
                </OperaterSelect>
                <ValueInput
                  name="value"
                  size="default"
                  placeholder="value"
                  value={x.value}
                  onChange={(e) =>
                    handleInputChange(e, i, "input", elsepropName)
                  }
                />
                <ButtonBoxDiv>
                  {props.config[elsepropName].length !== 1 && (
                    <MarginRightButton
                      size="default"
                      onClick={() => handleRemoveClick(i, elsepropName)}
                    >
                      Remove
                    </MarginRightButton>
                  )}
                  {props.config[elsepropName].length - 1 === i && (
                    <Fragment>
                      <LogicAddButton
                        type={"primary"}
                        size="default"
                        onClick={() => handleAddClick(elsepropName)}
                      >
                        Add
                      </LogicAddButton>
                      <Popover
                        content={
                          <div>
                            <TextArea
                              value={
                                props.config[elsepropName][i]["priventive"]
                              }
                              rows={5}
                              maxLength="150"
                              onChange={(e) =>
                                handleTextAreaChange(
                                  e,
                                  i,
                                  elsepropName,
                                  "priventive"
                                )
                              }
                            />
                            <span
                            style={{ color: "#1890ff", cursor: "pointer" }}
                              onClick={(e) => {
                                hanblePopoverOnclick(
                                  i,
                                  elsepropName,
                                  "close",
                                  "priventive",
                                  `noDeviceprevisible${elsepropName}`
                                );
                              }}
                            >
                              Cancel
                            </span>
                            <span
                            style={{ color: "#1890ff", cursor: "pointer",float: "right"  }}
                              onClick={(e) => {
                                hanblePopoverOnclick(
                                  i,
                                  elsepropName,
                                  "ok",
                                  `noDeviceprevisible${elsepropName}`
                                );
                              }}
                            >
                              Ok
                            </span>
                          </div>
                        }
                        visible={
                          props.config[`noDeviceprevisible${elsepropName}`]
                        }
                        onVisibleChange={(value) =>
                          handleVisibleChange(
                            value,
                            `noDeviceprevisible${elsepropName}`
                          )
                        }
                        title="Preventive Measures"
                        trigger="click"
                      >
                        <TextParagraph>Preventive Measures</TextParagraph>
                      </Popover>
                      <Divider type="vertical" />
                      <Popover
                        content={
                          <div>
                            <TextArea
                              value={
                                props.config[elsepropName][i]["rootcause"]
                              }
                              rows={5}
                              maxLength="150"
                              onChange={(e) =>
                                handleTextAreaChange(
                                  e,
                                  i,
                                  elsepropName,
                                  "rootcause"
                                )
                              }
                            />
                            <span
                            style={{ color: "#1890ff", cursor: "pointer" }}
                              onClick={(e) => {
                                hanblePopoverOnclick(
                                  i,
                                  elsepropName,
                                  "close",
                                  "rootcause",
                                  `noDeviceelseifroot${elsepropName}`
                                );
                              }}
                            >
                              Cancel
                            </span>
                            <span
                            style={{ color: "#1890ff", cursor: "pointer" , float: "right"}}
                              onClick={(e) => {
                                hanblePopoverOnclick(
                                  i,
                                  elsepropName,
                                  "ok",
                                  `noDeviceelseifroot${elsepropName}`
                                );
                              }}
                            >
                              Ok
                            </span>
                          </div>
                        }
                        visible={
                          props.config[`noDeviceelseifroot${elsepropName}`]
                        }
                        onVisibleChange={(value) =>
                          handleVisibleChange(
                            value,
                            `noDeviceelseifroot${elsepropName}`
                          )
                        }
                        title=" RootCause Analysis"
                        trigger="click"
                      >
                        <TextParagraph>RootCause Analysis</TextParagraph>
                      </Popover>
                    </Fragment>
                  )}
                </ButtonBoxDiv>
                {i === props.config[elsepropName].length - 1 ? (
                  <ActionSelectionDiv>
                    <BlockedLabelBlack>
                      Action<span style={{ color: "red" }}>*</span>
                    </BlockedLabelBlack>
                    <ActionCheckbox
                      options={actionList}
                      value={props.config[elsepropName][i]["action"]}
                      onChange={(checkedValues) => {
                        checkonChange(checkedValues, elsepropName, i);
                      }}
                    />
                    <TimePicker
                      defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                      value={
                        props.config[elsepropName][i]["delay"] === undefined
                          ? undefined
                          : moment(
                            props.config[elsepropName][i]["delay"][1],
                            "HH:mm:ss"
                          )
                      }
                      onChange={(e) => handleDelay(e, elsepropName, i)}
                      placeholder="Select Delay"
                      allowClear={false}
                      size="default"
                    />
                  </ActionSelectionDiv>
                ) : null}
              </Boxdiv>
            );
          });
        })
        : null}

      {conelse
        ? elseList.map((x, i) => {
          return (
            <Boxdiv key={`else${i}`}>
              {i === 0 ? <BlockedLabelBlack>else</BlockedLabelBlack> : null}
              {i > 0 ? (
                <RestConditionSelect
                  name="operater"
                  size="default"
                  value={x["54765"] === "" ? undefined : x["54765"]}
                  placeholder="Select condition"
                  onChange={(e) => handleConditionchange(e, i, "elseList")}
                >
                  <Option value="&&">AND</Option>
                  <Option value="||">OR</Option>
                </RestConditionSelect>
              ) : null}
              <TagNameSelect
                showSearch
                name="tagName"
                size="default"
                placeholder="Select Parameter"
                value={x.tagName === "" ? undefined : x.tagName}
                onChange={(e) => handleTagSelect(e, i, "elseList")}
              >
                {nodeviceparametersList.map((param, j) => {
                  return (
                    <Option
                      key={`elseparam${i}${j}`}
                      value={param.ParameterCode}
                    >
                      {param.ParameterCode}
                    </Option>
                  );
                })}
              </TagNameSelect>
              <OperaterSelect
                name="operater"
                size="default"
                placeholder="Select operator"
                value={x.operater === "" ? undefined : x.operater}
                onChange={(e) =>
                  handleInputChange(e, i, "select", "elseList")
                }
              >
                <Option value="==" title="EqualTo">
                  ==
                </Option>
                <Option value="!=" title="Not EqualTo">
                  !=
                </Option>
                <Option value=">" title="Greater Than">
                  {">"}
                </Option>
                <Option value="<" title="Less Than">
                  {"<"}
                </Option>
                <Option value=">=" title="Greater Than Or EqualTo">
                  {">="}
                </Option>
                <Option value="<=" title="Less Than Or Equal To">
                  {"<="}
                </Option>
              </OperaterSelect>
              <ValueInput
                size="default"
                name="value"
                placeholder="value"
                value={x.value}
                onChange={(e) => handleInputChange(e, i, "input", "elseList")}
              />
              <ButtonBoxDiv>
                {elseList.length !== 1 && (
                  <MarginRightButton
                    size="default"
                    onClick={() => handleRemoveClick(i, "elseList")}
                  >
                    Remove
                  </MarginRightButton>
                )}
                {elseList.length - 1 === i && (
                  <Fragment>
                    <LogicAddButton
                      type={"primary"}
                      size="default"
                      onClick={() => handleAddClick("elseList")}
                    >
                      Add
                    </LogicAddButton>
                    <Popover
                      content={
                        <div>
                          <TextArea
                            value={elseList[i]["priventive"]}
                            rows={5}
                            maxLength="150"
                            onChange={(e) =>
                              handleTextAreaChange(
                                e,
                                i,
                                "elseList",
                                "priventive"
                              )
                            }
                          />
                         <span
                            style={{ color: "#1890ff", cursor: "pointer" }}
                            onClick={(e) => {
                              hanblePopoverOnclick(
                                i,
                                "elseList",
                                "close",
                                "priventive",
                                `noDeviceElseprevisible`
                              );
                            }}
                          >
                            Cancel
                          </span>
                          <span
                            style={{ color: "#1890ff", cursor: "pointer",float: "right"  }}
                            onClick={(e) => {
                              hanblePopoverOnclick(
                                i,
                                "elseList",
                                "ok",
                                `noDeviceElseprevisible`
                              );
                            }}
                          >
                            Ok
                          </span>
                        </div>
                      }
                      visible={noDeviceElseprevisible}
                      onVisibleChange={(value) =>
                        handleVisibleChange(value, `noDeviceElseprevisible`)
                      }
                      title="Preventive Measures"
                      trigger="click"
                    >
                      <TextParagraph>Preventive Measures</TextParagraph>
                    </Popover>
                    <Divider type="vertical" />
                    <Popover
                      content={
                        <div>
                          <TextArea
                            value={elseList[i]["rootcause"]}
                            rows={5}
                            maxLength="150"
                            onChange={(e) =>
                              handleTextAreaChange(
                                e,
                                i,
                                "elseList",
                                "rootcause"
                              )
                            }
                          />
                          <span
                            style={{ color: "#1890ff", cursor: "pointer" }}
                            onClick={(e) => {
                              hanblePopoverOnclick(
                                i,
                                "elseList",
                                "close",
                                "rootcause",
                                `noDeviceElseroot`
                              );
                            }}
                          >
                            Cancel
                          </span>
                          <span
                            style={{ color: "#1890ff", cursor: "pointer",float: "right"}}
                            onClick={(e) => {
                              hanblePopoverOnclick(
                                i,
                                "elseList",
                                "ok",
                                `noDeviceElseroot`
                              );
                            }}
                          >
                            Ok
                          </span>
                        </div>
                      }
                      visible={noDeviceElseroot}
                      onVisibleChange={(value) =>
                        handleVisibleChange(value, `noDeviceElseroot`)
                      }
                      title=" RootCause Analysis"
                      trigger="click"
                    >
                      <TextParagraph>RootCause Analysis</TextParagraph>
                    </Popover>
                  </Fragment>
                )}
              </ButtonBoxDiv>
              {i === elseList.length - 1 ? (
                <ActionSelectionDiv>
                  <BlockedLabelBlack>
                    Action<span style={{ color: "red" }}>*</span>
                  </BlockedLabelBlack>
                  <ActionCheckbox
                    options={actionList}
                    value={props.config.elseList[i]["action"]}
                    onChange={(checkedValues) => {
                      checkonChange(checkedValues, "elseList", i);
                    }}
                  />

                  <TimePicker
                    defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                    value={
                      props.config.elseList[i]["delay"] === undefined
                        ? undefined
                        : moment(
                          props.config.elseList[i]["delay"][1],
                          "HH:mm:ss"
                        )
                    }
                    onChange={(e) => handleDelay(e, "elseList", i)}
                    placeholder="Select Delay"
                    allowClear={false}
                    size="default"
                  />
                </ActionSelectionDiv>
              ) : null}
            </Boxdiv>
          );
        })
        : null}
      <AddParagraph onClick={handleelse}>Add else</AddParagraph>
      <AddParagraph onClick={handleelseif}>Add else if</AddParagraph>
      <BlankDiv></BlankDiv>
    </StyledAppdiv>
  );
}
