import styled from "styled-components";
import { Icon } from "antd";
import { Link } from "react-router-dom";


export const DatePickerSlider = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  margin-bottom: 20px;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.85) !important;
`;
export const DatePickerIcon = styled(Icon)`
  font-size: 33px;
  cursor: pointer;
  color: white;
`;
export const darkTheme = {
  text: "rgba(0,0,0,.85)",
  even: "#595959",
  odd: "#8c8c8c",
  hover: "#f5f5f5",
};

export const lightTheme = {
  text: "rgba(0,0,0,.85)",
  even: "#d9d9d9",
  odd: "#efefef",
  hover: "#f5f5f5",
};

export const StyledTable = styled.table`
  caption-side: top;


  color: ${(props) => props.theme.text} !important;

  width: 100%;
  /* border-collapse: separate; */
  /* border-spacing: 5px 10px; */

  caption-side: bottom;
  /* empty-cell: show | hide;  */
  /* empty-cell is a property of table or the cells themselves */

  /* vertical-align: baseline | sub | super | text-top | 
                text-bottom | middle | top | bottom | 
                <percentage> | <length> */

  /* tbody {
    vertical-align: top;
  }              */
  td,
  th {
    border: none;
  }
  /* td,
  th {
      color:${(props) => props.theme.text} !important;
    border: 1px solid red;
   
  } */

  td {
    padding: 11px 10px;

  }
  .ant-table-thead > tr > th{
    font-weight:bold !important
    font-size: 1em !important;
     // color:red !important
  }
  text[style] {
    // font-weight:bold !important
    font-size: 1em !important;
    color: ${(props) => props.theme.text} !important;
 }
  tbody tr {
    :nth-of-type(odd) {
      background-color:${(props) => props.theme.odd} !important;
    }
    :hover {
      background-color:${(props) => props.theme.hover} !important; 
    }
  }
  tbody tr {
    :nth-of-type(even) {
      background-color:${(props) => props.theme.even} !important;
    }
    :hover {
      background-color:${(props) => props.theme.hover} !important; 
    }
  }
  thead > tr {
    color: ${(props) => props.theme.text} !important;
    background-color: #262626 !important;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;
export const LinkOverlay = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  :hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
  }
`;
