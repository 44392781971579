import React, { Fragment } from 'react'
import moment from 'moment'
import { Table, Spin, Tag } from 'antd'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getChartDataWithoutSelection, getChartDataWithSelection } from "../ChartCalls";
import { tableTranslation } from "../../Utils/Adapter/translationAdapter";
import { getCurrentLanguage, getTranslationCache } from '../../../../selectors/language'
import { Icon } from "antd";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
// import { excelDownloadUrl } from "./ExcelAdapters/const";
import { notificationtableMock } from "../../Mock/MockJson";
import { defaultRefreshTime } from '../constant.json'

import 'antd/dist/antd.css'
import "../CSS/Table.css";


class NotificationTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [],
      tabledata: [],
      loading: false,
      raisedEvents: 0,
      closedEvents: 0,
      dataSource: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    }
  }

  columns = [];

  tabledata = [];

  config = {
    redirectTo: 'counter'
  }

  timeTicket = null;
  abortController = new window.AbortController();
  rowUpdateInterval = null;
  requestCheck = true;
  componentWillMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket)
    }
  }

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = ""
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props);
      }

    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange || prevProps.dayMonthYear !== this.props.dayMonthYear
    ) {
      this.getData(this.props);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  updateData = async (data) => {
    try {
      this.columns = []
      this.tabledata = []
      const columnNames = Object.keys(data[0])

      columnNames.map((value, key) => {
        //   if (value === "tagname") {
        if (value.length > 0 && value !== "color" && value !== "noData") {
          this.columns.push({
            title: value,
            dataIndex: value,
            key: value,
            render(text, record, key) {
              return {
                props: {
                  //style: { background: Math.abs(key % 2) === 1 ? '#1a1a1a' : '#000' },
                  style: { color: record && record.color ? record.color : '#000' },
                },
                children: <text style={{ color: record && record.color ? record.color : '#8c8c8c' }}>{text}</text>,
              };
            },
          })
        }
        //  } else if (value === "tagvalue") {
        //  this.columns.push({
        //   title: "Tag Value",
        //   dataIndex: value,
        //  key: value,
        //   render: text => <text style={{ color: "orange" }}>{text}</text>
        //  });
        // }
      })

      if (data && data[0] && data[0]['noData']) {

      } else {
        data.map((dataval, dataindex) => {
          dataval.key = dataindex + 1
          this.tabledata.push(dataval)
        })
      }


      // this.setState({
      //   columns: this.columns,
      //   tabledata: this.tabledata.slice(0, 2)
      // });

      // let columnDisplaySize = (() => {
      //   var columncount = 0;
      //   let inner = () => {
      //     if (columncount < this.tabledata.length) {
      //       columncount = columncount + 2;

      //       var sliced = this.tabledata.slice(columncount - 2, columncount);
      //       return sliced;
      //     } else {
      //       columncount = 2;
      //       var sliced = this.tabledata.slice(columncount - 2, columncount);
      //       return sliced;
      //     }
      //   };
      //   return inner;
      // })();

      // this.rowUpdateInterval = setInterval(() => {
      // alert(JSON.stringify(this.columns))
      let translationTable = await tableTranslation(this.columns, this.props.translationCache, this.props.language)
      this.setState({
        columns: translationTable,
        tabledata: this.tabledata
      })
      // }, 3000);
    } catch (e) {
      this.setState({ columns: [], tabledata: [] })
    }
  };

  getData = async () => {
    let json = []
    this.requestCheck = false
    let { graphprops } = this.props
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false
    this.setState({ loading: true });
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = notificationtableMock;
      this.requestCheck = true
    } else {
      if ((graphprops && graphprops.dashboardType === 'dynamic' && this.props.deviceId) ||
        (graphprops && graphprops.dateExists === 'true' && this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shift === 'true' && this.props.shift) ||
        (graphprops && graphprops.dateRangeExists === 'true' && this.props.selectionDateRange) ||
        (graphprops && graphprops.dayMonthYearExists === 'true' && this.props.dayMonthYear)
      ) {

        json = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController
        )
        this.requestCheck = false
        this.setState({ loading: false });
      } else {
        json = await getChartDataWithoutSelection(graphprops, this.abortController)
        this.requestCheck = false
        this.setState({ loading: false });
      }
    }
    if ((json && json.message) || (Array.isArray(json) && (json.length === 0) &&
      (graphprops.enableMock !== true))) {

      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
      }

    } else {
      let eventsData = json && Array.isArray(json) && json.length > 0 && json[0] && json[0].eventStatus
      if (json && Array.isArray(json) && json.length > 0 && json[0].eventStatus) {
        this.setState({ raisedEvents: eventsData.raisedEvents });
        this.setState({
          closedEvents: eventsData.closedEvents,
          //   status: data[0].status,
        });
      }
      let dataSource = json && Array.isArray(json) && json.length > 0 && json[0] && json[0].tabledata
      this.setState({ dataSource, refreshDateTime: moment() })
      this.updateData(dataSource)

    }
    this.setState({
      errorInAPI,
      noDataInDB
    })

  };
  filteringEvents = (events, tagName) => {
    let dataSource = this.state.dataSource
    let tableData = []
    if (events ==="raisedEvents") {
      tableData = dataSource.filter(function (item) {
        return item.Status === tagName
      })

    } else if (events === "closedEvents") {
      tableData = dataSource.filter(function (item) {
        return item.Status === tagName
      })
    }
    this.setState({ tabledata: tableData })

  }
  render() {
    // let theme = this.props.selectedThemeing;
    const { loading, columns, raisedEvents, closedEvents, reportName, excelDownload, refreshDateTime, errorInAPI, noDataInDB } = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <span>
              {columns && columns.length > 0 ?
                <div>

                  <div className="notificationMain">
                    <div className="notificationDiv" onClick={() => this.filteringEvents("raisedEvents", this.props.graphprops.activateLights)}>
                      <Icon
                        type="bell"
                        //  onClick={()=>this.filteringEvents("closedvents")}
                        theme="filled"
                        style={{
                          marginRight: "20px",
                          fontWeight: "800",
                          fontSize: "2.5em",
                          color: this.props.graphprops.iconColor ? this.props.graphprops.iconColor : "#389e0d"
                        }}
                      />
                      <span style={{ color: this.props.graphprops.iconColor ? this.props.graphprops.iconColor : "green" }}>{raisedEvents}</span>
                    </div>

                    <div className="notificationDiv1" onClick={() => this.filteringEvents("closedEvents", this.props.graphprops.deActivateLights)}>
                      <Icon
                        type="bell"

                        theme="filled"
                        style={{
                          marginRight: "20px",
                          fontWeight: "800",
                          fontSize: "2.5em",
                          color: this.props.graphprops.titleColor ? this.props.graphprops.titleColor : "red"
                        }}
                      />
                      <span style={{
                        color: this.props.graphprops.titleColor ? this.props.graphprops.titleColor : "red"
                      }}>{closedEvents}</span>
                    </div>

                  </div>
                  <div>
                    <Table
                      scroll={{ x: (this.tabledata.length > 5) ? true : false }}
                      className="TableWidget"
                      style={{ padding: '0px 10px', fontSize: '13px !important' }}
                      columns={this.state.columns}
                      dataSource={this.state.tabledata}
                      pagination={{ pageSize: '5' }}
                    />
                  </div>
                </div>

                :
                <div
                  style={{
                    textAlign: "center",
                    color: "white",
                    marginTop: "10%"
                  }}
                >
                  <h2 style={{ color: "white" }}>
                    <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                  </h2>
                </div>
              }
            </span>
        }


        {/* {
          reportName && excelDownload === "true" ? (
            <a
              href={`${excelDownloadUrl}/${reportName}`}
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              <Icon
                type="download"
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginRight: "25px",
                  marginTop: "6px",
                }}
              />
            </a>
          ) : null
        } */}
      </Fragment >

    )
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache()
})


export default connect(mapStateToProps)(NotificationTable)
