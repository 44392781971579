import React from 'react'
import axios from 'axios'
import history from '../../../commons/history'
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Form, message, Input, Button } from 'antd'

import { HeaderLogo, Welcome } from './styles'
import rubusLogo from '../../../images/rubus_new/horizontal_logo.png'

import { getCurrentLanguage, getTranslationCache } from "../../../selectors/language";
import { constant } from "../Constants";

const FormItem = Form.Item

class ChangeFirstPasswordComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      confirmDirty: false,
      translationCache: props.translationCache || [],
      language: props.language || "English",
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    let path = (history.location.pathname).split('/changeFirstPassword/')
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this._changePasswordAPI({
          ...values,
          identifier: path[1]
        })
      }
    })
  }
  _changePasswordAPI = (value) => {
    const changePassword = {
      method: 'POST',
      url: '/api/auth/changeFirstPassword ',
      headers: {
        // Authorization: `Bearer ${accessToken}`
      },
      data: value
    }
    axios(changePassword)
      .then((response) => {
        message.success(response.data.message)
        history.push('/login')
      })
      .catch((error) => {
        console.log("Error", error);
        message.error(error.message)
      })
  }
  handleConfirmBlur = (e) => {
    const value = e.target.value
    this.setState({ confirmDirty: this.state.confirmDirty || !!value })
  }

  compareToFirstPassword = (value) => {
    const form = this.props.form
    if (value && value !== form.getFieldValue('password')) {
      // callback('Two passwords that you enter is inconsistent!')
    } else {
      // callback()
    }
  }

  validateToNextPassword = (value) => {
    const form = this.props.form
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirmPassword'], { force: true })
    }
    // callback()
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { language, translationCache } = this.state

    return (
      <section style={{ width: '100%', textAlign: 'center' }} >
        <HeaderLogo><img src={rubusLogo} style={{ height: '42px' }} /></HeaderLogo>
        <Welcome>Change Password</Welcome>

        <Form onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('oldPassword', {
              rules: [{ required: true, message: 'Current Password' }],
              initialValue: ''
            })(
              <Input.Password style={{ width: '380px' }} placeholder="Current Password" />
            )}
            <div>You must provide your current password in order to change it.</div>
          </FormItem>
          <FormItem>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'New Password' }]
            })(
              <Input.Password style={{ width: '380px' }} placeholder="New Password" />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('confirmPassword', {
              rules: [{ required: true, message: 'Password Confirmation' }]
            })(
              <Input.Password style={{ width: '380px' }} placeholder="Password confirmation" />
            )}
          </FormItem>
          <FormItem>
            <Button type="primary" htmlType="submit" >
              {translationCache &&
                translationCache[`${constant.Save_Password}`] &&
                translationCache[`${constant.Save_Password}`][language] ?
                translationCache[`${constant.Save_Password}`][language] :
                `${constant.Save_Password}`}
            </Button>
          </FormItem>
        </Form>
      </section>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),

});


export default connect(mapStateToProps)(Form.create()(ChangeFirstPasswordComponent));


