import { types } from './types'
import { takeLatest, call, put } from 'redux-saga/effects'

import { getTagReferenceListSuccess } from './tagReference.duck'
import tagReference from './tagReference.requests'

export function * listTagReferenceSaga () {
  const tagReferenceSagaSuccess = yield call(tagReference.getList)
  yield put(getTagReferenceListSuccess(tagReferenceSagaSuccess))

}

export default function * tagReferenceSaga () {
  yield [
    takeLatest(types.GET_TAGREFERENCE_LIST, listTagReferenceSaga)
  ]
}
