import React from "react";
import moment from "moment";
import ReactEcharts from "../Echarts/Echarts";
import { connect } from 'react-redux';
import { Tag } from "antd";

import { createStructuredSelector } from 'reselect'
import { heatmapCalendarMockJson } from "../../Mock/MockJson";
import { getChartDataWithoutSelection, getChartDataWithSelection } from "../ChartCalls"
import {
    getCurrentLanguage,
    getTranslationCache,
} from "../../../../selectors/language";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { defaultRefreshTime } from '../constant.json';
import {
    ErrorComponent,
    NoRecordComponent
} from "../Utils"
import { Fragment } from "react";

class HeatmapCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            option: {},
            translationCache: props.translationCache || [],
            language: props.language || "English",
            refreshDateTime: moment(),
            errorInAPI: false,
            noDataInDB: false
        };
    }

    timeTicket = null;
    requestCheck = true;
    abortController = new window.AbortController();
    async componentDidMount() {
        if (this.timeTicket) {
            clearInterval(this.timeTicket);
        }
        this.getData(this.props);

        let refreshTime = ""
        if (this.props.graphprops.refreshTime) {
            refreshTime = this.props.graphprops.refreshTime * 60000
        } else {
            refreshTime = defaultRefreshTime
        }

        this.timeTicket = setInterval(() => {
            if (this.requestCheck) {
                this.getData(this.props, this.props.selectedThemeing);
            }

        }, refreshTime);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.graphprops !== this.props.graphprops ||
            prevProps.selectionDate !== this.props.selectionDate ||
            prevProps.selectionMonth !== this.props.selectionMonth ||
            prevProps.selectionWeek !== this.props.selectionWeek ||
            prevProps.deviceId !== this.props.deviceId ||
            prevProps.shift !== this.props.shift ||
            prevProps.selectionDateRange !== this.props.selectionDateRange ||
            prevProps.dayMonthYear !== this.props.dayMonthYear ||
            prevProps.dropdownSelection !== this.props.dropdownSelection
        ) {
            this.getData();
        }
    }

    componentWillUnmount() {
        this.abortController.abort()
        clearInterval(this.timeTicket);
    }

    getData = async () => {
        this.requestCheck = false
        let json = []
        const { graphprops } = this.props;
        let option = this.getOption()
        let { errorInAPI, noDataInDB } = this.state
        errorInAPI = false
        noDataInDB = false
        if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
            json = heatmapCalendarMockJson;
            this.requestCheck = true
        } else {

            if ((graphprops && graphprops.dashboardType === 'dynamic' && this.props.deviceId) ||
                (graphprops && graphprops.dateExists === 'true' && this.props.selectionDate) ||
                (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
                (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
                (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
                (graphprops && graphprops.dateRangeExists === 'true' && this.props.selectionDateRange) ||
                (graphprops && graphprops.dayMonthYearExists === 'true' && this.props.dayMonthYear)
            ) {

                json = await getChartDataWithSelection(
                    graphprops,
                    this.props.deviceId,
                    this.props.selectionDate,
                    this.props.selectionMonth,
                    this.props.selectionWeek,
                    this.props.shift,
                    this.props.selectionDateRange,
                    this.props.dayMonthYear,
                    this.abortController,
                    this.props.dropdownSelection
                );
                this.requestCheck = true
            } else {
                json = await getChartDataWithoutSelection(graphprops, this.abortController, this.props.dropdownSelection)
                this.requestCheck = true
            }
        }

        if ((json && json.message) || (Array.isArray(json) && (json.length === 0) &&
            (graphprops.enableMock !== true))) {

            if (json.message) {
                errorInAPI = true
            } else if (json.length === 0) {
                noDataInDB = true
            }

        } else {
            let seriesData = []
            if (json && Array.isArray(json)) {
                seriesData = json.map(function (item) {
                    if (item.intervalAlias && item.tagValue) {
                        return [item.intervalAlias, item.tagValue]
                    } else {
                        return []
                    }
                })
                option.series[0].data = seriesData
                option.series[1].data = seriesData
                this.setState({
                    option,
                    refreshDateTime: moment()
                })
            }
        }
        this.setState({
            errorInAPI,
            noDataInDB
        })
    };

    render() {
        const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
        let { graphprops } = this.props
        return (

            <Fragment>
                {
                    graphprops && graphprops.checked === true ?
                        <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
                            {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
                        </Tag>
                        : null
                }
                {
                    noDataInDB === true || errorInAPI === true ?
                        noDataInDB === true ?
                            <NoRecordComponent />
                            : <ErrorComponent />
                        :
                        <ReactEcharts
                            id={Math.ceil(Math.random(100, 100000) * 1000)}
                            option={this.state.option}
                            style={{
                                height: "80%",
                                width: "100%",
                            }}
                            className="react_for_echarts"
                        />
                }
            </Fragment>

        );
    }

    getOption = () => {
        let theme = this.props.selectedThemeing;
        let range = ""
        let { dateExists, monthExists } = this.props.graphprops

        if (dateExists && dateExists === "true") {
            range = moment(this.props.selectionDate, "YYYY-MM-DD 00:00:00").format("YYYY-MM")
        } else if (monthExists && monthExists === "true") {
            range = this.props.selectionMonth
        }

        let option = {
            title: {
                top: 30,
                left: 'center'
            },
            toolbox: {
                showTitle: false,
                itemSize: 25,
                feature: {
                    saveAsImage: {
                        backgroundColor: theme === "lightTheme" ? "white" : "black",
                        title: "download",
                        name: this.props.graphprops.title,
                        iconStyle: {
                            borderColor: "#8c8c8c",
                            borderWidth: 2,
                        },
                    },
                },
            },
            tooltip: {
                // trigger: "axis",
                position: 'top',
                // formatter:'{c0}'
                formatter: function (param) {
                    return [
                        param.marker,
                        "<bold>" + param.value[0] + "</bold>" + ": " + param.data[1],
                    ].join("");
                },
            },
            visualMap: {
                min: 0,
                max: 10000,
                type: 'piecewise',
                orient: 'horizontal',
                left: 'center',
                top: 40,

                pieces: this.props.graphprops.VisualMap,
                outOfRange: {
                    color: '#999'
                },
                textStyle: {
                    color: theme === "lightTheme" ? "black" : "white",
                    fontWeight: "bold",
                    fontSize: 18,
                }

            },
            calendar: {
                top: 120,
                left: 80,
                right: 20,
                cellSize: [70, 70],
                range: range ? range : moment().format("YYYY"),
                itemStyle: {
                    borderWidth: 0.5
                },
                dayLabel: {

                    color: theme === "lightTheme" ? "black" : "white",
                    fontWeight: "bold",
                    fontSize: 18
                },
                monthLabel: {
                    textStyle: {
                        color: theme === "lightTheme" ? "black" : "white",
                        fontWeight: "bold",
                        fontSize: 18,
                    },
                    color: theme === "lightTheme" ? "black" : "white"
                },
                yearLabel: {

                    show: true,
                    margin: 50,

                    color: theme === "lightTheme" ? "black" : "white",
                    fontWeight: "bold",
                    fontSize: 20,


                }
            },
            series: [{
                "type": "scatter",
                "coordinateSystem": "calendar",
                "symbolSize": 1,
                "data": [],
                label: {
                    show: true,
                    formatter: function (params) {
                        var d = (params.value[0]);
                        return d + '\n\n' + params.value[1];
                    },
                    color: '#000'
                },
            }, {
                "type": "heatmap",
                "coordinateSystem": "calendar",
                "symbolSize": 1,
                "data": []
            }
            ]

        };
        return option
    }
}

const mapStateToProps = createStructuredSelector({
    language: getCurrentLanguage(),
    translationCache: getTranslationCache(),
    selectedThemeing: makeSelectThemeing(),
})


export default connect(mapStateToProps)(HeatmapCalendar);