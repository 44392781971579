import axios from 'axios'
import { message } from 'antd'

import localStorage from "../../../utils/localStorage"

export async function _getTranslationMasterData() {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get('accessToken')
    let changePassword = {
        method: 'POST',
        url: `/api/language/list`,
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        data: {
            // type: 'table',
            SiteId: siteId,
        }
    }
    return axios(changePassword)
        .then((response) => {
            return response.data
        })
        .catch(function (error) {

        })
}

export async function _saveTranslation(saveObject,apiURL) {
    const accessToken = localStorage.get('accessToken')
    let payload = {
        method: 'POST',
        url: `/api/language/${apiURL}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        data: saveObject
    }
    return axios(payload)
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch(function (error) {
            message.error('Error',error)
        })
}
export async function _getCurrentLanguages() {
    const accessToken = localStorage.get('accessToken')
    let changePassword = {
        method: 'GET',
        url: `/api/language/languageList`,
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    }
    return axios(changePassword)
        .then((response) => {
            return response.data
        })
        .catch(function () {

        })
}
