import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import AssetDeviceComponent from './AssetDeviceComponent'
import { getConditionList } from '../../../modules/condition/condition.duck'
import { currentAsset } from '../../../modules/asset/asset.duck'
import { setDeviceBreadcrumbs,currentDevice } from '../../../modules/device/device.duck'

const mapStateToProps = createStructuredSelector({

})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getConditionList,
        currentAsset,
        setDeviceBreadcrumbs,
        currentDevice
      },
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetDeviceComponent)
