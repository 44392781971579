
import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router-dom";

import { StyledHomeRouter } from "./styles";
import {

  PageWrapper,
  SettingsPage,

  XMLConfiguration,

  AssetDeviceList,
  Device,
  DeviceType,
  ApplicationSettings,

  DefaultLandingPage,
  GenericPageNotFound,

  Trends,
  ViewTrends,

  HMI,
  HmiConfiguration,

  DashboardConfiguration,
  ViewDashboard,
  EditDashboard,

  Report,
  ReportConfiguration,
  ReportTypeConfiguration,
  RuleEngine,
  RulesList,
  RulesFeedback,
  RuleEngineRootCause

} from "../pages";

export class HomePageRouter extends Component {

  dynamicRouteRender = (routes) => {
    return routes && Array.isArray(routes) && routes.map((route) => {
      return <Route {...route} />
    })
  }

  render() {
    let showPageBreadCrumb = true;
    if (
      JSON.stringify(this.props.history.location.pathname).match("/assetConfiguration")
    ) {
      showPageBreadCrumb = false;
    }

    let breadCrumb = "";
    document.title = "Rubus-Industrial IoT Platform"

    let routes = [
      {
        "path": "/rubus/settingsPage",
        "component": SettingsPage
      },

      {
        "path": "/rubus/XMLFileUpload",
        "component": XMLConfiguration
      },

      {
        "path": "/rubus/assetConfiguration",
        "component": AssetDeviceList
      }, {
        "path": "/rubus/assetConfiguration/:location/:device",
        "component": AssetDeviceList
      }, {
        "path": "/rubus/devices",
        "component": Device
      }, {
        "path": "/rubus/device-type",
        "component": DeviceType
      }, {
        "path": "/rubus/configurationSettings",
        "component": ApplicationSettings
      },

      {
        "path": "/rubus/LandingPage",
        "component": DefaultLandingPage
      },

      {
        "path": "/rubus/Trend Analysis",
        "component": Trends
      }, {
        "path": "/rubus/Trend Analysis/:module",
        "component": Trends
      }, {
        "path": "/rubus/ViewTrends",
        "component": ViewTrends
      },
      // {
      //   "path": "/rubus/Trend Analysis1",
      //   "component": Trends_New
      // },

      {
        "path": "/rubus/hmiConfiguration",
        "component": HmiConfiguration
      },
      {
        "path": "/rubus/singleHMI/static/:hmiName",
        "component": HMI
      },

      {
        "path": "/rubus/dashboard",
        "component": DashboardConfiguration,
        "exact": true
      }, {
        "path": "/rubus/dashboard/:name",
        "component": ViewDashboard,
        "exact": true
      }, {
        "path": "/rubus/dashboard/:name/:deviceId",
        "component": ViewDashboard,
        "exact": true
      }, {
        "path": "/rubus/editDashboard",
        "component": EditDashboard
      },

      {
        "path": "/rubus/reportConfiguration",
        "component": ReportConfiguration,
        "exact": true
      }, {
        "path": "/rubus/report/:reportType/:report",
        "component": Report,
        "exact": true
      }, {
        "path": "/rubus/ReportTypeConfiguration",
        "component": ReportTypeConfiguration,
        "exact": true
      }, {
        "path": "/rubus/RuleEngine",
        "component": RuleEngine,
        "exact": true
      }, {
        "path": "/rubus/RulesList",
        "component": RulesList,
        "exact": true
      }, {
        "path": "/rubus/RuleEngine/:id",
        "component": RuleEngine,
        "exact": true
      }, {
        "path": "/rubus/RulesFeedback",
        "component": RulesFeedback,
        "exact": true
      }, {
        "path": "/rubus/RuleEngine/RootCause",
        "component": RuleEngineRootCause,
        "exact": true
      },

      {
        "path": "/rubus/RuleEngine",
        "component": RuleEngine
      }, {
        "path": "/rubus/RulesList",
        "component": RulesList
      }, {
        "path": "/rubus/RuleEngine/:id",
        "component": RuleEngine
      }, {
        "path": "/rubus/RulesFeedback",
        "component": RulesFeedback
      }, {
        "path": "/rubus/RuleEngine/RootCause",
        "component": RuleEngineRootCause
      },

      {
        "path": "*",
        "component": GenericPageNotFound,

      }
    ]

    return (
      <StyledHomeRouter>

        {
          showPageBreadCrumb && !breadCrumb ? (
            <PageWrapper
              breadCrumb={breadCrumb}
              history={this.props.history}
              selectedMenu={this.props.selectedMenu}
              sideMenu={this.props.sideMenu}
            />
          ) : null
        }

        <Switch>

          {
            this.dynamicRouteRender(routes)
          }

        </Switch>
      </StyledHomeRouter>
    );
  }
}
export default withRouter(HomePageRouter);


