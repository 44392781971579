import React from "react";
import moment from "moment"
import { Tag } from "antd";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import ReactEcharts from "../Echarts/Echarts";
import {
  getChartDataWithoutSelection,
  getChartDataWithSelection,
} from "../ChartCalls";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";

import { makeSelectThemeing } from "../../../../selectors/theme";
import { Row, Col, Table } from "antd";
import "../CSS/Table.css";

// import { trendLabelTranslation, columnsAdapter } from "../../Utils/Adapter/translationAdapter";
import { defaultRefreshTime } from '../constant.json'
import { stackedBarMock } from "../../Mock/MockJson";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { Fragment } from "react";
import { StyledTable, lightTheme, darkTheme } from "../CSS/style.js";

class StackedLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      dataSource: [],
      columns: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear
    ) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  getData = async () => {
    this.requestCheck = false
    // let theme = this.props.selectedThemeing;
    let json = [];
    let series = [];
    const { graphprops } = this.props;

    let option = this.getOption();
    // let legends = [];
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = stackedBarMock;
      this.requestCheck = true
    } else {
      // let colors = [];
      // if (graphprops && graphprops.colors && graphprops.colors.length > 0) {
      //   colors = graphprops.colors;
      // } else {
      //   colors = ["rgb(251, 118, 123)", "rgb(129, 227, 238)"];
      // }

      if (
        (graphprops &&
          graphprops.dashboardType === "dynamic" &&
          this.props.deviceId) ||
        (graphprops &&
          graphprops.dateExists === "true" &&
          this.props.selectionDate) ||
        (graphprops &&
          graphprops.monthExists === "true" &&
          this.props.selectionMonth) ||
        (graphprops &&
          graphprops.weekExists === "true" &&
          this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
        (graphprops &&
          graphprops.dateRangeExists === "true" &&
          this.props.selectionDateRange) ||
        (graphprops &&
          graphprops.dayMonthYearExists === "true" &&
          this.props.dayMonthYear)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController
        );
        this.requestCheck = true
      } else {
        json = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true
      }
    }

    if ((json && json.message) || (Array.isArray(json) && (json.length === 0 &&
      graphprops.enableMock !== true))) {
      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
      }

    } else {
      this.setState({
        data: json,
      });
      let columns = []
      if (json && json.table &&
        Array.isArray(json.table) && this.props.graphprops && this.props.graphprops.table &&
        this.props.graphprops.table === "yes") {
        let dataSource = json.table
        dataSource && dataSource[0] && Object.keys(dataSource[0]).map((tableKey) => {
          columns.push({
            title: tableKey,
            dataIndex: tableKey,
            key: tableKey,
          })
        })
        // columns = columnsAdapter(columns,
        //   this.props.translationCache,
        //   this.props.language)

        this.setState({
          columns,
          dataSource
        });
      }
      let xAxisDataArray = [];
      let namesArray = [];
      xAxisDataArray = json && json.bar && json.bar[0] ? json.bar[0].intervalAlias.map((intervalAlias) => {
        let date = graphprops && graphprops.type === "live" ? intervalAlias : intervalAlias
        return date
      }) : []


      let dataObj = {}

      json &&
        json.bar &&
        Array.isArray(json.bar) &&
        json.bar.map((data, i) => {
          namesArray.push(data.tagName);
          dataObj = {
            name: data.tagName,
            type: data.tagName && graphprops.tags && graphprops.tags.includes(data.tagName) ? "line" : "line",
            stack: graphprops.tags && graphprops.tags.length === 0 ? "true" : null,
            data: data.tagValue,
            yAxisIndex: data.tagName && graphprops.tags && graphprops.tags.includes(data.tagName) && data.tagName !== "MIN" ? 1 : 0,
            markPoint: {

              data: this.props.graphprops.minMax ? [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
              ] : null
            }
          };
          series.push(dataObj);
        });
      let yAxis = []
      series && Array.isArray(series) && series.map((seriesData) => {
        if (seriesData && seriesData.name && seriesData.name !== undefined) {
          yAxis.push({
            type: 'value',
            name: seriesData.name
          })
          option.yAxis = yAxis;
        }
      })


      let finalArray = xAxisDataArray;
      option.xAxis.data = finalArray;

      option.series = series;
      this.setState({ option, refreshDateTime: moment() });

    };
    this.setState({
      errorInAPI,
      noDataInDB
    })
  }
  render() {
    const { dataSource, columns } = this.state
    let theme = this.props.selectedThemeing;
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />

              : <ErrorComponent />
            :
            this.props.graphprops && this.props.graphprops.table &&
              this.props.graphprops.table !== "yes" ?
              <ReactEcharts
                option={this.state.option}
                style={{ height: "80%", width: "100%" }}
                className="react_for_echarts"
              />
              : <div style={{ padding: "10px" }}>
                <Row>
                  <Col span={24}>
                    <ReactEcharts
                      option={this.state.option}
                      style={{ height: "400%" }}
                      className="react_for_echarts"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={{ padding: "20px" }}>
                      {
                        this.props.graphprops && this.props.graphprops.table &&
                          this.props.graphprops.table === "yes" && dataSource.length > 0 ?
                          <StyledTable theme={theme === "lightTheme" ? lightTheme : darkTheme}> <Table
                            // className="barTable"
                            className="TableLightWidget"
                            dataSource={dataSource && dataSource}
                            columns={columns && columns ? columns : []}
                            pagination={false}
                            size={"small"}
                            style={{ padding: "0px 10px", marginTop: "12px" }}
                            scroll={{ y: 240 }}
                            bordered
                          /></StyledTable>
                          : null
                      }

                    </div>
                  </Col>
                </Row>
              </div>

        }
      </Fragment>
    );
  }
  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow", // 'line' | 'shadow'
        },
      },
      legend: {
        left: "center",
        itemHeight: 25,
        itemWidth: 25,
        itemGap: 10.5,
        textStyle: {
          color: theme === "lightTheme" ? "black" : "white",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      // yAxis: {
      //   type: "value",
      // },
      xAxis: {
        type: "category",
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },

        axisTick: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisLabel: {
          color: theme === "lightTheme" ? "black" : "white",
        },
        scale: true,
      },

      yAxis: {
        scale: true,
        type: "value",
        // splitLine: {
        //     lineStyle: {
        //         type: 'dashed'
        //     }
        // },
        splitLine: {
          show: false,
        },
        // scale: true,
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisTick: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisLabel: {
          color: theme === "lightTheme" ? "black" : "white",
        },
      },
      dataZoom: {
        type: "slider",
      },

      series: [],
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            backgroundColor: theme === "lightTheme" ? "black" : "black",
            title: "download",
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: "#8c8c8c",
              borderWidth: 2,
            },
          },
        },
      },
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(StackedLine);
