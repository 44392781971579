export const types = {

  DEVICE_LIST: '@/DEVICE_LIST',
  CREATE_DEVICE: '@/CREATE_DEVICE',
  UPDATE_DEVICE: '@/UPDATE_DEVICE',
  DELETE_DEVICE: '@/DELETE_DEVICE',
  Current_Device: '@/Current_Device',

  DEVICE_LIST_SUCCESS: '@/DEVICE_LIST_SUCCESS',

  DEVICE_LIST_CM: '@/DEVICE_LIST_CM',
  DEVICE_CM_LIST_SUCCESS: '@/DEVICE_CM_LIST_SUCCESS',
  SETDEVICE_BREADCRUMB_LIST: "@/SET_DEVICE_BREADCRUMB"
}
