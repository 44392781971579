import { types } from "./types";
import { message } from "antd";
import _ from "lodash";
import { jwtToken } from "../../commons/jwtTokenExpiry";
import { takeLatest, call, put } from "redux-saga/effects";

import deviceType from "./deviceType.requests";
import {
  getDeviceTypeListSuccess,
  getDeviceTypeCMListSuccess
} from "./deviceType.duck";

export function* getDeviceTypeSaga() {
  const deviceTypeSagaSuccess = yield call(deviceType.getDeviceTypeList);
  if (deviceTypeSagaSuccess.name === "TokenExpiredError") {
    jwtToken(deviceTypeSagaSuccess.message);
  } else if (deviceTypeSagaSuccess.statusCode === 400) {
    message.error(deviceTypeSagaSuccess.message);
    // jwtToken(deviceTypeSagaSuccess.message)
  } else {
    yield put(getDeviceTypeListSuccess(deviceTypeSagaSuccess));
  }
}

export function* saveDeviceParameters({ payload }) {
  const deviceParameterSave = yield call(
    deviceType.deviceParameterCURD,
    payload
  );
  if (deviceParameterSave !== undefined) {
    if (deviceParameterSave.name === "TokenExpiredError") {
      jwtToken(deviceParameterSave.message);
    } else {
      message.success("Device Parameters has been updated");
    }
  } else {
    message.success("Device Parameters has been updated");
  }
}

export function* deleteDeviceParameters({ payload }) {
  const deleteDeviceParameter = yield call(
    deviceType.deleteDeviceParameter,
    payload
  );
  if (deleteDeviceParameter.name === "TokenExpiredError") {
    jwtToken(deleteDeviceParameter.message);
  }
}

export function* saveDeviceTypeSaga(payload) {
  const deviceSagaSuccess = yield call(
    deviceType.saveDeviceType,
    payload.payload
  );
  if (deviceSagaSuccess.name === "TokenExpiredError") {
    jwtToken(deviceSagaSuccess.message);
  } else {
    message.success("DeviceType saved successfully");
  }
}

export function* updateDeviceTypeSaga({ payload }) {
  const updateDeviceType = yield call(deviceType.updateDeviceType, payload);
  if (updateDeviceType.name === "TokenExpiredError") {
    jwtToken(updateDeviceType.message);
  } else {
    message.success("DeviceType updated successfully");
  }
}

export function* getDeviceTypeCM() {
  const deviceTypeSagaSuccess = yield call(deviceType.getDeviceTypeCMList);
  if (deviceTypeSagaSuccess.name === "TokenExpiredError") {
    jwtToken(deviceTypeSagaSuccess.message);
  } else if (deviceTypeSagaSuccess.statusCode === 400) {
    message.error(deviceTypeSagaSuccess.message);
  } else {
    let uniqueArray = _.uniqBy(deviceTypeSagaSuccess, "deviceTypeId").filter(
      deviceType => {
        return deviceType.module === "CM";
      }
    );
    yield put(getDeviceTypeCMListSuccess(uniqueArray));
  }
}

export function* deleteDeviceType(deviceId) {
   const deleteDevice = yield call(deviceType.deleteDeviceType, deviceId.deviceTypeID);
   if(deleteDevice === 204){

   }

}

export default function* deviceTypeSaga() {
  yield [
    takeLatest(types.GET_DEVICETYPE, getDeviceTypeSaga),
    takeLatest(types.SAVE_DEVICETYPE, saveDeviceTypeSaga),
    takeLatest(types.UPDATE_DEVICETYPE, updateDeviceTypeSaga),
    takeLatest(types.DELETE_DEVICETYPE, deleteDeviceType),

    takeLatest(types.SAVE_DEVICEPARAMETERS, saveDeviceParameters),
    takeLatest(types.DELETE_DEVICEPARAMETERS, deleteDeviceParameters),

    takeLatest(types.GET_DEVICETYPE_CM, getDeviceTypeCM)
  ];
}
