
import React from "react";
import cloneDeep from "lodash.clonedeep";
import { connect } from "react-redux";

import { Tag } from "antd";
import { createStructuredSelector } from "reselect";
import moment from "moment";
import ReactEcharts from "../Echarts/Echarts";
import { trendLabelTranslation } from "../../Utils/Adapter/translationAdapter";
import { getChartDataWithoutSelection, getChartDataWithSelection } from "../ChartCalls";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { makeSelectThemeing } from "../../../../selectors/theme";
import { defaultRefreshTime } from '../constant.json'
import { pieLineMockJson } from "../../Mock/MockJson";
import { Fragment } from "react";

class PieLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //option: this.getData(props),
      option: this.getOption(props),
      data: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
    this.pieName = "";
  }

  data = [];
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();


  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection
    ) {
      this.getData();
    }
  }
 
  componentWillUnmount() {
    this.abortController.abort()
    clearInterval(this.timeTicket);
  }
  
  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
         {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <ReactEcharts
              option={this.state.option}
              notMerge={true}
              lazyUpdate={true}
              //onEvents={onEvents}
              style={{ height: "90%", width: "100%" }}
              className="react_for_echarts"
            />
        }
      </Fragment>

    );
  }

  getData = async (props) => {
    let theme = this.props.selectedThemeing;
    this.requestCheck = false
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false
    let json = [];
    let { graphprops } = this.props;


    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = pieLineMockJson;
      this.requestCheck = true
    } else {
      if (
        (graphprops &&
          graphprops.dashboardType === "dynamic" &&
          this.props.deviceId) ||
        (graphprops &&
          graphprops.dateExists === "true" &&
          this.props.selectionDate) ||
        (graphprops &&
          graphprops.monthExists === "true" &&
          this.props.selectionMonth) ||
        (graphprops &&
          graphprops.weekExists === "true" &&
          this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
        (graphprops &&
          graphprops.dateRangeExists === "true" &&
          this.props.selectionDateRange) ||
        (graphprops &&
          graphprops.dayMonthYearExists === "true" &&
          this.props.dayMonthYear)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection
        )
        this.requestCheck = true
      } else if (graphprops) {
        json = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true
      }
    }

    if ((json && json.message) || (Array.isArray(json) && (json.length === 0 &&
      graphprops.enableMock !== true))) {

      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
        this.setState({noDataInDB})
      }

    } else {

      var line = json && json.line;
      var pie = json && json.pie;
      if (line && Array.isArray(line)) {
        let dates = [];
        let values = [];
        let name = "";
        let tagnames = [];
        let series = [];
        let legendsdata = [];
        // let finalArray = [];
        line.map((val, index) => {
          if (val.tagname !== name) {
            if (tagnames.indexOf(val.tagname) === -1) {
              tagnames.push(val.tagname);
              name = val.tagname;
              legendsdata.push({
                name: val.tag_description,

                icon: "roundRect",

                textStyle: {
                  color: theme === "lightTheme" ? "black" : "white",
                  fontSize: 16,
                  fontWeight: "bold",
                },
              });
            }
          }
        });

        let yAxis = {
          axisLine: {
            lineStyle: {
              color: theme === "lightTheme" ? "black" : "white",
            },
          },
          axisTick: {
            lineStyle: {
              color: theme === "lightTheme" ? "black" : "white",
            },
          },
          axisLabel: {
            color: theme === "lightTheme" ? "black" : "white",
          },
          gridIndex: 0,
        };

        tagnames.map((tagval, tagindex) => {
        
          values = [];
          line.map((jsonval, jsonindex) => {
            if (tagnames[0] === jsonval.tagname && tagindex === 0) {
              // dates.push(
              //   moment(jsonval.interval_alias)
              //     //.utc()
              //     .format("DD/MM/YYYY HH:mm:ss")
              // );
              // let date={}
              if(this.props.graphprops.monthExists === "true" || this.props.graphprops.monthExists === true){
                
             dates.push( graphprops && graphprops.type === "live" ? moment.utc(jsonval.interval_alias).local().format("DD/MM/YYYY") : moment.utc(jsonval.interval_alias).local().format("DD/MM/YYYY"))
            }else{
                dates.push( graphprops && graphprops.type === "live" ? moment.utc(jsonval.interval_alias).local().format("DD/MM/YYYY HH:mm:ss") : moment.utc(jsonval.interval_alias).local().format("DD/MM/YYYY HH:mm:ss"))
            }
          }
          let tagdescription = "";
            if (tagval === jsonval.tagname) {
              values.push(jsonval.tagvalue);

            }
            tagdescription = jsonval.tag_description;
          });

          series.push({
            name: legendsdata[tagindex]["name"],
            data: values,

            type: "line",
            axisLabel: { fontSize: 15 },
            //   areaStyle: {},
            smooth: true,
          });
        });

        series.push({
          type: "pie",
          id: "pie",
          radius: '30%',
          center: ['50%', '25%'],
          label: {
            // formatter: ' {b|{b}：}{c}  {per|{d}%}  ',
            formatter: " {b|{c}}  {per|{d}%}  ",

            color: "#fff",
            backgroundColor: "#eee",
            borderColor: "#aaa",
            borderWidth: 1,
            borderRadius: 4,
            // shadowBlur:3,
            // shadowOffsetX: 2,
            //     shadowOffsetY: 2,
            //     shadowColor: '#999',
            padding: [0, 4],
            rich: {
              b: {
                fontSize: 14,
                fontWeight: "bold",
                color: "#fff",
                lineHeight: 22,
                backgroundColor: "#c23531",
                padding: [3, 5],
                borderRadius: 2,
              },
              per: {
                fontSize: 14,
                fontWeight: "bold",
                color: "#fff",
                backgroundColor: "#52c41a",
                padding: [3, 5],
                borderRadius: 2,
              },
            },
          },
          // label:{
          //   fontSize:"16",
          //  height:"30",
          //  backgroundColor:"#f5f5f5",
          //  padding: [3, 4, 5, 6],
          //  borderRadius: 4,

          // },
          // label: {
          //   formatter: "{b}: {@2012} ({d}%)",
          // },
          // encode: {
          //   itemName: "product",
          //   value: "2013",
          //   tooltip: "2013",
          // },
          data:
            pie &&
            Object.keys(pie).map(function (key) {
              return {
                name: key,
                value: pie[key],
              };
            }),
        });

        const option = cloneDeep(this.getOption());
        series = await trendLabelTranslation(
          series,
          this.props.translationCache,
          this.props.language
        );
        option.xAxis.data = [...dates];
        option.yAxis = { ...yAxis };
        option.series = [...series];
        option.legend = [...legendsdata];
        this.setState({
          option: option,
          refreshDateTime: moment(),
          noDataInDB:false
        });
        return option;
      }
      this.setState({
        errorInAPI,
        noDataInDB
      })
    }
  };


  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      // backgroundColor: {
      //     type: 'pattern',
      //     image: canvas,
      //     repeat: 'repeat'
      // },
      tooltip: {
        trigger: "item",
        formatter: "{b} : {c} ({d}%)",
      },
      label: {
        color: theme === "lightTheme" ? "black" : "white",
        fontWeight: "bold",
        fontSize: 14,
      },
      textStyle: {
        color: theme === "lightTheme" ? "black" : "white",
        fontWeight: "bold",
        fontSize: 18,

      },
      legend: {
        left: "center",
        textStyle: {
          color: theme === "lightTheme" ? "black" : "white",
        },

      },
      // tooltip: {
      //   trigger: "axis",
      //   //showContent: false
      // },
      toolbox: {
        showTitle: false,
        itemSize: 25,

        feature: {
          saveAsImage: {
            title: "download",
            name: this.props.graphprops.title,

            backgroundColor: "black",
            iconStyle: {
              // color: "none",
              borderColor: "#8c8c8c",
              borderWidth: 2,
            },
          },
        },
      },
      grid: [
        {
          top: "45%",
          width: "100%",
          //bottom: '45%',
          left: 10,
          height: "50%",
          containLabel: true,
        },
        {
          top: 0,
          width: "100%",
          bottom: 0,
          left: 0,
          height: "50%",
          containLabel: false,
        },
      ],
      // dataset: {
      //   source: [
      //     ["product", "2012", "2013", "2014", "2015", "2016", "2017"],
      //     ["Matcha Latte", 41.1, 30.4, 65.1, 53.3, 83.8, 98.7],
      //     ["Milk Tea", 86.5, 92.1, 85.7, 83.1, 73.4, 55.1],
      //     ["Cheese Cocoa", 24.1, 67.2, 79.5, 86.4, 65.2, 82.5],
      //     ["Walnut Brownie", 55.2, 67.1, 69.2, 72.4, 53.9, 39.1],
      //   ],
      // },
      xAxis: {
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisTick: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisLabel: {
          color: theme === "lightTheme" ? "black" : "white",
        },
        type: "category",
      },
      yAxis: {
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisTick: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisLabel: {
          color: theme === "lightTheme" ? "black" : "white",
        },
        gridIndex: 0,
      },
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(PieLine);

