import React from 'react'
import axios from 'axios'
import {
    Table, Form, Col, Input, Drawer, Button, Card, Row, message, Icon,
    Popconfirm, Upload, Select, Modal
} from 'antd'

import history from '../../../commons/history'
import localStorage from '../../../utils/localStorage'
import { DrawerFooter, StyledTable, lightTheme, StyledDashboard } from './style'

const { Option } = Select
class NestedHierarchy extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            datasource: [],
            editObj: {},
            HierarchyName: "",
            ParentId: undefined,
            parentHierarchy: [],
            modalVisible: false,
            deviceList: [],
            selectedObject: {},
            value: []
        }
    }

    componentWillMount() {
        const pathSnippets = this.props.history.location.pathname.split('/rubus/settingsPage/Hierarchy/').filter(i => i)
        if (pathSnippets && pathSnippets[0]) {
            let hierarchyLevel = pathSnippets[0].split('/').filter(i => i)
            let parentObject = this.props.history.location.state ? this.props.history.location.state : null
            let ParentId = parentObject && parentObject.Id ? parentObject.Id : null
            this._getHierarchyList(hierarchyLevel[0] || null, ParentId)
            this.setState({ HierarchyName: hierarchyLevel[0], parentObject, ParentId })
        }
    }

    _getHierarchyList = (HierarchyName, ParentId) => {
        const accessToken = localStorage.get('accessToken')
        let headers = {
            method: 'POST',
            url: `/api/hierarchymodule/getById`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: {
                ParentId,
                HierarchyName
            }
        }
        axios(headers)
            .then(async ({ data }) => {
                if (Array.isArray(data)) {
                    this.setState({
                        datasource: data,
                        HierarchyName,
                        ParentId
                    })
                }
            })
            .catch(function (error) {
                return []
            })
    }

    openModal = () => {
        this.props.form.resetFields()
        this.setState({
            visible: true,
            editObj: {}
        });
    };

    _cancel = () => {
        this.setState({
            visible: false,
        });
    };

    editHierarchy = (editObj) => {
        this.setState({
            editObj,
            visible: true,
        })
    }

    _createHierarchy = (e) => {
        const { HierarchyName, ParentId } = this.state
        e.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (!error) {
                let payload = {
                    ...values,
                    HierarchyType: values.Next && values.Next === true ? 0 : 1,
                    HierarchyName,
                    ParentId
                }
                this._createHierarchyAPI(payload)
            }
        })
    };

    _updateHierarchy = (e) => {
        e.preventDefault();
        let { editObj } = this.state
        this.props.form.validateFields((error, values) => {
            if (!error) {
                let updateObject = {
                    ...values,
                    HierarchyType: values.Next && values.Next === true ? 0 : 1,
                    Id: editObj.Id
                }
                this._updateHierarchyAPI(updateObject)
            }
        })
    };

    _createHierarchyAPI = (createObject) => {
        let { HierarchyName, parentObject } = this.state
        const accessToken = localStorage.get('accessToken')
        let orgCreateHeaders = {
            method: 'POST',
            url: '/api/hierarchymodule/create',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: createObject
        }
        axios(orgCreateHeaders)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    message.success("Hierarchy Saved")
                    this._getHierarchyList(HierarchyName, parentObject && parentObject.Id ? parentObject.Id : null)

                    this.setState({ visible: false })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    _updateHierarchyAPI = (updateObject) => {
        let { HierarchyName, editObj, parentObject } = this.state
        const accessToken = localStorage.get('accessToken')
        let orgUpdateHeaders = {
            method: 'POST',
            url: `/api/hierarchymodule/update`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: {
                ...editObj,
                ...updateObject
            }
        }
        axios(orgUpdateHeaders)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    message.success("Hierarchy Updated")
                    this._getHierarchyList(HierarchyName, parentObject && parentObject.Id ? parentObject.Id : null)
                    this.setState({ visible: false })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    _deleteHierarchy = (payload) => {
        let { HierarchyName, parentObject } = this.state
        const accessToken = localStorage.get('accessToken')
        let orgDeleteHeaders = {
            method: 'POST',
            url: `/api/hierarchymodule/delete`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: payload
        }
        axios(orgDeleteHeaders)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    message.success("Hierarchy Deleted")
                    this._getHierarchyList(HierarchyName, parentObject && parentObject.Id ? parentObject.Id : null)
                    this.setState({ visible: false })
                }
            })
            .catch(function (error) {
                console.log(error)
            })

    }

    nextLevel = (record) => {
        let { HierarchyName, parentHierarchy, ParentId } = this.state
        this._getHierarchyList(HierarchyName, record && record.Id ? record.Id : null)

        parentHierarchy.push(ParentId)
        history.push({ pathname: `/rubus/settingsPage/Hierarchy/${HierarchyName}/${record.Name}`, state: record })
        this.setState({
            parentObject: record,
            parentHierarchy
        })
    }


    handleChange = info => {
        const { status } = info.file;
        if (status !== "uploading") {
        }
        if (status === "done") {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    beforeUpload = file => {
        const isLt2M = file.size / 1024 / 1024 < 10;
        const isXLXS = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (!isXLXS) {
            message.error("You can only upload xlxs file!");
        }
        if (!isLt2M) {
            message.error("XLXS file must be smaller than 10MB!");
        }
        return isLt2M && isXLXS;
    };
    openDeviceModal = () => {
        this.deviceChange([])
        this._getDeviceList(this.state.parentObject)
        this.setState({ modalVisible: true, })
    }
    _getDeviceList = (parentObject) => {
        const SiteId = localStorage.get("currentSite");
        let accessToken = localStorage.get("accessToken");
        const healthHeaders = {
            method: "GET",
            url: `/api/device/list?SiteId=${SiteId}&ParentEquipment=${parentObject && parentObject.MainId ? parentObject.MainId : null}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };

        axios(healthHeaders)
            .then((response) => {
                this.setState({ deviceList: response.data });
            })
            .catch((error) => { });
    };
    onOk = () => {
        const { selectedObject } = this.state
        this.setState({ modalVisible: false })
        this._createHierarchyAPI(selectedObject)
    }
    deviceChange = (value) => {
        console.log(value);
        const { deviceList, HierarchyName, parentObject } = this.state

        let data = []
        data = value && value.map((item) => {

            let innerObject = deviceList.map((selectedDevice) => {
                if (selectedDevice.Id === item) {
                    return {
                        Name: selectedDevice.Name,
                        Code: selectedDevice.DeviceCode,
                        ShortDescription: "This is " + selectedDevice.Name + " Equipment",
                        LongDescription: "This is " + selectedDevice.Name + " Equipment",

                        HierarchyName,
                        ParentId: parentObject && parentObject.Id ? parentObject.Id : null,
                        DeviceId: 1,
                        Type: "Device",

                        MainId: selectedDevice.Id,
                        Next: false,
                        HierarchyType: 1
                    }
                } else {
                    return undefined
                }
            })
            innerObject = innerObject.filter((element) => {
                return element !== undefined;
            });
            return innerObject && innerObject[0] ? innerObject[0] : {}
        })
        let payload = {
            data,
            deviceSelection: true,

        }
        this.setState({ selectedObject: payload, value })

    }

    _OnCancel = () => {
        this.setState({
            modalVisible: false,
        });
    };
    render() {

        let { getFieldDecorator } = this.props.form
        let { datasource, visible, editObj, parentHierarchy, ParentId,
            HierarchyName, modalVisible, deviceList } = this.state

        const columns = [{
            title: 'Name',
            dataIndex: 'Name',
        }, {
            title: 'Code',
            dataIndex: 'Code',
        }, {
            title: 'Short Description',
            dataIndex: 'ShortDescription',
        }, {
            title: 'Long Description',
            dataIndex: 'LongDescription',
        }, {
            title: 'Action',
            render: (text, record) =>
                <div>
                    <button type='button' onClick={() => this.editHierarchy(record)} style={{ marginRight: '10px' }} className='ant-btn' >
                        <Icon type='edit' />
                    </button>
                    <Popconfirm
                        title="Are you sure delete Hierarchy ?"
                        onConfirm={e => {
                            e.stopPropagation()
                            this._deleteHierarchy(record)
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button type='button' className='ant-btn'>
                            <Icon
                                type="delete"
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                            />
                        </button>
                    </Popconfirm>
                    {
                        (record.Next === true) ?

                            <button type='button' onClick={() => this.nextLevel(record)} style={{ marginLeft: '10px' }} className='ant-btn' >
                                Next Level
                            </button>
                            : null
                    }


                </div>
        }
        ]

        const token = localStorage.get("accessToken");
        var Authorization = "Bearer " + token;
        return (
            <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
                <Modal visible={modalVisible} onOk={this.onOk} onCancel={this._OnCancel}>

                    <Select
                        id=""
                        mode="multiple"
                        placeholder="Please Select  Device"
                        style={{ width: 350 }}
                        value={this.state.value}
                        onChange={(value) => this.deviceChange(value)}
                    >
                        {
                            deviceList && Array.isArray(deviceList) && deviceList.map((columns) => {
                                return (
                                    <Option value={columns.Id}>{columns.Name}</Option>
                                )
                            })}
                    </Select>


                </Modal>
                <Card bordered={false} >
                    <Drawer
                        title="Create Hierarchy"
                        visible={visible}
                        width={600}
                        closable={false}
                        onClose={this._cancel}
                    >

                        <Form layout="vertical">
                            <Row gutter={16}>
                                <Col span={24}>

                                    <Form.Item label="Name">
                                        {getFieldDecorator("Name", {
                                            initialValue: editObj.Name || '',
                                        })(
                                            <Input placeholder="Enter name" />
                                        )}
                                    </Form.Item>

                                    <Form.Item label="Code">
                                        {getFieldDecorator("Code", {
                                            initialValue: editObj.Code || '',

                                        })(
                                            <Input placeholder="Enter Hierarchy Code " />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Short Description">
                                        {getFieldDecorator("ShortDescription", {
                                            initialValue: editObj.ShortDescription || '',

                                        })(
                                            <Input placeholder="Enter Short Description " />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Long Description">
                                        {getFieldDecorator("LongDescription", {
                                            initialValue: editObj.LongDescription || '',
                                        })(
                                            <Input placeholder="Enter Long Description" />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Type">
                                        {getFieldDecorator("Type", {
                                            initialValue: editObj.Type || '',
                                        })(
                                            <Input placeholder="Enter Type" />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Enable Next Level">
                                        {getFieldDecorator("Next", {
                                            initialValue: editObj.Next,
                                        })(
                                            <Select>
                                                <Option value={true}>True</Option>
                                                <Option value={false}>False</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        <DrawerFooter>
                            <Button onClick={this._cancel} style={{ marginRight: 8, }}>Cancel</Button>
                            {
                                !editObj.Id ?
                                    <Button type="primary" onClick={this._createHierarchy}>Create</Button> :
                                    <Button type="primary" onClick={this._updateHierarchy}>Update</Button>
                            }
                        </DrawerFooter>
                    </Drawer>

                    <Button type="primary" size={"large"} style={{ marginBottom: '10px', float: "right" }}
                        onClick={this.openModal}>Add </Button>

                    <Button type='primary' size={"large"} style={{ marginBottom: '10px', float: "right", marginRight: "10px" }}
                        onClick={() => this.openDeviceModal()}>
                        Device Selection
                    </Button>

                    <Button onClick={() => {
                        history.goBack()
                        this._getHierarchyList(HierarchyName, parentHierarchy[parentHierarchy.length - 1])
                        parentHierarchy.splice(-1, 1)
                        this.setState({ parentHierarchy })
                    }}>Go back</Button>
                    <StyledTable theme={lightTheme}>
                        <Table
                            columns={columns}
                            dataSource={datasource}
                            pagination={
                                datasource.length > 10
                                    ? {
                                        pageSize: "10"
                                    }
                                    : false
                            }
                        />
                    </StyledTable>
                </Card>

                <Upload.Dragger
                    name="file"
                    action={`/api/hierarchymodule/hierarchyfileupload/${ParentId}`}
                    // accept="text/csv"
                    multiple="false"
                    headers={{
                        Authorization: Authorization
                    }}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">
                        Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">1. Your can only upload .xlxs file</p>
                    <p className="ant-upload-hint">2. File should be less than 10MB</p>
                </Upload.Dragger>
            </StyledDashboard>
        )
    }
}
export default Form.create()(NestedHierarchy)
