import React, { Component } from "react";
import cloneDeep from "lodash.clonedeep";
import moment from "moment";
import { message } from "antd";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import ReactEcharts from "../../Echarts/Echarts";
import {  trendLabelTranslation,
  predictionsLegendsTranslation,} from "../../../../../Adapter/TranslationAdapter"
import {
  historicPredictionsChartDataa,
  historicPredictionsWithPayloadChartDataa,
} from "../../ChartCalls";

import { getCurrentLanguage , getTranslationCache } from "../../../../../selectors/language"
// import { data } from './mock.js'

class ActualGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      translationCache: props.translationCache || [],
      language: props.language || "English",
    };
  }
  timeTicket = null;

  componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = 1 * 60000;
    }

    this.timeTicket = setInterval(() => {
      this.getData(this.props);
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.translationCache !== this.props.translationCache ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange
    ) {
      this.getData();
    }
  }

  componentWillReceiveProps(props) {
    this.getData(props);
  }

  componentWillUnmount() {
    clearInterval(this.timeTicket);
  }

  getData = async (props) => {
    let json = [];
    const { graphprops } = this.props;
    const option = cloneDeep(this.state.option);

    if (
      (graphprops &&
        graphprops &&
        graphprops.dashboardType === "dynamic" &&
        this.props.deviceId) ||
      (graphprops &&
        graphprops.dateExists === "true" &&
        this.props.selectionDate) ||
      (graphprops &&
        graphprops.monthExists === "true" &&
        this.props.selectionMonth) ||
      (graphprops &&
        graphprops.weekExists === "true" &&
        this.props.selectionWeek) ||
      (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
      (graphprops &&
        graphprops.dateRangeExists === "true" &&
        this.props.selectionDateRange)
    ) {
      json = await historicPredictionsWithPayloadChartDataa(
        "actuals",
        graphprops,
        this.props.deviceId,
        this.props.selectionDate,
        this.props.selectionMonth,
        this.props.selectionWeek,
        this.props.shift,
        this.props.selectionDateRange
      );
    } else if (graphprops) {
      json = await historicPredictionsChartDataa("actuals", graphprops);
    }

    // if (
    //   (graphprops && graphprops.dashboardType === 'dynamic' && this.props.deviceId) ||
    //   (graphprops && graphprops.dateExists === 'true' && this.props.selectionDate) ||
    //   (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
    //   (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
    //   (graphprops && graphprops.shiftExists === 'true' && this.props.shift)
    // ) {
    //   json = await historicPredictionsWithPayloadChartDataa(graphprops, this.props.deviceId, this.props.selectionDate, this.props.selectionMonth,  this.props.selectionWeek, this.props.shift)
    // } else if (graphprops) {
    //   json = await historicPredictionsChartDataa(this.props.graphprops, 'actuals',this.props.deviceId, this.props.selectionDate, this.props.selectionMonth, this.props.shift)
    // }

    let series = [];
    // json = [
    //   ["2020-04-24 14:03:03", 1]
    //   ,
    //   ["2020-04-24 14:03:03", 1]
    // ]

    if (json && Array.isArray(json)) {
      this.setState({
        data: json,
      });
      option.xAxis.data =
        json &&
        json.map(function(item) {
          return moment(item[0])
            .utc()
            .format("DD/MM/YYYY HH:mm:ss");
        });

      series = [
        {
          name: "Actual",
          symbolSize: 0,
          data: json.map(function(item) {
            return item[1];
          }),
          type: "line",
        },
      ];
    }

    series = await trendLabelTranslation(
      series,
      this.props.translationCache,
      this.props.language
    );
    let legends = await predictionsLegendsTranslation(
      option.legend,
      this.props.translationCache,
      this.props.language
    );
    option.legends = legends;
    option.series = series;

    this.setState({ option });
    return option;
  };

  getOption = () => {
    let option = {
      title: {
        left: "center",
        text:
          this.props && this.props.language !== "English"
            ? "実際の圧力グラフ"
            : "Actual Pressure Graph",
        textStyle: {
          color: "white",
          align: "center",
        },
      },
      toolbox: {
        showTitle: false,
        feature: {
          saveAsImage: {
            title: "download",
            backgroundColor: "black",
            name: this.props.graphprops.title,
          },
        },
      },
      tooltip: {
        trigger: "axis",
        textStyle: {
          color: "white",
          fontSize: 15,
        },
      },
      xAxis: {
        type: "category",
        // data: data.map(function (item) {
        //     return item[0];
        // }),
        axisLine: {
          lineStyle: {
            color: "white",
          },
        },
        axisTick: {
          lineStyle: {
            color: "white",
          },
        },
        axisLabel: {
          color: "white",
        },
      },
      yAxis: {
        splitLine: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "white",
          },
        },
        axisTick: {
          lineStyle: {
            color: "white",
          },
        },
        axisLabel: {
          color: "white",
        },
      },
      dataZoom: [
        {
          // startValue: '2020-04-20'
        },
        {
          type: "inside",
        },
      ],
      legend: {
        right: 60,
        data: ["Actual"],
        textStyle: {
          color: "white",
        },
      },
      // series: [{
      //     name: 'Anomaly',
      //     symbolSize: 12,
      //     data: data.map(function (item) {
      //         return item[1];
      //     }),
      //     type: 'scatter'
      // }, {
      //     symbolSize: 0,
      //     data: data.map(function (item) {
      //         return 4;
      //     }),
      //     type: 'line',
      //     lineStyle: {
      //         color: 'skyblue',
      //     },
      // }, {
      //     name: 'Actual',
      //     symbolSize: 12,
      //     data: data.map(function (item) {
      //         return item[2];
      //     }),
      //     type: 'line',
      //     symbol: 'triangle',
      //     lineStyle: {
      //         color: 'skyblue',
      //     },
      // }]
    };
    return option;
  };

  onChartClick = (cellData) => {
    if (cellData.seriesType === "scatter" && cellData.seriesIndex === 0) {
      let predictedObject = this.state.data.filter((predicted) => {
        return predicted[0] === cellData.name;
      });
      if (predictedObject[0] && predictedObject[0][3]) {
        this.props.summaryTable(predictedObject[0]);
      } else {
        message.warn("No Data");
      }
    }
  };

  render() {
    let onEvents = {
      click: (cellData) => this.onChartClick(cellData),
    };
    return (
      <div style={{ padding: "10px" }}>
        <ReactEcharts
          onEvents={onEvents}
          option={this.state.option}
          className="react_for_echarts"
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
});

export default connect(mapStateToProps)(ActualGraph);