import { fromJS } from 'immutable'

import { types } from './types'

export const getTagReferenceList = () => {
  return {
    type: types.GET_TAGREFERENCE_LIST
  }
}

export const getTagReferenceListSuccess = (response) => {
  return {
    type: types.GET_TAGREFERENCE_LIST_SUCCESS,
    response
  }
}

export const createDevice = (payload) => {
  return {
    type: types.CREATE_DEVICE,
    payload
  }
}
export const updateDevice = (payload) => {
  return {
    type: types.UPDATE_DEVICE,
    payload
  }
}
const initialState = fromJS({
  tagReferenceList: []
})

const tagReference = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TAGREFERENCE_LIST_SUCCESS: {
      return state.set('tagReferenceList', action.response)
    }
    default: return state
  }
}

export default tagReference
