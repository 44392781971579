export const constant = {

    //***trends label start***//
   
    Application_Settings:'Application Settings',
    LogOut:'Log Out'
    

    //***trends label stop***//
}
  