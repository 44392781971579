import React from "react";
import orderBy from "lodash.orderby";
import { Modal } from "antd";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Responsive, WidthProvider } from "react-grid-layout";
import { makeSelectThemeing, makeSelectTheme } from "../../../selectors/theme";

import Graph from "./Graph";
import {
  Title,
  lightTheme,
  darkTheme,
  PanelDiv,
  FullScreenIcon,
  ModalDiv,
} from "./CSS/styles.js";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../selectors/language";
import "react-grid-layout/css/styles.css";
import "./CSS/Panel.css";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
class Panel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedThemeing: this.props.selectedThemeing,
      selectedTheme: this.props.selectedTheme,
      visibe: false,
    };
  }

  componentDidUpdate(preprops) {
    if (preprops.navigationMode !== this.props.navigationMode) {
    }
  }

  componentDidMount() {
    let obj = {};
    this.props.panels.map((panelObject) => {
      obj = {
        [panelObject.id]: false,
      };
    });
    this.setState({
      ...obj,
    });
  }

  getTranslation = (keyword) => {
    let { translationCache } = this.props
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword
  }

  render() {

    return (
      <ResponsiveReactGridLayout
        className="layout"
        draggableHandle=".headerdrag"
        rowHeight={30}
        style={{
          width:
            this.props.navigationMode && this.props.navigationMode === "side"
              ? this.props.divWidth
                ? window.innerWidth - this.props.divWidth - 30
                : window.innerWidth - 100
              : undefined,
        }}
      >
        {this.getPanels(
          this.props.panels,
          this.props.dashboardName,
          this.state.selectedThemeing,
          this.state.selectedTheme
        )}
      </ResponsiveReactGridLayout>
    );
  }

  getPanels = (panels, dashboardName, theme, themes) => {
    let divWidth = this.props.divWidth
      ? window.innerWidth - this.props.divWidth - 30
      : window.innerWidth - 100;
    divWidth = window.innerWidth;

    panels = orderBy(panels, ["position.y"], ["asc"]);
    let prevx = 0,
      prevw = 0,
      prevy = 0,
      percent,
      actualwidth;
    let data = panels.map((value, index) => {
      let { x, y, w, h } = value.position;
      if (prevy !== y) {
        prevw = 0;
        prevx = 0;
      }
      if (divWidth < 1434 && divWidth >= 1368) {
        percent = (w * 100) / 1200;
        percent = Math.floor(percent * 100);
        actualwidth = (percent / 10) * 1.2;
        w = actualwidth;
        x = prevx + prevw;
        prevx = x;
        prevw = actualwidth;
        prevy = y;
      } else if (divWidth < 1299 && divWidth >= 1224) {
        percent = (w * 100) / 1000;
        percent = Math.floor(percent * 100);
        actualwidth = (percent / 10) * 1;
        w = actualwidth;
        x = prevx + prevw;
        prevx = x;
        prevw = actualwidth;
        prevy = y;
      } else if (divWidth < 1224 && divWidth >= 1000) {
        percent = (w * 100) / 1000;
        percent = Math.floor(percent * 100);
        actualwidth = (percent / 10) * 0.825;
        w = actualwidth;
        x = prevx + prevw;
        prevx = x;
        prevw = actualwidth;
        prevy = y;
      } else if (divWidth < 1000 && divWidth >= 801) {
        percent = (w * 100) / 1000;
        percent = Math.floor(percent * 100);
        actualwidth = (percent / 10) * 0.5;
        w = actualwidth;
        x = prevx + prevw;
        prevx = x;
        prevw = actualwidth;
        prevy = y;
      } else if (divWidth <= 800) {
        w = 12;
      }

      value.dashboardName = dashboardName;
      const styles = {
        fontWeight: "bold",
        fontSize: "18px",
      };
      const fullScreenStyle = {
        color: theme === "lightTheme" ? "rgba(0,0,0,.85)" : "#bfbfbf",
        marginTop: "-37px",
      };
      return (
        <PanelDiv
          key={dashboardName + index}
          data-grid={{ x, y, w, h, isResizable: true }}
          theme={theme === "lightTheme" ? lightTheme : darkTheme}
        >
          <div className="headerdrag">
            <Title
              style={styles}
              theme={theme === "lightTheme" ? lightTheme : darkTheme}
            >
              {this.getTranslation(value.title)}
            </Title>
            <FullScreenIcon
              style={fullScreenStyle}
              type="fullscreen"
              className="editsettings"
              title="fullscreen"
              onClick={() => {
                this.setState({
                  [value.id]: true,
                });
              }}
            />
          </div>

          <Graph
            key={"graph" + dashboardName + index}
            graphprops={value}
            drilldownRedirect={this.props.drilldownRedirect}
            deviceId={this.props.deviceId}
            selectionDate={this.props.selectionDate}
            selectionDateRange={this.props.selectionDateRange}
            selectionMonth={this.props.selectionMonth}
            selectionWeek={this.props.selectionWeek}
            dayMonthYear={this.props.dayMonthYear}
            shift={this.props.shift}
            dropdownSelection={this.props.dropdownSelection}
          />
          <ModalDiv
            key={dashboardName + index}
            theme={theme === "lightTheme" ? lightTheme : darkTheme}
          >
            <Modal
              id={dashboardName + index}
              className={theme}
              title={value.title}
              centered
              width="1200px"
              // height="900px"
              style={{ background: "black" }}
              visible={this.state[value.id]}
              onOk={() =>
                this.setState({
                  [value.id]: true,
                })
              }
              onCancel={() =>
                this.setState({
                  [value.id]: false,
                })
              }
              footer={null}
            >
              <Graph
                key={"graph" + dashboardName + index}
                graphprops={value}
                drilldownRedirect={this.props.drilldownRedirect}
                deviceId={this.props.deviceId}
                selectionDate={this.props.selectionDate}
                selectionDateRange={this.props.selectionDateRange}
                selectionMonth={this.props.selectionMonth}
                selectionWeek={this.props.selectionWeek}
                dayMonthYear={this.props.dayMonthYear}
                shift={this.props.shift}
                dropdownSelection={this.props.dropdownSelection}
              />
            </Modal>
          </ModalDiv>
        </PanelDiv>
      );
    });

    return data;
  };
}
const mapStateToProps = createStructuredSelector({
  selectedThemeing: makeSelectThemeing(),
  selectedTheme: makeSelectTheme(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(Panel);
