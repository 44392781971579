import React from 'react'
import { Fragment } from 'react'
import axios from 'axios'
import { Tag } from "antd";
import moment from "moment";

import Highlighter from 'react-highlight-words'
import { Table, Spin, Button, Modal, Form, Input, Radio, Icon } from 'antd'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import localStorage from '../../../../utils/localStorage'
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../ChartCalls'
import { tableTranslation } from "../../Utils/Adapter/translationAdapter";
import { getCurrentLanguage, getTranslationCache } from '../../../../selectors/language'
import { makeSelectThemeing } from "../../../../selectors/theme";
import { defaultRefreshTime } from '../constant.json';
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"

import 'antd/dist/antd.css'
import "../CSS/Table.css";

class FeedbackTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [],
      tabledata: [],
      loading: false,
      openModal: false,
      modalObj: {},
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      searchText: '',
      errorInAPI: false,
      noDataInDB: false
    }
  }

  columns = [];

  tabledata = [];

  config = {
    redirectTo: 'counter'
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  rowUpdateInterval = null;

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = ""
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange
    ) {
      this.getData();
    }
  }



  openModal = (e, record) => {
    let { openModal } = this.state
    this.setState({
      openModal: !openModal,
      modalObj: record
    })
  }

  getActionColumn = (text) => {

    return (<div onClick={(e) => this.openModal(e, text)} style={{ color: '#fff' }}>Click to feedback</div>)
  }


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record && record[dataIndex] && record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: 'white', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }


  updateData = async (data) => {
    let theme = this.props.selectedThemeing;
    try {
      this.columns = []
      this.tabledata = []
      const columnNames = Object.keys(data[0])

      columnNames.map((value, key) => {

        if (value.length > 0 && value !== "color" && value !== "noData" && value !== "Action" && value !== "Comment" && value !== "Timestamp") { //ignoring columns
          this.columns.push({
            title: value,
            dataIndex: value,
            key: value,
            ...this.getColumnSearchProps(value),
            render(text, record, key) {
              return {
                props: {
                  style: { color: record && record.color ? record.color : '#000' },
                },
                children: <text style={{ color: record && record.color ? record.color : '#8c8c8c' }}>{text}</text>,
              };
            },
          })
        } else if (value && value === "Action") {
          this.columns.push({
            title: value,
            dataIndex: value,
            key: value,
            render: (text, row, key) => {
              return {
                children: this.getActionColumn(row),
              };
            },
          })
        } else if (value && value === 'Timestamp') {
          this.columns.push({
            title: value,
            dataIndex: value,
            key: value,
            //...this.getColumnSearchProps(value),
            render: (text, record, key) => {
              return {
                style: { color: theme === "lightTheme" ? "black" : "white" },
                children: <text style={{ color: record && record.color ? record.color : '#8c8c8c' }}>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</text>,
              };
            },
          })
        }

      })

      if (data && data[0] && data[0]['noData']) {

      } else {
        data.map((dataval, dataindex) => {
          dataval.key = dataindex + 1
          this.tabledata.push(dataval)
        })
      }
      let translationTable = await tableTranslation(this.columns, this.props.translationCache, this.props.language)
      this.setState({
        columns: translationTable,
        tabledata: this.tabledata
      })
    } catch (e) {
      this.setState({
        columns: [
          //       {
          //      title:"Timestamp",
          //      dataIndex:"Timestamp",
          //      key:"Timestamp"
          //    },

          //     {
          //       title:"Notification_Id",
          //       dataIndex:"Notification_Id",
          //       key:"Notification_Id"
          //     },

          //     {
          //       title:"Tag",
          //       dataIndex:"Tag",
          //       key:"Tag"
          //     },
          //     {
          //       title:"Action",
          //       dataIndex:"Action",
          //       key:"Action"
          //     },
          //     {
          //       title:"Comment",
          //       dataIndex:"Comment",
          //       key:"Comment"
          //     }

          //   ], tabledata:  [
          //     {
          //       "Timestamp": '2020-06-05T01:00:00.000Z',
          //       "Notification_Id": 1,
          //       "Tag": 'PIC301',
          //       "Action": "yes", //(yes/no)
          //       "Comment": ""
          //     }
        ]
      })
    }
  };

  getData = async () => {
    this.requestCheck = false
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false
    let data = []
    let { graphprops } = this.props

    // this.setState({ loading: true });
    if ((graphprops && graphprops.dashboardType === 'dynamic' && this.props.deviceId) ||
      (graphprops && graphprops.dateExists === 'true' && this.props.selectionDate) ||
      (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
      (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
      (graphprops && graphprops.shift === 'true' && this.props.shift) ||
      (graphprops && graphprops.dateRangeExists === 'true' && this.props.selectionDateRange)
    ) {

      data = await getChartDataWithSelection(
        graphprops,
        this.props.deviceId,
        this.props.selectionDate,
        this.props.selectionMonth,
        this.props.selectionWeek,
        this.props.shift,
        this.props.selectionDateRange,
        this.props.dayMonthYear,
        this.abortController
      );
      this.requestCheck = true
      this.setState({ loading: false });
    } else {
      data = await getChartDataWithoutSelection(graphprops, this.abortController);
      this.requestCheck = true

      if ((data && data.message) || (Array.isArray(data) && (data.length === 0 &&
        graphprops.enableMock !== true))) {

        if (data.message) {
          errorInAPI = true
        } else if (data.length === 0) {
          noDataInDB = true
        }

      } else {

        this.setState({
          loading: false,
          errorInAPI,
          noDataInDB

        });
      }


      //   data = [
      //     {
      //       "Timestamp": '2020-06-05T01:00:00.000Z',
      //       "Notification_Id": 1,
      //       "Tag": 'PIC301',
      //       "Action": "yes", //(yes/no)
      //       "Comment": ""
      //     }
      //  ]
      this.updateData(data)

    };
  }

  handleOk = () => {
    let { modalObj } = this.state
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        values = {
          ...values,
          "Notification Id": modalObj['Notification Id']
        }
        //Feedback
        // let response = await this.submitFeedbackAPI(values)
        this.getData(this.props);
        this.setState({
          openModal: false
        })
      }

    })
  }

  submitFeedbackAPI = (payload) => {
    //API
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get("accessToken");
    const deviceTypeObject = {
      method: "POST",
      url: `/predictions/update`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    return axios(deviceTypeObject)
      .then(response => {
        return 'success'
      })
      .catch(function () {
        return 'error'
      });


  }

  handleCancel = () => {
    this.setState({
      openModal: false
    })
  }
  render() {
    let theme = this.props.selectedThemeing;
    let { loading, columns, openModal, modalObj, errorInAPI, noDataInDB, refreshDateTime } = this.state
    const { getFieldDecorator } = this.props.form;
    let { graphprops } = this.props
    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <span>
              <Modal
                visible={openModal}
                title="Prediction feedback"
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
                  <Button key="back" onClick={this.handleCancel}>
                    Cancel
                  </Button>,
                  <Button key="submit" type="primary" onClick={this.handleOk}>
                    Submit
                  </Button>,
                ]}
              >

                <Form onSubmit={this.handleSubmit}>
                  <Form.Item label=" Is this prediction useful?">
                    {getFieldDecorator('Action', {
                      initialValue: modalObj.Action || 'no',
                      rules: [
                        {
                          required: true,
                          message: 'Yes/No ? ',
                        },
                      ],
                    })(
                      <Radio.Group>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                      </Radio.Group>)}
                  </Form.Item>
                  <Form.Item label="Comment">
                    {getFieldDecorator('Comment', {
                      initialValue: modalObj.Comment || '',
                      rules: [
                        {
                          required: true,
                          message: 'Please input Comment',
                        },
                      ],
                    })(<Input.TextArea min={'5'} />)}
                  </Form.Item>
                </Form>

              </Modal>

              {loading === false && columns && columns.length > 0 ?
                <Table
                  bordered
                  scroll={{ x: (this.tabledata.length > 5) ? true : false }}
                  className="TableWidget"
                  style={{ padding: '0px 10px', marginTop: '12px', fontSize: '13px !important' }}
                  columns={this.state.columns}
                  dataSource={this.state.tabledata}
                  pagination={{ pageSize: '5' }}
                />
                :
                <div
                  style={{
                    textAlign: "center",
                    color: theme === "lightTheme" ? "black" : "white",
                    marginTop: "10%"
                  }}
                >
                  <h2 style={{ color: theme === "lightTheme" ? "black" : "white" }}>
                    <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                  </h2>
                </div>
              }
            </span>
        }
      </Fragment>

    )
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
})


export default connect(mapStateToProps)(Form.create()(FeedbackTable))

