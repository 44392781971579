import React from "react";
import moment from "moment";
import { Spin } from "antd";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ReactEcharts from "../Echarts/Echarts";
import clone from "lodash.clonedeep";
import { trendLabelTranslation } from "../../../../Adapter/TranslationAdapter";
// import { trendLabelTranslation } from "../../Adapter/TranslationAdapter";
import {
  historicChartData,
  historicWithPayloadTableChartData,
} from "../ChartCalls";
import AnomalyFeedbackTable from "./AnomalyFeedbackTable";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
class NotificationScatter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      loading: false,
      notificationFormData: {},
      openModal: false,
      feedbackForm: {},

      translationCache: props.translationCache || [],
      language: props.language || "English",
    };
  }
  timeTicket = null;
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = 1 * 60000;
    }

    this.timeTicket = setInterval(() => {
      this.getData(this.props);
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear
    ) {
      this.getData();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeTicket);
  }

  getData = async () => {
    let json = [];
    let series = [];
    const { graphprops } = this.props;
    let colors = [];
    let option = this.getOption();
    if (graphprops.colors && graphprops.colors.length > 0) {
      colors = graphprops.colors;
    } else {
      colors = ["rgb(251, 118, 123)", "rgb(129, 227, 238)"];
    }
    if (
      (graphprops &&
        graphprops.dashboardType === "dynamic" &&
        this.props.deviceId) ||
      (graphprops &&
        graphprops.dateExists === "true" &&
        this.props.selectionDate) ||
      (graphprops &&
        graphprops.monthExists === "true" &&
        this.props.selectionMonth) ||
      (graphprops &&
        graphprops.weekExists === "true" &&
        this.props.selectionWeek) ||
      (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
      (graphprops &&
        graphprops.dateRangeExists === "true" &&
        this.props.selectionDateRange) ||
      (graphprops &&
        graphprops.dayMonthYearExists === "true" &&
        this.props.dayMonthYear)
    ) {
      json = await historicWithPayloadTableChartData(
        graphprops,
        this.props.deviceId,
        this.props.selectionDate,
        this.props.selectionMonth,
        this.props.selectionWeek,
        this.props.shift,
        this.props.selectionDateRange,
        this.props.dayMonthYear
      );
    } else {
      json = await historicChartData(graphprops);
    }
    //  json =[
    //   {
    //     "scatter": [
    //       {
    //         "interval_alias": "2020-11-13 16:00:00",
    //         "tagname": "No 1 chip supply  Conveyor torque PV",
    //         "tagvalue": 10,
    //         "notification": {
    //           "Timestamp": "2020-11-13 10:30:00",
    //           "TagName": "TRR_TR121_PV",
    //           "Action": null,
    //           "Comment": null
    //         }
    //       }
    //     ]
    //   },
    //   {
    //     "scatter": [
    //       {
    //         "interval_alias": "2020-11-13 16:00:00",
    //         "tagname": "Chip supply feeder torque PV",
    //         "tagvalue": 26,
    //         "notification": {
    //           "Timestamp": "2020-11-13 16:00:00",
    //           "NotificationId": "TRR_TR131_PV_2020-11-14 01:00:00",
    //           "TagName": "TRR_TR131_PV",
    //           "TagDescription": "There are 26 spikes in back one hour",
    //           "Action": null,
    //           "Comment": null
    //         }
    //       },
    //       {
    //         "interval_alias": "2020-11-14 11:00:00",
    //         "tagname": "Chip supply feeder torque PV",
    //         "tagvalue": 38,
    //         "notification": {
    //           "Timestamp": "2020-11-14 11:00:00",
    //           "TagName": "TRR_TR131_PV",
    //           "Action": null,
    //           "Comment": null
    //         }
    //       }
    //     ]
    //   }
    // ]
    if (json && Array.isArray(json)) {
      json.map((item, j) => {
        let xAxisDataArray = [];
        let name = [];
        item &&
          item.scatter &&
          Array.isArray(item.scatter) &&
          item.scatter.map(function (data) {
            xAxisDataArray.push(
              moment
                .utc(data.interval_alias, "YYYY-MM-DD HH:mm")
                .local()
                .format("YYYY-MM-DD HH:mm")
            );
            name.push(data.tagname);
          });

        let scatterObj = {
          name: name[0],
          symbolSize: 15,
          type: "bar",
          color: colors[j],
          data:
            item &&
            item.scatter &&
            item.scatter.map(function (item) {
              return item.tagvalue;
            }),
        };
        let effectScatterObj = {
          name: name[0],
          symbolSize: 15,
          type: "bar",
          color: colors[j],
          data:
            item &&
            item.effectScatter &&
            item.effectScatter.map(function (item) {
              return item;
            }),
        };
        series.push(scatterObj);
        series.push(effectScatterObj);
        let finalArray = xAxisDataArray.sort();

        option.xAxis.data = finalArray;
      });
    }
    series = await trendLabelTranslation(
      series,
      this.props.translationCache,
      this.props.language
    );
    option.series = series;
    this.setState({ option, data: json });
    return option;
  };

  onChartClick = (cellData) => {
    let { data } = this.state;
    data &&
      data.map((item, j) => {
        item.scatter.map((data) => {
          if (
            moment.utc(data.interval_alias)
              .local()
              .format("YYYY-MM-DD HH:mm") === cellData.name &&
            data.tagvalue === cellData.value &&
            data.tagname === cellData.seriesName
          ) {
            let feedbackForm = clone(data.notification);
            this.setState({ feedbackForm, openModal: true });
          }
        });
      });
  };
  handleOk = (e) => {
    this.setState({
      openModal: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      openModal: false,
    });
  };

  render() {
    let { loading, openModal, feedbackForm } = this.state;
    let onEvents = {
      click: (cellData) => this.onChartClick(cellData),
    };
    return (
      <div style={{ height: "70%", marginTop: "40px" }}>
        {loading === false ? (
          <div style={{ padding: "10px" }}>
            <ReactEcharts
              onEvents={onEvents}
              option={this.state.option}
              className="react_for_echarts"
            />
            <AnomalyFeedbackTable
              feedbackForm={feedbackForm}
              openModal={openModal}
              // onOk={this.handleOk}
              // onCancel={this.handleCancel}
              refereshData={(refresh) => {
                this.getData()
              }}
            />
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              color: "white",
              marginTop: "15%",
            }}
          >
            <h2 style={{ color: "white" }}>
              <Spin spinning={loading} tip="Loading..." size="large"></Spin>
            </h2>
          </div>
        )}
      </div>
    );
  }
  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      tooltip: {
        formatter: function (param) {
          return [
            "Date " + param.name + ": ",
            "value: " + param.data,
            "Name: " + param.seriesName,
          ].join("<br/>");
        },
      },
      // tooltip: {
      //   // tooltip: {
      //   trigger: "item",
      //   formatter: "{a} <br/>{b}  ",
      //   //    trigger: "axis",

      //   textStyle: {
      //     color: theme === "lightTheme" ? "black" : "white",
      //     fontSize: 12,
      //   },
      // },
      legend: {
        left: "center",
        itemHeight: 17,
        itemWidth: 17,
        itemGap: 10.5,
        textStyle: {
          color: theme === "lightTheme" ? "black" : "white",
          fontWeight: "bold",
          fontSize: 12,
        },
      },
      xAxis: {
        type: "category",
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },

        axisTick: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },

        axisLabel: {
          color: theme === "lightTheme" ? "black" : "white",
          formatter: function (value, index) {
            return moment(value).format("HH:mm");
          }
        },
        scale: true,
      },

      yAxis: {
        scale: true,

        // splitLine: {
        //     lineStyle: {
        //         type: 'dashed'
        //     }
        // },
        splitLine: {
          show: false,
        },
        // scale: true,
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisTick: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisLabel: {
          color: theme === "lightTheme" ? "black" : "white",
        },
      },
      dataZoom: {
        type: "slider",
      },

      series: [],
      toolbox: {
        showTitle: false,
        // orient: "vertical",
        itemSize: 25,
        feature: {
          saveAsImage: {
            // backgroundColor: "black",
            title: "download",
            backgroundColor: theme === "lightTheme" ? "white" : "black",
            name: this.props.graphprops.title,
            iconStyle: {
              // color: "none",
              borderColor: "#8c8c8c",
              borderWidth: 2,
            },
          },
        },
      },
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
});

export default connect(mapStateToProps)(NotificationScatter);
