export const types = {

  GET_DEVICETYPE: '@/GET_DEVICETYPE',
  GET_DEVICETYPE_SUCCESS: '@/GET_DEVICETYPE_SUCCESS',

  SAVE_DEVICETYPE: '@/SAVE_DEVICETYPE',
  UPDATE_DEVICETYPE: '@/UPDATE_DEVICETYPE',
  DELETE_DEVICETYPE: '@/DELETE_DEVICETYPE',

  SAVE_DEVICEPARAMETERS: '@/SAVE_DEVICEPARAMETERS',
  DELETE_DEVICEPARAMETERS: '@/DELETE_DEVICEPARAMETERS',

  GET_DEVICETYPE_CM: '@/GET_DEVICETYPE_CM',
  GET_DEVICETYPE_CM_SUCCESS: '@/GET_DEVICETYPE_CM_SUCCESS'

}
