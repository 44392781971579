import styled from 'styled-components'

export const StyledApplication = styled.div`
background: #173049 ;
margin: 20px;
padding: 30px;
min-height: 750px;
`

export const lightTheme = {
    text: 'rgba(0,0,0,.85)',
    even: '#d9d9d9',
    odd: '#efefef',
    hover: '#f5f5f5',
   };
   
   export const StyledTable = styled.table`
       caption-side: top;
     
     
       color: ${(props) => props.theme.text} !important;
     
       width: 100%;
       /* border-collapse: separate; */
       /* border-spacing: 5px 10px; */
     
       caption-side: bottom;
       /* empty-cell: show | hide;  */
       /* empty-cell is a property of table or the cells themselves */
     
       /* vertical-align: baseline | sub | super | text-top | 
                     text-bottom | middle | top | bottom | 
                     <percentage> | <length> */
     
       /* tbody {
         vertical-align: top;
       }              */
       th{
           background:#13c2c2 !important;
           border: 1px solid #bfbfbf !important;
           text-align:center !important;
           color:#fff !important;
       }
       td
        {lightTheme
           border: 1px solid #bfbfbf !important;
           text-align:center !important;
         color: ${(props) => props.theme.text} !important;
       }
       
     
       td {
         padding: 11px 10px;
         font-size: 1.2em !important;
     
       }
       .ant-table-thead > tr > th{
         font-weight:bold !important
         font-size: 1.2em !important;
         
          // color:red !important
       }
       text[style] {
         font-weight:bold !important
         font-size: 2em !important;
         color: ${(props) => props.theme.text} !important;
      }
       tbody tr {
         :nth-of-type(odd) {
           background-color:${(props) => props.theme.odd} !important;
         }
         :hover {
           background-color:${(props) => props.theme.hover} !important; 
         }
       }
       tbody tr {
         :nth-of-type(even) {
           background-color:${(props) => props.theme.even} !important;
         }
         :hover {
           background-color:${(props) => props.theme.hover} !important; 
         }
       }
       thead > tr {
         color: ${(props) => props.theme.text} !important;
         background-color: #262626 !important;
       }
       caption {
         font-size: 0.9em;
         padding: 5px;
         font-weight: bold;
       }
     `;
     
   