import React from "react";
import moment from "moment";
import axios from 'axios';
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { getCurrentLanguage, getTranslationCache } from "../../../selectors/language";
import {
  message, Upload, Icon, Button, Steps, Form, Drawer, Select, Row, Divider,
  Col,
  Table,
} from "antd";
import localStorage from "../../../utils/localStorage";
import constant from '../../../constants/config.json'
import "./styles.css";
import { DrawerFooter } from "./style"
moment.locale("English");
const { Step } = Steps;
let { Option } = Select
const FormItem = Form.Item;
class ConfigurationUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      current: 0,
      visible: false,
      FileNames: {},
      datasource: [],
      filenamevisible: false,
      Type: "Asset",
      Limit: 1
    }
  }
  handleChange = info => {
    const { status } = info.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  onChange = current => {
    this.setState({ current });
    if (current === 0) {
      this.setState({
        Type: "Asset"
      })
    }
    else {
      this.setState({ Type: "Device" })
    }
  };

  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 10;
    const isCSV = file.type === "text/csv";
    if (!isCSV) {
      message.error("You can only upload CSV file!");
    }
    if (!isLt2M) {
      message.error("CSV file must be smaller than 10MB!");
    }
    return isLt2M && isCSV;
  };

  handleButton = (e) => {
    this.setState({
      visible: true,
    })
    this.handleOk()
  };

  handleCancel = (e) => {
    this.props.form.resetFields();
    this.setState({
      visible: false,
      datasource:[]
    });
  };

  handleOk = (e) => {
    this.setState({
      datasource:[]
    })
    const accessToken = localStorage.get("accessToken");
    let siteId = localStorage.get("currentSite");
    const deviceTypeObject = {
      method: "POST",
      url: `/api/asset/getLastConfigRecordsByType?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        Type: this.state.Type,
        Limit: e || 1
      }

    };
    return axios(deviceTypeObject)
      .then((response) => {
        this.setState({ datasource: response.data, Limit: e })
      }).catch((error) => {
        console.log(error)
      })

  }
  
  render() {
    const columns = [
      {
        title: 'FileName',
        dataIndex: 'FileName',
        key: 'FileName',
      },
      {
        title: 'Download',
        dataIndex: 'Download',
        key: 'Download',
        render: (text, row, key) => {
          return {
            children: (
              <div>
              
                <a href={`https://bpt.bmecomosolution.com/api/uploads/${row.FileName}`}>
                <Icon style={{ marginLeft: "20px",color: "#1890ff" }} type="download" />
                </a>
              </div>
            )
          };
        }
      },
    ];
    const { current, datasource, Type,  } = this.state;
    let ipAddress = constant.ipAddress
    let siteId = localStorage.get('currentSite')
    const token = localStorage.get("accessToken");
    var Authorization = "Bearer " + token;
    const steps = [
      {
        title: "Asset",
        key: "asset",
        content: (
          <Upload.Dragger
            name="file"
            action={`/api/asset/bulk?SiteId=${siteId}`}
            accept="text/csv"
            multiple="false"
            headers={{
              Authorization: Authorization
            }}
            beforeUpload={this.beforeUpload}
            onChange={this.handleChange}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">1. Your can only upload .CSV file</p>
            <p className="ant-upload-hint">2. File should be less than 10MB</p>
          </Upload.Dragger>
        )
      },
      {
        title: "Equipment",
        key: "device",
        content: (
          <Upload.Dragger
            name="file"
            action={`/api/device/bulk?SiteId=${siteId}`}
            accept="text/csv"
            multiple="false"
            headers={{
              Authorization: Authorization
            }}
            beforeUpload={this.beforeUpload}
            onChange={this.handleChange}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">1. Your can only upload .CSV file</p>
            <p className="ant-upload-hint">2. File should be less than 10MB</p>
          </Upload.Dragger>
        )
      }
    ];
    return (
      <div style={{ minHeight: window.innerHeight - 300 }}>
        <div>
          <Steps current={current} type="navigation" onChange={this.onChange}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                marginTop: "20px"
              }}
            >
              <div style={{ "marginLeft": "40px" }}>
                <Icon type="download" style={{ color: "#1a91ff" }} />
                <a
                  href={`${ipAddress}/api/sample/${steps[current].key}.csv`}
                >
                  {" "}
                  {steps[current].title} Sample Template
                </a>
              </div>
              <div style={{ "marginLeft": "20px" }}>
                {/* <a
                  href={``}
                >
                  {" "}
                  {steps[current].title} Previous Upload
                </a> */}
                <Button type="primary" onClick={this.handleButton}>
                  {" "}
                  {steps[current].title} Previous Upload
                </Button>

                <Drawer
                  title=
                  {steps[current].title} Previous Downloads
                  width={550}
                  visible={this.state.visible}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                >
                  <Form>
                    <Row>
                      <Col>
                        <Form.Item label="Limit">

                          <Select value={this.state.Limit || 1} style={{ width: "250px" }} onChange={this.handleOk}>
                            <Option value="1">Last Version</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                            <Option value="4">4</Option>
                            <Option value="5">5</Option>
                          </Select>

                        </Form.Item>
                        <FormItem>
                          <Table columns={columns} dataSource={datasource}>

                          </Table>
                        </FormItem>
                      </Col>
                    </Row>
                  </Form>
                  <DrawerFooter>
                    <Button onClick={this.handleCancel} style={{ marginRight: 8 }}> Ok </Button>
                    <Button onClick={this.handleCancel} type="primary">Cancel</Button>
                  </DrawerFooter>
                </Drawer>

              </div>
            </div>
            <div className="steps-content">{steps[current].content}</div>
          </div>

          <div className="steps-action">
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => this.next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => message.success("Processing complete!")}
              >
                Done
              </Button>
            )}
            {current > 0 && (
              <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                Previous
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
});
export default connect(mapStateToProps)(Form.create()(ConfigurationUpload));