import React, { Component } from 'react'
import {
    Input,
    Tag,
    Icon
} from "antd";
class ColorInputWidget extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inputTagVisible: false,
            tagList: [],
            graphTypeProps: props.graphTypeProps || {},
            value: props.value || []
        }
    }

    componentDidUpdate(prevprops) {
        if (prevprops.value !== this.props.value) {
            this.setState({
                value: this.props.value || []
            })
        }
    }

    render() {
        let { value, graphTypeProps } = this.state
        return (
            <div >
                <Tag
                    onClick={() => {
                        this.setState({
                            inputTagVisible: !this.state.inputTagVisible,
                        });
                    }}
                    style={{ background: "#fff", borderStyle: "dashed" }}
                    value={value}
                >
                    <Icon type="plus" /> New Tag
                </Tag>

                {this.state.inputTagVisible && (
                    <Input
                        type="text"
                        onPressEnter={(e) => {
                            let valueData = [
                                ...value,
                                e.target.value
                            ]
                            this.setState({
                                inputTagVisible: !this.state.inputTagVisible
                            });
                            this.props.addedTagList(valueData)
                        }}
                    />
                )}
                {value && Array.isArray(value) && value.map((tags, index) => {
                    const isLongTag = tags.length > 20;
                    const tagElem = (
                        <Tag
                            color={tags}
                            key={tags}
                            closable={true}
                            onClose={() => {
                                value.splice(index, 1);
                                this.setState({
                                    inputTagVisible: !this.state.inputTagVisible
                                });
                                this.props.addedTagList(value)
                            }}
                        >
                            {isLongTag ? `${tags.slice(0, 20)}...` : tags}
                        </Tag>
                    );
                    return isLongTag ? tagElem : tagElem;
                })
                }
            </div>
        )
    }
}

export default (ColorInputWidget)
