import axios from "axios";
import localStorage from "../../utils/localStorage";
import moment from "moment";
/**this function is for save rule and param is json  payload  */
export async function saveRule(param) {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");

  let Authorization = "Bearer " + token;
  const saveRule = {
    method: "POST",
    url: `/api/ruleEngine/create`,
    headers: {
      Authorization: Authorization,
    },
    data: { ...param, "SiteId": siteId },
  };
  return axios(saveRule)
}
/**this function is to get the rule list created by user based on siteID */
export async function ruleList() {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");

  let Authorization = "Bearer " + token;
  const ruleList = {
    method: "POST",
    url: `/api/ruleEngine/list`,
    headers: {
      Authorization: Authorization,
    },
    data: {
      "SiteId": siteId ? siteId : "1"
    }
  };
  return axios(ruleList)
}
/**this function is for get the rule details based on ID for edit or update purpose  */
export async function ruleListID(param) {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");

  let Authorization = "Bearer " + token;
  const getById = {
    method: "post",
    url: `/api/ruleEngine/getById`,
    headers: {
      Authorization: Authorization,
    },
    data: {
      "SiteId": siteId,
      "Id": param
    }
  };
  return axios(getById)
}
/**this function is for update the rule   */
export async function editRule(key, payLoad) {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");
  let Authorization = "Bearer " + token;
  const updateRule = {
    method: "PATCH",
    url: `/api/ruleEngine/update`,
    headers: {
      Authorization: Authorization,
    },
    data: { ...payLoad, "SiteId": siteId, "Id": parseInt(key) }
  };
  return axios(updateRule)
}
/**this function is for delete the rule  based on rule id  */
export async function deleteRule(key) {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");

  let Authorization = "Bearer " + token;
  const deleteRule = {
    method: "POST",
    url: `/api/ruleEngine/delete`,
    headers: {
      Authorization: Authorization,
    },
    data: { "SiteId": siteId, "Id": parseInt(key) }
  };
  return axios(deleteRule)
}
/**this function purpose to get the Device List */
export async function deviceList() {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");

  let Authorization = "Bearer " + token;
  const deciceListApi = {
    method: "POST",
    /**this url for device TypeList */
    // url:`/api/device/getDeviceTypes`,
    /**this url for device List */
    url: `/api/device/cache`,
    // url: `/api/ruleEngine/getDevicesList?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization,
    },
    data: {
      "Module": "",
      "SiteId": siteId
    }
  };
  return axios(deciceListApi)
}
/**this function purpose to get the Device Type List */
export async function deviceTypeList() {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");

  let Authorization = "Bearer " + token;
  const deciceListApi = {
    method: "POST",
    /**this url for device TypeList */
    url: `/api/device/getDeviceTypes`,

    headers: {
      Authorization: Authorization,
    },
    data: {
      "Module": "",
      "SiteId": siteId
    }
  };
  return axios(deciceListApi)
}
/**this function purpose is to get recipient or user list for mail ID purpose */
export async function recipientList() {
  const token = localStorage.get("accessToken");
  // let payload = {
  //   type: "RuleEngine"
  // }
  let Authorization = "Bearer " + token;
  const recipientListApi = {
    method: "GET",
    url: `/api/user/list`,
    headers: {
      Authorization: Authorization,
    }
  };
  return axios(recipientListApi)
}
/**this funtion is to get the list of parametbased on seleted Device ID  */
export async function getparameterList(device, type) {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");
  let Authorization = "Bearer " + token;
  const parameterListApi = {
    method: "POST",
    //url: `/api/ruleEngine/getParametersList?SiteId=${siteId}`,
    // url: `/api/device/relatedParams?SiteId=${siteId}`,
    url: `/api/device/relatedParams?SiteId=${siteId}`,
    data: {
      DeviceId: device,
      // "Type":type 
      "Type": "Device"
    },
    headers: {
      Authorization: Authorization,
    },
  };
  return axios(parameterListApi)
}
/**this function purpose is to get REST API list */
export async function urlList() {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");

  let Authorization = "Bearer " + token;
  const urlListApi = {
    method: "GET",
    url: `/api/ruleEngine/getRestApiList`,
    headers: {
      Authorization: Authorization,
    },
  };
  return axios(urlListApi).then((response) => {
    return response.data
  }).catch(() => {
    return []
  })
}
/**this function purpose is to either enable or disable the rule to trigger notifications  */
export async function updateRuleStatus(payLoad) {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");

  let Authorization = "Bearer " + token;
  const upateRuleStatus = {
    method: "POST",
    url: `/api/ruleEngine/updateRuleStatus`,
    data: { ...payLoad, "SiteId": siteId },
    headers: {
      Authorization: Authorization,
    },
  };
  return axios(upateRuleStatus)
}
/** this function is get noDevice parameters list*/
export async function nodeviceParameterList(deviceListData) {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");

  let Authorization = "Bearer " + token;

  const nodeviceparameterList = {
    method: "GET",
    url: `/api/ruleEngine/getNonDeviceParametersList?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization,
    },
    data: {
      deviceListData

    }
  };
  // return axios(nodeviceparameterList)
  return axios(nodeviceparameterList)
    .then(response => {
      return response.data
    })
    .catch(function () { });

}

export async function tablesList() {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");

  let Authorization = "Bearer " + token;

  const tableList = {
    method: "GET",
    url: `/api/ruleEngine/getTableList?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization,
    },
  };
  return axios(tableList).then((response) => {
    return response.data
  }).catch(() => {
    return []
  })
}

export async function getColumnsList(tables) {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");

  let Authorization = "Bearer " + token;
  const columnList = {
    method: "POST",
    url: `/api/ruleEngine/getColumnList?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization,
    },
    data: {
      tableId: tables,
    },
  };
  return axios(columnList)
}
/**this function purpose is get the cronJob frequency list which was usingon batch Type */
export async function frequencyList() {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");

  let Authorization = "Bearer " + token;
  const frequencyList = {
    method: "GET",
    url: `/api/ruleEngine/getprioritylist?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization,
    },
  };
  return axios(frequencyList).then((response) => {
    return response.data
  }).catch(() => {
    return []
  })
}
/**this function purpose is to get the rule trigger list based on rule selection */
export async function getRuleExecutionInfo(ruleId, value) {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");

  let Authorization = "Bearer " + token;
  const getRuleExecutionInfo = {
    method: "POST",
    url: `/api/ruleEngine/getRuleStatus`,
    headers: {
      Authorization: Authorization,
    },
    data: {
      "RuleId": ruleId,
      "StartDate": moment(value[0]).format('YYYY-MM-DD HH:mm'),
      "EndDate": moment(value[1]).format('YYYY-MM-DD HH:mm'),
      "SiteId": siteId

    }
  };
  return axios(getRuleExecutionInfo);
}
/**this function purpose is to give  the feedback for already excuted or notification trigger rules */
export async function saveFeedback(feedBackInfo) {
  const token = localStorage.get("accessToken");
  let Authorization = "Bearer " + token;
  const saveFeedback = {
    method: "POST",
    url: `/api/ruleEngine/saveFeedbackData`,
    data: feedBackInfo,
    headers: {
      Authorization: Authorization,
    },
  };
  return axios(saveFeedback);
}

export async function getfeedBackInfo(ruleId) {
  const token = localStorage.get("accessToken");

  let Authorization = "Bearer " + token;
  const getRuleExecutionInfo = {
    method: "GET",
    url: `/api/ruleEngine/getFeedbackList/${ruleId}`,
    headers: {
      Authorization: Authorization,
    },
  };
  return axios(getRuleExecutionInfo);
}
export async function getWarningTypes() {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");

  let Authorization = "Bearer " + token;
  const getWarningTypes = {
    method: "GET",
    url: `/api/ruleEngine/getWarningTypes?SiteId=${siteId}
    `,
    headers: {
      Authorization: Authorization,
    },
  };
  return axios(getWarningTypes);
}
/**this function purpose is to updated  the feedback  */
export async function updateFeedBack(feedBackInfo) {
  const token = localStorage.get("accessToken");
  let Authorization = "Bearer " + token;
  const getRuleExecutionInfo = {
    method: "POST",
    url: `/api/ruleEngine/updateFeedback`,
    data: feedBackInfo,
    headers: {
      Authorization: Authorization,
    },
  };
  return axios(getRuleExecutionInfo);
}
/**this function purpose is to get the rootcause  list based on rule executation */
export async function getRootCauseInfo(ruleData,) {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");

  let Authorization = "Bearer " + token;
  const getRootCauseInfo = {
    method: "POST",
    url: `/api/ruleEngine/getRootCauseData`,
    headers: {
      Authorization: Authorization,
    },
    data: {
      "DeviceId": ruleData.DeviceId,
      "StartDate": moment(ruleData.selectedDate.startDate).format('YYYY-MM-DD HH:mm'),
      "EndDate": moment(ruleData.selectedDate.endDate).format('YYYY-MM-DD HH:mm '),

      "ParameterCode": ruleData.ParameterCode,
      "WarningType": ruleData.WarningType,

      "SiteId": siteId

    }
  };
  return axios(getRootCauseInfo);
}