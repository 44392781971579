import React, { Fragment } from "react";
import {
  Table,
  Icon,
  Button,
  message,
  Input,
  Form,
  Checkbox
} from "antd";
import { DatePicker } from "antd4";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import moment from "moment";

import constants from "./constants";
import history from "../../commons/history";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../selectors/language";
import {
  getRuleExecutionInfo,
  saveFeedback,
  getfeedBackInfo,
  updateFeedBack,
} from "./Apicalls";
import { StyledDashboard, StyledTable, StyledModel, lightTheme } from "./style";
import 'antd4/lib/date-picker/style/css'
const { RangePicker } = DatePicker

class RulesFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      openModel: false,
      feedBackInfo: {},
      currentPage: 1,
      editFlag: false,
      selectedDate: {
        startDate: moment().subtract(1, "hour").format('YYYY-MM-DD 00:00'),
        endDate: moment().format('YYYY-MM-DD 23:59')
      }
    };
  }

  componentDidMount() {
    this.getRuleHistory([moment().format("YYYY-MM-DD 00:00"), moment().format("YYYY-MM-DD 23:59")])
  }
  getRuleHistory = async (date) => {
    try {
      let queryString = this.props.location.search;
      let ruleId = queryString.split("=")[1];
      if (ruleId) {
        let ruleStatuInfo = await getRuleExecutionInfo(ruleId, date);
        this.setState({ tableData: ruleStatuInfo.data });
      }
    } catch (error) {
      message.error(`${error.response.data.message}`);
    }
  }

  handleFeedbackEvents = (e, type) => {
    let { feedBackInfo } = this.state;
    if (type === "actions") {
      const { translationCache, language } = this.props;
      let translationAction;
      if (language !== undefined && language !== "") {
        if (language === "English") {
          translationAction = e;
        } else {
          let keys = Object.keys(translationCache);

          let arrConverter = e.map((action) => {
            let data = keys.filter((labels) => {
              return translationCache[labels] === action;
            });
            if (data[0] === undefined) {
              return [action];
            } else {
              return data;
            }
          });
          translationAction = arrConverter.map((indexedArry) => {
            return indexedArry[0];
          });
        }
      }
      feedBackInfo["actions"] = translationAction;
    } else {
      feedBackInfo["comments"] = e.target.value;
    }
    this.setState({ feedBackInfo });
  };

  handleModelCancel = () => {
    this.setState({ openModel: false });
  };

  handleFeedbacksave = async () => {
    const { feedBackInfo, editFlag } = this.state;
    try {
      if (!editFlag) {
        await saveFeedback(feedBackInfo);
        this.setState({ openModel: false });
      } else {
        await updateFeedBack(feedBackInfo);
        this.setState({ openModel: false, feedBackInfo: {} });
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  handlePagination = async (pageNumber) => {
    this.setState({ currentPage: pageNumber.current });
  };

  // onChangeDate = (date, dateString) => {
  //   this.getRuleHistory(dateString)
  //   this.setState({
  //     selectedDate:date
  //   })
  // }
  onChange = (value, dateString) => {
    this.getRuleHistory(value)
    this.setState({
      selectedDate: {
        startDate: moment(value[0]).format('YYYY-MM-DD HH:mm'),
        endDate: moment(value[1]).format('YYYY-MM-DD HH:mm'),

      }
    })
  }

  onOk = (value) => {

  }
  // disabledDate = (current) => {
  //   return current > moment().startOf('day').add(1, 'day');
  // }
  disabledDate = (current) => {
    return current > moment().endOf('day');
  }

  render() {
    const { selectedRecord, feedBackInfo, tableData, openModel, editFlag, selectedDate } = this.state;
    const { translationCache, language } = this.props;
    let translationAction;
    console.log("fi",feedBackInfo);
    if (selectedRecord) {
      if (language !== undefined && language !== "") {
        if (language === "English") {
          if (Array.isArray(selectedRecord.CommunicationType)) {
            translationAction = selectedRecord.CommunicationType;
          } else {
            translationAction = [selectedRecord.CommunicationType];
          }
        } else {
          let values = Array.isArray(selectedRecord.CommunicationType)
            ? selectedRecord.CommunicationType
            : [selectedRecord.CommunicationType];

          translationAction = values.map((action) => {
            let data =
              translationCache[action] !== undefined
                ? translationCache[action]
                : action;
            return data;
          });
        }
      }
    }

    const columns = [
      {
        title:
          translationCache &&
            translationCache[`${constants.sno}`] &&
            translationCache[`${constants.sno}`]
            ? translationCache[`${constants.sno}`]
            : `${constants.sno}`,
        dataIndex: "RuleId",
        render: (text, record, index) => {
          const { currentPage } = this.state;
          return (
            <span>
              {currentPage === 1
                ? currentPage * (index + 1)
                : (currentPage - 1) * 10 + (index + 1)}
            </span>
          );
        },
      },
      {
        title:
          translationCache &&
            translationCache[`${constants.tagName}`] &&
            translationCache[`${constants.tagName}`]
            ? translationCache[`${constants.tagName}`]
            : `${constants.tagName}`,
        dataIndex: "ParameterCode",
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },
      {
        title:
          translationCache &&
            translationCache[`${constants.tagValue}`] &&
            translationCache[`${constants.tagValue}`]
            ? translationCache[`${constants.tagValue}`]
            : `${constants.tagValue}`,
        dataIndex: "ParameterValue",
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },
      {
        title:
          translationCache &&
            translationCache[`${constants.communicationType}`] &&
            translationCache[`${constants.communicationType}`]
            ? translationCache[`${constants.communicationType}`]
            : `${constants.communicationType}`,
        dataIndex: "CommunicationType",
        key: "CommunicationType",
      },
      {
        title:
          translationCache &&
            translationCache[`${constants.exeTime}`] &&
            translationCache[`${constants.exeTime}`]
            ? translationCache[`${constants.exeTime}`]
            : `${constants.exeTime}`,
        dataIndex: "CommunicationTime",
        key: "CommunicationTime",
        render: (text, record) => (
          <span>
            {moment(text).format("YYYY-MM-DD HH:mm:ss")}
          </span>
        )
      },

      {
        title:
          translationCache &&
            translationCache[`${constants.fdBack}`] &&
            translationCache[`${constants.fdBack}`]
            ? translationCache[`${constants.fdBack}`]
            : `${constants.fdBack}`,
        key: "Feedback",
        render: (text, record) => (

          <span>
            <Icon
              type="edit"
              onClick={async () => {
                const { feedBackInfo } = this.state;
                feedBackInfo.timeStamp = record.CommunicationTime;
                feedBackInfo.TagId = record.TagId;
                feedBackInfo.Id = record.Id;
                feedBackInfo.actions = [];
                feedBackInfo["comments"] = record.comments ? record.comments : "";
                let editFlag = false;
                try {
                  let feedBackData = await getfeedBackInfo(record.Id);
                  if (feedBackData.data.length > 0) {
                    feedBackInfo.actions = feedBackData.data[0].Action;
                    feedBackInfo["comments"] = feedBackData.data[0].Comments;
                    editFlag = true;
                  }
                } catch (error) {
                  message.warn("Can't Fetch Feedback Info");
                }

                this.setState({
                  openModel: true,
                  selectedRecord: record,
                  feedBackInfo,
                  editFlag,
                });
              }}
            />
          </span>
        ),
      },
      // {
      //   title:
      //     translationCache &&
      //       translationCache[`${constants.rootCause}`] &&
      //       translationCache[`${constants.rootCause}`]
      //       ? translationCache[`${constants.rootCause}`]
      //       : `${constants.rootCause}`,
      //   key: "RootCause",
      //   render: (text, record) => (
      //     <span>
      //       <Icon
      //         type="cluster"
      //         style={{fontSize:"19px"}}
      //         onClick={async () => {
      //           const { feedBackInfo } = this.state;
      //           feedBackInfo.timeStamp = record.CommunicationTime;
      //           feedBackInfo.TagId = record.TagId;
      //           feedBackInfo.Id = record.Id;
      //           feedBackInfo.actions = [];
      //           feedBackInfo["comments"] = "";
      //           // let editFlag = false;
      //           this.props.history.push(`/rubus/RuleEngine/RootCause/?Device=${record.Id}&Parameter=${record.ParameterCode}&EndTime=${record.CommunicationTime}`)
      //           try {
      //             let feedBackData = await getfeedBackInfo(record.RuleId);
      //             if (feedBackData.data.length > 0) {
      //               feedBackInfo.actions = feedBackData.data[0].Action;
      //               feedBackInfo["comments"] = feedBackData.data[0].Comments;
      //               // editFlag = true;
      //             }
      //           } catch (error) {
      //             message.warn("Can't Fetch Feedback Info");
      //           }

      //           // this.setState({
      //           //   openModel: true,
      //           //   selectedRecord: record,
      //           //   feedBackInfo,
      //           //   editFlag,
      //           // });
      //         }}
      //       />
      //     </span>
      //   ),
      // },
    ];
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 10 },
    };

    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        <Fragment>
          <div>
            <Button type="primary" onClick={() => {
              history.goBack()
            }}><Icon type="left" />  {translationCache &&
              translationCache[`${constants.Go_Back}`] &&
              translationCache[`${constants.Go_Back}`]
              ? translationCache[`${constants.Go_Back}`]
              : `${constants.Go_Back}`} </Button>
            <div>
              {/* <DatePicker
                onChange={this.onChangeDate}
                style={{ width: "300px",float:"right" }}
                value={moment(this.state.selectedDate)}
                disabledDate={this.disabledDate}
                size="large" /> */}
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                style={{ width: "300px", float: "right" }}
                format="YYYY-MM-DD HH:mm"
                value={[moment(selectedDate.startDate), moment(selectedDate.endDate)]}
                onChange={this.onChange}
                disabledDate={this.disabledDate}
                onOk={this.onOk} />
            </div>


            <StyledTable theme={lightTheme}>
              <Table
                style={{ marginTop: "3%" }}
                dataSource={tableData}
                columns={columns}
                onChange={this.handlePagination}
              />
            </StyledTable>

            <StyledModel
              title="FeedBack"
              centered
              visible={openModel}
              footer={[
                <Button key="back" onClick={this.handleModelCancel}>
                  {translationCache &&
                    translationCache[`${constants.cancel}`] &&
                    translationCache[`${constants.cancel}`]
                    ? translationCache[`${constants.cancel}`]
                    : `${constants.cancel}`}
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={(e) => {
                    this.handleFeedbacksave();
                  }}
                  style={{ fontWeight: 700 }}
                >
                  {editFlag
                    ? translationCache &&
                      translationCache[`${constants.update}`] &&
                      translationCache[`${constants.update}`]
                      ? translationCache[`${constants.update}`]
                      : `${constants.update}`
                    : translationCache &&
                      translationCache[`${constants.save}`] &&
                      translationCache[`${constants.save}`]
                      ? translationCache[`${constants.save}`]
                      : `${constants.save}`}
                </Button>,
              ]}
              onCancel={this.handleModelCancel}
            >
              <Form {...formItemLayout}>
                <Form.Item
                  label={
                    translationCache &&
                      translationCache[`${constants.timeStamp}`] &&
                      translationCache[`${constants.timeStamp}`]
                      ? translationCache[`${constants.timeStamp}`]
                      : `${constants.timeStamp}`
                  }
                >
                  <Input
                    readOnly
                    value={
                      feedBackInfo
                        ? moment
                          .utc(feedBackInfo.timeStamp, "YYYY-MM-DD HH:mm:ss")
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss")
                        : null
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={
                    translationCache &&
                      translationCache[`${constants.tagName}`] &&
                      translationCache[`${constants.tagName}`]
                      ? translationCache[`${constants.tagName}`]
                      : `${constants.tagName}`
                  }
                >
                  <Input
                    readOnly
                    value={feedBackInfo ? feedBackInfo.TagId : null}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    translationCache &&
                      translationCache[`${constants.action}`] &&
                      translationCache[`${constants.action}`]
                      ? translationCache[`${constants.action}`]
                      : `${constants.action}`
                  }
                >
                  <Checkbox.Group
                    options={translationAction}
                    value={
                      feedBackInfo !== undefined
                        ? Array.isArray(feedBackInfo.actions)
                          ? feedBackInfo.actions
                          : [feedBackInfo.actions]
                        : null
                    }
                    onChange={(e) => {
                      this.handleFeedbackEvents(e, "actions");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    translationCache &&
                      translationCache[`${constants.comment}`] &&
                      translationCache[`${constants.comment}`]
                      ? translationCache[`${constants.comment}`]
                      : `${constants.comment}`
                  }
                >
                  <Input.TextArea
                    value={feedBackInfo.comments}
                    onChange={(e) => this.handleFeedbackEvents(e)}
                  />
                </Form.Item>
              </Form>
            </StyledModel>
          </div>
        </Fragment>
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
});

export default connect(mapStateToProps)(RulesFeedback);
