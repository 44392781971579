import React from "react";
import moment from "moment";
import axios from "axios";
import { Icon, Tooltip, Table } from "antd";
import localStorage from "../../utils/localStorage";


export default class TrendTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      language: props.language || "English",
      reportName: "",
      dataSource: []
    };
  }

  async componentDidMount() {
    this.prepareData();
  }

  getReportNametoDownload(excelData, type, excelDate, date1, date2) {
    let payload = {};
    if (type === "timeRange") {
      payload = {
        portName: "BPT Bmecomo",
        name: type && type ? type : "",
        // name: type && type === "live" ? "Live" : "Historic",
        consumption: "Trend",
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:mm:SS"),
        currentTime: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
        data: excelData || [],
        reportType: "singleHierarchy",
        
        excelDate: moment.utc(date1.fromDate1).local().format('YYYY-MM-DD HH:mm') + "~" +
          moment.utc(date1.toDate1).local().format('YYYY-MM-DD HH:mm'),


        // excelDate2: moment.utc(date2.fromDate2).local().format('YYYY-MM-DD HH:mm') + "~" +
        //   moment.utc(date2.toDate2).local().format('YYYY-MM-DD HH:mm'),
        selectionType: "dateRange",
      }
    }
    else{
       payload = {
        portName: "BPT Bmecomo",
        name: type && type ? type : "",
        // name: type && type === "live" ? "Live" : "Historic",
        consumption: "Trend",
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:mm:SS"),
        currentTime: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
        data: excelData || [],
        reportType: "singleHierarchy",
        excelDate:
          moment.utc(excelDate.fromDate).local().format('YYYY-MM-DD HH:mm') + "~" +
          moment.utc(excelDate.toDate).local().format('YYYY-MM-DD HH:mm'),
        selectionType: "dateRange",
      };

    }
   

    let accessToken = localStorage.get("accessToken");
    let options = {};
    options = {
      method: "POST",
      url: `/api/report/generateExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify(payload),
    };
    return axios(options)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return "";
      });
  }

  prepareData = async () => {
    const { panelData } = this.props;
    console.log("pd2", panelData);
    let newList = [];
    let dataSource = [];
    newList =
      panelData &&
      panelData.trendData &&
      panelData.trendData.map((columns, index) => {
        return {
          "S.No": index + 1,
          "Timestamp": moment.utc(columns.interval_Alias, "DD-MM-YYYY HH:mm:ss").local().format('YYYY-MM-DD HH:mm'),
          "Device Name": columns.deviceName,
          "Parameter Name": columns.parameterName,
          "Parameter Value": columns.tagValue,
        };
      });

    dataSource =
      panelData &&
      panelData.trendData &&
      panelData.trendData.map((columns, index) => {
        return {
          "interval_Alias": moment.utc(columns.interval_Alias, "DD-MM-YYYY HH:mm:ss").local().format('YYYY-MM-DD HH:mm'),
          "deviceName": columns.deviceName,
          "parameterName": columns.parameterName,
          "tagValue": columns.tagValue,
        }
      });
    this.setState({
      dataSource,
    });
    console.log("123panel", panelData);
    let { fromDate, toDate } = panelData.tunedData
    let { fromDate1, toDate1 } = panelData.tunedData
    let { fromDate2, toDate2 } = panelData.tunedData

    let reportName = await this.getReportNametoDownload(
      newList,
      panelData.type,
      { fromDate, toDate },
      {
        fromDate1, toDate1
      },
      {
        fromDate2, toDate2
      }

    );

    this.setState({
      reportName: reportName && reportName.file ? reportName.file : "",
    });
  };

  render() {
    let { reportName, dataSource } = this.state;
    console.log("props123", this.props);
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Tooltip placement="top" title={"Download"}>
            <a href={`https://bpt.bmecomosolution.com/api/reports/${reportName}`}>
              {" "}
              <Icon type="download" style={{ fontSize: "25px" }} />
            </a>
          </Tooltip>{" "}
        </div>

        <Table
          className="trendTable"
          dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
          columns={this.props.columns}
          pagination={false}
          bordered={true}
          style={{ padding: '0px 10px', marginTop: '12px' }}
          scroll={{ x: 140, y: 250 }}
        />
      </div>
    );
  }
}
