import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import DeviceCondition from './DeviceCondition'
import { getConditionListSelector } from '../../../../selectors/condition'

const mapStateToProps = createStructuredSelector({
  conditionList: getConditionListSelector()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(
      {

      },
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceCondition)
