import React from "react";
import cloneDeep from "lodash.clonedeep";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import moment from "moment";
import { Tag } from "antd";
import ReactEcharts from "../Echarts/Echarts";
import { trendLabelTranslation } from "../../Utils/Adapter/translationAdapter";
import { getChartDataWithoutSelection, getChartDataWithSelection } from "../ChartCalls";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { pieBarMockJson } from "../../Mock/MockJson";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { defaultRefreshTime } from '../constant.json'
import { Fragment } from "react";

class PieBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // option: this.getData(props),
      option: this.getOption(props),
      data: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
    this.pieName = "";
  }

  data = [];
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }
    // componentWillReceiveProps(props) {
    //     this.getData(props);
    // }


    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange
    ) {
      this.getData(this.props, this.props.selectedThemeing);
    }
  }
  // componentWillReceiveProps(props) {
  //     this.getData(props);
  // }

  onChartClick = (cellData) => {
    if (cellData.componentIndex === 2) {
      this.pieName = cellData.name;
      this.getData(this.props);
    }
  };

  componentWillUnmount() {
    this.abortController.abort()
    clearInterval(this.timeTicket);
  }

  render() {

    let onEvents = {
      click: (cellData) => this.onChartClick(cellData),
    };
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <ReactEcharts
              option={this.state.option}
              notMerge={true}
              lazyUpdate={true}
              onEvents={onEvents}
              style={{ height: "90%", width: "100%" }}
              className="react_for_echarts"
            />
        }
      </Fragment>

    );
  }

  getData = async (props, theme) => {
    this.requestCheck = false
    let json = [];
    let { graphprops } = this.props;

    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = pieBarMockJson;
      this.requestCheck = true
    } else {
      if (
        (graphprops &&
          graphprops.dashboardType === "dynamic" &&
          this.props.deviceId) ||
        (graphprops &&
          graphprops.dateExists === "true" &&
          this.props.selectionDate) ||
        (graphprops &&
          graphprops.monthExists === "true" &&
          this.props.selectionMonth) ||
        (graphprops &&
          graphprops.weekExists === "true" &&
          this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
        (graphprops &&
          graphprops.dateRangeExists === "true" &&
          this.props.selectionDateRange)
        ||
        (graphprops && graphprops.dayMonthYearExists === 'true' && this.props.dayMonthYear)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController
        );
        this.requestCheck = true
      } else if (graphprops) {
        json = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true
      }
    }

    if ((json && json.message) || (Array.isArray(json) && (json.length === 0 &&
      graphprops.enableMock !== true))) {

      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
      }

    } else {

      var bar = json && json.bar;
      var pie = json && json.pie;
      //  bar = {
      //      "Site_1": {
      //          "frontend": 3237,
      //          "backend": 2000,
      //          "database": 3000,
      //          "Implementation": 4000,
      //          "frontend": 5000,
      //          "backend": 6000,
      //          "database": 2364,
      //          "Implementation": 1234
      //      },
      //      "Site_2": {
      //          "frontend": 3237,
      //          "backend": 2164,
      //          "database": 2364,
      //          "Implementation": 1234,
      //          "frontend": 3237,
      //          "backend": 2164,
      //          "database": 2364,
      //          "Implementation": 1234
      //      },
      //      "Site_3": {
      //          "frontend": 3237,
      //          "backend": 2164,
      //          "database": 2364,
      //          "Implementation": 1234,
      //          "frontend": 3237,
      //          "backend": 2164,
      //          "database": 2364,
      //          "Implementation": 1234
      //      },
      //      "Site_4": {
      //          "frontend": 3237,
      //          "backend": 2164,
      //          "database": 2364,
      //          "Implementation": 1234,
      //          "frontend": 3237,
      //          "backend": 2164,
      //          "database": 2364,
      //          "Implementation": 1234
      //      }
      //  };

      //  pie = {
      //      "Site_1": 17365,
      //      "Site_2": 4079,
      //      "Site_3": 6929,
      //      "Site_4": 14890
      //  };

      let barObject = {};



      if (this.pieName) {
        barObject = bar[this.pieName];
      } else if (pie) {
        barObject = bar[Object.keys(pie)[0]];
      }

      let yAxis = [
        {
          type: "category",
          data: Object.keys(barObject),
          // axisLabel: {
          //   interval: 0,
          //   rotate: 30,
          // },
          axisLine: {
            lineStyle: {
              color: theme === "lightTheme" ? "black" : "white",
            },
          },
          axisTick: {
            lineStyle: {
              color: theme === "lightTheme" ? "black" : "white",
            },
          },
          axisLabel: {
            color: theme === "lightTheme" ? "black" : "white",
            interval: 0,
            rotate: 30,
          },
        },
      ];

      let series = [
        {
          type: "bar",
          stack: "chart",
          z: 3,
          label: {
            normal: {
              position: "right",
              show: true,
            },
          },
          data: Object.keys(barObject).map(function (key) {
            return barObject[key];
          }),
        },
        {
          type: "bar",
          stack: "chart",
          silent: true,
          itemStyle: {
            normal: {
              color: "#eee",
            },
          },
          data: Object.keys(barObject).map(function (key) {
            return barObject.al - barObject[key];
          }),
        },
        {
          type: "pie",
          radius: [0, "30%"],
          center: ["75%", "25%"],
          data: pie && Object.keys(pie).map(function (key) {
            return {
              name: key,
              value: pie[key],
            };
          }),
        },
      ];

      series = await trendLabelTranslation(
        series,
        this.props.translationCache,
        this.props.language
      );
      const option = cloneDeep(this.getOption());
      option.series = series;
      option.yAxis = yAxis;
      this.setState({
        option: option,
        refreshDateTime: moment()
      });
    };
    this.setState({
      errorInAPI,
      noDataInDB
    })
  }

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      // backgroundColor: {
      //     type: 'pattern',
      //     image: canvas,
      //     repeat: 'repeat'
      // },
      tooltip: {},
      // title: [{
      //     text: 'BarTitle',
      //     left: '25%',
      //     textAlign: 'center'
      // }, {
      //     text: 'PieTitle',
      //     left: '75%',
      //     textAlign: 'center'
      // }],
      grid: [
        {
          top: 50,
          width: "50%",
          bottom: "45%",
          left: 10,
          containLabel: true,
        },
        {
          top: "55%",
          width: "50%",
          bottom: 0,
          left: 10,
          containLabel: false,
        },
      ],
      xAxis: [
        {
          type: "value",
          // max: builderJson.all,
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: theme === "lightTheme" ? "black" : "white",
            },
          },
          axisTick: {
            lineStyle: {
              color: theme === "lightTheme" ? "black" : "white",
            },
          },
          axisLabel: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
      ],
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(PieBar);
