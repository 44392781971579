import React from "react";
import moment from "moment";
import { Tag } from "antd";
import ReactEcharts from "../Echarts/Echarts";
import cloneDeep from "lodash.clonedeep";
import random from "lodash.random";

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {
  getCurrentLanguage,
  getTranslationCache
} from "../../../../selectors/language";
import { defaultRefreshTime } from '../constant.json'
import { Fragment } from "react";

class MultiLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      option: this.getOption(),
      data: [],
      refreshDateTime: moment(),

    };
  }
  data = [];
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  // componentDidMount() {
  //   if (this.timeTicket) {
  //     clearInterval(this.timeTicket);
  //   }
  //   this.updateData();
  //   this.timeTicket = setInterval(() => {
  //     this.updateData();
  //   }, this.props.graphprops.refreshTime * 1000);
  // }
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    // this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.updateData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  render() {
    const { refreshDateTime } = this.state;
    let { graphprops } = this.props
    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
        <ReactEcharts
          option={this.state.option}
          style={{ height: "90%", width: "100%" }}
          className="react_for_echarts"
        />

      </Fragment>
    );
  }

  updateData = () => {
    // let theme = this.props.selectedThemeing;
    let legendsdata = [];

    let tagsdata = [
      {
        name: "Air Flow Coil ",
        data: [
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
        ],
      },
      {
        name: "Outside Air humidity",
        data: [
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
          random(10, 100),
        ],
      },
    ];

    tagsdata.map((val, i) => {
      val.color = this.props.graphprops.lineColor[i];
      val.type = "line";
      val.lineStyle = {
        color: this.props.graphprops.lineColor[i],
      };


      legendsdata.push({
        name: val.name,

        icon: "roundRect",

        textStyle: {
          fontSize: 18,
          fontWeight: "bold",
        },
      });
    });

    const option = cloneDeep(this.state.option);
    option.series = [...tagsdata];

    option.legend.data = [...legendsdata];

    this.setState({
      option,
      refreshDateTime: moment()
    });
  };
  getOption = () => {
    let theme = this.props.selectedThemeing;
    // let option = (option = {
    let option = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        textStyle: { color: theme === "lightTheme" ? "black" : "white" },
        data: [],
      },
      xAxis: {
        type: "category",
        data: this.props.graphprops.xaxisData,
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisTick: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisLabel: {
          color: theme === "lightTheme" ? "black" : "white",
        },
      },
      yAxis: {
        type: "value",
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
      },
      series: [],
    };
    // )
    return option;
  };
}
const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),

});

export default connect(mapStateToProps)(MultiLine);


