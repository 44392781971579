import React from 'react'
import axios from 'axios'
import { Form, Input, Button, message } from 'antd'
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getCurrentLanguage, getTranslationCache } from "../../../selectors/language";
import { constant } from "../Constants";
import localStorage from '../../../utils/localStorage'
const FormItem = Form.Item

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
}

class EditProfileComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userProfile: props.userProfile,
      translationCache: props.translationCache || [],
      language: props.language || "English",
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    let { userProfile } = this.props
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (userProfile && userProfile.user) {
          let newObj = {
            ...userProfile.user,
            ...values
          }
          this._updateProfile(newObj)
        }
      }
    })
  }

  _updateProfile = (profile) => {
    const accessToken = localStorage.get('accessToken')
    const changePassword = {
      method: 'POST',
      url: `/api/user/update`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: profile
    }
    axios(changePassword)
      .then(() => {
        message.success('Profile updated')
        this.props.actions.getLoggedInProfile()
      })
      .catch(function () {
        message.error('Error')
      })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ userProfile: nextProps.userProfile })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache || prevProps.language !== this.props.language) {
      this.setState({
        translationCache: this.props.translationCache, language: this.props.language
      });
    }
  }

  translation = (keyword) => {
    let { translationCache } = this.props
    return translationCache &&
      translationCache[keyword] ?
      translationCache[keyword] : keyword
  }


  render() {
    const { getFieldDecorator } = this.props.form
    const { userProfile } = this.state
    return (
      <Form style={{ marginRight: '180px' }} {...formItemLayout} onSubmit={this.handleSubmit}>
        <Form.Item label={this.translation(constant.First_Name)}>
          {getFieldDecorator('FirstName', {
            rules: [{ required: true, message: 'Please Enter First Name' }],
            initialValue: userProfile['user'] ? userProfile['user'].FirstName : ''
          })(
            <Input placeholder="First name" />
          )}
        </Form.Item>
        <Form.Item label={this.translation(constant.Last_Name)}>
          {getFieldDecorator('LastName', {
            rules: [{ required: true, message: 'Please Enter Last Name' }],
            initialValue: userProfile['user'] ? userProfile['user'].LastName : ''
          })(
            <Input placeholder="Last name" />
          )}
        </Form.Item>
        <FormItem label={this.translation(constant.Email)}>
          {getFieldDecorator('Email', {
            rules: [{ required: true, message: 'Please Enter Email' }],
            initialValue: userProfile['user'] ? userProfile['user'].Email : ''
          })(
            <Input placeholder="Email Id" disabled />
          )}
        </FormItem>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {this.translation(constant.Update_Profile)}
          </Button>
        </Form.Item>
      </Form>

    )
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),

});
export default connect(mapStateToProps)(Form.create()(EditProfileComponent));

