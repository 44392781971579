import React from 'react'
import { withRouter } from 'react-router'
import { Menu, Icon } from 'antd'
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import SettingsRouter from '../../routes/settingsRouter'
import styles from '../../commons/styles'
import history from '../../commons/history'
import { getCurrentLanguage, getTranslationCache } from "../../selectors/language";
import { getMenuHierarchy } from '../../selectors/layout'

import './style.css'
import { constant } from "./Constants"

const { SubMenu } = Menu;
const { StyledWrapper, StyledWrapperLeft, StyledWrapperRight } = styles.layout


class SettingsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      tabPosition: 'left',
      translationCache: props.translationCache || [],
      language: props.language || "English",
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache || prevProps.language !== this.props.language) {
      this.setState({
        translationCache: this.props.translationCache, language: this.props.language
      });
    }
    if (prevProps.menuHierarchy !== this.props.menuHierarchy && this.props.menuHierarchy['ApplicationSettings'] &&
      this.props.menuHierarchy['ApplicationSettings'].children) {
      this.setState({
        menu: Object.keys(this.props.menuHierarchy['ApplicationSettings'].children).map(i => this.props.menuHierarchy['ApplicationSettings'].children[i])
      })
    }
  }

  componentDidMount() {
    if (this.props.menuHierarchy && this.props.menuHierarchy['ApplicationSettings']) {
      this.setState({
        menu: Object.keys(this.props.menuHierarchy['ApplicationSettings'].children).map(i => this.props.menuHierarchy['ApplicationSettings'].children[i])
      })
    }
  }

  translation = (keyword) => {
    let { translationCache } = this.props
    return translationCache &&
      translationCache[keyword] ?
      translationCache[keyword] : keyword
  }

  getSubMenuOrItem = (item) => {
    // const { translationCache, language } = this.state;
    if (item && item.children) {
      if (!item.children) {
        return [];
      }
      if (item.children) {
        return <SubMenu
          key="settings"
          title={
            <span>
              <Icon type='user' />
              <span>  {this.translation(constant.User_Management)}
              </span>
            </span>
          }
        >
          {
            item.children
              ?
              Object.keys(item.children).map((itemName) => item["children"][itemName])
                .filter(item => item && item.name && !item.hideInMenu)
                .map(item => {
                  return this.getSubMenuOrItem(item);
                })
                .filter(item => item)
              : null
          }
        </SubMenu>
      }
    }
    document.title = "Application Settings"
    return (
      <Menu.Item
        key={item.key}
        className="customclass"
        onClick={() => {
          history.push(item.path);
        }}
      >
        {item.icon ? <Icon type={item.icon} /> : null}
        <span className="nav-text">
          {this.translation(item.name)}</span>
      </Menu.Item>
    );
  };

  getNavMenuItems = (menusData) => {
    if (!menusData) {
      return [];
    }
    if (Array.isArray(menusData)) {
      return menusData
        .filter(item => item && item.name && !item.hideInMenu)
        .map(item => {
          return this.getSubMenuOrItem(item);
        })
        .filter(item => item);
    }
  };


  render() {
    let { menu } = this.state
    return (
      <StyledWrapper style={{ minHeight: window.innerHeight - 140 }}>
        <StyledWrapperLeft >
          <Menu
            mode='inline'
          >
            {
              menu && Array.isArray(menu)
                ? this.getNavMenuItems(menu)
                : null}
          </Menu>
        </StyledWrapperLeft >
        <StyledWrapperRight style={{ background: 'white', margin: '1px 15px 13px 15px' }} >
          <SettingsRouter />
        </StyledWrapperRight >
      </StyledWrapper>
    )
  }
}
const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  menuHierarchy: getMenuHierarchy()
});

export default connect(mapStateToProps)(withRouter(SettingsPage));

