import styled from 'styled-components'
import { Menu, Layout } from 'antd'

const { Sider } = Layout

export const StyledWrapper = styled.div`
display:flex;
justify-content:space-between;
flex-direction:row;
`
export const StyledFloatLeft = styled.div`
float:left
`
export const StyledFloatRight = styled.div`
float:right
`
export const StyledWrapperRight = styled.div`
margin-left:2%;
width:100%;
padding:50px;
`
export const StyledWrapperLeft = styled.div`
color: #595959;
width:25%
`
export const StyledMenu = styled(Menu)`
background-color:${(props) => props.theme.primaryColor};
border-right:0;
color:${(props) => props.theme.color};
`
export const StyledSider = styled(Sider)`
background-color:${(props) => props.theme.primaryColor};
color:${(props) => props.theme.color};
`

export const StyledHeading = styled.h2`
font-size:26
`
