import React, { Component } from "react";
import { Radio, Select } from "antd";
import axios from "axios";

import localStorage from "../../../utils/localStorage";
import ReportTypeComponent from "./ReportTypeComponent";
// import { getDatasourceData, getreportTypeData } from '../eLogCalls'
import { getReportTypeList } from "./reportCalls";
import { StyledDashboard } from "./styles";

const { Option } = Select;
const radioOptions = ["Generate JSON", "Edit Report JSON"];

class ReportTypeConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "Generate JSON",
      reportTypeList: [],

      reportList: [],
      reportObject: {},
      reportType: "",

      reportName: "",

      editObj: {},
      filteredObject: {},

      editList: [],
      tabName: "",
    };
  }

  componentDidMount() {
    this._getReportList();
    this._getReportType();
  }
  _getReportList = () => {
    const siteId = localStorage.get("currentSite");
    let accessToken = localStorage.get("accessToken");
    const healthHeaders = {
      method: "GET",
      url: `/api/site/${siteId}/report/reportsList`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios(healthHeaders).then((response) => {
      if (Array.isArray(response.data)) {
        this.setState({ reportList: response.data });
      }
    });
  };
  _getReportType = async () => {
    let reportTypeList = await getReportTypeList();
    this.setState({ reportTypeList });
  };
  onChangeRadio = (e) => {
    this.setState({
      currentTab: e.target.value,
      editObj: {},
    });
  };

  handleChange = (value) => {
    let { reportList, editList } = this.state;

    let filteredObject = reportList.filter((key) => key.ReportName === value);

    this._getReportJsonList(filteredObject[0]);
    this.setState({ reportObject: filteredObject[0] });

    this.setState({
      reportName: value,
      reportType:
        editList && filteredObject[0] && filteredObject[0].ReportType
          ? filteredObject[0].ReportType
          : "",
      // reportName:
      //   editList && filteredObject[0] && filteredObject[0].ReportName
      //     ? filteredObject[0].ReportName
      //     : "",
    });
  };

  _getReportJsonList = (payload) => {
    const siteId = localStorage.get("currentSite");
    const accessToken = localStorage.get("accessToken");
    const deviceTypeObject = {
      method: "POST",
      url: `/api/site/${siteId}/report/reportData`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: payload,
    };
    axios(deviceTypeObject)
      .then((response) => {
        let editList = response.data.map((edit) => {
          let newObj = {
            ...edit,
            editEditor: edit.JSON,
          };
          return newObj;
        });

        this.setState({
          editList,
          editObj:
            editList && editList[0] && editList[0].JSON && editList[0].JSON
              ? editList[0].JSON
              : {},

          tabName: editList && editList[0].TabName,
        });
      })
      .catch(function () { });
  };

  handleReportTypeChange = (reportType) => {
    this.setState({
      reportType,
      editObj: {},
    });
  };

  render() {
    let {
      currentTab,
      reportList,
      editObj,
      reportType,
      reportTypeList,
      reportObject,
      reportName,
      tabName,
    } = this.state;

    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        <div style={{ marginBottom: "20px" }}>
          <Radio.Group
            options={radioOptions}
            onChange={this.onChangeRadio}
            value={currentTab}
          />
        </div>

        {currentTab && currentTab === "Generate JSON" ? (
          <div>
            <Select
              placeholder={"Please select reportType"}
              style={{ width: 250 }}
              onChange={this.handleReportTypeChange}
            >
              {reportTypeList &&
                reportTypeList.map((reportType) => {
                  return (
                    <Option value={reportType.ReportType}>
                      {reportType.ReportType}
                    </Option>
                  );
                })}
            </Select>
            {reportType === "Report" ? (
              <ReportTypeComponent
                editObj={editObj ? editObj : {}}
                reportType={reportType}
                currentTab={currentTab}
                reportObject={reportObject}
              />
            ) : null}
          </div>
        ) : null}
        {currentTab && currentTab === "Edit Report JSON" ? (
          <div>
            <Select
              placeholder={"Please select report"}
              style={{ width: 250 }}
              onChange={this.handleChange}
            >
              {reportList &&
                reportList.map((report) => {
                  if (report.ReportType === "Report") {
                    return (
                      <Option value={report.ReportName}>
                        {report.ReportName}
                      </Option>
                    );
                  }
                })}
            </Select>
            {reportName !== "" ? (
              <ReportTypeComponent
                editObj={editObj ? editObj : {}}
                reportType={reportType}
                reportName={reportName}
                currentTab={currentTab}
                reportObject={reportObject}
                updatefunction={this._getReportJsonList}
                tabName={tabName}
              />
            ) : null}
          </div>
        ) : null}
      </StyledDashboard>
    );
  }
}

export default ReportTypeConfiguration;
