import { fromJS } from "immutable";

import { types } from "./types";

export const getAssetDeviceCache = () => {
  return {
    type: types.ASSET_CACHE
  };
};

export const createAsset = asset => {
  return {
    type: types.CREATE_ASSET,
    asset
  };
};

export const updateAsset = asset => {
  return {
    type: types.UPDATE_ASSET,
    asset
  };
};

export const deleteAsset = asset => {
  return {
    type: types.DELETE_ASSET,
    asset
  };
};

export const getAssetCacheSuccess = response => {
  return {
    type: types.ASSET_CACHE_SUCCESS,
    response
  };
};

export const getDeviceCacheSuccess = response => {
  return {
    type: types.DEVICE_CACHE_SUCCESS,
    response
  };
};

export const setCurrentLanguage = payload => {
  return {
    type: types.SET_CURRENTLANGUAGE,
    payload
  };
};

export const setBreadcrumbs = (breadCrumbList) => {
  return {
    type: types.SET_BREADCRUMB_LIST,
    breadCrumbList
  };
}

export const getAssetDeviceList = (
  assetNameList,
  checkObject,
  assetId,
  asset,
  siteDirection
) => {
  return {
    type: types.ASSET_DEVICE_LIST,
    assetNameList,
    checkObject,
    assetId,
    asset,
    siteDirection
  };
};

export const currentAsset = asset => {
  return {
    type: types.Current_ASSET,
    asset
  };
};

export const breadcrumbList = (breadcrumbList) => {
  return {
    type: types.BREADCRUMB_LIST,
    breadcrumbList
  };
}

const initialState = fromJS({
  breadcrumbList: [],
  assetCache: {},
  deviceCache: {},
  assetNameList: [],
  assetId: "",
  asset: {},
  checkObject: [],
  currentSite: "",
  currentLanguage: "",
  currentAssetType: null
});

const asset = (state = initialState, action) => {
  let newObj = []
  switch (action.type) {
    case types.ASSET_CACHE_SUCCESS: {
      return state.set("assetCache", action.response);
    }
    case types.DEVICE_CACHE_SUCCESS: {
      return state.set("deviceCache", action.response);
    }
    case types.ASSET_DEVICE_LIST: {
      return state
        .set("assetNameList", action.assetNameList)
        .set("assetId", action.assetId)
        .set("asset", action.asset)
        .set("siteDirection", action.siteDirection)
        .set("checkObject", action.checkObject);
    }
    case types.SET_CURRENTLANGUAGE: {
      return state.set("currentLanguage", action.payload);
    }
    case types.Current_ASSET: {
      return state.set("currentAsset", action.asset);
    }
    case types.SET_BREADCRUMB_LIST: {
      let oldBreadcrumbList = state.get('breadcrumbList')
      if (Array.isArray(oldBreadcrumbList)) {
        newObj = oldBreadcrumbList
        newObj.push(action.breadCrumbList)
      } else {
        newObj = [action.breadCrumbList]
      }
      return state.set("breadcrumbList", newObj);
    } case types.BREADCRUMB_LIST: {
      return state.set("breadcrumbList", action.breadcrumbList);
    }
    default:
      return state;
  }
};

export default asset;
