import React from "react";
import moment from "moment";
import { Spin } from "antd";
import { Tag } from "antd";
import cloneDeep from "lodash.clonedeep";
import axios from "axios";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import ReactEcharts from "../Echarts/Echarts";
import { getChartDataWithoutSelection, getChartDataWithSelection } from "../ChartCalls";
import { trendLabelTranslation } from "../../Utils/Adapter/translationAdapter";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import localStorage from "../../../../utils/localStorage";
import { sliderstatus } from "../../Mock/MockJson";
import { makeSelectThemeing } from "../../../../selectors/theme";
import { defaultRefreshTime } from '../constant.json'
import { Fragment } from "react";

class SliderStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      loading: false,
      colors: this.props.graphprops.colors,
      Linecolor: this.props.graphprops.Linecolor,
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      reportName: "",
      errorInAPI: false,
      noDataInDB: false
    };
  }

  data = [];
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection
    ) {
      this.getData(this.props);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  render() {
    let theme = this.props.selectedThemeing;
    let { graphprops } = this.props
    let { loading, errorInAPI, noDataInDB, refreshDateTime } = this.state;
    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }

        {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
              <NoRecordComponent />
              : <ErrorComponent />
            :
            <span>
              {loading === false ? (
                <ReactEcharts
                  option={this.state.option}
                  notMerge={true}
                  lazyUpdate={true}
                  style={{ height: "80%", width: "100%" }}
                  className="react_for_echarts"
                />
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    color: theme === "lightTheme" ? "black" : "white",
                    marginTop: "10%",
                  }}
                >
                  <h2 style={{ color: "black" }}>
                    <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                  </h2>
                </div>
              )}
            </span>
        }
      </Fragment>


    );
  }

  getReportNametoDownload(excelData) {
    let payload = {
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm:SS"),
      data: excelData || [],
      ...this.props.graphprops.excelObj,
    };

    let accessToken = localStorage.get("accessToken");
    // const siteId = localStorage.get("currentSite");
    let options = {
      method: "POST",
      url: `/api/report/generateExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify(payload),
    };
    return axios(options)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return "";
      });
  }

  getData = async (props) => {
    let theme = this.props.selectedThemeing;
    this.requestCheck = false
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false
    let json = [];
    let { graphprops } = this.props;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = sliderstatus;
      this.requestCheck = true
    } else {

      if (
        props.graphprops.graph === "rangeBar" &&
        props.graphprops.currentDateData !== undefined
      ) {
        json = props.graphprops.currentDateData;
      } else {
        if (
          ((graphprops &&
            graphprops.dashboardType === "dynamic") ||
            (graphprops.dashboardType === "dropdownHide" &&
              this.props.deviceId)) ||
          (graphprops &&
            graphprops.dateExists === "true" &&
            this.props.selectionDate) ||
          (graphprops &&
            graphprops.monthExists === "true" &&
            this.props.selectionMonth) ||
          (graphprops &&
            graphprops.weekExists === "true" &&
            this.props.selectionWeek) ||
          (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
          (graphprops &&
            graphprops.dateRangeExists === "true" &&
            this.props.selectionDateRange) ||
          (graphprops &&
            graphprops.dayMonthYearExists === "true" &&
            this.props.dayMonthYear)
        ) {
          json = await getChartDataWithSelection(
            graphprops,
            this.props.deviceId,
            this.props.selectionDate,
            this.props.selectionMonth,
            this.props.selectionWeek,
            this.props.shift,
            this.props.selectionDateRange,
            this.props.dayMonthYear,
            this.abortController,
            this.props.dropdownSelection
          );
          this.requestCheck = true
        } else {
          json = await getChartDataWithoutSelection(graphprops, this.abortController, this.props.dropdownSelection);
          this.requestCheck = true
        }
      }
    }

    if ((json && json.message) || (Array.isArray(json) && (json.length === 0) &&
      (graphprops.enableMock !== true))) {

      if (json.message) {
        errorInAPI = true
      } else if (json.length === 0) {
        noDataInDB = true
      }

    } else {

      let visualMap = {}
      let dates = [];
      let values = [];
      let name = "";
      let tagnames = [];
      let seriesarray = [];
      let legendsdata = [];
      json && Array.isArray(json) && json.map((val, index) => {
        if (val.tag_description !== name) {
          if (tagnames.indexOf(val.tag_description) === -1) {
            tagnames.push(val.tag_description);
            name = val.tag_description;
            legendsdata.push({
              name: val.tag_description,
              icon: "roundRect",
              textStyle: {
                color: theme === "lightTheme" ? "black" : "white",
                fontSize: 16,
                fontWeight: "bold"
              },
            });
          }
        }
      });

      let yAxisData = [];

      if (Array.isArray(this.props.graphprops.YAxis)) {
        yAxisData = [...this.props.graphprops.YAxis];
      }

      let yaxis = yAxisData.map((value, index) => {
        let axis = {};
        axis.splitLine = {
          show: true,
          lineStyle: {
            color: "#504c4c",
          },
          axisLabel: { fontSize: 15 },
        };
        axis.triggerEvent = true;
        axis.axisLine = {
          lineStyle: {
            // color: "white"
            color:
              this.props.graphprops.colors[index] !== undefined
                ? value && value.tags && value.tags.length > 1
                  ? "white"
                  : this.props.graphprops.colors[index]
                : "white",
          },
          axisLabel: { fontSize: 12 },
        };
        axis.max = value.max;
        axis.min = value.min;
        axis.offset = value.offset;
        axis.interval = value.interval;
        axis.position = value.position;
        axis.name = value.name;
        axis.tags = value.tags;
        return axis;
      });

      yaxis.unshift({
        splitLine: {
          show: true,
          lineStyle: {
            color: "#504c4c",
          },
        },
        triggerEvent: true,
        axisLine: {
          lineStyle: {
            color: theme === "lightTheme" ? "black" : "white",
          },
        },
        axisLabel: { fontSize: 15 },
        tags: [],
      });

      tagnames.map((tagval, tagindex) => {
        values = [];
        let array = [{
          lt: 0,
          color: "Green"
        }]
        let linetags = props.graphprops ? props.graphprops.tags : [];
        let tagdescription = "";
        json.map((jsonval, jsonindex) => {
          if (tagnames[0] === jsonval.tag_description && tagindex === 0) {
            let date = graphprops && graphprops.type === "live" ? moment.utc(jsonval.interval_alias).local().format("DD/MM/YYYY HH:mm:ss") : moment(jsonval.interval_alias).format("DD/MM/YYYY HH:mm:ss")
            dates.push(date);
          }
          if (tagval === jsonval.tag_description) {
            values.push(jsonval.tagvalue);
          }
          tagdescription = jsonval.tag_description;

          if (jsonval.tag_description === linetags[0]) {
            if (jsonval.gt && jsonval.lte && jsonval.color !== undefined)
            array.push({
              gt: jsonval.gt,
              lte: jsonval.lte,
              color: jsonval.color
            })
            visualMap = {
              show: false,
              dimension: 0,
              seriesIndex: 0,
              pieces: array,
              textStyle: {
                color: "white"
              }
            }
          }



        });
        if (this.props.graphprops.linetype === "area") {
          if (yaxis.length > 1) {
            yaxis.map((axis, axisindex) => {
              if (axis.tags.indexOf(tagval) !== -1) {
                let linetags = props.graphprops ? props.graphprops.tags : [];
                if (linetags && linetags.length > 0) {
                  linetags &&
                    linetags.map((tag) => {
                      seriesarray.push({

                        type: legendsdata[tagindex]["name"] !== tag ? "bar" : "line",
                        name: legendsdata[tagindex]["name"],
                        data: values,
                        axisLabel: { fontSize: 15 },
                        yAxisIndex: axisindex,
                        areaStyle: {},
                        smooth: true,
                      });
                    });
                } else {
                  seriesarray.push({

                    type: "bar",
                    name: legendsdata[tagindex]["name"],
                    data: values,
                    axisLabel: { fontSize: 15 },
                    yAxisIndex: axisindex,
                    areaStyle: {},
                    smooth: true,
                  });
                }

              }
            });
          } else {
            let linetags = props.graphprops ? props.graphprops.tags : [];
            if (linetags && linetags.length > 0) {
              linetags &&
                linetags.map((tag) => {
                  seriesarray.push({
                    name: legendsdata[tagindex]["name"],
                    data: values,
                    type: legendsdata[tagindex]["name"] !== tag ? "bar" : "bar",
                    axisLabel: { fontSize: 15 },
                    areaStyle: {},
                    smooth: true,
                  });
                })
            } else {
              seriesarray.push({
                name: legendsdata[tagindex]["name"],
                data: values,
                type: "bar",
                axisLabel: { fontSize: 15 },
                areaStyle: {},
                smooth: true,
              });
            }
          }
        } else {
          if (yaxis.length > 1) {
            yaxis.map((axis, axisindex) => {
              if (axis && axis.tags && axis.tags.indexOf(tagval) !== -1) {
                let linetags = props.graphprops ? props.graphprops.tags : [];
                if (linetags && linetags.length > 0) {
                  linetags &&
                    linetags.map((tag) => {
                      seriesarray.push({
                        name: legendsdata[tagindex]["name"],
                        data: values,
                        yAxisIndex: axisindex,
                        type: legendsdata[tagindex]["name"] !== tag ? "bar" : "line",
                        axisLabel: { fontSize: 15 },
                      });
                    })
                } else {
                  seriesarray.push({
                    name: legendsdata[tagindex]["name"],
                    data: values,
                    yAxisIndex: axisindex,
                    type: "bar",
                    axisLabel: { fontSize: 15 },
                  });
                }
              }
            });
          } else {

            if (linetags && linetags.length > 0) {
              linetags &&
                linetags.map((tag) => {
                  seriesarray.push({
                    markArea: {
                      itemStyle: {
                        color: 'rgba(255, 173, 177, 0.4)'
                      }
                    },
                    name: legendsdata[tagindex]["name"],
                    data: values,
                    type: legendsdata[tagindex]["name"] !== tag ? "bar" : "line",
                    axisLabel: { fontSize: 15 },
                  });
                })
            } else {
              seriesarray.push({

                name: legendsdata[tagindex]["name"],
                // data: values,
                type: "bar",
                color: this.props.graphprops.colors[0],
                axisLabel: { fontSize: 15 },
              });
            }
          }
        }
      });
      const option = cloneDeep(this.getOption());
      if (this.props.graphprops.Linecolor && this.props.graphprops.Linecolor.length > 0
      ) {
        option.color = [...this.props.graphprops.Linecolor];
      }
      let series = await trendLabelTranslation(
        seriesarray,
        this.props.translationCache,
        this.props.language
      );
      option.xAxis.data = [...dates];
      option.yAxis = [...yaxis];
      option.series = [...series];
      option.legend = [...legendsdata];
      option.visualMap = visualMap
      this.setState({
        option: option,
        refreshDateTime: moment()
      });

    };
    this.setState({
      errorInAPI,
      noDataInDB
    })
  }

  getOption = () => {
    // let colorCode = this.props.graphprops && this.props.graphprops.colors ? this.props.graphprops.colors : ['red', "green"]
    let option = {
      title: {
        // text: 'Distribution of Electricity',
        // subtext: 'Fake Data'
      },
      // tooltip: {
      //   trigger: 'axis',
      //   // axisPointer: {
      //   //   type: 'cross'
      //   // }
      // },
      tooltip: {
        trigger: "axis",
        // formatter: "{b} <br/>{a}: {c} <br/> {a1} : {c1}",
        formatter: function (params) {
          var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';
          let rez = '<p>' + params[0].axisValue + '</p>';
          var xx = ""
          params.forEach((item, index) => {
            if (index <= 1) {
              if (item.seriesName === "BeltSpeed(mt/sec)" && item.color === "Red") {
                xx = '<p>' + colorSpan(item.color) + ' ' + "TransientSpeed" + ': ' + item.data + '</p>'
              } else if (item.seriesName === "BeltSpeed(mt/sec)" && item.color !== "Red") {
                xx = '<p>' + colorSpan(item.color) + ' ' + "StationarySpeed" + ': ' + item.data + '</p>'
              } else {
                xx = '<p>' + colorSpan(item.color) + ' ' + item.seriesName + ': ' + item.data + '</p>'
              }

              rez += xx;
            }

          });

          return rez;
        },
        axisPointer: {
          type: "shadow",
          animation: true
        },

        textStyle: {
          // color: theme === "lightTheme" ? "black" : "white",
          fontSize: 16,
        },
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLabel: {
          color: "white"
        }
        // prettier-ignore
        // data: ['00:00', '01:15', '02:30', '03:45', '05:00', '06:15', '07:30', '08:45', '10:00', '11:15', '12:30', '13:45', '15:00', '16:15', '17:30', '18:45', '20:00', '21:15', '22:30', '23:45']
      },
      yAxis: {
        type: 'value',
        // axisLabel: {
        //     formatter: '{value} W'
        // },
        axisPointer: {
          snap: true
        }
      },

    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(SliderStatus);
