import moment  from "moment";
import localStorage from "../../../utils/localStorage";

export async function selectDashboard(e) {
  let siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");
  var Authorization = "Bearer " + token;
  siteId = siteId ? siteId : 1
  let data = await fetch(`/api/dashboard/list`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify({
      Name: e,
      SiteId: siteId
    })
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (Array.isArray(json)) {
        return json
      }
    })
    .catch(error => {
      console.log("Error", error);
    });
  return data === undefined ? [] : data;
}


export async function getDashboardByName(e) {
  let siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");
  var Authorization = "Bearer " + token;
  siteId = siteId ? siteId : 1
  let data = await fetch(`/api/dashboard/getByName`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify({
      Name: e,
      SiteId: siteId
    })
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json) {
        return json
      }
    })
    .catch(error => {
      console.log("Error", error);
    });
  return data === undefined ? [] : data;
}

export async function updateDashboard(panelData, dashboardName, dashboardId) {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");
  var Authorization = "Bearer " + token;
  let data = await fetch(`/api/dashboard/update`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify({
      Name: dashboardName,
      Id: dashboardId,
      
      json: panelData,
      ModifiedBy: 1,
      ModifiedDate:  moment().format('YYYY-MM-DD HH:mm:ss'),
      SiteId: siteId
    })
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.Error) {
      } else if (json.message) {
        return json.message;
      }
    })
    .catch(err => {
      //return [];
    });
  return data === undefined ? "" : data;
}

export async function insertDashboard(panelData, dashboardName, dashboardId) {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");
  var Authorization = "Bearer " + token;

  let data = await fetch(`/api/dashboard/insert`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify({
      Name: dashboardName,
      Id: dashboardId,
      json: panelData,
      CreatedBy: 1,
      CreatedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      SiteId: siteId,
      Status: "Active"
    })
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.Error) {
      } else if (json.message) {
        return json.message;
      }
    })
    .catch(err => {
      // return [];
    });

  return data === undefined ? [] : data;
}

export async function deleteDashboard(Id) {
  const siteId = localStorage.get("currentSite");
  const token = localStorage.get("accessToken");
  var Authorization = "Bearer " + token;

  let data = await fetch(`/api/dashboard/delete`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify({
      Id,
      SiteId: siteId
    })
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.Error) {
      } else if (json.Data) {
        return json.Data;
      }
    })
    .catch(err => {
      // return [];
    });

  return data === undefined ? [] : data;
}
