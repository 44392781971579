import React from "react";
import { Row, Col } from "antd";

 import ActualGraph from "./ActualGraph";
import SummaryTable from "./SummaryTable";
import PredictedGraph from "./PredictedGraph";

class Prediction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      summaryTable: [],
      feedbackForm: {},
      graphprops: props.graphprops,
      predictionsType: "",
    };
  }

  summaryTable = (summaryTable, predictionsType) => {
   
    this.setState({
      summaryTable,
      predictionsType,
    });
  };
  feedbackForm = (feedbackForm) => {
    this.setState({
      feedbackForm,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift
    ) {
      this.setState({
        graphprops: this.props.graphprops,
        selectionDate: this.props.selectionDate,
      });
    }
  }

  render() {
    const { feedbackForm } = this.state;
    return (
      <div style={{ height: "70%", marginTop: "40px" }}>
        {/* <Row>
                    <Col span={12}>
                        <PredictedGraph graphprops={this.props.graphprops} selectionDate={this.props.selectionDate} summaryTable={this.summaryTable} />
                    </Col>
                    <Col span={12}>
                        <ActualGraph graphprops={this.props.graphprops} selectionDate={this.props.selectionDate}/> 
                    </Col>
                </Row> */}
        <PredictedGraph
          graphprops={this.props.graphprops}
          selectionDate={this.props.selectionDate}
          summaryTable={this.summaryTable}
          feedbackForm={this.feedbackForm}
          feedbackFormData={feedbackForm}
          selectionDateRange={this.props.selectionDateRange}
          // refereshData= {this.state.refereshData}
          refereshData={(refereshData)=> {
            this.setState({
              refereshData
          })
        }
      }
        />
        <SummaryTable
          summaryTable={this.state.summaryTable}
          predictionsType={this.state.predictionsType}
          feedbackForm={feedbackForm}
          refereshData={(refereshData)=> {
            this.setState({
              refereshData
          })
        }
      }

        />
      </div>
    );
  }
}

export default Prediction;
