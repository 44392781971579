import React from 'react'
import axios from 'axios'
import { Table, Form, Button, Input, Drawer, Row, Col, Select } from 'antd'

import localStorage from '../../../../utils/localStorage'
import { StyledApplication, DrawerFooter, AddTableButton } from '../style'

const FormItem = Form.Item
const { Option } = Select

class Conditions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      conditionList: props.conditionList || [],
      currentLanguage: 'English',
      deviceParameterConditionObject: {}
    }
  }

  componentWillReceiveProps({ conditionList }) {
    this.setState({ conditionList })
  }

  componentWillMount() {
    this.props.actions.getConditionList()
  }

  showDrawer = () => {
    this.props.form.resetFields()
    this.setState({
      deviceParameterConditionObject: {},
      visible: true
    })
  };

  onClose = () => {
    this.props.form.resetFields()
    this.setState({
      visible: false
    })
  };

  resetForm = () => {
    this.props.form.resetFields()
  }

  showDrawerOldDevice = (e, row) => {
    e.preventDefault()
    this.props.form.resetFields()
    this.setState({
      deviceParameterConditionObject: row,
      visible: true
    })
  };

  createDeviceParameterFactor = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this._saveDeviceParameterFactor(values)
      }
    })
  }

  updateDeviceParameterFactor = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this._updateDeviceParameterFactor(values)
      }
    })
  }

  _saveDeviceParameterFactor = (payload) => {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get('accessToken')
    const deviceTypeObject = {
      method: 'POST',
      url: `/api/site/${siteId}/condition/create`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    }
    axios(deviceTypeObject)
      .then(() => {
        this.setState({
          visible: false
        })
        this.props.actions.getConditionList()
      })
      .catch(function () {

      })
  }

  _updateDeviceParameterFactor = (payload) => {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get('accessToken')
    const deviceTypeObject = {
      method: 'PATCH',
      url: `/api/site/${siteId}/condition/update/${payload._key}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    }
    axios(deviceTypeObject)
      .then(() => {
        this.setState({
          visible: false
        })
        this.props.actions.getConditionList()
      })
      .catch(function () {

      })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { conditionList, deviceParameterConditionObject, visible } = this.state
    const columns = [{
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    }, {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    }, {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    }
    ]

    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 338 }}>
        <AddTableButton type='primary' onClick={this.showDrawer}>Add Conditions</AddTableButton>
        <Drawer
          title={!deviceParameterConditionObject._key ? 'Create Conditions' : 'Update Conditions'}
          placement="right"
          closable={true}
          width={500}
          onClose={this.onClose}
          visible={visible}
        >

          <Form layout='vertical'>
            <Row>
              <Col span={24}>
                <FormItem label="Name">
                  {getFieldDecorator('name', {
                    initialValue: deviceParameterConditionObject.name,
                    rules: [{ required: true, message: 'Please Input Your Name!' }]
                  })(
                    <Input placeholder="Enter Name" />
                  )}
                  {getFieldDecorator('_id', {
                    initialValue: deviceParameterConditionObject._id
                  })(
                    <Input style={{ display: 'none' }} />
                  )}
                  {getFieldDecorator('_key', {
                    initialValue: deviceParameterConditionObject._key
                  })(
                    <Input style={{ display: 'none' }} />
                  )}
                  {getFieldDecorator('_rev', {
                    initialValue: deviceParameterConditionObject._rev
                  })(
                    <Input style={{ display: 'none' }} />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label="Code">
                  {getFieldDecorator('code', {
                    initialValue: deviceParameterConditionObject.code,
                    rules: [{ required: true, message: 'Please Input Your Code!' }]
                  })(
                    <Input placeholder="Enter Code" disabled={deviceParameterConditionObject._key} />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label="Type">
                  {getFieldDecorator('type', {
                    initialValue: deviceParameterConditionObject.type,
                    rules: [{ required: true, message: 'Please Input Your Type!' }]
                  })(
                    <Select
                      defaultValue={deviceParameterConditionObject.type}
                      placeholder="DataType"
                      disabled={deviceParameterConditionObject._key}
                    >
                      <Option value="float">float</Option>
                      <Option value="boolean">boolean</Option>
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
            </Row>

          </Form>
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}> Cancel </Button>
            {
              deviceParameterConditionObject._key === undefined
                ? <Button onClick={this.createDeviceParameterFactor} type="primary">Create Conditions</Button>
                : <Button onClick={this.updateDeviceParameterFactor} type="primary">Update Conditions</Button>
            }
          </DrawerFooter>

        </Drawer>
        <Table
          size='medium'
          columns={columns}
          dataSource={conditionList}
          pagination={(conditionList.length > 5) ? {
            pageSize: '5'
          } : false}
          locale={{ emptyText: 'No Data' }}
          onRow={(record) => ({
            onClick: (e) => {
              e.stopPropagation()
              this.showDrawerOldDevice(e, record)
            }
          })} />
      </StyledApplication>
    )
  }
}

export default Form.create()(Conditions)
