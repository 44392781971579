import React from "react";

import {
  Table,
  Guage,
  Pie,
  PieRose,
  Image,
  Donut,
  MultiLine,
  Counter,
  Funnel,
  Bar,
  CandleStick,
  SingleBar,
  Slider,
  Area,
  DatePicker,
  BarDatePicker,
  TableDatePicker,
  Prediction,
  ProgressBar,
  Regression,
  PieBar,
  BubbleChart,
  CandleStickBrush,
  StackBar,
  TreeGraph,
  BoxPlot,
  BeijingLine,
  RangeDualTable,
  LiquidGuage,
  SingleCandleStick,
  BarTable,
  StatusCounter,
  DateRangeSlider,
  DateRangeTable,
  DateRangeSingleBar,
  DateRangeCandleStick,
  // DateRangePrediction,
  DateBarTable,
  Scatter,
  HeatmapCartesion,
  HeatmapCalendar,
  DateBar,
  DateDottedBar,
  DottedBar,
  PieSingleLine,
  PieLine,
  CardsList,
  WindBarb,
  StackedBar,
  StackedLine,
  FeedbackTable,
  DateFeedbackTable,
  ImageGallery,
  VideoComponent,
  GuageRing,
  NotificationTable,
  SelectImageDetails,
  PieNestComponent,
  LineTable,
  SliderStatus,
  GuageLine,
  BarwithLine,
  Report,
  DateHeatmapCartesion,
  NotificationScatter,
  DateRangePrediction,
  DateRangeNotificationScatter
} from "../ChartComponents";
import DateRangeSingleCandleStick from "../ChartComponents/Widgets/DateRangeSingleCandleStick";
//import DateRangePrediction from "../ChartComponents/Widgets/DateRangePrediction";
class Graph extends React.Component {
  render() {
    let { graphprops } = this.props
    switch (graphprops.graph) {
      case "report":
        return <Report {...this.props}  {...this.props.selection} />
      case "guage":
        return <Guage {...this.props} {...this.props.selection} />
      case "singleBar":
        return <SingleBar {...this.props}  {...this.props.selection} />
      case "dateRangeSingleBar":
        return <DateRangeSingleBar {...this.props}  {...this.props.selection} />
      case 'image':
        return <Image {...this.props} {...this.props.selection} />
      case "counter":
        return <Counter {...this.props} {...this.props.selection} />
      case "bar":
        return <Bar {...this.props} {...this.props.selection} />
      case 'dateBar':
        return <DateBar {...this.props} {...this.props.selection} />
      case 'dateBarDotted':
        return <DateDottedBar {...this.props} {...this.props.selection} />
      case "rangeBar":
        return <BarDatePicker {...this.props} {...this.props.selection} />
      case "table":
        return <Table {...this.props}  {...this.props.selection} />
      case "dateRangeTable":
        return <DateRangeTable {...this.props}  {...this.props.selection} />
      case "rangeTable":
        return <TableDatePicker {...this.props} {...this.props.selection} />
      case "candleStick":
        return <CandleStick {...this.props}  {...this.props.selection} />
      case "dateRangeCandleStick":
        return <DateRangeCandleStick {...this.props}  {...this.props.selection} />
      case "line":
        return <Slider {...this.props} {...this.props.selection} />
      case "dateRangeLine":
        return <DateRangeSlider {...this.props} {...this.props.selection} />
      case "rangeLine":
        return <DatePicker {...this.props}  {...this.props.selection} />
      case "progressBar":
        return <ProgressBar {...this.props}  {...this.props.selection} />
      case "multiline":
        return <MultiLine {...this.props}  {...this.props.selection} />
      case "funnel":
        return <Funnel {...this.props} {...this.props.selection} />
      case "donut":
        return <Donut {...this.props} {...this.props.selection} />
      case "pie":
        return <Pie {...this.props}  {...this.props.selection} />
      case "pieRose":
        return <PieRose {...this.props}  {...this.props.selection} />
      case "area":
        return <Area {...this.props}  {...this.props.selection} />
      case "regression":
        return <Regression {...this.props} {...this.props.selection} />
      case "pieBar":
        return <PieBar {...this.props} {...this.props.selection} />
      case "bubbleChart":
        return <BubbleChart {...this.props} {...this.props.selection} />
      case "candleStickBrush":
        return <CandleStickBrush {...this.props} {...this.props.selection} />
      case "stackBar":
        return <StackBar {...this.props} {...this.props.selection} />;
      case "treeGraph":
        return <TreeGraph {...this.props} {...this.props.selection} />;
      case "boxPlot":
        return <BoxPlot {...this.props} {...this.props.selection} />;
      case "liquidGuage":
        return <LiquidGuage {...this.props} {...this.props.selection} />
      case "beijingLine":
        return <BeijingLine {...this.props} {...this.props.selection} />
      case "rangeDualTable":
        return <RangeDualTable {...this.props}  {...this.props.selection} />
        case "dateRangeSingleCandleStick":
          return <DateRangeSingleCandleStick {...this.props}  {...this.props.selection} />
      case "singleCandleStick":
        return <SingleCandleStick {...this.props} {...this.props.selection} /> 
      case "barTable":
        return <BarTable {...this.props} {...this.props.selection} />
      case "lineTable":
        return <LineTable {...this.props} {...this.props.selection} />
      case "dateBarTable":
        return <DateBarTable {...this.props} {...this.props.selection} />
      case "statusCounter":
        return <StatusCounter {...this.props} {...this.props.selection} />
      case "scatter":
        return <Scatter {...this.props}  {...this.props.selection} />
      case "HeatmapCartesion":
        return <HeatmapCartesion {...this.props}  {...this.props.selection} />
      case "dateHeatmapCartesion":
        return <DateHeatmapCartesion {...this.props}  {...this.props.selection} />
      case "heatmapCalendar":
        return <HeatmapCalendar {...this.props}  {...this.props.selection} />
      case "dottedBar":
        return <DottedBar {...this.props} {...this.props.selection} />
      case "GuageLine":
        return <GuageLine {...this.props} {...this.props.selection} />
      case "pieSingleLine":
        return <PieSingleLine {...this.props} {...this.props.selection} />
      case "pieLine":
        return <PieLine {...this.props} {...this.props.selection} />
      case "cardsList":
        return <CardsList {...this.props} {...this.props.selection} />
      case "windBarb":
        return <WindBarb {...this.props} {...this.props.selection} />
      case "stackedBar":
        return <StackedBar {...this.props} {...this.props.selection} />
      case "stackedLine":
        return <StackedLine {...this.props} {...this.props.selection} />
      case "feedbackTable":
        return <FeedbackTable {...this.props} {...this.props.selection} />
      case "dateFeedbackTable":
        return <DateFeedbackTable {...this.props} {...this.props.selection} />
      case "imageGallery":
        return <ImageGallery {...this.props}  {...this.props.selection} />
      case "video":
        return <VideoComponent {...this.props} {...this.props.selection} />
      case "GuageRing":
        return <GuageRing {...this.props} {...this.props.selection} />
      case "notificationTable":
        return <NotificationTable {...this.props} {...this.props.selection} />
      case "selectImageDetails":
        return <SelectImageDetails {...this.props} {...this.props.selection} />
      case "pieNest":
        return <PieNestComponent {...this.props} {...this.props.selection} />
      case "sliderStatus":
        return <SliderStatus {...this.props} {...this.props.selection} />
      // case "cards":
      //   return <BarwithLine {...this.props} {...this.props.selection} />
      case "predictions":
        return <Prediction {...this.props}  {...this.props.selection} />
      case "dateRangePredictions":
        return <DateRangePrediction {...this.props}  {...this.props.selection} />
      case "notificationScatter":
        return <NotificationScatter {...this.props} {...this.props.selection} />
      case "dateRangeNotificationScatter":
        return <DateRangeNotificationScatter {...this.props} {...this.props.selection} />
      default:
        return "";
    }
  }
}

export default Graph;
