
import React from 'react'
import ReactDOM from 'react-dom'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import history from './commons/history'

import { Provider } from 'react-redux'
import { fromJS } from 'immutable'
import { routerMiddleware } from 'react-router-redux'
import { createStore, compose, applyMiddleware } from 'redux'
import { persistStore, autoRehydrate } from 'redux-persist-immutable'

import rootReducer from './modules'
import rootSaga from './modules/sagas'

import './index.css'
import 'antd/dist/antd.css'
// import 'antd4/dist/antd.css'
import Route from './routes'

const initialState = {}
const enhancers = []
const routerMiddleWare = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()
const middleware = [logger, routerMiddleWare, sagaMiddleware]

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  autoRehydrate(),
  ...enhancers
)

const store = createStore(
  rootReducer(),
  fromJS(initialState),
  composedEnhancers
)
persistStore(store)
sagaMiddleware.run(rootSaga)

ReactDOM.render(
  <Provider store={store}>
    <Route />
  </Provider>,
  document.getElementById('root')
)