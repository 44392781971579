import React from 'react'
import axios from 'axios'
import {  Form, Col, Row, Icon,Select } from 'antd'

import localStorage from '../../../../utils/localStorage'

const { Option } = Select

class SiteRoleComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            siteList: [],
            roleList: [],
            site: props.site,
            index: props.index
        }
    }

    componentWillMount() {
        this._getSiteList()
        this._getRolesList(this.props.site.SiteId)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.site !== this.props.site) {
            this.setState({
                site: this.props.site
            })
            this._getRolesList(this.props.site.SiteId)
        }
    }


    _getSiteList = () => {
        const accessToken = localStorage.get('accessToken')
        let orgHeaders = {
            method: 'GET',
            url: `/api/site/list`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
        axios(orgHeaders)
            .then((response) => {
                this.setState({ siteList: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }


    _getRolesList = (siteId) => {
        const accessToken = localStorage.get('accessToken')
        let orgHeaders = {
            method: 'GET',
            url: `/api/role/list?SiteId=${siteId}`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
        axios(orgHeaders)
            .then((response) => {
                this.setState({ roleList: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }


    render() {
        const { site, index, siteList, roleList } = this.state
        const { getFieldDecorator } = this.props.form
        return (
            <Row>

                <Col span={12}>
                    <Form.Item label={index === 0 ? "Site" : ""} key={index}>
                        {getFieldDecorator(`SiteId[${index}]`, {
                            initialValue: site.SiteId,
                            rules: [{ required: true, message: "Site is required" }]
                        })(
                            <Select
                                showArrow
                                showSearch
                                defaultValue={site.SiteId}
                                openOnFocus
                                style={{ width: 160 }}
                                onChange={e => {
                                    this.props.handleFieldChange(e, "SiteId", index)
                                }
                                }
                                placeholder="Site"
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    siteList && siteList.map(siteObj => {
                                        return <Option value={siteObj.Id}>{siteObj.Name}</Option>;
                                    })
                                }
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label={index === 0 ? "Role" : ""} key={index}>
                        {getFieldDecorator(`RoleId[${index}]`, {
                            initialValue: site.RoleId,
                            rules: [{ required: true, message: "Role is required" }]
                        })(
                            <Select
                                defaultValue={site.RoleId}
                                openOnFocus
                                style={{ width: 160 }}
                                onChange={e => this.props.handleFieldChange(e, "RoleId", index)}
                                placeholder="Role"
                            >
                                {roleList.map(role => {
                                    return <Option value={role.Id}>{role.Name}</Option>;
                                })}
                            </Select>
                        )}
                        <Icon
                            style={{ marginLeft: "10px" }}
                            className="dynamic-delete-button"
                            type="minus-circle-o"
                            onClick={() => this.props.remove(index, site)}
                        />
                    </Form.Item>
                </Col>

            </Row>

        )
    }
}
export default (SiteRoleComponent)
