import React from "react";
import {
  Select,
  Button,
  DatePicker,
  Spin,
  Input,
  message,
  Form,
  Row,
  Col,
  Radio,
  Cascader
} from "antd";
import moment from "moment";
import queryString from "query-string";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../selectors/language";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import BookmarkListDrawer from "./listDrawer";
import { StyledDashboard } from "./styles";
import TrendSlider from "./trendSlider";
import TrendTable from "./TrendTable";
import history from "../../commons/history";
import { constant } from "./constants";
import NoRecordComponent from "./NoRecords";
import "./trends.css";
import configAdapter from "./adapter";
import {
  getTrendData,
  getParameterList,
  getbookmarkList,
  createBookmark,
  updateBookmark,
  deleteBookmark,
  getDeviceList,
} from "./ChartCalls";
import "./trends.css";

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;
const color = [
  "orange",
  "tomato",
  "steelblue",
  "green",
  "pink",
  "voilet",
  "yellow",
  "red",
  "blue",
  "gray",
];

const columns = [
  {
    title: "Device Name",
    dataIndex: "deviceName",
    key: "deviceName",
  },
  {
    title: "Parameter Name",
    dataIndex: "parameterName",
    key: "parameterName",
  },
  {
    title: "Parameter Value",
    dataIndex: "tagValue",
    key: "tagValue",
  },
  {
    title: "TimeStamp",
    dataIndex: "interval_Alias",
    key: "interval_Alias",
  },
];

const IntervalData = [
  {
    key: "1Min",
    value: "1m"
  },
  {
    key: "15Min",
    value: "15m"
  }, {
    key: "30Min",
    value: "30m"
  },
  {
    key: "60Min",
    value: "1h"
  },
  {
    key: "Day",
    value: "1d"
  }
]

const FormItem = Form.Item;

class Trends extends React.Component {
  timeTicket = null;
  requestCheck = true;

  constructor(props) {
    super(props);
    var dt = new Date();
    dt.setDate(dt.getDate() - 1);
    this.state = {
      loading: false,
      showDateRange: false,
      type: "live",
      devicesData: [],
      parameters: [],
      parameterAlias: [],
      noDataInDB: false,
      range: [],
      currentTab: "day",
      momentRange: [],
      mmRangeComparision: [],
      momentRangeComparision: [],
      trendData: [],
      selectedParams: [],
      selectedParam: [],
      options: [],
      parameterType: [],
      selectedDevice: [],
      shwParamdrpDown: false,
      deviceData: [],
      dataToTrendSlider: [],
      dateStringg: "",
      parameterList: {},
      bookmarkName: "",
      singleView: true,
      diff: "",
      // selectedType: [],
      listView: false,
      selectedInterval: "",
      viewType: "graph",
      translationCache: props.translationCache || [],
      parameterListByType: [],
      language: props.language || "english",
      Module: ""
    };
    this.devicehandleChange = this.devicehandleChange.bind(this);
    this.paramhandle = this.paramhandle.bind(this);
  }

  abortController = new window.AbortController();

  async componentWillMount() {
    // this.props.actions.getAssetDeviceCache();
    let Module = this.props && this.props.match && this.props.match.params && this.props.match.params.module;
    let deviceData = await getDeviceList(Module);
    if (deviceData) {
      this.setState({ deviceData, Module })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.translationCache !== this.props.translationCache ||
      prevProps.language !== this.props.language
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        language: this.props.language,
      });
    }
    if (prevProps.assetList !== this.props.assetList) {
      // this.getReportName();
      this.setState({
        options: configAdapter(this.props.assetList),
      });
    }

  }

  componentWillUnmount() {
    this.getReportName();
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  async componentDidMount() {
    this.getReportName();
  }

  onChange = (value, dateString) => {
    // let startDateSelected1 = moment(date).format('YYYY-MM-DD 00:00:00')
    // let endDateSelected1 = moment(date).format('YYYY-MM-DD 23:59:59')
    this.setState({
      range: [dateString[0], dateString[1]],
      momentRange: [moment(dateString[0]).format('YYYY-MM-DD HH:mm:ss'),
      moment(dateString[1]).format("YYYY-MM-DD HH:mm:ss")],
    });
    var now = moment(dateString[0]);
    var then = moment(dateString[1]);
    let difference = then.diff(now, "day")
    this.setState({
      diff: difference
    })
  };

  onChangesectimerange = (value, dateString) => {
    let { diff, dateStringg, mmRangeComparision, momentRangeComparision, range } = this.state
    this.setState({
      // range:[dateString[0],null],
      momentRangeComparision: [dateString[0], dateString[1]],
      mmRangeComparision: [moment(dateString[0]).format('YYYY-MM-DD HH:mm:ss'),

      moment(dateString[1]).format('YYYY-MM-DD HH:mm:ss')],
      dateStringg: moment(dateString[0]).add(diff, "days").format("YYYY-MM-DD HH:MM:ss"),
    });
  };

  updateLiveChart = async (config) => {
    let { noDataInDB } = this.state
    let trendData = await getTrendData(config, this.abortController);
    console.log("trtendDATAINUPL", trendData, this.state.singleView, this.state.trendData);
    if (this.state.singleView) {
      console.log("trtendDATAINUPL1", trendData);
      if (trendData && Array.isArray(trendData) && trendData.length > 0) {
        this.setState(
          {
            trendData: [...this.state.trendData, ...trendData],
            loading: false,
            payLoad: config,
            update: true,
          },
          () => {
            this.requestCheck = true;
          }
        );
      } else {
        this.requestCheck = true;
        this.setState({ loading: false });
        message.warn(" Data is not available for selected parameters");
      }
    }
    else {
      if (trendData && trendData.length > 0) {
        if (this.state.trendData.length > 0) {
          console.log("trtendDATAINUPL2", this.state.trendData);
          let data = trendData.map((data, i) => {
            if (trendData[i][0] !== undefined) {
              data.push(trendData[i][0]);
            }
            return data;
          });
          this.setState(
            {
              trendData: data,
              update: true,
            },
            () => {
              this.requestCheck = true;
            }
          );
        }
        else {
          console.log("tf", trendData);
          this.setState(
            {
              trendData: [...this.state.trendData, ...trendData],
              loading: false,
              payLoad: config,
              update: true,
            },
            () => {
              this.requestCheck = true;
            }
          );
        }
      }
      else {
        this.requestCheck = true;
        this.setState({ loading: false });
        message.warn("Data is not available for selected parameters");
      }
    }

  };

  getData = async (selectedWidget) => {
    console.log("sec", selectedWidget);
    let { dataToTrendSlider } = this.state
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.state.type === "live") {
          if (this.timeTicket) {
            clearInterval(this.timeTicket);
          }

          const tunedData = this.trendAdapter();
          console.log("tunedData", tunedData);
          this.setState({
            dataToTrendSlider: tunedData
          })
          if (tunedData.data.length > 0) {
            this.setState({ trendData: [], loading: true });
            if (this.requestCheck) {
              this.requestCheck = false;
              this.updateLiveChart(tunedData);
            }
            this.timeTicket = setInterval(() => {
              if (this.requestCheck) {
                this.requestCheck = false;
                this.updateLiveChart(tunedData);
              }
            }, 60000);
          }
        }
        else if (this.state.type === "historic" || this.state.type === "timeWithSelectionType") {
          if (this.timeTicket) {
            clearInterval(this.timeTicket);
          }
          const tunedData = this.trendAdapter();
          console.log("tuned88", tunedData);
          this.setState({
            dataToTrendSlider: tunedData
          })
          if (tunedData.data.length > 0) {
            if (this.requestCheck) {
              this.setState({ trendData: [], loading: true });
              this.requestCheck = false;
              this.updateLiveChart(tunedData);
            }
          }
        }
        else if (this.state.type === "timeRange") {
          if (this.timeTicket) {
            clearInterval(this.timeTicket);
          }
          const tunedData = this.trendAdapter();
          console.log("tuned89", tunedData);
          this.setState({
            dataToTrendSlider: tunedData
          })
          if (tunedData.data.length > 0) {
            if (this.requestCheck) {
              this.setState({ trendData: [], loading: true });
              this.requestCheck = false;
              this.updateLiveChart(tunedData);
            }
          }
        }
        // else {
        //   if (this.timeTicket) {
        //     clearInterval(this.timeTicket);
        //   }
        //   const tunedData = this.trendAdapter(selectedWidget);
        //   console.log("tuned89",tunedData);
        //   if (tunedData.data.length > 0) {
        //     if (this.requestCheck) {
        //       this.setState({ trendData: [], loading: true });
        //       this.requestCheck = false;
        //       this.updateLiveChart(tunedData);
        //     }
        //   }
        // }
      }
    });
  };

  trendAdapter(selectedWidget) {
    const {
      parameterList,
      parameterListByType,
      selectedParam,
      type,
      range,
      momentRange,
      mmRangeComparision,
      selectedType,
      selectedInterval,
      singleView,
      currentTab
    } = this.state;
    let params = [];

    try {
      selectedParam.map((param) => {
        let spliteData = param.split("@@");
        let data = parameterListByType
          .filter(
            (cacheParam) =>
              cacheParam.DeviceId === Number(spliteData[0]) &&
              cacheParam.ParameterCode === spliteData[1]
          )
          .map((obj) => ({
            parameterCode: obj.ParameterCode,
            deviceId: obj.DeviceId,
            tableName: obj.TableName,
            parameterType: obj.ParameterType,
            parameterDescription: obj.ParameterDescription,
            parameterName: obj.ParameterName,
            deviceName: obj.DeviceName
          }));
        params = [...params, ...data];
      });
      let queryObj = { type, singleView, data: params };
      if (type === "historic") {
        // queryObj.fromDate = range[0];
        // queryObj.toDate = range[1];
        queryObj.operationType = selectedType;
        queryObj.interval = selectedInterval;
        queryObj.fromDate = moment(momentRange[0]).utc().format("YYYY-MM-DD HH:mm:ss"); //conversion to utc
        queryObj.toDate = moment(momentRange[1]).utc().format("YYYY-MM-DD HH:mm:ss"); //conversion to utc
      }
      else if (type === "timeRange") {

        queryObj.fromDate1 = moment(momentRange[0]).utc().format("YYYY-MM-DD HH:mm:ss");
        queryObj.toDate1 = moment(momentRange[1]).utc().format("YYYY-MM-DD HH:mm:ss");
        queryObj.fromDate2 = moment(mmRangeComparision[0]).utc().format("YYYY-MM-DD HH:mm:ss");
        queryObj.toDate2 = moment(mmRangeComparision[1]).utc().format("YYYY-MM-DD HH:mm:ss");
        //queryObj.operationType = selectedType;
        queryObj.interval = selectedInterval;
      }
      else if (type === "timeWithSelectionType") {
        this.setState({
          currentTab: selectedWidget
        })
        // queryObj.fromDate = range[0];
        // queryObj.toDate = range[1];
        queryObj.fromDate = moment(momentRange[0]).utc().format("YYYY-MM-DD HH:mm:ss"); //conversion to utc
        queryObj.toDate = moment(momentRange[1]).utc().format("YYYY-MM-DD HH:mm:ss"); //conversion to utc
        queryObj.operationType = selectedType;
        queryObj.interval = selectedInterval;
        queryObj.selectionType = selectedWidget || currentTab;
      }
      return queryObj;
    } catch (error) {
    }
  }


  // async devicehandleChange(value) {
  //   //  console.log(value,"testCase")
  //   if (value.length == 0) {
  //     this.props.form.resetFields();
  //     this.setState({ selectedParam: [], parameterListByType: [], parameterType: "" })
  //   } else {
  //     this.setState({ shwDeviceDropDown: true });
  //     let parameterList = {};
  //     if (value.length === 6) {
  //       value.shift();
  //       parameterList = await getParameterList(value);
  //     } else {
  //       parameterList = await getParameterList(value);
  //     }
  //     this.setState({
  //       selectedDevice: value,
  //       parameterList: parameterList.data,
  //       update: false,
  //       parameterType: null
  //     });
  //   }
  // }
  async devicehandleChange(value) {
    this.setState({ shwDeviceDropDown: true });
    let parameterList = {};
    if (value.length === 6) {
      value.shift();
      parameterList = await getParameterList(value);
    } else {
      parameterList = await getParameterList(value);
    }
    this.setState({
      selectedDevice: value,
      parameterList: parameterList.data,
      update: false,
      parameterListByType: [],
      parameterType: undefined,
      selectedParam: []
    });
    this.props.form.setFieldsValue({
      ["Select Parameter (s)"]: []
    });

  }


  paramhandle(value) {
    if (this.state.type === "timeRange" && value.length === 2) {
      value.shift();
      this.setState({ selectedParam: value, update: false });
    }
    else if (this.state.type === "timeWithSelectionType" && value.length === 2) {
      value.shift();
      this.setState({ selectedParam: value, update: false });
    }
    else if (value.length === 11) {
      value.shift();
      this.setState({ selectedParam: value, update: false });
    } else {
      this.setState({ selectedParam: value, update: false });
    }
  }

  handleView = () => {
    this.setState({
      singleView: !this.state.singleView,
      trendData: []
    },
      () => {
        this.requestCheck = true;
        this.getData();
      });
  };

  saveBookmark = async () => {
    let { bookmarkName } = this.state;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (bookmarkName) {
          this.bookmarkSave();
        } else {
          message.error("Bookmark name can't be empty");
        }
      }
    });
  };

  bookmarkSave = async () => {
    const {
      type,
      selectedParam,
      selectedDevice,
      bookmarkName,
      selectedType,
      selectedInterval,
      parameterType,
      payLoad,
      Module
    } = this.state;

    const param = {
      Type: type,
      Name: bookmarkName,
      Devices: selectedDevice,
      Parameters: selectedParam,
      // operationType: selectedType,
      OperationType: selectedType,
      Interval: selectedInterval,
      Payload: payLoad, //JSON.stringify(payLoad)
      ParameterType: parameterType,
      Module
    };
    let data = await createBookmark(param);
    if (data && data.message) {
      message.success(data.message);
      let list = await getbookmarkList(Module);
      this.setState({ bookmarkList: list.data });
    }
  };

  updateBookmark = async () => {
    let {
      type,
      selectedParam,
      selectedDevice,
      bookmarkName,
      payLoad,
      updateId,
      selectedInterval,
      parameterType,
      selectedType,
      Module
    } = this.state;

    let param = {
      Type: type,
      Id: updateId,
      Name: bookmarkName,
      Devices: selectedDevice,
      Parameters: selectedParam,
      OperationType: selectedType,
      Interval: selectedInterval,
      Payload: payLoad, //JSON.stringify(payLoad)
      ParameterType: parameterType,
      Module
    };
    let data = await updateBookmark(param);
    if (data && data.message) {
      message.success(data.message);
      let list = await getbookmarkList(Module);
      this.setState({ bookmarkList: list.data });
    }
  };

  hableInput = (e) => {
    this.setState({ bookmarkName: e, update: false });
  };

  handleBookmarkList = async () => {
    let { Module } = this.state
    let list = await getbookmarkList(Module);
    this.setState({ listView: true, bookmarkList: list.data, update: false });
  };

  onCloseList = () => {
    this.setState({ listView: false });
  };
  editBookmark = async (data) => {
    console.log("datatype", data);
    let paramData = await getParameterList(data.Devices);
    let trendsName = this.props.match.params.module;
    let deviceData = await getDeviceList(trendsName)
    // this.props.form.resetFields()
    console.log("dtype1", data.Type);
    if (data.Type === "live") {
      console.log("typelive", data.Payload.singleView);
      this.setState({
        editBookmark: true,
        parameterList: paramData.data,
        type: data.Type,
        // deviceData: deviceData.data,
        selectedDevice: data.Devices,
        bookmarkName: data.Name,
        payLoad: data.Payload,
        singleView: data.Payload.singleView,
        selectedParam: data.Parameters,
        listView: false,
        updateId: data.Id,
        parameterType: data.ParameterType,
        parameterListByType: paramData.data[data.ParameterType]
      });
    }
    else if (data.Type === "timeRange") {
      console.log("typetr", data.Payload.singleView);
      this.setState({
        editBookmark: true,
        parameterList: paramData.data,
        type: data.Type,
        // deviceData: deviceData.data,
        selectedDevice: data.Devices,
        bookmarkName: data.Name,
        payLoad: data.Payload,
        singleView: data.Payload.singleView,
        selectedParam: data.Parameters,
        range: [data.Payload.fromDate, data.Payload.toDate],
        selectedType: data.Payload.operationType,
        selectedInterval: data.Payload.interval,
        momentRange: [
          moment(data.Payload.fromDate1),
          moment(data.Payload.toDate1),
        ],
        mmRangeComparision: [
          moment(data.Payload.fromDate2),
          moment(data.Payload.toDate2),
        ],
        listView: false,
        updateId: data.Id,
        parameterType: data.ParameterType,
        parameterListByType: paramData.data[data.ParameterType]
      });
    }
    else {
      console.log("typedmy", data.Payload.singleView);
      this.setState({
        editBookmark: true,
        parameterList: paramData.data,
        type: data.Type,
        // deviceData: deviceData.data,
        selectedDevice: data.Devices,
        bookmarkName: data.Name,
        payLoad: data.Payload,
        singleView: data.Payload.singleView,
        selectedParam: data.Parameters,
        range: [data.Payload.fromDate, data.Payload.toDate],
        selectedType: data.Payload.operationType,
        selectedInterval: data.Payload.interval,
        momentRange: [
          moment(data.Payload.fromDate),
          moment(data.Payload.toDate),
        ],
        listView: false,
        updateId: data.Id,
        parameterType: data.ParameterType,
        parameterListByType: paramData.data[data.ParameterType]
      });
    }
    this.props.form.resetFields()
  };

  deleteBookmark = async (bookmarkName, Id) => {
    let { Module } = this.state
    let data = await deleteBookmark(bookmarkName, Id);
    if (data.status === 200) {
      message.success("Deleted Successfully");
      let list = await getbookmarkList(Module);
      this.setState({ bookmarkList: list.data, listView: false });
    }
  };

  viewBookmark = async (name) => {
    let stringdata = queryString.stringify(
      { type: "bookmark", name: name },
      { arrayFormat: "index" }
    );
    let path = `/rubus/ViewTrends?${stringdata}`;
    history.push(path);
  };

  onChangeToggle = (e) => {
    const { viewType } = this.state;
    this.setState({ viewType: e.target.value });
  };

  // getReportNametoDownload(excelData, type) {
  //   let payload = {
  //     portName: "DPT Kandla",
  //     name: type && type === "live" ? "Live" : "Historic",
  //     consumption: "Trend",
  //     date: moment().format("YYYY-MM-DD"),
  //     time: moment().format("HH:mm:SS"),
  //     data: excelData || [],
  //     reportType: "singleHierarchy",
  //   };

  //   let accessToken = localStorage.get("accessToken");
  //   const siteId = localStorage.get("currentSite");
  //   let options = {};
  //   options = {
  //     method: "POST",
  //     url: `/api/site/${siteId}/report/generateExcel`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //     data: JSON.stringify(payload),
  //   };
  //   return axios(options)
  //     .then(({ data }) => {
  //       return data;
  //     })
  //     .catch((error) => {
  //       return "";
  //     });
  // }

  getReportName = async () => {
    const panelData = this.state.trendData;
    let newList = []
    newList = panelData && panelData.trendData && panelData.trendData.map((columns, index) => {
      return {
        "S.No": index + 1,
        "Timestamp": columns.interval_Alias,
        "Device Name": columns.deviceName,
        "Parameter Name": columns.parameterName,
        "Parameter Value": columns.tagValue,
      }
    })
    // let reportName = await this.getReportNametoDownload(newList, panelData.type);
    this.setState({
      // reportName: reportName && reportName.file ? reportName.file : "",
    });
  }



  handleParameterType = (value) => {
    let { parameterList } = this.state
    this.setState({
      parameterListByType: parameterList[value],
      parameterType: value
    })
  }

  onRadioChange = (e) => {
    this.setState({
      currentTab: e.target.value
    })
    this.getData(e.target.value);
  }

  disabledDate = (current) => {
    return current > moment().startOf('day').add(1, 'day');
  }

  typeChange = (value) => {
    this.setState({
      type: value,
      singleView: value && (value === "timeRange" || value === "timeWithSelectionType") ? false : true,
      mmRangeComparision: [],
      momentRange: []
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      type,
      loading,
      deviceData,
      selectedDevice,
      singleView,
      trendData,
      translationCache,
      language,
      viewType,
      selectedParam,
      selectedInterval,
      parameterList,
      parameterListByType,
      parameterType,
      tunedData
    } = this.state
    console.log("11render", trendData);
    return (
      <StyledDashboard>
        <React.Fragment>
          <div style={{ background: "rgb(26, 54, 82)", padding: "30px" }}>
            <div style={{ border: "0.1px dashed gray", padding: "20px" }}>
              <Form>
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <FormItem
                      className="trendsFormLabel"
                      style={{
                        marginTop: "5px",
                        fontWeight: "bold",
                        fontSize: "16px",
                        width: 350,
                      }}
                      label={
                        translationCache &&
                          translationCache[`${constant.Select_Trend_Type}`]
                          ? translationCache[`${constant.Select_Trend_Type}`]
                          : `${constant.Select_Trend_Type}`
                      }
                    >
                      {getFieldDecorator("Select Trend Type", {
                        initialValue: this.state.type,
                        rules: [
                          { required: true, message: "Please Select TrendType!" },
                        ],
                      })(
                        <Select
                          placeholder="Please Select Trend Type"
                          onChange={this.typeChange}
                        // {(value) => {
                        //   this.setState({
                        //     type: value,
                        //     singleView: value && (value === "timeRange" || value === "timeWithSelectionType") ? false : true
                        //   });
                        // }}
                        >
                          <Option value="live">Live</Option>
                          <Option value="historic">Historical</Option>
                          <Option value="timeRange">Trend between two different TimeRanges</Option>
                          <Option value="timeWithSelectionType">Trend based Day Week Month Year Comparision</Option>
                        </Select>
                      )}
                    </FormItem>
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <FormItem
                      className="trendsFormLabel"
                      label={
                        translationCache &&
                          translationCache[`${constant.Select_Device}`]
                          ? translationCache[`${constant.Select_Device}`]
                          : `${constant.Select_Device}`
                      }
                    >
                      {getFieldDecorator("Select Device", {
                        initialValue: selectedDevice,
                        rules: [
                          { required: true, message: "Please Select Device!" },
                        ],
                      })(
                        <Select
                          mode="multiple"
                          style={{ width: 350 }}
                          placeholder={
                            translationCache &&
                              translationCache[`${constant.Select_Device}`] &&
                              translationCache[`${constant.Select_Device}`]
                              ? translationCache[`${constant.Select_Device}`][
                              language
                              ]
                              : `${constant.Select_Device}`
                          }
                          filterOption={(input, option) => {
                            if (option && option.props) {
                              return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          }


                          }
                          value={selectedDevice}
                          onChange={this.devicehandleChange}
                        >
                          {deviceData &&
                            Object.keys(deviceData).map((device) => {
                              return (
                                <Option value={deviceData[device].Id}>
                                  {
                                    translationCache &&
                                      translationCache[`${deviceData[device].Name}`]
                                      ? translationCache[`${deviceData[device].Name}`]
                                      : `${deviceData[device].Name}`
                                  }
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                    </FormItem>
                  </Col>

                  <Col className="gutter-row" span={8}>
                    <FormItem

                      className="trendsFormLabel"
                      style={{
                        marginTop: "5px",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                      label={
                        translationCache &&
                          translationCache[`${constant.Select_Parameter}`] &&
                          translationCache[`${constant.Select_Parameter}`]
                          ? translationCache[`${constant.Select_Parameter}`]
                          : `${constant.Select_Parameter}`
                      }
                    >
                      <Select
                        defaultValue={parameterList ? Object.keys(parameterList)[0] : ""}
                        style={{ width: 160 }}
                        value={this.state.parameterType}
                        placeholder="Select Tag Type"
                        onChange={this.handleParameterType}
                      >
                        {Object.keys(parameterList).map(parameterType => (
                          <Option key={parameterType}>
                            {
                                            translationCache &&
                                              translationCache[`${parameterType}`]
                                              ? translationCache[`${parameterType}`]
                                              : `${parameterType}`
                                          }
                            </Option>
                        ))}
                      </Select>
                      {getFieldDecorator("Select Parameter (s)", {
                        initialValue: this.state.selectedParam,

                      })(
                        <Select
                          showSearch
                          mode="multiple"
                          style={{ width: 300 }}
                          value={selectedParam}
                          onChange={this.paramhandle}
                          placeholder={
                            translationCache &&
                              translationCache[`${constant.Select_Parameter}`] &&
                              translationCache[`${constant.Select_Parameter}`][
                              language
                              ]
                              ? translationCache[`${constant.Select_Parameter}`][
                              language
                              ]
                              : `${constant.Select_Parameter}`
                          }
                        >
                          {selectedDevice.map((device) => {
                            return (
                              <OptGroup label={device}>
                                {
                                  parameterListByType && Array.isArray(parameterListByType) &&
                                  parameterListByType.map((param) => {
                                    if (param.DeviceId === device) {
                                      return (
                                        <Option
                                          value={`${device}@@${param.ParameterCode}`}
                                        >
                                          {
                                            translationCache &&
                                              translationCache[`${param.ParameterName}`]
                                              ? translationCache[`${param.ParameterName}`]
                                              : `${param.ParameterName}`
                                          }
                                        </Option>
                                      );
                                    }
                                  })
                                }
                              </OptGroup>
                            );
                          })}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  {
                    type === "historic" ? (
                      <div>
                        {/* <Col span={6} offset={1}> */}
                        <Col className="gutter-row" span={6}>
                          <FormItem

                            className="trendsFormLabel"
                            label={
                              translationCache &&
                                translationCache[`${constant.Select_Range}`] &&
                                translationCache[`${constant.Select_Range}`]
                                ? translationCache[`${constant.Select_Range}`]
                                : `${constant.Select_Range}`
                            }
                          >
                            {getFieldDecorator("Select Range", {
                              initialValue: this.state.momentRange || "",
                              rules: [
                                {
                                  required: true,
                                  message: "Please Select DateRanges!",
                                },
                              ],
                            })(
                              <RangePicker
                                style={{ width: 350 }}
                                showTime={{ format: "HH:mm" }}
                                format="YYYY-MM-DD HH:mm"
                                placeholder={["Start DateTime", "End DateTime"]}
                                value={this.state.momentRange}
                                onChange={this.onChange}
                                onOk={this.onOk}
                                disabledDate={this.disabledDate}
                              />
                            )}
                          </FormItem>
                        </Col>
                        {/* <Col span={6} offset={1}> */}
                        <Col className="gutter-row" span={6}>
                          <FormItem
                            className="trendsFormLabel"
                            label={
                              translationCache &&
                                translationCache[`${constant.Interval}`] &&
                                translationCache[`${constant.Interval}`]
                                ? translationCache[`${constant.Interval}`]
                                : `${constant.Interval}`
                            }
                          >
                            {getFieldDecorator("Select Interval", {
                              initialValue: selectedInterval,
                              rules: [
                                { required: true, message: "Please Select Interval!" },
                              ],
                            })(
                              <Select
                                // mode="multiple"
                                // style={{}}
                                // style={{ width: 350 }}
                                style={{ width: 350 }}
                                placeholder={
                                  translationCache &&
                                    translationCache[`${constant.Interval}`] &&
                                    translationCache[`${constant.Interval}`]
                                    ? translationCache[`${constant.Interval}`][
                                    language
                                    ]
                                    : `${constant.Interval}`
                                }
                                value={selectedInterval}
                                onChange={(value) => { this.setState({ selectedInterval: value }) }}
                              >
                                {IntervalData.map((item) => {
                                  return (<Option value={item.value}>
                                    {item.key}
                                  </Option>)
                                })}
                              </Select>
                            )}
                          </FormItem>
                        </Col>
                      </div>
                    ) : null
                  }
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  {
                    type === "timeRange" ? (
                      <div>
                        <Col className="gutter-row" span={6}>
                          <FormItem
                            className="trendsFormLabel"
                            label={
                              translationCache &&
                                translationCache[`${constant.Select_Range}`] &&
                                translationCache[`${constant.Select_Range}`]
                                ? translationCache[`${constant.Select_Range}`]
                                : `${constant.Select_Range}`
                            }
                          >
                            {getFieldDecorator("Select Range", {
                              initialValue: this.state.momentRange || "",
                              rules: [
                                {
                                  required: true,
                                  message: "Please Select DateRanges!",
                                },
                              ],
                            })(
                              <RangePicker
                                // style={{ width: 350 }}
                                showTime={{ format: "HH:mm" }}
                                format="YYYY-MM-DD HH:mm"
                                placeholder={["Start DateTime", "End DateTime"]}
                                value={this.state.momentRange}
                                onChange={this.onChange}
                                onOk={this.onOk}
                                disabledDate={this.disabledDate}
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <FormItem
                            className="trendsFormLabel"
                            label={
                              translationCache &&
                                translationCache[`${constant.Select_Range_Comparision}`] &&
                                translationCache[`${constant.Select_Range_Comparision}`]
                                ? translationCache[`${constant.Select_Range_Comparision}`]
                                : `${constant.Select_Range_Comparision}`
                            }
                          >
                            {getFieldDecorator("Select Range Comparision", {
                              initialValue: this.state.mmRangeComparision || "",
                              rules: [
                                {
                                  required: true,
                                  message: "Please Select DateRanges!",
                                },
                              ],
                            })(
                              <RangePicker
                                style={{ width: 350 }}
                                showTime={{ format: "HH:mm" }}
                                format="YYYY-MM-DD HH:mm"
                                placeholder={["Start DateTime", "End DateTime"]}
                                value={this.state.mmRangeComparision}
                                onChange={this.onChangesectimerange}
                                // onPanelChange = {(date)=>this.onChangesectimerange(date)}
                                // onOpenChange = {(date)=>this.onChangesectimerange(date)}
                                onOk={this.onOk}
                                disabledDate={this.disabledDate}
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <FormItem
                            className="trendsFormLabel"
                            label={
                              translationCache &&
                                translationCache[`${constant.Interval}`] &&
                                translationCache[`${constant.Interval}`]
                                ? translationCache[`${constant.Interval}`]
                                : `${constant.Interval}`
                            }
                          >
                            {getFieldDecorator("Select Interval", {
                              initialValue: selectedInterval,
                              rules: [
                                { required: true, message: "Please Select Interval!" },
                              ],
                            })(
                              <Select
                                // mode="multiple"
                                // style={{}}
                                style={{
                                  width: 350,
                                  marginRight: "500%"
                                }}
                                placeholder={
                                  translationCache &&
                                    translationCache[`${constant.Interval}`] &&
                                    translationCache[`${constant.Interval}`]
                                    ? translationCache[`${constant.Interval}`][
                                    language
                                    ]
                                    : `${constant.Interval}`
                                }
                                value={selectedInterval}
                                onChange={(value) => { this.setState({ selectedInterval: value }) }}
                              >
                                {IntervalData.map((item) => {
                                  return (<Option value={item.value}>
                                    {item.key}
                                  </Option>)
                                })}
                              </Select>
                            )}
                          </FormItem>
                        </Col>
                      </div>
                    ) : null
                  }
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  {
                    type === "timeWithSelectionType" ? (
                      <div>
                        <Col className="gutter-row" span={6}>
                          <FormItem
                            className="trendsFormLabel"
                            label={
                              translationCache &&
                                translationCache[`${constant.Select_Range}`] &&
                                translationCache[`${constant.Select_Range}`]
                                ? translationCache[`${constant.Select_Range}`]
                                : `${constant.Select_Range}`
                            }
                          >
                            {getFieldDecorator("Select Range", {
                              initialValue: this.state.momentRange || "",
                              rules: [
                                {
                                  required: true,
                                  message: "Please Select DateRanges!",
                                },
                              ],
                            })(
                              <RangePicker
                                style={{ width: 350 }}
                                showTime={{ format: "HH:mm" }}
                                format="YYYY-MM-DD HH:mm"
                                placeholder={["Start DateTime", "End DateTime"]}
                                value={this.state.momentRange}
                                onChange={this.onChange}
                                onOk={this.onOk}
                                disabledDate={this.disabledDate}
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <FormItem
                            className="trendsFormLabel"
                            label={
                              translationCache &&
                                translationCache[`${constant.Interval}`] &&
                                translationCache[`${constant.Interval}`]
                                ? translationCache[`${constant.Interval}`]
                                : `${constant.Interval}`
                            }
                          >
                            {getFieldDecorator("Select Interval", {
                              initialValue: selectedInterval,
                              rules: [
                                { required: true, message: "Please Select Interval!" },
                              ],
                            })(
                              <Select
                                // mode="multiple"
                                // style={{}}
                                style={{ width: 350 }}
                                placeholder={
                                  translationCache &&
                                    translationCache[`${constant.Interval}`] &&
                                    translationCache[`${constant.Interval}`]
                                    ? translationCache[`${constant.Interval}`][
                                    language
                                    ]
                                    : `${constant.Interval}`
                                }
                                value={selectedInterval}
                                onChange={(value) => { this.setState({ selectedInterval: value }) }}
                              >
                                {IntervalData.map((item) => {
                                  return (<Option value={item.value}>
                                    {item.key}
                                  </Option>)
                                })}
                              </Select>
                            )}
                          </FormItem>
                        </Col>
                      </div>
                    ) : null
                  }
                </Row>
              </Form>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    this.getData();
                  }}
                >
                  {translationCache &&
                    translationCache[`${constant.Generate_Trend}`] &&
                    translationCache[`${constant.Generate_Trend}`]
                    ? translationCache[`${constant.Generate_Trend}`]
                    : `${constant.Generate_Trend}`}
                </Button>
                {!singleView ? (
                  <Button
                    type="primary"
                    style={{ marginLeft: "30px" }}

                    onClick={() => {
                      this.setState({
                        currentTab: "day"
                      })
                      this.handleView();
                    }}
                  >
                    {translationCache &&
                      translationCache[`${constant.Single_View}`] &&
                      translationCache[`${constant.Single_View}`]
                      ? translationCache[`${constant.Single_View}`]
                      : `${constant.Single_View}`}
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    style={{ marginLeft: "30px" }}
                    onClick={() => {
                      this.handleView();
                    }}
                  >
                    {translationCache &&
                      translationCache[`${constant.Multiple_View}`] &&
                      translationCache[`${constant.Multiple_View}`]
                      ? translationCache[`${constant.Multiple_View}`]
                      : `${constant.Multiple_View}`}
                  </Button>
                )}
                <Button
                  type="primary"
                  style={{ marginLeft: "30px" }}
                  onClick={() => {
                    this.handleBookmarkList();
                  }}
                >
                  {translationCache &&
                    translationCache[`${constant.Bookmark_List}`] &&
                    translationCache[`${constant.Bookmark_List}`]
                    ? translationCache[`${constant.Bookmark_List}`]
                    : `${constant.Bookmark_List}`}
                </Button>
              </div>
            </div>

            {trendData.length > 0 ? (
              <div>
                {this.state.editBookmark ? (
                  <Button
                    type="primary"
                    style={{
                      float: "right",
                      // marginLeft: "1%",
                      // marginRight: "1%",
                      marginRight: "-11px"
                    }}
                    onClick={() => {
                      this.updateBookmark();
                    }}
                  >
                    Update Bookmark
                  </Button>
                ) : (
                  <div>
                    <Form>
                      <Row>
                        <Col span={5} offset={14}>
                          <FormItem
                            className="trendsFormLabel"
                            label={
                              translationCache &&
                                translationCache[
                                `${constant.Enter_Bookmark_Name}`
                                ] &&
                                translationCache[`${constant.Enter_Bookmark_Name}`][
                                language
                                ]
                                ? translationCache[
                                `${constant.Enter_Bookmark_Name}`
                                ]
                                : `${constant.Enter_Bookmark_Name}`
                            }
                          >
                            {getFieldDecorator("Enter Bookmark Name", {
                              rules: [
                                { message: "Please Provide Bookmark Name!" },
                              ],
                            })(
                              <Input
                                placeholder={
                                  translationCache &&
                                    translationCache[
                                    `${constant.Enter_Bookmark_Name}`
                                    ] &&
                                    translationCache[
                                    `${constant.Enter_Bookmark_Name}`
                                    ]
                                    ? translationCache[
                                    `${constant.Enter_Bookmark_Name}`
                                    ]
                                    : `${constant.Enter_Bookmark_Name}`
                                }
                                value={this.state.bookmarkName}
                                onChange={(e) => {
                                  this.hableInput(e.target.value);
                                }}
                              />
                            )}
                          </FormItem>
                        </Col>

                        <Col span={4}>
                          <Button
                            type="primary"
                            style={{
                              marginTop: "47px",
                              marginLeft: "10%",
                              marginRight: "1%",
                            }}
                            onClick={() => {
                              this.saveBookmark();
                            }}
                          >
                            {translationCache &&
                              translationCache[`${constant.Save_Bookmark}`] &&
                              translationCache[`${constant.Save_Bookmark}`][
                              language
                              ]
                              ? translationCache[`${constant.Save_Bookmark}`][
                              language
                              ]
                              : `${constant.Save_Bookmark}`}
                          </Button>

                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}

                {
                  this.state.parameterType !== "Status" ?
                    <div className="radioGroup" style={{ align: "right", marginTop: "18px", fontSize: "18px", fontWeight: "bold", color: "#000" }}>
                      <Radio.Group
                        onChange={this.onChangeToggle}
                        defaultValue={this.state.viewType}
                      >
                        <Radio value={"graph"}>Generate Data in Graph</Radio>
                        <Radio value={"table"}>Generate Data in Table</Radio>
                      </Radio.Group>
                    </div>
                    : null
                }
                {
                  this.state.type === "timeWithSelectionType"
                    ?
                    <div className="radioGroup" style={{ align: "right", marginTop: "18px", fontSize: "18px", fontWeight: "bold", color: "#000" }}>
                      <Radio.Group defaultValue={this.state.currentTab} onChange={this.onRadioChange} style={{ marginLeft: '20px' }}>
                        <Radio.Button value="day">Daily</Radio.Button>
                        <Radio.Button value="week">Week</Radio.Button>
                        <Radio.Button value="month">Month</Radio.Button>
                        <Radio.Button value="year">Year</Radio.Button>
                      </Radio.Group>
                    </div>
                    : ""
                }

              </div>

            ) : null}

            {singleView ?
              (
                <div
                  style={{
                    height: "55vh",
                    marginTop: "40px",
                  }}
                >
                  {loading === true ? (
                    <div
                      style={{
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      <h2 style={{ color: "white" }}>
                        <Spin
                          spinning={loading}
                          tip="Loading..."
                          size="large"
                        ></Spin>
                      </h2>
                    </div>
                  ) : trendData.length > 0 ? (
                    viewType == "graph" && this.state.parameterType !== "Status" ? (

                      <React.Fragment>

                        <TrendSlider
                          panelData={{
                            graph: "trends",
                            trendData: trendData,
                            tunedData: this.state.dataToTrendSlider,
                            colors: color,
                            linetype: "",
                            update: this.state.update,
                            type
                          }}
                        />
                      </React.Fragment>
                    )
                      : (
                        <div
                          style={{
                            height: "55vh",
                            marginTop: "40px",
                          }}
                        >
                          <TrendTable
                            panelData={{
                              graph: "trends",
                              trendData: trendData,
                              colors: color,
                              linetype: "",
                              update: this.state.update,

                              graph: "trends",
                              trendData: trendData,
                              tunedData: this.state.dataToTrendSlider,
                              colors: color,
                              linetype: "",
                              update: this.state.update,
                              type
                            }}
                            className="trendTable"
                            dataSource={trendData}
                            columns={columns}
                          />
                        </div>
                      )
                  ) : (
                    <h2 style={{ color: "white", textAlign: "center" }}>
                      Configure To View Trends
                    </h2>
                  )}
                </div>
              ) :
              viewType == "graph" && this.state.parameterType !== "Status" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "40px",
                    flexWrap: "wrap",
                  }}
                >
                  {trendData.map((graphData, i, tunedData) => {
                    return (
                      <div
                        style={{
                          width: "48%",
                          height: "48vh",
                          marginTop: "1%",
                        }}
                      >
                        {
                          graphData && Array.isArray(graphData) && graphData.length > 0 ?
                            <TrendSlider
                              panelData={{
                                graph: "trends",
                                trendData: graphData,
                                tunedData: this.state.dataToTrendSlider,
                                colors: [color[i]],
                                linetype: "",
                                update: this.state.update,
                                type
                              }}
                            /> :
                            <div
                              style={{
                                width: "100%",
                                height: "48vh",
                                backgroundColor: "white"
                              }}
                            >
                              <NoRecordComponent />
                            </div>
                        }
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Row>
                  {
                    trendData && Array.isArray(trendData) &&
                    trendData.map((item, index) => {
                      return (
                        <Col span={10} offset={1}>
                          <div
                            style={{
                              marginTop: "40px",
                            }}
                          >
                            <TrendTable
                              panelData={{
                                graph: "trends",
                                trendData: item,
                                tunedData: this.state.dataToTrendSlider,
                                colors: color,
                                linetype: "",
                                update: this.state.update,
                                type
                              }}
                              className="trendTable"
                              dataSource={item}
                              columns={columns}
                            />
                          </div>
                        </Col>)
                    })}
                </Row>
              )
            }
          </div>
          <BookmarkListDrawer
            listView={this.state.listView}
            onCloseList={this.onCloseList}
            editBookmark={this.editBookmark}
            list={this.state.bookmarkList}
            deleteBookMark={this.deleteBookmark}
            viewBookmark={this.viewBookmark}
          />
        </React.Fragment>
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
});
export default connect(mapStateToProps)(Form.create()(Trends));
