import React from "react";

import {  Icon } from 'antd';
import "../CSS/style.css"

class ErrorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mbdObject: {},
    };
  }

  getSelectedMBD = (selectedMDB) => {
    this.setState({
      selectedMDB,
    });
  };

  render() {
    return (

      <div class="warning-placeholder">
        <div class="ant-empty ant-empty-normal">
          <div class="ant-empty-image">

            <Icon type="warning" theme="filled" style={{ fontSize: "5em", marginBottom: "2px", color: "grey" }} />
          </div>

        </div>
        <p style={{ color: "grey", fontSize: "17px", fontWeight: "bold" }} class="ant-empty-description">ERROR IN API</p>
      </div>

    );

  }
}

export default ErrorComponent;
