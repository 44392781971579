import React from 'react'
import axios from 'axios'
import { Table, Form, Col, Input, Drawer, Button, Card, Row, message, Icon, Popconfirm, Modal, Tree } from 'antd'
import { constant } from "../../Settings/Constants"
import history from '../../../commons/history'
import localStorage from '../../../utils/localStorage'
import { DrawerFooter, StyledTable, lightTheme, StyledDashboard } from './style'

const { TreeNode } = Tree;


class Hierarchy extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            datasource: [],
            editObj: {},
            treeData: [],
            modalVisible: false,
        }
    }

    componentWillMount() {
        this._getHierarchyList()
    }

    _getHierarchyList = () => {
        const accessToken = localStorage.get('accessToken')
        let headers = {
            method: 'GET',
            url: `/api/hierarchymodule/mainHierarchyList`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
        axios(headers)
            .then(({ data }) => {
                if (Array.isArray(data)) {
                    this.setState({ datasource: data })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    openModal = () => {
        this.props.form.resetFields()
        this.setState({
            visible: true,
            editObj: {}
        });
    };

    _cancel = () => {
        this.setState({
            visible: false,
        });
    };

    editHierarchy = (editObj) => {
        this.setState({
            editObj,
            visible: true,
        })
    }

    _createHierarchy = (e) => {
        e.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (!error) {
                this._createHierarchyAPI(values)
            }
        })
    };


    _updateHierarchy = (e) => {
        e.preventDefault();
        let { editObj } = this.state
        this.props.form.validateFields((error, values) => {
            if (!error) {
                let updateObject = {
                    ...values,
                    Id: editObj.Id
                }
                this._updateHierarchyAPI(updateObject)
            }
        })
    };

    _createHierarchyAPI = (createObject) => {
        const accessToken = localStorage.get('accessToken')
        let orgCreateHeaders = {
            method: 'POST',
            url: '/api/hierarchymodule/mainHierarchyCreate',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: createObject
        }
        axios(orgCreateHeaders)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    message.success("Hierarchy Saved")
                    this._getHierarchyList()
                    this.setState({ visible: false })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    _updateHierarchyAPI = (updateObject) => {
        const accessToken = localStorage.get('accessToken')
        let orgUpdateHeaders = {
            method: 'POST',
            url: `/api/hierarchymodule/mainHierarchyUpdate`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: updateObject
        }
        axios(orgUpdateHeaders)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    message.success("Hierarchy Updated")
                    this._getHierarchyList()
                    this.setState({ visible: false })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    _deleteHierarchy = (payload) => {
        const accessToken = localStorage.get('accessToken')
        let orgDeleteHeaders = {
            method: 'POST',
            url: `/api/hierarchymodule/mainHierarchyDelete`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: payload
        }
        axios(orgDeleteHeaders)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    message.success("Hierarchy Deleted")
                    this._getHierarchyList()
                    this.setState({ visible: false })
                }
            })
            .catch(function (error) {
                console.log(error)
            })

    }

    openHierarchyForm = (record) => {
        history.push(`/rubus/settingsPage/Hierarchy/${record.Id}`)
    }

    _getHierarchyLevels = (record) => {
        this.setState({ modalVisible: true });
        let payload = {
            "HierarchyId": record.Id
        }
        const accessToken = localStorage.get("accessToken");
        let headers = {
            method: "POST",
            url: `/api/hierarchymodule/getHierarchyLevels`,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            data: payload
        };
        axios(headers)
            .then(({ data }) => {
                if (Array.isArray(data)) {
                    this.setState({ treeData: data, modalVisible: true });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    renderTreeNodes = (data) =>
        data &&
        Array.isArray(data) &&
        data.map((item) => {
            if (item.children) {

                return (
                    <TreeNode title={item.Name} key={item.Id} autoExpandParent={true} dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode key={item.Id} title={item.Name} {...item} />;
        });

    onOk = () => {
        this.setState({ modalVisible: false })
    }
    translation = (keyword) => {
        let { translationCache } = this.props
        return translationCache &&
          translationCache[keyword] ?
          translationCache[keyword] : keyword
      }
    render() {
        let { getFieldDecorator } = this.props.form
        let { datasource, visible, editObj, modalVisible, treeData } = this.state

        const columns = [{
            title: this.translation(constant.Hierarchy_Name),
            dataIndex: 'Name',
        }, {
            title: this.translation(constant.Hierarchy_Code),
            dataIndex: 'Code',
        }, {
            title: this.translation(constant.Action),
            render: (text, record) =>
                <div>
                    <button type='button' onClick={() => this.editHierarchy(record)} style={{ marginRight: '10px' }} className='ant-btn' >
                        <Icon type='edit' />
                    </button>
                    <Popconfirm
                        title={this.translation(constant.Are_you_sure_delete_Hierarchy)}
                        onConfirm={e => {
                            e.stopPropagation()
                            this._deleteHierarchy(record)
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button type='button' className='ant-btn'>
                            <Icon
                                type="delete"
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                            />
                        </button>
                    </Popconfirm>
                    <button type='button' onClick={() => this.openHierarchyForm(record)} style={{ marginLeft: '10px' }} className='ant-btn' >
                      {this.translation(constant.Click_to_redirect)} 
                    </button>
                    <button type='primary' className='ant-btn' style={{ marginLeft: '10px' }} onClick={() => this._getHierarchyLevels(record)}>
                        {this.translation(constant.View)}
                    </button>
                </div>
        }
        ]

        return (
            <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
                <Modal visible={modalVisible} onOk={this.onOk} onCancel={this.onOk}>
                    <Tree defaultExpandAll showLine >
                        treeData && Array.isArray(treeData) && treeData.length != 0 ?
                        {this.renderTreeNodes(treeData)}
                        : null
                    </Tree>
                </Modal>
                <Card bordered={false} >

                    <Drawer
                        title={this.translation(constant.Create_Hierarchy)}
                        visible={visible}
                        width={600}
                        closable={false}
                        onClose={this._cancel}
                    >
                        <Form layout="vertical">
                            <Row gutter={16}>
                                <Col span={24}>

                                    <Form.Item label= {this.translation(constant.Hierarchy_Name)}>
                                        {getFieldDecorator("Name", {
                                            initialValue: editObj.Name || '',
                                        })(
                                            <Input placeholder="Please enter Hierarchy name" />
                                        )}
                                    </Form.Item>

                                    <Form.Item label= {this.translation(constant.Hierarchy_Code)}>
                                        {getFieldDecorator("Code", {
                                            initialValue: editObj.Code || '',

                                        })(
                                            <Input placeholder="please enter Hierarchy Code " />
                                        )}
                                    </Form.Item>

                                </Col>
                            </Row>
                        </Form>
                        <DrawerFooter>
                            <Button onClick={this._cancel} style={{ marginRight: 8, }}>
                                {this.translation(constant.Cancel)}</Button>
                            {
                                !editObj.Id ?
                                    <Button type="primary" onClick={this._createHierarchy}>{this.translation(constant.Create_Hierarchy)}</Button>
                                    :
                                    <Button type="primary" onClick={this._updateHierarchy}>{this.translation(constant.Update_Hierarchy)}</Button>
                            }
                        </DrawerFooter>
                    </Drawer>
                    <Button type="primary" size={"large"} style={{ marginBottom: '10px', float: "right" }} onClick={this.openModal}>
                     {this.translation(constant.Add_Hierarchy)}
                        </Button>
                    <StyledTable theme={lightTheme}>
                        <Table
                            columns={columns}
                            dataSource={datasource}
                            pagination={
                                datasource.length > 10
                                    ? {
                                        pageSize: "10"
                                    }
                                    : false
                            }
                        />
                    </StyledTable>
                </Card>
            </StyledDashboard>
        )
    }
}
export default Form.create()(Hierarchy)
