import React, { Component, Fragment } from "react";
import { Input, Form, Select, Button, message} from "antd";
import "./reportConfig.css";

let { Option } = Select;


class WidgetCustomization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editObj: {},
      activeKey: [],
      dragDisabled: false,
      widgetProperties: props.widgetProperties || [],
      tableMaster: [],
      inputFields: [],
      payloadFields: [],
    };
    this.columns = {};
  }
  componentDidUpdate(prevprops) {
    if (this.props.widgetProperties !== prevprops.widgetProperties) {
      this.setState({
        widgetProperties: this.props.widgetProperties,
      });
    }
  }

  handleFieldChange(value, colName) {
    const { widgetProperties } = this.state;
    widgetProperties[colName] = value;

    this.setState({
      widgetProperties,
    });
    this.props.jsonSaveObject(widgetProperties, "selectionWidget");
  }

  handleChange(value, keyName, key) {
    const { widgetProperties } = this.state;
    widgetProperties[keyName][key] = value;

    this.setState({
      widgetProperties,
    });
    this.props.jsonSaveObject(widgetProperties, "selectionWidget");
  }

  widgetSave = (e) => {
    const { widgetProperties } = this.state;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.jsonSaveObject(widgetProperties, "selectionWidget");
        message.success("Widget Validated Successfully");
        this.setState({
          activeKey: [],
        });
      }
    });
  };

  callback = (key) => {
    if (key && key.length <= 1) {
      this.setState({
        activeKey: key,
      });
    } else if (key.length > 1) {
      key = key[key.length - 1];
      this.setState({
        activeKey: [key],
      });
    }
  };
  handlePayloadChange = (key, event, objectkey) => {
    const { widgetProperties } = this.state;
    if (objectkey === "key") {
      widgetProperties.payload[event.target.value] =
        widgetProperties.payload[key];
      delete widgetProperties.payload[key];

      this.setState({ widgetProperties });
    } else if (objectkey === "value") {
      if (typeof widgetProperties.payload[key] === "object") {
        widgetProperties.payload[key] = {
          "format": event.target.value,
        };
        this.setState({ widgetProperties });
      } else if (typeof widgetProperties.payload[key] !== "object") {
        widgetProperties.payload[key] = event.target.value;
        this.setState({ widgetProperties });
      }
    }

    this.props.jsonSaveObject(widgetProperties, "selectionWidget");
  };
  addPayload = (e) => {
    let inputFields = [
      {
        reportKey: "",
        reportValue: "",
      },
    ];

    this.setState({ inputFields });
  };
  handleAddFields = () => {
    const values = this.state.inputFields;
    values.push({ reportKey: "", reportValue: "" });
    this.setState(values);
  };
  handleRemoveFields = (index) => {
    const values = this.state.inputFields;
    values.splice(index, 1);
    this.setState(values);
  };
  handleMainPayloadRemoveFields = (index) => {
    let { widgetProperties } = this.state;
    let { payload } = widgetProperties;

    delete payload[index];

    this.setState({ widgetProperties });
  };
  handlePayloadRemoveFields = (index) => {
    const values = this.state.payloadFields;
    values.splice(index, 1);
    this.setState(values);
  };
  UpdatePayload = () => {
    let { payloadFields, widgetProperties } = this.state;
    payloadFields.map((item, index) => {
      widgetProperties.payload[item.reportKey] = item.reportValue;
    });

    this.setState({ payloadFields: [] });
    this.setState({ widgetProperties });
    this.props.jsonSaveObject(widgetProperties, "selectionWidget");
  };
  handlePayloadInputChange = (index, event) => {
    const { payloadFields } = this.state;

    payloadFields[index][event.target.name] = event.target.value;

    this.setState({ payloadFields });
    // payloadFields.map((item, index) => {
    //   widgetProperties.payload[item.reportKey] = item.reportValue;
    // });
    // this.setState({ widgetProperties });

    // this.props.jsonSaveObject(widgetProperties, "api");
  };
  handleAddPayloadFields = () => {
    let { payloadFields } = this.state;

    // apiProperties = {
    //   ...apiProperties,
    //   ["payload"]:{
    //   ...( apiProperties["payload"] ? apiProperties["payload"] : {}),
    //  "":""
    // },

    payloadFields.push({
      reportKey: "",
      reportValue: "",
    });

    this.setState({ payloadFields });
  };

  handleInputChange = (index, event) => {
    const inputFields = this.state.inputFields;
    inputFields[index][event.target.name] = event.target.value;
    this.setState({ inputFields });
    const { widgetProperties } = this.state;
    inputFields.map((item, index) => {
      widgetProperties.payload[item.reportKey] = item.reportValue;
    });
    this.setState({ widgetProperties });
    this.props.jsonSaveObject(widgetProperties, "selectionWidget");
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { widgetProperties, payloadFields, inputFields } = this.state;
    let item = widgetProperties;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <div style={{ marginBottom: "50px" }}>
        {widgetProperties ? (
          <div>
            <Form {...formItemLayout}>
              <Form.Item label="DisplayWidget :">
                <Select
                  style={{ width: "250px" }}
                  defaultValue={item.displayWidget}
                  onSelect={(value) =>
                    this.handleFieldChange(value, "displayWidget")
                  }
                >
                  <Option value="true">TRUE</Option>
                  <Option value="false">FALSE</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Widget :">
                {getFieldDecorator("widget", {
                  initialValue: item.widget,
                  rules: [{ required: true, message: "Please Select Widget!" }],
                })(
                  <Select
                  style={{ width: "250px" }}
                    onSelect={(value) =>
                      this.handleFieldChange(value, "widget")
                    }
                  >
                    <Option value="date-picker">DATE-PICKER</Option>
                    <Option value="month-picker">MONTH-PICKER</Option>
                    <Option value="year-picker">YEAR-PICKER</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Placeholder :">
                <Input
                 style={{ width: "250px" }}
                  defaultValue={item.placeholder}
                  onChange={(e) =>
                    this.handleFieldChange(e.target.value, "placeholder")
                  }
                ></Input>
              </Form.Item>
              <Form.Item label="Format :">
                {getFieldDecorator("format", {
                  initialValue: item.format,
                  rules: [
                    { required: true, message: "Please Provide Format!" },
                  ],
                })(
                  <Select
                    style={{ width: "250px" }}
                    value={item.format}
                    // mode="tags"
                    onSelect={(value) =>
                      this.handleFieldChange(value, "format")
                    }
                  >
                    <Option value="YYYYY-MMMM">YYYYY-MMMM</Option>
                    <Option value="YY-MM-DD">YYYY-MM-DD</Option>
                    <Option value="MM-DD-YY">MM-DD-YY</Option>
                    <Option value="DD-MM-YY">DD-MM-YY</Option>
                    <Option value="YY-MM-DD">YY-MM-DD</Option>
                  </Select>
                )}
              </Form.Item>

              <Form.Item label="Payload :">
                {Object.entries(widgetProperties.payload).length > 0 &&
                this.props.currentTab === "Edit Report JSON" ? (
                  Object.entries(widgetProperties.payload).map(
                    ([key, value], index) => (
                      <div>
                        {" "}
                        <input
                          type="text"
                          className="form-control"
                          id="reportKey"
                          name="reportKey"
                          value={key}
                          onChange={(e) =>
                            this.handlePayloadChange(key, e, "key")
                          }
                        />
                        {/* <label htmlFor="reportName">Last Name</label> */}
                        <input
                          type="text"
                          className="form-control"
                          id="reportValue"
                          name="reportValue"
                          value={
                            typeof value === "object" ? value.format : value
                          }
                          onChange={(e) =>
                            this.handlePayloadChange(key, e, "value")
                          }
                        />
                        <Button
                          className="btn-link"
                          type="Button"
                          onClick={() =>
                            this.handleMainPayloadRemoveFields(key)
                          }
                        >
                          -
                        </Button>
                        {Object.keys(widgetProperties.payload).length - 1 ===
                        index ? (
                          <Button
                            className="btn-link"
                            type="Button"
                            onClick={() => this.handleAddPayloadFields()}
                          >
                            +
                          </Button>
                        ) : null}
                      </div>
                    )
                  )
                ) : (
                  <div>
                    <Button onClick={(e) => this.addPayload(e)}>
                      addPayload
                    </Button>
                    {inputFields.length > 0 &&
                      inputFields.map((item, index) => (
                        <Fragment key={`${item}~${index}`}>
                          <div>
                            {/* <label htmlFor="reportKey">First Name</label> */}
                            <input
                              type="text"
                              className="form-control"
                              id="reportKey"
                              name="reportKey"
                              value={item.reportKey}
                              onChange={(e) => this.handleInputChange(index, e)}
                            />

                            {/* <label htmlFor="reportName">Last Name</label> */}
                            <input
                              type="text"
                              className="form-control"
                              id="reportValue"
                              name="reportValue"
                              value={item.reportValue}
                              onChange={(e) => this.handleInputChange(index, e)}
                            />

                            <Button
                              className="btn-link"
                              type="Button"
                              onClick={() => this.handleRemoveFields(index)}
                            >
                              -
                            </Button>
                            {inputFields.length - 1 === index ? (
                              <Button
                                className="btn-link"
                                type="Button"
                                onClick={() => this.handleAddFields()}
                              >
                                +
                              </Button>
                            ) : null}
                          </div>
                          {/* <div><Button onClick={() => this.submitPayload()}>Submit</Button></div> */}
                        </Fragment>
                      ))}
                  </div>
                )}
                {this.props.currentTab === "Edit Report JSON" &&
                payloadFields &&
                payloadFields.length > 0
                  ? payloadFields.map((item, index) => (
                      <Fragment key={`${item}~${index}`}>
                        <div>
                          {/* <label htmlFor="reportKey">First Name</label> */}
                          <input
                            type="text"
                            className="form-control"
                            id="reportKey"
                            name="reportKey"
                            value={item.reportKey}
                            onChange={(e) =>
                              this.handlePayloadInputChange(index, e)
                            }
                          />

                          {/* <label htmlFor="reportName">Last Name</label> */}
                          <input
                            type="text"
                            className="form-control"
                            id="reportValue"
                            name="reportValue"
                            value={item.reportValue}
                            onChange={(e) =>
                              this.handlePayloadInputChange(index, e)
                            }
                          />

                          <Button
                            className="btn-link"
                            type="Button"
                            onClick={() =>
                              this.handlePayloadRemoveFields(index)
                            }
                          >
                            -
                          </Button>
                          {payloadFields.length - 1 === index ? (
                            <Button
                              className="btn-link"
                              type="Button"
                              onClick={() => this.handleAddPayloadFields()}
                            >
                              +
                            </Button>
                          ) : null}
                        </div>

                        {/* <div><Button onClick={() => this.submitPayload()}>Submit</Button></div> */}
                      </Fragment>
                    ))
                  : null}
              </Form.Item>
            </Form>
            <div className="validButton">
              <Button
                type="primary"
                // style={{ marginLeft: "60x" }}
                onClick={(e) => this.widgetSave(e)}
              >
                ValidateJson
              </Button>{" "}
              {payloadFields.length > 0 ? (
                <Button
                  type="primary"
                  // style={{ marginLeft: "60px" }}
                  onClick={(e) => this.UpdatePayload(e)}
                >
                  UpdatePayload
                </Button>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
export default Form.create()(WidgetCustomization);
