import { createSelector } from 'reselect'

const layoutList = state => {
  return state.get('layout').get('layout')
}

const getUserProfile = () =>
  createSelector(layoutList, state => {
    return state.get('loggedInProfile')
  })
  
const getMenuCollapse = () =>
  createSelector(layoutList, state => {
    return state.get('menuCollapse')
  })

const getCurrentSite = () =>
  createSelector(layoutList, state => {
    return state.get('currentSite')
  })

const getMenuHierarchy = () =>
  createSelector(layoutList, state => {
    return state.get('menuHierarchy')
  })

const getLandingPage = () =>
  createSelector(layoutList, state => {
    return state.get('landingPage')
  })

const getUserDetails = () =>
  createSelector(layoutList, state => {
    return state.get('UserDetails')
  })

export {
  getUserProfile,
  getCurrentSite,
  getMenuHierarchy,
  getLandingPage,
  getUserDetails,
  getMenuCollapse
}
