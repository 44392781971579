import { types } from './types'
import { jwtToken } from '../../commons/jwtTokenExpiry'
import { takeLatest, call, put } from 'redux-saga/effects'

import layout from './layout.requests'
import history from '../../commons/history'
import localStorage from '../../utils/localStorage'
import { getLoggedInProfileSuccess, setMenuHierarchy, setLandingPage,setUserDetails } from './layout.duck'

export function * getUserProfileSaga () {
  const userProfileSagaSuccess = yield call(layout.getUserprofile) 
  
  if (userProfileSagaSuccess.name === 'JsonWebTokenError') {
    localStorage.clear() 
    history.push('/login')
    window.location.reload()
    jwtToken(userProfileSagaSuccess.message)
  } else if (userProfileSagaSuccess.message === 'User is not permitted to this resource') {
    jwtToken(userProfileSagaSuccess.message)
  } else if (userProfileSagaSuccess.statusCode === 400) {
    jwtToken(userProfileSagaSuccess.message)
  } else {
    if(localStorage.get("currentSite") === undefined && userProfileSagaSuccess && userProfileSagaSuccess.belongsTo && userProfileSagaSuccess.belongsTo.sites){
      let roleItems = userProfileSagaSuccess.belongsTo.sites[ Object.keys(userProfileSagaSuccess.belongsTo.sites)[0]].role
      let landingPage = userProfileSagaSuccess.belongsTo.sites[ Object.keys(userProfileSagaSuccess.belongsTo.sites)[0]].role.LandingPageId
      let hierarchy = userProfileSagaSuccess.belongsTo.sites[ Object.keys(userProfileSagaSuccess.belongsTo.sites)[0]].hierarchy
      let userDeatils=userProfileSagaSuccess.user
      let menuHierarchy = {
        ApplicationSettings: roleItems.ApplicationSettings,
        Modules: roleItems.Modules,
        Configuration: roleItems.Configuration,
        hierarchy
      }
      localStorage.set("currentSite", Object.keys(userProfileSagaSuccess.belongsTo.sites)[0])
      localStorage.set("modules",JSON.stringify(menuHierarchy))
      localStorage.set("role", roleItems.Name)
      localStorage.set("landingPage", landingPage)
      yield put(setMenuHierarchy(menuHierarchy))
      yield put(setLandingPage(landingPage))
      yield put(setUserDetails(userDeatils))
    }
    yield put(getLoggedInProfileSuccess(userProfileSagaSuccess))
    

  }
}

export default function * layoutSaga () {
  yield [
    takeLatest(types.GET_LOGGEDIN_PROFILE, getUserProfileSaga)
  ]
}
