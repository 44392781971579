import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { DatePicker, message } from 'antd'

import Slider from './Slider'
import { getChartDataWithoutSelection } from "../ChartCalls";
import {
    getCurrentLanguage,
    getTranslationCache,
} from "../../../../selectors/language";

import { DatePickerSlider, DatePickerIcon } from '../CSS/style'

let { RangePicker } = DatePicker
class DatePickerComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDate: moment(),
            endDate: moment(),
            graphprops: {
                ...props.graphprops,
                colors: ['orange', 'tomato', 'steelblue', 'green', 'pink', 'voilet', 'yellow', 'red', 'blue', 'gray'],
                linetype: '',
                startDate: moment().format('YYYY-MM-DD 00:00:00'),
                endDate: moment().format('YYYY-MM-DD 23:59:59'),
                currentDateData: [],
                disableNext: false,
                translationCache: props.translationCache || [],
                language: props.language || "English"
            } || {}
        };
    }

    requestCheck = true;
    abortController = new window.AbortController();

    disabledDate = (current) => {
        return current > moment().startOf('day').add(1, 'day');
    }

    async componentDidMount() {
        let json = []
        let { graphprops } = this.state
        json = await getChartDataWithoutSelection(graphprops, this.abortController);

        let graphpropsResult = {
            ...graphprops,
            currentDateData: json
        }
        this.setState({ graphprops: graphpropsResult })
    }

    previousDate = async () => {
        let { currentDate, endDate, graphprops } = this.state
        currentDate.subtract('days', 1)
        let graphpropsProps = {
            ...graphprops,
            startDate: currentDate.format('YYYY-MM-DD 00:00:00'),
            endDate: endDate.format('YYYY-MM-DD 23:59:59')
        }
        let chartData = await getChartDataWithoutSelection(graphpropsProps, this.abortController);
        let graphpropsResult = {
            ...graphpropsProps,
            currentDateData: chartData
        }
        this.setState({ currentDate, graphprops: graphpropsResult })

    }

    nextDate = async () => {
        let { currentDate, endDate, graphprops } = this.state

        if (endDate > moment().startOf('day')) {
            this.setState({ disableNext: true })
            message.warn("Cant select future dates")
            this.setState({ currentDate })

        } else {
            endDate.add('days', 1)
            let graphpropsProps = {
                ...graphprops,
                startDate: currentDate.format('YYYY-MM-DD 00:00:00'),
                endDate: endDate.format('YYYY-MM-DD 23:59:59')
            }
            let chartData = await getChartDataWithoutSelection(graphpropsProps, this.abortController);
            let graphpropsResult = {
                ...graphpropsProps,
                currentDateData: chartData
            }
            this.setState({ currentDate, endDate, graphprops: graphpropsResult, disableNext: false })
        }

    }

    onChange = async (currentDate) => {
        let { graphprops } = this.state
        let graphpropsProps = {
            ...graphprops,
            startDate: currentDate[0].format('YYYY-MM-DD 00:00:00'),
            endDate: currentDate[1].format('YYYY-MM-DD 23:59:59')
        }
        let chartData = await getChartDataWithoutSelection(graphpropsProps, this.abortController);
        let graphpropsResult = {
            ...graphpropsProps,
            currentDateData: chartData
        }
        this.setState({ currentDate: currentDate[0], endDate: currentDate[1], graphprops: graphpropsResult })
    }
    componentWillUnmount() {
        this.abortController.abort()

    }



    render() {
        return (
            <div style={{ height: '70%', marginTop: '40px' }}>
                <DatePickerSlider>
                    <DatePickerIcon type="right-square" theme="filled" onClick={this.nextDate} />
                    <RangePicker onChange={this.onChange} value={[this.state.currentDate, this.state.endDate]} disabledDate={this.disabledDate} />
                    <DatePickerIcon type="left-square" theme="filled" disabled onClick={this.previousDate} />
                </DatePickerSlider>
                <Slider graphprops={this.state.graphprops} />
            </div>
        );
    }



}

const mapStateToProps = createStructuredSelector({
    language: getCurrentLanguage(),
    translationCache: getTranslationCache()
})


export default connect(mapStateToProps)(DatePickerComponent);