import styled from "styled-components";
import { Select } from "antd";

export const StyledSelect = styled(Select)`
  width: 30%;
`;
export const StyledTheme = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 3px;
  border: 1px solid black;
`;
