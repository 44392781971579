import React from "react";
import moment from "moment";
import { Table, Radio, DatePicker, Dropdown, Icon, Button, Menu } from "antd";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {Tag} from "antd"
import { historicTableChartData } from "../ChartCalls";
import { tableTranslation } from "../../Utils/Adapter/translationAdapter";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { makeSelectThemeing } from "../../../../selectors/theme";

import "antd/dist/antd.css";
import { StyledTable, lightTheme, darkTheme,DatePickerSlider } from  '../CSS/style'
import { defaultRefreshTime } from '../constant.json'
import { Fragment } from "react";
import {rangleDualTableJson} from "../../Mock/MockJson";


class RangeDualTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns1: [],
      tabledata1: [],
      columns2: [],
      tabledata2: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime:moment(),
      errorInAPI: false,
      noDataInDB: false,
      currentTab: "today",
      currentDate: moment(),
      graphData:
        {
          ...props.graphData,
          date: moment().format("YYYY-MM-DD"),
          month: moment().format("MMMM"),
          year: moment().format("YYYY"),
          days: moment().daysInMonth(),
        } || {},
    };
  }

  columns1 = [];
  tabledata1 = [];
  columns2 = [];
  tabledata2 = [];

  config = {
    redirectTo: "counter",
  };

  timeTicket = null;

  rowUpdateInterval = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if(this.requestCheck){
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }
  disabledDate = (current) => {
    return (
      current >
      moment()
        .startOf("day")
        .add(1, "day")
    );
  };

  updateData = async (data, type) => {
    try {
      const columnNames = Object.keys(data[0]);
      if (type === "planned") {
        this.columns1 = [];
        this.tabledata1 = [];

        columnNames.map((value, key) => {
          if (value.length > 0) {
            this.columns1.push({
              title: value,
              dataIndex: value,
              key: value,
              render(text, record, key) {
                return {
                  props: {
                    // style: { background: Math.abs(key % 2) === 1 ? '#1a1a1a' : '#000' },
                  },
                  children: <text style={{ color: "grey" }}>{text}</text>,
                };
              },
            });
          }
        });

        data.map((dataval, dataindex) => {
          dataval.key = dataindex + 1;
          this.tabledata1.push(dataval);
        });

        let translationTable = await tableTranslation(
          this.columns1,
          this.props.translationCache,
          this.props.language
        );
        this.setState({
          columns1: translationTable,
          tabledata1: this.tabledata1,
        });
      } else if (type === "actual") {
        this.columns2 = [];
        this.tabledata2 = [];

        columnNames.map((value, key) => {
          if (value.length > 0) {
            this.columns2.push({
              title: value,
              dataIndex: value,
              key: value,
              render(text, record, key) {
                return {
                  props: {
                    style: {
                      background: Math.abs(key % 2) === 1 ? "#1a1a1a" : "#000",
                    },
                  },
                  children: <text style={{ color: "grey" }}>{text}</text>,
                };
              },
            });
          }
        });

        data.map((dataval, dataindex) => {
          dataval.key = dataindex + 1;
          this.tabledata2.push(dataval);
        });

        let translationTable = await tableTranslation(
          this.columns2,
          this.props.translationCache,
          this.props.language
        );
        this.setState({
          columns2: translationTable,
          tabledata2: this.tabledata2,
        });
      }
    } catch (e) {
      this.setState({
        columns1: [],
        tabledata1: [],
        columns2: [],
        tabledata2: [],
      });
    }
  };

  getData = async () => {
    this.requestCheck = false
    let { graphprops } = this.state;
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false 
    let data = {};
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      data = rangleDualTableJson;
      this.requestCheck = true
    } else {

    data = await historicTableChartData(graphprops,this.abortController);
    this.requestCheck = true

    if ((data && data.message) || (Array.isArray(data) && (data.length === 0 )&& 
    (graphprops.enableMock !== true))) {
  
      if (data.message) {
        errorInAPI = true
      } else if (data.length === 0) {
        noDataInDB = true
      }
  
    } else {
    if (data && data.planned !== undefined && data.actual !== undefined) {
      this.updateData(data.planned, "planned");
      this.updateData(data.actual, "actual");
     
    }
    this.setState({
      errorInAPI,
      noDataInDB
    })
  } 
    
  };
  
}

 


  onChange = async (date) => {
    let { graphprops } = this.state;
    let graphDataProps = {
      ...graphprops,
      date: date.format("YYYY-MM-DD"),
      days: moment().daysInMonth(),
    };
    this.setState({ currentDate: date, graphData: graphDataProps });
  };

  onRadioChange = (e) => {
    if (e.target.value === "month") {
      let currentYear = moment().format("YYYY");
      let currentMonth = moment().format("MMMM");
      this.handleMenuClick(currentMonth, "month");
      this.setState({
        currentTab: e.target.value,
        month: currentMonth,
        year: currentYear,
      });
    } else if (e.target.value === "year") {
      let currentYear = moment().year();
      this.handleMenuClick(currentYear, "year");
      this.setState({
        currentTab: e.target.value,
        year: currentYear,
      });
    } else if (e.target.value === "today") {
      this.onChange(moment());
      this.setState({
        currentTab: e.target.value,
      });
    } else {
      this.setState({
        currentTab: e.target.value,
      });
    }
  };

  getNavMenuItems = (menusData, parent) => {
    if (!menusData) {
      return [];
    }
    return menusData
      .map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
      })
      .filter((item) => item);
  };

  handleMenuClick = async (value, parent) => {
    let { graphData } = this.state;

    if (parent === "month") {
      let currentYear = moment().year();
      let month = moment(value, "MMMM").month();
      let graphDataProps = {
        ...graphData,
        date: null,
        month: moment([currentYear, month])
          .startOf("month")
          .format("MMMM"),
        days: moment([currentYear, month]).daysInMonth(),
      };
      this.setState({
        currentYear: value,
        graphData: graphDataProps,
        month: value,
      });
    } else if (parent === "year") {
      let graphDataProps = {
        ...graphData,
        date: null,
        month: null,
        year:
          moment([value]).year() === moment().year()
            ? moment().format("YYYY")
            : moment([value, 11])
                .endOf("month")
                .format("YYYY"),
        days:
          moment([value]).year() === moment().year()
            ? moment().daysInMonth()
            : moment([value, 11]).daysInMonth(),
      };
      this.setState({ graphData: graphDataProps, year: value });
    }
  };

  componentWillUnmount() {
    this.abortController.abort()
    clearInterval(this.timeTicket);
  }

  render() {
    let theme = this.props.selectedThemeing;
    let { currentTab } = this.state;
    const menuMonth = (
      <Menu
        onClick={(e) => this.handleMenuClick(e.key, "month")}
        selectedKeys={[this.state.month]}
      >
        {this.getNavMenuItems(moment.months(), "month")}
      </Menu>
    );
    const menuYear = (
      <Menu
        onClick={(e) => this.handleMenuClick(e.key, "year")}
        selectedKeys={[this.state.year]}
      >
        {this.getNavMenuItems([2021, 2020, 2019, 2018, 2017], "year")}
      </Menu>
    );

   const { errorInAPI, noDataInDB ,refreshDateTime} = this.state;
   let { graphprops } = this.props
    return (
      <Fragment>
        {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
         {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
            <NoRecordComponent/>
            : <ErrorComponent/>
            :
            <div style={{ height: "70%", marginTop: "40px" }}>
        <DatePickerSlider>
          <Radio.Group
            defaultValue={this.state.currentTab}
            onChange={this.onRadioChange}
            style={{ marginLeft: "20px" }}
          >
            <Radio.Button value="today">Today</Radio.Button>
            <Radio.Button value="month">Month</Radio.Button>
            <Radio.Button value="year">Year</Radio.Button>
          </Radio.Group>
          {currentTab === "today" ? (
            <DatePicker
              onChange={this.onChange}
              value={moment(this.state.currentDate)}
              disabledDate={this.disabledDate}
            />
          ) : null}
          {currentTab === "month" ? (
            <Dropdown overlay={menuMonth}>
              <Button style={{ marginRight: "8px" }}>
                {this.state.month} <Icon type="down" />
              </Button>
            </Dropdown>
          ) : null}
          {currentTab === "year" || currentTab === "month" ? (
            <Dropdown overlay={menuYear}>
              <Button style={{ marginRight: "8px" }}>
                {this.state.year} <Icon type="down" />
              </Button>
            </Dropdown>
          ) : null}
        </DatePickerSlider>
        {this.state.columns1.length > 0 ? (
          <StyledTable theme={theme === "light" ? lightTheme : darkTheme}>
            <Table
              columns={this.state.columns1}
              dataSource={this.state.tabledata1}
              scroll={{ x: this.state.tabledata1.length > 5 ? true : false }}
              className="TableWidget"
              style={{ padding: "0px 10px" }}
              pagination={{ position: "none" }}
            />
          </StyledTable>
        ) : null}

        {this.state.columns2.length > 0 ? (
          <StyledTable theme={theme === "light" ? lightTheme : darkTheme}>
            <Table
              columns={this.state.columns2}
              dataSource={this.state.tabledata2}
              scroll={{ x: this.state.tabledata2.length > 5 ? true : false }}
              className="TableWidget"
              style={{ padding: "0px 10px", marginTop: "25px" }}
              pagination={{ position: "none" }}
            />
          </StyledTable>
        ) : null}
      </div>
  }
      </Fragment>
      
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(RangeDualTable);
