import React from "react";
import axios from "axios";

import history from "../../../../commons/history";
import localStorage from "../../../../utils/localStorage";

class DefaultLandingPageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      landingPageList: [],
    };
  }
  componentWillMount() {
    this._getLandingPageList();
  }
  _getLandingPageList = () => {
    const accessToken = localStorage.get("accessToken");
    let orgHeaders = {
      method: "GET",
      url: `/api/landingPage/list`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ landingPageList: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  userBasedLandingPage = (landingPageID) => {
    let landingList = this.state.landingPageList;
    if (landingPageID && landingList && landingList.length > 0) {
      landingList.filter((landing) => landing.Id === landingPageID && landingPageID !== undefined).map((landingPath) => (
        history.push(landingPath.Path)
      )
      )
    }
  };
  render() {
    return <div>{this.userBasedLandingPage(this.props.landingPage)}</div>;
  }
}

export default DefaultLandingPageComponent;
