import localStorage from '../../../utils/localStorage'
import axios from 'axios'
import moment from 'moment'

export async function dataWatchTableCall(dataWatchName) {
  const siteId = localStorage.get('currentSite')
  const token = localStorage.get('accessToken')
  var Authorization = "Bearer " + token;
  let data = await fetch(`/api/report/reportData`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: Authorization
    },
    body: JSON.stringify({
      "ReportType": "Data Watch",
      "ReportName": dataWatchName,
      "SiteId":siteId
    })
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json
    })
    .catch(error => {
      console.log("Error",error)
      // if(error.response.data.name === 'TokenExpiredError'){
      //   jwtToken(error.response.data.message)
      // }
    });

  return data === undefined ? [] : data;
}



export async function getDataSourceFromBackend(apiObject) {
  let accessToken = localStorage.get('accessToken')
  const siteId = localStorage.get('currentSite')
  let options = {}
  if (apiObject.method === 'GET' && apiObject.uri) {
    options = {
      method: apiObject.method,
      url: `${apiObject.uri}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }
  } else if (apiObject.method === 'POST' && apiObject.payload && apiObject.uri) {
    options = {
      method: apiObject.method,
      url: `${apiObject.uri}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: JSON.stringify(
        {
          ...apiObject.payload,
          "SiteId":siteId
        })
    }
  }

  return await axios(options)
    .then(({ data }) => {
      return data
    }).catch(()=>{
      return []
    })

}



export async function geDataWatchNametoDownload(columns, datasource, downloadExcelPayload) {
  let reportPayload = []
  datasource.map((datasourceObject) => {
    let newObj = {}
    columns.map((column) => {
      if (column && column.children) {
        let newChildrenObject = {}
        column.children.map((columnChildren) => {
          if (datasourceObject[columnChildren.key] === undefined) {
            newChildrenObject[columnChildren.title] = ""
          } else {
            newChildrenObject[columnChildren.title] = datasourceObject[columnChildren.key]
          }
        })
        newObj[column.title] = {
          ...newChildrenObject
        }
      } else {
        if (datasourceObject[column.key] === undefined) {
          newObj[column.title] = ""
        } else {
          newObj[column.title] = datasourceObject[column.key]
        }
      }

    })
    reportPayload.push(newObj)
  })
  let payload = {
    ...downloadExcelPayload,
    "date": moment().format('YYYY-MM-DD'),
    "time": moment().format('HH:mm:SS'),
    "data": reportPayload
  }



  let accessToken = localStorage.get('accessToken')
  let options = {}
  options = {
    method: 'POST',
    url: `/api/report/generateExcel`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: JSON.stringify(payload)
  }
  return axios(options)
    .then(({ data }) => {
      return data
    }).catch(error => {
      return ""
      // console.log('ERROR', error)

    })

}
