import React from 'react'
import { Button } from 'antd'

import history from '../../../commons/history'
import imageUrl from '../../../images/403.png'

class CentralDashboardComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    navToHomePage = () =>{
        history.push('/rubus/dashboard/PlantOverview')
    }
  
    render() {
        return (
            <div style={{ backgroundColor: '#fff', minHeight: window.innerHeight - 137 }}>
                <div style={{ height: '100%', display: 'flex', justifyContent: 'space-around' }}>
                    <div key="1">
                        <div style={{ textAlign: 'center !important' }}>
                            <img src={imageUrl} height='400px' alt="avatar" />
                            <h2>Sorry, the page you visited does not exist.</h2>
                            <Button style={{ marginLeft: '90px',marginTop:'15px' }} type="primary" onClick={ this.navToHomePage }>Go Back to Home Page</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CentralDashboardComponent
