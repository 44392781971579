import React, { Component } from 'react'
import axios from 'axios'
import { Table, Form, Drawer, Input, Button, message } from 'antd'

import localStorage from '../../../utils/localStorage'
import {
    StyledDashboard, DrawerFooter, AddTableButton
} from './styles'
import SelectComponent from './SelectComponent'
import './style.css'

class MasterView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            masterName: "",
            masterJSON: {},
            datasource: [],
            editObject: {},
            visible: false
        }
    }

    componentDidMount() {
        let masterName = this.props.match.params.mastername;
        this.getMasterData(masterName);
        this.setState({
            masterJSON: this.props.match.params.mastername
        })
    }

    componentDidUpdate(prevprops) {
        if (this.props.match.params.mastername !== prevprops.match.params.mastername) {
            this.getMasterData(this.props.match.params.mastername);
            this.setState({
                masterJSON: this.props.match.params.mastername
            })
        }
    }

    getDatasourceData = async (createAPIObject, type) => {
        let { editObject, masterJSON } = this.state
        const accessToken = localStorage.get('accessToken')
        const SiteId = localStorage.get('currentSite')
        let header = {}
        if (type === 'list') {
            header = {
                method: createAPIObject.method,
                url: `${createAPIObject.url}`,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    "MasterName": this.props.match.params.mastername,
                    "ActionType": "read"
                }
            }
        } else if (type === 'create') {
            header = {
                method: 'POST',
                url: `${createAPIObject.url}`,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    "MasterName": masterJSON.MasterName,
                    "ActionType": "create",
                    "data": { ...editObject, SiteId }
                }
            }
        } else if (type === 'update') {
            header = {
                method: createAPIObject.method,
                url: `${createAPIObject.url}`,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    "MasterName": masterJSON.MasterName,
                    "ActionType": "update",
                    "data": { ...editObject, SiteId }
                }
            }
        }
        else {
            header = {

            }
        }

        return axios(header)
            .then((response) => {
                return response.data
            })
            .catch(function () {
                return []
            })
    }

    getMasterData = async (masterName) => {
        let masterJSON = await this._getMasterData(masterName)
        if (masterJSON && masterJSON.api && masterJSON.api.read) {
            let datasource = await this.getDatasourceData(masterJSON.api.read, "list")
            this.setState({
                masterJSON,
                masterName,
                datasource,
                visible: false
            })
        }
    }
    _getMasterData = (MasterName) => {
        const accessToken = localStorage.get('accessToken')
        const Object = {
            method: 'POST',
            url: `/api/masterconfiguration/getMasterByName`,
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            data: {
                MasterName
            }
        }
        return axios(Object)
            .then((response) => {
                return response.data
            }).catch((error) => {
                return []
            })
    }

    openDrawer = (e, row) => {
        e.preventDefault()
        this.setState({
            editObject: row ? row : {},
            visible: true
        })
    }

    onClose = () => {
        this.setState({
            visible: false
        })
    };

    showDrawer = () => {
        this.setState({
            visible: true,
            editObject: {}
        })
    }

    _masterCreateObject = (e, apiObject, type) => {
        e.preventDefault()
        let { visible, masterJSON } = this.state
        this.props.form.validateFields(async (err) => {
            if (!err) {
                await this.getDatasourceData(apiObject, "create")
                let datasource = await this.getDatasourceData(masterJSON.api.read, "list")
                this.setState({
                    visible: !visible,
                    datasource
                })
            }
        })
    }


    _masterUpdateObject = (e, apiObject, type) => {
        e.preventDefault()
        let { visible, masterJSON } = this.state
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                await this.getDatasourceData(apiObject, "update")
                let datasource = await this.getDatasourceData(masterJSON.api.read, "list")
                this.setState({
                    visible: !visible,
                    datasource
                })
            }
        })
    }


    onInputChange = (e, key) => {
        // e.preventDefault()
        let { editObject } = this.state
        editObject = {
            ...editObject,
            [key]: e
        }
        this.setState({
            editObject
        })
    }

    renderFormItems(formObject, editObject) {
        switch (formObject.widget) {
            case 'input':
                return <Form.Item label={formObject.title} required={formObject.isMandatory}>
                    <Input
                        disabled={formObject && formObject.disableOnUpdate && editObject
                            //  && (editObject.Id || formObject.key === "Id")
                            ? formObject.disableOnUpdate : false}
                        value={editObject &&
                            editObject[formObject.key]
                            ? editObject[formObject.key]
                            : ''
                        }
                        onChange={(e) =>
                            this.onInputChange(e.target.value, formObject.key)}
                    />
                </Form.Item>
            case 'select':
                return <Form.Item label={formObject.title} required={formObject.isMandatory}>
                    <SelectComponent
                        value={editObject &&
                            editObject[formObject.key]
                            ? editObject[formObject.key]
                            : ''
                        }
                        column={formObject}
                        handleFieldChange={this.onInputChange}
                    />
                </Form.Item>
            // case 'select':
            //     return <SelectComponent
            //         column={columns}
            //         record={{ key: columns }}
            //         text={formObject[name] &&
            //             formObject[name].headerValue &&
            //             formObject[name].headerValue[columns.key]
            //             ? formObject[name].headerValue[columns.key]
            //             : undefined
            //         }
            //         handleFieldChange={(e) => this.onHeaderInputChange(e, columns.key, name)}
            //         handleKeyPress={this.handleKeyPress}

            //     />

            default:

                return <Form.Item label={formObject.title}>
                    <Input
                        disabled={formObject && formObject.disableOnUpdate && editObject && (editObject.Id || formObject.key === "Id") ? formObject.disableOnUpdate : false}
                        value={editObject &&
                            editObject[formObject.key]
                            ? editObject[formObject.key]
                            : ''
                        }
                        onChange={(e) =>
                            this.onInputChange(e.target.value, formObject.key)}
                    />
                </Form.Item>
        }



    }

    onFileUpload = (e) => {
        let { masterJSON } = this.state
        let file = e.target.files[0]
        const accessToken = localStorage.get("accessToken");
        const url = '/api/masterconfiguration/masterUpload';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('tableName', masterJSON.TableName);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${accessToken}`
            },
        }
        axios.post(url, formData, config).then((response) => {
            message.success(response.data.message)
        }).catch((err) => {
            console.log(err);
            message.error("Error while upload")
        });
    }

    render() {
        let { datasource, masterJSON, editObject, visible } = this.state
        return (
            <div>
                {
                    masterJSON && masterJSON.MasterName ?
                        <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
                            <AddTableButton type='primary' onClick={this.showDrawer}>{`Create ${masterJSON.MasterName}`}</AddTableButton>

                            <div style={{ margin: "0px auto 12px", display: "flex", flexDirection: "row-reverse",background:"rgb(33, 73, 114)" }}>
                                <input type="file" onChange={this.onFileUpload} />
                            </div>


                            <Table
                                className="masterTable"
                                columns={masterJSON && masterJSON.columns && Array.isArray(masterJSON.columns) ? masterJSON.columns : []}
                                dataSource={datasource}
                                size='large'
                                pagination={(datasource.length > 10) ? {
                                    pageSize: '10'
                                } : false}
                                locale={{ emptyText: 'No Data' }}
                                onRow={(record) => ({
                                    onClick: (e) => {
                                        e.stopPropagation()
                                        this.openDrawer(e, record)
                                    }
                                })} />

                            <Drawer
                                title={!editObject.Id ? `Create ${masterJSON.MasterName}` : `Update ${masterJSON.MasterName}`}
                                placement="right"
                                closable={true}
                                width={500}
                                onClose={this.onClose}
                                visible={visible}
                            >
                                <Form layout='vertical'>
                                    {
                                        masterJSON
                                        && masterJSON.columns
                                        && Array.isArray(masterJSON.columns)
                                        && masterJSON.columns.map((masterColumns) => {
                                            return this.renderFormItems(masterColumns, editObject)
                                        })
                                    }
                                </Form>
                                <DrawerFooter>
                                    <Button onClick={this.onClose} style={{ marginRight: 8 }}>Cancel</Button>
                                    {
                                        editObject && editObject.Id !== undefined
                                            ? <Button onClick={(e) => this._masterUpdateObject(e, masterJSON.api.update, "update")} type="primary">{`Update ${masterJSON.MasterName}`}</Button>
                                            : <Button onClick={(e) => this._masterCreateObject(e, masterJSON.api.create, "create")} type="primary">{`Create ${masterJSON.MasterName}`}</Button>
                                    }
                                </DrawerFooter>

                            </Drawer>
                        </StyledDashboard>
                        : null
                }
            </div>
        )
    }
}

export default Form.create()(MasterView)
