import localStorage from "../../utils/localStorage";

export default class device {
  static getDeviceList() {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get("accessToken");
    return fetch(`/api/device/list?SiteId=${siteId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static getDeviceCMList() {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get("accessToken");
    return fetch(`/api/site/${siteId}/deviceType/cmdevices`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static createDevice(payload) {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get("accessToken");
    return fetch(`/api/site/${siteId}/device/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload)
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static updateDevice(payload) {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get("accessToken");
    return fetch(`/api/site/${siteId}/device/update/${payload._key}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static deleteDeviceSaga(payload) {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get("accessToken");
    return fetch(`/api/site/${siteId}/device/delete/${payload}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
        return response;
    }
  }
}
