import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";

import SlideMenu from "./SlideMenu";
// import { getTranslationsCache } from "../../../../modules/translation/translation.duck";
import { StyledHeaderLayout } from "./styles";
import { getNavigationMode } from "../../../../selectors/theme";
import "./styles.css";

class HeaderLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.props.actions.getTranslationsCache("English");
  }

  render() {
    return (
      <span>
        <StyledHeaderLayout
          style={{
            display: this.props.hideLayout === true ? "none" : "block",
            padding: 0
          }}
        >
          <SlideMenu
            name="test"
            history={this.props.history}
            nativProps={this.props}
            fullScreen={this.props.fullScreen}
          />
        </StyledHeaderLayout>
      </span>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  navigationMode: getNavigationMode()
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        // getTranslationsCache
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLayout);
