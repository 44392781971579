import { createSelector } from 'reselect'

const conditionList = state => {
  return state.get('condition').get('condition')
}

const getConditionListSelector = () =>
  createSelector(conditionList, state => {
    return state.get('conditionList')
  })

export {
  getConditionListSelector,
}
