export default class deviceDataSourceAdapter {
  static filterJsonData(deviceTypeList, deviceList) {
    if (!deviceList.message) {
      deviceList.forEach((devices, index) => {
        deviceTypeList.forEach((deviceType) => {
          if (devices.DeviceType === deviceType.Id) {
            deviceList[index].DeviceTypeName = deviceType.Name
            deviceList[index].TimeSeries = deviceList[index].TimeSeries.toString()
          }
        })
      })
      return deviceList
    }
  }
}
