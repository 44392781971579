import React from "react";
import { connect } from "react-redux";
import { List, Card } from "antd";
import { createStructuredSelector } from "reselect";
import moment from "moment";
import { Tag} from "antd";
import { getChartDataWithoutSelection, getChartDataWithSelection } from "../ChartCalls";
import { tableTranslation } from "../../Utils/Adapter/translationAdapter";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";
import {
  ErrorComponent,
  NoRecordComponent
} from "../Utils"
import { cardListMockJson } from "../../Mock/MockJson";
import { defaultRefreshTime } from '../constant.json'
import "../CSS/CounterList.css";

class CardsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardsList: [],
      translationCache: props.translationCache || [],
      language: props.language || "English",
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = "";
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }

    }, refreshTime);
  }

  componentWillUnmount() {
    this.abortController.abort()
    clearInterval(this.timeTicket);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear
    ) {
      this.getData(this.props);
    }
  }

  getData = async (props) => {
    this.requestCheck = false
    let { graphprops } = this.props;
    let data = [];
    let { errorInAPI, noDataInDB } = this.state
    errorInAPI = false
    noDataInDB = false 
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      data = cardListMockJson;
      this.requestCheck = true
    } else {
      if (
        (graphprops &&
          graphprops.dashboardType === "dynamic" &&
          this.props.deviceId) ||
        (graphprops &&
          graphprops.dateExists === "true" &&
          this.props.selectionDate) ||
        (graphprops &&
          graphprops.monthExists === "true" &&
          this.props.selectionMonth) ||
        (graphprops &&
          graphprops.weekExists === "true" &&
          this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === "true" && this.props.shift) ||
        (graphprops &&
          graphprops.dateRangeExists === "true" &&
          this.props.selectionDateRange) ||
        (graphprops &&
          graphprops.dayMonthYearExists === "true" &&
          this.props.dayMonthYear)
      ) {
        data = await getChartDataWithSelection(
          graphprops,
          this.props.deviceId,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController

        );
        this.requestCheck = true
      } else {
        data = await getChartDataWithoutSelection(graphprops, this.abortController);
        this.requestCheck = true

        this.setState({ data: data }); //need
      }
    }
    if ((data && data.message )|| (Array.isArray(data) && (data.length === 0) && 
    (graphprops.enableMock !== true))) {
  
      if (data.message) {
        errorInAPI = true
      } else if (data.length === 0) {
        noDataInDB = true
      }
  
    } else {

    if (data && Array.isArray(data) && data.length > 0
    ) {
      let columnsData = await tableTranslation(data, this.props.translationCache, this.props.language)
      this.setState({ cardsList: columnsData });
    }
    else {
      this.setState({
        cardsList: [],
        refreshDateTime: moment()
      })
    }

  };
  this.setState({
    errorInAPI,
    noDataInDB
  })
};

  render() {
    const { cardsList } = this.state;
   const { errorInAPI, noDataInDB,refreshDateTime } = this.state;
   let { graphprops } = this.props
    const colors = [
      "steelblue",
      "#a0d911",
      "#faad14",
      "#c41d7f",
      "#006d75",
      "steelblue",
      "#a0d911",
      "#faad14",
      "#c41d7f",
      "#006d75",
    ];
  
    return (
     <>
       {
          graphprops && graphprops.checked === true ?
            <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
              {refreshDateTime ? moment(refreshDateTime).format('DD-MM-YYYY HH:mm:ss') : ""}
            </Tag>
            : null
        }
      {
          noDataInDB === true || errorInAPI === true ?
            noDataInDB === true ?
            <NoRecordComponent/>
            : <ErrorComponent/>
            :
      <div style={{ padding: "10px" }}>
        {this.props.graphprops.viewType === "listOfItems" ? (
          <List
            itemLayout="horizontal"
            dataSource={cardsList}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  style={{
                    marginLeft: "30px",
                  }}
                  title={
                    <span style={{ color: colors[index], fontSize: "30px" }}>
                      {item.title}
                    </span>
                  }
                />

                <div style={{ color: colors[index], fontSize: "30px" }}>
                  {item.value}
                </div>
              </List.Item>
            )}
          />
        ) : null}

        {this.props.graphprops.viewType === "listOfCards" ? (
          <List
            grid={{
              gutter: 10,
              column: this.props.graphprops.numberOfColumns
                ? this.props.graphprops.numberOfColumns
                : 3,
            }}
            dataSource={cardsList}
            style={{
              textAlign: "center",
              textColor: "#fff !important",
              fontSize: "3.5em",
            }}
            renderItem={(item, index) => (
              <List.Item>
                <Card
                  title={item.title}
                  style={{ backgroundColor: colors[index], fontSize: "1.5em" }}
                >
                  {" "}
                  {item.value}
                </Card>
                {/* <Card title={item.title} style={{ backgroundColor: colors[index],fontSize: "1.5em"}}>  <Card.Grid style={gridStyle}>{item.value}</Card.Grid></Card> */}
              </List.Item>
            )}
          />
        ) : null}
      </div>
  }
     </>

    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
});

export default connect(mapStateToProps)(CardsList);
