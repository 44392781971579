import React from "react";
import { Menu, Icon } from "antd";
import { withRouter } from "react-router";

import ApplicationSettingsRouter from "../../../routes/applicationSettingsRoute";
import styles from "../../../commons/styles";

const { StyledWrapper, StyledWrapperLeft, StyledWrapperRight } = styles.layout;

class ApplicationSettings extends React.Component {
  render() {
    return (
      <StyledWrapper style={{ minHeight: window.innerHeight - 250 }}>
        <StyledWrapperLeft>
          <Menu mode="inline" defaultSelectedKeys={["configUpload"]}>
            <Menu.Item
              key="configUpload"
              onClick={() => {
                this.props.history.push(
                  "/rubus/configurationSettings/configurationUpload"
                );
              }}
            >
              <Icon type="upload" />
              Configuration Upload
            </Menu.Item>
            <Menu.Item
              key="hierarchyUpload"
              onClick={() => {
                this.props.history.push(
                  "/rubus/configurationSettings/hierarchyUpload"
                );
              }}
            >
              <Icon type="pic-right" />
              Default Hierarchy Upload
            </Menu.Item>
          </Menu>
        </StyledWrapperLeft>
        <StyledWrapperRight
          style={{ backgroundColor: "white", margin: "1px 15px 13px 15px" }}
        >
          <ApplicationSettingsRouter />
        </StyledWrapperRight>
      </StyledWrapper>
    );
  }
}

export default withRouter(ApplicationSettings);
