import localStorage from '../../utils/localStorage'
import axios from 'axios'

export default class tagReference {
  static getList () {
    const accessToken = localStorage.get("accessToken");
    const ParameterTypeObject = {
      method: "POST",
      url: `/api/masterconfiguration/master`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data:{
        "MasterName":"AssetDevice_TagReference",
        "ActionType": "read"
      }
    };
   return axios(ParameterTypeObject)
      .then(response => {
        return response.data 
      })
      .catch(function() {});
  }

  static createDevice (payload) {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get('accessToken')
    return fetch(`/api/site/${siteId}/device/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload)
    }).then(responseToken)

    function responseToken (response) {
      return response.json()
    }
  }

  static updateDevice (payload) {
    const siteId = localStorage.get('currentSite')
    const accessToken = localStorage.get('accessToken')
    return fetch(`/api/site/${siteId}/device/update/${payload._key}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }).then(responseToken)
    function responseToken (response) {
      return response.json()
    }
  }
}
