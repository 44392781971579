import React from 'react'
import Highlighter from 'react-highlight-words'
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Form, Table, message, Button, Drawer, Input, Col, Row, Popconfirm, Icon, Upload } from 'antd';

import localStorage from '../../../utils/localStorage'
import { AddTableButton, DrawerFooter } from './styles'
import { _getTranslationMasterData, _saveTranslation, _getCurrentLanguages } from "./translationCalls"
import { getCurrentLanguage, getTranslationCache } from "../.././../selectors/language";
import { constant } from "../Constants";
import './../style.css'

const FormItem = Form.Item

class Translation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dataSource: [],
            editObject: {},
            searchText: '',
            languageList: [],
            translationCache: props.translationCache || [],
            language: props.language || "English",
            columns: [],
            fileProps: {}
        }
    }

    componentWillMount() {
        this._getTranslationList()
        this._getCurrentLanguage()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.translationCache !== this.props.translationCache || prevProps.language !== this.props.language) {
            this.setState({
                translationCache: this.props.translationCache, language: this.props.language
            });
        }
    }

    _getTranslationList = async () => {
        let dataSource = await _getTranslationMasterData()
        this.setState({
            dataSource
        })
    }

    _getCurrentLanguage = async () => {
        let columnsAddition = []
        let languageList = []
        languageList = await _getCurrentLanguages()
        columnsAddition = languageList && Array.isArray(languageList) && languageList.map((language) => {
            return {
                title: this.translation(language),
                dataIndex: language,
                key: language,
                width: '15%',
                ...this.getColumnSearchProps(language),
            }
        })
        let columns = [
            {
                title: this.translation(constant.Keyword),
                dataIndex: 'Keyword',
                key: 'Keyword',
                width: '15%',
                ...this.getColumnSearchProps('Keyword'),
            },
            ...(columnsAddition && Array.isArray(columnsAddition) ? columnsAddition : []),
            {
                title: this.translation('CreatedBy'),
                dataIndex: 'CreatedBy',
                key: 'CreatedBy',
                width: '10%',
                ...this.getColumnSearchProps('CreatedBy'),
            },
            {
                title: this.translation('Action'),
                dataIndex: '',
                key: '',
                width: '15%',
                render: (text, record) =>
                    <div>
                        <button type="button" className='ant-btn' onClick={(e) => this.showDrawer(e, record)}>
                            <Icon type="edit"></Icon>
                        </button>
                        <Popconfirm
                            title={"Do you want to delete ?"}
                            onConfirm={() => {
                                this.deleteTranslation(record);
                            }}
                            onCancel={this.cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <button type="button" className='ant-btn' style={{ marginLeft: '10px' }} >
                                <Icon type="delete"></Icon>
                            </button>
                        </Popconfirm>

                    </div>
            }
        ]

        this.setState({ languageList, columns })
    }

    showDrawer = (e, record) => {
        e.preventDefault(e)
        this.setState({ editObject: record, visible: true })
    }

    onClose = () => {
        this.setState({ editObject: {}, visible: false })
    }

    saveTranslation = (e) => {
        const SiteId = localStorage.get('currentSite')
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let saveTranslation = {
                    ...values,
                    SiteId,
                    "CreatedBy": "1"

                }
                this._curdTranslationFn(saveTranslation, "create")

            }
        });
    }

    updateTranslation = (e) => {
        let { editObject } = this.state
        const SiteId = localStorage.get('currentSite')
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let updateTranslation = {
                    ...values,
                    SiteId,
                    "Id": editObject.Id,
                    "CreatedBy": "1",
                    "ModifiedBy": "1"
                }
                this._curdTranslationFn(updateTranslation, "languageUpdate")
            }
        });
    }

    _curdTranslationFn = async (payload, apiURL) => {
        let responseData = await _saveTranslation(payload, apiURL)
        if (responseData && responseData.message) {
            this._getTranslationList()
            this.props.form.resetFields()
            this.setState({ visible: false })
            message.success(responseData.message)
        }
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record && record[dataIndex] && record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select())
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text && text.toString()}
            />
        )
    })

    handleSearch = (selectedKeys, confirm) => {
        confirm()
        this.setState({ searchText: selectedKeys[0] })
    }

    handleReset = clearFilters => {
        clearFilters()
        this.setState({ searchText: '' })
    }
    handleChange = info => {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            this._getTranslationList()
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    fileUpload = (file) => {
        const reader = new FileReader();
        reader.onload = e => {
            this.setState({
                filename: file.name

            })
        }
    }

    deleteTranslation = (e) => {
        const SiteId = localStorage.get('currentSite')
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let deleteTranslation = {
                    "Keyword": e.Keyword,
                    SiteId
                }
                this._curdTranslationFn(deleteTranslation, "delete")
                this.props.form.resetFields()
                this.setState({ visible: false })
                this._getTranslationMasterData()
            }
        });
    }

    translation = (keyword) => {
        let { translationCache } = this.props
        return translationCache &&
            translationCache[keyword] ?
            translationCache[keyword] : keyword
    }


    render() {
        const { getFieldDecorator } = this.props.form
        const { dataSource, editObject, languageList, columns } = this.state
        const token = localStorage.get("accessToken");
        var Authorization = "Bearer " + token
        const props = {
            name: 'file',
            file: this.state.filename,
            method: 'POST',
            action: '/api/language/bulk',
            headers: {
                Authorization: Authorization
            },
            data: {
                file: this.state.filename
            },
            onChange: this.handleChange
        };


        return (
            <div style={{
                flexDirection: 'row', justifyContent: 'space-evenly'
            }}>
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <AddTableButton type='primary' style={{ background: "#214972", border: "#214972", margin: "9px 10px" }} onClick={(e) => this.showDrawer(e, {})}>
                        <Icon type="plus"></Icon>{this.translation(constant.Add)}
                    </AddTableButton>
                    <Button type='primary' className='ant-btn' style={{ background: "#214972", border: "#214972", margin: "9px 10px" }}>
                        <Icon type="download" />
                        <a
                            href={`https://bpt.bmecomosolution.com/api/sample/sample.csv`}
                            style={{ color: "white" }}
                        >
                            {" "}
                            {this.translation(constant.Default_Template)}
                        </a>
                    </Button>
                    <Upload
                        name="file"
                        accept="csv/xml"
                        multiple="false"
                        beforeUpload={this.fileUpload}
                        {...props}
                    >
                        <Button type='primary' className='ant-btn' style={{ background: "#214972", border: "#214972", margin: "9px 10px" }}>
                            <Icon type="upload" /> {this.translation(constant.Click_to_Upload)}
                        </Button>
                    </Upload>
                </div>

                <Drawer
                    title={
                        !editObject.English ?
                            this.translation("Insert Translation Words")
                            : this.translation(constant.Update_Translation_Words)
                    }
                    placement="right"
                    closable={true}
                    width={500}
                    onClose={this.onClose}
                    visible={this.state.visible}
                >
                    <Form layout='vertical'>
                        <Row>
                            <Col span={24}>
                                <FormItem label={
                                    this.translation("Keyword")
                                }>
                                    {getFieldDecorator("Keyword", {
                                        initialValue: editObject["Keyword"] || ""
                                    })(
                                        <Input />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        {
                            languageList && Array.isArray(languageList) && languageList.map((language) => {
                                return <Row>
                                    <Col span={24}>
                                        <FormItem label={
                                            this.translation(language)
                                        }>
                                            {getFieldDecorator(language, {
                                                initialValue: editObject[language] || ""
                                            })(
                                                <Input />
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                            })
                        }
                        <DrawerFooter>
                            <Button onClick={this.onClose} style={{ marginRight: 8 }}>{this.translation("Cancel")}</Button>
                            {
                                !editObject.English ?
                                    <Button type="primary" onClick={this.saveTranslation}>{this.translation("Create")}</Button> :
                                    <Button type="primary" onClick={this.updateTranslation}>{this.translation("Update")}</Button>
                            }
                        </DrawerFooter>
                    </Form>

                </Drawer>
                <Table className={"customTable"} columns={columns} dataSource={dataSource} />
            </div >
        );
    }
}


const mapStateToProps = createStructuredSelector({
    language: getCurrentLanguage(),
    translationCache: getTranslationCache(),

});
export default connect(mapStateToProps)(Form.create()(Translation));


