import React from "react"
import moment from "moment"
import { DatePicker } from 'antd'

import HeatmapCartesion from './HeatmapCartesion'
import { DatePickerSlider } from '../CSS/style'

class DateHeatmapCartesion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphprops: {
        ...props.graphprops,
        dateExists: 'true',
        startDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      } || {},
      selectionDate: {
        dateExists: 'true',
        date: moment().utc().format("YYYY-MM-DD 00:00:00")
      }
    };
  }

  onChangeDatePicker = (value, dateString) => {
    if (dateString === moment().format('YYYY-MM-DD')) {
      this.setState({
        selectionDate: {
          dateExists: 'true',
          date: moment(value).format('YYYY-MM-DD 00:00:00'),
        }
      })
    } else {
      this.setState({
        selectionDate: {
          dateExists: 'true',
          date: moment(value).format('YYYY-MM-DD 00:00:00'),
        }
      })
    }
  }

  disabledDate = (current) => {
    return current > moment().endOf('day');
  }

  render() {
    let { selectionDate } = this.state
    return (
      <div style={{ height: '70%', marginTop: '40px' }}>
        <DatePickerSlider>
          <DatePicker
            format="YYYY-MM-DD"
            value={moment(selectionDate.date)}
            onChange={this.onChangeDatePicker}
            disabledDate={this.disabledDate}
          />
        </DatePickerSlider>

        <HeatmapCartesion graphprops={this.state.graphprops} selectionDate={this.state.selectionDate} />
      </div>

    );
  }

}
export default DateHeatmapCartesion
