import React, { Component } from 'react'
import {  Cascader } from 'antd'

import localStorage from '../../../../../utils/localStorage'

// const { Option } = Select

class HierarchyDropdownSelection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            optionList: [],
            value: ""
        }
    }

    componentDidUpdate(prevprops) {
        if (prevprops.dropdownSelection !== this.props.dropdownSelection) {
            // if (prevprops.dropdownSelection.reportName !== this.props.dropdownSelection.reportName) {
            //     this._getOptionList(this.props.dropdownSelection, "first")
            // } else {
            // this._getOptionList(this.props.dropdownSelection)
            // }

        }
    }

    componentWillMount() {
        this._getOptionList()
    }

    _getOptionList = () => {
        let options = []
        options = JSON.parse(localStorage.get('modules'))
        this.setState({
            options: options && options.hierarchy ? options.hierarchy : []
        })

    }


    handleChange = value => {
        this.props.handleDropdownChange(value)
    };

    render() {
        let { options } = this.state
        return (
            <div style={{ height: '70%', marginTop: '10px' }}>
                <Cascader
                    options={options}
                    onChange={this.handleChange}
                />

            </div>

        )
    }
}

export default (HierarchyDropdownSelection)
