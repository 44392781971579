import React from 'react'
import axios from 'axios'
import { Table, Drawer, Button, Form, Input, Checkbox, Row, Tree, Icon, Popconfirm, message, Select } from 'antd'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect';

import rolesPermissions from './Adapter'
import localStorage from '../../../../utils/localStorage'

import { getCurrentLanguage, getTranslationCache } from "../../../../selectors/language";
import { StyledTable, lightTheme } from '../style'

const { TreeNode } = Tree
const { Option } = Select


class Role extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            editObj: {},
            siteId: '',
            orgId: '',
            moduleList: [],
            configurationList: [],
            landingPageList: [],
            permissionsList: {},
            checkedConfiguartionKeys: [],
            checkedModuleKeys: [],
            checkedApplicationSettingsKeys: [],
            checkedPermissionsKeys: {
                "asset_device": [],
                "dashboard": [],
                "timeScale": []
            },

            configurationActualList: {},
            modulesActualList: {},
            applicationActualList: {},

            datasource: [],
            editObj: {},

            selectedKeys: [],
            expandedKeys: [],
            autoExpandParent: true,
        }
    }

    componentWillMount() {
        let siteId = this.props.match.params.siteId
        let orgId = this.props.match.params.orgId
        this.setState({ siteId, orgId })
        this._getRolesList(siteId)
        this._getPermissionsList()
        this._getModuleList()
        this._getLandingPageList()

    }

    _getPermissionsList = () => {
        const accessToken = localStorage.get('accessToken')
        let orgHeaders = {
            method: 'GET',
            url: `/api/role/permissions`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
        axios(orgHeaders)
            .then((response) => {
                this.setState({ permissionsList: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    _getRolesList = (siteId) => {
        const accessToken = localStorage.get('accessToken')
        let orgHeaders = {
            method: 'GET',
            url: `/api/role/list`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
        axios(orgHeaders)
            .then((response) => {
                this.setState({ datasource: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    _getModuleList = () => {
        let siteId = this.props.match.params.siteId
        const accessToken = localStorage.get('accessToken')
        let orgHeaders = {
            method: 'GET',
            url: `/api/menu/list?SiteId=${siteId}`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
        axios(orgHeaders)
            .then((response) => {

                if (response.data[0]) {
                    this.setState({
                        configurationActualList: response.data[0].Configuration,
                        modulesActualList: response.data[0].Modules,
                        applicationSettingsActualList: response.data[0].ApplicationSettings
                    })
                    let configurationList = rolesPermissions.menuListAdapter(response.data[0].Configuration)
                    let moduleList = rolesPermissions.menuListAdapter(response.data[0].Modules)
                    let applicationList = rolesPermissions.menuListAdapter(response.data[0].ApplicationSettings)
                    this.setState({ moduleList, configurationList, applicationList })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    _getLandingPageList = () => {
        const accessToken = localStorage.get('accessToken')
        let orgHeaders = {
            method: 'GET',
            url: `/api/landingPage/list`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
        axios(orgHeaders)
            .then((response) => {
                this.setState({ landingPageList: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    showDrawer = () => {
        this.props.form.resetFields();
        this.setState({
            editObj: {},
            visible: true,
            checkedConfiguartionKeys: [],
            checkedModuleKeys: [],
            checkedApplicationSettingsKeys: [],
            checkedPermissionsKeys: {
                "asset_device": [],
                "dashboard": [],
                "timeScale": []
            }
        });
    };

    onClose = () => {
        this.props.form.resetFields()
        this.setState({
            visible: false,
        });
    };

    _updateRole = (updateObject) => {
        const accessToken = localStorage.get('accessToken')
        let updateHeader = {
            method: 'POST',
            url: `/api/role/update`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: (updateObject)
        }
        axios(updateHeader)
            .then((response) => {
                this._getRolesList(updateObject.site)
                this.props.form.resetFields()
                message.success(response.data.message)
                this.setState({ visible: false });
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    _saveRole = (createObject) => {
        const accessToken = localStorage.get('accessToken')
        let orgCreateHeaders = {
            method: 'POST',
            url: '/api/role/insert',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: (createObject)
        }
        axios(orgCreateHeaders)
            .then((response) => {
                this._getRolesList(createObject.site)
                this.props.form.resetFields()
                message.success(response.data.message)
                this.setState({ visible: false });
            })
            .catch(function (error) {
                console.log(error)
            })
    }


    _deleteRole = (role) => {
        const accessToken = localStorage.get('accessToken')
        let orgDeleteHeaders = {
            method: 'POST',
            url: `/api/role/delete`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: {
                Id: role.Id
            }
        }
        axios(orgDeleteHeaders)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    message.success("Role Deleted")
                    this._getRolesList(role.site)
                    this.props.form.resetFields()
                    this.setState({ visible: false })
                } else {
                    console.log("response.data.message", response.data.message);
                    message.error("Error in delete")
                }
            })
            .catch(function (error) {
                console.log(error)
                message.error("Error")
            })

    }


    openDrawer = (e, record) => {

        let checkedConfiguartionKeys = []
        let checkedModuleKeys = []
        let checkedApplicationSettingsKeys = []

        if (record.Configuration && record.Configuration.children) {
            checkedConfiguartionKeys = rolesPermissions.generateCheckedList(record.Configuration.children)
        }
        if (record.Modules && record.Modules.children) {
            checkedModuleKeys = rolesPermissions.generateCheckedList(record.Modules.children)
        }
        if (record.ApplicationSettings && record.ApplicationSettings.children) {
            checkedApplicationSettingsKeys = rolesPermissions.generateCheckedList(record.ApplicationSettings.children)
        }

        let checkedPermissionsKeys = rolesPermissions.permissionAdapter(record.Permission)

        this.setState({
            visible: true,
            editObj: record,
            checkedPermissionsKeys,
            checkedModuleKeys,
            checkedConfiguartionKeys,
            checkedApplicationSettingsKeys
        })
    }

    onCheck = (name, checkedKey) => {
        if (name === 'configuration') {
            this.setState({ checkedConfiguartionKeys: checkedKey });
        } else if (name === 'module') {
            this.setState({ checkedModuleKeys: checkedKey });
        } else if (name === 'applicationSettings') {
            this.setState({ checkedApplicationSettingsKeys: checkedKey });
        }
    }

    onSubmit = (operation) => {
        let {
            configurationActualList, modulesActualList, applicationSettingsActualList,
            checkedConfiguartionKeys, checkedApplicationSettingsKeys,
            checkedPermissionsKeys, checkedModuleKeys,
            orgId, siteId } = this.state


        this.props.form.validateFields((error, values) => {
            if (!error) {
                let Modules = []
                let Configuration = []
                let ApplicationSettings = []
                let Permission = []

                if (checkedConfiguartionKeys && checkedConfiguartionKeys.length > 0) {
                    Configuration = rolesPermissions.createConfiguration(configurationActualList, checkedConfiguartionKeys)
                }

                if (checkedModuleKeys && checkedModuleKeys.length > 0) {
                    Modules = rolesPermissions.createConfiguration(modulesActualList, checkedModuleKeys)
                }

                if (checkedApplicationSettingsKeys && checkedApplicationSettingsKeys.length > 0) {
                    ApplicationSettings = rolesPermissions.createConfiguration(applicationSettingsActualList, checkedApplicationSettingsKeys)
                }

                if (checkedPermissionsKeys) {
                    Permission = rolesPermissions.createPermissions(checkedPermissionsKeys)
                }

                let saveObject = {
                    ...values,
                    OrganizationId: orgId,
                    SiteId: siteId,
                    Permission,
                    Modules,
                    Configuration,
                    ApplicationSettings
                }
                if (operation === "create") {
                    this._saveRole(saveObject)
                } else if (operation === "update") {
                    this._updateRole(saveObject)
                }

            }
        })




    }


    renderTreeNodes = data =>
        data && data.map(item => {
            if (item.children) {
                return (
                    <TreeNode title={item.title} key={item.key} dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode key={item.key} {...item} />;
        });

    onChange = (checkedValues, title) => {
        let { checkedPermissionsKeys } = this.state
        let newObj = {
            ...checkedPermissionsKeys,
            [title]: checkedValues

        }
        this.setState({ checkedPermissionsKeys: newObj })

    }

    deleteRole = () => {

    }

    translation = (keyword) => {
        let { translationCache, language } = this.props
        return translationCache &&
            translationCache[keyword] &&
            translationCache[keyword][language] ?
            translationCache[keyword][language] : keyword
    }


    render() {
        const { moduleList, configurationList, applicationList,
            checkedModuleKeys, checkedApplicationSettingsKeys,
            checkedConfiguartionKeys, checkedPermissionsKeys, datasource,
            editObj, landingPageList, permissionsList } = this.state
        const { getFieldDecorator } = this.props.form
        const columns = [
            {
                title: this.translation('Role Name'),
                dataIndex: 'Name',
                key: 'Name',
            },
            {
                title: this.translation('Description'),
                dataIndex: 'Description',
                key: 'Description',
            },
            {
                title: this.translation('Action'),
                dataIndex: '',
                key: '',
                render: (text, record) =>
                    <div>
                        <button type='button' onClick={(e) => this.openDrawer(e, record)} style={{ marginRight: '10px' }} className='ant-btn' >
                            <Icon type='edit' />
                        </button>
                        <Popconfirm
                            title={this.translation("Are you sure delete Site ?")}
                            onConfirm={e => {
                                e.stopPropagation()
                                this._deleteRole(record)
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <button type='button' className='ant-btn'>
                                <Icon
                                    type="delete"
                                    onClick={e => {
                                        e.stopPropagation();
                                    }}
                                />
                            </button>
                        </Popconfirm>

                    </div>
            }
        ];


        return (
            <div>
                <Button type="primary" onClick={this.showDrawer} size={"large"} style={{ float: "right" }}>{this.translation("Add")}</Button>
                <Drawer
                    title={this.translation("Create Role")}
                    placement="right"
                    width={520}
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.visible}
                >
                    <Form layout='vertical'>

                        <Form.Item label={<div style={{ fontWeight: 'bold' }}>{this.translation('Role Name')}</div>} >
                            {getFieldDecorator(`Name`, {
                                initialValue: editObj.Name,
                                rules: [
                                    {
                                        required: true,
                                        message: this.translation('Please input Role Name'),
                                    },
                                ],
                            })
                                (<Input placeholder={this.translation('Please input Role Name')} disabled={editObj.Id ? true : false} />)
                            }
                        </Form.Item>

                        <Form.Item label={<div style={{ fontWeight: 'bold' }}>{this.translation('Description')}</div>} >
                            {getFieldDecorator(`Description`, {
                                initialValue: editObj.Description,
                                rules: [
                                    {
                                        required: true,
                                        message: this.translation('Please input Description')
                                    },
                                ],
                            })
                                (<Input placeholder={this.translation('Please input Description')} />)
                            }

                        </Form.Item>
                        {getFieldDecorator('Id', {
                            initialValue: editObj.Id,

                        })(
                            <Input placeholder="Id" disabled={true} style={{ display: "none" }} />,
                        )}

                        <Form.Item label={<div style={{ fontWeight: 'bold' }}>{this.translation('Landing Page')}</div>} >
                            {getFieldDecorator(`LandingPageId`, {
                                initialValue: editObj.LandingPageId,
                                rules: [
                                    {
                                        required: true,
                                        message: this.translation('Please select Landing Page')
                                    },
                                ],
                            })(<Select placeholder={this.translation('Please select Landing Page')}>
                                {
                                    landingPageList.map((value) => {
                                        return <Option value={value.Id}>{value.Name}</Option>
                                    })
                                }
                            </Select>)}

                        </Form.Item>

                        {
                            Object.keys(permissionsList).map((permissionName) => {
                                let { name, key, permission } = permissionsList[permissionName]
                                return <Form.Item label={<div style={{ fontWeight: 'bold' }}>{this.translation(name)}</div>}>
                                    <Checkbox.Group value={checkedPermissionsKeys && checkedPermissionsKeys[key] ? checkedPermissionsKeys[key] : []} onChange={(e) => this.onChange(e, key)} >
                                        <Row>
                                            {
                                                permission && Array.isArray(permission) && permission.map(({ label, value }) => {
                                                    return <Checkbox value={value}>{this.translation(label)}</Checkbox>
                                                }
                                                )
                                            }
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>

                            })
                        }

                        <Form.Item label={<div style={{ fontWeight: 'bold' }}>{this.translation('Configuration')}</div>} >
                            <Tree
                                checkable
                                onCheck={(e) => this.onCheck('configuration', e)}
                                checkedKeys={checkedConfiguartionKeys}
                            >
                                {this.renderTreeNodes(configurationList)}
                            </Tree>
                        </Form.Item>

                        <Form.Item label={<div style={{ fontWeight: 'bold' }}>{this.translation('Modules')}</div>} >
                            <Tree
                                checkable
                                onCheck={(e) => this.onCheck('module', e)}
                                checkedKeys={checkedModuleKeys}
                            >
                                {this.renderTreeNodes(moduleList)}
                            </Tree>
                        </Form.Item>

                        <Form.Item label={<div style={{ fontWeight: 'bold' }}>{this.translation('Application Settings')}</div>} >
                            <Tree
                                checkable
                                onCheck={(e) => this.onCheck('applicationSettings', e)}
                                checkedKeys={checkedApplicationSettingsKeys}
                            >
                                {this.renderTreeNodes(applicationList)}
                            </Tree>
                        </Form.Item>


                    </Form>
                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 16px',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={this.onClose} style={{ marginRight: 8 }}>{this.translation('Cancel')}</Button>

                        {
                            Object.keys(editObj).length === 0 ?
                                <Button onClick={() => this.onSubmit('create')} type="primary">{this.translation('Create')}</Button> :
                                <Button onClick={() => this.onSubmit('update')} type="primary">{this.translation('Update')}</Button>
                        }

                    </div>
                </Drawer>
                <StyledTable theme={lightTheme}>
                    <Table
                        dataSource={datasource}
                        columns={columns}
                        style={{ marginTop: '20px' }}
                    />
                </StyledTable>
            </div>


        )
    }
}

const mapStateToProps = createStructuredSelector({
    language: getCurrentLanguage(),
    translationCache: getTranslationCache(),
});
export default connect(mapStateToProps)(Form.create()(Role))
