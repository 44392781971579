import React, { Component } from "react";
import { connect } from 'react-redux';
import { Layout } from "antd";
import { createStructuredSelector } from "reselect";

import FooterLayout from "./FooterLayout/index";
import HomePageRouter from "../../../../routes/homePageRoute";
import HeaderLayout from "./HeaderLayout";
import Menubuilder from "./SideMenubuilder";
import ThirdLevelComponent from "./ThirdLevelMenu";

import { StyledContent } from "./styles";
import { sideMenuFormat } from "./Adapter/sideMenuAdapter";
import { makeSelectThemeing, makeSelectTheme } from "../../../../selectors/theme";
import './styles.css';

const { Content } = Layout;

class HomePageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideLayout: false,
      HideFooter: false,
      ability: undefined,
      userProfile: props.userProfile || {},
      selectedMenu: [],
      hideThirdLevelComponent: false,
      collapsed: false,
    };
  }

  componentWillMount() {
    this.props.actions.getLoggedInProfile();
    this._setCentralDashboard();
  }

  componentWillReceiveProps({ userProfile, selectedMenu }) {
    this._setCentralDashboard();
    this.setState({
      userProfile,
      selectedMenu: selectedMenu ? selectedMenu : []
    });
  }

  componentDidUpdate(preprops) {
    if (preprops.navigationMode !== this.props.navigationMode) {
      this.setState({
        navigationMode: this.props.navigationMode
      })
    }
  }

  _setCentralDashboard = () => {
    if (
      JSON.stringify(this.props.history.location.pathname).match(
        "editpanel"
      ) !== null
    ) {
      this.setState({ hideThirdLevelComponent: false });
    } else {
      this.setState({ hideThirdLevelComponent: true });
    }
  };

  requestFullscreen(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  }

  hideLayout = hideLayout => {
    if (hideLayout !== this.state.hideLayout) {
      this.setState({
        hideLayout: hideLayout
      });
    }
  };

  hideFooter = hideFooter => {
    if (hideFooter !== this.state.hideFooter) {
      this.setState({
        hideFooter
      });
    }
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  render() {
    const {
      hideFooter,
      selectedMenu,
      hideThirdLevelComponent,
      menuHierarchy
    } = this.state;
    let theme = this.props.selectedTheme;
    return (
      <div>
        {this.props.navigationMode === "side" ? (

          <Layout style={{ minHeight: '100vh', minWidth: "100vw" }}>

            <HeaderLayout
              collapsed={this.state.collapsed}
              hideLayout={this.state.hideLayout}
              fullScreen={this.requestFullscreen}
              history={this.props.history}
              menuHierarchy={false}
            />
            {
              !this.state.hideLayout ?
                <Menubuilder style={{ marginTop: "3.9vh", }} menuList={sideMenuFormat(menuHierarchy, "parent")} />
                : null
            }
            <Layout
              className={`themeLayout 
             ${theme === "#292961" ? "theme1" : ""}
             ${theme === "#4b4ba3" ? "theme2" : ""}
             ${theme === "#1a3652" ? "theme3" : ""}
             ${theme === "#2261a1" ? "theme4" : ""}
             ${theme === "#0d4524" ? "theme5" : ""}
             ${theme === "#156b39" ? "theme6" : ""}
             ${theme === "#691a16" ? "theme7" : ""}
             ${theme === "#a62e21" ? "theme8" : ""}
             ${theme === "#2e2e2e" ? "theme9" : ""}
           `}
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <StyledContent>
                <Content>
                  <HomePageRouter
                    hideLayout={this.hideLayout}
                    hideFooter={this.hideFooter}
                    selectedMenu={selectedMenu}
                    sideMenu={false}
                  />
                </Content>
              </StyledContent>
            </Layout>
          </Layout>

        ) : (
          <Layout className="asd" style={{ minHeight: "100vh" }}>
            <HeaderLayout
              collapsed={this.state.collapsed}
              hideLayout={this.state.hideLayout}
              fullScreen={this.requestFullscreen}
              history={this.props.history}
              menuHierarchy={true}
            />
            <Layout>

              {hideThirdLevelComponent ? (
                selectedMenu.length >= 3 ? (
                  <div
                    style={{
                      position: "fixed",
                      zIndex: "1",
                      display: "block",
                      width: "100%",
                      height: "16px"
                    }}
                  >
                    <ThirdLevelComponent selectedMenu={selectedMenu} />
                  </div>
                ) : null
              ) : null}
              <StyledContent>
                <Content>
                  <HomePageRouter
                    hideLayout={this.hideLayout}
                    hideFooter={this.hideFooter}
                    selectedMenu={selectedMenu}
                    sideMenu={true}
                  />
                </Content>
              </StyledContent>
              {!hideFooter ? <FooterLayout /> : <div />}
            </Layout>
          </Layout>
        )}

      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  selectedThemeing: makeSelectThemeing(),
  selectedTheme: makeSelectTheme(),
});
export default connect(mapStateToProps)(HomePageComponent);
