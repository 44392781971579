import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import {
  Input,
  Radio,
  Modal,
  Button,
  Form,
} from "antd";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import localStorage from "../../../../utils/localStorage";
import { makeSelectThemeing } from "../../../../selectors/theme";
import {
  getCurrentLanguage,
  getTranslationCache,
} from "../../../../selectors/language";

import "../CSS/Table.css";

class AnomalyFeedbackTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: "yes",
      feedbackText: "",
      openModal: false,
      notoficationId: "",
      notoficationValue: "",
      notoficationParamters: "",
      Notofication: "",
      translationCache: props.translationCache || [],
      language: props.language || "English",
      feedbackForm: props.feedbackForm
    };
  };

  componentDidMount() {
    this.setState({
      feedbackForm: this.props.notification ? this.props.notification : {},
      openModal: this.props.openModal,
    });
  }

  componentDidUpdate(PreProps) {
    if (PreProps.notification !== this.props.notification || PreProps.openModal !== this.props.openModal ) {
      this.setState({
        feedbackForm: this.props.notification ? this.props.notification : {},
        openModal: this.props.openModal,
      });
    }
  }


  openModal = (e) => {
    this.setState({
      openModal: true,
    });
  };

  handleOk = (e) => {
    const { feedbackForm } = this.props;
      console.log(feedbackForm);
    let payload = {
      ...feedbackForm,
      "Timestamp": moment(feedbackForm.Timestamp,"YYYY-MM-DD 00:00:00").local().format("YYYY-MM-DD HH:mm:ss"),
      "Action": this.state.action,
      "Comment": this.state.Comment,
    };
    const siteId = localStorage.get("currentSite");
    const accessToken = localStorage.get("accessToken");
    const deviceTypeObject = {
      method: "POST",
      url: `/api/dashboard/feedback`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: payload,
    };
    return axios(deviceTypeObject)
      .then((response) => {
        this.setState({
          openModal: false,
        });
        this.props.refereshData("refresh");
      })
      .catch(function () {
        return "error";
      });

  };

  handleCancel = (e) => {
    this.setState({
      openModal: false,
    });
  };

  render() {
    let { action, Comment, translationCache, language,openModal} = this.state;
    let { feedbackForm } = this.props
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 10 },
    };
    console.log(feedbackForm,openModal,this.props.openModal);
    return (
      <div style={{ marginLeft: "9px" }}>
        {/* <Button type="primary" onClick={this.openModal}>
                    {translationCache &&
                      translationCache['Feedback'] &&
                      translationCache['Feedback'] ?
                      translationCache['Feedback'] :
                      'Feedback'}
        </Button> */}

        <Modal
          visible={openModal}
          width={1000}
          height={1000}
          title="Prediction Feedback"
          onOk={this.handleOk}
          onCancel={this.handleCancel}

        >
          <Form {...formItemLayout}>
            <Form.Item label="NotificationId">
              <Input value={feedbackForm.NotificationId} readOnly></Input>
            </Form.Item>
            <Form.Item label="Timestamp">
              <Input value={feedbackForm.Timestamp} readOnly></Input>
            </Form.Item>
            <Form.Item label="TagName">
              <Input value={feedbackForm.TagName} readOnly></Input>
            </Form.Item>
            <Form.Item label="Tag Description">
              <Input value={feedbackForm.TagDescription} readOnly></Input>
            </Form.Item>

            <Form.Item label="Action">
              <Radio.Group
                value={action}
                onChange={(e) => this.setState({ action: e.target.value })}
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Comment"
              onChange={(e) => {
                this.setState({
                  "Comment": e.target.value
                })
              }}
            >
              <Input.TextArea value={this.state.Comment} />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}


const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
});

export default connect(mapStateToProps)(AnomalyFeedbackTable);


