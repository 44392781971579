import styled from 'styled-components'
import { Button } from 'antd'

export const StyledBody = styled.div`
background: white;
margin: 20px;
padding: 30px;
min-height: 720px;
`

export const AddTableButton = styled(Button)`
margin-bottom: 20px;
`

export const ResetButton = styled(Button)`
margin-left: 20px;
`

export const AddColumn = styled(Button)`
width: 60%;
`

export const DrawerFooter = styled.div`
position: absolute;
left: 0;
bottom: 0;
width: 100%;
border-top: 1px solid #e9e9e9;
padding: 10px 16px;
background: #fff;
text-align: right;
`